import { FC, useEffect, useRef, useState } from "react";
import { DEFAULT_ITEM_WIDTH, getItemsPerRow, iGrid, ITEMS_GAP } from "./Grid.helpers";
import "./Grid.styles.scss";

const Grid: FC<iGrid> = (
    {
        itemWidth = DEFAULT_ITEM_WIDTH,
        children,
        maxRowsCount,
        handleChangeItemsPerRow = () => {},
    }
) => {
    const ref = useRef<HTMLDivElement>(null);
    const timer = useRef<any>(null);
    const [itemsPerRow, setItemsPerRow] = useState(0);

    useEffect(() => {
        const updateItemsPerRow = () => {
            clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                if (ref.current) {
                    const count = getItemsPerRow(ref.current.offsetWidth, itemWidth);
                    setItemsPerRow(count);
                    handleChangeItemsPerRow(count);
                }
            }, 100);
        };

        updateItemsPerRow();
        window.addEventListener('resize', updateItemsPerRow);

        return () => {
            window.removeEventListener('resize', updateItemsPerRow)
        };
    }, [ref.current]);

    const slicedChildren = maxRowsCount ? children.slice(0, maxRowsCount * itemsPerRow) : children;

    return (
        <div className="Grid" ref={ref}>
            {slicedChildren.map(child => (
                <div
                    key={JSON.stringify(child.key)}
                    className="GridTile"
                    style={{
                        flexBasis: `calc(calc(100%/${itemsPerRow}) - ${ITEMS_GAP}px)`,
                        maxWidth: `calc(calc(100%/${itemsPerRow}) - ${ITEMS_GAP}px)`
                    }}
                >
                    {child.value}
                </div>
            ))}
        </div>
    );
};

export { Grid };
