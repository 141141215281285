import { Component, createRef } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import TextArea from 'react-textarea-autosize';
import Popup from '../../components/Popup/Popup';
import Scrollbar from 'react-custom-scrollbars';

import {
    close, save, setData, showValidationErrors, checkNameUnique, hideValidationErrors,
} from './projectEditPopup.actions';

class ProjectEditPopup extends Component {
    constructor(props) {
        super(props);
        this.inputRef = createRef();
        this.innerRef = createRef();
        this.save = this.save.bind(this);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
    }

    handleNameChange(event) {
        const { setData, checkNameUnique } = this.props;
        checkNameUnique(event.target.value);
        setData({ name: event.target.value });
    }

    save() {
        this.props.save();
    }

    close() {
        this.props.close();
    }

    open() {
        setTimeout(() => {
            this.inputRef.current.selectionStart = this.inputRef.current.value.length;
            this.inputRef.current.selectionEnd = this.inputRef.current.value.length;
        }, 100);
    }

    render() {
        const { isOpened, name = '', initialName, description = '', isNameUnique, isNameUniquenessChecking } = this.props.data;

        let errorMsgText = '';

        if (name.trim() !== '' && !isNameUnique && name.trim() !== initialName && !isNameUniquenessChecking) {
            errorMsgText = 'this name is not available';
        }

        return (
            <Popup
                isOpened={isOpened}
                onClose={this.close}
                onOpen={this.open}
                className="popup-project-edit popup--fade-in"
            >
                <div ref={this.innerRef} className="popup__inner popup__inner--small">
                    <div className="popup__header">
                        <div
                            className="popup__header-title"
                            data-testid="textProjectEditPopupTitle"
                        >
                            Edit Project
                        </div>
                    </div>
                    <div className="popup__input-container popup__input-container--fixed">
                        <div className="popup__input-wrapper">
                            <label
                                className="popup__input-label"
                                data-testid="textProjectEditPopupProjectNameLabel"
                            >
                                project name
                            </label>
                            <input
                                type="text"
                                ref={this.inputRef}
                                className="popup__project-input"
                                value={name}
                                onChange={this.handleNameChange}
                                autoFocus={true}
                                data-testid="inputProjectEditPopupProjectName"
                            />
                        </div>
                        <div className="popup__input-wrapper">
                            <label
                                className="popup__input-label popup__description-label"
                                data-testid="textProjectEditPopupProjectDescriptionLabel"
                            >
                                description
                            </label>
                            <div className="popup__scrollbar-inner popup__scrollbar-inner--fixed">
                                <Scrollbar>
                                <TextArea
                                    autoFocus={false}
                                    minRows={3}
                                    maxRows={8}
                                    value={description}
                                    className="popup__project-text-area popup__textarea"
                                    onChange={event => this.props.setData({ description: event.target.value })}
                                    data-testid="inputProjectEditPopupProjectDescription"
                                />
                                </Scrollbar>
                            </div>
                        </div>
                        <div
                            className="popup__error-msg popup__error-msg--proj-edit"
                            data-testid="textProjectEditPopupErrorMessage"
                        >
                            {errorMsgText}
                        </div>
                    </div>

                    <div className="popup__options popup__options--bottom popup__options--fixed">
                        <button
                            disabled={
                                (!isNameUnique && name !== initialName)
                                || isNameUniquenessChecking
                                || name.trim() === ''
                                || description.trim() === ''
                            }
                            className="popup__btn popup__btn--md"
                            onClick={this.save}
                            data-testid="buttonProjectEditPopupSubmit"
                        >
                            SAVE
                        </button>
                    </div>
                </div>
            </Popup>
        );
    }
}

ProjectEditPopup.propTypes = {
    data: PT.object,
    setData: PT.func,
    checkNameUnique: PT.func,
    save: PT.func,
    close: PT.func,
};

const MapStateToProps = state => ({
    data: state.projectEditPopupData,
});

const MapDispatchToProps = dispatch => ({
    close: () => dispatch(close()),
    setData: data => dispatch(setData(data)),
    save: () => dispatch(save()),
    showValidationErrors: () => dispatch(showValidationErrors()),
    hideValidationErrors: () => dispatch(hideValidationErrors()),
    checkNameUnique: name => dispatch(checkNameUnique(name)),
});

export default connect(MapStateToProps, MapDispatchToProps)(ProjectEditPopup);
