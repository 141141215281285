import Http from '../../core/fetch';
import {
    DOCUMENT_TYPE_DATA_STORE,
    DOCUMENT_TYPE_DATA_CLEAR
} from '../../actions/actionsNamesList';

export function storeData(data) {
    return {
        type: DOCUMENT_TYPE_DATA_STORE,
        data
    };
}

export function getData() {
    return (dispatch, getState) => {
        const { id, customerViewCompanyId, isShareMode } = getState().documentTypeData;
        const params = { doctypeId: id, isShareMode };

        if (customerViewCompanyId) {
            params.companyId = customerViewCompanyId;
        }
        Http.get('get document type', params)
            .then((response) => {
                dispatch(storeData({ dataLoaded: true }));
                dispatch(storeData(response));
            });
    };
}

export function clearData() {
    return {
        type: DOCUMENT_TYPE_DATA_CLEAR
    };
}
