import { FC, useState } from "react";
import { getFormattedDate } from "../../../../core/helpers";
import { iNote } from "../../../../core/models/Note";
import { IconDelete, IconEditWhite, IconLock } from "../../../../icons";
import Button from "../../Button/Button";
import "./NotesItem.styles.scss";

interface iProps {
    data: iNote;
    onUpdate: (note: iNote) => void;
    onRemove: (id: number) => void;
    updateScrollbar: () => void;
}

export const NotesItem: FC<iProps> = ({
    data,
    onUpdate,
    onRemove,
    updateScrollbar
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const MAX_CHARS = 90;

    const formatedDate = getFormattedDate(data.modifiedDateTime, "MMMM DD, YYYY");

    const handleRemove = (e: MouseEvent) => {
        e.stopPropagation();
        onRemove(data.id);
    }

    const handleEdit = (e: MouseEvent) => {
        e.stopPropagation();
        onUpdate(data);
    }

    const handleExpand = () => {
        setIsExpanded(!isExpanded);
        updateScrollbar();
    }

    return (
        <div className="NotesItem" onClick={handleExpand}>
            <div className="NotesItem__wrapper">
                <div className="NotesItem__content">
                    {!data.isPublic && (
                        <div className="NotesItem__status">
                            <IconLock />
                        </div>
                    )}

                    <div className="NotesItem__text">
                        {data.text.substring(0, isExpanded ? data.text.length : MAX_CHARS)}
                        {!isExpanded && data.text.length > MAX_CHARS && "..." }
                    </div>
                </div>

                {data.isOwn && (
                    <div className="NotesItem__actions">
                        <Button
                            modifiers={["icon"]}
                            className="NotesItem__actions-button"
                            onClick={handleEdit}
                        >
                            <IconEditWhite />
                        </Button>
                        <Button
                            modifiers={["icon"]}
                            className="NotesItem__actions-button"
                            onClick={handleRemove}
                        >
                            <IconDelete className="NotesItem__actions-remove" />
                        </Button>
                    </div>
                )}
            </div>

            <div className="NotesItem__date">{formatedDate}</div>
        </div>
    );
};
