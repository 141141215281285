import { FC, useEffect, useState } from "react";
import { getInputErrors, isDataValid } from "../../../../../core/formValidation";
import { iContact } from "../../../../../core/models/Contact";
import Button from "../../../Button/Button";
import InputField from "../../../InputField/InputField";
import { iValidationAddData, validationAddConfig } from "../FormRequestContact.helpers";

interface iProps {
    data: iContact;
    onChange: (field: keyof iContact) => (value: string) => void;
    onClose: () => void;
    onSubmit: (item: iContact) => void;
    isShown?: boolean;
}

const FormAdd: FC<iProps> = ({ data, onChange, onClose, onSubmit, isShown }) => {
    const [isValidationShown, setIsValidationShown] = useState(false);

    const validationAddData: iValidationAddData = {
        name: data.name,
        department: data.department,
        email: data.email,
        title: data.title,
    };

    const isValid = isDataValid(validationAddData)(validationAddConfig);

    const fieldHasError = (field: keyof iValidationAddData) =>
        !!getInputErrors(validationAddData)(validationAddConfig)(field).length && isValidationShown;

    const handleSave = () => {
        if (isValid) {
            setIsValidationShown(false);
            onSubmit(data);
        } else {
            setIsValidationShown(true);
        }
    };

    useEffect(() => {
        return () => {
            setIsValidationShown(false);
        };
    }, [isShown]);

    if (!isShown) return null;

    return (
        <>
            <div className="ContactsForm__form">
                <div className="ContactsForm__row">
                    <InputField
                        label="Full name"
                        onChange={onChange("name")}
                        hasError={fieldHasError("name")}
                        value={data.name}
                        isRequired
                    />
                </div>
                <div className="ContactsForm__row">
                    <InputField
                        label="Department"
                        onChange={onChange("department")}
                        hasError={fieldHasError("department")}
                        value={data.department}
                        isRequired
                    />
                    <InputField
                        label="Job Title"
                        onChange={onChange("title")}
                        hasError={fieldHasError("title")}
                        value={data.title}
                        isRequired
                    />
                </div>
                <div className="ContactsForm__row">
                    <InputField
                        label="Email"
                        onChange={onChange("email")}
                        hasError={fieldHasError("email")}
                        value={data.email}
                        isRequired
                    />
                </div>
                <div className="ContactsForm__row">
                    <InputField
                        label="Phone number"
                        onChange={onChange("phone")}
                        value={data.phone}
                    />
                </div>
                <div className="ContactsForm__row">
                    <InputField
                        label="Linkedin profile link"
                        onChange={onChange("linkedInProfile")}
                        value={data.linkedInProfile}
                    />
                </div>
            </div>
            <div className="ContactsForm__footer">
                <Button
                    className="ContactsForm__footer-button ContactsForm__footer-button--close"
                    onClick={onClose}
                    modifiers={["secondary"]}
                >
                    Cancel
                </Button>

                <Button
                    className="ContactsForm__footer-button ContactsForm__footer-button--submit"
                    onClick={handleSave}
                    modifiers={["primary"]}
                >
                    SUBMIT
                </Button>
            </div>
        </>
    );
};

export default FormAdd;
