import { ReactNode } from "react";
import { ID } from "../../../core/models";

export interface iGridItem {
    key: ID;
    value: ReactNode;
}

export const DEFAULT_ITEM_WIDTH = 300;
export const ITEMS_GAP = 24;

export function getItemsPerRow(viewportWidth: number, approxTileWidth: number) {
    return Math.floor(viewportWidth / approxTileWidth);
}

export interface iGrid {
    children: iGridItem[];
    itemWidth?: number;
    maxRowsCount?: number;
    handleChangeItemsPerRow?: (count: number) => void;
}