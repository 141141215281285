export interface iDocumentQueryParams {
    id: string;
    doctype: string;
    signature?: string;
    fromDocuments?: string;
    from?: string;
    publicationFamily?: string;
}

export enum DocumentTypes {
    report = 0,
    mac = 3,
    document = 5,
    snapshot = 6,
    trendspotting = 7,
    trendspottingarticle = 8,
    mtdFoodProfile = 16,
}