import http from '../..//core/fetch';
import { PROJECT_DELETE_POPUP } from '../../actions/actionsNamesList';

import {
    getData as updateDocumentsLibraryData, updatePageToReload
} from '..//DocumentsLibraryPopups/documentsLibraryPopups.actions';

const { OPEN, CLOSE, SET_DATA, RESET_DATA } = PROJECT_DELETE_POPUP;

export function startDeleting(data) {
    return (dispatch) => {
        dispatch(setData(data));
        dispatch(open());
    };
}

export function open() {
    return {
        type: OPEN
    };
}

export function close() {
    return {
        type: CLOSE
    };
}

export function setData(project) {
    return {
        type: SET_DATA,
        data: project
    };
}

export function resetData() {
    return {
        type: RESET_DATA
    };
}

export function deleting(redirect) {
    return (dispatch, getState) => {
        const { id } = getState().projectDeletePopupData;
        const { customerViewCompanyId } = getState().documentsLibraryPopupsData;
        const params = { projectGuid: id };
        if (customerViewCompanyId !== null) {
            params.companyId = customerViewCompanyId;
        }
        http.post(
            'delete project',
            params,
            { headers: { accept: 'application/json' } }
        )
            .then(() => {
                if (redirect) {
                    redirect();
                } else {
                    dispatch(updateDocumentsLibraryData());
                    dispatch(updatePageToReload());
                }
                dispatch(close());
            });
    };
}
