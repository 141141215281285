import SlickSlider, { Settings } from "react-slick";

export const SLIDER_DEFAULT_CONFIG: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "",
};

export const THUMBNAILS_DEFAULT_CONFIG: Settings = {
    slidesToShow: 8,
    swipeToSlide: true,
    focusOnSelect: true,
    arrows: false,
};

export interface SlickSliderExtended extends SlickSlider {
    innerSlider: any;
}