import { DOCUMENT_EDIT_POPUP } from '../../actions/actionsNamesList';

const {
    OPEN,
    CLOSE,
    SET_DATA,
    SHOW_VALIDATION_ERRORS,
    HIDE_VALIDATION_ERRORS,
    SET_SAVING_REQUESTED,
    UNSET_SAVING_REQUESTED,
    STORE_UPLOADING_FILE,
    REMOVE_REQUESTED_SAVE_DATA,
    STORE_REQUESTED_SAVE_DATA,
} = DOCUMENT_EDIT_POPUP;

const defaultState = {
    isOpened: false,
    showErrors: false,
    id: null,
    name: '',
    initialName: '',
    isNameUnique: true,
    isNameUniquenessChecking: false,
    description: '',
    typeId: null,
    documentType: null,
    projectId: null,
    shareType: 0,
    shareContacts: new Map(),
    shareWithIds: [],
    size: 0,
    isFileChanged: false,
    requestedSaves: new Map(),
};

export default function documentEditPopupData(state = defaultState, action) {
    switch (action.type) {
        case OPEN: {
            return {
                ...state,
                isOpened: true,
                isSavingRequested: true,
            };
        }
        case CLOSE: {
            return {
                ...state,
                isOpened: false,
            };
        }
        case SHOW_VALIDATION_ERRORS: {
            return {
                ...state,
                showErrors: true
            };
        }
        case HIDE_VALIDATION_ERRORS: {
            return {
                ...state,
                showErrors: true
            };
        }
        case SET_DATA: {
            return {
                ...state,
                ...action.data
            };
        }
        case SET_SAVING_REQUESTED: {
            return {
                ...state,
                isSavingRequested: true
            }
        }
        case UNSET_SAVING_REQUESTED: {
            return {
                ...state,
                isSavingRequested: false
            }
        }
        case STORE_UPLOADING_FILE: {
            return {
                ...state,
                file: action.file,
                isFileChanged: true,
                backEndData: null,
            }
        }
        case STORE_REQUESTED_SAVE_DATA: {
            const requestedSaves = new Map(state.requestedSaves);
            requestedSaves.set(action.data.id, action.data);
            return {
                ...state,
                requestedSaves,
            };
        }
        case REMOVE_REQUESTED_SAVE_DATA: {
            const requestedSaves = new Map(state.requestedSaves);
            requestedSaves.delete(action.id);
            return {
                ...state,
                requestedSaves,
            };
        }
        default: {
            return { ...state };
        }
    }
}
