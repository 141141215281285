import { FC, useRef, useState } from "react";
import cn from "classnames";
import { DOCUMENTS_FILTER, iDocumentsFilter, ViewDocuments } from "../Documents.helpers";
import Dropdown from "@components/new/Dropdown/Dropdown";
import useOutsideClick from "@hooks/useOutsideClick";
import "./DocumentsHeader.styles.scss";

interface iProps {
    onChangeView: (view: ViewDocuments) => void;
    onChangeFilter: (id: number) => void;
    view: ViewDocuments;
    totalDocuments: number;
    title: string;
    selectedFilter: iDocumentsFilter;
}

const DocumentsHeader: FC<iProps> = ({
    onChangeView,
    view,
    totalDocuments,
    title,
    onChangeFilter,
    selectedFilter,
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdown = useRef<HTMLDivElement>(null);

    useOutsideClick({
        ref: dropdown,
        isDisabled: !isDropdownOpen,
        fn: () => setIsDropdownOpen(false),
    });

    const getViewButtonClassnames = (buttonView: ViewDocuments) => {
        return cn("DocumentsHeader__view-button", {
            "DocumentsHeader__view-button--active": view === buttonView,
        });
    };

    const handleChangeFilter = (id: number) => {
        onChangeFilter(id);
        setIsDropdownOpen(false);
    }

    return (
        <div className="DocumentsHeader">
            <div className="DocumentsHeader__info">
                <div className="DocumentsHeader__title">{title}</div>
                <div className="DocumentsHeader__total">found in {totalDocuments} documents</div>
            </div>
            <div className="DocumentsHeader__actions">
                <div className="DocumentsHeader__toggle-view">
                    <div
                        className={getViewButtonClassnames(ViewDocuments.Tiles)}
                        onClick={() => onChangeView(ViewDocuments.Tiles)}
                    >
                        tile
                    </div>
                    <div
                        className={getViewButtonClassnames(ViewDocuments.Table)}
                        onClick={() => onChangeView(ViewDocuments.Table)}
                    >
                        table
                    </div>
                </div>

                <div
                    className={cn("DocumentsHeader__filter", {
                        "DocumentsHeader__filter--opened": isDropdownOpen,
                    })}
                    ref={dropdown}
                >
                    <div
                        className="DocumentsHeader__filter-selected"
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                        {selectedFilter.title}
                    </div>

                    <Dropdown shown={isDropdownOpen}>
                        <div className="DocumentsHeader__filter-list">
                            {DOCUMENTS_FILTER.map(filter => (
                                <div
                                    key={filter.id}
                                    onClick={() => handleChangeFilter(filter.id)}
                                    className={cn("DocumentsHeader__filter-list-item", {
                                        "DocumentsHeader__filter-list-item--selected": selectedFilter.id === filter.id,
                                    })}
                                >
                                    {filter.title}
                                </div>
                            ))}
                        </div>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
};

export default DocumentsHeader;
