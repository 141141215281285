import { ListChildComponentProps } from "react-window";
import { iOption } from "../Autocomplete.helpers";

interface iOptionProps extends ListChildComponentProps {
    data: {
        options: iOption[];
        handleSelect: (option: iOption) => void;
        activeOptionIndex: number;
        searchQuery: string;
        autoHighlight?: boolean;
    };
}

const Option: React.FC<iOptionProps> = ({ index, style, data }) => {
    const {
        options,
        handleSelect,
        activeOptionIndex,
        searchQuery,
        autoHighlight = false
    } = data;

    const item = options[index];

    const title = autoHighlight ? (
        <span
            dangerouslySetInnerHTML={{
                __html: item.title.replace(
                    new RegExp(`(${searchQuery})`, "gi"),
                    "<span class='Autocomplete__option-highlight'>$1</span>",
                ),
            }}
        />
    ) : (
        <span>{item.title}</span>
    );
    return (
        <div
            className={`Autocomplete__option ${activeOptionIndex === index ? "is-selected" : ""}`}
            key={item.id}
            style={style}
        >
            <div className="Autocomplete__option-text" onClick={() => handleSelect(item)}>
                <div className="Autocomplete__option-title">{item.title && title}</div>
            </div>
        </div>
    );
};

export default Option;
