const UNITS_CONFIG = ['b', 'kb', 'mb', 'gb'];

export function getHigherUnitSize(size, times = 1) {
    if (times > 1) {
        return getHigherUnitSize(size / 1024, times - 1);
    }

    return size / 1024;
}

export function possibleConversions(size) {
    let _size = size;
    let number = 0;
    while (_size > 1024) {
        _size /= 1024;
        number += 1;
    }
    return number;
}

export default function getFileSize(size, units = '') {
    let conversions = possibleConversions(size);
    let _units = units;

    if (units !== '') {
        conversions = UNITS_CONFIG.indexOf(units);
    } else {
        _units = UNITS_CONFIG[conversions];
    }
    const value = getHigherUnitSize(size, conversions);

    return {
        value,
        units: _units,
        valueWithTwoAfterSign: value.toFixed(2),
        classicFormat: `${value.toFixed(2)} ${_units}`,
        initialSize: size,
    };
}
