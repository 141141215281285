import { FC } from "react";
import classNames from "classnames";
import "./EmptyContactCard.styles.scss";
import { ReactComponent as AddIcon } from "../../../icons/add-icon.svg";

interface iProps {
    className?: string;
    text?: string;
    onClick: () => void;
}

const EmptyContactCard: FC<iProps> = ({ className = "", onClick, text = "Add" }) => {
    return (
        <div
            className={classNames("EmptyContactCard", className)}
            onClick={onClick}
        >
            <AddIcon className="EmptyContactCard__icon" />
            <div className="EmptyContactCard__title">{text}</div>

        </div>
    );
};

export default EmptyContactCard;
