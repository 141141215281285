import { API__ChainMenuItem } from "../apiSchema";
import { getMenuPartType } from "./ChainMenuData";

export interface iMenuExample {
    title: string;
    description: string;
    price: number;
    menuPart: string;
}

export class MenuExamples implements iMenuExample {
    static defaultData: iMenuExample = {
        title: "",
        description: "",
        price: 0,
        menuPart: ""
    }

    title = MenuExamples.defaultData.title;
    description = MenuExamples.defaultData.description;
    price = MenuExamples.defaultData.price;
    menuPart = MenuExamples.defaultData.menuPart;

    constructor(data?: API__ChainMenuItem) {
        if (data) {
            this.mapFromApi(data);
        }
    }

    private setData(model: iMenuExample) {
        ({
            title: this.title,
            description: this.description,
            price: this.price,
            menuPart: this.menuPart
        } = model);
    }

    private mapFromApi(data: API__ChainMenuItem) {
        const {
            item,
            menu_part,
            item_description,
            item_price,
        } = data;

        this.setData({
            title: item,
            description: item_description,
            price: item_price,
            menuPart: getMenuPartType(menu_part),
        })
    }
}