import { createSelector } from 'reselect';

const macData = state => state.macData.data;

export const macDataSelector = createSelector(
    macData,
    data => data
);

export const macWords169Selector = createSelector(
    macData,
    data => data.dispArraysSecond
);

export const macWords43Selector = createSelector(
    macData,
    data => data.dispArrays
);

export const macDataTitleSelector = createSelector(
    macData,
    data => data.title
);

export const macDataOptionsSelector = createSelector(
    macData,
    data => data.options
);

export const macBackgroundUrlSelector = createSelector(
    state => state.macData,
    data => data.customBgUrl
);

export const macUploadingStateSelector = createSelector(
    state => state.macData,
    data => data.uploadingBgImage
);

export const macFileSizeStatusSelector = createSelector(
    state => state.macData,
    data => data.sizeIsExceeded
);

export const macResettingBgStateSelector = createSelector(
    state => state.macData,
    data => data.resettingBgImage
);

export const macDefaultBackgroundUrlSelector = createSelector(
    state => state.macData,
    data => data.defaultBgUrl
);

export const macLoadingDownloadLinkStateSelector = createSelector(
    state => state.macData,
    data => data.loadingDownloadLink
);

export const macDownloadUrlSelector = createSelector(
    state => state.macData,
    data => data.downloadUrl
);

export const macTableDataSelector = createSelector(
    state => state.macData,
    data => data.dataTable.data
);

export const macMenusLoadingSelector = createSelector(
    state => state.macData,
    data => data.loadingMenus
);



export const placesColumnsSettings = () => [
    { label: 'Restaurant', align: 'left' },
    { label: 'Item' },
    { label: 'Description' },
    { label: 'Price' }
];

export const placesTableCellsSelector = createSelector(
    macTableDataSelector,
    section => section.map((item) => {
        return [
            item.RestaurantName,
            item.Name,
            { value: item.Description, type: 'html' },
            item.Price
        ];
    })
);

export const placesTableDataSelector = createSelector(
    placesTableCellsSelector,
    placesColumnsSettings,
    (data, settings) => {
        return {
            data,
            settings
        };
    }
);
