import { createSelector } from 'reselect';
import { chain as _chain } from 'lodash';
import getFileSize from '../../core/getFileSize';
import getFileTypeIcon from '../../core/getFileTypeIcon';
import { capitalize, makeEnding } from '../../core/old_helpers';
import { companiesSelector } from '../../containers/DocumentsLibraryPopups/documentsLibraryPopups.selectors';
import { isImpersonateModeSelector } from '../../selectors/page';

const pageData = state => state.myDocumentsData;

export const projectsSelector = createSelector(
    pageData,
    (data) => {
        return data.projects
            .filter(project => project.name)
            .map((project, index) => {
                const { createdDate } = project;
                const date = new Date(createdDate);
                let day = date.getDate();
                let month = 1 + date.getMonth();
                let color;
                const year = date.getFullYear();
                if (day < 10) day = `0${day}`;
                if (month < 10) month = `0${month}`;
                switch (project.name[0].toLowerCase()) {
                    case 'a':
                    case 'h':
                    case 'o':
                    case 'v':
                        color = 1;
                        break;
                    case 'b':
                    case 'i':
                    case 'p':
                    case 'w':
                        color = 2;
                        break;
                    case 'c':
                    case 'j':
                    case 'q':
                    case 'x':
                        color = 3;
                        break;
                    case 'd':
                    case 'k':
                    case 'r':
                    case 'y':
                        color = 4;
                        break;
                    case 'e':
                    case 'l':
                    case 's':
                    case 'z':
                        color = 5;
                        break;
                    case 'f':
                    case 'm':
                    case 't':
                        color = 6;
                        break;
                    case 'g':
                    case 'n':
                    case 'u':
                        color = 7;
                        break;
                    default:
                        color = 7;
                        break;
                }
                return {
                    ...project,
                    index,
                    color,
                    formattedDate: isNaN(year) ? '--/--/----' : `${month}/${day}/${(`${year}`.substr(2, 4))}`
                };
            });
    }
);

export const documentsCountSelector = createSelector(
    projectsSelector,
    projects => projects.reduce((acc, project) => acc + project.documentsCount, 0)
);

export const TABLE_COLUMNS_SETTINGS = [
    { label: 'Project', align: 'left', sorting: 'abc' },
    { label: 'Files', sorting: 'num' },
    { label: 'Date', sorting: 'date' },
    { label: 'Created by', sorting: 'abc' },
    { label: 'Shared with', sorting: 'num' },
];

export const projectsTableSelector = createSelector(
    pageData,
    projectsSelector,
    companiesSelector,
    isImpersonateModeSelector,
    (data, projects, companies, isImpersonateMode) => {
        const settings = [...TABLE_COLUMNS_SETTINGS];
        if (data.projectsViewMode !== 'shared') {
            settings.splice(4, 1);
        }
        return {
            settings,
            data: projects.map((project) => {
                const date = new Date(project.createdDate);
                let day = date.getDate();
                let month = 1 + date.getMonth();
                const year = date.getFullYear();
                if (day < 10) day = `0${day}`;
                if (month < 10) month = `0${month}`;
                const companyId = data.isCustomerViewMode ? `&companyId=${data.customerViewCompanyId}` : '';
                const isShareMode = data.projectsViewMode === 'shared' ? '&isShareMode=true' : '';
                let documentsCount = project.documentsCount;
                let size = getFileSize(project.sharedDocumentFilesSize + project.internalDocumentFilesSize);
                if (data.projectsViewMode === 'shared') {
                    documentsCount = project.sharedDocumentsCount;
                    size = getFileSize(project.sharedDocumentFilesSize);
                } else if (data.projectsViewMode === 'internal') {
                    documentsCount = project.internalDocumentsCount;
                    size = getFileSize(project.internalDocumentFilesSize);
                }

                if (window.pageData.currentUsercompanyId !== 1) {
                    size = getFileSize(project.totalDocumentSize)
                }

                if (data.projectsViewMode === 'shared') {
                    let shareWithTitle = '--';
                    const sharedWithList = companies.filter(({ value }) => project.sharedCompaniesIds.includes(value));
                    if (project.isShared) {
                        if (project.sharedWithCount === 1 && companies.length) {
                            const company = companies.find(
                                ({ value }) => value === project.sharedCompaniesIds[0]
                            );
                            shareWithTitle = company ? company.title : '---';
                        } else if (project.sharedWithCount === 0) {
                            shareWithTitle = '--';
                        } else {
                            shareWithTitle = `${project.sharedWithCount} ${makeEnding('company', project.sharedWithCount)}`;
                        }
                    }
                    return [
                        data.isCustomerViewMode && project.isInternal ? {
                            type: 'text',
                            value: project.name,
                            name: project.name,
                            showFooter: !isImpersonateMode,
                        } : {
                            type: 'documents library project',
                            data: project,
                            value: project.name,
                            size: `${size.value.toFixed(1)} ${size.units}`,
                            path: '/project',
                            search: `?id=${project.id}${companyId}${isShareMode}`,
                            showFooter: !isImpersonateMode,
                        },
                        documentsCount,
                        {
                            type: 'date',
                            name: isNaN(year) ? '--/--/----' : `${month}/${day}/${year}`,
                            value: isNaN(year) ? '--/--/----' : date.getTime()
                        },
                        capitalize(project.createdByUserName),
                        {
                            type: 'tooltip',
                            name: shareWithTitle,
                            value: project.sharedWithCount,
                            data: project.sharedWithCount > 1 && sharedWithList
                        },
                    ];
                }

                return [
                    data.isCustomerViewMode && project.isInternal ? {
                        type: 'text',
                        value: project.name,
                        name: project.name,
                        showFooter: !isImpersonateMode,
                    } : {
                        type: 'documents library project',
                        data: project,
                        value: project.name,
                        size: `${size.value.toFixed(1)} ${size.units}`,
                        path: '/project',
                        search: `?id=${project.id}${companyId}${isShareMode}`,
                        showFooter: !isImpersonateMode,
                    },
                    documentsCount,
                    {
                        type: 'date',
                        name: isNaN(year) ? '--/--/----' : `${month}/${day}/${year}`,
                        value: isNaN(year) ? '--/--/----' : date.getTime()
                    },
                    capitalize(project.createdByUserName),
                ];
            })
        };
    }
);

export const documentTypesSelector = createSelector(
    pageData,
    data => _chain(data.documentTypes)
        .reduce((groups, type) => {
            const name = type.name.trim().toLowerCase();
            const result = { ...groups };
            if (groups[name]) {
                result[name] = [...groups[name], type];
            } else {
                result[name] = [type];
            }
            return result;
        }, {})
        .map((groupedItem, key) => {
            const ids = Object.keys(groupedItem).map(f => groupedItem[f].docTypeId);
            const sum = groupedItem.reduce((memo, item) => memo + item.documentsCount, 0);

            return {
                documentTypeName: key,
                documentTypeDocumentsCount: sum,
                documentTypeIds: ids
            };
        })
        .value()
);

export const SEARCH_RESULTS_TABLE_COLUMNS_SETTINGS = [
    { label: 'Name', align: 'left', sorting: 'abc' },
    { label: 'Project name', sorting: 'abc' },
    { label: 'Type', sorting: 'abc' },
    { label: 'Date', sorting: 'date' },
    { label: 'Added by', sorting: 'abc' },
];

const userCompanyIdSelector = state => state.documentsLibraryPopupsData.currentUserCompanyId;

export const SEARCH_RESULTS_TABLE_COLUMNS_SETTINGS_DS = [
    { label: 'Name', align: 'left', sorting: 'abc' },
    { label: 'Project name', sorting: 'abc' },
    { label: 'Type', sorting: 'abc' },
    { label: 'Date', sorting: 'date' },
    { label: 'Shared with', sorting: 'num' },
    { label: 'Added by', sorting: 'abc' },
];

export const searchResultsSelector = createSelector(
    pageData,
    (data) => {
        const { searchResultsProjects, searchResultsDocuments } = data;

        /* create additional field to differentiate projects and files */
        return [
            ...searchResultsProjects.map(project => ({ ...project, resultType: 'project' })),
            ...searchResultsDocuments.map(document => ({ ...document, resultType: 'document' }))
        ];
    }
);

export const searchResultsTableSelector = createSelector(
    pageData,
    searchResultsSelector,
    userCompanyIdSelector,
    companiesSelector,
    isImpersonateModeSelector,
    (data, documents, companyId, companies, isImpersonateMode) => ({
        settings: companyId === 1 ? SEARCH_RESULTS_TABLE_COLUMNS_SETTINGS_DS : SEARCH_RESULTS_TABLE_COLUMNS_SETTINGS,
        data: documents.map((result) => {
            if (result.resultType === 'document') {
                const date = new Date(result.createdOn);
                let day = date.getDate();
                let month = 1 + date.getMonth();
                const year = date.getFullYear();
                const fileSize = getFileSize(result.fileSize || 0);

                if (day < 10) day = `0${day}`;
                if (month < 10) month = `0${month}`;

                let sharedWithName = '--';
                const companiesCount = result.companyIds.length;
                if (companies.length > 1 && companiesCount) {
                    sharedWithName = companiesCount > 1
                        ? `${companiesCount} companies`
                        : companies.find(({ value }) => value === result.companyIds[0]).title;
                }
                const sharedWithList = companies
                    .filter(({ value }) => result.companyIds.includes(value) && +value !== 1)
                    .sort(({ title: a = '' }, { title: b = '' }) => {
                        return a.trim().toLowerCase() < b.trim().toLowerCase() ? -1 : 1;
                    });

                if (companyId === 1) {
                    return [
                        {
                            type: 'documents library document',
                            value: result.title,
                            data: result,
                            icon: result.docTypeString ? getFileTypeIcon(result.docTypeString.substr(1)) : 'file-other',
                            size: `${fileSize.value.toFixed(1)} ${fileSize.units}`,
                            width: 30,
                            height: 36,
                            showFooter: !isImpersonateMode,
                        },
                        result.projectName ?? '',
                        result.documentType.name ?? '',
                        {
                            type: 'date',
                            name: isNaN(year) ? '--/--/----' : `${month}/${day}/${year}`,
                            value: isNaN(year) ? '--/--/----' : `${month}/${day}/${year}`
                        },
                        {
                            type: 'tooltip',
                            name: sharedWithName,
                            value: companiesCount,
                            data: companiesCount > 1 && sharedWithList
                        },
                        capitalize(result.createdByUserName),
                        // {
                        //     type: 'overlay document',
                        //     value: result
                        // }
                    ];
                }

                return [
                    {
                        type: 'documents library document',
                        value: result.title,
                        data: result,
                        icon: result.docTypeString ? getFileTypeIcon(result.docTypeString.substr(1)) : 'file-other',
                        width: 30,
                        size: `${fileSize.value.toFixed(1)} ${fileSize.units}`,
                        height: 36,
                        showFooter: !isImpersonateMode,
                    },
                    result.projectName ?? '',
                    result.documentType.name ?? '',
                    {
                        type: 'date',
                        name: isNaN(year) ? '--/--/----' : `${month}/${day}/${year}`,
                        value: isNaN(year) ? '--/--/----' : `${month}/${day}/${year}`
                    },
                    capitalize(result.createdByUserName),
                    // {
                    //     type: 'overlay document',
                    //     value: result
                    // }
                ];
            }
            else {
                const date = new Date(result.createdDate);
                let day = date.getDate();
                let month = 1 + date.getMonth();
                const year = date.getFullYear();
                if (day < 10) day = `0${day}`;
                if (month < 10) month = `0${month}`;
                const urlParams = {
                    id: result.id
                };
                const size = getFileSize(result.totalDocumentSize);

                if (data.isCustomerViewMode) {
                    urlParams.companyId = data.customerViewCompanyId;
                }

                if (!result.isInternal && result.isShared) {
                    urlParams.isShareMode = true;
                }

                if (companyId === 1) {
                    let shareWithTitle = '--';
                    const sharedWithList = companies.filter(
                        ({ value }) => result.sharedCompaniesIds.includes(value) && value !== 1
                    );
                    if (result.isShared) {
                        if (result.sharedWithCount === 1 && companies.length) {
                            shareWithTitle = companies.find(
                                ({ value }) => value === result.sharedCompaniesIds[0]
                            ).title;
                        } else if (result.sharedWithCount === 0) {
                            shareWithTitle = '--';
                        } else {
                            shareWithTitle = `${result.sharedWithCount} ${makeEnding('company', result.sharedWithCount)}`;
                        }
                    }
                    return [
                        {
                            type: 'documents library project',
                            data: result,
                            value: result.name,
                            size: `${size.value.toFixed(1)} ${size.units}`,
                            icon: 'project-icon',
                            width: 30,
                            height: 36,
                            path: '/project',
                            search: `?id=${result.id}`,
                            showFooter: !isImpersonateMode,
                        },
                        '--',
                        '--',
                        {
                            type: 'date',
                            name: isNaN(year) ? '--/--/----' : `${month}/${day}/${year}`,
                            value: isNaN(year) ? '--/--/----' : `${month}/${day}/${year}`
                        },
                        result.isShared ? {
                            type: 'tooltip',
                            name: shareWithTitle,
                            value: result.sharedWithCount,
                            data: result.sharedWithCount > 1 && sharedWithList
                        } : {
                            type: 'tooltip',
                            value: -Infinity,
                            name: '--'
                        },
                        capitalize(result.createdByUserName),
                    ];
                }

                return [
                    {
                        type: 'icon',
                        value: result.name,
                        icon: 'project-icon',
                        width: 30,
                        height: 36,
                        showFooter: !isImpersonateMode,
                    },
                    '--',
                    '--',
                    {
                        type: 'date',
                        name: isNaN(year) ? '--/--/----' : `${month}/${day}/${year}`,
                        value: isNaN(year) ? '--/--/----' : `${month}/${day}/${year}`
                    },
                    {
                        type: 'size',
                        name: `${size.value.toFixed(1)} ${size.units}`,
                        value: size.initialSize,
                    },
                    capitalize(result.createdByUserName),
                    {
                        type: 'overlay project',
                        value: result,
                        search: `?id=${result.id}`
                    }
                ];
            }
        })
    })
);
