import { useCallback, useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { IconClose } from "../../icons";
import Modal from "../Modal/Modal";
import Loader from "../new/Loader/Loader";
import Slider from "../Slider/Slider";
import "./PopupCarousel.styles.scss";

interface iProps {
    isShown: boolean;
    onClose: () => void;
    initialSlideIndex?: number;
    additionalSlidesCount?: number;
}
const PopupCarousel: React.FC<iProps> = ({
    isShown,
    onClose,
    initialSlideIndex = 0,
    additionalSlidesCount,
    children,
}) => {
    const [isDraggable, setIsDraggable] = useState(true);
    const currentSlideRef = useRef(initialSlideIndex);

    const handleAfterChange = useCallback(
        (currentSlideIndex: number) => {
            currentSlideRef.current = currentSlideIndex;
            if (!additionalSlidesCount) return;

            if (currentSlideIndex > additionalSlidesCount - 1 && !isDraggable) {
                setIsDraggable(true);
            }

            if (currentSlideIndex === additionalSlidesCount - 1) {
                setIsDraggable(false);
            }
        },
        [additionalSlidesCount, isDraggable],
    );

    const MODAL_TIMEOUT = 600;

    useEffect(() => {
        if ( isShown ) {
            setIsLoading(true);
            setIsDraggable(initialSlideIndex > 0);

            setTimeout(() => {
                setIsLoading(false);
            }, MODAL_TIMEOUT);
        }
    }, [isShown, initialSlideIndex]);

    const handleClose = () => {
            setIsDraggable(true);
            onClose();
    }

    const [isLoading, setIsLoading] = useState(true);
    const popupRef = useRef<HTMLDivElement>(null);

    return (
        <Modal
            isOpened={isShown}
            close={handleClose}
            className="is-content-full"
            closeOnEsc={true}
            hasInner={false}
            closeOnOutsideClick={false}
        >
            <div className="PopupCarousel__close" onClick={handleClose}>
                <IconClose width={18} height={18} />
            </div>

            {isLoading && <Loader className="is-no-bg" />}

            <CSSTransition
                in={!isLoading}
                timeout={MODAL_TIMEOUT}
                unmountOnExit
                classNames="FadePopupAnim"
                nodeRef={popupRef}
            >
                <div className="PopupCarousel__wrapper" ref={popupRef}>
                    {!isLoading && (
                        <Slider
                            autoFocus
                            sliderConfig={{
                                initialSlide: initialSlideIndex,
                                className: "is-centered",
                                touchMove: isDraggable,
                                draggable: isDraggable,
                                afterChange: handleAfterChange,
                            }}
                        >
                            {children}
                        </Slider>
                    )}

                </div>
            </CSSTransition>

        </Modal>
    );
};

export default PopupCarousel;
