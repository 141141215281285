import { useData } from "../hooks/useData";
import { ChainMenuData, iChainMenuData } from "../models/ChainMenuData";
import Http from "../fetch";
import { API__MenuItemsVIewModel } from "../apiSchema";


async function getMenu(chainId: number): Promise<iChainMenuData> {
    const data: API__MenuItemsVIewModel = await Http.get('get chain menu data', { chainId });
    return new ChainMenuData({ apiModel: data });
}

export default function useMenu(chainId: number) {
    return useData(new ChainMenuData(), getMenu, chainId);
}
