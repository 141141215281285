import { FC } from "react";
import cn from "classnames";
import TruncatedText from "react-dotdotdot";

interface iProps {
    lines?: number;
    isWrap?: boolean;
}
const TableCellValue: FC<iProps> = ({ lines = 2, isWrap, children }) => {
    const classnames = cn("TableCellValue", isWrap && "is-wrap");

    if ( isWrap ) {
        return <div className={classnames}>{children}</div>
    }

    return (
        /* @ts-ignore */
        <TruncatedText className={classnames} clamp={lines}>
            {children}
        </TruncatedText>
    );
};

export default TableCellValue;
