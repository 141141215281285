import { REPORT_POPUP } from '../../actions/actionsNamesList';

const { OPEN, CLOSE, SET_DATA } = REPORT_POPUP;
const defaultState = {
    // isOpened: false,
    isOpened: false,
    reportId: null,
};

export default function reportPopupData(state = defaultState, { type, data }) {
    switch (type) {
        case OPEN: {
            return {
                ...state,
                isOpened: true,
            }
        }
        case CLOSE: {
            return {
                ...state,
                isOpened: false,
            }
        }
        case SET_DATA: {
            return {
                ...state,
                ...data,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}
