import { FC } from "react";
import classNames from "classnames";
import "./Toggle.styles.scss";

interface iProps {
    className?: string;
    isDisabled?: boolean;
    onChange: () => void;
    isChecked: boolean;
}

const Toggle: FC<iProps> = (
    { 
        className,
        isDisabled,
        onChange,
        isChecked,
    }
) => {
	return (
		<div
			className={
				classNames([
					'Toggle',
					isDisabled && 'Toggle--disabled',
					className
				])}
		>
			<div className="Toggle__input">
				<input
					className="Toggle__checkbox"
					type="checkbox"
					disabled={isDisabled}
                    onChange={onChange}
                    checked={isChecked}
				/>
				<div className="Toggle__track" />
				<div className="Toggle__thumb" />
			</div>
		</div>
	);
}

export default Toggle;
