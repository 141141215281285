import {
    HEADER_USER_ACTIONS_MENU_CLOSE,
    HEADER_USER_ACTIONS_MENU_OPEN,
    HEADER_USER_ACTIONS_MENU_TOGGLE,
    HEADER_DOCUMENTS_MENU_CLOSE,
    HEADER_DOCUMENTS_MENU_OPEN,
    HEADER_DOCUMENTS_MENU_TOGGLE,
    HEADER__OPEN_SNAP_ASSISTANT_POPUP,
    HEADER__CLOSE_SNAP_ASSISTANT_POPUP,
    HEADER__OPEN_FOOD_SCAPE_POPUP,
    HEADER__CLOSE_FOOD_SCAPE_POPUP,
    HEADER_MENU_CLOSE,
    HEADER_MENU_OPEN,
    HEADER_MENU_TOGGLE,
    SEARCH_POPUP_CLOSE,
    SEARCH_POPUP_OPEN,
    SEARCH_POPUP_TOGGLE,
    SET_SEARCH_STRING,
    CLEAR_SEARCH_RESULTS_STRING,
    CLEAR_SEARCH_RESULTS_DATA,
    SET_AVAILABLE_SEARCH_TABS,
    TOGGLE_FILTER_POPUP_OPENED,
    PLACES_SAVE_SEARCH_STRING,
    DOCUMENTS_SAVE_SEARCH_STRING,
    PAGE_NOTIFICATION_OPEN,
    PAGE_NOTIFICATION_CLOSE,
    HIDE_LOGOUT_POPUP,
    SHOW_LOGOUT_POPUP,
    HIDE_HELP_POPUP,
    SHOW_HELP_POPUP,
    HIDE_SNAP_ASSISTANT_REMINDER,
    SHOW_SNAP_ASSISTANT_REMINDER,
    OPEN_CUSTOM_RESEARCH_POPUP,
    CLOSE_CUSTOM_RESEARCH_POPUP,
    OPEN_IMPERSONATE_POPUP,
    CLOSE_IMPERSONATE_POPUP,
    OPEN_AGENCY_MODE_POPUP,
    CLOSE_AGENCY_MODE_POPUP,
} from '../actions/actionsNamesList';

function getDefaultPageDataState() {
    const { ads = [] } = window.pageData;
    const UA = navigator.userAgent;
    const isFoodScapePopupOpened = ads.some(
        ad => ad === 'foodScape2019'
    );
    const shouldRenderLogoutPopup = ads.some(ad => ad === 'firstLogout');
    const shouldRenderReminder = ads.some(ad => ad === 'addExtension') && !ads.some(ad => ad === 'snapAssistant');
    const isImpersonateMode = window.pageData.originalData.mode === 'impersonated';
    const isAgencyModePopupBeenClosed = localStorage.getItem('isAgencyModePopupBeenClosed') === 'true';

    return {
        isUserActionsMenuOpened: false,
        isDocumentsMenuOpened: false,
        isHeaderMenuOpened: false,
        isSearchPopupOpened: false,
        searchString: '',
        searchResultsExist: {
            foodProfilesExists: true,
            placesExists: true,
            documentsExists: true
        },
        filterPopupOpened: true,
        showNotification: false,
        isLogoutPopupOpened: false,
        isHelpPopupOpened: false,
        isSnapAssistantReminderShown: false,
        shouldRenderLogoutPopup,
        shouldRenderReminder,
        isFoodScapePopupOpened,
        isCustomResearchPopupOpened: false,
        isImpersonatePopupOpened: false,
        isAgencyModePopupOpened: !isAgencyModePopupBeenClosed,
        isAgencyModePopupBeenClosed,
        isImpersonateMode,
    };
}

export default function pageStatus(state = getDefaultPageDataState(), action) {
    switch (action.type) {
        case HEADER__OPEN_FOOD_SCAPE_POPUP: {
            return {
                ...state,
                isFoodScapePopupOpened: true,
            };
        }
        case HEADER__CLOSE_FOOD_SCAPE_POPUP: {
            return {
                ...state,
                isFoodScapePopupOpened: false,
            };
        }
        case HEADER_MENU_OPEN: {
            return {
                ...state,
                isDocumentsMenuOpened: false,
                isUserActionsMenuOpened: false,
                isHeaderMenuOpened: true
            };
        }
        case HEADER_MENU_CLOSE: {
            return {
                ...state,
                isHeaderMenuOpened: false
            };
        }
        case PAGE_NOTIFICATION_OPEN: {
            return {
                ...state,
                showNotification: true
            };
        }
        case PAGE_NOTIFICATION_CLOSE: {
            return {
                ...state,
                showNotification: false
            };
        }
        case HEADER_MENU_TOGGLE: {
            return {
                ...state,
                isDocumentsMenuOpened: false,
                isUserActionsMenuOpened: false,
                isHeaderMenuOpened: !state.isHeaderMenuOpened
            };
        }
        case HEADER_USER_ACTIONS_MENU_OPEN: {
            return {
                ...state,
                isDocumentsMenuOpened: false,
                isHeaderMenuOpened: false,
                isUserActionsMenuOpened: true
            };
        }
        case HEADER_USER_ACTIONS_MENU_CLOSE: {
            return {
                ...state,
                isUserActionsMenuOpened: false
            };
        }
        case HEADER_USER_ACTIONS_MENU_TOGGLE: {
            return {
                ...state,
                isDocumentsMenuOpened: false,
                isHeaderMenuOpened: false,
                isUserActionsMenuOpened: !state.isUserActionsMenuOpened
            };
        }
        case HEADER_DOCUMENTS_MENU_OPEN: {
            return {
                ...state,
                isUserActionsMenuOpened: false,
                isHeaderMenuOpened: false,
                isDocumentsMenuOpened: true
            };
        }
        case HEADER_DOCUMENTS_MENU_CLOSE: {
            return {
                ...state,
                isDocumentsMenuOpened: false
            };
        }
        case HEADER_DOCUMENTS_MENU_TOGGLE: {
            return {
                ...state,
                isUserActionsMenuOpened: false,
                isHeaderMenuOpened: false,
                isDocumentsMenuOpened: !state.isDocumentsMenuOpened
            };
        }
        case SEARCH_POPUP_OPEN: {
            return {
                ...state,
                isSearchPopupOpened: true
            };
        }
        case CLEAR_SEARCH_RESULTS_DATA: {
            return {
                ...state,
                isSearchPopupOpened: false
            };
        }
        case SEARCH_POPUP_CLOSE: {
            return {
                ...state,
                isSearchPopupOpened: false
            };
        }
        case SEARCH_POPUP_TOGGLE: {
            return {
                ...state,
                isSearchPopupOpened: !state.isSearchPopupOpened
            };
        }
        case SET_SEARCH_STRING:
        case PLACES_SAVE_SEARCH_STRING:
        case DOCUMENTS_SAVE_SEARCH_STRING: {
            return {
                ...state,
                searchString: action.query
            };
        }
        case CLEAR_SEARCH_RESULTS_STRING: {
            return {
                ...state,
                searchString: ''
            };
        }
        case SET_AVAILABLE_SEARCH_TABS: {
            return {
                ...state,
                searchResultsExist: action.data
            };
        }
        case TOGGLE_FILTER_POPUP_OPENED: {
            return {
                ...state,
                filterPopupOpened: !state.filterPopupOpened
            };
        }
        case SHOW_LOGOUT_POPUP: {
            return {
                ...state,
                isLogoutPopupOpened: true,
            };
        }
        case HIDE_LOGOUT_POPUP: {
            return {
                ...state,
                isLogoutPopupOpened: false,
            };
        }
        case SHOW_HELP_POPUP: {
            return {
                ...state,
                isHelpPopupOpened: true,
            };
        }
        case HIDE_HELP_POPUP: {
            return {
                ...state,
                isHelpPopupOpened: false,
            };
        }
        case SHOW_SNAP_ASSISTANT_REMINDER: {
            return {
                ...state,
                isSnapAssistantReminderShown: true,
            };
        }
        case HIDE_SNAP_ASSISTANT_REMINDER: {
            return {
                ...state,
                isSnapAssistantReminderShown: false,
            };
        }
        case OPEN_CUSTOM_RESEARCH_POPUP : {
            return {
                ...state,
                isCustomResearchPopupOpened: true,
            };
        }
        case CLOSE_CUSTOM_RESEARCH_POPUP : {
            return {
                ...state,
                isCustomResearchPopupOpened: false,
            };
        }
        case OPEN_IMPERSONATE_POPUP : {
            return {
                ...state,
                isImpersonatePopupOpened: true,
            };
        }
        case CLOSE_IMPERSONATE_POPUP : {
            return {
                ...state,
                isImpersonatePopupOpened: false,
            };
        }
        case OPEN_AGENCY_MODE_POPUP: {
            return {
                ...state,
                isAgencyModePopupOpened: true,
            };
        }
        case CLOSE_AGENCY_MODE_POPUP: {
            if (!state.isAgencyModePopupBeenClosed) {
                localStorage.setItem('isAgencyModePopupBeenClosed', 'true');
            }
            return {
                ...state,
                isAgencyModePopupOpened: false,
                isAgencyModePopupBeenClosed: true,
            };
        }
        default: {
            return state;
        }
    }
}
