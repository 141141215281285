import { Component } from 'react';
import { connect } from 'react-redux';
import Popup from '../../components/Popup/Popup';
import { CLOSE_IMPERSONATE_POPUP } from '../../actions/actionsNamesList';
import http from '../../core/fetch';
import SelectCompany from './SelectCompany';
import { companiesSelector } from '../DocumentsLibraryPopups/documentsLibraryPopups.selectors';
import ApiConfig from '../../core/apiConfig';
import './ImpersonatePopup.styles.scss';
import SelectUser from './SelectUser';
import serializeParams from '../../core/serializeParams';

class ImpersonatePopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentView: 1,
            selectedCompanyId: null,
            selectedUserId: null,
            users: [],
            isLoading: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const isCompanyChanged = this.state.selectedCompanyId !== prevState.selectedCompanyId;
        const isCompanySelected = !!this.state.selectedCompanyId;
        const isImpersonateStarted = this.state.isLoading === true && prevState.isLoading === false;
        const isClosing = !this.props.isOpened && prevProps.isOpened;

        if (isCompanyChanged && isCompanySelected) {
            http.get('get users with status', { companyId: this.state.selectedCompanyId })
                .then((data) => {
                    this.setState({ users: data });
                });
        }

        if (isImpersonateStarted) {
            window.location.href = `${ApiConfig['impersonate']}?${serializeParams({
                userId: this.state.selectedUserId,
                // userId: '9B48E879-87D6-E711-813D-0256BFEE35ED',
                companyId: this.state.selectedCompanyId,
                // companyId: 1,
            })}`;
            // http.get('impersonate', {
            //     // userId: this.state.selectedUserId,
            //     userId: '9B48E879-87D6-E711-813D-0256BFEE35ED',
            //     // companyId: this.state.selectedCompanyId,
            //     companyId: 1,
            // });
        }

        if (isClosing) {
            this.setState({
                currentView: 1,
                selectedCompanyId: null,
                selectedUserId: null,
                users: [],
                isLoading: false,
            });
        }
    }

    renderCompaniesView() {
        const { companies } = this.props;
        const { selectedCompanyId } = this.state;
        companies.sort((a, b) => a.title < b.title ? -1 : 1);
        return (
            <SelectCompany
                options={
                    companies
                        .map(({ value, title, isActive }) => ({ title, id: value, isActive }))
                        .filter(({ id, isActive }) => id !== 1 && isActive)
                }
                value={selectedCompanyId}
                setValue={id => this.setState({ selectedCompanyId: id })}
            />
        )
    }

    renderUsersView() {
        const { users, selectedUserId } = this.state;

        return (
            <SelectUser
                contacts={users.map(({ name: title, id, status }) => ({ title, id, status }))}
                value={selectedUserId}
                setValue={(id) => this.setState({ selectedUserId: id })}
            />
        );
    }

    render() {
        const { isOpened, close } = this.props;
        const { currentView, selectedCompanyId, selectedUserId } = this.state;
        return (
            <Popup isOpened={isOpened} onClose={close}>
                <div className="ImpersonatePopup">
                    <div className="ImpersonatePopup__header">
                        {currentView === 1 && 'Choose your client:'}
                        {currentView === 2 && 'Choose an account:'}
                    </div>
                    <div className="ImpersonatePopup__body">
                        {currentView === 1 && this.renderCompaniesView()}
                        {currentView === 2 && this.renderUsersView()}
                    </div>
                    <div className="ImpersonatePopup__footer">
                        {currentView === 1 && (
                            <button
                                onClick={close}
                                className="popup__link popup__link--comfortaa"
                            >
                                cancel
                            </button>
                        )}
                        {currentView === 2 && (
                            <button
                                className="popup__link popup__link--comfortaa"
                                onClick={() => this.setState({ currentView: 1 })}
                            >
                                back
                            </button>
                        )}
                        {currentView === 1 && (
                            <button
                                className="popup__btn popup__btn--xmd"
                                disabled={!selectedCompanyId}
                                onClick={() => this.setState({ currentView: 2 })}
                            >
                                NEXT
                            </button>
                        )}
                        {currentView === 2 && (
                            <button
                                className="popup__btn popup__btn--xmd"
                                disabled={!selectedUserId}
                                onClick={() => this.setState({ isLoading: true })}
                            >
                                SIGN IN
                            </button>
                        )}
                    </div>
                </div>
            </Popup>
        );
    }
}

const mapState = state => ({
    isOpened: state.pageStatus.isImpersonatePopupOpened,
    companies: companiesSelector(state),
});

const mapDispatch = dispatch => ({
    close: () => dispatch({ type: CLOSE_IMPERSONATE_POPUP }),
});

export default connect(mapState, mapDispatch)(ImpersonatePopup);
