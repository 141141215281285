import { CSSProperties, FC, useContext, useEffect, useMemo, useRef } from "react";
import { TableContext } from "@components/new/Table/TableContext";
import cn from "classnames";
import TableCell from "@components/new/Table/TableCell";

const WhoOffersTableRow: FC<{
    index: number;
    style: CSSProperties;
    data: {
        styles: CSSProperties;
        setSize: (index: number, size: number) => void;
    };
}> = ({
    index,
    style,
    data,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const { config } = useContext(TableContext);

    useEffect(() => {
        if (ref.current) {
            data.setSize(index, ref.current.getBoundingClientRect().height);
        }
    }, [data.setSize, index]);

    return (
        <div
            className="TableNew__row"
            style={{
                ...style,
                ...data.styles,
            }}
            data-testid={`textTableRow-${index}`}
        >
            <div
                ref={ref}
                className="WhoOffersTableRow"
            >
                {config.map((item, colIndex) => {
                    const { Value, ...column } = item;
                    return (
                        <TableCell
                            key={colIndex}
                            columnData={{ ...column, Value }}
                            props={{ head: false }}
                            testId={`textTableBodyCell-${index}-${colIndex}`}
                        >
                            <Value index={index}/>
                        </TableCell>
                    );
                })}
            </div>
        </div>
    );
};

export default WhoOffersTableRow;