import { FC, useMemo } from "react";
import cn from "classnames";
import {
    getMenuPartType,
    MenuPartsEnum,
} from "../../../../../../core/models/ChainMenuData";
import { iMenuDetailsItem } from "../../../../../../core/models/MenuDetailsItem";
import MenuDetailsButton from "../MenuDetailsButton/MenuDetailsButton";
import "./ButtonGrid.styles.scss";

interface iProps {
    selectedButton: string;
    handleSelectButton: (id: string) => void;
    data: iMenuDetailsItem[];
}

const ButtonGrid: FC<iProps> = ({ data, selectedButton, handleSelectButton }) => {
    const totalFoodItemsCount = useMemo(
        () =>
            data
                .filter(item => getMenuPartType(item.title) !== MenuPartsEnum.Beverage)
                .reduce((acc, current) => acc + current.itemCount, 0),
        [data],
    );

    const handleSelect = (id: string, totalItems: number) => {
        if (!totalItems) return;

        handleSelectButton(id);
    }

    return (
        <div className="ButtonGrid">
            <div className="ButtonGrid__button is-head">
                <div className="ButtonGrid__header">
                    <span className="ButtonGrid__header-value">
                        {totalFoodItemsCount}
                    </span>
                    food total
                </div>
            </div>

            {data.map(item => {
                const id = item.title;
                const classNames = (id: string) => {
                    return cn(
                        "ButtonGrid__button",
                        id === MenuPartsEnum.Beverage ? "is-large" : "is-small"
                    );
                };

                const classNamesButton = (id: string) => {
                    return cn(id === MenuPartsEnum.Beverage ? "is-compact" : "");
                }

                return (
                    <div
                        key={item.title}
                        className={classNames(id)}
                        onClick={() => handleSelect(id, item.itemCount)}
                    >
                        <MenuDetailsButton
                            totalItems={item.itemCount}
                            label={item.title.toLowerCase()}
                            isSelected={selectedButton === id}
                            isDisabled={!item.itemCount}
                            className={classNamesButton(id)}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default ButtonGrid;
