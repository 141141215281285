import { useDebouncedCallback } from "@hooks/useDebouncedCallback";
import { useCallback, useEffect, useMemo, useState } from "react";

const useLazy = (take: number, totalItemsCount: number) => {
    const [visibleItemsCount, setVisibleItemsCount] = useState(0);
    const hasNextItems = useMemo(() => visibleItemsCount < totalItemsCount, [totalItemsCount, visibleItemsCount]);

    const showNextItems = useCallback((visibility: boolean) => {
        if (visibility && hasNextItems) {
            setVisibleItemsCount(prevState => Math.min(totalItemsCount, prevState + take));
        }
    }, [hasNextItems, take, totalItemsCount]);

    const showNextItemsDebounced = useDebouncedCallback((visibility: boolean) => showNextItems(visibility), 250);

    useEffect(() => {
        setVisibleItemsCount(take);
    }, [take]);

    return {
        visibleItemsCount,
        showNextItems: showNextItemsDebounced,
        hasNextItems
    }
}

export default useLazy;