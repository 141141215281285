import { PureComponent } from 'react';
import PT from 'prop-types';
import Popup from '../../components/Popup/Popup';

class ConfirmPopup extends PureComponent {
    constructor(props) {
        super(props);

        this.escKeyAction = this.escKeyAction.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keyup', this.escKeyAction, false);
        document.querySelector('body').style.overflow = 'hidden';
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.escKeyAction, false);
        document.querySelector('body').style.overflow = 'visible';
    }

    escKeyAction(e) {
        if (e.which === 27) {
            if (this.props.isOpened) {
                this.props.onCancel();
            }
        }
    }

    render() {
        return (
            <Popup
                isOpened={this.props.isOpened}
                className="popup popup-confirm popup--fade-in"
                onClose={this.props.onCancel}
            >
                <div className="popup__inner popup__inner--xs popup-red">
                    <div className="popup__header">
                        <div className="popup__header-title">
                            Delete team
                        </div>
                    </div>
                    <div className="popup__container">
                        <div className="popup__text">
                            {this.props.text}
                        </div>
                        <div className="popup__footer">
                            <div className="popup__right-side">
                                <button
                                    className="popup__link popup__link--light"
                                    onClick={this.props.onCancel}
                                >
                                    cancel
                                </button>
                                <button
                                    className="popup__btn popup__btn--white popup__btn--md"
                                    onClick={this.props.onConfirm}
                                >
                                    DELETE
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}

ConfirmPopup.propTypes = {
    onConfirm: PT.func,
    onCancel: PT.func,
    text: PT.string,
    isOpened: PT.bool,
};

export default ConfirmPopup;
