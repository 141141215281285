import { FCX } from "@datassential/platform-ui-lib";
import { useDispatch, useSelector } from "react-redux";
import { setAvailableSearchTabs } from "@actions/page";
import { availableSearchTabsSelector } from "@selectors/page";
import { useCallback, useEffect, useState } from "react";
import { routeNames } from "@core/routeNames";
import { useHistory, useLocation } from "react-router-dom";
import Http from "@core/fetch";
import useQueryParam from "@hooks/useQueryParam";

const SearchTabs: FCX = () => {
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const history = useHistory();
    const searchQuery = useQueryParam("q");
    const location = useLocation();

    const storeAvailableSearchTabs = (data: any) => dispatch(setAvailableSearchTabs(data));

    const searchTabsData = useSelector(availableSearchTabsSelector);

    useEffect(() => {
        if (!isLoading) {
            const { foodProfilesExists, placesExists, documentsExists} = searchTabsData;

            if (foodProfilesExists) {
                    history.replace(`/food${location.search}`);
            } else if (placesExists) {
                history.replace(`/places${location.search}`);
            } else if (documentsExists) {
                history.replace(`/documents${location.search}`);
            } else {
                    history.replace(routeNames.error);
            }
        }
    }, [isLoading, searchTabsData]);

    useEffect(() => {
        if (!searchQuery.trim()) {
            history.push(routeNames.error);
        } else {
            Http.get('active tabs', { word: searchQuery })
                .then((data) => {
                    storeAvailableSearchTabs(data);
                    setIsLoading(false);
                });
        }
    }, [searchQuery]);

    return null;
}

export default SearchTabs;