import { FC } from "react";
import {
    IconFileAif,
    IconFileAifBw,
    IconFileAifInner,
    IconFileArc,
    IconFileArcBw,
    IconFileArcInner,
    IconFileAvi,
    IconFileAviBw,
    IconFileAviInner,
    IconFileBmp,
    IconFileBmpBw,
    IconFileBmpInner,
    IconFileCsv,
    IconFileCsvBw,
    IconFileCsvInner,
    IconFileDoc,
    IconFileDocBw,
    IconFileDocInner,
    IconFileFvl,
    IconFileFvlBw,
    IconFileFvlInner,
    IconFileGif,
    IconFileGifBw,
    IconFileGifInner,
    IconFileJpg,
    IconFileJpgBw,
    IconFileJpgInner,
    IconFileMov,
    IconFileMovBw,
    IconFileMovInner,
    IconFileMp3,
    IconFileMp3Bw,
    IconFileMp3Inner,
    IconFileMp4,
    IconFileMp4Bw,
    IconFileMp4Inner,
    IconFileOther,
    IconFileOtherBw,
    IconFileOtherInner,
    IconFilePdf,
    IconFilePdfBw,
    IconFilePdfInner,
    IconFilePng,
    IconFilePngBw,
    IconFilePngInner,
    IconFilePpt,
    IconFilePptBw,
    IconFilePptInner,
    IconFileRtf,
    IconFileRtfBw,
    IconFileRtfInner,
    IconFileTxt,
    IconFileTxtBw,
    IconFileTxtInner,
    IconFileWma,
    IconFileWmaBw,
    IconFileWmaInner,
    IconFileWmf,
    IconFileWmfBw,
    IconFileWmfInner,
    IconFileXls,
    IconFileXlsBw,
    IconFileXlsInner,
    IconFileZip,
    IconFileZipBw,
    IconFileZipInner,
} from "@icons";

interface iProps {
    type: string;
    classNames?: string;
}

const getIcon = (type: string) => {
    switch (type) {
        case "aif":
            return <IconFileAif />;
        case "aif-bw":
            return <IconFileAifBw />;
        case "aif-inner":
            return <IconFileAifInner />;
        case "arc":
            return <IconFileArc />;
        case "arc-bw":
            return <IconFileArcBw />;
        case "arc-inner":
            return <IconFileArcInner />;
        case "avi":
            return <IconFileAvi />;
        case "avi-bw":
            return <IconFileAviBw />;
        case "avi-inner":
            return <IconFileAviInner />;
        case "bmp":
            return <IconFileBmp />;
        case "bmp-bw":
            return <IconFileBmpBw />;
        case "bmp-inner":
            return <IconFileBmpInner />;
        case "csv":
            return <IconFileCsv />;
        case "csv-bw":
            return <IconFileCsvBw />;
        case "csv-inner":
            return <IconFileCsvInner />;
        case "doc":
            return <IconFileDoc />;
        case "doc-bw":
            return <IconFileDocBw />;
        case "doc-inner":
            return <IconFileDocInner />;
        case "fvl":
            return <IconFileFvl />;
        case "fvl-bw":
            return <IconFileFvlBw />;
        case "fvl-inner":
            return <IconFileFvlInner />;
        case "gif":
            return <IconFileGif />;
        case "gif-bw":
            return <IconFileGifBw />;
        case "gif-inner":
            return <IconFileGifInner />;
        case "jpg":
            return <IconFileJpg />;
        case "jpg-bw":
            return <IconFileJpgBw />;
        case "jpg-inner":
            return <IconFileJpgInner />;
        case "mov":
            return <IconFileMov />;
        case "mov-bw":
            return <IconFileMovBw />;
        case "mov-inner":
            return <IconFileMovInner />;
        case "mp3":
            return <IconFileMp3 />;
        case "mp3-bw":
            return <IconFileMp3Bw />;
        case "mp3-inner":
            return <IconFileMp3Inner />;
        case "mp4":
            return <IconFileMp4 />;
        case "mp4-bw":
            return <IconFileMp4Bw />;
        case "mp4-inner":
            return <IconFileMp4Inner />;
        case "other-bw":
            return <IconFileOtherBw />;
        case "other-inner":
            return <IconFileOtherInner />;
        case "pdf":
            return <IconFilePdf />;
        case "pdf-bw":
            return <IconFilePdfBw />;
        case "pdf-inner":
            return <IconFilePdfInner />;
        case "png":
            return <IconFilePng />;
        case "png-bw":
            return <IconFilePngBw />;
        case "png-inner":
            return <IconFilePngInner />;
        case "ppt":
            return <IconFilePpt />;
        case "ppt-bw":
            return <IconFilePptBw />;
        case "ppt-inner":
            return <IconFilePptInner />;
        case "rtf":
            return <IconFileRtf />;
        case "rtf-bw":
            return <IconFileRtfBw />;
        case "rtf-inner":
            return <IconFileRtfInner />;
        case "txt":
            return <IconFileTxt />;
        case "txt-bw":
            return <IconFileTxtBw />;
        case "txt-inner":
            return <IconFileTxtInner />;
        case "wma":
            return <IconFileWma />;
        case "wma-bw":
            return <IconFileWmaBw />;
        case "wma-inner":
            return <IconFileWmaInner />;
        case "wmf":
            return <IconFileWmf />;
        case "wmf-bw":
            return <IconFileWmfBw />;
        case "wmf-inner":
            return <IconFileWmfInner />;
        case "xls":
            return <IconFileXls />;
        case "xls-bw":
            return <IconFileXlsBw />;
        case "xls-inner":
            return <IconFileXlsInner />;
        case "zip":
            return <IconFileZip />;
        case "zip-bw":
            return <IconFileZipBw />;
        case "zip-inner":
            return <IconFileZipInner />;
        default:
            return <IconFileOther />;
    }
};
export const FileExtention: FC<iProps> = ({ classNames = "", type = "other" }) => {
    return <div className={`FileExtention ${classNames}`}>{getIcon(type)}</div>;
};
