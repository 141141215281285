import { API__ReportProPublicationClient } from "@apiSchema";

export interface iReportProPublication {
    id: number;
    title: string;
    description: string;
    image: string;
    thumbnail: string;
    featuredImage?: string;
    createdDate: string;
    numberOfPages: number;
    minutesToRead: number;
    hasAccess: boolean;
    isLiked: boolean;
    isAddedToReadingList: boolean;
    buttonCategoryId: number;
    titleCategoryId: number;
    publicationId: number;
    publicationTitle: string;
}

type iPublicationData = {
    subModel?: Partial<iReportProPublication>;
    apiModel?: API__ReportProPublicationClient;
};

export class ReportProPublication implements iReportProPublication {
    id = ReportProPublication.defaultData.id;
    title = ReportProPublication.defaultData.title;
    description = ReportProPublication.defaultData.description;
    image = ReportProPublication.defaultData.image;
    thumbnail = ReportProPublication.defaultData.thumbnail;
    featuredImage = ReportProPublication.defaultData.featuredImage;
    createdDate = ReportProPublication.defaultData.createdDate;
    numberOfPages = ReportProPublication.defaultData.numberOfPages;
    minutesToRead = ReportProPublication.defaultData.minutesToRead;
    hasAccess = ReportProPublication.defaultData.hasAccess;
    isLiked = ReportProPublication.defaultData.isLiked;
    isAddedToReadingList = ReportProPublication.defaultData.isAddedToReadingList;
    buttonCategoryId = ReportProPublication.defaultData.buttonCategoryId;
    titleCategoryId = ReportProPublication.defaultData.titleCategoryId;
    publicationId = ReportProPublication.defaultData.publicationId;
    publicationTitle = ReportProPublication.defaultData.publicationTitle;

    static defaultData: iReportProPublication = {
        id: 0,
        title: '',
        description: '',
        image: '',
        thumbnail: '',
        featuredImage: '',
        createdDate: '',
        numberOfPages: 0,
        minutesToRead: 0,
        hasAccess: true,
        isLiked: false,
        isAddedToReadingList: false,
        buttonCategoryId: 0,
        titleCategoryId: 0,
        publicationId: 0,
        publicationTitle: '',
    };

    constructor(data?: iPublicationData) {
        if (data) {
            const { apiModel, subModel } = data;
            if (apiModel) {
                this.mapFromApi(apiModel);
            }
            else if (subModel) {
                this.mapFromClient(subModel);
            }
        }
    }

    private setData (model: iReportProPublication) {
        ({
            id: this.id,
            title: this.title,
            description: this.description,
            image: this.image,
            thumbnail: this.thumbnail,
            featuredImage: this.featuredImage,
            createdDate: this.createdDate,
            numberOfPages: this.numberOfPages,
            minutesToRead: this.minutesToRead,
            hasAccess: this.hasAccess,
            isLiked: this.isLiked,
            isAddedToReadingList: this.isAddedToReadingList,
            buttonCategoryId: this.buttonCategoryId,
            titleCategoryId: this.titleCategoryId,
            publicationId: this.publicationId,
            publicationTitle: this.publicationTitle,
        } = model);
    }

    private mapFromApi (apiModel: API__ReportProPublicationClient) {
        const {
            id = ReportProPublication.defaultData.id,
            imageUrl,
            bigImageUrl,
            featuredContentImageUrl,
            createDate,
            numberOfPages = ReportProPublication.defaultData.numberOfPages,
            minutesToRead = ReportProPublication.defaultData.minutesToRead,
            hasAccess = ReportProPublication.defaultData.hasAccess,
            isLiked = ReportProPublication.defaultData.isLiked,
            isAddedToReadingList = ReportProPublication.defaultData.isAddedToReadingList,
            publicationType,
            ...rest
        } = apiModel;

        this.setData({
            ...rest,
            id,
            numberOfPages,
            minutesToRead,
            hasAccess,
            isAddedToReadingList,
            isLiked,
            thumbnail: imageUrl,
            image: bigImageUrl,
            featuredImage: featuredContentImageUrl,
            createdDate: createDate,
        });
    }

    private mapFromClient (clientModel: Partial<iReportProPublication>) {
        this.setData({
            ...ReportProPublication.defaultData,
            ...clientModel,
        });
    }
}