import http from '../../core/fetch';
import {
    MY_DOCUMENTS__STORE_DATA,
    MY_DOCUMENTS__CLEAR_DATA
} from '../../actions/actionsNamesList';

export function clearData() {
    return { type: MY_DOCUMENTS__CLEAR_DATA };
}

export function storeData(data) {
    return {
        type: MY_DOCUMENTS__STORE_DATA,
        data
    };
}

export function storeSearchQuery(searchQuery) {
    return {
        type: MY_DOCUMENTS__STORE_DATA,
        data: { searchQuery }
    };
}

export function getSearchResults() {
    return (dispatch, getState) => {
        const { searchQuery, customerViewCompanyId, isCustomerViewMode } = getState().myDocumentsData;
        const params = { word: searchQuery };

        if (isCustomerViewMode) {
            params.companyId = customerViewCompanyId;
        }
        return http.get('get documents search', params)
            .then((response) => {
                dispatch(storeData({
                    searchResultsDocuments: response.documents,
                    searchResultsProjects: response.projects
                }));
            });
    };
}

export function getData() {
    return (dispatch, getState) => {
        const { customerViewCompanyId, isCustomerViewMode } = getState().myDocumentsData;
        const params = {
            time: Date.now(),
        };
        if (isCustomerViewMode) {
            params.companyId = customerViewCompanyId;
        }
        return http.get('get projects', params)
            .then((response) => {
                dispatch(storeData({
                    projects: response.projectViewModels,
                    documentTypes: response.currentCompanyProjectsDocumentTypes
                }));
            });
    };
}

export function updateData() {
    return (dispatch, getState) => {
        const { searchQuery } = getState().myDocumentsData;
        if (searchQuery === '') {
            dispatch(getData());
        } else {
            dispatch(getSearchResults());
        }
    };
}
