import Http from "../fetch";
import { useData } from "../hooks/useData";
import { iLocationsData, LocationsData } from "../models/Location";
import { API__ChainPermissionWrapper, API__InsightsWrapper } from "../apiSchema";
import { ID } from "../models";

const getLocations = async (chainId: ID): Promise<iLocationsData> => {
    const response: API__ChainPermissionWrapper<API__InsightsWrapper> = await Http.get("chain competitors data", { chainId });
    return new LocationsData({ apiModel: response });
}

export default function useLocations(chainId: ID) {
    return useData(new LocationsData(), getLocations, chainId);
}
