import http from '../..//core/fetch';
import { PROJECT_CREATE_POPUP } from '../../actions/actionsNamesList';
import { setActiveProjectId } from '..//DocumentsAddPopup/documentsAddPopup.actions';
import {
    getData as updateDocumentsLibraryData,
    updatePageToReload
} from '..//DocumentsLibraryPopups/documentsLibraryPopups.actions';

const {
    OPEN, CLOSE, SET_DATA, SHOW_VALIDATION_ERRORS, HIDE_VALIDATION_ERRORS
} = PROJECT_CREATE_POPUP;
let timer;
let request = {
    abort() {
    },
    then() {
    },
    catch() {
    }
};

export function setData(data) {
    return {
        type: SET_DATA,
        data
    };
}

export function showValidationErrors() {
    return {
        type: SHOW_VALIDATION_ERRORS
    };
}
export function hideValidationErrors() {
    return {
        type: HIDE_VALIDATION_ERRORS
    };
}
export function open() {
    return {
        type: OPEN
    };
}
export function close() {
    return {
        type: CLOSE
    };
}
export function save() {
    return (dispatch, getState) => {
        const { name, description } = getState().projectCreatePopupData;

        http.post('save project', {
            name: name.trim(),
            description
        })
            .then((response) => {
                dispatch(updateDocumentsLibraryData());
                dispatch(updatePageToReload());
                dispatch(setActiveProjectId(response.id));
            });
    };
}
export function setView(type) {
    return (dispatch) => {
        dispatch(setData({ view: type }));
    };
}

export function checkNameUnique(name) {
    return (dispatch) => {
        dispatch(setData({ isNameUniquenessChecking: true }));
        clearTimeout(timer);
        timer = setTimeout(() => {
            request.abort();

            request = http.getXHR(
                'check project unique name',
                { projectName: name.trim() }
            );
            request.then((response) => {
                dispatch(setData({
                    isNameUnique: response,
                    isNameUniquenessChecking: false
                }));
            });
        }, 500);
    };
}

export function resetData() {
    return (dispatch) => {
        dispatch(setData({
            showErrors: false,
            view: 'name',
            name: '',
            isNameUnique: false,
            isNameUniquenessChecking: false,
            description: ''
        }));
    };
}
