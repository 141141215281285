import { useData } from "../hooks/useData";
import Http from "../fetch";
import { iTopChainsData, TopChainsData } from "../models/TopChainsData";
import { API__ChainBaseViewModel } from "../apiSchema";

async function getFireflyChains(): Promise<iTopChainsData> {
    const data: API__ChainBaseViewModel[] = await Http.get('top chains get all');
    return new TopChainsData({ apiModel: data });
}

export default function useFireflyChains() {
    return useData(new TopChainsData(), getFireflyChains);
}
