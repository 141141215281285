import { FC, useEffect, useMemo, useState } from "react";
import qs from "query-string";
import Page from "@containers/Page/Page";
import PageContent from "@containers/PageContent/PageContent";
import Spinner from "@components/new/Spinner/Spinner";
import useDocumentsData from "./useDocumentsData";
import { iDocumentsQueryParams, ViewDocuments } from "./Documents.helpers";
import DocumentsTable from "./DocumentsTable/DocumentsTable";
import DocumentsTiles from "./DocumentsTiiles/DocumentsTiles";
import DocumentsHeader from "./DocumentsHeader/DocumentsHeader";
import { useHistory, useLocation } from "react-router";
import { routeNames } from "@core/routeNames";
import "./Documents.styles.scss";
import "@pages/Documents/Documents.scss";
import SharePopup from "@pages/_shared/SharePopup/SharePopup";
import { useSelector } from "react-redux";
import { shareDataSelector } from "@selectors/documents.selectors";

interface iProps {}

const Documents: FC<iProps> = () => {
    const location = useLocation();
    let history = useHistory();
    const isSharePopupOpen = useSelector(shareDataSelector);
    const params: iDocumentsQueryParams = useMemo(() => qs.parse(location.search), [location.search]);
    const searchQuery = params.q || "";
    const [view, setView] = useState(ViewDocuments.Tiles);
    const {
        isLoading,
        data,
        selectedFilter,
        handleChangeFilter,
        totalDocuments
    } = useDocumentsData({ searchQuery });


    useEffect(() => {
        if (!searchQuery) {
            history.push(routeNames.error);
        }
    }, [history, searchQuery]);

    return (
        /* @ts-ignore */
        <Page>
            <PageContent>
                <div className="Documents">
                    {isLoading && <Spinner modifiers={["full"]} />}

                    {!isLoading && (
                        <>
                            <DocumentsHeader
                                view={view}
                                onChangeView={setView}
                                totalDocuments={totalDocuments}
                                title={searchQuery}
                                onChangeFilter={handleChangeFilter}
                                selectedFilter={selectedFilter}
                            />

                            <div className="Documents__body">
                                {view === ViewDocuments.Tiles && <DocumentsTiles data={data} />}

                                {view === ViewDocuments.Table && <DocumentsTable data={data} searchQuery={searchQuery} />}
                            </div>
                            {/* @ts-ignore */}
                            {isSharePopupOpen && <SharePopup/>}
                        </>
                    )}
                </div>
            </PageContent>
        </Page>
    );
};

export default Documents;
