const PREFIX = 'data-snap';

export enum AnalyticsAction {
    Download = 'Download',
    Search = 'Search',
}

export enum FoodProfileDownloadLocation {
    FoodProfile = 'Food Profile',
    FoodProfileSidebar = 'Food Profile - Sidebar',
    FoodProfileLto = 'Food Profile - Scores Item Report',
}

export enum AnalyticsAttribute {
    Action = 'action',
    DownloadLocation = 'download-location',
    SearchResultTitle = 'search-results-title',
    SearchResultLocation = 'search-results-location',
    ClickTitle = 'click-title'
}

export function getAnalyticsAttributeName (name: string): string {
    return `${PREFIX}-${name}`;
}

export interface AnalyticsData {
    [AnalyticsAttribute.Action]?: AnalyticsAction;
    [AnalyticsAttribute.DownloadLocation]?: FoodProfileDownloadLocation;
    [AnalyticsAttribute.SearchResultTitle]?: string;
    [AnalyticsAttribute.SearchResultLocation]?: string;
    [AnalyticsAttribute.ClickTitle]?: string;
}

export function getAnalyticsComputedData (data: AnalyticsData) {
    const result: any = {};
    (Object.keys(data) as AnalyticsAttribute[]).forEach(
        (key: AnalyticsAttribute) => result[getAnalyticsAttributeName(key)] = data[key]
    );
    return result;
}