import {
    API__NewAllMenuTrendsPenetrationViewModel,
    API__PredictedHaikuData
} from "@apiSchema";

interface iChartPoint {
    value: number;
    label: string;
}

export interface iFoodProfileHaikuData {
    pastData: iChartPoint[];
    currentYearLabel: string;
    currentYearValue: number;
    currentData: number[];
    currentDataPredicted: number[];
    futureData: iChartPoint[];
}

type API_Model1 = API__PredictedHaikuData;
type API_Model2 = API__NewAllMenuTrendsPenetrationViewModel;

interface API_Model {
    predictionModel?: API_Model1;
    penetrationModel?: API_Model2;
}

interface iData {
    apiModel?: API_Model;
}

export class FoodProfileHaiku implements iFoodProfileHaikuData {
    pastData = FoodProfileHaiku.defaultData.pastData;
    currentYearLabel = FoodProfileHaiku.defaultData.currentYearLabel;
    currentYearValue = FoodProfileHaiku.defaultData.currentYearValue;
    currentData = FoodProfileHaiku.defaultData.currentData;
    currentDataPredicted = FoodProfileHaiku.defaultData.currentDataPredicted;
    futureData = FoodProfileHaiku.defaultData.futureData;

    static defaultData: iFoodProfileHaikuData = {
        currentData: [],
        currentDataPredicted: [],
        currentYearLabel: '',
        currentYearValue: 0,
        futureData: [],
        pastData: [],
    };

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private setData(model: iFoodProfileHaikuData) {
        ({
            pastData: this.pastData,
            currentYearLabel: this.currentYearLabel,
            currentYearValue: this.currentYearValue,
            currentData: this.currentData,
            currentDataPredicted: this.currentDataPredicted,
            futureData: this.futureData,
        } = model);
    }

    private mapFromApi(rawData: API_Model) {
        const { predictionModel, penetrationModel } = rawData;

        if (predictionModel && penetrationModel) {
            const {
                yearsPenetrations,
                quartersPenetrations,
            } = penetrationModel;

            const {
                quartersPredictedPenetrations: qPredicted,
                yearsPredictedPenetrations: yPredicted,
            } = predictionModel;

            const currentYearValue = quartersPenetrations[quartersPenetrations.length - 1].value;

            this.setData({
                pastData: yearsPenetrations.map(i => ({
                    label: i.key,
                    value: i.value,
                })),
                futureData: yPredicted.map(i => ({
                    label: i.key,
                    value: i.value,
                })),
                currentYearLabel: (quartersPenetrations[0]?.key || '').substring(0, 4),
                currentYearValue,
                currentData: quartersPenetrations.map(i => i.value),
                currentDataPredicted: qPredicted.map(i => i.value),
            });
        }

    }
}