import { FC, useCallback, useRef, useState } from "react";
import ContentWidget from "@components/ContentWidget/ContentWidget";
import { Link } from "react-router-dom";
import './FoodProfileRecommendedCharts.scss';
import cn from "classnames";
import ContentWidgetLink from "@components/ContentWidget/ContentWidgetLink";
import ContentWidgetButton from "@components/ContentWidget/ContentWidgetButton";
import { AnalyticsAttribute } from "@models/AnalyticsAttributes";
import useOutsideClick from "@hooks/useOutsideClick";

export interface iItem {
    title: string;
    subtitle: string;
    link: string;
}

const FoodProfileRecommendedCharts: FC<{
    data: iItem[]
}> = (
    {
        data,
    }
) => {
    const totalCount = data.length;
    const [isOpened, setIsOpened] = useState(false);
    const container = useRef<HTMLDivElement>(null);
    const close = useCallback(() => setIsOpened(false), []);

    useOutsideClick({
        ref: container,
        isDisabled: !isOpened,
        fn: close,
    });

    const showDetailInfo = function () {
        setIsOpened(!isOpened);
    }
    const linksListFull = ([...data].map((item, index) => (
        <Link
            key={item.title + '_' + index}
            to={item.link}
            className="FoodProfileRecommendedCharts__link font-text"
            data-testid={`textFoodProfileRecommendedChartsItem${index}`}
        >
            <span
                className="FoodProfileRecommendedCharts__link-name"
                data-testid={`textChartsItemTitle`}
            >
                {item.title}
            </span>
            <span
                className="FoodProfileRecommendedCharts__type"
                data-testid={`textChartsItemType`}
            >
                ({item.subtitle})
            </span>
        </Link>
    )));

    const button = (
        <ContentWidgetButton
            onClick={showDetailInfo}
            analyticsData={{
                [AnalyticsAttribute.ClickTitle]: 'Recommended Charts',
            }}
            testId="buttonFoodProfileRecommendedChartsExpandCollapse"
        >
            {isOpened ? 'show less' : 'show more'}
        </ContentWidgetButton>
    );

    return (
        <ContentWidget
            title="RECOMMENDED CHARTS"
            titleTestId={"textFoodProfileRecommendedChartsTitle"}
            className="FoodProfileRecommendedCharts"
            modifiers={['no-hover', 'no-margin', 'col-5']}
        >
            <div className="FoodProfileRecommendedCharts__body" ref={container}>
                <div className="FoodProfileRecommendedCharts__links is-short">
                    {linksListFull}
                </div>
                {totalCount > 15 && button}
                <ContentWidget
                    title="RECOMMENDED CHARTS"
                    titleTestId={"textFoodProfileRecommendedChartsExpandedTitle"}
                    className={cn("FoodProfileRecommendedCharts", isOpened && 'is-opened')}
                    modifiers={['no-hover', 'hover-data', 'no-margin', 'col-5']}
                >
                    <div className="FoodProfileRecommendedCharts__body">
                        <div className="FoodProfileRecommendedCharts__links">
                            {linksListFull}
                        </div>
                        {button}
                    </div>
                </ContentWidget>
            </div>
        </ContentWidget>
    );
};
export default FoodProfileRecommendedCharts;