import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import VisibilitySensor from 'react-visibility-sensor';

import Page from '../../../containers/Page/Page';
import PageContent from '../../../containers/PageContent/PageContent';
import {
    getOneDoctypeData,
    getMoreOneDoctypeData,
    getByPublicationFamily,
    setResetDocumentsData,
    updateCountToShowByDocType,
    resetCountToShowByDocType
} from '@actions/documents.actions';
import SpriteIcon from '@components/SpriteIcon/SpriteIcon';
import './AllPage.scss';
import {
    reportsListDataSelector,
    shareDataSelector,
    trendspottingsListDataSelector,
    macsListDataSelector,
    trendspottingsByFamilySelector,
    tableDataSelector,
    downloadingFileStateSelector,
    downloadingChopFileStateSelector
} from '@selectors/documents.selectors';
import {
    anyMacExistSelector
} from '@selectors/page';
import Notification from '@components/Notification/Notification';
import SharePopup from '@pages/_shared/SharePopup/SharePopup';
import DocumentsTable from '../DocumentsTable';
import MacList from '../Mac/MacList';
import ReactDOM from 'react-dom';

class AllPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredBy: 'all',
            publicationFamilyFilter: 'everything',
            searchString: '',
            tableMode: false,
            fromPublicationFamily: false,
            isDropdownOpened: false,
        };
        // this.scrollToItem = this.scrollToItem.bind(this);
        this.showFilterDropdown = this.showFilterDropdown.bind(this);
        this.setRef = this.setRef.bind(this);
        this.close = this.close.bind(this);
    }

    componentDidMount() {
        if (window.location.pathname === 'allMacs' && this.props.anyMacExist === false) {
            window.location.href = '/404';
        }
        this.requestData();
        document.addEventListener('click', this.close, true);
    }

    componentWillUnmount() {
        this.props.resetDocumentsData();
        this.props.resetCountToShow();
        document.removeEventListener('click', this.close, true);
    }

    componentDidUpdate(prevProps) {
        if (this.props.justPurchased && !prevProps.justPurchased) {
            this.requestData();
        }
        if (this.props.trendspottingsList.length !== prevProps.trendspottingsList.length) {
            const publicationFamilies = new Set([
                'everything',
                ...this.props.trendspottingsList.map(item => item.publicationFamily)
            ]);
        }
    }

    close(e = false) {
        if (this.dropDown) {
            if (!e || !ReactDOM.findDOMNode(this.dropDown).contains(e.target)) {
                this.setState({
                    isDropdownOpened: false,
                });
            }
        }
    }

    setRef(dropDown) {
        this.dropDown = dropDown;
    }

    requestData() {
        let familyValue = '';
        if (window.location.search) {
            const searchString = new window.URLSearchParams(window.location.search);
            familyValue = searchString.get('publicationFamily');
        }

        if (familyValue) {
            this.setState({
                publicationFamilyFilter: familyValue
            });
        }

        this.props.getData({
            doctype: this.props.type,
            take: 1000
        });
    }

    setAvailabilityFilter(state) {
        this.setState({ filteredBy: state });
    }

    filterByAvaliability(listOfData) {
        switch (this.state.filteredBy) {
            case 'hasPermission': {
                return listOfData.filter(item => item.hasPermission);
            }
            case 'canPurchase': {
                return listOfData.filter(item => !item.hasPermission);
            }
            default: {
                return listOfData;
            }
        }
    }

    handleSearchString(e) {
        this.setState({
            searchString: e.target.value.toLowerCase()
        });
    }

    filterListDataBySearch(propsData) {
        return propsData;
        // return propsData.filter((elem) => {
        //     return elem.title.toLowerCase().indexOf(this.state.searchString) !== -1;
        // });
    }

    switchTableMode(state) {
        if (this.state.tableMode !== state) this.setState({ tableMode: state });
    }

    sortMacsListByName(list) {
        const sorted = [].concat(list).sort((a, b) => {
            const valA = a.title.toLowerCase();
            const valB = b.title.toLowerCase();
            if (valA < valB) {
                return -1;
            }
            if (valA > valB) {
                return 1;
            }
            return 0;
        });
        return sorted;
    }

    renderContent() {
        const searchWord = '';
        // const searchWord = new window.URLSearchParams(this.props.location.search).get('q');
        let tabContent = '';
        switch (this.props.type) {
            case 'Mac':
                if (this.state.tableMode) {
                    tabContent = this.props.macsTableData.data.length ?
                        <DocumentsTable
                            data={this.props.macsTableData.data}
                            searchWord={searchWord}
                            shortMode={true}
                            from={'allMacs'}
                            maxToShow={this.props.maxToShow}
                            docType="Mac"
                            sortByColumn="Name"
                            sortDirection="asc"
                            testId="tableAllMacsTable"
                        /> :
                        null;
                } else {
                    tabContent = this.props.macsList.length ?
                        <MacList
                            items={this.sortMacsListByName(this.filterListDataBySearch(this.filterByAvaliability(this.props.macsList)))}
                            // scrollToItem={this.scrollToItem}
                            from={'allMacs'}
                            maxToShow={this.props.maxToShow}
                        />
                        : null;
                }
                break;
        }
        return tabContent;
    }

    handleChange(isVisible) {
        if (isVisible) {
            this.props.showMoreItems({
                docType: this.props.type
            });
        }
    }

    showFilterDropdown() {
        this.setState({
            isDropdownOpened: true,
        });
    }

    handleClickFilterOption(value, e) {
        e.preventDefault();
        e.stopPropagation();

        this.props.resetCountToShow();
        let url;
        if (value !== 'everything') {
            if (/\?publicationFamily=/.test(window.location.href)) {
                url = window.location.href.replace(/\?publicationFamily=.*$/g, '?publicationFamily=' + value);
            }
            else {
                url = `${window.location.href}?publicationFamily=${value}`;
            }
        }
        else {
            url = window.location.href.replace(/\?publicationFamily=.*$/g, '');
        }
        window.history.replaceState(null, '', url);
        this.setState({
            publicationFamilyFilter: value,
            isDropdownOpened: false,
        });
    }

    render() {
        const { documentsData } = this.props;

        return (
            <Page>
                <div>
                    <PageContent>
                        {
                            this.props.loading === false ?
                                (
                                    <div className={classNames(
                                        'doctype-page__section',
                                        {'doctype-page__section--loading': this.state.isSpinnerShown },
                                    )}>
                                        <div className="doctype-page__content">
                                            <Scrollbars>
                                                <div className="doctype-page__header">
                                                    <span
                                                        className="documents__found"
                                                        data-testid="textAllMacsTitle"
                                                    >
                                                        Menu Adoption Cycles (MACs)
                                                        <a
                                                            href={`${process.env.REACT_APP_API}content/pdf/MAC_DIAGRAM.pdf`}
                                                            download
                                                            title="Download information"
                                                            className="doctype-page__download-info"
                                                            data-testid="buttonAllMacsDownloadInfo"
                                                        >
                                                            <SpriteIcon iconId="info" className="info-icon"/>
                                                        </a>
                                                    </span>
                                                </div>
                                                <div className="documents__tab-header">
                                                    <div className="documents-search">
                                                        <label htmlFor="docs_search" className="documents-search__label">
                                                            <SpriteIcon iconId="search-small"/>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="docs_search"
                                                            onChange={this.handleSearchString.bind(this)}
                                                            className="documents-search__input"
                                                            placeholder="Find"
                                                        />
                                                    </div>

                                                    <p
                                                        className="documents__tab-header-title"
                                                        data-testid="textAllMacsDescription"
                                                    >
                                                        The Menu Adoption Cycle (MAC) tracks any food, ingredient, or other trend through its 4-stage life cycle – from Inception to Ubiquity.
                                                    </p>

                                                    <div className="documents__change-view-outer">
                                                        <button
                                                            className={`documents__change-view-button ${!this.state.tableMode ? 'documents__change-view-button--active' : ''}`}
                                                            onClick={this.switchTableMode.bind(this, false)}
                                                            data-testid="buttonAllMacsTileView"
                                                        >
                                                            tile
                                                        </button>
                                                        <button
                                                            className={`documents__change-view-button ${this.state.tableMode ? 'documents__change-view-button--active' : ''}`}
                                                            onClick={this.switchTableMode.bind(this, true)}
                                                            data-testid="buttonAllMacsTableView"
                                                        >
                                                            table
                                                        </button>
                                                    </div>
                                                </div>
                                                {
                                                    this.renderContent()
                                                }
                                                {
                                                    this.props.documentsData[this.props.type].itemsCount > this.props.maxToShow
                                                        ? (
                                                            <VisibilitySensor
                                                                scrollDelay={100}
                                                                intervalDelay={500}
                                                                partialVisibility={true}
                                                                onChange={this.handleChange.bind(this)}
                                                            >

                                                                <div className="doctype-page__visibility-sensor-after"/>
                                                            </VisibilitySensor>
                                                        ) : null
                                                }
                                            </Scrollbars>
                                        </div>
                                    </div>
                                ) : null
                        }

                    </PageContent>
                    {this.props.notification && <Notification />}
                    {this.props.showSharePopup && <SharePopup />}
                </div>
            </Page>
        );
    }
}

AllPage.propTypes = {
    searchQuery: PropTypes.string,
    getData: PropTypes.func,
    documentsData: PropTypes.object,
    reportsListDataSelector: PropTypes.func,
    reportsList: PropTypes.array,
    trendspottingsList: PropTypes.array,
    macsList: PropTypes.array,
    showSharePopup: PropTypes.bool,
    location: PropTypes.object,
    loading: PropTypes.bool,
    reportsTableData: PropTypes.object,
    notification: PropTypes.bool,
    isTypeLoaded: PropTypes.object,
    downloadingFile: PropTypes.bool,
    downloadingChop: PropTypes.bool,
    type: PropTypes.string,
    getByFamily: PropTypes.func,
    trendspottingsByFamilyList: PropTypes.array,
    macsTableData: PropTypes.object,
    isAllMac: PropTypes.bool,
    maxToShow: PropTypes.number,
};

AllPage.defaultProps = {};

const mapStateToProps = state => ({
    documentsData: state.documentsData.data,
    loading: state.documentsData.loading,
    loadingMore: state.documentsData.loadingMore,
    reportsList: reportsListDataSelector(state),
    trendspottingsList: trendspottingsListDataSelector(state),
    trendspottingsByFamilyList: trendspottingsByFamilySelector(state),
    macsTableData: tableDataSelector(state, 'Mac'),
    macsList: macsListDataSelector(state),
    showSharePopup: shareDataSelector(state),
    notification: state.documentsData.notification,
    downloadingFile: downloadingFileStateSelector(state),
    downloadingChop: downloadingChopFileStateSelector(state),
    anyMacExist: anyMacExistSelector(state),
    justPurchased: state.documentsData.justPurchased,
    maxToShow: state.documentsData.maxToShow
});

const mapDispatchToProps = dispatch => ({
    getData: data => dispatch(getOneDoctypeData(data)),
    getMoreData: data => dispatch(getMoreOneDoctypeData(data)),
    getByFamily: data => dispatch(getByPublicationFamily(data)),
    resetDocumentsData: () => dispatch(setResetDocumentsData()),
    showMoreItems: type => dispatch(updateCountToShowByDocType(type)),
    resetCountToShow: type => dispatch(resetCountToShowByDocType(type))
});

export default connect(mapStateToProps, mapDispatchToProps)(AllPage);
