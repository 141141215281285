import React, { FC, memo, useContext } from "react";
import { getChartXIntervals, iViewBoxProps } from "@components/Chart/Chart.helpers";

const ChartAxisX: FC<{
    labels: string[];
    viewport: iViewBoxProps;
}> = (
    {
        labels,
        viewport,
    }
) => {
    const intervals = getChartXIntervals({
        dataLength: labels.length,
        viewport,
    })

    return (
        <g
            name="axis-x"
            className="Chart__axis"
            style={{
                transform: `translateY(${viewport.height}px)`,
            }}
            data-testId={`textChartAxisX`}
        >
            {labels.map((label, index) => {
                return (
                    <React.Fragment key={index}>
                        <text
                            key={index}
                            x={intervals[index]}
                            y={12}
                            fill="#fff"
                            textAnchor="middle"
                            alignmentBaseline="middle"
                            data-testId={`textChartAxisXLabel${index}`}
                        >
                            {label}
                        </text>
                    </React.Fragment>
                )
            })}
        </g>
    );
};

export default memo(ChartAxisX);