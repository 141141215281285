export function isPrimitive(value) {
    return value !== Object(value);
}

export const monthValues = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'];

export function contains(text = '', string) {
    try {
        return new RegExp(string.toLowerCase(), 'gi').test(text.toLowerCase());
    } catch (err) {
        return false;
    }
}

// { title: 'January', value: 0, id: 0 },
// { title: 'February', value: 1, id: 1 },
// { title: 'March', value: 2, id: 2 },
// { title: 'April', value: 3, id: 3 },
// { title: 'May', value: 4, id: 4 },
// { title: 'June', value: 5, id: 5 },
// { title: 'July', value: 6, id: 6 },
// { title: 'August', value: 7, id: 7 },
// { title: 'September', value: 8, id: 8 },
// { title: 'October', value: 9, id: 9 },
// { title: 'November', value: 10, id: 10 },
// { title: 'December', value: 11, id: 11 }

export function findAncestor(el, cls) {
    if (el.classList.contains(cls)) {
        return el;
    }
    while ((el = el.parentElement) && !el.classList.contains(cls)) ;
    return el;
}

export function randomizeInRange(from, to) {
    return Math.floor(Math.random() * to) + from;
}

function findFirstChar(str, char) {
    return str.split('').findIndex(el => el === char);
}

export function test(object) {
    const toReturn = {};

    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            if ((typeof object[key]) === 'object') {
                const flatObject = test(object[key]);
                for (const key2 in flatObject) {
                    if (flatObject.hasOwnProperty(key2)) {
                        toReturn[`${key}][${key2}`] = flatObject[key2];
                    }
                }
            } else {
                toReturn[key] = object[key];
            }
        }
    }
    return toReturn;
}

export function test2(toReturn) {
    const toReturn2 = {};
    for (const key in toReturn) {
        if (toReturn.hasOwnProperty(key)) {
            let key2 = key.substr(0, findFirstChar(key, ']')) + key.substr(findFirstChar(key, ']') + 1);
            if (key2.indexOf('[') !== -1) {
                key2 += ']';
            }
            toReturn2[key2] = toReturn[key];
        }
    }
    return toReturn2;
}

export function toQueryString(obj) {
    const parts = [];
    for (const i in obj) {
        if (obj.hasOwnProperty(i)) {
            parts.push(`${encodeURIComponent(i)}=${encodeURIComponent(obj[i])}`);
        }
    }
    return parts.join('&');
}

export function scrollTo(element, to, duration) {
    const start = element.scrollTop;
    const change = to - start;
    let currentTime = 0;
    const increment = 20;

    const onScrollFinished = new Promise((resolve) => {
        function animateScroll() {
            currentTime += increment;
            const val = Math.easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
            if (element.scrollTop === Math.round(to)) {
                resolve(true);
            }
        }
        animateScroll();
    });

    return onScrollFinished;
}

// t = current time
// b = start value
// c = change in value
// d = duration
Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
};

export function cutDomainName(url) {
    return url.split('/')[2];
}

export function getDocumentTypeById(id) {
    switch (id) {
        case 0: {
            return 'DatassentialReportDocument';
        }
        case 1: {
            return 'FoodProfileDocument';
        }
        case 2: {
            return 'InstantChartDocument';
        }
        case 3: {
            return 'MacDocument';
        }
        case 4: {
            return 'PlaceDocument';
        }
        case 5: {
            return 'MyDocument';
        }
        case 6: {
            return 'SnapshotDocument';
        }
        case 7: {
            return 'TrendspottingDocument';
        }
        case 8: {
            return 'TrendspottingArticleDocument';
        }
        case 9: {
            return 'ChainReport';
        }
        case 10: {
            return 'ChainProfile';
        }
        case 11: {
            return 'ChainFireFlyReport';
        }
        case 12: {
            return 'ChainFingerprintsReport';
        }
        case 13: {
            return 'ChainLTO';
        }
        case 14: {
            return 'Scores';
        }
        case 15: {
            return 'FoodProfile';
        }
        case 16: {
            return 'MtdFoodProfile';
        }
        case 17: {
            return 'Project';
        }
        default: {
            return 'Error type';
        }
    }
}

/**
 *
 * @param params Document params with query
 * @returns {object} Route params
 */
export function getRouteUrlByDocType(params) {
    switch (params.docType) {
        case 0: {
            return 'DatassentialReportDocument';
        }
        case 1: {
            return {
                pathname: '/food',
                search: `?q=${encodeURIComponent(params.title)}`
            };
        }
        case 2: {
            return {
                pathname: '/instantCharts',
                search: `?q=${encodeURIComponent(params.query)}&id=${params.id}&title=${encodeURIComponent(params.title)}`
            };
        }
        case 3: {
            return {
                pathname: '/view-document',
                search: `?id=${params.id}&doctype=${params.docType}`
            };
        }
        case 4: {
            return 'PlaceDocument';
        }
        case 5: {
            return 'MyDocument';
        }
        case 6: {
            return 'SnapshotDocument';
        }
        case 7: {
            return 'TrendspottingDocument';
        }
        case 8: {
            return 'TrendspottingArticleDocument';
        }
        case 9: {
            return 'ChainReport';
        }
        case 10: {
            return 'ChainProfile';
        }
        case 11: {
            return 'ChainFireFlyReport';
        }
        case 12: {
            return 'ChainFingerprintsReport';
        }
        case 13: {
            return 'ChainLTO';
        }
        case 14: {
            return 'Scores';
        }
        case 15: {
            return 'FoodProfile';
        }
        case 16: {
            return 'MtdFoodProfile';
        }
        case 17: {
            return 'Project';
        }
        default: {
            return 'Error type';
        }
    }
}

export const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function capitalize(str = '', removeSpaces = false) {
    let result = str.toLowerCase().replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

    if (removeSpaces) {
        result = result.replace(/\s/g, '');
    }

    return result;
}

export function escapeRegExp(str = '') {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}

export function makeEnding(title, count, uppercase = false) {
    switch (title) {
        case 'company': {
            return count === 1 ? 'company' : 'companies';
        }
        case 'person': {
            return count === 1 ? 'person' : 'people';
        }
        default: {
            if(uppercase) {
                return count === 1 ? title : `${title}S`
            }
            return count === 1 ? title : `${title}s`;
        }
    }
}

export function deepCopy(data) {
    return JSON.parse(JSON.stringify(data));
}

export function getCookie(cname) {
    const name = cname + "=";
    const ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function setCookieEnhanced (name, value, options) {
    options = options || {};

    var expires = options.expires;

    if (typeof expires == "number" && expires) {
        var d = new Date();
        d.setTime(d.getTime() + expires * 1000);
        expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString();
    }

    value = encodeURIComponent(value);

    var updatedCookie = name + "=" + value;

    for (var propName in options) {
        updatedCookie += "; " + propName;
        var propValue = options[propName];
        if (propValue !== true) {
            updatedCookie += "=" + propValue;
        }
    }

    document.cookie = updatedCookie;
}

export function cutText(text, symbolsPerLine = 20) {
    let updatedText = text;
    if(text.length > symbolsPerLine) {
        updatedText = `${text.substr(0, symbolsPerLine)} ${cutText(text.substr(symbolsPerLine), symbolsPerLine)}`;
    }
    return updatedText;
}
