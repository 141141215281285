import { iPermissionFields } from "./PermissionSection";
import { API__FPChainData } from "../apiSchema";

export interface iBrandData extends iPermissionFields {
    data: API__FPChainData;
}

export class FPChainData implements API__FPChainData {
    static defaultData: API__FPChainData = {
        headerData: {
            circles: [],
            nps: 0,
            detractor: 0,
            passive: 0,
            promoter: 0,
        },
        situationAssessmentData: {
            strengths: [],
            weakness: [],
        },
        brandFunnelData: [],
        customerExperience: [],
        foodAndBeverageQuality: [],
        valueProposition: [],
    };

    headerData = FPChainData.defaultData.headerData;
    situationAssessmentData = FPChainData.defaultData.situationAssessmentData;
    brandFunnelData = FPChainData.defaultData.brandFunnelData;
    customerExperience = FPChainData.defaultData.customerExperience;
    foodAndBeverageQuality = FPChainData.defaultData.foodAndBeverageQuality;
    valueProposition = FPChainData.defaultData.valueProposition;
}
