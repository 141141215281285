import { API__SRChart } from "../apiSchema";

export enum YearSeasonsEnum {
    NotSet = "",
    Winter = "WIN",
    Spring = "SPR",
    Summer = "SUM",
    Fall = "FAL"
}

export interface iQuarterYear {
    season: YearSeasonsEnum;
    value: number;
}

export interface iLTOChart {
    year: number;
    quarters: iQuarterYear[];
}

interface iData {
    apiModel: API__SRChart;
}

export class LTOChart implements iLTOChart {
    static defaultData: iLTOChart = {
        year: 0,
        quarters: []
    };

    year = LTOChart.defaultData.year;
    quarters = LTOChart.defaultData.quarters;

    constructor(data?: iData) {
        if ( data && data.apiModel ) {
            this.mapFromApi(data.apiModel);
        }
    }

    private setData(model: iLTOChart) {
        ({
            year: this.year,
            quarters: this.quarters,
        } = model);
    }

    static getSeasonEnumValue(seasonStr: string): YearSeasonsEnum {
        switch (seasonStr) {
            case 'WIN': return YearSeasonsEnum.Winter;
            case 'SPR': return YearSeasonsEnum.Spring;
            case 'SUM': return YearSeasonsEnum.Summer;
            case 'FAL': return YearSeasonsEnum.Fall;
            default: return YearSeasonsEnum.NotSet;
        }
    }

    private mapFromApi(data: API__SRChart) {
        const { year, quarters, ...rest } = data;

        this.setData({
            ...rest,
            year,
            quarters: quarters.map(i => ({
                season: LTOChart.getSeasonEnumValue(i.season),
                value: i.value,
            })),
        })
    }
}