import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import ContentWidget from "@components/ContentWidget/ContentWidget";
import ChartLine from "@components/ChartLine/ChartLine";
import { splitRows } from "@helpers";
import { ID } from "@models";
import './FoodProfilePairedItems.scss';
import ContentWidgetLink from "@components/ContentWidget/ContentWidgetLink";
import { AnalyticsAttribute } from "@models/AnalyticsAttributes";
import Icon from "@components/SpriteIcon/SpriteIcon";
import useOutsideClick from "@hooks/useOutsideClick";
import { iFoodProfilePairedItems } from "@models/FoodProfilePairedItems";

function getItemValuePercentageString(value: number) {
    if (value < 0.01) {
        return `<1%`;
    }
    return `${Math.round(value * 100)}%`
}

export interface iPairedItem {
    id: ID;
    name: string;
    value: number;
}

const COLUMNS_COUNT = 3;
const ROWS_COUNT = 7;
const MAX_ITEMS_COUNT = COLUMNS_COUNT * ROWS_COUNT;

const FoodProfilePairedItems: FC<{
    searchQuery: string;
    data: iFoodProfilePairedItems;
    isLoading: boolean;
}> = (
    {
        searchQuery,
        data,
        isLoading,
    }
) => {

    const pairedItemsData: iPairedItem[] = useMemo(() => {
        return data.data.map(i => ({
            id: i.id,
            name: i.title,
            value: i.value,
        }));
    }, [data, data.data]);

    const dataSort: iPairedItem[] = useMemo(() => {
        const newData = [...pairedItemsData];
        newData.sort(function (a, b) {
            return a.value >= b.value ? -1 : 1;
        });
        return newData;
    }, [pairedItemsData]);

    const dataMaxVal = dataSort[0]?.value || 1;
    const container = useRef<HTMLDivElement>(null);
    const [isOpened, setIsOpened] = useState(false);
    const close = useCallback(() => setIsOpened(false), []);

    useOutsideClick({
        ref: container,
        isDisabled: !isOpened,
        fn: close,
    });

    const showDetailInfo = function () {
        setIsOpened(!isOpened);
    }
    const itemsListCol1 = dataSort.slice(0, 7);
    const itemsListColumnsSet = splitRows(dataSort.slice(0, MAX_ITEMS_COUNT), COLUMNS_COUNT) || [];

    const buttonShowDetails = (
        <ContentWidgetLink
            onClick={showDetailInfo}
            analyticsData={{
                [AnalyticsAttribute.ClickTitle]: 'Paired With',
            }}
            testId={"buttonFoodProfilePairedWithExpandCollapse"}
        >
            {isOpened ? 'hide details' : 'view details'}
        </ContentWidgetLink>
    );

    return (
        <ContentWidget
            title="Paired with"
            titleTestId={"textFoodProfilePairedWithTitle"}
            className="FoodProfilePairedItems"
        >
            {isLoading && (
                <div className="FoodProfilePairedItems__loader">
                    <div className="spinner spinner-blink">
                        <Icon
                            iconId="logo-dark"
                            style={{
                                width: 70,
                                height: 70,
                            }}
                        />
                    </div>
                </div>
            )}
            {!isLoading && (
                <div ref={container}>
                    <div className="ChartLine">
                        <div className="ChartLine__items-list ChartLine__items-list--paired-items">
                            {itemsListCol1.map((item, index) => (
                                <ChartLine
                                    key={item.id}
                                    name={item.name.toLowerCase()}
                                    value={getItemValuePercentageString(item.value)}
                                    widthPercentage={100 * (item.value / dataMaxVal)}
                                    modifiers={["is-gray", "is-inline"]}
                                    testId={`textFoodProfilePairedWithItem${index}`}
                                />
                            ))}
                        </div>
                    </div>
                    {buttonShowDetails}
                    <ContentWidget
                        title="Paired with"
                        titleTestId={"textFoodProfilePairedWithExpandedTitle"}
                        className={"FoodProfilePairedItems" + (isOpened ? ' is-opened' : '')}
                        modifiers={['paired-items', 'hover-data', 'no-hover']}
                    >
                        <div className="ChartLine__columns">
                            {itemsListColumnsSet.map((col, colIndex) => (
                                <div className="ChartLine__col-3" key={colIndex}>
                                    <div className="ChartLine__items-list ChartLine__items-list--paired-items">
                                        <div className="ChartLine">
                                            {col.map((item, index) => (
                                                <ChartLine
                                                    key={item.id}
                                                    name={item.name.toLowerCase()}
                                                    value={getItemValuePercentageString(item.value)}
                                                    widthPercentage={100 * (item.value / dataMaxVal)}
                                                    modifiers={["is-gray", "is-inline"]}
                                                    testId={`textFoodProfilePairedWithExpandItem${colIndex}-${index}`}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {buttonShowDetails}
                    </ContentWidget>
                </div>
            )}
        </ContentWidget>
    );
};
export default FoodProfilePairedItems;