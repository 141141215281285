import { iTableConfigItem } from "../../../../components/new/Table/Table.helpers";
import { iCompetitorsItem, iCompetitorsItemPercents, iLocation, iMarket, iPopulation, iPopulationStatistic } from "../../../../core/models/Location";
import { getFormattedDate } from "../../../../core/helpers";

class PopulationSubHeader {
    name = "";
    index = "";
    percent = "";
    isSubHeader = true;

    constructor(value: string) {
        this.name = value;
    }
}

interface iPopulationData {
    name: string;
    index: string | number;
    percent: string | number;
    isSubHeader?: boolean;
}

const transformData = (data: iPopulation[]) => {
    return data.map(el => ({
        ...el,
        percent: Number(el.percent),
        index: el.index,
    }));
};

export const getPopulationData = (data: iPopulationStatistic) => {

    let tableData: iPopulationData[] = [];

    Object.keys(data).forEach(key => {
        const el = (data as any)[key];

        const currentGroup = [
            new PopulationSubHeader(key),
            ...transformData(el)
        ];

        tableData = [...tableData, ...currentGroup];
    });

    return tableData;
};

export const getTabItems = (data: iLocation) => {
    const tableData = getPopulationData(data.residentPopulation.statistic);

    const competitionItems = !!data.competitors.items.length;
    const stateItems = !!data.marketAreas.stateMarketAreas.length;
    const metroItems = !!data.marketAreas.metroMarketAreas.length;
    const populationsItems = !!tableData.length;

    return [
        ...(stateItems ? [{ id: 1, title: "Top States" }] : []),
        ...(metroItems ? [{ id: 2, title: "Top Metros" }] : []),
        ...(competitionItems ? [{ id: 3, title: "Nearby Competition" }] : []),
        ...(populationsItems ? [{ id: 4, title: "Local Population" }] : []),
    ];
};

export interface iCopyData {
    head: iTableConfigItem[];
    data: any[];
};
export const highlightCellStyles = {
    cell: {
        background: `rgba(86,204,242,.08)`
    },
    head: {
        color: "#56CCF2"
    }
};

export const COMPETITORS_CELL_METROS_WIDTH = 160;
export const COMPETITORS_CELL_OVERLAP_WIDTH = 120;
export type CurrentTabData = iMarket & iCompetitorsItem & iPopulation;

export interface iTableConfig {
    states: iTableConfigItem[];
    metros: iTableConfigItem[];
    competitors: iTableConfigItem[];
    population: {
        gender: iTableConfigItem[];
        ethnicity: iTableConfigItem[];
        languages: iTableConfigItem[];
        generations: iTableConfigItem[];
        birthPlace: iTableConfigItem[];
    };
}

export interface iCurrentTabData {
    defaultField: keyof CurrentTabData;
    data: Partial<CurrentTabData>[];
    tableConfigKey: keyof iTableConfig;
}

export const getClipboardData = ({
    tabData,
    tableConfigs,
    sortedData,
    population,
}: {
    tabData: iCurrentTabData;
    tableConfigs: iTableConfig;
    sortedData: Partial<CurrentTabData>[];
    population: iPopulationStatistic;
}) => {
    // TODO: update method after tableConfigs refactor
    const key = tabData.tableConfigKey;
    const populationGender = tableConfigs.population.gender;
    const head = (key !== "population" ? tableConfigs[key] : populationGender).map(({ title }) => title || "");
    const populationData = getPopulationData(population);
    const COMPETITORS_LIMIT_ITEMS = 20;
    const slicedData = sortedData.slice(0, key === "competitors" ? COMPETITORS_LIMIT_ITEMS : sortedData.length);

    // TODO: add correct type
    const getCopyFields = () => {
        switch (key) {
            case "states":
            case "metros":
                return [
                    "region",
                    "numberOfStores",
                    "percentOfAllStores",
                    "density"
                ];
            case "competitors":
                return [
                    "chainName",
                    "commonMetros",
                    "averageDistance",
                    "percents.miles05",
                    "percents.miles1",
                    "percents.miles3",
                ];
            case "population":
                return [
                    "name",
                    "index",
                    "percent"
                ];
            default:
                return [];
        }
    };

    const getPercentsKey = (field: keyof CurrentTabData) => {
        const isMiles = field.includes("miles");
        const key = field.split("percents.").pop() as keyof iCompetitorsItemPercents; // miles05 | miles1 | miles3

        return { isMiles, key }
    }

    const fields = getCopyFields() as Array<keyof CurrentTabData>;

    const clipboardData = slicedData.map(row => {
        return fields
            .map(field => {
                const percents = row["percents"];
                const { isMiles, key } = getPercentsKey(field);
                const value = percents && isMiles ? percents[key] : row[field];
                return value ? `${value}` : "";
            })
            .filter(el => el);
    });

    const popuplationClipboardData = populationData.map(item => [
        item.name,
        `${item.index}`,
        `${item.percent}`,
    ]);

    return [
        head,
        ...(key === "population" ? popuplationClipboardData : clipboardData)
    ];
};
