import { API__ChainNoteViewModel, API__ChainPermissionWrapper } from "../apiSchema";
import { iPermissionSection, PermissionSection } from "./PermissionSection";

export interface iNote {
    id: number;
    modifiedDateTime: string;
    isPublic: boolean;
    text: string;
    isOwn: boolean;
}

export class NoteData implements iPermissionSection<iNote[]> {
    static defaultData: iPermissionSection<iNote[]> = new PermissionSection<iNote[]>();

    hasData = NoteData.defaultData.hasData;
    permissionLevel = NoteData.defaultData.permissionLevel;
    data = NoteData.defaultData.data;

    constructor(data?: API__ChainPermissionWrapper<iNote[]>) {
        if (data) {
            this.mapFromApi(data);
        }
    }

    private setData(model: iPermissionSection<iNote[]>) {
        ({
            hasData: this.hasData,
            permissionLevel: this.permissionLevel,
            data: this.data
        } = model);
    }

    private sortByDate(a: iNote, b: iNote) {
        return new Date(b.modifiedDateTime).getTime() - new Date(a.modifiedDateTime).getTime()
    }

    private mapFromApi(data : API__ChainPermissionWrapper<iNote[]>) {
        this.setData(
            new PermissionSection({
                permissionLevel: PermissionSection.getPermissionLevel(data.permissionLevel),
                hasData: data.hasData,
                data: (data.data || []).sort(this.sortByDate).map(n => new Note(n)),
            })
        );
    }
}

export class Note implements iNote {
    static defaultData: iNote = {
        id: 0,
        modifiedDateTime: "",
        isPublic: false,
        text: "",
        isOwn: false,
    };

    id = Note.defaultData.id;
    modifiedDateTime = Note.defaultData.modifiedDateTime;
    isPublic = Note.defaultData.isPublic;
    text = Note.defaultData.text;
    isOwn = Note.defaultData.isOwn;

    constructor(data?: API__ChainNoteViewModel) {
        if (data) {
            this.mapFromApi(data);
        }
    }

    private setData(model: iNote) {
        ({
            id: this.id,
            modifiedDateTime: this.modifiedDateTime,
            isPublic: this.isPublic,
            text: this.text,
            isOwn: this.isOwn,
        } = model);
    }

    private mapFromApi(data: API__ChainNoteViewModel) {
        this.setData(data);
    }
}