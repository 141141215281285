import { FC } from "react";
import { iYoutubeVideo } from "../../../../../core/models/SocialLinks";
import "./ItemYoutube.styles.scss";

interface iProps {
    data: iYoutubeVideo;
    onOpen: (url: string) => void;
}

const ItemYoutube: FC<iProps> = ({ data, onOpen }) => {
    return (
        <div className="ItemYoutube" onClick={() => onOpen(data.url)}>
            <div
                className="ItemYoutube__thumbnail"
                style={{ backgroundImage: `url(${data.thumbnailUrl})` }}
            />
            <div className="ItemYoutube__description">
                <div className="ItemYoutube__title">{data.title}</div>
            </div>
        </div>
    );
};

export default ItemYoutube;
