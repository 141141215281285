import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import SlickSlider, { Settings, CustomArrowProps } from "react-slick";
import Slide from "./Slide";
import { IconArrow } from "@icons";
import {
    SlickSliderExtended,
    SLIDER_DEFAULT_CONFIG,
    THUMBNAILS_DEFAULT_CONFIG,
} from "./Slider.helpers";
import "./Slider.styles.scss";
import ThumbSlide from "./ThumbSlide";

interface iCustomThumbnail {
    slideIndex: number;
    data: React.ReactNode;
}

interface iProps {
    isShownExtraSpace?: boolean;
    isShownThumbnails?: boolean;
    sliderConfig?: Settings;
    autoFocus?: boolean;
    customThumbnails?: iCustomThumbnail[];
    onClick?: () => void;
    onChangeSlide?: (index: number) => void;
    currentSlideIndex?: number;
    totalCounter?: React.ReactNode;
}

const SlickArrowNext = ({ onClick, className }: CustomArrowProps) => {
    return (
        <div className={`Slider__arrow Slider__arrow--next ${className}`} onClick={onClick}>
            <IconArrow className="Slider__arrow-icon" />
        </div>
    );
};

const SlickArrowPrev = ({ onClick, className }: CustomArrowProps) => {
    return (
        <div className={`Slider__arrow Slider__arrow--prev ${className}`} onClick={onClick}>
            <IconArrow className="Slider__arrow-icon" />
        </div>
    );
};

const Slider: React.FC<iProps> = ({
    sliderConfig = SLIDER_DEFAULT_CONFIG,
    isShownThumbnails = false,
    isShownExtraSpace,
    autoFocus = false,
    customThumbnails = [],
    onClick,
    onChangeSlide = () => {},
    currentSlideIndex,
    totalCounter,
    children,
}) => {
    const [slider, setSlider] = useState<SlickSlider>();
    const [thumbnails, setThumbnails] = useState<SlickSlider>();
    const sliderRef = useRef<SlickSliderExtended>(null);
    const thumbnailsRef = useRef<SlickSlider>(null);

    useEffect(() => {
        if (sliderRef && sliderRef.current) {
            setSlider(sliderRef.current);
        }
        if (currentSlideIndex && sliderRef.current) {
            sliderRef.current.slickGoTo(currentSlideIndex, true);
        }

        if (thumbnailsRef && thumbnailsRef.current) {
            setThumbnails(thumbnailsRef.current);
        }
    }, [currentSlideIndex]);

    const sliderSettings = useMemo(() => {
        const { className, ...options } = sliderConfig;
        return {
            ...options,
            nextArrow: SliderArrowNext,
            prevArrow: SliderArrowPrev,
            asNavFor: thumbnails,
            onReInit: () => {
                if (!autoFocus) return;
                const list = sliderRef.current && sliderRef.current.innerSlider.list;
                if (list) {
                    list.setAttribute("tabindex", 0);
                    list.focus();
                }
            },
            afterChange: (currentSlide: number) => {
                if ( options.afterChange ) {
                    options.afterChange(currentSlide);
                }
                onChangeSlide(currentSlide);
            },
        };
    }, [sliderConfig, thumbnails, autoFocus, onChangeSlide]);

    const thumbnailsSettings = useMemo(
        () => ({
            ...THUMBNAILS_DEFAULT_CONFIG,
            asNavFor: slider,
        }),
        [slider],
    );

    const sliderClasses = classNames("Slider", {
        "Slider--has-thumbnails": isShownThumbnails,
    });

    const containerClasses = classNames("Slider__slider", sliderConfig.className, {
        "Slider__slider--has-extra-space": isShownExtraSpace,
    });

    const leadSlides = useMemo(
        () =>
            React.Children.map(children, (child, idx) => {
                if (React.isValidElement(child)) {
                    return (
                        <Slide
                            key={idx}
                            styles={child.props.styles}
                            onClick={child.props.isPreventSlideClick ? () => {} : onClick}
                        >
                            {React.cloneElement(child)}
                        </Slide>
                    );
                }
            }),
        [children, onClick],
    );

    const thumbSlides = useMemo(
        () =>
            React.Children.map(children, (child, idx) => {
                if (React.isValidElement(child)) {
                    const matchedCustomThumbnail = customThumbnails.find(
                        ({ slideIndex }) => slideIndex === idx,
                    );

                    if (matchedCustomThumbnail) {
                        return <ThumbSlide key={idx}>{matchedCustomThumbnail?.data}</ThumbSlide>;
                    }

                    return <ThumbSlide key={idx}>{React.cloneElement(child)}</ThumbSlide>;
                }
            }),
        [children, customThumbnails],
    );

    const moveToNextSlide = () => {
        if ( sliderRef.current ) {
            sliderRef.current.slickNext();
            if ( onClick ) {
                onClick();
            }
        }
    }

    return (
        <div className={sliderClasses}>
            {totalCounter && (
                <div className="Slider__total-counter" onClick={moveToNextSlide}>
                    {totalCounter}
                </div>
            )}

            <SlickSlider className={containerClasses} {...sliderSettings} ref={sliderRef}>
                {leadSlides}
            </SlickSlider>

            {isShownThumbnails && (
                <SlickSlider
                    className="Slider__thumbnails"
                    ref={thumbnailsRef}
                    {...thumbnailsSettings}
                >
                    {thumbSlides}
                </SlickSlider>
            )}
        </div>
    );
};

const SliderArrowPrev = <SlickArrowPrev />;
const SliderArrowNext= <SlickArrowNext />;

export default Slider;
