import React, { FC, useContext, useState } from "react";
import Button, { ButtonTypeEnum } from "../../../../components/new/Button/Button";
import SectionHeader from "../../../../components/new/SectionHeader/SectionHeader";
import useMenuData from "./useMenuData";
import MenuExamples from "./MenuExamples/MenuExamples";
import "./Menu.styles.scss";
import MenuDetails from "./MenuDetails/MenuDetails";
import { MenuPartsEnum } from "../../../../core/models/ChainMenuData";
import { ChainDetailsDataContext, ChainSectionsEnum, getSectionData } from "../../ChainPage.helpers";
import SectionContent from "../../../../components/new/SectionContent/SetionContent";
import { isAvailable, PermissionLevelEnum } from "../../../../core/models/PermissionSection";
import { Lock, LockDefaultAction } from "../../../../components/new/LockSection/LockSection";
import { SectionEnum } from "../../../../core/enums";
import { ReportsNameEnum } from "../../../../core/models/ChainDetails";

const Menu: FC = () => {
    const {
        data: {
            mainData: {
                id: chainId,
                website,
            },
            reportsData,
        },
        openGapReportPopup,
        downloadReports,
    } = useContext(ChainDetailsDataContext);

    const menuReport = reportsData.find(i => i.data.name === ReportsNameEnum.Menu);
    const gapsReport = reportsData.find(i => i.data.name === ReportsNameEnum.Gaps);
    const isMenuReportAvailable = !!menuReport && isAvailable(menuReport);
    const isGapsReportAvailable = !!gapsReport && isAvailable(gapsReport);

    const {
        isLoading,
        menuExamples,
        menuDetailsItems,
        permissionLevel,
    } = useMenuData(chainId);
    const [selectedButton, setSelectedButton] = useState<string>(MenuPartsEnum.Entree);

    return (
        <div className="Menu">
            <SectionHeader title="Menu">
                <div className="Menu__header-actions">
                    {isMenuReportAvailable && (
                        <div className="Menu__header-action">
                            <Button
                                modifiers={["accent"]}
                                onClick={() => downloadReports([ReportsNameEnum.Menu])}
                            >
                                MENU REPORT
                            </Button>
                        </div>
                    )}
                    {isGapsReportAvailable && (
                        <div className="Menu__header-action">
                            <Button
                                modifiers={["primary"]}
                                onClick={openGapReportPopup}
                            >
                                BYO GAP ANALYSIS
                            </Button>
                        </div>
                    )}
                </div>
            </SectionHeader>

            <SectionContent isLoading={isLoading}>
                <div className="Menu__sections">
                    <div className="Menu__section">
                        <div className="Menu__details">
                            <MenuDetails
                                data={menuDetailsItems}
                                selectedButton={selectedButton}
                                setSelectedButton={setSelectedButton}
                            />
                            {permissionLevel === PermissionLevelEnum.None && (
                                <Lock>
                                    {getSectionData(ChainSectionsEnum.Menu).noAccessMessage}
                                    <LockDefaultAction section={SectionEnum.Menu}/>
                                </Lock>
                            )}
                        </div>
                    </div>
                    <div className="Menu__section">
                        <MenuExamples
                            data={menuExamples}
                            selectedButton={selectedButton}
                            setSelectedButton={setSelectedButton}
                        />
                    </div>
                </div>

                {!!website && (
                    <div className="Menu__footer">
                        <div className="Menu__footer-text">
                            For more menu information, check out the{" "}
                            <Button
                                type={ButtonTypeEnum.Anchor}
                                link={website}
                                className="Menu__footer-link"
                            >
                                restaurant’s website
                            </Button>
                        </div>
                    </div>
                )}
            </SectionContent>


        </div>
    );
};

export default React.memo(Menu);
