import { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import getFileTypeIcon from '../../core/getFileTypeIcon';
import { NavLink } from 'react-router-dom';
import serializeParams from '../../core/serializeParams';

import Page from '../../containers/Page/Page';
import Table from '../../components/Table/Table';
import Icon from '../../components/SpriteIcon/SpriteIcon';
import VisibilitySensor from 'react-visibility-sensor';
import { Scrollbars } from 'react-custom-scrollbars';
import { contains } from '../../core/old_helpers';
import PageContent from '../../containers/PageContent/PageContent';
import { addFileToPackage } from '../../actions/packages.actions';
import { setPageToReload, setCustomerViewCompanyId } from '../../containers/DocumentsLibraryPopups/documentsLibraryPopups.actions';
import { typesSelector } from '../../containers/DocumentsLibraryPopups/documentsLibraryPopups.selectors';
import PackageAddWrapper from '@components/PackageAddWrapper/PackageAddWrapper';
import SharePopup from '@pages/_shared/SharePopup/SharePopup';
import { setSharePopupState } from '../../actions/documents.actions';
import { shareDataSelector } from '../../selectors/documents.selectors';
import { startDeleting as startDeletingDocument } from '../../containers/DocumentDeletePopup/documentDeletePopup.actions';
import { startEditing as openDocumentEditPopup } from '../../containers/DocumentEditPopup/documentEditPopup.actions';
import {
    open as openDocumentsAddPopup, setActiveTypeId
} from '../../containers/DocumentsAddPopup/documentsAddPopup.actions';

import { documentsSelector, documentsTableSelector, subTitleSelector } from './documentType.selectors';
import { getData, clearData, storeData } from './documentType.actions';
import { isImpersonateModeSelector } from '../../selectors/page';

class DocumentType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            sortBy: 'Name',
            maxToShow: 25
        };
        this.changeSearchValue = this.changeSearchValue.bind(this);
        this.setSorting = this.setSorting.bind(this);
        this.addFiles = this.addFiles.bind(this);
    }

    changeSearchValue(event) {
        this.setState({
            searchValue: event.target.value
        });
    }

    setSorting(type) {
        this.setState({
            sortBy: type
        });
    }

    componentDidMount() {
        const { storeData, getData, setActiveTypeId, setPageToReload, typesList } = this.props;
        const params = new window.URLSearchParams(this.props.location.search);
        let id = params.getAll('id');
        id = id.map(param => +param);
        const customerViewCompanyId = +params.get('companyId');
        const isShareMode = params.get('isShareMode');

        this.setState({ customerViewCompanyId });
        if (customerViewCompanyId) {
            setCustomerViewCompanyId(customerViewCompanyId);
        }
        storeData({
            id,
            isShareMode: isShareMode !== 'null',
            customerViewCompanyId
        });

        getData();
        if (typesList.length !== 0) {
            const exactType = typesList.find(({ value }) => id.includes(value));
            if (exactType) {
                setActiveTypeId(exactType.value);
            } else {
                setActiveTypeId(id[0]);
            }
        }
        setPageToReload('documentType');
    }

    componentDidUpdate(prevProps) {
        if (prevProps.typesList.length === 0 && this.props.typesList.length !== 0) {
            const params = new window.URLSearchParams(this.props.location.search);
            let id = params.getAll('id');
            id = id.map(param => +param);
            const exactType = this.props.typesList.find(({ value }) => id.includes(value));
            if (exactType) {
                this.props.setActiveTypeId(exactType.value);
            }
        }
    }

    componentWillUnmount() {
        const { setPageToReload, setCustomerViewCompanyId, setActiveTypeId, clearData, typesList } = this.props;
        setPageToReload();
        setCustomerViewCompanyId(null);
        setActiveTypeId(typesList[0] ? typesList[0].value : null);
        clearData();
    }

    renderDocumentsTable() {
        const { documentsTable } = this.props;
        const filteredDocuments = documentsTable.data
            .filter(document => contains(document[0].value, this.state.searchValue));

        return filteredDocuments.length ? (
            <Table
                useLazyLoad={true}
                className={'table-container--my-documents'}
                settings={documentsTable.settings}
                data={filteredDocuments}
                sortType="client"
                addFileToPackage={this.props.addFileToPackage}
                openSharePopup={this.props.openSharePopup}
                editDocument={this.props.editDocument}
                deleteDocument={this.props.deleteDocument}
                showCopyButton={false}
                sortingDirection="desc"
                sortingColumn={2}
                testId={'tableDocumentTypeFiles'}
            />
        ) : (
            <p>Document not found</p>
        );
    }

    addFiles() {
        const { addFiles } = this.props;
        addFiles();
    }

    renderHeader() {
        const { data, isImpersonateMode } = this.props;
        const { customerViewCompanyId } = this.state;
        const goBackLinkParams = {};
        if (customerViewCompanyId && customerViewCompanyId !== 1) {
            goBackLinkParams.companyId = customerViewCompanyId;
        }
        const goBackLink = `/myDocuments?${serializeParams(goBackLinkParams)}`;
        return (
            <div className="my-documents__header">
                <div className="my-documents__wrapper my-documents__wrapper--flex">
                    <div className="my-documents__left-side">
                        <div
                            className="my-documents__title"
                            data-testid="textDocumentTypeTitle"
                        >
                            {data.name}
                        </div>
                    </div>
                    <div className="my-documents__right-side">
                        <NavLink
                            to={goBackLink}
                            className="my-documents__link"
                            data-testid="buttonDocumentTypeBack"
                        >
                            « go back
                        </NavLink>
                        {!isImpersonateMode && (
                            <div
                                onClick={this.addFiles}
                                className="my-documents__btn"
                                data-testid="buttonDocumentTypeAddFiles"
                            >
                                Upload Files
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { dataLoaded } = this.props;
        return (
            <Page>
                <PageContent>
                    <Scrollbars>
                        {dataLoaded ? (
                            <div className="my-documents">
                                {this.renderHeader()}
                                <div className="my-documents__content">
                                    <div className="my-documents__wrapper">
                                        <div
                                            className="my-documents__sub-title"
                                            data-testid="textDocumentTypeContentTitle"
                                        >
                                            THERE ARE <b data-testid="textDocumentTypeFilesCount">{this.props.documents.length} FILES</b> ASSOCIATED WITH THIS
                                            DOCUMENT TYPE
                                        </div>
                                    </div>
                                    <div className="my-documents__wrapper">
                                        <div className="my-documents__input-container my-documents__left-side">
                                            <input
                                                className="my-documents__search font-search"
                                                type="search"
                                                placeholder="Find"
                                                onChange={this.changeSearchValue}
                                                value={this.state.searchValue}
                                                data-testid="inputDocumentTypeSearchFiles"
                                            />
                                            <Icon
                                                width="22"
                                                height="22"
                                                iconId="search"
                                                className="my-documents__search-icon"
                                            />
                                        </div>
                                    </div>

                                    <div className="my-documents__content-wrapper wrapper">
                                        {this.renderDocumentsTable()}
                                    </div>

                                </div>
                            </div>
                        ) : (<div className="spinner spinner-blink">
                                <Icon iconId="logo-dark"/>
                            </div>
                        )}
                    </Scrollbars>
                    {this.props.showSharePopup && <SharePopup/>}
                </PageContent>
            </Page>
        );
    }
}

DocumentType.propTypes = {
    data: PT.object,
    getData: PT.func,
    setActiveTypeId: PT.func,
    showSharePopup: PT.bool,
    documents: PT.array,
    openSharePopup: PT.func,
    deleteDocument: PT.func,
    editDocument: PT.func,
    subTitle: PT.string,
    dataLoaded: PT.bool,
    addFiles: PT.func,
    addFileToPackage: PT.func,
    documentsTable: PT.object,
    location: PT.object,
    clearData: PT.func,
    storeData: PT.func,
    setPageToReload: PT.func,
    setCustomerViewCompanyId: PT.func,
    typesList: PT.array
};

const MapStateToProps = state => ({
    data: state.documentTypeData,
    dataLoaded: state.documentTypeData.dataLoaded,
    documents: documentsSelector(state),
    documentsTable: documentsTableSelector(state),
    subTitle: subTitleSelector(state),
    showSharePopup: shareDataSelector(state),
    isDocumentEditPopupOpened: state.documentEditPopupData.isOpened,
    typesList: typesSelector(state),
    isImpersonateMode: isImpersonateModeSelector(state),
});

const MapDispatchToProps = dispatch => ({
    storeData: data => dispatch(storeData(data)),
    getData: (id, companyId, isShareMode) => dispatch(getData(id, companyId, isShareMode)),
    addFiles: () => dispatch(openDocumentsAddPopup()),
    clearData: () => dispatch(clearData()),
    addFileToPackage: options => dispatch(addFileToPackage(options)),
    setPageToReload: name => dispatch(setPageToReload(name)),
    openSharePopup: data => dispatch(setSharePopupState(data)),
    deleteDocument: document => dispatch(startDeletingDocument(document)),
    editDocument: data => dispatch(openDocumentEditPopup(data)),
    setActiveTypeId: id => dispatch(setActiveTypeId(id)),
    setCustomerViewCompanyId: id => dispatch(setCustomerViewCompanyId(id))
});

export default connect(MapStateToProps, MapDispatchToProps)(DocumentType);
