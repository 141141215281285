import http from '../..//core/fetch';
import { DOCUMENTS_ADD_POPUP } from '../../actions/actionsNamesList';
import { addNotification } from '../../components/GlobalNotification/globalNotification.actions';
import {
    getData as updateDocumentsLibraryData,
    updatePageToReload,
    separateContacts,
    storeTypes,
    normalizeForSelect,
} from '..//DocumentsLibraryPopups/documentsLibraryPopups.actions';
import { documentsSelector } from './documentsAddPopup.selectors';
import { FILE_SIZE_MAX_LIMIT } from '..//DocumentsLibraryPopups/documentsLibraryPopups.reducers';

const {
    SET_VIEW,
    OPEN,
    CLOSE,
    SHOW_VALIDATION_ERRORS,
    HIDE_VALIDATION_ERRORS,
    SET_ACTIVE_PROJECT_ID,
    SET_ACTIVE_TYPE_ID,
    STORE_UPLOADING_FILES,
    UPDATE_DOCUMENT_DATA,
    CANCEL_FILE_UPLOAD,
    SET_UPLOADING_STARTED,
    SET_UPLOADING_FINISHED,
    SET_SAVING_REQUESTED,
    UNSET_SAVING_REQUESTED,
    REMOVE_LAST_SET,
    CLEAR_FILES_UPLOAD,
    SET_ACTIVE_COMPANY_ID,
    INC_CURRENT_DOCUMENT_INDEX,
    SET_CURRENT_DOCUMENT_INDEX,
} = DOCUMENTS_ADD_POPUP;

let currentUpload;
let currentUploadDocumentId;
const timers = {};

export function setView(viewName) {
    return {
        type: SET_VIEW,
        viewName
    };
}

export function open() {
    return (dispatch) => {
        dispatch({
            type: OPEN
        });
        dispatch(updateDocumentsLibraryData());
    };
}
export function close() {
    return (dispatch) => {
        dispatch({ type: CLOSE });
        if (window.location.href.indexOf('/project') === -1) {
            dispatch(setActiveProjectId(null));
        }
    };
}
export function showValidationErrors() {
    return {
        type: SHOW_VALIDATION_ERRORS
    };
}
export function hideValidationErrors() {
    return {
        type: HIDE_VALIDATION_ERRORS
    };
}
function setUploadingStarted() {
    return {
        type: SET_UPLOADING_STARTED
    };
}
function setUploadingFinished() {
    return {
        type: SET_UPLOADING_FINISHED
    };
}
function setSavingRequested() {
    return {
        type: SET_SAVING_REQUESTED
    };
}
function unsetSavingRequested() {
    return {
        type: UNSET_SAVING_REQUESTED
    };
}
function startUploading() {
    return (dispatch, getState) => {
        dispatch(setUploadingStarted());
        dispatch(upload(getState().documentsAddPopupData.documents[0]));
    };
}
export function setCustomerViewCompanyId(id) {
    return {
        type: SET_ACTIVE_COMPANY_ID,
        id
    };
}
export function getSelectedCompaniesProjectsList() {
    return (dispatch, getState) => {
        const popupData = getState().documentsAddPopupData;
        const isCustomerViewMode = popupData.customerViewCompanyId !== null;
        const shareWith = popupData.documents[0].shareWith.concat([]).filter(id => id !== 1);
        const params = {
            companyIds: shareWith
        };

        if (isCustomerViewMode) {
            params.isCustomerViewMode = isCustomerViewMode;
        }

        http.get('get doctypes and users', params)
    };
}
export function setActiveProjectId(id) {
    return {
        type: SET_ACTIVE_PROJECT_ID,
        id
    };
}
export function setActiveTypeId(id) {
    return {
        type: SET_ACTIVE_TYPE_ID,
        id
    };
}
export function storeUploadingFiles(files, isFromLastSet = false) {
    return (dispatch, getState) => {
        dispatch(unsetSavingRequested());
        dispatch({
            type: STORE_UPLOADING_FILES,
            files,
            isFromLastSet
        });
        if (!getState().documentsAddPopupData.isUploading) {
            dispatch(startUploading());
        }
    };
}

export function continueUpload(id) {
    return (dispatch, getState) => {
        const { documents, isSavingRequested } = getState().documentsAddPopupData;
        const index = documents.findIndex(({ id: docId }) => docId === id);
        const isDocumentLast = index === documents.length - 1;
        const nextDocument = documents[index + 1];
        if (isDocumentLast) {
            dispatch(setUploadingFinished());
            currentUploadDocumentId = null;
            if (isSavingRequested) {
                dispatch(save());
            } else {
                dispatch(setSavingRequested());
            }
        } else {
            dispatch(upload(nextDocument));
        }
    };
}

export function cancelFileUpload(id) {
    return (dispatch) => {
        if (currentUploadDocumentId === id) {
            currentUpload.abort();
            dispatch(continueUpload(id));
        }
        dispatch({
            type: CANCEL_FILE_UPLOAD,
            id
        });
    };
}

export function cancelLastSetUpload() {
    return (dispatch, getState) => {
        const { isSavingRequested, isUploading } = getState().documentsAddPopupData;
        if ((isUploading && !isSavingRequested) || !isUploading) {
            const lastSetIds = documentsSelector(getState()).map(({ id }) => id);
            if (lastSetIds.includes(currentUploadDocumentId)) {
                if (currentUpload) currentUpload.abort();
                dispatch(setUploadingFinished());
                dispatch(save());
            } else {
                dispatch(setSavingRequested());
            }
            dispatch({ type: REMOVE_LAST_SET });
        }
    };
}

export function updateDocumentData(id, data) {
    return {
        type: UPDATE_DOCUMENT_DATA,
        id,
        data
    };
}

export function updateAllDocumentsData(data) {
    return (dispatch, getState) => {
        documentsSelector(getState()).forEach(document => dispatch(updateDocumentData(document.id, data)));
    };
}

export function upload({ file, id }) {
    return (dispatch) => {
        currentUploadDocumentId = id;
        const isFileSizeValid = file.size < FILE_SIZE_MAX_LIMIT;

        if (isFileSizeValid) {
            currentUpload = http.uploadFileXHR(
                file,
                progress => dispatch(updateDocumentData(id, { progress }))
            );

            currentUpload.then((response) => {
                dispatch(updateDocumentData(id, { backEndData: response }));
                dispatch(continueUpload(id));
            }).catch(console.log);
        } else {
            dispatch(continueUpload(id));
        }
    };
}

export function checkDocumentNameUniqueness(id, name) {
    return (dispatch) => {
        dispatch(updateDocumentData(id, {
            isNameUniquenessChecking: true,
        }));
        clearTimeout(timers[`debounce-${id}`]);
        timers[`debounce-${id}`] = setTimeout(() => {
            if (timers[`request-${id}`]) {
                timers[`request-${id}`].abort();
            }
            timers[`request-${id}`] = http.getXHR(
                'check document unique name',
                { documentName: name.trim() }
            );
            timers[`request-${id}`]
                .then((response) => {
                    dispatch(updateDocumentData(id, {
                        isNameUniqueOnServer: response,
                        isNameUniquenessChecking: false,
                    }));
                });
        }, 500);
    };
}

export function clearDocuments() {
    return {
        type: CLEAR_FILES_UPLOAD
    };
}

export function save() {
    return (dispatch, getState) => {
        const { documents, isSavingRequested } = getState().documentsAddPopupData;
        if (isSavingRequested) {
            const { contacts, types, currentUserId, customerViewCompanyId } = getState().documentsLibraryPopupsData;

            const preparedDocuments = documents.map((document) => {
                const { shareContacts, shareType } = document;

                /* destructuring (comment near the accumulator initial value) */
                const [usersIds, groupsIds] = separateContacts(contacts);
                const selectedUsersIds = usersIds.filter(id => shareContacts.has(id));
                const selectedGroupsIds = groupsIds.filter(id => shareContacts.has(id));
                const whoCanView = {
                    usersIds: [currentUserId],
                    groupsIds: []
                };
                const whoCanEdit = {
                    usersIds: [currentUserId],
                    groupsIds: []
                };

                switch (shareType) {
                    case 0: {
                        /* all users and groups can view and edit */
                        whoCanView.usersIds = usersIds;
                        whoCanView.groupsIds = groupsIds;
                        whoCanEdit.usersIds = usersIds;
                        whoCanEdit.groupsIds = groupsIds;
                        break;
                    }
                    case 1: {
                        /* all users and groups can view */
                        whoCanView.usersIds = usersIds;
                        whoCanView.groupsIds = groupsIds;

                        /* only selected users and groups can edit */
                        whoCanEdit.usersIds = selectedUsersIds;
                        whoCanEdit.groupsIds = selectedGroupsIds;
                        break;
                    }
                    case 2: {
                        /* only selected users and groups can view */
                        whoCanView.usersIds = selectedUsersIds;
                        whoCanView.groupsIds = selectedGroupsIds;
                        /* only current user can edit */
                        break;
                    }
                    default: {
                        break;
                    }
                }

                let documentTypeName = '';
                let type = types.find(({ value })=> value === document.typeId);
                if (type) {
                    documentTypeName = type.title;
                }

                return {
                    title: document.name.toLowerCase().trim(),
                    description: document.description,
                    documentTypeId: document.typeId,
                    documentTypeName,
                    projectsIds: document.projectId,
                    file: document.backEndData,
                    whoCanView,
                    whoCanEdit,
                    selectedCompany: document.shareWith
                };
            });

            const params = {};

            if (customerViewCompanyId !== null) {
                params.overrideForCompanyId = customerViewCompanyId;
            }

            http.post(
                'save documents',
                params,
                {
                    body: JSON.stringify(preparedDocuments),
                    headers: {
                        'content-type': 'application/json'
                    }
                }
            ).then(() => {
                dispatch(updateDocumentsLibraryData());
                dispatch(updatePageToReload());
                dispatch(addNotification({
                    text: 'Files successfully uploaded.',
                    duration: 5000
                }));
                dispatch(clearDocuments());
                dispatch(unsetSavingRequested());
            });
        } else {
            dispatch(setSavingRequested());
        }
    };
}

export function createNewProject(name = '', description) {
    return (dispatch) => {
        return http.post('save project', { name: name.trim(), description })
            .then((response) => {
                dispatch(updateDocumentsLibraryData());
                dispatch(updatePageToReload());
                return Promise.resolve(response);
            });
    };
}

export function updateDocumentTypes() {
    return (dispatch) => {
        http.get('get document types')
            .then((response) => {
                dispatch(storeTypes(response.map(normalizeForSelect)));
            });
    };
}

export function incCurrentDocumentIndex() {
    return {
        type: INC_CURRENT_DOCUMENT_INDEX,
    };
}

export function setCurrentDocumentIndex(index) {
    return {
        type: SET_CURRENT_DOCUMENT_INDEX,
        index,
    }
}
