import { SEARCH_POPUP, SEARCH_POPUP_CLOSE } from '../actions/actionsNamesList';

const {
    CHANGE_QUERY, UPDATE_DATA, OPEN_CATEGORY, CLOSE_CATEGORY, SET_LOADING, UNSET_LOADING,
    DONT_SHOW_DOCUMENTS_ANYWAY, SHOW_DOCUMENTS_ANYWAY, SEARCH_POPUP_HIDE_SPINNER, SEARCH_POPUP_SHOW_SPINNER,
    UPDATE_CHAINS_WHO_OFFERS_COUNT,
} = SEARCH_POPUP;

const defaultState = {
    query: '',
    isLoading: false,
    data: [],
    openedCategory: '',
    showDocumentsAnyway: false,
    isSpinnerShown: false,
    chainsWhoOffersCount: 0,
};

export default function searchPopupData(state = defaultState, action) {
    switch (action.type) {
        case CHANGE_QUERY: {
            return {
                ...state,
                query: action.searchQuery
            };
        }
        case SEARCH_POPUP_CLOSE: {
            return {
                ...state,
                query: '',
                openedCategory: '',
                chainsWhoOffersCount: 0,
            };
        }

        case UPDATE_DATA: {
            return {
                ...state,
                data: action.data
            };
        }

        case UPDATE_CHAINS_WHO_OFFERS_COUNT: {
            return {
                ...state,
                chainsWhoOffersCount: action.count,
            }
        }

        case OPEN_CATEGORY: {
            return {
                ...state,
                openedCategory: action.categoryName
            };
        }
        case CLOSE_CATEGORY: {
            return {
                ...state,
                openedCategory: ''
            };
        }
        case SET_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }
        case UNSET_LOADING: {
            return {
                ...state,
                isLoading: false
            };
        }
        case SHOW_DOCUMENTS_ANYWAY: {
            return {
                ...state,
                showDocumentsAnyway: true,
            };
        }
        case DONT_SHOW_DOCUMENTS_ANYWAY: {
            return {
                ...state,
                showDocumentsAnyway: false,
            }
        }
        case SEARCH_POPUP_SHOW_SPINNER: {
            return {
                ...state,
                isSpinnerShown: true,
            };
        }
        case SEARCH_POPUP_HIDE_SPINNER: {
            return {
                ...state,
                isSpinnerShown: false,
            }
        }
        default:
            return state;
    }
}
