import React, { FC } from "react";
import cn from "classnames";
import "./ChartTooltipMultiline.styles.scss";
import { capitalize } from "@core/old_helpers";
import { iTooltipRow } from "@components/Chart/ChartTooltipMultiline/ChartTooltipMultiline.helpers";

const ChartTooltipMultiline: FC<{
    title: string;
    rows: iTooltipRow[];
    columns: string[];
    x: number;
    y: number;
    position: 'top' | 'bottom';
    align: 'left' | 'center' | 'right';
    hasHeader: boolean;
}> = (
    {
        title,
        rows,
        columns,
        x,
        y,
        position,
        align,
        hasHeader,
    }
) => {
    return (
        <div
            className="ChartTooltipMultiline"
            style={{
                transform: `translate(${x}px, ${y}px)`,
            }}
        >
            <div className={cn(
                "ChartTooltipMultiline__arrow",
                `p-${position}`,
                `a-${align}`,
            )}/>
            <div className={cn(
                "ChartTooltipMultiline__inner",
                `p-${position}`,
                `a-${align}`,
            )}>
                {hasHeader && (
                    <div className="ChartTooltipMultiline__header">
                        <div className="ChartTooltipMultiline__row is-active">
                            <div className="ChartTooltipMultiline__cell">
                                {title}
                            </div>
                            {columns.map((columnTitle) => (
                                <div key={columnTitle} className="ChartTooltipMultiline__cell">
                                    {columnTitle}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <div className="ChartTooltipMultiline__body">
                    {rows.map((row, rowIndex) => (
                        <div
                            key={`${row.title} ${row.values.join(' | ')} ${row.isActive ? 'active' : ''}`}
                            className={cn("ChartTooltipMultiline__row", row.isActive && 'is-active')}
                        >
                            <div className="ChartTooltipMultiline__cell">
                                {row.title}
                            </div>
                            {columns.map((column, columnIndex) => (
                                <div
                                    key={row.values[columnIndex]}
                                    className="ChartTooltipMultiline__cell"
                                >
                                    {row.values[columnIndex] || '-'}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ChartTooltipMultiline;
