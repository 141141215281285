import { Settings } from "react-slick";

export const SLIDER_CONFIG_COLUMNS: Settings = {
    slidesToShow: 5,
    slidesToScroll: 5,
    swipeToSlide: true,
    touchThreshold: 40,
    infinite: false,
    draggable: false,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            },
        },
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 567,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

export const SLIDER_CONFIG_ROWS: Settings = {
    slidesToShow: 1,
    rows: 2,
    slidesPerRow: 1,
    slidesToScroll: 1,
    infinite: false,
    touchMove: true,
    draggable: true,
    swipeToSlide: true,
    touchThreshold: 40,
    arrows: true,
    className: "is-multi-row is-outside-arrow",
};