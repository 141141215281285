import { Component } from 'react';
import ReactDOM from 'react-dom';
import PT from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { NavLink } from 'react-router-dom';

class CompaniesListDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: ''
        };
        this.setRef = this.setRef.bind(this);
        this.close = this.close.bind(this);
        this.closeOnEsc = this.closeOnEsc.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.close, true);
        document.addEventListener('touchend', this.close, true);
        document.addEventListener('keydown', this.closeOnEsc, true);

        this.dropDown.focus();
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.close, true);
        document.removeEventListener('touchend', this.close, true);
        document.removeEventListener('keydown', this.closeOnEsc, true);
    }

    closeOnEsc(e = false) {
        if (e.code === 'Escape') {
            this.props.close();
            e.stopPropagation();
        }
    }

    close(e = false) {
        if (!e || !ReactDOM.findDOMNode(this.dropDown).contains(e.target)) {
            this.props.close();
        }
    }

    setRef(dropDown) {
        this.dropDown = dropDown;
    }

    render() {
        const { data = [] } = this.props;
        return (
            <div
                ref={this.setRef}
                className="companies-list-drop-down"
                data-testid={this.props.testId}
            >
                <div>
                    <input
                        value={this.state.value}
                        placeholder="Search company..."
                        autoFocus={true}
                        className="companies-list-drop-down__input"
                        onChange={({ target }) => this.setState({ searchValue: target.value.toLowerCase() })}
                        data-testid="inputCompaniesListDropDown"
                    />
                </div>
                <div
                    className="companies-list-drop-down__options"
                    data-testid="selectCompaniesListDropDown"
                >
                    <Scrollbars>
                        {
                            data
                                .filter(
                                    ({ name }) => name.toLowerCase().indexOf(this.state.searchValue) > -1
                                )
                                .sort(({ name: a }, { name: b }) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1))
                                .map(
                                    ({ name, link }, index) => (
                                        <div
                                            key={index}
                                            className="companies-list-drop-down__option"
                                            data-testid="buttonCompaniesListDropDownItem"
                                        >
                                            <NavLink
                                                to={link}
                                                className="companies-list-drop-down__link"
                                            >
                                                {name}
                                            </NavLink>
                                        </div>
                                    )
                                )
                        }
                    </Scrollbars>
                </div>
            </div>
        );
    }
}

CompaniesListDropDown.propTypes = {
    data: PT.array,
    close: PT.func,
    testId: PT.string,
};

export default CompaniesListDropDown;
