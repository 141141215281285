import { FC } from "react";
import "./SectionHeader.styles.scss";

interface iProps {
    title: string;
}

const SectionHeader: FC<iProps> = ({ title, children }) => {
    return (
        <div className="SectionHeader">
            <div className="SectionHeader__wrapper">
                <div className="SectionHeader__title">
                    {/* TODO: review later */}
                    <svg width="100%" height="48px">
                        <defs>
                            <linearGradient id="text" x1="0" y1="0" x2="100%" y2="0">
                                <stop stopColor="#56CCF2" offset="0" />
                                <stop stopColor="#1485C6" offset="100%" />
                            </linearGradient>
                        </defs>
                        <text
                            x="0%"
                            y="0%"
                            dy="40px"
                            fill="url(#text)"
                        >
                            {title}
                        </text>
                    </svg>
                </div>
                <div className="SectionHeader__actions">{children}</div>
            </div>
        </div>
    );
};

export default SectionHeader;
