// TODO move to constants & add groups to object
export const routeNames = {
    home: '/',
    search: '/search',
    food: '/food',
    documents: '/documents',
    places: '/places',
    viewDocument: '/view-document',
    topChains: '/topChains',
    topChainsNew: '/topChainsNew',
    fireflyChains: '/fireflyChains',
    chainDetails: '/chainDetails',
    allReports: '/allReports',
    error: '/404',
    myDocuments: '/myDocuments',
    projects: '/project',
    documentType: '/documentType',
    allMacs: '/allMacs',
    instantCharts: '/instantCharts',
    packages: '/packages',
    groups: '/groups',
    test: '/test'
};