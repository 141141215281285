import DocumentActions from "@components/new/DocumentActions/DocumentActions";
import { typesSelector } from "@containers/DocumentsLibraryPopups/documentsLibraryPopups.selectors";
import getFileSize from "@core/getFileSize";
import { routeNames } from "@core/routeNames";
import serializeParams from "@core/serializeParams";
import { getEnding, getFormattedDate } from "@helpers";
import { useCopyToClipboard } from "@hooks/useCopyToClipboard";
import { iDocument } from "@models/Documents/Documents";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./DocumentViewItem.styles.scss";
import Icon from "@components/SpriteIcon/SpriteIcon";
import getFileTypeIcon from "@core/getFileTypeIcon";

interface iProps {
    data: iDocument;
}

const DocumentViewItem: FC<iProps> = ({ data }) => {
    const {
        id,
        imageUrl,
        title,
        project,
        date,
        size,
        pages,
        copyUrl,
        docType,
        projectId,
        downloadUrl,
        canEdit,
        documentTypeId,
        fileExtention,
    } = data;
    const fileIcon = `${getFileTypeIcon(fileExtention.substring(1))}`;

    const fileSize = getFileSize(parseInt(size));
    const copyToClipboard = useCopyToClipboard();
    const documentsTypes = useSelector(typesSelector);
    const isDocumentsTypesLoading = !documentsTypes.length;

    const getEditData = () => {
        if ( !canEdit || isDocumentsTypesLoading ) return;
        return {
            id,
            typeId: documentTypeId,
            name: title,
            initialName: title,
            fileSize: +size,
            size: +size,
            projectId,
            thumnailUrl: imageUrl,
            thumnailExist: !!imageUrl,
        }
    };
    const handleCopy = () => {
        copyToClipboard(
            copyUrl,
            'Link copied to your clipboard. You can paste it into email or any else you need.'
        );
    }

    return (
        <div className="DocumentViewItem">
            <div className="DocumentViewItem__preview">
                {imageUrl && (
                    <img
                        className="DocumentViewItem__image"
                        src={imageUrl}
                        alt={title}
                        data-testid="blockDocumentPageImage"
                    />
                )}
                {!imageUrl && (
                    <Icon
                        className="DocumentViewItem__image DocumentViewItem__image--blank"
                        // @ts-ignore
                        iconId={fileIcon}
                        data-testid="blockDocumentPageIcon"
                    />
                )}
            </div>

            <div className="DocumentViewItem__details">
                <div className="DocumentViewItem__group">
                    <div
                        className="DocumentViewItem__copy"
                        onClick={handleCopy}
                        data-testid="buttonDocumentPageCopyLink"
                    >
                        Copy link
                    </div>
                </div>
                <div className="DocumentViewItem__group">
                    <div
                        className="DocumentViewItem__title"
                        data-testid="textDocumentPageName"
                    >
                        {title}
                    </div>
                    <div className="DocumentViewItem__actions">
                        <DocumentActions
                            downloadUrl={downloadUrl}
                            packageData={{ id, docType }}
                            shareData={{ id, title, shareLinkString: copyUrl }}
                            editData={getEditData()}
                        />
                    </div>
                </div>

                <div className="DocumentViewItem__info">
                    {date && (
                        <div
                            className="DocumentViewItem__info-item"
                            data-testid="textDocumentPageDate"
                        >
                            {getFormattedDate(`${date}`, "MMMM YYYY")}
                        </div>
                    )}
                    {pages > 0 && (
                        <div
                            className="DocumentViewItem__info-item"
                            data-testid="textDocumentPagePagesCount"
                        >
                            {pages} {getEnding("page", pages)}
                        </div>
                    )}
                    {project && (
                        <div className="DocumentViewItem__info-item">
                            <Link
                                className="DocumentViewItem__info-link"
                                to={{
                                    pathname: routeNames.projects,
                                    search: `?${serializeParams({ id: projectId })}`,
                                }}
                                data-testid="buttonDocumentPageProjectLink"
                            >
                                {project}
                            </Link>
                        </div>
                    )}
                    {size && (
                        <div className="DocumentViewItem__info-item">
                            <span data-testid="textDocumentPageFileSize">
                                {Math.round(fileSize.value)} {fileSize.units}
                            </span>
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
};

export default DocumentViewItem;
