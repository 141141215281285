import { useData } from "@hooks/useData";
import {
    API__NewAllMenuTrendsPenetrationViewModel,
    API__PredictedHaikuData,
} from "@apiSchema";
import http from '../fetch';
import { FoodProfileHaiku } from "@models/FoodProfileHaiku";

async function getFoodProfileHaikuData(word: string, totalUsPenetration?: API__NewAllMenuTrendsPenetrationViewModel): Promise<FoodProfileHaiku> {
    try {
        const response: API__PredictedHaikuData = await http.get('food tab haiku data', { word });

        return new FoodProfileHaiku({
            apiModel: {
                predictionModel: response,
                penetrationModel: totalUsPenetration,
            }
        });
    }
    catch (err) {
        return new FoodProfileHaiku( );
    }
}

export function useFoodProfileHaikuData(word: string, totalUsPenetration?: API__NewAllMenuTrendsPenetrationViewModel) {
    return useData(new FoodProfileHaiku(), getFoodProfileHaikuData, word, totalUsPenetration);
}