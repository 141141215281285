import { PureComponent } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { capitalize } from '../../core/old_helpers';

import { getGroupsData, createGroup, editGroup, deleteGroup, open, close } from '../../actions/groups.actions';
import Page from '../../containers/Page/Page';
import PageContent from '../../containers/PageContent/PageContent';
import Scrollbar from 'react-custom-scrollbars';
import SpriteIcon from '../../components/SpriteIcon/SpriteIcon';
import ConfirmPopup from './confirmPopup';
import GroupsPopup from './groupsPopup';
import './Groups.scss';

class Groups extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            confirmPopupVisible: false,
            groupsPopupVisible: false,
            confirmCallback: null,
            groupsPopupSaveCallback: null,
            groupsPopupType: false,
            groupsPopupData: {},
        };

        this.editGroup = this.editGroup.bind(this);
        this.leaveGroup = this.leaveGroup.bind(this);
        this.deleteGroup = this.deleteGroup.bind(this);
        this.closeGroupsPopup = this.closeGroupsPopup.bind(this);
        this.closeConfirmPopup = this.closeConfirmPopup.bind(this);
    }

    componentDidMount() {
        this.props.getGroups();
    }

    openConfirmPopup(text, callback) {
        const { open } = this.props;
        open();
        this.setState({
            confirmPopupText: text,
            confirmCallback: callback,
            confirmPopupVisible: true,
        });
    }

    closeConfirmPopup() {
        const { close } = this.props;
        close();
        this.setState({
            confirmPopupText: '',
            confirmPopupVisible: false,
        });
    }

    openGroupsPopup(options) {
        const { open } = this.props;
        open();
        this.setState({
            groupsPopupTitle: options.title,
            groupsPopupSaveCallback: options.callback,
            groupsPopupVisible: true,
            groupsPopupType: options.popupType || '',
            groupsPopupData: options.data,
        });
    }

    closeGroupsPopup() {
        const { close } = this.props;
        close();
        this.setState({
            groupsPopupVisible: false,
            groupsPopupTitle: '',
            groupsPopupType: '',
        });
    }

    initCreateGroup() {
        const title = 'Create team';
        const callback = (data) => {
            this.props.createGroup(data)
                .then(this.closeGroupsPopup);
        };
        this.openGroupsPopup({
            title,
            callback,
            popupType: 'CREATE_GROUP',
        });
    }

    editGroup(group, event) {
        event.stopPropagation();
        const title = 'Rename team';
        const callback = (params) => {
            this.props.editGroup(params)
                .then(this.closeGroupsPopup);
        };
        this.openGroupsPopup({
            title,
            callback,
            popupType: 'EDIT_GROUP',
            data: {
                groupName: group.name,
                groupId: group.id,
                groupMembers: group.members,
            },
        });
    }

    leaveGroup(group, groupIndex, event) {
        event.stopPropagation();
        console.log(`leave ${group.id}`);
        const text = `Are you sure you want to LEAVE ${group.name}?`;
        const callback = () => {
            this.props.leaveGroup(group.id)
                .then(() => {
                    this.closeConfirmPopup();
                    const group = this.props.groups[groupIndex];
                    if (group.members.length < 1) {
                        this.props.deleteGroup(group.id);
                    }
                });
        };
        this.openConfirmPopup(text, callback);
    }

    deleteGroup(group, event) {
        event.stopPropagation();
        const text = `Are you sure, you want to DELETE "${group.name}" team?`;
        const callback = () => {
            this.props.deleteGroup(group.id)
                .then(() => this.closeConfirmPopup());
        };
        this.openConfirmPopup(text, callback.bind(this, group.id));
    }

    renderGroupsList() {
        return this.props.groups.map((item, index) => {
            const membersNames = item.members
                .concat([])
                .sort((a, b) => {
                    if (a.userName) return -1;
                    else if (b.userName) return 1;
                    return -1;
                })
                .map((member) => {
                    if (member.userName) return capitalize(member.userName);
                    return member.email;
                });
            const membersList = membersNames.map((elem, index) => {
                if (index < 9) {
                    return <div key={index} className="group-tile__user-name">{elem}</div>;
                }
            });

            return (
                <div className="groups__item group-tile fadeInUp" key={index}>
                    <div className="group-tile__title-wrap">
                        <div className="group-tile__title" title={item.name}>
                            {item.name}
                        </div>
                    </div>
                    <div className="group-tile__text">
                        <div className="group-tile__text-header">
                            {item.members.length} member{item.members.length !== 1 ? 's' : ''}
                        </div>
                        <div className="group-tile__text-content">{membersList}</div>
                        {
                            item.members.length > 9 && <div className="group-tile__text-footer">
                                ... plus {item.members.length - 9} more
                            </div>
                        }
                    </div>
                    <div className="groups__item-actions">
                        <button
                            className="groups__item-action"
                            onClick={e => this.editGroup(item, e)}
                        >
                            <SpriteIcon iconId="edit" className="groups__action-icon"/>
                            <div>edit</div>
                        </button>
                        <button
                            className="groups__item-action"
                            onClick={e => this.deleteGroup(item, e)}
                        >
                            <SpriteIcon iconId="delete" className="groups__action-icon"/>
                            <div>delete</div>
                        </button>
                    </div>
                </div>
            );
        });
    }

    render() {
        const { groupsLoading, isOpened } = this.props;
        return (
            <Page>
                <PageContent className="groups">
                    {
                        groupsLoading ?
                            (
                                <div className="documents__loading">
                                    <div className="spinner-pow">
                                        <SpriteIcon iconId="logo-dark" width="200" height="200"/>
                                    </div>
                                </div>
                            ) :
                            (
                                <div>
                                    <div className="groups__header">
                                        <span className="groups__header-title">
                                            Teams
                                        </span>
                                    </div>
                                    <div className="groups__scrollbar-outer">
                                        <Scrollbar>
                                            <div className="groups__content">
                                                <div className="groups__grid-header">
                                                    TEAMS help you share reports and documents with your colleagues.
                                                    You can create as many teams as you like,
                                                    and then add people to those teams.
                                                </div>
                                                <ul className="groups__list">
                                                    <div
                                                        onClick={this.initCreateGroup.bind(this)}
                                                        className="groups__item group-tile fadeInUp"
                                                    >
                                                        <div className="group-tile__button">
                                                            <div className="group-tile__button--inner">
                                                                <div className="group-tile__wrapper">
                                                                    <div className="group-tile__icon-plus"/>
                                                                    <div
                                                                        className={`group-tile__title
                                                                    group-tile__title--shrink`}
                                                                    >
                                                                        Create Team
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {this.renderGroupsList()}
                                                </ul>
                                            </div>
                                        </Scrollbar>
                                    </div>
                                </div>
                            )
                    }
                    {
                        this.state.confirmPopupVisible &&
                        <ConfirmPopup
                            onConfirm={this.state.confirmCallback}
                            onCancel={this.closeConfirmPopup.bind(this)}
                            text={this.state.confirmPopupText}
                            isOpened={isOpened}
                        />
                    }
                    {
                        this.state.groupsPopupVisible &&
                        <GroupsPopup
                            onSave={this.state.groupsPopupSaveCallback}
                            onCancel={this.closeGroupsPopup.bind(this)}
                            title={this.state.groupsPopupTitle}
                            data={this.state.groupsPopupData}
                            popupType={this.state.groupsPopupType}
                            isOpened={isOpened}
                        />
                    }
                </PageContent>
            </Page>
        );
    }
}

Groups.propTypes = {
    groups: PT.array,
    getGroups: PT.func,
    createGroup: PT.func,
    editGroup: PT.func,
    leaveGroup: PT.func,
    deleteGroup: PT.func,
    addMembers: PT.func,
    removeMember: PT.func,
    groupsLoading: PT.bool,
    open: PT.func,
    close: PT.func,
};

const mapStateToProps = state => ({
    groups: state.groupsData.groups,
    groupsLoading: state.groupsData.groupsLoading,
    isOpened: state.groupsData.popupIsOpened,
});

const mapDispatchToProps = dispatch => ({
    getGroups: () => dispatch(getGroupsData()),
    createGroup: data => dispatch(createGroup(data)),
    editGroup: data => dispatch(editGroup(data)),
    deleteGroup: id => dispatch(deleteGroup(id)),
    open: () => dispatch(open()),
    close: () => dispatch(close()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
