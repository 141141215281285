import { API__LocationsRootObject } from "../apiSchema";

export enum TerrainEnum {
    Urban = "Urban",
    Suburban = "Suburban",
    Rural = "Rural"
}
export interface iTerrain {
    name: TerrainEnum;
    stores: number;
    percentLocation: number;
}

export class Terrain implements iTerrain {
    name = TerrainEnum.Urban;
    stores = 0;
    percentLocation = 0;

    constructor(data?: iTerrain) {
        if (data) {
            this.setData(data);
        }
    }

    static parseAllFromApi(data: API__LocationsRootObject): iTerrain[] {
        return [
            {
                name: TerrainEnum.Urban,
                stores: data.urbanCount,
                percentLocation: data.urbanPercent,
            },
            {
                name: TerrainEnum.Suburban,
                stores: data.suburbanCount,
                percentLocation: data.suburbanPercent,
            },
            {
                name: TerrainEnum.Rural,
                stores: data.ruralCount,
                percentLocation: data.ruralPercent,
            },
        ];
    }

    private setData(data: iTerrain) {
        ({
            name: this.name,
            percentLocation: this.percentLocation,
            stores: this.stores,
        } = data);
    }
}