import { useCallback, useMemo } from "react";
import {
    getIsFilterModified,
    getTabOtherSubTitle,
    getTabSubTitle,
    isUnitsCountFilterModified,
    iTab
} from "./Filters.helpers";
import FilterSelectGrid from "./FilterSelectGrid";
import InputField from "../../../components/new/InputField/InputField";
import { ChainsFilters, iChainsFilters } from "../../../core/models/ChainsFilters";
import InputRadio from "../../../components/new/InputRadio/InputRadio";
import Checkbox from "../../../components/new/Checkbox/Checkbox";
import { Alcohol, getAlcoholString, segmentsConfig } from "../../../core/models/ChainBase";
import { equalInLC, prepareForMatching, toggleInArray } from "../../../core/helpers";
import { LocationsTab } from "../NewTopChains";
import { IconClose, IconSearchWhite } from "@icons";

function formatUnitsInput(value: string): number {
    return +value
        .replace(/\D/g, '')
        .slice(0, 5);

}

export function useFiltersTabs(
    selectedFilters: iChainsFilters,
    filtersData: iChainsFilters,
    handleFilterChange: (filterName: keyof iChainsFilters) => (value: any) => void,
    selectedLocationsTab: LocationsTab,
    setSelectedLocationsTab: (tab: LocationsTab) => void,
    searchQuery: string,
    setSearchQuery: (searchQuery: string) => void,
): iTab[] {
    const handleFilterItemToggle = (filterName: keyof iChainsFilters) => (value: string[]) => {
        handleFilterChange(filterName)(value);
    };

    const {
        segments: selectedSegments,
        menuTypes: selectedMenuTypes,
        metros: selectedMetros,
        states: selectedStates,
        minUnits: selectedMinUnits,
        maxUnits: selectedMaxUnits,
        alcohol: selectedAlcohol,
    } = selectedFilters;

    const {
        segments,
        menuTypes,
        metros,
        states,
        minUnits,
        maxUnits,
        alcohol,
    } = filtersData;


    const locationsData = useMemo(() => ({
        selected: selectedLocationsTab === "metros" ? selectedMetros : selectedStates,
        all: selectedLocationsTab === "metros" ? metros : states
    }), [metros, selectedLocationsTab, selectedMetros, selectedStates, states]);

    const handleSelectAlcoholService = useCallback((value: Alcohol) => {
        handleFilterChange("alcohol")(toggleInArray(selectedAlcohol, value));
    }, [handleFilterChange, selectedAlcohol]);

    return useMemo(() => [
        {
            renderThumb: <>
                <InputField
                    onChange={value => setSearchQuery(value)}
                    value={searchQuery}
                    placeholder="Search chain..."
                    style={{
                        input: {
                            paddingLeft: 32,
                            paddingRight: 16,
                            paddingTop: 8,
                        },
                    }}
                >
                    <IconSearchWhite style={{
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        left: 0,
                    }}/>
                    {!!prepareForMatching(searchQuery) && (
                        <IconClose
                            style={{
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                right: 2,
                                cursor: 'pointer',
                                opacity: 0.5,
                            }}
                            onClick={() => setSearchQuery('')}
                        />
                    )}
                </InputField>
            </>,
            isModified: !!prepareForMatching(searchQuery),
            content: null,
        },
        {
            title: 'SEGMENT',
            subTitle: getTabSubTitle(selectedSegments, segments, 'segments'),
            isModified: getIsFilterModified(selectedSegments, segments),
            content: (
                <>
                    {segmentsConfig.map(segmentGroup => {
                        const items = segments.filter(s => segmentGroup.items.some(i => equalInLC(i, s)));

                        if (items.length === 0) return null;

                        return (
                            <FilterSelectGrid
                                key={segmentGroup.title + segmentGroup.items.join('-')}
                                data={items}
                                value={selectedSegments}
                                setValue={handleFilterItemToggle("segments")}
                                itemsPerRow={6}
                                isSearchable={false}
                                title={segmentGroup.title}
                                isBodyFluid
                            />
                        );
                    })}
                    {(() => {
                        const items = segments.filter(
                            s => segmentsConfig.every(
                                sg => sg.items.every(i => !equalInLC(i, s))
                            )
                        );

                        if (items.length === 0) return null;

                        return (
                            <FilterSelectGrid
                                data={items}
                                value={selectedSegments}
                                setValue={handleFilterItemToggle("segments")}
                                itemsPerRow={6}
                                isSearchable={false}
                                title="Other segments"
                                isBodyFluid
                            />

                        )
                    })()}
                </>
            ),
        },
        {
            title: 'LOCATIONS',
            subTitle: getTabSubTitle(locationsData.selected, locationsData.all, selectedLocationsTab),
            isModified: getIsFilterModified(locationsData.selected, locationsData.all),
            content: (
                <>
                    <div className="FiltersContent__columns">
                        <div className="FiltersContent__column">
                            <div className="FiltersContent__radio">
                                <InputRadio
                                    isActive={selectedLocationsTab === "states"}
                                    onChange={() => setSelectedLocationsTab("states")}
                                    label="STATES"
                                    modifiers={["inherit-label"]}
                                />
                            </div>
                            <div className="FiltersContent__grid">
                                <FilterSelectGrid
                                    data={states}
                                    value={selectedStates}
                                    setValue={handleFilterItemToggle("states")}
                                    isDisabled={selectedLocationsTab !== "states"}
                                />
                            </div>
                        </div>
                        <div className="FiltersContent__column FiltersContent__column--divider"/>
                        <div className="FiltersContent__column">
                            <div className="FiltersContent__radio">
                                <InputRadio
                                    isActive={selectedLocationsTab === "metros"}
                                    onChange={() => setSelectedLocationsTab("metros")}
                                    label="METROS"
                                    modifiers={["inherit-label"]}
                                />
                            </div>
                            <div className="FiltersContent__grid">
                                <FilterSelectGrid
                                    data={metros}
                                    value={selectedMetros}
                                    setValue={handleFilterItemToggle("metros")}
                                    isDisabled={selectedLocationsTab !== "metros"}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )
        },
        {
            title: 'OTHER FILTERS',
            subTitle: getTabOtherSubTitle(
                selectedMinUnits,
                minUnits,
                selectedMaxUnits,
                maxUnits,
                selectedAlcohol,
                alcohol,
                selectedMenuTypes,
                menuTypes,
            ),
            isModified: isUnitsCountFilterModified(selectedMinUnits, minUnits, selectedMaxUnits, maxUnits)
                || getIsFilterModified(selectedAlcohol, alcohol)
                || getIsFilterModified(selectedMenuTypes, menuTypes),
            isInvalid: selectedMinUnits > selectedMaxUnits || !selectedMinUnits || !selectedMaxUnits,
            content: (
                <div className="FiltersContent__rows">
                    <div className="FiltersContent__row">
                        <div
                            className="FiltersContent__columns FiltersContent__columns--fluid FiltersContent__columns--border-bottom">
                            <div className="FiltersContent__column FiltersContent__column--fluid">
                                <>
                                    <div className="FiltersContent__header">
                                        <div className="FiltersContent__title">
                                            CHAIN SIZE&nbsp;
                                            <div className="FiltersContent__sub-title">
                                                (# of units)
                                            </div>
                                        </div>
                                    </div>
                                    <div className="FiltersContent__body FiltersContent__body--fluid">
                                        <div className="FiltersContent__part">
                                            <div className="FiltersContent__input">
                                                <InputField
                                                    label="From"
                                                    onChange={(value) => handleFilterChange("minUnits")(formatUnitsInput(value))}
                                                    hasError={!selectedMinUnits || selectedMaxUnits < selectedMinUnits}
                                                    value={`${selectedMinUnits}`}
                                                    style={{
                                                        input: {
                                                            paddingRight: 0,
                                                        }
                                                    }}
                                                />
                                                <div className="FiltersContent__label">
                                                    units
                                                </div>
                                            </div>
                                            <div className="FiltersContent__input">
                                                <InputField
                                                    label="To"
                                                    onChange={(value) => handleFilterChange("maxUnits")(formatUnitsInput(value))}
                                                    hasError={!selectedMaxUnits || selectedMaxUnits < selectedMinUnits}
                                                    value={`${selectedMaxUnits}`}
                                                    style={{
                                                        input: {
                                                            paddingRight: 0,
                                                        }
                                                    }}
                                                />
                                                <div className="FiltersContent__label">
                                                    units
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </div>

                            <div
                                className="FiltersContent__column FiltersContent__column--fluid FiltersContent__column--border">
                                <>
                                    <div className="FiltersContent__header">
                                        <div className="FiltersContent__title">ALCOHOL SERVICE</div>
                                    </div>
                                    <div className="FiltersContent__body FiltersContent__body--fluid">
                                        <div className="FiltersContent__checkboxes">
                                            {alcohol.map(el => {
                                                return (
                                                    <div className="FiltersContent__checkbox" key={el}>
                                                        <Checkbox
                                                            isChecked={selectedAlcohol.includes(el)}
                                                            label={getAlcoholString(el)}
                                                            onChange={() => handleSelectAlcoholService(el)}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>

                    <div className="FiltersContent__header">
                        <div className="FiltersContent__title">MENU TYPE</div>
                    </div>
                    <div className="FiltersContent__row FiltersContent__row--fluid">
                        <div className="FiltersContent__column">
                            <FilterSelectGrid
                                data={menuTypes}
                                value={selectedMenuTypes}
                                setValue={handleFilterItemToggle("menuTypes")}
                                itemsPerRow={6}
                                isBodyFluid
                            />
                        </div>
                    </div>
                </div>
            )
        },
    ], [JSON.stringify(selectedFilters), JSON.stringify(filtersData), selectedLocationsTab, searchQuery])
}