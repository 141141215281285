import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { addNotification } from '../../../components/GlobalNotification/globalNotification.actions';
import Clipboard from 'clipboard';
import PackageAddWrapper from '@components/PackageAddWrapper/PackageAddWrapper';
import { addFileToPackage } from '../../../actions/packages.actions';
import {
    getMacDetailsData,
    setMacDetailsBackgroundImage,
    setMacDetailsBackgroundImageStatus,
    setDefaultMacDetailsBackgroundImage,
    getMacMenuExamplesData,
    resetMacMenusData,
    getMacDownloadLink
} from '../../../actions/macDetails.actions';
import {
    macDataSelector,
    macWords169Selector,
    macWords43Selector,
    macDataTitleSelector,
    macDataOptionsSelector,
    macBackgroundUrlSelector,
    macUploadingStateSelector,
    macDownloadUrlSelector,
    macDefaultBackgroundUrlSelector,
    macLoadingDownloadLinkStateSelector,
    macTableDataSelector,
    macResettingBgStateSelector,
    macMenusLoadingSelector,
    macFileSizeStatusSelector
} from '../../../selectors/macDetails.selectors';
import SpriteIcon from '../../../components/SpriteIcon/SpriteIcon';
import IMG_CURVE from '../../../img/Curve.svg';
import MacMenusTable from './MacDetailsMenus';
import './MacDetailsStyle.scss';
import {DocumentTypes} from "@pages/NewDocuments/DocumentView/DocumentView.helpers";

class MacDetails extends Component {
    constructor(props) {
        super(props);
        /* todo: add redirect if no mac id*/
        const macId = +(new window.URLSearchParams(this.props.location.search).get('id'));

        this.state = {
            macId,
            macIsLoaded: false,
            downloadOpened: false,
            // widescreen: this.props.data.options ? this.props.data.options.widescreen : true,
            widescreen: true,
            setAnimated: true,
            activeBackgroundMenu: 0,
            backgroundMenu: [
                {
                    name: this.props.data.options ? this.props.data.options.hasCustomBgndImage ? 'Custom' : 'Skyfield' : '',
                    url: this.props.data.options ? this.props.data.options.bgndImage : '',
                    selected: true
                }
                // {
                //     name: 'Metal',
                //     url: 'url("Snap/Client/src/img/1.jpg")',
                //     selected: false
                // }
            ],
            casingMenu: [
                {
                    name: 'ALL UPPERCASE',
                    value: 'uppercase',
                    selected: false
                },
                {
                    name: 'Proper Casing',
                    value: 'capitalize',
                    selected: false
                },
                {
                    name: 'all lowercase',
                    value: 'lowercase',
                    selected: true
                }
            ],
            tableWord: '',
            menusLoaded: false
        };

        const clipboardButton = new Clipboard('#copyTable', {
            target: (trigger) => {
                return trigger.nextElementSibling;
            }
        });

        clipboardButton.on('success', (e) => {
            e.clearSelection();

            this.props.showNotification({
                text: 'Link copied to your clipboard. You can paste it into email or any else you need.',
                duration: 3000,
            });
        });

        this.documentClickHandler = (e) => {
            if (this.state.downloadOpened && e.target.id !== 'download_mac') {
                this.setState({
                    downloadOpened: false
                });
            }
        };
    }

    componentDidMount() {
        this.props.getMacData({ id: this.state.macId });
        // this.props.getMacData({ id: this.props.documentInfo.id });
        // document.getElementById('entryPoint').addEventListener('click', this.documentClickHandler, false);
    }

    componentWillUnmount() {
        // document.getElementById('entryPoint').removeEventListener('click', this.documentClickHandler, false);
        this.props.resetMacDataStore();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data.options) {
            // const newCasingMenu = [].concat(this.state.casingMenu);
            // newCasingMenu.forEach((elem, index) => {
            //     if (elem.value === nextProps.data.options.textTransform) newCasingMenu[index].selected = true
            // });
            const newBackgroundMenu = [...this.state.backgroundMenu];
            newBackgroundMenu[0].url = nextProps.data.options.bgndImage;
            newBackgroundMenu[0].name = nextProps.data.options.hasCustomBgndImage ? 'Custom' : 'Skyfield';
            this.setState({
                backgroundMenu: newBackgroundMenu,
                // casingMenu: newCasingMenu,
                macIsLoaded: true,
                // widescreen: nextProps.data.options.widescreen,
            });
        }
        if (nextProps.defaultBgUrl.length > 0) {
            const newBackgroundMenu = [...this.state.backgroundMenu];
            newBackgroundMenu[0].url = nextProps.defaultBgUrl;
            this.setState({
                backgroundMenu: newBackgroundMenu
            });
        }
        if (nextProps.downloadUrl !== this.props.downloadUrl) {
            this.setState({
                downloadOpened: false
            });
        }

        if (this.props.menusLoading && !nextProps.menusLoading) {
            this.setState({
                menusLoaded: true
            });
        }
    }

    scrollTo(element, to, duration) {
        const start = element.scrollTop;
        const change = to - start;
        let currentTime = 0;
        const increment = 20;

        const animateScroll = function () {
            currentTime += increment;
            const val = Math.easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }

    componentDidUpdate() {
        this.animatedTimeout = setTimeout(() => {
            if (this.state.setAnimated && this.props.arrays16x9) {
                this.setState({ setAnimated: false });
            }
        }, 1400);

        if (this.state.menusLoaded) {
            return;
            const offsetTop = this.refs.DataTable.offsetTop;
            // const offsetTop = document.getElementById('data_table').offsetTop;
            window.scrollBy(0, offsetTop - 40);
            this.setState({
                menusLoaded: false
            });
        }
    }

    handleDownloadClick(e) {
        e.stopPropagation();
        this.setState({
            downloadOpened: !this.state.downloadOpened
        });
    }

    downloadUserMac(type) {
        const data = {};

        this.state.casingMenu.forEach((elem) => {
            if (elem.selected) {
                data.TextTransform = elem.value;
            }
        });

        data.BackgroundImage = this.props.customBgUrl || this.state.backgroundMenu.filter(elem => elem.selected)[0].url;
        data.Id = new window.URLSearchParams(this.props.location.search).get('id');
        data.Widescreen = this.state.widescreen;
        this.props.requestUserMac(data, type, this.refs.downloadLink);

        this.setState({
            downloadOpened: false
        });
    }

    addToPackage() {
        this.props.addFileToPackage({
            itemId: this.state.macId,
            itemDocumentType: "Mac",
        });
    }

    switchRatio(val) {
        if (this.state.widescreen === val) {
            return;
        }
        clearInterval(this.animatedTimeout);
        this.setState({
            widescreen: val,
            setAnimated: true
        });
    }

    setCase(casingMenu) {
        this.setState({
            casingMenu
        });
    }

    setBackground(backgroundMenu) {
        let isDefault = false;

        backgroundMenu.forEach((elem) => {
            if (elem.selected && elem.name === 'Skyfield' || elem.name === 'Custom') {
                isDefault = true;
            }
        });

        if (isDefault) {
            this.props.resetBackground({ macId: this.props.data.id });
            return;
        }
        this.setState({
            backgroundMenu
        });
    }

    uploadBackground(value, event) {
        if (event.target.files[0].size > 5242880) { // file larger than 5Mb
            this.props.setFileSizeStatus({
                sizeIsExceeded: true
            });
            setTimeout(() => {
                this.props.setFileSizeStatus({
                    sizeIsExceeded: false
                });
            }, 5000);
            // this.props.addNotification({
            //     text: `The maximum size of image can't be greater then 5Mb`,
            //     duration: 5000,
            //     iconDisabled: true
            // });
            value = '';
            return;
        }
        const reader = new FileReader();
        let fileBase64 = '';
        reader.onload = (loadEvent) => {
            fileBase64 = loadEvent.target.result;
            this.props.uploadNewBackground({
                imageData: fileBase64,
                macId: new window.URLSearchParams(this.props.location.search).get('id')
            });
            value = '';
        };
        reader.readAsDataURL(event.target.files[0]);
    }

    showMenuExamples(word) {
        const params = {
            id: word.id,
            text: word.text
        };
        this.setState({
            tableWord: word.text
        });
        const jsonData = JSON.stringify(word);
        this.props.getMenuExamples(params, jsonData);
    }

    renderWords(data, color, colIndex) {
        const { data: { isNonUs} } = this.props;
        return data.map((elem, index) => {
            const styleList = {
                top: `${elem.position.top}px`,
                left: `${elem.position.left}px`,
                backgroundColor: color,
                fontSize: `${this.props.options.wordsFontSize}px`
            };
            const classList = new Set([
                'canvas__word',
                `canvas__word--${this.state.casingMenu.filter(elem => elem.selected)[0].value}`,
                this.state.setAnimated ? 'animated' : ''
            ]);
            return (
                <div
                    key={index}
                    className={Array.from(classList).join(' ')}
                    style={styleList}
                    data-testid={`blockMacDetailsChartWord-${colIndex}-${index}`}
                >
                    <span
                        className="canvas__word-text"
                        data-testid={`textMacDetailsChartWord-${colIndex}-${index}`}
                    >
                        {elem.text.toLowerCase()}
                    </span>
                    {!isNonUs && (
                        <ul className="canvas__word-menu">
                            {
                                elem.hasMenuExamples &&
                                (
                                    <li
                                        className="canvas__word-menu-item"
                                        onClick={this.showMenuExamples.bind(this, elem)}
                                        data-testid={`buttonMacDetailsChartWordMenuExamples-${colIndex}-${index}`}
                                    >Menu Examples</li>
                                )
                            }
                            {
                                elem.viewInSnap && elem.penetration > 1 &&
                                (
                                    <Link
                                        to={{
                                            pathname: '/food',
                                            search: `?q=${elem.text}`
                                        }}
                                        className="canvas__word-menu-item"
                                        data-testid={`buttonMacDetailsChartWordViewInSnap-${colIndex}-${index}`}
                                    >View in SNAP !</Link>
                                )
                            }
                        </ul>
                    )}
                </div>
            );
        });
    }

    renderColumns() {
        const array = this.state.widescreen ? this.props.arrays16x9 : this.props.arrays4x3;

        const columnDescriptions = [
            "fine dining, mixology, earliest stage",
            "trendy restaurants + specialty grocers",
            "chain restaurants + mainstream grocery",
            "find it just about anywhere",
        ];

        return array.map((elem, index) => {
            const colStyles = {
                fontSize: this.props.options && `${this.props.options.bodyFont} px`
            };
            const classList = new Set([
                'canvas__col',
                this.state.widescreen ? 'canvas__col--16-9' : 'canvas__col--4-3'
            ]);
            return (
                <div
                    key={index}
                    style={colStyles}
                    className={Array.from(classList).join(' ')}
                    data-testid={`blockMacDetailsChartCol-${index}`}
                >
                    <div className="canvas__col-area">
                        {this.renderWords(elem.words, elem.color, index)}
                    </div>
                    <div className={`canvas__col-name-wrap canvas__col-name-wrap--${elem.name.toLowerCase()}`}>
                        <div
                            className="canvas__col-title"
                            data-testid={`textMacDetailsChartColTitle-${index}`}
                        >
                            {elem.name}
                        </div>
                        <div
                            className="canvas__col-description"
                            data-testid={`textMacDetailsChartColDescription-${index}`}
                        >
                            {columnDescriptions[index]}
                        </div>
                    </div>
                </div>
            );
        });
    }

    renderTableRows() {
        return this.props.tableData.map((elem, index) => {
            return (
                <tr
                    key={index}
                    className="table__data-row mac-table__row"
                >
                    <td className="table__data table__data--left">
                        {elem.RestaurantName}
                    </td>
                    <td className="table__data">
                        {elem.Name}
                    </td>
                    <td className="table__data table__data--left mac-table__description-col"
                        dangerouslySetInnerHTML={{
                            __html: elem.Description
                        }}
                    >

                    </td>
                    <td className="table__data">
                        {elem.Price}
                    </td>
                </tr>
            );
        });
    }

    render() {
        const { title = {}, options } = this.props;
        const canvasStyles = {
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            fontSize: options && `${options.bodyFont} px`,
            width: 1067,
            height: 600,
        };
        const curveClassList = new Set([
            'canvas__curve',
            this.props.arrays16x9 ? 'canvas__curve--visible' : ''
        ]);
        const curveImgClassList = new Set([
            'canvas__curve-img',
            this.state.widescreen ? 'canvas__curve-img--widescreen' : ''
        ]);
        const downloadBtnClassList = new Set([
            'btn-drop',
            this.state.downloadOpened ? '' : 'hidden'
        ]);

        const searchPropsData = new window.URLSearchParams(this.props.location.search);
        const query = searchPropsData.get('fromDocuments');
        const from = searchPropsData.get('from');
        const isFromDocuments = query !== null;
        {/*<Scrollbars onScroll={this.handleScroll} ref="MacDetailsScrollbar">*/
        }
        return (
            <div className="mac-details__wrapper">
                {
                    (this.props.uploadingBgImage || this.props.loadingDownloadLink || this.props.resettingBackground) &&
                    (
                        <div className="download-spinner-container download-spinner-container--mac-details">
                            <div className="spinner-line"></div>
                        </div>
                    )
                }
                <div className="mac-details__header">
                    <div className="mac-details__header-left">
                        <p
                            className="mac-details__title"
                            data-testid="textMacDetailsTitle"
                        >
                            {this.props.title && title.header} {this.props.publicationFamily}
                        </p>
                    </div>
                    <div className="mac-details__header-right">
                        {
                            from !== 'allMacs' &&
                            isFromDocuments &&
                            this.props.documentInfo &&
                            this.props.documentInfo.docType !== 'mac' ? (
                                <Link
                                    to={{
                                        pathname: '/documents',
                                        search: `?q=${encodeURIComponent(query)}&doctype=${this.props.documentInfo.docType}`
                                    }}
                                    className="mac-details__btn-link"
                                    data-testid="buttonMacDetailsBackToDocuments"
                                    style={{ textTransform: 'none' }}
                                >
                                    « view all MACs
                                </Link>
                            ) : (
                                <Link
                                    to={{
                                        pathname: '/allMacs',
                                        search: ''
                                    }}
                                    data-testid="buttonMacDetailsBackToList"
                                    className="mac-details__btn-link"
                                    style={{ textTransform: 'none' }}
                                >
                                    « view all MACs
                                </Link>
                            )}
                        <div
                            className="popup__btn btn--with-triangle"
                            id='download_mac'
                            data-testid="selectMacDetailsDownloadType"
                            onClick={this.handleDownloadClick.bind(this)}
                        >
                            Download
                            <ul className={Array.from(downloadBtnClassList).join(' ')}
                                onClick={e => e.stopPropagation()}>
                                <li
                                    className="btn-drop__item"
                                    onClick={this.downloadUserMac.bind(this, 'image')}
                                    data-testid="buttonMacDetailsDownloadImage"
                                >
                                    Image
                                </li>
                                <li
                                    className="btn-drop__item"
                                    data-testid="buttonMacDetailsDownloadPpt"
                                    onClick={this.downloadUserMac.bind(this, 'ppt')}
                                >
                                    Ppt
                                </li>
                            </ul>
                        </div>
                        <a
                            ref="downloadLink"
                            href={`${process.env.REACT_APP_API}${this.props.downloadUrl}`}
                            className="hidden"
                        />
                    </div>
                </div>
                <div className="mac-details">
                    <div className="mac-details__main">
                        <div className="mac-details__options">
                            {/*<div className="documents__change-view-outer">*/}
                            {/*    <button*/}
                            {/*        className={`documents__change-view-button ${this.state.widescreen ? 'documents__change-view-button--active' : ''}`}*/}
                            {/*        onClick={this.switchRatio.bind(this, true)}*/}
                            {/*    >*/}
                            {/*        16:9*/}
                            {/*    </button>*/}
                            {/*    <button*/}
                            {/*        className={`documents__change-view-button ${!this.state.widescreen ? 'documents__change-view-button--active' : ''}`}*/}
                            {/*        onClick={this.switchRatio.bind(this, false)}*/}
                            {/*    >*/}
                            {/*        4:3*/}
                            {/*    </button>*/}
                            {/*</div>*/}
                            {/*<Dropdown*/}
                            {/*    className={'dropdown-component dropdown--right-floated'}*/}
                            {/*    list={this.state.casingMenu}*/}
                            {/*    label="Casing"*/}
                            {/*    handleClick={this.setCase.bind(this)}*/}
                            {/*    expanded={false}*/}
                            {/*    dropdownId="dropdown.setCase"*/}
                            {/*/>*/}
                            {/*<Dropdown*/}
                            {/*    className={'dropdown-component dropdown--right-floated'}*/}
                            {/*    list={this.state.backgroundMenu}*/}
                            {/*    label="Background"*/}
                            {/*    hasFileInput={true}*/}
                            {/*    fileInputLabel="Upload background"*/}
                            {/*    handleChange={this.uploadBackground.bind(this)}*/}
                            {/*    handleClick={this.setBackground.bind(this)}*/}
                            {/*    uploading={this.props.uploadingBgImage}*/}
                            {/*    isSizeExceeded={this.props.sizeIsExceeded}*/}
                            {/*    dropdownId="dropdown.setBackground"*/}
                            {/*/>*/}
                            <div
                                className="mac-item__btn mac-item__btn--in-mac-details"
                                title="add this item to a package; you can then share your package with colleagues"
                                onClick={this.addToPackage.bind(this)}
                                data-testid="buttonMacDetailsAddToPackage"
                            >
                                <PackageAddWrapper>
                                        <span className="mac-item__btn-icon">
                                            <SpriteIcon iconId="add-to-package" className="btn-icon-svg"/>
                                        </span>
                                    <p
                                        className="mac-item__btn-text"
                                        data-testid="textMacDetailsAddToPackageButton"
                                    >
                                        Package
                                    </p>
                                </PackageAddWrapper>
                            </div>
                        </div>
                        <div className="canvas__outer" style={canvasStyles} data-testid="blockMacDetailsChart">
                            <div className={Array.from(curveClassList).join(' ')}>
                                <img src={IMG_CURVE} className={Array.from(curveImgClassList).join(' ')}/>
                            </div>
                            {
                                this.props.arrays16x9 ?
                                    this.renderColumns() :
                                    (
                                        <div className="canvas__loading">
                                            <div className="spinner-pow">
                                                <SpriteIcon iconId="logo-dark" width="200" height="200"/>
                                            </div>
                                        </div>
                                    )
                            }
                        </div>
                        {
                            this.props.tableData.length > 0 &&
                            (
                                <div className="data-table" id="data_table" ref="data_table">
                                    <div className="data-table__header">
                                        <div className="data-table__word-outer">
                                            <span data-testid="textMacDetailsTableTitleLabel">word:</span> <span className="data-table__word" data-testid="textMacDetailsTableTitle">
                                                {this.state.tableWord}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="data-table__content">
                                        <button
                                            id="copyTable"
                                            className="table__button"
                                            onClick={e => e}
                                            data-testid="buttonMacDetailsCopyTable"
                                        >
                                            <span className="table__button-icon">
                                                <SpriteIcon iconId="copy-icon"/>
                                            </span>
                                            copy table
                                        </button>

                                        <MacMenusTable
                                            tableData={this.props.tableData}
                                            word={this.state.tableWord}
                                            menusLoading={this.props.menusLoading}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div ref="scrollHere" className="scroll__HERE"></div>
                </div>
            </div>
        );
        // </Scrollbars>
    }
}

MacDetails.propTypes = {
    loading: PropTypes.bool,
    data: PropTypes.object,
    id: PropTypes.number,
    getMacData: PropTypes.func,
    isFromDocuments: PropTypes.bool,
    documentInfo: PropTypes.object,
    location: PropTypes.object,
    options: PropTypes.object,
    from: PropTypes.string,
    uploadNewBackground: PropTypes.func,
    resetBackground: PropTypes.func,
    customBgUrl: PropTypes.string,
    defaultBgUrl: PropTypes.string,
    getMenuExamples: PropTypes.func,
    downloadUrl: PropTypes.string,
    requestUserMac: PropTypes.func,
    uploadingBgImage: PropTypes.bool,
    loadingDownloadLink: PropTypes.bool,
    tableData: PropTypes.array,
    menusLoading: PropTypes.bool,
    resetMacDataStore: PropTypes.func,
    addNotification: PropTypes.func,
    setFileSizeStatus: PropTypes.func,
    showNotification: PropTypes.func,
    addFileToPackage: PropTypes.func,
    arrays16x9: PropTypes.array,
    arrays4x3: PropTypes.array,
    instanceId: PropTypes.number,
    docType: PropTypes.string,
    title: PropTypes.object,
    resettingBackground: PropTypes.bool,
    sizeIsExceeded: PropTypes.bool,
    publicationFamily: PropTypes.string
};

const mapStateToProps = state => ({
    // data: state.macData.data,
    loading: state.macData.loading,
    data: macDataSelector(state),
    options: macDataOptionsSelector(state),
    title: macDataTitleSelector(state),
    arrays16x9: macWords169Selector(state),
    arrays4x3: macWords43Selector(state),
    customBgUrl: macBackgroundUrlSelector(state),
    defaultBgUrl: macDefaultBackgroundUrlSelector(state),
    downloadUrl: macDownloadUrlSelector(state),
    uploadingBgImage: macUploadingStateSelector(state),
    sizeIsExceeded: macFileSizeStatusSelector(state),
    loadingDownloadLink: macLoadingDownloadLinkStateSelector(state),
    tableData: macTableDataSelector(state),
    resettingBackground: macResettingBgStateSelector(state),
    menusLoading: macMenusLoadingSelector(state),
    instanceId: state.viewDocumentData.data.info.instanceId,
    docType: state.viewDocumentData.data.info.docType
});

const mapDispatchToProps = dispatch => ({
    getMacData: params => dispatch(getMacDetailsData(params)),
    uploadNewBackground: params => dispatch(setMacDetailsBackgroundImage(params)),
    setFileSizeStatus: params => dispatch(setMacDetailsBackgroundImageStatus(params)),
    resetBackground: params => dispatch(setDefaultMacDetailsBackgroundImage(params)),
    getMenuExamples: (params, jsonData) => dispatch(getMacMenuExamplesData(params, jsonData)),
    requestUserMac: (data, params, anchorLink) => dispatch(getMacDownloadLink(data, params, anchorLink)),
    showNotification: text => dispatch(addNotification(text)),
    resetMacDataStore: () => dispatch(resetMacMenusData()),
    addFileToPackage: options => dispatch(addFileToPackage(options)),
    addNotification: data => dispatch(addNotification(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MacDetails));
