import { FC } from "react";
import './SimpleLoader.styles.scss';

const SimpleLoader: FC = () => {
    return (
        <div className="SimpleLoader">
            <div/>
            <div/>
            <div/>
            <div/>
        </div>
    )
}

export default SimpleLoader;