import { getFormattedThousand, getFormattedThousandWithDecimal, sanitizeUrl } from "../helpers";
import {
    API__CompanyDataViewModel,
    API__KeyAttributesDataViewModel,
    API__KeyAttributesDataViewModelAlcoholEnum,
} from "../apiSchema";
import { ReactNode } from "react";
import { IconYelp } from "../../icons";

type SpecialLocationField = 'airport' | 'mall' | 'lodging' | 'college/hospital';
type DayPartField = 'hasBreakfast' | 'hasLunch' | 'hasDinner';

interface iFilteredFieldData<T> {
    label: string,
    field: T,
}


class OptionalField<T> implements iFilteredFieldData<T> {
    label: string = '';
    field: T;

    constructor(data: iFilteredFieldData<T>) {
        this.label = data.label;
        this.field = data.field;
    }

    getIsInData = (data: any) => !!data[`${this.field}`];
}

const SPECIAL_LOCATIONS: OptionalField<SpecialLocationField>[] = [
    new OptionalField({ label: 'Airport', field: 'airport' }),
    new OptionalField({ label: 'Mall', field: 'mall' }),
    new OptionalField({ label: 'Lodging', field: 'lodging' }),
    new OptionalField({ label: 'College/Hospital', field: 'college/hospital' }),
];

const DAY_PARTS: OptionalField<DayPartField>[] = [
    new OptionalField({ label: 'Breakfast', field: 'hasBreakfast' }),
    new OptionalField({ label: 'Lunch', field: 'hasLunch' }),
    new OptionalField({ label: 'Dinner', field: 'hasDinner' }),
];

export enum Alcohol {
    NoData = -1,
    FullBar,
    BeerWine,
    NoAlcohol
}

export function getAlcohol(alcohol: API__KeyAttributesDataViewModelAlcoholEnum): Alcohol {
    switch (alcohol) {
        case API__KeyAttributesDataViewModelAlcoholEnum.NUMBER_MINUS_1:
            return Alcohol.NoData;
        case API__KeyAttributesDataViewModelAlcoholEnum.NUMBER_0:
            return Alcohol.FullBar;
        case API__KeyAttributesDataViewModelAlcoholEnum.NUMBER_1:
            return Alcohol.BeerWine;
        case API__KeyAttributesDataViewModelAlcoholEnum.NUMBER_2:
            return Alcohol.NoAlcohol;
        default:
            return Alcohol.NoData;
    }
}

enum PriceLevel {
    NoData,
    Under10,
    From10To19,
    From20To29,
    From30To49,
    More50,
}

export interface iChainBase {
    id: number;
    name: string; /* chain name */
    company: string; /* parent company name */
    metroArea: string
    stateFullName: string; /* stateFullName */
    numberOfUnits: number;
    unitsGrowth: number;
    segment: string;
    menuType: string;
    alcohol: Alcohol;
}

interface iKeyAttribute<T> {
    getValue: (data: T) => string | ReactNode;
    getTestId?: (data: T) => string;
    getIsShown: (data: T) => boolean;
    label: string;
    valueExample?: string;
    comment?: string;
}

export const getAlcoholString = (value: Alcohol) => {
    switch (value) {
        case Alcohol.FullBar:
            return 'Full Bar';
        case Alcohol.BeerWine:
            return 'Beer & Wine Only';
        case Alcohol.NoAlcohol:
            return 'No Alcohol';
        default:
            return 'No data';
    }
}

export const stockInfoAttributesConfig: iKeyAttribute<API__CompanyDataViewModel>[] = [
    {
        label: 'Ticker',
        getIsShown: d => !!d.financialContent.ticker,
        getValue: d => `${d.financialContent.ticker}`,
        valueExample: 'MCD',
    },
    {
        label: 'Current stock price',
        getIsShown: d => !!d.financialContent.last || !!d.financialContent.change || !!d.financialContent.changePercent,
        getValue: d => {
            const last = getFormattedThousandWithDecimal(d.financialContent.last);
            const change = getFormattedThousandWithDecimal(d.financialContent.change);
            const changePct = getFormattedThousandWithDecimal(d.financialContent.changePercent);
            const className = d.financialContent.change > 0 ? 'value-is-up' : 'value-is-down';

            return (<>
                {last}&nbsp;&nbsp;&nbsp;<span className={className}>{change} ({changePct}%)</span>
            </>);
        },
        valueExample: '80 20 (30%)',
        comment: `
            if financialContent.change <= 0, show change and changePercent in red color
        `,
    },
    {
        label: '52-week range',
        getIsShown: d => !!d.financialContent.min52 || !!d.financialContent.max52,
        getValue: d => {
            const min = getFormattedThousandWithDecimal(d.financialContent.min52);
            const max = getFormattedThousandWithDecimal(d.financialContent.max52)
            return `${min} - ${max}`;
        },
        valueExample: '',
        comment: `
            what if we have only 1 of 2 value?
        `,
    },
];

export const keyAttributesConfig: iKeyAttribute<API__KeyAttributesDataViewModel>[] = [
    {
        label: 'Holding Company',
        getIsShown: d => !!d.holdingCompany,
        getValue: d => `${d.holdingCompany}`,
        valueExample: 'Some Company Name',
    },
    {
        label: 'Holding Company Website',
        getIsShown: d => !!d.holdingCompanyWebsite,
        getValue: d => <>
            <a
                className="ChainPage__link"
                href={sanitizeUrl(d.holdingCompanyWebsite)}
                target="_blank"
                rel="noreferrer"
            >
                {d.holdingCompanyWebsite}
            </a>
        </>,
        valueExample: 'Some Company Name Website',
    },
    {
        label: 'Special Locations',
        getIsShown: d => SPECIAL_LOCATIONS.some(l => l.getIsInData(d)),
        getValue: d => {
            return SPECIAL_LOCATIONS.filter(l => l.getIsInData(d)).map(l => l.label).join(' | ');
        },
        valueExample: 'Airport | Lodging',
    },
    {
        label: 'Franchised / Owned',
        getIsShown: d => !Number.isNaN(d.franchisedPercent),
        getValue: d => `${d.franchisedPercent}% / ${(100 - (d.franchisedPercent ?? 0)).toFixed(2)}%`,
        valueExample: '30% / 70%',
    },
    {
        label: 'Store Size',
        getIsShown: d => !!d.minSquareFootage || !!d.maxSquareFootage,
        getValue: d => `${getFormattedThousand(+(d.minSquareFootage ?? 0))} - ${getFormattedThousand(+(d.maxSquareFootage ?? 0))} sf`,
        valueExample: '1,000 - 10,000 sf',
    },
    {
        label: 'Distributor',
        getIsShown: d => !!d.primaryDistributor,
        getValue: d => `${d.primaryDistributor}`,
        valueExample: 'SYSCO Food Services',
    },
    {
        label: 'POS',
        getIsShown: d => !!d.pos,
        getValue: d => `${d.pos}`,
        valueExample: 'Deco Solutions Group, Inc.; Torex Retail Holdings Ltd',
    },
    {
        label: 'Alcohol',
        getIsShown: d => !Number.isNaN(d.alcohol) && getAlcohol(d.alcohol) !== Alcohol.NoData,
        getValue: d => getAlcoholString(getAlcohol(d.alcohol)),
        valueExample: 'Beer & Wine Only',
    },
    {
        label: 'Price level',
        getIsShown: d => d.priceLevelString !== '',
        getValue: d => d.priceLevelString,
        getTestId: () => 'textChainPagePriceLevel',
        valueExample: '$20 - $39',
        comment: 'review range for each value'
    },
    {
        label: 'Dayparts',
        getIsShown: d => DAY_PARTS.some(l => l.getIsInData(d)),
        getValue: d => {
            return DAY_PARTS.filter(l => l.getIsInData(d)).map(l => l.label).join(' | ');
        },
        valueExample: 'Breakfast | Dinner',
    },
    {
        label: 'Founded',
        getIsShown: d => d.foundedYear > 0,
        getValue: d => `${d.foundedYear}`,
        valueExample: '2010',
    },
    {
        label: 'Primary Delivery Partner',
        getIsShown: d => !!d.deliverySystem,
        getValue: d => `${d.deliverySystem}`,
        valueExample: 'Multiple',
    },
    {
        label: 'Average Rating',
        getIsShown: d => !Number.isNaN(d.averageRating) && d.averageRating !== 0,
        getValue: d => <>
            {d.averageRating}&nbsp;
        </>,
        valueExample: '3.0',
    },
];

interface iSegmentGroup {
    title: string;
    items: string[];
}

export const segmentsConfig: iSegmentGroup[] = [
    {
        title: 'Restaurants',
        items: [
            'Quick service',
            'Fast casual',
            'Midscale dining',
            'Casual dining',
            'Upscale casual',
            'Fine dining',
            'Food Trucks',
            'Ghost Restaurant',
        ]
    },
    {
        title: 'Retail Food',
        items: [
            'Kitchen Center',
            'C-Stores',
            'Grocery / Supermarkets',
            'Bakery',
            'Non Grocery Retailer',
        ]
    },
    {
        title: 'Lodging',
        items: [
            'Hotels',
            'Motels',
            'Resorts',
            'Bed & breakfast',
            'Other Lodging',
        ]
    }
]