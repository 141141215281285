import { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SpriteIcon from '../../components/SpriteIcon/SpriteIcon';
import { cutText } from '../../core/old_helpers';

class DocumentsTableCell extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const iconSize = 39;

        const innerWidth = this.refs.inner.getBoundingClientRect().width;
        const outerWidth = ReactDOM.findDOMNode(this.refs.outer).getBoundingClientRect().width - iconSize;
        if(innerWidth > outerWidth) {
            if(window.innerWidth > 1440) {
                this.refs.inner.innerHTML = cutText(this.refs.inner.innerHTML, 35);
            } else {
                this.refs.inner.innerHTML = cutText(this.refs.inner.innerHTML, 20);
            }
        }
    }

    render() {
        const classList = new Set(['documents-table-cell', `documents-table-cell--${this.props.align}`]);
        if (this.props.fullInfo.docType === 'mac' && this.props.cellIndex === 1) {
            classList.add('documents-table-cell--mac-type');
        }
        if (this.props.fullInfo.docType === 'document') {
            classList.add('documents-table-cell--document-type');
            if (this.props.cellIndex === 2) {
                classList.add('documents-table-cell--hoverable');
            }
        }
        let iconType = this.props.fullInfo.fileExtention ? this.props.fullInfo.fileExtention.replace('.', '').substr(0, 3) : 'other';
        if (iconType.length < 3) {
            iconType = 'ppt';
        }
        const cellName = typeof this.props.cellData === 'object' ? this.props.cellData.value : this.props.cellData;
        const linkData = typeof this.props.cellData === 'object' && this.props.cellData.to;
        let to = {
            pathname: '/view-document',
            search: `?id=${this.props.fullInfo.docType === 'mac' ? this.props.fullInfo.instanceId : this.props.fullInfo.id}&doctype=${this.props.fullInfo.documentType}&${this.props.from === 'fromDocuments' ? 'fromDocuments=' + encodeURIComponent(this.props.searchWord) : 'from=' + this.props.from}`
        };
        if (linkData){
            to = linkData;
        }
        if (this.props.fullInfo.docType === 'mtdfoodprofile') {
            to = {
                pathname: '/food',
                search: `?q=${encodeURIComponent(this.props.fullInfo.title)}`
            }
        }
        return (this.props.isLink && to) ?
                (
                    <Link
                        to={to}
                        className={Array.from(classList).join(' ')}
                        ref="outer"
                        data-testid={this.props.testId}
                    >
                        {
                            this.props.icon &&
                            (<div className="documents-table-cell__icon">
                                <SpriteIcon iconId={`file-${iconType}`}></SpriteIcon>
                            </div>)
                        }
                        <span ref="inner">{cellName}</span>
                    </Link>
                ) :
                (
                    <div ref="outer" className={Array.from(classList).join(' ')} data-testid={this.props.testId}>
                        {
                            this.props.icon &&
                            (<div className="documents-table-cell__icon">
                                <SpriteIcon iconId={`file-${iconType}`}></SpriteIcon>
                            </div>)
                        }
                        <span ref="inner">{cellName}</span>
                    </div>
                );
    }
}

DocumentsTableCell.propTypes = {
    align: PropTypes.string,
    cellData: PropTypes.any,
    isLink: PropTypes.bool,
    fullInfo: PropTypes.object,
    testId: PropTypes.string
};

export default DocumentsTableCell;
