import {
    PACKAGES_SAVE_CURRENT,
    PACKAGES_ADD_ITEM_TO_PACKAGE,
    PACKAGES_ADD_ITEM_LIST_TO_PACKAGE,
    PACKAGES_REMOVE_ITEM_FROM_PACKAGE,
    PACKAGES_SAVE_PACKAGE_NAME,
    PACKAGES_STORE_ALL_PACKAGES,
    PACKAGES_CHANGE_ACTIVE_PACKAGE,
    PACKAGES_REMOVE_PACKAGE,
    PACKAGES_ADD_NEW_ACTIVE_PACKAGE,
    PACKAGES_REMOVE_PACKAGE_FROM_LIST,
    PACKAGES_SAVE_EMAILS,
    PACKAGES_OPEN,
    PACKAGES_CLOSE,
    PACKAGES_SET_DELETING,
    PACKAGES_RESET_DELETING,
} from '../actions/actionsNamesList';

const defaultState = {
    allPackages: [],
    activePackage: {
        items: [],
        itemsCount: 0,
        name: ''
    },
    isLoading: true,
    isOpened: false,
    isDeleting: false,
};

export default function packagesData(state = defaultState, action) {
    switch (action.type) {
        case PACKAGES_SAVE_CURRENT: {
            if (state.allPackages.some((packageItem) => packageItem.id === action.data.id)) {
                return {
                    ...state,
                    activePackage: action.data
                };
            } else {
                return {
                    ...state,
                    activePackage: action.data,
                    allPackages: [...state.allPackages.map((item) => {
                        item.isActive = false;
                        return item;
                    }), action.data]
                };
            }
        }
        case PACKAGES_ADD_ITEM_TO_PACKAGE: {
            return {
                ...state,
                activePackage: {
                    ...state.activePackage,
                    itemsCount: state.activePackage.itemsCount + 1,
                    items: [...state.activePackage.items, action.item]
                }
            };
        }
        case PACKAGES_ADD_ITEM_LIST_TO_PACKAGE: {
            return {
                ...state,
                activePackage: {
                    ...state.activePackage,
                    itemsCount: state.activePackage.itemsCount + action.list.length,
                    items: [...state.activePackage.items, ...action.list]
                }
            };
        }
        case PACKAGES_REMOVE_ITEM_FROM_PACKAGE: {
            return {
                ...state,
                activePackage: {
                    ...state.activePackage,
                    itemsCount: state.activePackage.itemsCount - 1,
                    items: state.activePackage.items.filter(item => item.id !== action.id)
                }
            };
        }
        case PACKAGES_SAVE_PACKAGE_NAME: {
            return {
                ...state,
                activePackage: {
                    ...state.activePackage,
                    name: action.name
                },
                allPackages: state.allPackages.map((item) => {
                    if (item.id === state.activePackage.id) {
                        item.name = action.name
                    }
                    return { ...item };
                })
            };
        }
        case PACKAGES_STORE_ALL_PACKAGES: {
            return {
                ...state,
                allPackages: action.data,
                isLoading: false
            };
        }
        case PACKAGES_CHANGE_ACTIVE_PACKAGE: {
            return {
                ...state,
                allPackages: state.allPackages.map((item) => {
                    item.isActive = item.id === action.id;
                    return item;
                }),
                activePackage: state.allPackages.find((item) => item.id === action.id)
            };
        }
        case PACKAGES_REMOVE_PACKAGE: {
            return {
                ...state,
                allPackages: state.allPackages.filter((item) => item.id !== action.id)
            };
        }
        case PACKAGES_ADD_NEW_ACTIVE_PACKAGE: {
            return {
                ...state,
                activePackage: action.data,
                allPackages: [...state.allPackages, action.data]
            };
        }
        case PACKAGES_REMOVE_PACKAGE_FROM_LIST: {
            return {
                ...state,
                allPackages: state.allPackages.map((packageItem) => {
                    if (packageItem.id === action.data.packageId) {
                        packageItem.items = packageItem.items.filter((item) => item.id !== action.data.id);
                    }
                    return { ...packageItem };
                })
            };
        }
        case PACKAGES_SAVE_EMAILS: {
            return {
                ...state,
                allPackages: state.allPackages.map((packageItem) => {
                    if (packageItem.id === action.params.id) {
                        packageItem.emails = [...action.params.emails];
                    }
                    return { ...packageItem };
                })
            };
        }
        case PACKAGES_OPEN: {
            return {
                ...state,
                isOpened: true,
            };
        }
        case PACKAGES_CLOSE: {
            return {
                ...state,
                isOpened: false,
            };
        }
        case PACKAGES_SET_DELETING: {
            return {
                ...state,
                isDeleting: true,
            };
        }
        case PACKAGES_RESET_DELETING: {
            return {
                ...state,
                isDeleting: false,
            };
        }
        default:
            return state;
    }

}

