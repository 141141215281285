import { FC } from "react";
import { iNote } from "../../../../core/models/Note";
import Textarea from "../../Textarea/Textarea";
import "./NotesForm.styles.scss";

interface iProps {
    value: string;
    onChange: (field: keyof iNote, value: string) => void;
}

const NotesForm: FC<iProps> = ({ value, onChange }) => {
    return (
        <div className="NotesForm">
            <Textarea
                onChange={(value) => onChange("text", value)}
                value={value}
                placeholder="Start typing here..."
                maxHeight={320}
                minHeight={50}
                autoFocus
                caretAtEnd
                styles={{
                    container: {
                        border: "none",
                        borderRadius: 0,
                    },
                    scrollbar: {
                        background: "none",
                        borderBottom: "1px solid rgba(255,255,255,.2)",
                        borderRadius: 0,
                    },
                    textarea: {
                        padding: "16px",
                    },
                }}
            />
        </div>
    );
};

export default NotesForm;
