import Http from '../core/fetch';
import {
    GROUPS_FETCH_DATA_STARTED,
    GROUPS_FETCH_DATA_LOADED,
    GROUPS_FETCH_DATA_STORE,
    GROUPS_UPDATE_GROUPS_DATA,
    GROUPS_OPEN,
    GROUPS_CLOSE,
} from './actionsNamesList';

import { getData as updateDocumentsLibraryData } from '../containers/DocumentsLibraryPopups/documentsLibraryPopups.actions';

export function setGroupsFetchingData() {
    return {
        type: GROUPS_FETCH_DATA_STARTED
    };
}

export function setGroupsDataLoaded() {
    return {
        type: GROUPS_FETCH_DATA_LOADED
    };
}

export function setGroupsDataStore(data) {
    return {
        type: GROUPS_FETCH_DATA_STORE,
        data
    };
}

export function getGroupsData() {
    return (dispatch) => {
        dispatch(setGroupsFetchingData());
        return Http
            .get('groups get all')
            .then((res) => {
                dispatch(setGroupsDataStore(res));
                dispatch(setGroupsDataLoaded());
            });
    };
}

export function createGroup(data) {
    return (dispatch, getState) => {
        return Http
            .post('groups create group', {}, {
                body: JSON.stringify({
                    ...data,
                    name: data.name.trim()
                }),
                headers: {
                    'content-type': 'application/json; charset=utf-8'
                }
            })
            .then((res) => {
                const newGroups = getState().groupsData.groups;
                newGroups.push(res);
                dispatch(setUpdateGroupsData(newGroups));
                dispatch(updateDocumentsLibraryData());
            });
    };
}

export function editGroup(data) {
    return (dispatch, getState) => {
        return Http
            .post('groups edit group', {}, {
                body: JSON.stringify({
                    ...data,
                    name: data.name.trim()
                }),
                headers: {
                    'content-type': 'application/json; charset=utf-8'
                }
            })
            .then((res) => {
                const groups = getState().groupsData.groups;
                groups.forEach((group, i) => {
                    if (group.id === data.id) {
                        groups[i] = { ...res };
                    }
                });
                dispatch(setUpdateGroupsData(groups));
                dispatch(updateDocumentsLibraryData());
            });
    };
}

export function setUpdateGroupsData(data) {
    return {
        type: GROUPS_UPDATE_GROUPS_DATA,
        data
    };
}

export function renameGroup(params) {
    return (dispatch, getState) => {
        return Http
            .get('groups rename group', params)
            .then((res) => {
                if (res.success) {
                    const groups = getState().groupsData.groups;
                    groups.forEach((group, i) => {
                        if (group.id === params.id) {
                            groups[i].name = params.name;
                        }
                    });
                    dispatch(setUpdateGroupsData(groups));
                    dispatch(updateDocumentsLibraryData());
                }
            });
    };
}

export function leaveGroup(groupId) {
    return (dispatch, getState) => {
        return Http
            .get('groups leave group', { userGroupId: groupId })
            .then((res) => {
                if (res.success) {
                    const updatedGroupsData = getState().groupsData.groups;
                    updatedGroupsData.forEach((group, i) => {
                        if (group.id === groupId) {
                            group.members.forEach((member, index) => {
                                if (member.email === res.email) {
                                    updatedGroupsData[i].members.splice(index, 1);
                                }
                            });
                        }
                    });
                    dispatch(setUpdateGroupsData(updatedGroupsData));
                    dispatch(updateDocumentsLibraryData());
                }
            });
    };
}

export function deleteGroup(groupId) {
    return (dispatch, getState) => {
        return Http
            .get('groups delete group', { userGroupId: groupId })
            .then((res) => {
                if (res.success) {
                    const withoutDeletedGroup = getState().groupsData.groups
                        .filter(elem => elem.id !== groupId);
                    dispatch(setUpdateGroupsData(withoutDeletedGroup));
                    dispatch(updateDocumentsLibraryData());
                }
            })
            .catch((error) => {
                console.error('!!!error', error);
            });
    };
}

export function removeMember(memberData) {
    return (dispatch, getState) => {
        return Http
            .get('groups remove member', memberData)
            .then((res) => {
                const groups = getState().groupsData.groups;
                groups.forEach((group, index) => {
                    if (group.id === res.id) {
                        groups[index] = res;
                    }
                });
                dispatch(setUpdateGroupsData(groups));
                dispatch(updateDocumentsLibraryData());
            });
    };
}

export function addMembers(params) {
    return (dispatch, getState) => {
        return Http
            .post('groups add members', {}, {
                body: JSON.stringify({
                    ...params
                }),
                headers: {
                    'content-type': 'application/json; charset=utf-8'
                }
            })
            .then((res) => {
                const groups = getState().groupsData.groups;
                groups.forEach((group, index) => {
                    if (group.id === res.id) {
                        groups[index].members = res.members;
                    }
                });
                dispatch(setGroupsDataStore(groups));
                dispatch(updateDocumentsLibraryData());
            });
    };
}

export function open() {
    return {
        type: GROUPS_OPEN,
    };
}

export function close() {
    return {
        type: GROUPS_CLOSE,
    };
}
