import { FC, useEffect, useState } from "react";
import { iNote, Note } from "../../../../core/models/Note";
import Button from "../../Button/Button";
import FormToggle from "../../FormToggle/FormToggle";
import Loader from "../../Loader/Loader";
import NotesFooter from "../NotesFooter/NotesFooter";
import NotesForm from "../NotesForm/NotesForm";
import NotesHeader from "../NotesHeader/NotesHeader";
import "./NotesEdit.styles.scss";

interface iProps {
    data: iNote;
    handleChangeField?: (field: keyof iNote, value: boolean | string) => void;
    isLoading?: boolean;
    onCancel?: () => void;
    onSave: (note: iNote) => void;
}

const NotesEdit: FC<iProps> = ({
    data,
    onCancel = () => {},
    handleChangeField,
    isLoading = false,
    onSave
}) => {
    const headerTitle = data.id ? "Edit a note" : "Add a note";
    const [selectedNote, setSelectedNote] = useState<iNote>(new Note());

    useEffect(() => {
        setSelectedNote(data);
    }, [data]);

    const handleChange = (field: keyof iNote, value: boolean | string) => {
        if ( handleChangeField ) {
            handleChangeField(field, value);
        } else {
            setSelectedNote(prevState => ({
                ...prevState,
                [field]: value
            }));
        }
    };

    return (
        <div className="NotesEdit">
            <div className="NotesEdit__header">
                <NotesHeader
                    title={headerTitle}
                >
                    <div className="NotesHeader__toggle-status">
                        <FormToggle
                            labels={["Private", "Public"]}
                            isChecked={selectedNote.isPublic}
                            onChange={() => handleChange("isPublic", !selectedNote.isPublic)}
                        />
                    </div>
                </NotesHeader>
            </div>

            <div className="NotesEdit__body">
                {isLoading && (
                    <div className="NotesEdit__loader">
                        <Loader />
                    </div>
                )}
                <div className="NotesEdit__form">
                    <NotesForm
                        value={selectedNote.text}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="NotesEdit__footer">
                <NotesFooter>
                    <div className="NotesFooter__action">
                        <Button
                            modifiers={["icon"]}
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div className="NotesFooter__action">
                        <Button
                            modifiers={["primary"]}
                            onClick={() => onSave(selectedNote)}
                            isDisabled={isLoading || selectedNote.text.length === 0}
                        >
                            Save
                        </Button>
                    </div>
                </NotesFooter>
            </div>
        </div>
    );
};

export default NotesEdit;
