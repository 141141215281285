import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    getCurrentPackage,
    closePackage,
    savePackageName,
    removeFileFromPackage,
    open,
    close,
} from '@actions/packages.actions';
import { Link } from 'react-router-dom';
import PackageConfirmDelete from '@pages/PackagesPage/PackageConfirmDelete';
import PackagesSharePopup from './PackagesSharePopup';
import { routeNames } from "@core/routeNames";
import Modal from "@components/Modal/Modal";
import cn from "classnames";

class PackagesModals extends Component {
    constructor(props) {
        super(props);
        this.packageNameRef = createRef();
        this.openSharePopup = this.openSharePopup.bind(this);
        this.closeSharePopup = this.closeSharePopup.bind(this);
        this.savePackageName = this.savePackageName.bind(this);
        this.togglePackagesPopup = this.togglePackagesPopup.bind(this);
        this.state = {
            sharePopupOpen: false,
            popupOpen: false,
            packageName: '',
            deletePopupOpen: false,
            itemToDelete: {},
            nameValid: true,
            firstFocus: false,
        };
    }

    openSharePopup() {
        this.props.open();
        if (this.packageNameRef.current.value.trim()) {
            this.setState({
                sharePopupOpen: true
            });
            document.body.style.overflow = 'hidden';
        } else {
            this.setState({
                nameValid: false
            });
        }
    }

    closeSharePopup() {
        this.props.close();
        this.setState({
            sharePopupOpen: false
        });
        document.body.style.overflow = '';
    }

    closeDeletePopup() {
        this.props.close();
        this.setState({ deletePopupOpen: false, itemToDelete: {} });
    }

    togglePackagesPopup(e) {
        e.preventDefault();
        this.setState({
            firstFocus: true,
            packageName: this.props.activePackage.name.length ? this.props.activePackage.name : 'Give me a name',
            nameValid: true
        });
        this.props.isOpened ? this.props.close() : this.props.open();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isOpened !== prevProps.isOpened) {
            this.setState({
                firstFocus: true,
                packageName: this.props.activePackage.name.length ? this.props.activePackage.name : 'Give me a name',
            })
        }
    }

    savePackageName(e) {
        this.props.savePackageName(this.state.packageName);
        if (this.state.packageName === '') {
            this.setState({
                packageName: 'Give me a name'
            })
        }
    }

    changePackageName(e) {
        const value = e.target.value;
        this.setState({ packageName: value });
    }

    closePackage() {
        if (this.packageNameRef.current.value.trim()) {
            this.props.closePackage();
            this.setState({ popupOpen: !this.state.popupOpen });
        } else {
            this.setState({
                nameValid: false
            });
        }
    }

    checkPackageName(e) {
        const val = e.target.value;
        e.target.value = '';
        e.target.value = val;
        if (e.target.value.toLowerCase() === 'give me a name') {
            if (this.state.firstFocus) {
                this.setState({
                    firstFocus: false
                });
            } else {
                e.target.value = '';
                this.setState({ packageName: '' });
            }
        }
    }

    handlePackNameKeyUp() {
        let packNameVal = this.packageNameRef.current.value;

        if (packNameVal.indexOf('Give me a name') !== -1) {
            packNameVal = packNameVal.replace('Give me a name', '');
            this.setState({
                packageName: packNameVal
            });
        }
    }

    render() {
        const { activePackage, isDeleting } = this.props;

        return (
            <>
                <Modal isOpened={this.props.isOpened} close={this.togglePackagesPopup}>
                    <div className="header-packages__popup">
                        <div className="header-packages__popup-header">
                            <input id="header-package-name"
                                   className={cn(
                                       "header-packages__package-name-input",
                                       !this.state.nameValid && 'header-packages__package-name-input--invalid'
                                   )}
                                   ref={this.packageNameRef}
                                   autoFocus
                                   onChange={this.changePackageName.bind(this)}
                                   onBlur={this.savePackageName}
                                   onFocus={this.checkPackageName.bind(this)}
                                   onClick={this.checkPackageName.bind(this)}
                                   onKeyUp={this.handlePackNameKeyUp.bind(this)}
                                   placeholder=""
                                   value={this.state.packageName}/>
                            <p className="header-packages__header-text">
                                packages are one or more
                                <br/>
                                files you can add from SNAP
                                <br/>
                                share with colleagues
                            </p>
                        </div>
                        {
                            activePackage.items.length ?
                                <div className="header-packages__packages-list-title">
                                    your package contains:
                                </div> : null
                        }
                        <div className="header-packages__packages-list">
                            {activePackage.items.map((item, index) => (
                                <a href={item.downloadLink} key={index} className="header-packages__packages-list-item">
                                    <div className="header-packages__package-info">
                                        <p className="header-packages__package-name">
                                            {item.name}
                                        </p>
                                        <p className="header-packages__package-type">
                                            {item.type}
                                        </p>
                                    </div>
                                    <button
                                        className="header-packages__button header-packages__button--remove"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            if(!isDeleting) {
                                                this.props.removeFileFromPackage(item.id);
                                            }
                                        }}
                                    >
                                    </button>
                                </a>
                            ))}
                        </div>
                        {activePackage.items.length ? (
                                <div className="header-packages__footer">
                                    <button className="header-packages__link-button"
                                            title="This will save your package. You can access it later at any time."
                                            onClick={this.closePackage.bind(this)}>
                                        close package
                                    </button>
                                    <div className="header-packages__footer-controls">
                                        <a href={`${process.env.REACT_APP_API}Package/DownloadPackage?packageid=${activePackage.id}`}
                                           className="header-packages__link-button header-packages__link-button--underline">
                                            download package
                                        </a>
                                        <button className="packages__button packages__button--send"
                                                onClick={this.openSharePopup}>
                                            send package
                                        </button>
                                    </div>
                                </div>
                            )
                            : null
                        }
                        <div className="header-packages__go-to-packages">
                        <span onClick={this.togglePackagesPopup}>
                            <Link
                                to={routeNames.packages}
                                className="header-packages__go-to-packages-link"
                            >
                                View all packages
                            </Link>
                        </span>
                        </div>
                    </div>
                </Modal>
                <PackagesSharePopup
                    activePackage={activePackage}
                    closeSharePopup={this.closeSharePopup}
                    sharePopupOpen={this.state.sharePopupOpen}
                    packageName={this.state.packageName}
                    filesCount={this.props.activePackage.itemsCount}
                />
                <PackageConfirmDelete
                    deletePopupOpen={this.state.deletePopupOpen}
                    item={this.state.itemToDelete}
                    deleteType="packageItemDelete"
                    deletePopupClose={this.closeDeletePopup.bind(this)}
                />
            </>
        );
    }
}

PackagesModals.propTypes = {
    isOpened: PropTypes.bool,
    activePackage: PropTypes.object,
    open: PropTypes.func,
    close: PropTypes.func,
    isDeleting: PropTypes.bool,
    className: PropTypes.string,
};

const mapStateToProps = state => ({
    activePackage: state.packagesData.activePackage,
    notification: state.documentsData.notification,
    isDeleting: state.packagesData.isDeleting,
    isOpened: state.packagesData.isOpened,
});

const mapDispatchToProps = dispatch => ({
    getCurrentPackage: () => dispatch(getCurrentPackage()),
    closePackage: () => dispatch(closePackage()),
    savePackageName: name => dispatch(savePackageName(name)),
    removeFileFromPackage: id => dispatch(removeFileFromPackage(id)),
    open: () => dispatch(open()),
    close: () => dispatch(close()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PackagesModals);
