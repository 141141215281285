import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import SpriteIcon from '../../components/SpriteIcon/SpriteIcon';
import './GlobalNotificationStyle.scss';

class GlobalNotification extends Component {

    renderNotifications() {
        return this.props.notifications.map((elem, index) => {
            return (
                <div
                    key={index}
                    className={classNames(
                        'global-notification',
                        elem.className,
                        { visible: true }
                    )}
                >
                    {
                        !elem.iconDisabled ?
                            (
                                <span className="global-notification__icon">
                                    <SpriteIcon iconId="success"/>
                                </span>
                            ) : null
                    }
                    {elem.text || 'Notification'}
                </div>
            );
        });
    }

    render() {
        const { notifications } = this.props;
        return (
            <div className="notification-container">
                { notifications.length > 0 ? this.renderNotifications() : null }
            </div>
        );
    }
}

GlobalNotification.propTypes = {
    isVisible: PropTypes.bool,
    notifications: PropTypes.array
};

const mapStateToProps = state => ({
    notifications: state.notificationsData.notifications
});

export default connect(mapStateToProps)(GlobalNotification);
