import { FC } from "react";
import { YearSeasonsEnum } from "../../../../../../../core/models/LTOChart";
import "./ChartLine.styles.scss";

interface iProps {
    value: number;
    quarter: YearSeasonsEnum;
}

const ChartLine: FC<iProps> = ({ value, quarter }) => {
    return <div className="ChartLine">
        <div className="ChartLine__value">{value}</div>
        <div className="ChartLine__line"/>
        <div className="ChartLine__quarter">{quarter}</div>
    </div>;
};

export default ChartLine;
