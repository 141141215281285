import Http from "../fetch";
import { ID } from "../models";
import { ChainsListItem__API } from "../models/api/ChainsListItem";
import { ChainsListItem, iChainsListItem } from "../models/ChainsListItem";
import { useDebouncedData } from "../hooks/useDebouncedData";

let request: any;

async function getChainsListByName(chainId: ID, word: string): Promise<iChainsListItem[]> {
    if ( chainId && word.length > 0 ) {
        if (request) request.abort();

        request = Http.getXHR('chain get list by name', {
            chainId,
            word,
            _: new Date().toISOString(),
        });

        const response: ChainsListItem__API[] = await request;
        return response.map(p => new ChainsListItem({ apiModel: p }));
    }

    return [];
}

export default function useChainsListByName(chainId: ID, word: string) {
    return useDebouncedData(300, [], getChainsListByName, chainId, word);
}