
import Http from "../fetch";
import { useData } from "../hooks/useData";
import { API__ChainNews } from "../models/api/ChainNews";
import { ChainNews, iChainNews } from "../models/ChainNews";

async function getChainNews(chainName: string): Promise<iChainNews[]> {
    const response: API__ChainNews[] = await Http.get('get chain news', { chainName });
    return response.map(n => new ChainNews(n));
}

export default function useChainNews(chainName: string) {
    return useData([], getChainNews, chainName);
}