import { API__NewMenuTrendsPenetrationViewModel } from "@apiSchema";
import moment from 'dayjs';

interface iChartPoint {
    value: number;
    label: string;
    subValues?: number[];
}

const fakeChartValues = [
    51.325199831720653,
    56.499789650820361,
    56.668051516410472,
    57.250313414124527,
    57.853457172342623,
    58.532563891178896,
    58.067831449126416,
    60.369989722507711,
    61.042439225381131,
    61.527377521613836,
    61.356141797197033,
];

export interface iFoodProfileSegment {
    title: string;
    oneYearGrowth: number;
    fourYearGrowth: number;
    chartData: iChartPoint[];
}

type iApiDataExtended = API__NewMenuTrendsPenetrationViewModel & { title: string };

interface iData {
    apiModel?: iApiDataExtended;
}

export class FoodProfileSegment implements iFoodProfileSegment {
    title = FoodProfileSegment.defaultData.title;
    oneYearGrowth = FoodProfileSegment.defaultData.oneYearGrowth;
    fourYearGrowth = FoodProfileSegment.defaultData.fourYearGrowth;
    chartData = FoodProfileSegment.defaultData.chartData;

    static defaultData: iFoodProfileSegment = {
        title: '',
        oneYearGrowth: 0,
        fourYearGrowth: 0,
        chartData: [],
    };

    static fakeData: iFoodProfileSegment = {
        title: 'Total US',
        chartData: fakeChartValues.map((value, index) => ({
            value,
            label: moment().add(- 11 + index, 'y').format('YYYY'),
        })),
        oneYearGrowth: 10,
        fourYearGrowth: 10,
    };

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private setData (model: iFoodProfileSegment) {
        ({
            title: this.title,
            oneYearGrowth: this.oneYearGrowth,
            fourYearGrowth: this.fourYearGrowth,
            chartData: this.chartData,
        } = model);
    }

    private mapFromApi (data: iApiDataExtended) {
        const {
            title,
            oneYearTrend,
            fourYearTrend,
            yearsPenetrations,
            quartersPenetrations,
        } = data;

        quartersPenetrations.sort(({ key: a}, { key: b}) => a < b ? -1 : 1);

        this.setData({
            title,
            oneYearGrowth: oneYearTrend,
            fourYearGrowth: fourYearTrend,
            chartData: [
                ...yearsPenetrations.map(i => ({
                    label: i.key,
                    value: i.value,
                })),
                {
                    label: (quartersPenetrations[quartersPenetrations.length - 1]?.key || '').substring(0, 4),
                    value: quartersPenetrations[quartersPenetrations.length - 1]?.value || 0,
                    subValues: quartersPenetrations.map(i => i.value),
                },
            ],
        });
    }
}