import { ReportsNameEnum } from "../../core/models/ChainDetails";
import { PermissionLevelEnum } from "../../core/models/PermissionSection";

export const getTabList = () => {
    return [
        {
            id: 0,
            title: "Download",
        },
    ]
};



interface iReportDescription {
    reportName: ReportsNameEnum;
    permissionLevel: PermissionLevelEnum;
    chainName: string;
}

export const getReportDescriptionByPermission = ({ reportName, permissionLevel, chainName }: iReportDescription): string => {
    const descriptions = [
        {
            name: ReportsNameEnum.Profile,
            data: [
                {
                    permission: PermissionLevelEnum.None,
                    value: ``,
                },
                {
                    permission: PermissionLevelEnum.Full,
                    value: `Overview of ${chainName}, with location summary, contacts, competitive comparison, menu activity, consumer snapshot, news and notes.`,
                },
                {
                    permission: PermissionLevelEnum.Limited,
                    value: `Overview of ${chainName}, with competitive comparison and recent news.`,
                },
            ]
        },
        {
            name: ReportsNameEnum.Firefly,
            data: [
                {
                    permission: PermissionLevelEnum.None,
                    value: `A detailed report of where ${chainName} has stores, its local competitive set, and profiles of consumers that live in its direct vicinity.`,
                },
                {
                    permission: PermissionLevelEnum.Full,
                    value: `A detailed report of where ${chainName} has stores, its local competitive set, and profiles of consumers that live in its direct vicinity.`,
                },
                {
                    permission: PermissionLevelEnum.Limited,
                    value: ``,
                },
            ]
        },
        {
            name: ReportsNameEnum.LTOActivity,
            data: [
                {
                    permission: PermissionLevelEnum.None,
                    value: `Analysis of LTO activity at ${chainName} and its competitive set – number of items, focus menu areas and examples for recent items.`,
                },
                {
                    permission: PermissionLevelEnum.Full,
                    value: `Analysis of LTO activity at ${chainName} and its competitive set – number of items, focus menu areas and examples for recent items.`,
                },
                {
                    permission: PermissionLevelEnum.Limited,
                    value: ``,
                },
            ]
        },
        {
            name: ReportsNameEnum.BrandFingerprints,
            data: [
                {
                    permission: PermissionLevelEnum.None,
                    value: `Consumer brand ratings for ${chainName} versus its competitive set across more than 100 key metrics; this is an authoritative look at what consumers think of the brand and where its opportunities are. `,
                },
                {
                    permission: PermissionLevelEnum.Full,
                    value: `Consumer brand ratings for ${chainName} versus its competitive set across more than 100 key metrics; this is an authoritative look at what consumers think of the brand and where its opportunities are. `,
                },
                {
                    permission: PermissionLevelEnum.Limited,
                    value: ``,
                },
            ]
        },
        {
            name: ReportsNameEnum.Menu,
            data: [
                {
                    permission: PermissionLevelEnum.None,
                    value: ``,
                },
                {
                    permission: PermissionLevelEnum.Full,
                    value: `Analysis of menu item distribution for ${chainName}, menu gap analysis, and full menu item details`,
                },
                {
                    permission: PermissionLevelEnum.Limited,
                    value: `Full menu item details for ${chainName}`,
                },
            ]
        }
    ];

    const currentGroup = descriptions.find(({ name }) => name === reportName);

    if ( !currentGroup ) return "";

    return currentGroup.data.find(({ permission }) => permission === permissionLevel)?.value || "";
}