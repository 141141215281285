import { FC } from "react";
import cn from "classnames";
import ContentWidget from "@components/ContentWidget/ContentWidget";
import ContentWidgetButton, { Modifiers } from "@components/ContentWidget/ContentWidgetButton";

const FoodProfileNoData: FC<{
    onButtonClick: () => void;
    isActivated: boolean;
    buttonText: string;
}> = (
    {
        children,
        onButtonClick,
        isActivated,
        buttonText,
    }
) => {

    const modifiers: Modifiers[] = ["filled"];

    if (isActivated) {
        modifiers.push('inactive');
    }

    return (
        <ContentWidget
            title=""
            className={"FoodProfileNoData"}
            modifiers={['transparent', 'no-hover', 'font-text']}
        >
            {children}
            <ContentWidgetButton
                onClick={onButtonClick}
                modifiers={modifiers}
            >
                {buttonText}
            </ContentWidgetButton>
        </ContentWidget>
    );
};

export default FoodProfileNoData;