import { FC } from "react";
import { getEnding, getFormattedThousand } from "../../../../../core/helpers";
import { iTerrain } from "../../../../../core/models/Terrain";
import "./LocationTile.styles.scss";

interface iProps {
    data: iTerrain;
}

const LocationTile: FC<iProps> = ({ data }) => {
    const { name, percentLocation, stores } = data;

    const getBackgroundImage = (name: string) => `${process.env.PUBLIC_URL}/location-terrain-${name.toLowerCase()}.jpg`;

    return (
        <div className="LocationTile">
            <div className="LocationTile__background" style={{backgroundImage: `url(${getBackgroundImage(name)})`}}/>
            <div className="LocationTile__name">{name}</div>
            <div className="LocationTile__info">
                <div className="LocationTile__info-item">{getFormattedThousand(stores)} {getEnding("store", stores)}</div>
                <div className="LocationTile__info-item">{percentLocation.toFixed(0)}% of locations</div>
            </div>
        </div>
    );
};

export default LocationTile;
