import { DOCUMENTS_LIBRARY_POPUPS } from '../../actions/actionsNamesList';
export const FILE_SIZE_MAX_LIMIT = 1024 * 1024 * 1024;

const { STORE_DATA, SET_PAGE_TO_RELOAD, SET_TYPES, SET_ACTIVE_COMPANY_ID } = DOCUMENTS_LIBRARY_POPUPS;

const defaultState = {
    types: [],
    projects: [],
    contacts: [],
    companies: [],
    pageToReload: '',
    customerViewCompanyId: null,
    currentUserId: window.pageData.userId,
    currentUserCompanyId: window.pageData.currentUsercompanyId
};

export default function documentsLibraryPopupsData(state = defaultState, action) {
    switch (action.type) {
        case STORE_DATA: {
            return {
                ...state,
                ...action.data
            };
        }
        case SET_PAGE_TO_RELOAD: {
            return {
                ...state,
                pageToReload: action.name
            };
        }
        case SET_TYPES: {
            return {
                ...state,
                types: action.types
            };
        }
        case SET_ACTIVE_COMPANY_ID: {
            return {
                ...state,
                customerViewCompanyId: action.id
            };
        }
        default: {
            return state;
        }
    }
}
