import React, { FC, useEffect, useMemo } from 'react';
import { iSummaryItem } from "@models/FoodLtoSection";
import useTableSort from "@components/new/Table/useTableSort";
import { useTableConfig } from "./FoodProfileMenuItemsSummary.helpers";
import Table from "@components/new/Table/Table";
import './FoodProfileMenuItemsSummary.scss';
import { CopyButtonContext } from "@components/new/Table/CopyButton";
import { AnalyticsAttribute } from "@models/AnalyticsAttributes";

interface iProps {
    data: iSummaryItem[];
}

function getDefaultColumnSorting(fieldName: keyof iSummaryItem | null): 'asc' | 'desc' {
    if (!fieldName) return 'asc';
    const ascColumns: Array<keyof iSummaryItem> = ["chainName"];
    if (ascColumns.includes(fieldName)) return 'asc';
    return 'desc';
}

const FoodProfileMenuItemsSummary: FC<iProps> = (
    {
        data,
    }
) => {
    const {
        handleSort,
        sortingField,
        sortingDirection,
        getColumnIcon,
        setSortingDirection,
        sortedData,
    } = useTableSort(data, "pastTwoYears");

    const tableConfig = useTableConfig(
        sortedData,
        sortingField,
        sortingDirection,
        handleSort,
        getColumnIcon,
    );

    useEffect(() => {
        setSortingDirection(getDefaultColumnSorting(sortingField));
    }, [sortingField, setSortingDirection]);

    const clipboardData: string[][] = useMemo(() => {
        return [
            tableConfig.map((col) => col.title || ''),
            ...sortedData.map(data => [
                `${data.chainName}`,
                `${data.pastThreeMonth}`,
                `${data.pastSixMonth}`,
                `${data.pastOneYear}`,
                `${data.pastTwoYears}`,
            ])
        ];
    }, [sortedData, tableConfig]);

    return (
        <div className="FoodProfileMenuItemsSummary">
            <div className="FoodProfileMenuItemsSummary__table">
                <CopyButtonContext.Provider
                    value={{
                        data: clipboardData,
                        analyticsData: {
                            [AnalyticsAttribute.ClickTitle]: 'Chains Featuring Keyword – Copy Table',
                        },
                    }}>
                    <Table
                        config={tableConfig}
                        rowsCount={data.length}
                        theme="light"
                        hasInnerScroll={false}
                        testId={"tableFoodProfileMenuItemsSummary"}
                    />
                </CopyButtonContext.Provider>
            </div>
        </div>
    );
}

export default FoodProfileMenuItemsSummary;