import { FC, useMemo } from "react";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import { DocumentTypes, iDocumentQueryParams } from "./DocumentView.helpers";
import Mac from "./DocumentTypes/Mac";
import Document from "./DocumentTypes/Document";
import "./DocumentView.styles.scss";

interface iProps {}
const DocumentView: FC<iProps> = () => {
    const location = useLocation();
    const params = useMemo(() => qs.parse(location.search) as unknown as iDocumentQueryParams, [location.search]);
    const isMac = useMemo(() => params.doctype === `${DocumentTypes.mac}`, [params.doctype]);

    return (
        <>
            {isMac && <Mac />}
            {!isMac && <Document />}
        </>
    );
};

export default DocumentView;
