import { Component } from 'react';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';
import Page from '../../containers/Page/Page';
import PageContent from '../../containers/PageContent/PageContent';
import { getAllPackages, deletePackage, getCurrentPackage } from '../../actions/packages.actions';
import { downloadingFileStateSelector } from '../../selectors/documents.selectors';
import SpriteIcon from '../../components/SpriteIcon/SpriteIcon';
import EditPackage from './EditPackage';
import { monthValues } from '../../core/old_helpers';
import './PackagesPage.scss';

class PackagesPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPackageId: null
        };
    }

    componentDidMount() {
        this.props.getAllPackages().then(() => {
            const packageWithItems = this.props.allPackages.find(item => item.items.length);
            if (packageWithItems) {
                this.setState({ selectedPackageId: packageWithItems.id });
            }
        });
    }

    componentDidUpdate() {
        if (!this.state.selectedPackageId) return;

        if (!this.props.allPackages.find(item => item.id === this.state.selectedPackageId).items.length) {
            const selectedPackage = this.props.allPackages.find(item => item.items.length);
            if(!selectedPackage) {
                this.setState({
                    selectedPackageId: null,
                });
            } else {
                this.setState({
                    selectedPackageId: selectedPackage.id,
                });
            }
        }
    }

    selectPackage(id) {
        this.setState({
            selectedPackageId: id
        });
    }

    removePackage(id) {
        this.setState({
            selectedPackageId: null
        });
        debugger;
        this.props.deletePackage(id).then(() => {
            this.setState({
                selectedPackageId: this.props.allPackages.find(packageItem => packageItem.items.length).id
            });
        });
    }

    render() {
        const { isLoading, packagesWithItems } = this.props;
        return (
            <Page>
                <PageContent className={isLoading ? '' : 'packages'}>
                    <Scrollbars className="packages__content-scroll" >
                        <div className="packages__header-wrapper">
                            {
                                this.props.downloadingFile &&
                                (
                                    <div className="download-spinner-container">
                                        <div className="spinner-line"></div>
                                    </div>
                                )
                            }
                            <div className="packages__container packages__header">
                                <h2 className="packages__title">
                                    My packages
                                </h2>
                                <p>
                                    Select a package on the left to view its contents.
                                </p>
                            </div>
                        </div>
                        {isLoading ? (
                                <div className="spinner spinner-blink">
                                    <SpriteIcon iconId="logo-dark"/>
                                </div>
                            ) :
                            packagesWithItems.length ? (
                                    <div className="packages__content">
                                        <div className="packages__container clearfix">
                                            <ul className="packages__list">
                                                {packagesWithItems.map((packageItem, index) => (
                                                        <li key={index}
                                                            className={`packages__list-item ${
                                                                packageItem.id === this.state.selectedPackageId ? 'packages__list-item--active' : ''}`}
                                                            onClick={this.selectPackage.bind(this, packageItem.id)}>
                                        <div>
                                            {packageItem.name}
                                            <p className="packages__created-date">
                                                Created {`${new Date(packageItem.createDate).getDate()} ${monthValues[new Date(packageItem.createDate).getMonth()]} ${new Date(packageItem.createDate).getFullYear()}`}
                                            </p>
                                            {packageItem.isActive &&
                                            <span className="packages__active-marker">active</span>}
                                        </div>
                                        <span className="packages__files-counter">
                                            {packageItem.items.length} files
                                        </span>
                                                        </li>)
                                                )}
                                            </ul>
                                            <section className="packages__detail-info">
                                                {this.state.selectedPackageId &&
                                                <EditPackage deletePackage={this.removePackage.bind(this)}
                                                    id={this.state.selectedPackageId}/>}
                                            </section>
                                        </div>
                                    </div>)
                                : (
                                    <div className="packages__container">
                                        <p>
                                            There are no Packages
                                        </p>
                                    </div>
                                )
                        }
                    </ Scrollbars>

                </PageContent>
            </Page>
        );
    }
}

const mapStateToProps = (state) => {
    const packagesWithItems = state.packagesData.allPackages.filter(packageItem => packageItem.items.length && packageItem.name.trim());
    return {
        allPackages: state.packagesData.allPackages,
        isLoading: state.packagesData.isLoading,
        packagesWithItems,
        downloadingFile: downloadingFileStateSelector(state),
        notification: state.documentsData.notification
    };
};

const mapDispatchToProps = dispatch => ({
    getAllPackages: () => dispatch(getAllPackages()),
    deletePackage: id => dispatch(deletePackage(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(PackagesPage);
