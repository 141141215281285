import {
    GROUPS_FETCH_DATA_STARTED,
    GROUPS_FETCH_DATA_LOADED,
    GROUPS_FETCH_DATA_STORE,
    GROUPS_UPDATE_GROUPS_DATA,
    GROUPS_OPEN,
    GROUPS_CLOSE,
} from '../actions/actionsNamesList';

const defaultState = {
    groups: [],
    groupsLoading: false,
    popupIsOpened: false,
};

export default function groupsData(state = defaultState, action) {
    switch (action.type) {
        case GROUPS_FETCH_DATA_STARTED:
            return {
                ...state,
                groupsLoading: true
            };
        case GROUPS_FETCH_DATA_LOADED:
            return {
                ...state,
                groupsLoading: false
            };
        case GROUPS_FETCH_DATA_STORE:
            return {
                ...state,
                groups: action.data
            };
        case GROUPS_UPDATE_GROUPS_DATA:
            return {
                ...state,
                groups: action.data
            };
        case GROUPS_OPEN: {
            return {
                ...state,
                popupIsOpened: true,
            };
        }
        case GROUPS_CLOSE: {
            return {
                ...state,
                popupIsOpened: false,
            };
        }
        default: {
            return state;
        }
    }
}
