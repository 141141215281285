import { Component } from 'react';
import PT from 'prop-types';
import { NavLink } from 'react-router-dom';
import { cutText } from '../../core/old_helpers';

class ProjectTile extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const paddingX = 30;
        const outerWidth = this.header.getBoundingClientRect().width - paddingX * 2;
        const innerWidth = this.title.getBoundingClientRect().width;
        if (innerWidth > outerWidth) {
            this.title.innerHTML = cutText(this.title.innerHTML, 15);
        }
    }

    setTitleRef(node) {
        this.title = node;
    }

    setHeaderRef(node) {
        this.header = node;
    }

    render() {
        const {
            url, isModified, isBlocked, leftTopText, leftBottomText, rightTopText, rightBottomText,
            color, name, initialName
        } = this.props;

        const classList = new Set([
            'my-documents-projects-grid__item',
            'my-documents-project-tile',
            'fadeInUp'
        ]);
        const headerClassList = new Set(['my-documents-project-tile__header']);

        if (isBlocked) {
            classList.add('my-documents-project-tile--blocked');
            headerClassList.add('my-documents-project-tile__header--color-0');
        } else {
            headerClassList.add(`my-documents-project-tile__header--color-${color}`);
        }
        const titleClassList = new Set(['my-documents-project-tile__title']);

        if (name.split(' ').length > 10) {
            titleClassList.add('my-documents-project-tile__title--small');
        }

        const content = <>
            {isModified && (
                <div
                    className="tile-label tile-label--yellow tile-label--big"
                    data-testid="textProjectTileModified"
                >
                    modified
                </div>
            )}
            <div ref={this.setHeaderRef.bind(this)} className={Array.from(headerClassList).join(' ')}>
                {isModified ? (
                    <div
                        ref={this.setTitleRef.bind(this)}
                        className={Array.from(titleClassList).join(' ')}
                        data-testid="textProjectTileTitle"
                    >
                        <div className="my-documents-project-tile__sub-title">
                                <span
                                    className="strike"
                                    data-testid="textProjectTileOldName"
                                >
                                    {initialName}
                                </span>
                        </div>
                        {name}
                    </div>
                ) : (
                    <div
                        ref={this.setTitleRef.bind(this)}
                        className={Array.from(titleClassList).join(' ')}
                        data-testid="textProjectTileName"
                    >
                        {name}
                    </div>
                )}
            </div>
            <div className="my-documents-project-tile__text">
                <div className="my-documents-project-tile__left">
                    <div data-testid="textProjectTileText1">{leftTopText}</div>
                    <div data-testid="textProjectTileText2">{leftBottomText}</div>
                </div>
                <div className="my-documents-project-tile__right">
                    <div data-testid="textProjectTileText3">{rightTopText}</div>
                    <div data-testid="textProjectTileText4">{rightBottomText}</div>
                </div>
            </div>
        </>;

        const props = {
            'data-testid': this.props.testId,
            className: Array.from(classList).join(' '),
        };

        if (isBlocked) {
            return (
                <div {...props}>
                    {content}
                </div>
            );
        }

        return (
            <NavLink {...props} to={url}>
                {content}
            </NavLink>
        );
    }
};

ProjectTile.propTypes = {
    url: PT.string,
    isModified: PT.bool,
    isBlocked: PT.bool,
    name: PT.string,
    initialName: PT.string,
    isSharedMode: PT.bool,
    leftTopText: PT.string,
    leftBottomText: PT.string,
    rightTopText: PT.string,
    rightBottomText: PT.string,
    color: PT.number,
    testId: PT.string,
};

export default ProjectTile;
