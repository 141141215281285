import { API__FoodProfilePageDataLiteViewModel } from "@apiSchema";
import { ID } from "@models/index";
import { FoodProfileSummary, iFoodProfileSummary } from "@models/FoodProfileSummary";
import { FoodProfileAffinity, iFoodProfileAffinity } from "@models/FoodProfileAffinity";
import { FoodProfileVersatility, iFoodProfileVersatility } from "@models/FoodProfileVersatility";
import { FoodProfileHeatScore, iFoodProfileHeatScore } from "@models/FoodProfileHeatScore";

type API_Model = API__FoodProfilePageDataLiteViewModel;

interface iData {
    apiModel?: API_Model;
    noData?: boolean;
}

export interface iFoodProfile {
    foodProfileId: ID;
    foodProfileWord: string;
    summary: iFoodProfileSummary;
    affinity: iFoodProfileAffinity;
    heatScoreData: iFoodProfileHeatScore;
    versatility: iFoodProfileVersatility;
    hasData: boolean;
}

export class FoodProfile implements iFoodProfile {
    foodProfileId = FoodProfile.defaultData.foodProfileId;
    foodProfileWord = FoodProfile.defaultData.foodProfileWord;
    summary = FoodProfile.defaultData.summary;
    affinity = FoodProfile.defaultData.affinity;
    heatScoreData = FoodProfile.defaultData.heatScoreData;
    versatility = FoodProfile.defaultData.versatility;
    hasData = FoodProfile.defaultData.hasData;

    static defaultData: iFoodProfile = {
        foodProfileId: 0,
        foodProfileWord: '',
        summary: new FoodProfileSummary(),
        affinity: new FoodProfileAffinity(),
        heatScoreData: new FoodProfileHeatScore(),
        versatility: new FoodProfileVersatility(),
        hasData: true,
    }

    constructor(data?: iData) {
        if (data) {
            if ( data.apiModel) {
                this.mapFromApi(data.apiModel);
            }
            else if (data.noData) {
                this.setNoData();
            }
        }
    }

    private setData(model: iFoodProfile) {
        ({
            foodProfileId: this.foodProfileId,
            foodProfileWord: this.foodProfileWord,
            summary: this.summary,
            affinity: this.affinity,
            heatScoreData: this.heatScoreData,
            versatility: this.versatility,
        } = model);
    }

    private setNoData() {
        this.setData({
            ...FoodProfile.defaultData,
            hasData: false,
        });
    }

    private mapFromApi(data: API_Model): void {
        const {
            foodProfileId,
            foodProfileWord,
        } = data;

        let affinity: iFoodProfileAffinity;

        try {
            affinity = new FoodProfileAffinity({ apiModel: data.flavorWordInfo })
        } catch (err) {
            console.error(err);
            affinity = FoodProfile.defaultData.affinity;
        }

        let heatScoreData: iFoodProfileHeatScore;

        try {
            heatScoreData = new FoodProfileHeatScore({ apiModel: data.flavorWordInfo });
        } catch (err) {
            console.error(err);
            heatScoreData = FoodProfile.defaultData.heatScoreData;
        }

        let versatility: iFoodProfileVersatility;

        try {
            versatility = new FoodProfileVersatility({ apiModel: data.menuTrendsPenetration });
        } catch (err) {
            console.error(err);
            versatility = FoodProfile.defaultData.versatility;
        }

        this.setData({
            foodProfileId,
            foodProfileWord,
            summary: new FoodProfileSummary({ apiModel: data }),
            affinity,
            heatScoreData,
            versatility,
            hasData: true,
        });
    }
}