export interface iFoodProfileNavigationItem {
    id: string;
    testId?: string;
    title: string;
    yOffset: number;
}

const navigationItems: iFoodProfileNavigationItem[] = [
    {
        id: 'overview',
        testId: 'buttonFoodProfileSideNavOverview',
        yOffset: -30,
        title: 'overview',
    },
    {
        id: 'trend-details',
        testId: 'buttonFoodProfileSideNavTrendDetails',
        yOffset: -10,
        title: 'trend details',
    },
    {
        id: 'charts',
        testId: 'buttonFoodProfileSideNavCharts',
        yOffset: -10,
        title: 'charts',
    },
    {
        id: 'chain-activity',
        testId: 'buttonFoodProfileSideNavChainActivity',
        yOffset: 0,
        title: 'chain activity',
    },
];

export function getNavigationItems(
    {
        hasWidgetsData,
        hasChartsData,
        hasMenuItemsData,
    }: {
        hasWidgetsData: boolean;
        hasChartsData: boolean;
        hasMenuItemsData: boolean;
    }
): iFoodProfileNavigationItem[] {
    let result: iFoodProfileNavigationItem[] = [...navigationItems];

    if (!hasWidgetsData) {
        result = result.filter(i => i.id !== 'trend-details');
    }

    if (!hasChartsData) {
        result = result.filter(i => i.id !== 'charts');
    }

    if (!hasMenuItemsData) {
        result = result.filter(i => i.id !== 'chain-activity');
    }

    return result;
}