import {FC} from "react";
import "@components/ChartLine/ChartLine.scss";
import cn from "classnames";

export type iType = 'is-gray' | 'is-blue' | 'is-inline' | 'is-data-top';

const ChartLine: FC<{
    name: string;
    value: string;
    widthPercentage: number;
    modifiers: iType[];
    testId?: string;
}> = (
    {
        name,
        value,
        widthPercentage,
        modifiers,
        testId,
    }
) => {
    const classNameConcat = cn(
        modifiers.map(c => `${c}`)
    );
    return (
        <div
            className={"ChartLine__item " + (classNameConcat)}
            data-testid={testId}
        >
            <div className="ChartLine__info font-text clearfix">
                <div
                    className="ChartLine__state"
                    data-testid={"textChartLineTitle"}
                >
                    {name}
                </div>
                <div
                    className="ChartLine__state-value"
                    data-testid={"textChartLineValue"}
                >
                    {value}
                </div>
            </div>
            <div className="ChartLine__progress-bar">
                <div
                    className="ChartLine__progress-line"
                    style={{width: `${widthPercentage}%`}}
                />
            </div>
        </div>
    );
};

export default ChartLine;