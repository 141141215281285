import { PureComponent } from 'react';
import PT from 'prop-types';
import SpriteIcon from '../../components/SpriteIcon/SpriteIcon';
import './NotificationPopup.scss';

export default class NotificationPopup extends PureComponent {
    render() {
        const {
            isOpened,
            className = '',
            hasCloseButton = true,
            style = {},
        } = this.props;

        const classList = new Set([
            'notification-popup',
            className,
        ]);

        if (isOpened) {
            classList.add('notification-popup--show');
        }

        return (
            <div className={Array.from(classList).join(' ')} style={style}>
                {this.props.children}
                {hasCloseButton && (
                    <button
                        className="notification-popup__close"
                        onClick={this.props.close}
                    >
                        close
                        <SpriteIcon
                            iconId="close"
                            height="12px"
                            width="12px"
                            className="notification-popup__close-icon"
                        />
                    </button>
                )}
            </div>
        );
    }
}

NotificationPopup.propTypes = {
    close: PT.func,
    children: PT.element,
    isOpened: PT.bool,
    className: PT.string,
    hasCloseButton: PT.bool,
};
