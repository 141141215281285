import { createSelector } from 'reselect';
import getFileSize from '../..//core/getFileSize';
import { projectsSelector } from '..//DocumentsLibraryPopups/documentsLibraryPopups.selectors';

import { FILE_SIZE_MAX_LIMIT } from '..//DocumentsLibraryPopups/documentsLibraryPopups.reducers';

const documentsLibraryData = state => state.documentsAddPopupData;

const lastUploadingSetIdSelector = createSelector(
    documentsLibraryData,
    data => data.lastUploadingSetId,
);

export const documentsSelector = createSelector(
    documentsLibraryData,
    lastUploadingSetIdSelector,
    (data, setId) => {
        // file id has structure '%set id%--%file index in set at the beginning%'
        const fileIsFromLastSetRegExp = new RegExp(`^${setId}--\\d+`);

        // show only files from the last set
        return data.documents
            .filter(({ id }) => fileIsFromLastSetRegExp.test(id))
            .map((document) => {
                const { name } = document.file;
                const size = getFileSize(document.file.size);
                const isSizeValid = document.file.size < FILE_SIZE_MAX_LIMIT;
                const fileName = name.length > 14
                    ? `${name.substr(0, 7)}...${name.substr(name.length - 7)}`
                    : name;

                let subTitle = `<div class="file-upload__file-name" data-testid="textContinueUploadingFilePopupFileName">${fileName}</div>`;
                subTitle += ` ${!isSizeValid ? '<span class="file-upload__error-mark">' : ''}`;
                subTitle += `<div class="file-upload__file-size" data-testid="textContinueUploadingFilePopupFileSize">${size.value.toFixed(1)} ${size.units}</div>`;
                subTitle += ` ${!isSizeValid ? '' : '</span>'}`;

                return {
                    ...document,
                    subTitle,
                    isSizeValid,
                };
            });
    },
);

export const currentDocumentIndexSelector = createSelector(
    documentsLibraryData,
    data => data.currentDocumentIndex
);

export const currentDocumentDataSelector = createSelector(
    documentsSelector,
    currentDocumentIndexSelector,
    (documents, index) => (documents[index])
);

export const globalProjectSelector = createSelector(
    documentsSelector,
    (files) => {
        // set of projects the files are added to
        const projectsIds = files.reduce(
            (projectsIdsSet, { projectId }) => projectsIdsSet.add(projectId),
            new Set(),
        );

        // if all projects are added to the same project
        if (projectsIds.size === 1) {
            return { id: projectsIds.values().next().value, isActive: true };
        }
        // otherwise no global project set
        return { id: null, isActive: false };
    },
);

const getDocumentName = (state, props) => props.name;
const getDocumentIndex = (state, props) => props.index;

const documentsNamesSelector = createSelector(
    documentsSelector,
    documents => documents.reduce((set, document) => set.add(document.name), new Set()),
);

export const isNamesUniqueOnServerSelector = createSelector(
    documentsSelector,
    documents => documents.some(document => document.isNameUniqueOnServer && !document.isNameUniquenessChecking),
);

export const isNamesUniqueOnClientSelector = createSelector(
    documentsSelector,
    documentsNamesSelector,
    (documents, documentsNames) => documents.length === documentsNames.size,
);

export const isFilesSizeValidSelector = createSelector(
    documentsSelector,
    documents => documents.reduce((isValid, document) => {
        return isValid && document.file.size < FILE_SIZE_MAX_LIMIT;
    }, true),
);

export const isFormValidSelector = createSelector(
    documentsSelector,
    isNamesUniqueOnServerSelector,
    isNamesUniqueOnClientSelector,
    isFilesSizeValidSelector,
    (documents, isNamesUniqueOnClient, isNamesUniqueOnServer, isFilesSizeValid) => {
        const isNamesUnique = isNamesUniqueOnClient && isNamesUniqueOnServer;
        const isDocumentsFilled = documents.reduce((isValid, document) => {
            const isNameSet = document.name !== '';
            const isProjectSet = document.projectId !== null;
            return isValid
                && isNameSet
                && isProjectSet;
        }, true);

        return isDocumentsFilled && isNamesUnique && isFilesSizeValid;
    },
);

export const selectedCompaniesProjectsListSelector = createSelector(
    documentsSelector,
    projectsSelector,
    currentDocumentIndexSelector,
    (documents, projects, currentDocumentIndex) => {
        if (documents.length) {
            const shareWith = documents[currentDocumentIndex].shareWith;
            return projects
                .filter(({ sharedToCompanies }) => {
                    return shareWith.every(id => sharedToCompanies.includes(id));
                });
        }
        return [];
    },
);

export const isAddDocumentPopupOpenedSelector = createSelector(
    documentsLibraryData,
    (popupData) => popupData.isOpened,
);


export const isCurrentDocumentNameUniqueOnClientSelector = createSelector(
    documentsSelector,
    currentDocumentIndexSelector,
    currentDocumentDataSelector,
    (documents, currentDocumentIndex, currentDocument) => documents.filter(
        (document, documentIndex) => (document.name === currentDocument.name && documentIndex !== currentDocumentIndex),
    ).length === 0,
);

export const isCurrentDocumentValidSelector = createSelector(
    currentDocumentDataSelector,
    isCurrentDocumentNameUniqueOnClientSelector,
    (document, isNameUniqueOnClient) => {
        if (!document) return false;
        let isValid = true;
        isValid = isValid && document.title !== '';
        isValid = isValid && document.projectId !== null;
        isValid = isValid && isNameUniqueOnClient;
        isValid = isValid && document.isNameUniqueOnServer && !document.isNameUniquenessChecking;
        isValid = isValid && document.file.size < FILE_SIZE_MAX_LIMIT;
        return isValid;
    },
);
