import {
    CHAIN_DETAILS_FETCH_DATA_LOADED,
    CHAIN_DETAILS_FETCH_DATA_STORE,
    CHAIN_DETAILS_FETCH_NEWS_STORE,
    CHAIN_DETAILS_SET_FILTER_QUERY,
    CHAIN_DETAILS_SAVE_NOTE,
    CHAIN_DETAILS_EDIT_NOTE,
    CHAIN_DETAILS_REMOVE_NOTE,
    CHAIN_DETAILS_ADD_LOCAL_INSIGHTS,
    CHAIN_DETAILS_RESET_STORE,
    CHAIN_DETAILS_SAVE_MENU_COMPETITORS,
    CHAIN_DETAILS_CLEAR_MENU_COMPETITORS,
    CHAIN_DETAILS_GAP_REPORT_LOADING,
    CHAIN_DETAILS_GAP_REPORT_STORE,
    CHAIN_DETAILS_CLEAR_GAP_REPORT,
    CHAIN_DETAILS_SELECTED_CHAINS_LOADING,
    CHAIN_DETAILS_SELECTED_CHAINS_LOADED,
    CHAIN_DETAILS_CHAINS_LIST_BY_NAME_STORE,
    CHAIN_DETAILS_CLEAR_CHAINS_LIST_BY_NAME,
    CHAIN_DETAILS_SELECTED_CHAINS_STORE,
    CHAIN_DETAILS_CLEAR_SELECTED_CHAINS,
    CHAIN_DETAILS_MTD_PRODUCT_PERMISSION_STORE,
    CHAIN_DETAILS_ADD_REPORTS_PERMISSIONS,
    CHAIN_DETAILS_SINGLE_MENU_ITEM_UPDATE,
    CHAIN_DETAILS_POPUP_OPEN,
    CHAIN_DETAILS_POPUP_CLOSE,
    CHAIN_DETAILS_MENU_GAPS_STORE,
    CHAIN_DETAILS_MENU_GAPS_LOADED,
} from '../actions/actionsNamesList';

const defaultState = {
    data: {
        menuItems: [],
        chainInfoWrapper: {
            chainDetails: {
                socialLinks: {}
            },
            menuData: {}
        },
        youTubeVideos: [],
        facebookPosts: [],
        contacts: [],
        news: [],
        tweets: [],
        notes: [],
        chainPermissions: {},
        savedQueries: [],
        presets: [],
        interactiveLists: [],
    },
    isLoading: true,
    isGapsLoaded: false,
    isNewsLoading: true,
    filterQuery: '',
    localInsight: {
        competitors: {
            items: []
        },
        marketAreas: {
            stateMarketAreas: [],
            metroMarketAreas: []
        },
        residentPopulation: {
            statistic: {}
        }
    },
    chainCompetitors: [],
    generatingGapReport: false,
    downloadUrl: '',
    chainsSuggestListLoading: false,
    chainsSuggestList: [],
    selectedChainsList: [],
    permissions: {},
    isOpened: false,
};

export default function chainDetailData(state = defaultState, action) {
    switch (action.type) {
        case CHAIN_DETAILS_FETCH_DATA_STORE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data
                }
            };
        }
        case CHAIN_DETAILS_MENU_GAPS_STORE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data,
                }
            }
        }
        case CHAIN_DETAILS_MENU_GAPS_LOADED: {
            return {
                ...state,
                isGapsLoaded: true,
            }
        }
        case CHAIN_DETAILS_FETCH_NEWS_STORE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    news: [...action.data]
                },
                isNewsLoading: false
            };
        }
        case CHAIN_DETAILS_FETCH_DATA_LOADED: {
            return {
                ...state,
                isLoading: false
            };
        }
        case CHAIN_DETAILS_SET_FILTER_QUERY: {
            return {
                ...state,
                filterQuery: action.query
            };
        }
        case CHAIN_DETAILS_SAVE_NOTE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    notes: [action.note, ...state.data.notes]
                }
            };
        }
        case CHAIN_DETAILS_EDIT_NOTE: {
            let newNotes = [];
            state.data.notes.map(note => {
                if (note.id === action.note.id) {
                    note.text = action.note.text;
                    note.isPublic = action.note.isPublic;
                    newNotes.unshift(note);
                } else {
                    newNotes.push(note);
                }
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    notes: newNotes,
                }
            }
        }
        case CHAIN_DETAILS_REMOVE_NOTE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    notes: [...state.data.notes.slice(0, action.index), ...state.data.notes.slice(action.index + 1)]
                }
            };
        }
        case CHAIN_DETAILS_ADD_LOCAL_INSIGHTS: {
            return {
                ...state,
                localInsight: {
                    ...action.data
                }
            };
        }
        case CHAIN_DETAILS_SAVE_MENU_COMPETITORS: {
            return {
                ...state,
                chainCompetitors: action.data
            };
        }
        case CHAIN_DETAILS_CLEAR_MENU_COMPETITORS: {
            return {
                ...state,
                chainCompetitors: []
            };
        }
        case CHAIN_DETAILS_GAP_REPORT_LOADING: {
            return {
                ...state,
                generatingGapReport: true
            };
        }
        case CHAIN_DETAILS_GAP_REPORT_STORE: {
            return {
                ...state,
                downloadUrl: action.data
            };
        }
        case CHAIN_DETAILS_CLEAR_GAP_REPORT: {
            return {
                ...state,
                downloadUrl: ''
            };
        }
        case CHAIN_DETAILS_SELECTED_CHAINS_LOADING: {
            return {
                ...state,
                chainsSuggestListLoading: true
            };
        }
        case CHAIN_DETAILS_SELECTED_CHAINS_LOADED: {
            return {
                ...state,
                chainsSuggestListLoading: false
            };
        }
        case CHAIN_DETAILS_CHAINS_LIST_BY_NAME_STORE: {
            return {
                ...state,
                chainsSuggestList: action.data
            };
        }
        case CHAIN_DETAILS_CLEAR_CHAINS_LIST_BY_NAME: {
            return {
                ...state,
                chainsSuggestList: []
            };
        }
        case CHAIN_DETAILS_SELECTED_CHAINS_STORE: {
            return {
                ...state,
                selectedChainsList: action.data
            };
        }
        case CHAIN_DETAILS_CLEAR_SELECTED_CHAINS: {
            return {
                ...state,
                selectedChainsList: []
            };
        }
        case CHAIN_DETAILS_MTD_PRODUCT_PERMISSION_STORE: {
            return {
                ...state,
                permissions: action.permissions
            };
        }
        case CHAIN_DETAILS_RESET_STORE: {
            return defaultState;
        }
        case CHAIN_DETAILS_ADD_REPORTS_PERMISSIONS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    chainPermissions: {
                        ...state.data.chainPermissions,
                        ...action.permissions
                    }
                }
            };
        }
        case CHAIN_DETAILS_SINGLE_MENU_ITEM_UPDATE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    menuItems: [
                        ...state.data.menuItems.slice(0, action.index),
                        action.data,
                        ...state.data.menuItems.slice(action.index + 1),
                    ]
                }
            };
        }
        case CHAIN_DETAILS_POPUP_OPEN: {
            return {
                ...state,
                isOpened: true,
            }
        }
        case CHAIN_DETAILS_POPUP_CLOSE: {
            return {
                ...state,
                isOpened: false,
            }
        }
        default: {
            return state;
        }
    }
}
