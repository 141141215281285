import { addNotification } from "@components/GlobalNotification/globalNotification.actions";
import { useDispatch } from "react-redux";

const fallbackCopyTextToClipboard = (value: string) => {
    let textarea = document.createElement("textarea");
    textarea.value = value;
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
};

export const useCopyToClipboard = () => {
    const dispatch = useDispatch();
    const showNotify = (message: {text: string; duration: number;}) => dispatch(addNotification(message));

    const copyToClipboard = async (value: string, message: string = '') => {
        if (!value) return;
        const notifyParams = { text: message, duration: 3000 };
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(value);
            message && showNotify(notifyParams);
            return;
        }

        await navigator.clipboard.writeText(value);
        message && showNotify(notifyParams);
    };

    return copyToClipboard;
};
