
import Http from "../fetch";
import { useData } from "../hooks/useData";
import { API__DOCUMENTS_DATA, Document, iDocument } from "@models/Documents/Documents";

async function getDocuments(searchQuery: string): Promise<iDocument[]> {
    const params = {
        skip: 0,
        take: 1000,
        word: searchQuery
    };
    const response: API__DOCUMENTS_DATA[] = await Http.get('documents', params);
    const documents = response.find(item => item.docType === 'document');
    if ( !documents ) return [];
    return documents.items.map(n => new Document(n));
}

export default function useDocuments(searchQuery: string) {
    return useData([], getDocuments, searchQuery);
}