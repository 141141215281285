import { GoogleMap, HeatmapLayer } from "@react-google-maps/api";
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import Loader from "../../../../../components/new/Loader/Loader";
import { defaultContainerStyle, getRadius, MAP_CENTER, MAP_GRADIENT, MAP_OPTIONS } from "./ChainMap.helpers";


interface iProfileMap {
    onLoad?: () => void;
    onMapClick?: () => void;
    zoom?: number;
    isUnload?: boolean;
    mapId: string;
    isInited: boolean;
    heatmapData: google.maps.LatLng[];
}

interface iMapExtended extends google.maps.Map {
    zoom: number;
}

const ProfileMap: FC<iProfileMap> = ({
    onLoad = () => {},
    onMapClick,
    zoom,
    isUnload,
    mapId,
    isInited,
    heatmapData,
}) => {
    const [map, setMap] = useState<iMapExtended | null>(null);
    const [heatmapRadius, setHeatmapRadius] = useState(5);

    useEffect(() => {
        if (heatmapData.length) return;
        if (!map) return;
        const zoom = map.zoom;
        setHeatmapRadius(getRadius(zoom));
    }, [heatmapData, map]);

    const loaderRef = useRef<HTMLDivElement>(null);

    const mapConfig = useMemo(() => (
        {
            ...MAP_OPTIONS,
            zoom: zoom || MAP_OPTIONS.zoom
        }
    ), [zoom]);

    const handleLoadMap = useCallback((map: google.maps.Map) => {
        setMap(map as iMapExtended);
    }, []);

    const onMapZoomChanged = useCallback(() => {
        if (!map) return;
        const zoom = map.zoom;
        setHeatmapRadius(getRadius(zoom));
    }, [map]);

    const [heatmapLayerData, setHeatmapLayerData] = useState<google.maps.visualization.HeatmapLayer>();

    useEffect(() => {
        const isLoading = !heatmapLayerData || !isInited || !heatmapData.length;
        if ( isLoading ) return;

        const el = document.getElementById(mapId) as HTMLElement;
        if ( !el ) return;

        const observer = new MutationObserver(() => {
            const heatmapCanvases = el.querySelectorAll("canvas");
            if ( heatmapCanvases.length > 0 ) {
                onLoad();
                observer.disconnect();
            }
        });

        observer.observe(el, {
            childList: true,
            subtree: true
        });

        return () => {
            observer.disconnect();
        };
    }, [map, heatmapLayerData, mapId, isInited, heatmapData, onLoad]);

    const renderHeatmapLayer = useMemo(() => {
        if ( !heatmapData.length ) return null;

        return (
            /* @ts-ignore */
            <HeatmapLayer
                options={{
                    radius: heatmapRadius,
                    maxIntensity: 1,
                    opacity: 0.7,
                    gradient: MAP_GRADIENT,
                    data: heatmapData,
                }}
                data={heatmapData}
                onLoad={setHeatmapLayerData}
            />
        )
    }, [heatmapData, heatmapRadius]);

    if ( isUnload ) return null;
    return (
        <div className="ChainMap" >
            {isInited && (
                /* @ts-ignore */
                <GoogleMap
                    mapContainerStyle={defaultContainerStyle}
                    mapContainerClassName="ChainMap__container-map"
                    center={MAP_CENTER}
                    onLoad={handleLoadMap}
                    onClick={onMapClick}
                    onZoomChanged={onMapZoomChanged}
                    options={mapConfig}
                    id={mapId}
                >
                    {renderHeatmapLayer}
                </GoogleMap>
            )}

            <CSSTransition
                in={!isInited || !heatmapData.length}
                timeout={1000}
                classNames="ChainMap__loader-anim"
                nodeRef={loaderRef}
                unmountOnExit
            >
                <div ref={loaderRef}>
                    <div className="ChainMap__loader">
                        <Loader className="is-no-bg" />
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}

export default memo(ProfileMap);