import {
    VIEW_DOCUMENT_FETCH_DATA_STORE,
    VIEW_DOCUMENT_FETCH_DATA_STARTED,
    VIEW_DOCUMENT_FETCH_DATA_LOADED,
    VIEW_DOCUMENT_CLEAR_DATA_STORE
} from '../actions/actionsNamesList';

const defaultState = {
    loading: false,
    data: {
        info: {}
    }
    // info: {},
    // similarDocuments: [],
    // trendspottingArticles: []
};

export default function documentViewData(state = defaultState, action) {
    switch (action.type) {
        case VIEW_DOCUMENT_FETCH_DATA_LOADED: {
            return {
                ...state,
                loading: false
            };
        }
        case VIEW_DOCUMENT_FETCH_DATA_STARTED: {
            return {
                ...state,
                loading: true
            };
        }
        case VIEW_DOCUMENT_FETCH_DATA_STORE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data,
                    info: {
                        ...state.data.info,
                        ...action.data.info
                    }
                }
            };
        }
        case VIEW_DOCUMENT_CLEAR_DATA_STORE: {
            return {
                ...state,
                data: {
                    info: {}
                }
            };
        }
        default: {
            return state;
        }
    }
}
