import { FC, memo, useEffect, useRef } from "react";
import Scrollbars from "react-custom-scrollbars";
import LazyGrid from "@components/new/Grid/LazyGrid/LazyGrid";
import { iDocument } from "@models/Documents/Documents";
import DocumentItem from "../DocumentItem/DocumentItem";
import "./DocumentsTiles.styles.scss";

interface iProps {
    data: iDocument[];
}

const DocumentsTiles: FC<iProps> = ({ data = [] }) => {
    const scrollbar = useRef<Scrollbars>(null);

    useEffect(() => {
        // recalc custom scrollbar thumb height
        setTimeout(() => {
            if ( scrollbar.current ) {
                scrollbar.current.forceUpdate();
            }
        }, 250);
    }, []);

    return (
        <div className="DocumentsTiles">
            <Scrollbars ref={scrollbar}>
                {data.length === 0 && <div>nothing to see here!</div>}
                <div className="DocumentsTiles__grid">
                    <LazyGrid
                        initialRows={2}
                        itemWidth={300}
                        offset={300}
                    >
                        {data.map(document => ({
                            key: document.id,
                            value: <DocumentItem data={document} />,
                        }))}
                    </LazyGrid>
                </div>
            </Scrollbars>
        </div>
    );
};

export default memo(DocumentsTiles);
