import ReactDOM from 'react-dom';
import { Component } from 'react';
import Scrollbar from 'react-custom-scrollbars';
import PT from 'prop-types';
import { contains } from '../../core/old_helpers';
import http from '../../core/fetch';
import Popup from '../Popup/Popup';

class SelectProject extends Component {
    constructor(props) {
        super(props);

        this.state = {
            viewMode: 'Select',
            searchValue: '',
            newProjectName: '',
            newProjectDescription: '',
            isNameUnique: true,
            showValidationErrors: false,
            value: this.props.value,
            showAlternativeProjects: this.props.isMultipleCompaniesMode
        };

        this.close = this.close.bind(this);
        this.setRef = this.setRef.bind(this);
        this.saveNew = this.saveNew.bind(this);
        this.renderOption = this.renderOption.bind(this);
        this.changeSearchValue = this.changeSearchValue.bind(this);
        this.changeNewProjectName = this.changeNewProjectName.bind(this);
        this.changeNewProjectDescription = this.changeNewProjectDescription.bind(this);
        this.handleClickOption = this.handleClickOption.bind(this);
        this.setCreateProjectViewMode = this.setCreateProjectViewMode.bind(this);
        this.setSelectViewMode = this.setSelectViewMode.bind(this);
        this.closeOnEsc = this.closeOnEsc.bind(this);
    }

    componentDidMount() {
        this.dropDown.focus();
        document.addEventListener('click', this.close, true);
        document.addEventListener('keydown', this.closeOnEsc, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.close, true);
        document.removeEventListener('keydown', this.closeOnEsc, true);
    }

    closeOnEsc(e = false) {
        if (e.code === 'Escape') {
            this.props.close();
            e.stopPropagation();
        }
    }

    close(e = false) {
        if (!e || !ReactDOM.findDOMNode(this.dropDown).contains(e.target)) {
            this.props.close();
        }
    }

    handleClickOption(value) {
        const { setValue } = this.props;
        setValue(value);
    }

    renderOption(option, index) {
        const { value } = this.state;
        const isChecked = value === option.value;
        const classList = new Set(['custom-drop-down__option', 'custom-drop-down__option--proj']);

        if (isChecked) {
            classList.add('custom-drop-down__option--active');
        }

        const hasAuthor = Boolean(option.author.trim());

        const date = option.date ? new Date(option.date) : null;
        const formattedDate = date ?
            `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().substr(2)}` :
            `- / - / -`;

        return (
            <div
                className={Array.from(classList).join(' ')}
                key={index}
                onClick={() => this.handleClickOption(option.value)}
                data-testid={`buttonSelectProjectPopupOption-${index}`}
            >
                <div
                    className="custom-drop-down__option-title"
                    title={option.title}
                    data-testid="textSelectProjectPopupOptionTitle"
                >
                    {option.title}
                </div>
                <div
                    className="custom-drop-down__option-date"
                    data-testid="textSelectProjectPopupOptionDate"
                >
                    {formattedDate}
                </div>
                <div
                    className="custom-drop-down__option-length"
                    data-testid="textSelectProjectPopupOptionFilesCount"
                >
                    {option.docCount} files
                </div>
                <div
                    className={
                        `custom-drop-down__option-author
                        ${hasAuthor ? '' : 'custom-drop-down__option-author--empty'}`
                    }
                    title={hasAuthor ? option.author : ''}
                    data-testid="textSelectProjectPopupOptionAuthor"
                >
                    {hasAuthor ? option.author : `-`}
                </div>
            </div>
        );
    }

    renderOptions() {
        const { options, alternativeOptions } = this.props;
        const { searchValue, showAlternativeProjects } = this.state;
        let filteredOptions = (showAlternativeProjects ? alternativeOptions : options)
            .concat([])
            .sort((a, b) => {
                if (a.title.toLowerCase() === 'trend reports') {
                    return -1;
                }
                if (b.title.toLowerCase() === 'trend reports') {
                    return 1;
                }
                return a.title < b.title ? -1 : 1;
            });

        if (searchValue !== '') {
            filteredOptions = filteredOptions.filter(
                option => contains(option.title, searchValue)
            );
        }

        const style = (filteredOptions.length >= 9 ? { height: '306px' } : { height: `${filteredOptions.length * 34 + 2}px` });

        return (
            <div
                className="custom-drop-down__options custom-drop-down__options--proj"
                style={style}
                data-testid="blockSelectProjectPopupOptions"
            >
                <Scrollbar autoHide autoHideTimeout={1000}>
                    {filteredOptions.length ? filteredOptions.map(this.renderOption) : (
                        <span data-testid="textSelectProjectPopupNotFound">
                            Projects are not found.
                        </span>
                    )}
                </Scrollbar>
            </div>
        );
    }

    changeSearchValue(event) {
        this.setState({ searchValue: event.target.value });
    }

    changeNewProjectName(event) {
        const value = event.target.value;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            if (this.request) this.request.abort();
            this.request = http.getXHR(
                'check project unique name',
                { projectName: value }
            );
            this.request.then((response) => {
                this.setState({ isNameUnique: response });
            });
        }, 300);
        this.setState({ newProjectName: value });
    }

    changeNewProjectDescription(event) {
        this.setState({ newProjectDescription: event.target.value });
    }

    setRef(dropDown) {
        this.dropDown = dropDown;
    }

    setSelectViewMode() {
        this.dropDown.classList.add('custom-drop-down--animated-out');
        setTimeout(() => this.dropDown.classList.remove('custom-drop-down--animated-out'), 300);
        this.setState({ viewMode: 'Select' });
    }

    setCreateProjectViewMode() {
        this.dropDown.classList.add('custom-drop-down--animated-in');
        setTimeout(() => this.dropDown.classList.remove('custom-drop-down--animated-in'), 300);
        this.setState({
            viewMode: 'CreateProject',
            newProjectName: this.state.searchValue
        });
    }

    saveNew() {
        const { newProjectDescription, newProjectName, isNameUnique } = this.state;
        if (isNameUnique && newProjectName.trim() !== '' && newProjectDescription.trim() !== '') {
            this.props.saveNew(newProjectName, newProjectDescription);
            this.props.close();
        } else {
            this.setState({ showValidationErrors: true });
        }
    }

    renderSelectView() {
        const { searchValue, showAlternativeProjects } = this.state;
        const { isMultipleCompaniesMode, multipleCompaniesModeTitle } = this.props;
        return (
            <div className="custom-drop-down__select-view">
                <div className="custom-drop-down__header">
                    <div
                        className="custom-drop-down__title"
                        data-testid="textSelectProjectPopupTitle"
                    >
                        Select a project below or
                    </div>
                    <button
                        onClick={this.setCreateProjectViewMode}
                        className="popup__btn popup__btn--project"
                        data-testid="buttonSelectProjectPopupCreate"
                    >
                        Create New Project
                    </button>
                </div>
                <div className="custom-drop-down__main custom-drop-down__main--project">
                    <input
                        type="search"
                        autoFocus="autofocus"
                        value={searchValue}
                        onChange={this.changeSearchValue}
                        className="custom-drop-down__input"
                        placeholder="Search project..."
                        data-testid="inputSelectProjectPopupSearch"
                    />
                    {this.renderOptions()}
                </div>
                <div className="custom-drop-down__section ">
                    {isMultipleCompaniesMode && (
                        <div className="custom-drop-down__section-inner">
                            <div>
                                <div className="custom-drop-down__section-left">
                                    <div className="custom-drop-down__section-static">we are showing</div>
                                    <div className="custom-drop-down__text--bold">
                                        {showAlternativeProjects ? multipleCompaniesModeTitle : 'Datassential projects'}
                                    </div>
                                </div>
                            </div>
                            <button
                                className="custom-drop-down__link"
                                onClick={() => this.setState({
                                    showAlternativeProjects: !showAlternativeProjects,
                                    value: null
                                })}
                            >
                                {showAlternativeProjects ? 'View' : 'Hide'} all projects
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderCreateProjectView() {
        const { newProjectDescription, newProjectName, showValidationErrors, isNameUnique } = this.state;
        let errorMessageText = '';

        if (showValidationErrors) {
            if (!isNameUnique) {
                errorMessageText = 'this name is not available';
            }
        }

        return (
            <div className='custom-drop-down__create-view'>
                <div className="custom-drop-down__header">
                    <div
                        className="custom-drop-down__title"
                        data-testid="textSelectNewProjectPopupTitle"
                    >
                        Create new project
                    </div>
                </div>
                <div className="custom-drop-down__main custom-drop-down__main--project">
                    <div
                        className="popup__error-msg popup__error-msg--proj-select"
                        data-testid="textSelectNewProjectPopupError"
                    >
                        {errorMessageText}
                    </div>
                    <input
                        type="text"
                        value={newProjectName}
                        onChange={this.changeNewProjectName}
                        className={`
                            custom-drop-down__input
                            ${
                            newProjectName.trim() === '' && showValidationErrors
                                ? 'custom-drop-down__input--error'
                                : ''
                            }
                        `}
                        placeholder="Project name"
                        data-testid="inputSelectNewProjectPopupName"
                    />
                    <textarea
                        value={newProjectDescription}
                        onChange={this.changeNewProjectDescription}
                        className={`
                            custom-drop-down__textarea
                            ${
                            newProjectDescription.trim() === '' && showValidationErrors
                                ? 'custom-drop-down__textarea--error'
                                : ''
                            }
                        `}
                        placeholder="Project description"
                        data-testid="inputSelectNewProjectPopupDescription"
                    />
                </div>
                <div className="custom-drop-down__footer custom-drop-down__footer--no-border">
                    <div className="popup__right-side">
                        <button
                            onClick={this.setSelectViewMode}
                            className="popup__link popup__link--comfortaa"
                            data-testid="buttonSelectNewProjectPopupCancel"
                        >
                            cancel
                        </button>
                        <button
                            onClick={this.saveNew}
                            className="popup__btn popup__btn--xmd"
                            data-testid="buttonSelectNewProjectPopupSave"
                        >
                            SAVE
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const classList = new Set(['custom-drop-down', 'custom-drop-down--xwide']);

        return (
            <Popup isOpened={true}>
                <div
                    ref={this.setRef}
                    className={Array.from(classList).join(' ')}
                >
                    {this[`render${this.state.viewMode}View`]()}
                </div>
            </Popup>
        );
    }
}

SelectProject.propTypes = {
    options: PT.array,
    value: PT.oneOfType([
        PT.array,
        PT.string,
        PT.number
    ]),
    setValue: PT.func,
    className: PT.string,
    saveNew: PT.func,
    close: PT.func,
    isMultipleCompaniesMode: PT.bool,
    multipleCompaniesModeTitle: PT.string,
    alternativeOptions: PT.array,
};

export default SelectProject;
