  import { FC, useState } from "react";
import classNames from "classnames";
import Button from "../Button/Button";
import Modal from "../../Modal/Modal";
import "./DownloadButton.styles.scss";
import ReportsDownload from "../../ReportsDownload/ReportsDownload";

interface iProps {
    className?: string;
}

const DownloadButton: FC<iProps> = ({
    className = ""
}) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    return (
        <div className={classNames("DownloadButton", className)}>
            <Button
                onClick={() => setIsPopupOpen(true)}
                modifiers={["primary"]}
            >
                DOWNLOAD
            </Button>

            <Modal
                closeOnOutsideClick
                close={() => setIsPopupOpen(false)}
                isOpened={isPopupOpen}
                isHiddenCloseButton
                className="DownloadButton__reports-popup ChainPage__modal"
            >
                <div className="DownloadButton__reports">
                    <ReportsDownload
                        onClose={() => setIsPopupOpen(false)}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default DownloadButton;
