import React, { FC, useCallback, useMemo } from "react";
import SpriteIcon from "@components/SpriteIcon/SpriteIcon";
import PackageAddWrapper from "@components/PackageAddWrapper/PackageAddWrapper";
import { addFileToPackage } from "@actions/packages.actions";
import { useDispatch } from "react-redux";
import { iFoodProfileSummaryData } from "@models/FoodProfileSummary";
import { getValueWithSign } from "@helpers";
import ProfileDownloadButton from "@pages/FoodProfile/FoodProfileSummary/ProfileDownloadButton";
import { IconHelp } from '@icons';

const FoodProfileSummaryInfo: FC<{
    data: iFoodProfileSummaryData;
    activeSegmentIndex: number;
    searchQuery: string;
    openHaiku: () => void;
    showHaiku: boolean;
    filterLabel?: string;
}> = (
    {
        data: {
            wordId,
            wordName,
            totalPenetration,
            loveOrLikePercentage,
            segments,
            downloadReportUrl,
            restaurantsCommonTitle,
            popularityTitle,
        },
        openHaiku,
        showHaiku,
        activeSegmentIndex,
        searchQuery,
        filterLabel,
    }
) => {
    const dispatch = useDispatch();

    const addToPackage = useCallback(() => {
        dispatch(addFileToPackage({
            itemId: wordId,
            itemDocumentType: 'foodProfile',
        }));
    }, [dispatch, wordId]);

    const { oneYearGrowth, fourYearGrowth } = useMemo(() => {
        if (segments.length === 0) return { oneYearGrowth: 0, fourYearGrowth: 0 };
        return segments[activeSegmentIndex];
    }, [activeSegmentIndex, segments]);

    return (
        <div className="FoodProfileSummaryChartInfo">
            <div className="FoodProfileSummaryChartInfo__header">
                <div
                    className="FoodProfileSummaryChartInfo__title"
                    data-testid="textFoodProfileChartSectionTitle"
                >
                    {(wordName || searchQuery).toLowerCase()}
                </div>
                {!!totalPenetration && (
                    <div
                        className="FoodProfileSummaryChartInfo__subtitle"
                        data-testid="textFoodProfileChartSectionSubTitle"
                    >
                        is on&nbsp;
                        <span className="FoodProfileSummaryChartInfo__mark">
                            {totalPenetration.toFixed(1)}%
                        </span>
                        &nbsp;of US menus
                    </div>
                )}
                <div
                    className="font-text"
                    data-testid="textFoodProfileChartSectionGrowth"
                >
                    <span>
                        12-month growth <b>{isFinite(oneYearGrowth) ? `${getValueWithSign(Math.round(+oneYearGrowth))}%` : 'N/A'}</b>
                    </span>
                    &nbsp;|&nbsp;
                    <span>
                        4-year growth <b>{isFinite(fourYearGrowth) ? `${getValueWithSign(Math.round(+fourYearGrowth))}%` : 'N/A'}</b>
                    </span>
                </div>
                <div className="FoodProfileSummaryChartInfo__header-bottom font-text">
                    {showHaiku && (
                        <div
                            onClick={openHaiku}
                            data-testid="buttonFoodProfileChartSectionOpenPrediction"
                            className="FoodProfileSummaryChartInfo__prediction-btn"
                        >
                            predicted growth
                            <div className="FoodProfileSummaryChartInfo__prediction-btn-info">
                                <IconHelp/>
                                <div className="FoodProfileSummaryChartInfo__prediction-btn-tooltip">
                                    <div className="PredictionTooltip">
                                        <div className="PredictionTooltip__data">
                                            <div>
                                                <b>Menucast™</b> uses machine learning to accurately predict menu penetration up to four years into the future.
                                            </div>
                                            <br/>
                                            <div>
                                                <b>
                                                    Click for a glimpse of where {searchQuery.toUpperCase()} is headed.
                                                </b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="PredictionTooltip__triangle"/>
                                </div>
                            </div>
                        </div>
                    )}
                    {!!filterLabel && (
                        <div
                            className="FoodProfileSummaryChartInfo__filter-label"
                            data-testid="textFoodProfileChartSectionFilterDescription"
                            dangerouslySetInnerHTML={{ __html: filterLabel }}
                        />
                    )}
                </div>
            </div>
            <div className="FoodProfileSummaryChartInfo__body">
                {!!loveOrLikePercentage && (
                    <>
                        <span data-testid="textFoodProfileChartSectionLoveOrLike">
                            <b>{Math.round(loveOrLikePercentage)}%</b> of consumers love or like it.
                        </span>
                        <br/>
                    </>
                )}
                {!!popularityTitle && (
                    <>
                        <span
                            data-testid="textFoodProfileChartSectionPopularity"
                            dangerouslySetInnerHTML={{ __html: popularityTitle }}
                        />
                        <br/>
                    </>
                )}
                {!!restaurantsCommonTitle && (
                    <span data-testid="textFoodProfileChartSectionRestaurantsTitle">
                        {restaurantsCommonTitle}
                    </span>
                )}
            </div>
            <div className="FoodProfileSummaryChartInfo__footer">
                <ProfileDownloadButton
                    testId="buttonFoodProfileChartSectionDownload"
                    url={downloadReportUrl}
                    onClick={() => null}
                />
                <PackageAddWrapper
                    className="FoodProfileSummaryChartInfo__button FoodProfileSummaryChartInfo__button--small font-text clickable"
                    onClick={addToPackage}
                    testId="buttonFoodProfileChartSectionAddToPackage"
                >
                    <SpriteIcon iconId="mi-add-to-package" width={20} height={20}/>
                    package
                </PackageAddWrapper>
            </div>
        </div>
    );
}

export default FoodProfileSummaryInfo;