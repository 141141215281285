import { FC } from "react";
import "./DescriptionList.styles.scss";

export interface iItem {
    label: string;
    value: string | number;
}

interface iProps {
    data: iItem[];
    columns?: number;
}

const DescriptionList: FC<iProps> = ({ data, columns = 1 }) => {
    var style = { "--columns": columns } as React.CSSProperties;

    return (
        <div className="DescriptionList" style={style}>
            {data.map(({ label, value }, index) => {
                return (
                    <div className="DescriptionList__item" key={index}>
                        <div className="DescriptionList__label">{label}</div>
                        <div className="DescriptionList__value">{value}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default DescriptionList;
