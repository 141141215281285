import API_URLS from './apiConfig';
import { downloadBlobFile, getFilenameFromContentDisposition } from './helpers';
import serializeParams from './serializeParams';

const defaultFetchOptons = {
    method: 'get',
    credentials: 'include',
    headers: {
        Accept: 'application/json',
    },
};

class Http {
    constructor() {
        this.getOptions = { ...defaultFetchOptons, method: 'get' };
        this.postOptions = { ...defaultFetchOptons, method: 'post' };
    }

    getUrlFromType(type) {
        const url = API_URLS[type];
        if (!url) {
            throw new Error(`Type "${type}" is not defined in config. Please check the params.`);
        }

        return url;
    }

    handleResponseStatus(status) {
        if (status === 401) {
            window.location.href = `${process.env.REACT_APP_API}userAuth?returnUrl=${window.location.href}`;
        }
        if (status === 404 || status === 204) {
            window.location.href = `${process.env.PUBLIC_URL}404`;
        }
    }

    getQuery(type, params = {}) {
        let query = this.getUrlFromType(type);
        if (Object.keys(params).length) {
            const urlParamsKeys = Object.keys(params).filter(key => query.includes(`:${key}`));
            const queryParamsKeys = Object.keys(params).filter(key => !query.includes(`:${key}`));
            urlParamsKeys.forEach(key => query = query.replace(`:${key}`, params[key]))
            query += `?${serializeParams(
                queryParamsKeys.reduce(
                    (acc, key) => ({
                        ...acc,
                        [key]: params[key],
                    }),
                    {}
                )
            )}`;
        }
        return query;
    }

    fetchFromUrl(query, options, promise, responseType) {
        fetch(query, options)
            .then((response) => {
                this.handleResponseStatus(response.status);

                switch (responseType) {
                    case 'file': {
                        response.blob()
                            .then((result) => {
                                promise.resolve(result);
                            })
                            .catch(error => console.error(query, error));
                        break;
                    }
                    case 'bool': {
                        response.text()
                            .then((result) => {
                                promise.resolve(result);
                            })
                            .catch(error => console.error('fetch', query, error));
                        break;
                    }
                    case 'none': {
                        promise.resolve();
                        break;
                    }
                    default: {
                        response.json()
                            .then((result) => {
                                promise.resolve(result);
                            })
                            .catch((error) => {
                                console.error('fetch', query, error);
                                promise.reject(error);
                            });
                    }
                }
            })
            .catch(error => console.error(query, error));

    }

    fetch(type, params, options, promise, responseType) {
        const query = this.getQuery(type, params);

        this.fetchFromUrl(query, options, promise, responseType);
    }

    get(type, params = {}, getOptions = {}, responseType) {
        return new Promise((resolve, reject) => this.fetch(
            type,
            params,
            { ...this.getOptions, ...getOptions },
            { resolve, reject },
            responseType,
        ));
    }

    post(type, params = {}, postOptions = {}, responseType) {
        return new Promise((resolve, reject) => this.fetch(
            type,
            params,
            {
                ...this.postOptions,
                ...postOptions,
                headers: {
                    ...this.postOptions.headers,
                    ...postOptions.headers,
                },
            },
            { resolve, reject },
            responseType,
        ));
    }

    getXHR(type, params = {}) {
        const query = this.getQuery(type, params);
        const xhr = new window.XMLHttpRequest();
        xhr.open('GET', query);
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.withCredentials = true;
        const promise = new Promise((resolve, reject) => {
            xhr.onload = () => {
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve(JSON.parse(xhr.response));
                } else {
                    this.handleResponseStatus(xhr.status);
                    reject(xhr.statusText);
                }
            };
            xhr.onerror = () => reject(xhr.statusText);
            xhr.send();
        });
        promise.abort = () => {
            xhr.abort();
        };
        return promise;
    }

    postXHR(type, params = {}, data) {
        const query = this.getQuery(type, params);
        const xhr = new window.XMLHttpRequest();
        xhr.open('POST', query);
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
        xhr.withCredentials = true;
        const promise = new Promise((resolve, reject) => {
            xhr.onload = () => {
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve(JSON.parse(xhr.response));
                } else {
                    this.handleResponseStatus(xhr.status);
                    reject(xhr.statusText);
                }
            };
            xhr.onerror = () => reject(xhr.statusText);
            xhr.send(data);
        });
        promise.abort = () => {
            xhr.abort();
        };
        return promise;
    }

    uploadFileXHR(file, onprogress) {
        const query = this.getQuery('upload documents');
        const xhr = new window.XMLHttpRequest();
        xhr.open('POST', query);
        xhr.upload.onprogress = (event) => {
            const progress = (event.loaded / event.total) * 100;

            return onprogress(progress);
        };
        xhr.withCredentials = true;
        const body = new window.FormData();
        body.append('file', file);

        const promise = new Promise((resolve, reject) => {
            xhr.onload = () => {
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve(JSON.parse(xhr.response));
                } else {
                    this.handleResponseStatus(xhr.status);
                    reject(xhr.statusText);
                }
            };
            xhr.send(body);
        });
        promise.abort = () => {
            xhr.abort();
        };

        return promise;
    }

    downloadFileXHRFromUrl(method, query, data) {
        const xhr = new window.XMLHttpRequest();
        xhr.open(method, query);
        xhr.responseType = 'blob';
        xhr.withCredentials = true;
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');

        const promise = new Promise((resolve, reject) => {
            xhr.onload = () => {
                if (xhr.status === 200 && xhr.response && xhr.response.size > 0) {
                    let blob = xhr.response;
                    let disposition = xhr.getResponseHeader('Content-Disposition');
                    const filename = getFilenameFromContentDisposition(disposition);
                    downloadBlobFile(blob, filename);
                    resolve(xhr.response);
                } else {
                    this.handleResponseStatus(xhr.status);
                    reject(xhr.statusText);
                }
            };

            xhr.onerror = () => reject(xhr.statusText);
            xhr.send(data);
        });

        promise.abort = () => {
            xhr.abort();
        };

        return promise;
    }

    downloadFileXHR(method, type, params = {}, data) {
        const query = this.getQuery(type, params);
        return this.downloadFileXHRFromUrl(method, query, data);
    }
}

export default new Http();
