export enum ViewDocuments {
    Table = "Table",
    Tiles = "Tiles"
}

export interface iDocumentsFilter {
    id: number;
    title: string;
    isSelected: boolean;
    months: number | null;
}

export const DOCUMENTS_FILTER: iDocumentsFilter[] = [
    { id: 1, title: "1 month", isSelected: false, months: 1 },
    { id: 2, title: "3 months", isSelected: false, months: 3 },
    { id: 3, title: "6 months", isSelected: false, months: 6 },
    { id: 4, title: "12 months", isSelected: false, months: 12 },
    { id: 5, title: "all time", isSelected: true, months: null },
];

export interface iDocumentsQueryParams {
    q?: string;
}

export const getFileType = (extention: string, postfix: string = `-inner`) => {
    return extention ? `${extention.replace('.', '').substr(0, 3)}${postfix}` : `other${postfix}`
}

export const PUBLICATION_FAMILY_DEPRECATED = '';
export const DOCUMENTS_DOCTYPE = 5;