import { createSelector } from 'reselect';
import { deepCopy } from '../../core/old_helpers';

const documentsLibraryData = state => state.documentsLibraryPopupsData;

export const typesSelector = createSelector(
    documentsLibraryData,
    data => data.types
);
export const projectsSelector = createSelector(
    documentsLibraryData,
    data => data.projects
);
export const contactsSelector = createSelector(
    documentsLibraryData,
    data => data.contacts
);
export const companiesSelector = createSelector(
    documentsLibraryData,
    data => deepCopy(data.companies)
);
export const companyIdSelector = createSelector(
    documentsLibraryData,
    data => data.currentUserCompanyId
);
