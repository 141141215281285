import {
    API__ChainPermissionWrapper,
    API__PenetrationPairedFlavorsMostCommonViewModel
} from "@apiSchema";
import { ID } from "@models/index";
import { iPermissionFields, PermissionLevelEnum, PermissionSection } from "@models/PermissionSection";
import { raw } from "screenfull";

interface iFoodProfilePairedItem {
    id: ID;
    title: string;
    value: number;
}

export interface iFoodProfilePairedItems extends iPermissionFields {
    data: iFoodProfilePairedItem[];
}

type API_Model = API__ChainPermissionWrapper<API__PenetrationPairedFlavorsMostCommonViewModel[]>;

interface iData {
    apiModel?: API_Model;
}

export class FoodProfilePairedItems implements iFoodProfilePairedItems {
    permissionLevel = PermissionLevelEnum.None;
    hasData = true;
    data: iFoodProfilePairedItem[] = [];

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private setData(model: iFoodProfilePairedItems) {
        ({
            permissionLevel: this.permissionLevel,
            hasData: this.hasData,
            data: this.data,
        } = model);
    }

    private mapFromApi(rawData: API_Model) {
        if (rawData.data) {
            this.setData({
                permissionLevel: PermissionSection.getPermissionLevel(rawData.permissionLevel),
                hasData: rawData.hasData && rawData.data.length > 0,
                data: rawData.data.map(item => ({
                    id: item.id,
                    title: item.name,
                    value: +item.value || 0,
                })),
            });
        }
    }
}