import { FC, useContext, useState } from "react";
import { ReportsNameEnum } from "../../core/models/ChainDetails";
import { ChainDetailsDataContext } from "@pages/ChainPage/ChainPage.helpers";
import "./ReportsDownload.styles.scss";
import ReportsItem from "./ReportsItem/ReportsItem";
import Button from "../new/Button/Button";
import Tabs from "../new/Tabs/Tabs";

interface iProps {
    onClose?: () => void;
}

const REPORTS_TO_DOWNLOAD: ReportsNameEnum[] = [
    ReportsNameEnum.Profile,
    ReportsNameEnum.Firefly,
    ReportsNameEnum.BrandFingerprints,
    ReportsNameEnum.LTOActivity,
    ReportsNameEnum.Menu,
];

const tabsItems = [
    {
        id: 0,
        title: 'Download',
    }
];

const ReportsDownload: FC<iProps> = ({
    onClose = () => {
    }
}) => {
    const {
        data: {
            reportsData: rawReportsData
        },
    } = useContext(ChainDetailsDataContext);

    const reportsData = rawReportsData.filter(i => REPORTS_TO_DOWNLOAD.includes(i.data.name));

    const { downloadReports } = useContext(ChainDetailsDataContext);
    const [selectedReportNames, setSelectedReportNames] = useState<ReportsNameEnum[]>([]);

    const handleSelectReport = (name: ReportsNameEnum) => {
        setSelectedReportNames(prevState => {
            const isMatched = prevState.includes(name);
            return isMatched ? prevState.filter(item => item !== name) : [...prevState, name];
        })
    }

    const handleDownloadReports = () => {
        downloadReports(selectedReportNames);
        onClose();
    }

    const isReportSelected = (reportName: ReportsNameEnum) => selectedReportNames.includes(reportName);

    return (
        <div className="ReportsDownload">
            <div className="ReportsDownload__header">Include in my report</div>

            <div className="ReportsDownload__content">
                <Tabs
                    selectedId={0}
                    onChange={() => {}}
                    items={tabsItems}
                />
                {reportsData.map((report, index) => (
                    <ReportsItem
                        key={index}
                        data={report}
                        onClick={handleSelectReport}
                        isSelected={isReportSelected(report.data.name)}
                    />
                ))}
            </div>

            <div className="ReportsDownload__footer">
                <div className="ReportsDownload__action">
                    <Button
                        modifiers={["icon"]}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </div>

                <div className="ReportsDownload__action">
                    <Button
                        modifiers={["primary"]}
                        onClick={handleDownloadReports}
                        isDisabled={selectedReportNames.length === 0}
                    >
                        Download
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ReportsDownload;
