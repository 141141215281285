import React from 'react';
import cn from 'classnames';
import './Loader.styles.scss';

interface Props {
    className?: string;
    isSimple?: boolean;
    size?: number;
}

const Loader: React.FC<Props> = ({ className, isSimple, size }) => {
    if ( isSimple ) return (
        <div className={cn('Loader', 'Loader--simple', [className])}>
            <div className="Loader__dot" />
            <div className="Loader__dot" />
            <div className="Loader__dot" />
        </div>
    );
    return (
        <div className={cn('Loader', [className])}>
            <div className="lds-roller">
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    );
};

export default Loader;
