import { ChainsFilters } from "./ChainsFilters";
import { Place } from "./Place";
import { iChainBase } from "./ChainBase";
import { API__ChainBaseViewModel } from "../apiSchema";

export interface iTopChainsData {
    filtersData: ChainsFilters;
    places: iChainBase[];
}

export class TopChainsData implements iTopChainsData {
    static defaultData: iTopChainsData = {
        filtersData: new ChainsFilters(),
        places: [],
    };
    filtersData = TopChainsData.defaultData.filtersData;
    places = TopChainsData.defaultData.places;

    constructor(data?: { apiModel?: API__ChainBaseViewModel[] }) {
        if (data && data.apiModel) {
            this.getFromApi(data.apiModel);
        }
    }

    private setData(model: iTopChainsData) {
        ({
            filtersData: this.filtersData,
            places: this.places,
        } = model);
    }

    getFromApi(apiModel: API__ChainBaseViewModel[]) {
        const places = apiModel.map(i => new Place({ apiModel: i }));
        this.setData({
            places,
            filtersData: new ChainsFilters({ chainsList: places }),
        });
    }
}