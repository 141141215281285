import { FC, ReactNode } from "react";
import './SideNavigation.styles.scss';
import { ID } from "@models";


const SideNavigation: FC<{
    items: Array<{
        id: ID;
        content: ReactNode;
    }>;
}> = (
    {
        items,
    }
) => {
    return (
        <div className="SideNavigation">
            {items.map(item => (
                <div key={item.id} className="SideNavigation__item">
                    {item.content}
                </div>
            ))}
        </div>
    );
}

export default SideNavigation;