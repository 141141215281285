export enum SortDirectionEnum {
    ASC = 1,
    DESC = -1
};

export enum SectionEnum {
    Profile = 0,
    Contacts = 1,
    Menu = 2,
    Ltos = 3,
    Location = 4,
    Brand = 5,
    Chain = 6,
    LtoTileChainPage = 7,
    LtoTileFoodProfile = 8,
}

export enum States {
    NOT_SET = '',
    AL = 'Alabama',
    AK = 'Alaska',
    AZ = 'Arizona',
    AR = 'Arkansas',
    CA = 'California',
    CO = 'Colorado',
    CT = 'Connecticut',
    DE = 'Delaware',
    FL = 'Florida',
    GA = 'Georgia',
    HI = 'Hawaii',
    ID = 'Idaho',
    IL = 'Illinois',
    IN = 'Indiana',
    IA = 'Iowa',
    KS = 'Kansas',
    KY = 'Kentucky',
    LA = 'Louisiana',
    ME = 'Maine',
    MD = 'Maryland',
    MA = 'Massachusetts',
    MI = 'Michigan',
    MN = 'Minnesota',
    MS = 'Mississippi',
    MO = 'Missouri',
    MT = 'Montana',
    NE = 'Nebraska',
    NV = 'Nevada',
    NH = 'New Hampshire',
    NJ = 'New Jersey',
    NM = 'New Mexico',
    NY = 'New York',
    NC = 'North Carolina',
    ND = 'North Dakota',
    OH = 'Ohio',
    OK = 'Oklahoma',
    OR = 'Oregon',
    PA = 'Pennsylvania',
    RI = 'Rhode Island',
    SC = 'South Carolina',
    SD = 'South Dakota',
    TN = 'Tennessee',
    TX = 'Texas',
    UT = 'Utah',
    VT = 'Vermont',
    VA = 'Virginia',
    WA = 'Washington',
    WV = 'West Virginia',
    WI = 'Wisconsin',
    WY = 'Wyoming',
}

export function getState(abbr: string): string {
    switch (abbr) {
        case 'AL':
            return States.AL;
        case 'AK':
            return States.AK;
        case 'AZ':
            return States.AZ;
        case 'AR':
            return States.AR;
        case 'CA':
            return States.CA;
        case 'CO':
            return States.CO;
        case 'CT':
            return States.CT;
        case 'DE':
            return States.DE;
        case 'FL':
            return States.FL;
        case 'GA':
            return States.GA;
        case 'HI':
            return States.HI;
        case 'ID':
            return States.ID;
        case 'IL':
            return States.IL;
        case 'IN':
            return States.IN;
        case 'IA':
            return States.IA;
        case 'KS':
            return States.KS;
        case 'KY':
            return States.KY;
        case 'LA':
            return States.LA;
        case 'ME':
            return States.ME;
        case 'MD':
            return States.MD;
        case 'MA':
            return States.MA;
        case 'MI':
            return States.MI;
        case 'MN':
            return States.MN;
        case 'MS':
            return States.MS;
        case 'MO':
            return States.MO;
        case 'MT':
            return States.MT;
        case 'NE':
            return States.NE;
        case 'NV':
            return States.NV;
        case 'NH':
            return States.NH;
        case 'NJ':
            return States.NJ;
        case 'NM':
            return States.NM;
        case 'NY':
            return States.NY;
        case 'NC':
            return States.NC;
        case 'ND':
            return States.ND;
        case 'OH':
            return States.OH;
        case 'OK':
            return States.OK;
        case 'OR':
            return States.OR;
        case 'PA':
            return States.PA;
        case 'RI':
            return States.RI;
        case 'SC':
            return States.SC;
        case 'SD':
            return States.SD;
        case 'TN':
            return States.TN;
        case 'TX':
            return States.TX;
        case 'UT':
            return States.UT;
        case 'VT':
            return States.VT;
        case 'VA':
            return States.VA;
        case 'WA':
            return States.WA;
        case 'WV':
            return States.WV;
        case 'WI':
            return States.WI;
        case 'WY':
            return States.WY;
        default:
            return States.NOT_SET;
    }
}