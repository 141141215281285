import { FC, memo, useMemo } from "react";
import { Link } from "react-router-dom";
import Modal from "../../../components/Modal/Modal";
import Table from "../../../components/new/Table/Table";
import { iTableConfigItem } from "../../../components/new/Table/Table.helpers";
import useTableSort from "../../../components/new/Table/useTableSort";
import useAllNotes from "../../../core/apiHooks/useAllNotes";
import { getEnding } from "../../../core/helpers";
import { iNoteExtend } from "../../../core/models/NoteExtend";
import { routeNames } from "../../../core/routeNames";
import { IconClose, IconPrivateNoteNew, IconPublicNoteNew } from "../../../icons";
import "./NotesPopup.styles.scss";
import TableCellValue from "../../../components/new/Table/TableCellValue";
import { CopyButtonContext } from "../../../components/new/Table/CopyButton";

interface iProps {
    isShown: boolean;
    onClose: () => void;
}

const NotesPopup: FC<iProps> = ({ isShown, onClose }) => {
    const { isLoading, data } = useAllNotes();

    const {
        handleSort,
        sortingField,
        sortingDirection,
        getColumnIcon,
        sortedData: tableData,
    } = useTableSort(data, "chainId");

    const tableConfig: iTableConfigItem[] = useMemo(() => {
        const columnIcon = (field: keyof iNoteExtend) =>
            getColumnIcon(field, sortingField, sortingDirection);
        return [
            {
                title: "Chain ID",
                icon: columnIcon("chainId"),
                handleHeaderClick: handleSort("chainId"),
                isActive: sortingField === "chainId",
                width: "10%",
                Value: ({ index }) => <>{tableData[index].chainId}</>,
            },
            {
                title: "Chain name",
                icon: columnIcon("chainName"),
                handleHeaderClick: handleSort("chainName"),
                isActive: sortingField === "chainName",
                width: "15%",
                Value: ({ index }) => (
                    <Link
                        className="NewTopChains__link"
                        to={{
                            pathname: routeNames.chainDetails,
                            search: `id=${tableData[index].chainId}`,
                        }}
                    >
                        <TableCellValue isWrap>{tableData[index].chainName}</TableCellValue>
                    </Link>
                ),
            },
            {
                title: "HQ State",
                icon: columnIcon("state"),
                handleHeaderClick: handleSort("state"),
                isActive: sortingField === "state",
                width: "10%",
                Value: ({ index }) => <>{tableData[index].state}</>,
            },
            {
                title: "Note",
                Value: ({ index }) => (
                    <TableCellValue lines={3}>{tableData[index].note}</TableCellValue>
                ),
            },
            {
                title: "Created by",
                width: "15%",
                Value: ({ index }) => <TableCellValue isWrap>{tableData[index].author}</TableCellValue>,
            },
            {
                title: "Type",
                icon: columnIcon("isPublic"),
                handleHeaderClick: handleSort("isPublic"),
                isActive: sortingField === "isPublic",
                width: "10%",
                Value: ({ index }) => {
                    return tableData[index].isPublic ? (
                        <>
                            <IconPublicNoteNew className="NotesPopup__cell-icon"/> Public
                        </>
                    ) : (
                        <>
                            <IconPrivateNoteNew className="NotesPopup__cell-icon"/> Private
                        </>
                    );
                },
            },
            {
                title: "Date",
                icon: columnIcon("dateTimestamp"),
                handleHeaderClick: handleSort("dateTimestamp"),
                isActive: sortingField === "dateTimestamp",
                width: 150,
                Value: ({ index }) => <>{tableData[index].date}</>,
            },
        ];
    }, [getColumnIcon, handleSort, sortingDirection, sortingField, tableData]);


    const getClipboardData = useMemo(() => ({ head, data }: { head: string[], data: iNoteExtend[] }) => {
        return [
            head,
            ...data.map(item => {
                const type = item.isPublic ? "Public" : "Private";
                return [
                    item.chainId.toString(),
                    item.chainName,
                    item.state,
                    item.note,
                    item.author,
                    type,
                    item.date || "",
                ]
            })
        ]
    }, []);

    return (
        <Modal
            isOpened={isShown}
            close={onClose}
            hasInner={false}
            closeOnOutsideClick
        >
            <div className="NotesPopup">
                <div className="NotesPopup__wrapper">
                    <div className="NotesPopup__header">
                        <div className="NotesPopup__title">
                            {tableData.length} {getEnding("note", tableData.length)}
                        </div>
                        <div className="NotesPopup__close" onClick={onClose}>
                            <IconClose width={16} height={16}/>
                        </div>
                    </div>

                    <div className="NotesPopup__body">
                        <CopyButtonContext.Provider value={{
                            data: getClipboardData({
                                head: tableConfig.map(item => item.title || ""),
                                data
                            })
                        }}>
                            <Table
                                rowsCount={tableData.length}
                                config={tableConfig}
                                rowHeight={90}
                                isLoading={isLoading}
                            />
                        </CopyButtonContext.Provider>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default memo(NotesPopup);
