
import { API__DOCUMENT_DATA, Document, iDocument } from "@models/Documents/Documents";
import Http from "../fetch";
import { useData } from "../hooks/useData";

interface iParams {
    documentId: string;
    documentType: string;
    signature?: string;
}

async function getDocument(params: iParams): Promise<iDocument> {

    const data: iParams = {
        documentId: params.documentId,
        documentType: params.documentType,
    };
    if (params.signature) data.signature = params.signature;

    const response: API__DOCUMENT_DATA = await Http.get('view-document', data);
    return new Document(response.info);
}

export default function useDocument(params: iParams) {
    return useData(new Document(), getDocument, params);
}