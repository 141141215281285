import { createSelector } from 'reselect';
import getFormattedThousand from '../core/getFormattedThousand';
import { makeEnding } from '../core/old_helpers';
import serializeParams from '../core/serializeParams';

const CATEGORIES_DEFAULTS = {
    FoodProfilesItem: {
        title: 'FOOD PROFILES',
        section: 'food',
        sectionOrderNumber: 0,
        templateType: 'columns-list',
        type: 'food',
        openable: true,
        getItemUrl({ title }) {
            return {
                pathname: '/food',
                search: `?q=${encodeURIComponent(title.replace(/ \(.*penetration\)/g, ''))}`,
            };
        },
    },
    InstantChartsAndMacsItem: {
        title: 'INSTANT CHARTS & MACs',
        section: 'food',
        sectionOrderNumber: 1,
        templateType: 'two columns',
        hasSubTitle: true,
        isComplexItemType: true,
        type: 'macAndInstantChart',
        viewMoreUrl: true,
        getViewMoreUrl({ macCount = 0, instantChartsCount = 0 }, query) {
            if (macCount < instantChartsCount) {
                return `/instantCharts?q=${encodeURIComponent(query)}`;
            }

            return `/documents?q=${encodeURIComponent(query)}&doctype=mac`;
        },
        getItemUrl({ instanceId, id, type, docType: doctype }, query) {
            if (type === 'mac') {
                const params = serializeParams({
                    id: instanceId,
                    doctype,
                    fromDocuments: query,
                });
                return {
                    pathname: '/view-document',
                    search: `?${params}`,
                };
            }
            return {
                pathname: '/instantCharts',
                search: `?q=${encodeURIComponent(query)}&id=${id}`,
            };
        },
        getSubTitle({ documentType: type }) {
            if (type === 'MacDocument') {
                return 'Menu Adoption Cycle';
            }
            return 'Instant Chart';
        },
        getType({ documentType: type }) {
            if (type === 'MacDocument') {
                return 'mac';
            }
            return 'instantChart';
        },
    },
    PlaceItem: {
        title: 'CHAINS',
        sectionOrderNumber: 0,
        section: 'places',
        listViewCount: 12,
        hasSubTitle: true,
        viewMoreUrl: true,
        getSubTitle: ({ segment = '', cuisine = '', numberOfUnits: units = 0 }) => {
            const subTitle = [];
            if (segment !== '') {
                subTitle.push(segment.toLowerCase());
            }
            if (cuisine !== '') {
                subTitle.push(cuisine.toLowerCase());
            }
            if (units !== 0) {
                subTitle.push(`${getFormattedThousand(units)} ${makeEnding('unit', units)}`);
            }

            return subTitle.join(' ◦ ');
        },
        getItemUrl({ id }, query) {
            return {
                pathname: '/chainDetails',
                search: `?id=${id}&q=${encodeURIComponent(query)}`,
                from: `/places?q=${encodeURIComponent(query)}`,
            };
        },
        getViewMoreUrl(...args) {
            const { 1: query } = args;
            return `/places?q=${encodeURIComponent(query)}`;
        },
        type: 'chain',
    },
    TrendspottingAndReportItem: {
        title: 'REPORTS',
        sectionOrderNumber: 0,
        section: 'documents',
        hasSubTitle: true,
        type: 'trendspottingAndReports',
        href: true,
        getHref(query) {
            return `${window.pageData.reportsProUrl}?q=${encodeURIComponent(query)}`;
        },
        getItemHref({ id }) {
            return `${window.pageData.reportsProUrl}details/${id}`;
        },
        getSubTitle: ({ dateTimeMonthAndYear: date = '', pages = 0 }) => {
            const subTitle = [];
            if (pages !== 0) {
                subTitle.push(`${getFormattedThousand(pages)} ${makeEnding('page', pages)}`);
            }
            if (date !== '') {
                subTitle.push(date.toLowerCase());
            }

            return subTitle.join(' ◦ ');
        },
        getType({ documentType: type }) {
            if (type === 'TrendspottingItem') {
                return 'trendspotting';
            }
            return 'report';
        },
    },
    DocumentItem: {
        title: 'MY DOCS',
        sectionOrderNumber: 1,
        section: 'documents',
        hasSubTitle: true,
        listViewCount: 5,
        isComplexItemType: true,
        type: 'myDocs',
        viewMoreUrl: true,
        getViewMoreUrl({ items = [] }, query) {
            if (items.filter(item => item.type === 'project').length === items.length) {
                return `/project?id=${items[0].instanceGuid}`;
            }
            if (items.filter(item => item.docType === 17).length === items.length) {
                return '/myDocuments';
            }
            return `/documents?q=${encodeURIComponent(query)}`;
        },
        getItemUrl({ type, instanceGuid, id, docType }, query) {
            if (type === 'document') {
                const params = serializeParams({
                    id,
                    doctype: docType,
                    fromDocuments: query,
                });
                return {
                    pathname: '/view-document',
                    search: `?${params}`,
                };
            }
            return {
                pathname: '/project',
                search: `?id=${instanceGuid}`,
            };
        },
        getSubTitle: ({ dateTimeMonthAndYear: date = '', docType, projectName: project = '', documentsCount = 0 }) => {
            const subTitle = [];
            if (docType === 17) {
                subTitle.push(`${documentsCount} ${makeEnding('file', documentsCount)}`);
            } else {
                subTitle.push(project);
            }
            subTitle.push(date);

            return subTitle.join(' ◦ ');
        },
        getType: ({ docType: type }) => {
            if (type === 17) {
                return 'project';
            }
            return 'document';
        },
    },
};

function normalizeCategory(category, index = -1, query = '', getAllResults = false) {
    if (category) {
        const { documentType: name, items = [], totalItemsCount: total = 0 } = category;
        if (CATEGORIES_DEFAULTS[name]) {
            const config = { ...CATEGORIES_DEFAULTS[name] };
            let viewMoreUrl = '';
            let href = '';

            if (config.href) {
                href = config.getHref(query);
            }

            if (config.viewMoreUrl) {
                viewMoreUrl = config.getViewMoreUrl(category, query);
            }

            const updatedItems = items
                .slice(0, getAllResults ? items.length : config.listViewCount || 5)
                .map((el) => {
                    const updatedItem = { ...el };

                    if (config.hasSubTitle) {
                        updatedItem.subTitle = config.getSubTitle(el);
                    }

                    if (config.isComplexItemType) {
                        updatedItem.type = config.getType(el);
                    } else {
                        updatedItem.type = config.type;
                    }

                    if (config.href) {
                        updatedItem.href = config.getItemHref(updatedItem);
                    } else {
                        updatedItem.url = config.getItemUrl(updatedItem, query);
                    }

                    return updatedItem;
                });

            return {
                ...config,
                viewMoreUrl,
                href,
                index,
                name,
                items: updatedItems,
                total,
            };
        }
        return {};
    }

    return {};
}

const searchPopupQuery = state => state.searchPopup.query;
const searchPopupData = state => state.searchPopup.data;
export const chainsWhoOffersCountSelector = state => state.searchPopup.chainsWhoOffersCount;
const searchPopupOpenedCategory = state => state.searchPopup.openedCategory;
const showDocumentsAnyway = state => state.searchPopup.showDocumentsAnyway;

export const openedCategorySelector = createSelector(
    searchPopupOpenedCategory,
    category => category,
);

export const openedCategoryResultsSelector = createSelector(
    searchPopupQuery,
    searchPopupData,
    searchPopupOpenedCategory,
    (query, searchData, openedCategory) => {
        if (openedCategory === '') {
            return {};
        }

        return normalizeCategory(
            searchData.find(category => category.documentType === openedCategory),
            0,
            query,
            true,
        );
    },
);

export const searchPopupResultsSelector = createSelector(
    searchPopupQuery,
    searchPopupData,
    chainsWhoOffersCountSelector,
    (searchQuery, data, chainsWhoOffersCount) => {
        const results = [];
        if (searchQuery !== '' && data.length) {
            data.forEach((category, index) => {
                const isPlaces = category.documentType === 'PlaceItem';
                const showPlacesCategory = isPlaces && chainsWhoOffersCount > 0;
                if (category.items.length > 0 || showPlacesCategory) {
                    results.push(normalizeCategory(
                        { ...category },
                        index,
                        searchQuery,
                    ));
                }
            });
        }
        return results;
    },
);

export const hasFoodsSelector = createSelector(
    searchPopupResultsSelector,
    results => results
        .filter(category => category.section === 'food')
        .reduce((sectionHasResults, category) => sectionHasResults || category.items.length > 0, false),
);

export const hasChainsSelector = createSelector(
    searchPopupResultsSelector,
    chainsWhoOffersCountSelector,
    (results, chainsWhoOffersCount) =>
        results
            .filter(category => category.section === 'places')
            .reduce((sectionHasResults, category) => sectionHasResults || category.items.length > 0, false)
        || chainsWhoOffersCount > 0
);

export const hasDocsSelector = createSelector(
    searchPopupResultsSelector,
    showDocumentsAnyway,
    (results, showDocsAnyway) => results.filter(category => category.section === 'documents')
            .reduce((sectionHasResults, category) => sectionHasResults || category.items.length > 0, false)
        || showDocsAnyway
);

export const hasResultsSelector = createSelector(
    searchPopupResultsSelector,
    results => results.reduce(
        (sectionHasResults, category) => {
            if (!sectionHasResults) {
                return category.items ? category.items.length > 0 : false;
            }
            return true;
        },
        false,
    ),
);

export const searchPopupAutocompleteSelector = createSelector(
    searchPopupQuery,
    searchPopupResultsSelector,
    (query = '', results = []) => {
        if (query === '') {
            return '';
        }

        let firstMatch;
        let autocomplete = '';
        const sortedResults = [
            ...results
                .filter(cat => cat.section === 'food')
                .sort((cat1, cat2) => cat1.sectionOrderNumber - cat2.sectionOrderNumber),
            ...results
                .filter(cat => cat.section === 'places')
                .sort((cat1, cat2) => cat1.sectionOrderNumber - cat2.sectionOrderNumber),
            ...results
                .filter(cat => cat.section === 'documents')
                .sort((cat1, cat2) => cat1.sectionOrderNumber - cat2.sectionOrderNumber),
        ];

        sortedResults.forEach(({ items }) => {
            firstMatch = firstMatch
                || items
                    .find(({ title }) => {
                        return title.toLowerCase().indexOf(query.toLowerCase()) === 0 && title.length < 20;
                    });
        });

        if (firstMatch) {
            autocomplete = firstMatch.title.toLowerCase();
        }

        return autocomplete;
    },
);
