import { useData } from "../hooks/useData";
import Http from "../fetch";
import { API__LtoViewModel } from "../apiSchema";
import { iChainDetailsLtoSection, ChainDetailsLtoSection } from "../models/ChainDetailsLtoSection";
import { ID } from "../models";

async function getLTO(chainId: ID): Promise<iChainDetailsLtoSection> {
    const response: API__LtoViewModel = await Http.get("get chain lto", { chainId });
    return new ChainDetailsLtoSection(response);
}

export default function useChainDetailsLto(chainId: ID) {
    return useData(new ChainDetailsLtoSection(), getLTO, chainId);
}
