import {
    NOTIFICATION__ADD,
    NOTIFICATION__REMOVE
} from '@actions/actionsNamesList';

const defaultState = {
    notifications: []
};

export default function documentsData(state = defaultState, action) {
    switch (action.type) {
        case NOTIFICATION__ADD: {
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    action.notification
                ]
            };
        }
        case NOTIFICATION__REMOVE: {
            return {
                ...state,
                notifications: state.notifications.filter(n => n.id !== action.id),
            };
        }
        default:
            return state;
    }
}
