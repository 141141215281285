import { FC } from "react";
import classNames from "classnames";
import { Link } from "react-scroll";
import { ChainPageScrollId, getSectionId } from "@pages/ChainPage/ChainPage.helpers";
import "./TabList.styles.scss";
import { ID } from "../../../core/models";

interface iItem {
    id: ID;
    title: string;
}

interface iProps {
    data: iItem[];
    offset?: number;
    className?: string;
}

const TabList: FC<iProps> = (
    {
        data,
        className = "",
        offset = 0,
    }
) => {
    return (
        <div className={classNames("TabList", className)}>
            {data.map(({ id, title }) => (
                <Link
                    activeClass="TabList__link--active"
                    className="TabList__link"
                    to={getSectionId(id)}
                    key={id}
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-offset}
                    containerId={ChainPageScrollId}
                >
                    {title}
                </Link>
            ))}
        </div>
    );
};

export default TabList;
