import classNames from "classnames";
import { FC } from "react";
import Toggle from "../Toggle/Toggle";
import "./FormToggle.styles.scss";

interface iProps {
    labels: [string, string];
    isChecked: boolean;
    onChange: () => void;
}

const FormToggle: FC<iProps> = ({ labels, isChecked, onChange }) => {
    return (
        <div className="FormToggle">
            <div
                className={classNames("FormToggle__label", {
                    "is-active": !isChecked,
                })}
                onClick={onChange}
            >
                {labels[0]}
            </div>

            <Toggle
                onChange={onChange}
                isChecked={isChecked}
                className="FormToggle__toggle"
            />

            <div
                className={classNames("FormToggle__label", {
                    "is-active": isChecked,
                })}
                onClick={onChange}
            >
                {labels[1]}
            </div>
        </div>
    );
};

export default FormToggle;
