import { FC } from "react";
import cn from "classnames";
import { AnalyticsData, getAnalyticsComputedData } from "@models/AnalyticsAttributes";

type Modifiers = 'light' | 'disable';

const ContentWidgetLink: FC<{
    className?: string;
    onClick?: () => void;
    modifiers?: Modifiers[];
    analyticsData?: AnalyticsData;
    testId?: string;
}> = (
    {
        children,
        className,
        onClick,
        modifiers = [],
        analyticsData,
        testId,
    }
) => {
    return (
        <div className="ContentWidget__link-wrapper">
            <div
                className={cn(
                    'font-text',
                    'ContentWidget__link',
                    'clickable',
                    ...modifiers.map(m => `ContentWidget__link--${m}`),
                    className
                )}
                onClick={onClick}
                data-testid={testId}
                {...(analyticsData ? getAnalyticsComputedData(analyticsData) : {})}
            >
                {children}
            </div>
        </div>
    );
}

export default ContentWidgetLink;