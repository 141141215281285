import { FC, useEffect, useState } from "react";
import Autocomplete from "../../../../../components/new/Autocomplete/Autocomplete";
import { iOption } from "../../../../../components/new/Autocomplete/Autocomplete.helpers";
import useChainsListByName from "../../../../../core/apiHooks/useChainsListByName";
import { ID } from "../../../../../core/models";

interface iProps {
    chainId: ID;
    setSelected: (options: iOption[]) => void;
}

const ChainsCompareSearch: FC<iProps> = (
    {
        chainId,
        setSelected,
    }
) => {
    const [autocompleteValue, setAutocompleteValue] = useState("");
    const chainsList = useChainsListByName(chainId, autocompleteValue);
    const [presetChainOptions, setPresetChainOptions] = useState<iOption[]>([]);
    const [selectedPresetChainOptions, setSelectedPresetChainOptions] = useState<iOption[]>([]);

    useEffect(() => {
        if (chainsList.isLoading) return;

        const data = autocompleteValue
            ? chainsList.data.map(item => ({
                  id: item.id,
                  title: item.name,
              }))
            : [];

        setPresetChainOptions(data);

    }, [chainsList.data, chainsList.isLoading, autocompleteValue]);

    const handleSelectPresetChain = (options: iOption[]) => {
        setSelectedPresetChainOptions(options);
        setSelected(options);
    };

    return (
        <Autocomplete
            options={presetChainOptions}
            selectedOptions={selectedPresetChainOptions}
            onSelect={handleSelectPresetChain}
            onSearch={setAutocompleteValue}
            isLoading={chainsList.isLoading}
            autoHighlight
            autoFocus
            placeholder="Type to add a chain..."
        />
    );
};

export default ChainsCompareSearch;
