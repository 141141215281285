import { API__ChainPermissionWrapper } from "../apiSchema";
import Http from "../fetch";
import { useData } from "../hooks/useData";
import { ID } from "../models";
import { iNote, NoteData } from "../models/Note";
import { iPermissionSection } from "../models/PermissionSection";

async function getNotes(id?: ID): Promise<iPermissionSection<iNote[]>> {
    if ( !id ) return new NoteData();

    const data: API__ChainPermissionWrapper<iNote[]> = await Http.get('get notes by chain id', { chainId: id });
    return new NoteData(data);
}

export default function useNotes(id?: ID) {
    return useData(
        new NoteData(),
        getNotes,
        id
    );
}
