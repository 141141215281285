interface iProps {
    styles?: React.CSSProperties;
    onClick?: () => void;
}
const Slide: React.FC<iProps> = ({ children, styles, onClick }) => {
    return (
        <div className={`Slider__slide`} style={styles} onClick={onClick}>
            {children}
        </div>
    );
};

export default Slide;
