import { iChainBase } from "../../core/models/ChainBase";
import { iTableConfigItem } from "../../components/new/Table/Table.helpers";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { getFormattedThousand, highlightMatches } from "../../core/helpers";
import { routeNames } from "../../core/routeNames";
import { ColumnIconType } from "../../components/new/Table/useTableSort";
import { DEFAULT_FILTER_TITLE } from "../../core/models/ChainsFilters";

export function useTableConfig(
    places: iChainBase[],
    sortingField: keyof iChainBase | null,
    sortingDirection: 'asc' | 'desc',
    handleSort: (fieldName: keyof iChainBase) => () => void,
    getColumnIcon: ColumnIconType,
    searchQuery: string,
): iTableConfigItem[] {
    const columnIcon = useCallback(
        (fieldName: keyof iChainBase) => getColumnIcon(fieldName, sortingField, sortingDirection),
        [getColumnIcon, sortingDirection, sortingField]
    );

    const columnsWidthList = {
        name: 25,
        metroArea: 12,
        stateFullName: 12,
        numberOfUnits: 8,
        unitsGrowth: 8,
        segment: 15,
        menuType: 15,
    };

    return useMemo((): iTableConfigItem[] => [
        {
            styles: {
                cell: {
                    paddingLeft: 0,
                    flexBasis: `${columnsWidthList['name']}%`,
                },
            },
            title: 'Chain',
            icon: columnIcon('name'),
            isActive: sortingField === 'name',
            Value: ({ index }) => (
                <Link
                    className="NewTopChains__link"
                    to={{
                        pathname: routeNames.chainDetails,
                        search: `id=${places[index].id}`,
                    }}
                    dangerouslySetInnerHTML={{
                        __html: highlightMatches(
                            places[index].name,
                            searchQuery,
                            (match) => `<b class="table__data-highlight">${match}</b>`
                        ),
                    }}
                />
            ),
            handleHeaderClick: handleSort("name"),
        },
        {
            styles: {
                cell: {
                    flexBasis: `${columnsWidthList['metroArea']}%`,
                },
            },
            title: 'HQ Metro',
            icon: columnIcon('metroArea'),
            isActive: sortingField === 'metroArea',
            Value: ({ index }) => <>{places[index].metroArea}</>,
            handleHeaderClick: handleSort("metroArea"),
        },
        {
            styles: {
                cell: {
                    flexBasis: `${columnsWidthList['stateFullName']}%`,
                },
            },
            title: 'HQ State',
            icon: columnIcon('stateFullName'),
            isActive: sortingField === 'stateFullName',
            Value: ({ index }) => <>{places[index].stateFullName}</>,
            handleHeaderClick: handleSort("stateFullName"),
        },
        {
            styles: {
                cell: {
                    flexBasis: `${columnsWidthList['numberOfUnits']}%`,
                },
            },
            title: 'Current Units',
            icon: columnIcon('numberOfUnits'),
            isActive: sortingField === 'numberOfUnits',
            textCentered: true,
            Value: ({ index }) => (
                <>{getFormattedThousand(places[index].numberOfUnits)}</>
            ),
            handleHeaderClick: handleSort("numberOfUnits"),
        },
        {
            styles: {
                cell: {
                    flexBasis: `${columnsWidthList['unitsGrowth']}%`,
                },
            },
            title: 'Unit Growth (1yr)',
            icon: columnIcon('unitsGrowth'),
            isActive: sortingField === 'unitsGrowth',
            textCentered: true,
            Value: ({ index }) => {
                const val = places[index].unitsGrowth;
                let renderValue = DEFAULT_FILTER_TITLE;
                if (val !== null) {
                    renderValue = `${val > 0 ? "+" : ''}${getFormattedThousand(val)}`;
                }

                return (
                    <span>
                        {renderValue}
                    </span>
                )
            },
            handleHeaderClick: handleSort("unitsGrowth"),
        },
        {
            styles: {
                cell: {
                    flexBasis: `${columnsWidthList['segment']}%`,
                },
            },
            title: 'Segment',
            icon: columnIcon('segment'),
            isActive: sortingField === 'segment',
            Value: ({ index }) => <>{places[index].segment}</>,
            handleHeaderClick: handleSort("segment"),
        },
        {
            styles: {
                cell: {
                    flexBasis: `${columnsWidthList['menuType']}%`,
                },
            },
            title: 'Menu Type',
            icon: columnIcon('menuType'),
            isActive: sortingField === 'menuType',
            Value: ({ index }) => <>{places[index].menuType}</>,
            handleHeaderClick: handleSort("menuType"),
        },
    ], [columnIcon, handleSort, places, sortingField, searchQuery])
}