import { useData } from "../hooks/useData";
import Http from "../fetch";
import { API__Heatmap } from "../models/Heatmap";
import { ID } from "../models";

async function getHeatmapData(chainId: ID): Promise<google.maps.LatLng[]> {
    const response: API__Heatmap[] = await Http.post(
        "chain map",
        {},
        {
            body: JSON.stringify({ chainId }),
            headers: {
                "content-type": "application/json; charset=utf-8",
            },
        },
    );

    const coords = (lat: number, lng: number) => new google.maps.LatLng(lat, lng);

    return response.map(item => coords(+item.lt, +item.ln));
}

export default function useHeatmapData(chainId: ID) {
    return useData([], getHeatmapData, chainId);
}
