import { forwardRef} from "react";
import { iNote } from "../../../../core/models/Note";
import { CustomBaseScrollbars } from "../../../CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";
import { NotesItem } from "../NotesItem/NotesItem";

interface iProps {
    data: iNote[];
    isLoading?: boolean;
    onEdit: (note: iNote) => void;
    onRemove: (id: number) => void;
}

const NotesList = forwardRef((props: iProps, ref: any) => {
    const { data, onEdit, onRemove, isLoading } = props;

    const updateScrollbar = () => {
        if ( ref && ref.current ) {
            ref.current.update();
        }
    }

    return (
        <CustomBaseScrollbars refSetter={ref}>
            {data.length > 0 &&
                    data.map(note => (
                        <NotesItem
                            key={note.id}
                            data={note}
                            onUpdate={onEdit}
                            onRemove={onRemove}
                            updateScrollbar={updateScrollbar}
                        />
                    ))}

                {data.length === 0 && !isLoading && (
                    <div className="NotesItems__no-data">No notes...</div>
                )}
        </CustomBaseScrollbars>

    );
});

export default NotesList;
