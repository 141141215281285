import * as icons from '../../icons';
import { IconReportPro } from "../../icons";

export const ICONS = {
    'add-to-package': icons.IconAddToPackage,
    'admin': icons.IconAdmin,
    'adoption': icons.IconAdoption,
    'arrow_project': icons.IconArrowProject,
    'auto_suggest_documents': icons.IconAutoSuggestDocuments,
    'auto_suggest_food': icons.IconAutoSuggestFood,
    'auto_suggest_go': icons.IconAutoSuggestGo,
    'auto_suggest_places': icons.IconAutoSuggestPlaces,
    'back_arrow': icons.IconBackArrow,
    'BrandFingerprints_Logo_Violet_White': icons.IconBrandfingerprintsLogoVioletWhite,
    'buzz': icons.IconBuzz,
    'buzz-grayscale': icons.IconBuzzGrayscale,
    'check': icons.IconCheck,
    'chop': icons.IconChop,
    'chop-green-dark': icons.IconChopGreenDark,
    'close': icons.IconClose,
    'copy-icon': icons.IconCopy,
    'cuisine': icons.IconCuisine,
    'custom': icons.IconCustom,
    'custom-grayscale': icons.IconCustomGrayscale,
    'datassential-logo': icons.IconDatassentialLogo,
    'delete': icons.IconDelete,
    'discover': icons.IconDiscover,
    'docs': icons.IconDocs,
    'document-add': icons.IconDocumentAdd,
    'double-arrow-down': icons.IconDoubleArrowDown,
    'download': icons.IconDownload,
    'drag-n-drop': icons.IconDragNDrop,
    'dragonfly': icons.IconDragonfly,
    'dragonfly-grayscale': icons.IconDragonflyGrayscale,
    'Dragonfly_Logo_Green_White': icons.IconDragonflyLogoGreenWhite,
    'dropdown-arrow': icons.IconDropdownArrow,
    'edit': icons.IconEdit,
    'edit_blue': icons.IconEditBlue,
    'edit_dark': icons.IconEditDark,
    'expand': icons.IconExpand,
    'explore': icons.IconExplore,
    'explore-gradient': icons.IconExploreGradient,
    'explore-gradient-text': icons.IconExploreGradientText,
    'F3_title': icons.IconF3Title,
    'F3Logo': icons.IconF3logo,
    'F3Logo-dark': icons.IconF3logoDark,
    'F3Logo-text': icons.IconF3logoText,
    'facebook-icon': icons.IconFacebook,
    'file-aif': icons.IconFileAif,
    'file-aif-bw': icons.IconFileAifBw,
    'file-aif-inner': icons.IconFileAifInner,
    'file-arc': icons.IconFileArc,
    'file-arc-bw': icons.IconFileArcBw,
    'file-arc-inner': icons.IconFileArcInner,
    'file-avi': icons.IconFileAvi,
    'file-avi-bw': icons.IconFileAviBw,
    'file-avi-inner': icons.IconFileAviInner,
    'file-bmp': icons.IconFileBmp,
    'file-bmp-bw': icons.IconFileBmpBw,
    'file-bmp-inner': icons.IconFileBmpInner,
    'file-csv': icons.IconFileCsv,
    'file-csv-bw': icons.IconFileCsvBw,
    'file-csv-inner': icons.IconFileCsvInner,
    'file-doc': icons.IconFileDoc,
    'file-doc-bw': icons.IconFileDocBw,
    'file-doc-inner': icons.IconFileDocInner,
    'file-fvl': icons.IconFileFvl,
    'file-fvl-bw': icons.IconFileFvlBw,
    'file-fvl-inner': icons.IconFileFvlInner,
    'file-gif': icons.IconFileGif,
    'file-gif-bw': icons.IconFileGifBw,
    'file-gif-inner': icons.IconFileGifInner,
    'file-jpg': icons.IconFileJpg,
    'file-jpg-bw': icons.IconFileJpgBw,
    'file-jpg-inner': icons.IconFileJpgInner,
    'file-mov': icons.IconFileMov,
    'file-mov-bw': icons.IconFileMovBw,
    'file-mov-inner': icons.IconFileMovInner,
    'file-mp3': icons.IconFileMp3,
    'file-mp3-bw': icons.IconFileMp3Bw,
    'file-mp3-inner': icons.IconFileMp3Inner,
    'file-mp4': icons.IconFileMp4,
    'file-mp4-bw': icons.IconFileMp4Bw,
    'file-mp4-inner': icons.IconFileMp4Inner,
    'file-other': icons.IconFileOther,
    'file-other-bw': icons.IconFileOtherBw,
    'file-other-inner': icons.IconFileOtherInner,
    'file-pdf': icons.IconFilePdf,
    'file-pdf-bw': icons.IconFilePdfBw,
    'file-pdf-inner': icons.IconFilePdfInner,
    'file-png': icons.IconFilePng,
    'file-png-bw': icons.IconFilePngBw,
    'file-png-inner': icons.IconFilePngInner,
    'file-ppt': icons.IconFilePpt,
    'file-ppt-bw': icons.IconFilePptBw,
    'file-ppt-inner': icons.IconFilePptInner,
    'file-rtf': icons.IconFileRtf,
    'file-rtf-bw': icons.IconFileRtfBw,
    'file-rtf-inner': icons.IconFileRtfInner,
    'file-txt': icons.IconFileTxt,
    'file-txt-bw': icons.IconFileTxtBw,
    'file-txt-inner': icons.IconFileTxtInner,
    'file-wma': icons.IconFileWma,
    'file-wma-bw': icons.IconFileWmaBw,
    'file-wma-inner': icons.IconFileWmaInner,
    'file-wmf': icons.IconFileWmf,
    'file-wmf-bw': icons.IconFileWmfBw,
    'file-wmf-inner': icons.IconFileWmfInner,
    'file-xls': icons.IconFileXls,
    'file-xls-bw': icons.IconFileXlsBw,
    'file-xls-inner': icons.IconFileXlsInner,
    'file-zip': icons.IconFileZip,
    'file-zip-bw': icons.IconFileZipBw,
    'file-zip-inner': icons.IconFileZipInner,
    'files': icons.IconFiles,
    'filters': icons.IconFilters,
    'fingerprints': icons.IconFingerprints,
    'fingerprints-grayscale': icons.IconFingerprintsGrayscale,
    'firefly': icons.IconFirefly,
    'firefly-grayscale': icons.IconFireflyGrayscale,
    'firefly_logo': icons.IconFireflyLogo,
    'Firefly_Logo_Orange_White': icons.IconFireflyLogoOrangeWhite,
    'flavor': icons.IconFlavor,
    'flavor-enchanced': icons.IconFlavorEnchanced,
    'flavor-grayscale': icons.IconFlavorGrayscale,
    'Flavor_Logo_Pink_White': icons.IconFlavorLogoPinkWhite,
    'foodscape-logo-big': icons.IconFoodscapeLogoBig,
    'foodscape-logo-white': icons.IconFoodscapeLogoWhite,
    'FoodStudio_Logo_Lime_White': icons.IconFoodstudioLogoLimeWhite,
    'foodstudio-sidebar-icon': icons.IconFoodstudioSidebar,
    'foodstudio-sidebar-icon-grayscale': icons.IconFoodstudioSidebarGrayscale,
    'foursquare-icon': icons.IconFoursquare,
    'fullscreen': icons.IconFullscreen,
    'GO_logo': icons.IconGoLogo,
    'google-icon': icons.IconGoogle,
    'grande_dragonfly': icons.IconGrandeDragonfly,
    'grande_dragonfly-grayscale': icons.IconGrandeDragonflyGrayscale,
    'group-icon': icons.IconGroup,
    'groups': icons.IconGroups,
    'haiku': icons.IconHaiku,
    'haiku-big': icons.IconHaikuBig,
    'haiku-big-new': icons.IconHaikuBigNew,
    'haiku-colored': icons.IconHaikuColored,
    'haiku-line': icons.IconHaikuLine,
    'heart': icons.IconHeart,
    'help-icon': icons.IconHelp,
    'question': icons.IconQuestion,
    'icon-export-excel': icons.IconExportExcel,
    'icon-export-pdf': icons.IconExportPdf,
    'icon-pdf': icons.IconPdf,
    'icon-ppt': icons.IconPpt,
    'icon-xls': icons.IconXls,
    'impersonate': icons.IconImpersonate,
    'inception': icons.IconInception,
    'info': icons.IconInfo,
    'insider': icons.IconInsider,
    'insider-grayscale': icons.IconInsiderGrayscale,
    'Insider_Logo_Orange_White': icons.IconInsiderLogoOrangeWhite,
    'instagram-icon': icons.IconInstagram,
    'line_4_3': icons.IconLinex4x3,
    'line_16_9': icons.IconLinex16x9,
    'link': icons.IconLink,
    'link_blue': icons.IconLinkBlue,
    'link_dark': icons.IconLinkDark,
    'linkedin-icon': icons.IconLinkedin,
    'linkedin-icon-colored': icons.IconLinkedinColored,
    'local': icons.IconLocal,
    'local-enchanced': icons.IconLocalEnchanced,
    'local-grayscale': icons.IconLocalGrayscale,
    'Local_Logo_Blue_White': icons.IconLocalLogoBlueWhite,
    'location': icons.IconLocation,
    'log-out': icons.IconLogOut,
    'logo-clicked': icons.IconLogoClicked,
    'logo-collapsed': icons.IconLogoCollapsed,
    'logo-dark': icons.IconLogoDark,
    'logo-default': icons.IconLogoDefault,
    'logo-gradient': icons.IconLogoGradient,
    'logo-hovered': icons.IconLogoHovered,
    'logo-normal': icons.IconLogoNormal,
    'logo-pin': icons.IconLogoPin,
    'mac': icons.IconMac,
    'menutrends': icons.IconMenutrends,
    'menutrends-grayscale': icons.IconMenutrendsGrayscale,
    'MenuTrends_Logo_Blue_White': icons.IconMenutrendsLogoBlueWhite,
    'mi-add-to-package': icons.IconMiAddToPackage,
    'mi-chain': icons.IconMiChain,
    'mi-download': icons.IconMiDownload,
    'mi-insider': icons.IconMiInsider,
    'mi-scores': icons.IconMiScores,
    'my-documents': icons.IconMyDocuments,
    'nestle': icons.IconNestle,
    'order': icons.IconOrder,
    'order-arrows': icons.IconOrderArrows,
    'order-reverse': icons.IconOrderReverse,
    'packages-icon': icons.IconPackages,
    'packages-remove-icon': icons.IconPackagesRemove,
    'pause': icons.IconPause,
    'phone': icons.IconPhone,
    'pinnacle_dragonfly': icons.IconPinnacleDragonfly,
    'pinnacle_dragonfly-grayscale': icons.IconPinnacleDragonflyGrayscale,
    'pinterest-icon': icons.IconPinterest,
    'play': icons.IconPlay,
    'ppt-o': icons.IconPptO,
    'private-note': icons.IconPrivateNote,
    'project-icon': icons.IconProject,
    'project-icon-bw': icons.IconProjectBw,
    'proliferation': icons.IconProliferation,
    'public-note': icons.IconPublicNote,
    'remove': icons.IconRemove,
    'sample': icons.IconSample,
    'scores': icons.IconScores,
    'scores-grayscale': icons.IconScoresGrayscale,
    'Scores_Launch_NEW_Button': icons.IconScoresLaunchNewButton,
    'Scores_Logo_Yellow_White': icons.IconScoresLogoYellowWhite,
    'search': icons.IconSearch,
    'search-small': icons.IconSearchSmall,
    'search-widget-bg': icons.IconSearchWidgetBg,
    'segment': icons.IconSegment,
    'send-email': icons.IconSendEmail,
    'share': icons.IconShare,
    'share_link': icons.IconShareLink,
    'snap-assistant-qa': icons.IconSnapAssistantQa,
    'snap-assistant-trends': icons.IconSnapAssistantTrends,
    'snap-assistant-voice': icons.IconSnapAssistantVoice,
    'speaker': icons.IconSpeaker,
    'speaker-muted': icons.IconSpeakerMuted,
    'star': icons.IconStar,
    'state': icons.IconState,
    'success': icons.IconSuccess,
    'success-2': icons.IconSuccessx2,
    'twitter-icon': icons.IconTwitter,
    'ubiquity': icons.IconUbiquity,
    'units': icons.IconUnits,
    'user': icons.IconUser,
    'wiki-icon': icons.IconWiki,
    'web': icons.IconWeb,
    'xls-o': icons.IconXlsO,
    'yelp': icons.IconYelp,
    'yelp-new': icons.IconYelpNew,
    'youtube-icon': icons.IconYoutube,
    'ReportPro': icons.IconReportPro,
    'logo-snap-ds': icons.IconLogoSnapDs,
    'logo-snap-ds-icon': icons.IconLogoSnapDsIcon,
};

interface iProps {
    iconId: keyof typeof ICONS;
    width?: number;
    height?: number;
    className?: string;
    style?: React.CSSProperties
}
/* This component is deprecated. Don't use it! */
const SpriteIcon:React.FC<iProps> = ({
    iconId,
    width = 16,
    height = 16,
    className = 'icon',
    style = {},
    ...rest
}) => {
    let Component = ICONS[iconId] || (() => 'no icon');

    return (
        <Component
            className={className}
            width={width}
            height={height}
            style={style}
            {...rest}
        />
    );
}

export default SpriteIcon;
