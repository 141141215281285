import { iNoteExtend, NoteExtend } from "../models/NoteExtend";
import Http from "../fetch";
import { useData } from "../hooks/useData";

async function gelAllNotes(): Promise<iNoteExtend[]> {
    const response: iNoteExtend[] = await Http.get("get all chain notes");
    return response.map(p => new NoteExtend({ apiModel: p }));
}

export default function useAllNotes() {
    return useData([], gelAllNotes);
}