import http from '../..//core/fetch';
import {
    DOCUMENT_EDIT_POPUP,
} from '../../actions/actionsNamesList';
import {
    getData as updateDocumentsLibraryData,
    updatePageToReload,
    separateContacts,
} from '..//DocumentsLibraryPopups/documentsLibraryPopups.actions';
import {
    FILE_SIZE_MAX_LIMIT,
} from '..//DocumentsLibraryPopups/documentsLibraryPopups.reducers';
import { addNotification } from '../../components/GlobalNotification/globalNotification.actions';

const {
    OPEN,
    CLOSE,
    SET_DATA,
    SHOW_VALIDATION_ERRORS,
    HIDE_VALIDATION_ERRORS,
    SET_SAVING_REQUESTED,
    UNSET_SAVING_REQUESTED,
    STORE_UPLOADING_FILE,
    REMOVE_REQUESTED_SAVE_DATA,
    STORE_REQUESTED_SAVE_DATA,
} = DOCUMENT_EDIT_POPUP;

let timer;
let request = {
    abort() {},
    then() {},
    catch() {}
};

export function showValidationErrors() {
    return {
        type: SHOW_VALIDATION_ERRORS
    };
}

export function hideValidationErrors() {
    return {
        type: HIDE_VALIDATION_ERRORS
    };
}

export function startEditing(document) {
    return (dispatch, getState) => {
        const { customerViewCompanyId, types } = getState().documentsLibraryPopupsData;

        const currentType = types.find(({ value }) => value === document.typeId);

        if (!currentType) {
            const currentType = types.find(({ title }) => title.toLowerCase() === document.typeName.toLowerCase());

            document.typeId = currentType.value;
        }
        const params = { id: document.id };
        let minUsersCanEdit = 1;
        if (customerViewCompanyId) {
            params.companyId = customerViewCompanyId;
            minUsersCanEdit = 0
        }
        http.get('get document share settings', params)
            .then((response) => {
                const { canEditGroups, canViewGroups, canEditUsers, canViewUsers } = response;
                const { contacts } = getState().documentsLibraryPopupsData;
                const share = {
                    type: 0,
                    contactsIds: []
                };
                const [usersIds] = separateContacts(contacts);

                if (canEditGroups.length === 0 && canEditUsers.length === minUsersCanEdit) {
                    /* only logged user can edit */
                    share.type = 2;
                    /* set view contacts */
                    share.contactsIds = [
                        ...canViewUsers,
                        ...canViewGroups
                    ];
                } else if (canViewUsers.length === usersIds.length && canEditUsers.length === usersIds.length) {
                    /* all users can view */
                    share.type = 0;
                    /* set edit contacts */
                    share.contactsIds = [
                        ...canEditUsers,
                        ...canEditGroups
                    ];
                } else if (canViewUsers.length === usersIds.length) {
                    /* all users can view */
                    share.type = 1;
                    /* set edit contacts */
                    share.contactsIds = [
                        ...canEditUsers,
                        ...canEditGroups
                    ];
                }

                dispatch(setData({
                    ...document,
                    shareWithIds: response.companiesIds,
                    shareType: share.type,
                    shareContacts: new Map(
                        share.contactsIds.map(id => [id, contacts.find(contact => contact.id === id)])
                    ),
                }));
                dispatch(open());
            });
    };
}

export function open() {
    return (dispatch) => {
        dispatch({
            type: OPEN
        });
        dispatch(updateDocumentsLibraryData());
    };
}

export function close() {
    return {
        type: CLOSE
    };
}

function sendChanges(document) {
    return (dispatch, getState) => {
        const { contacts, currentUserId, customerViewCompanyId, types, } = getState().documentsLibraryPopupsData;
        const { shareContacts, shareType } = document;

        /* destructuring (comment near the accumulator initial value) */
        const [usersIds, groupsIds] = separateContacts(contacts);

        const selectedUsersIds = usersIds.filter(id => shareContacts.has(id));
        const selectedGroupsIds = groupsIds.filter(id => shareContacts.has(id));

        const whoCanView = {
            usersIds: customerViewCompanyId ? [] : [currentUserId],
            groupsIds: []
        };
        const whoCanEdit = {
            usersIds: customerViewCompanyId ? [] : [currentUserId],
            groupsIds: []
        };

        switch (shareType) {
            case 0: {
                /* all users and groups can view and edit */
                whoCanView.usersIds = usersIds;
                whoCanView.groupsIds = groupsIds;
                whoCanEdit.usersIds = usersIds;
                whoCanEdit.groupsIds = groupsIds;
                break;
            }
            case 1: {
                /* all users and groups can view */
                whoCanView.usersIds = usersIds;
                whoCanView.groupsIds = groupsIds;

                /* only selected users and groups can edit */
                whoCanEdit.usersIds = selectedUsersIds;
                whoCanEdit.groupsIds = selectedGroupsIds;
                break;
            }
            case 2: {
                /* only selected users and groups can view */
                whoCanView.usersIds = selectedUsersIds;
                whoCanView.groupsIds = selectedGroupsIds;
                /* only current user can edit */
                break;
            }
            default: {
                break;
            }
        }
        const params = {};

        if (customerViewCompanyId !== null) {
            params.overrideForCompanyId = customerViewCompanyId;
        }


        let documentTypeName = '';
        let type = types.find(({ value })=> value === document.typeId);
        if (type) {
            documentTypeName = type.title;
        }

        const requestBody = {
            id: document.id,
            title: document.name.trim(),
            description: document.description,
            documentTypeId: document.typeId,
            documentTypeName,
            projectsIds: [document.projectId],
            whoCanView,
            whoCanEdit,
            selectedCompany: document.shareWithIds,
        };

        if (document.isFileChanged) {
            requestBody.file = document.backEndData;
        }

        http.post(
            'save documents',
            params,
            {
                body: JSON.stringify([requestBody]),
                headers: {
                    'content-type': 'application/json'
                }
            }
        )
            .then(() => {
                dispatch(updateDocumentsLibraryData());
                dispatch(updatePageToReload());
                dispatch(removeRequestedSaveData(document.id));
                dispatch(close());
                dispatch(addNotification({
                    text: 'Files successfully uploaded.',
                    duration: 5000
                }));
                if (getState().documentEditPopupData.id === document.id) {
                    dispatch(clearData());
                    dispatch(updateTimestamp());
                }
            });
    }
}

export function save(specificDocumentId, backEndData) {
    return (dispatch, getState) => {
        if (specificDocumentId) {
            const document = getState().documentEditPopupData.requestedSaves.get(specificDocumentId);
            dispatch(sendChanges({
                ...document,
                backEndData,
            }));
        } else {
            const data = getState().documentEditPopupData;
            const { isSavingRequested } = data;
            if (!isSavingRequested) {
                dispatch(storeRequestedSaveData());
                dispatch(clearData());
            } else {
                dispatch(sendChanges(data));
            }
        }
    };
}
export function checkNameUnique(name) {
    return (dispatch) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            request.abort();
            dispatch(setData({ isNameUniquenessChecking: true }));

            request = http.getXHR('check document unique name', { documentName: name });
            request.then((response) => {
                dispatch(setData({
                    isNameUnique: response,
                    isNameUniquenessChecking: false
                }));
            });
        }, 500);
    };
}

export function setData(data) {
    return {
        type: SET_DATA,
        data
    };
}

export function createNewProject(name = '', description) {
    return (dispatch) => {
        http.post('save project', { name: name.trim(), description })
            .then((response) => {
                dispatch(updateDocumentsLibraryData());
                dispatch(updatePageToReload());
                dispatch(setData({
                    projectId: response.id
                }));
            });
    };
}

function updateTimestamp() {
    return (dispatch) => {
        dispatch(setData({
            timestamp: +new Date()
        }));
    }
}

export function clearData() {
    return (dispatch) => {
        // if (currentUpload) currentUpload.abort();
        dispatch(setData({
            showErrors: false,
            id: null,
            name: '',
            initialName: '',
            isNameUnique: false,
            isNameUniquenessChecking: false,
            description: '',
            typeId: null,
            projectId: null,
            shareType: 0,
            shareContacts: new Map(),
            shareWithIds: [],
            size: 0,
            isFileChanged: false,
            backEndData: null,
            isSavingRequested: false,
        }));
    };
}

export function abortPreviousUploads(id) {
    return () => {
        if(currentUpload && currentId === id) currentUpload.abort();
    }
}

function setSavingRequested() {
    return {
        type: SET_SAVING_REQUESTED
    };
}

function unsetSavingRequested() {
    return {
        type: UNSET_SAVING_REQUESTED
    };
}

export function storeUploadingFile(file) {
    return (dispatch) => {
        dispatch(unsetSavingRequested());
        dispatch({
            type: STORE_UPLOADING_FILE,
            file,
        });
        dispatch(upload());
    };
}

let currentUpload, currentId;

export function upload() {
    return (dispatch, getState) => {
        const { file, id } = getState().documentEditPopupData;
        const isFileSizeValid = file.size < FILE_SIZE_MAX_LIMIT;
        if (isFileSizeValid) {
            currentId = id;
            currentUpload = http.uploadFileXHR(
                file,
                progress => dispatch(setData({ progress }))
            );

            currentUpload.then((response) => {
                const { requestedSaves } = getState().documentEditPopupData;
                if (requestedSaves.has(id)) {
                    dispatch(save(id, response));
                } else {
                    dispatch(setData({ backEndData: response }));
                    dispatch(setSavingRequested());
                }
            });
        }
    };
}

export function removeRequestedSaveData(id) {
    return {
        type: REMOVE_REQUESTED_SAVE_DATA,
        id,
    };
}
export function storeRequestedSaveData() {
    return (dispatch, getState) => {
        const state = getState().documentEditPopupData;
        const data = { ...state };
        delete data.requestedSaves;

        dispatch({
            type: STORE_REQUESTED_SAVE_DATA,
            data,
        });
    };
}
