import { useData } from "@hooks/useData";
import { ChainDetails, iProfileData, ProfileData, ReportsNameEnum } from "@models/ChainDetails";
import { API__ProfileMainViewModel } from "@apiSchema";
import Http from '../fetch';
import { downloadFile } from "@helpers";
import { ID } from "@models";
import { PermissionLevelEnum, PermissionSection } from "@models/PermissionSection";

export enum ReportDownloadEnum {
    Firefly = 0,
    LTOActivity = 1,
    BrandFingerprints = 2,
    Profile = 3,
    Menu = 4,
    None = -1,
}

export function getReportDownloadEnumValue (reportName: ReportsNameEnum): ReportDownloadEnum {
    switch(reportName) {
        case ReportsNameEnum.Profile: return ReportDownloadEnum.Profile;
        case ReportsNameEnum.Firefly: return ReportDownloadEnum.Firefly;
        case ReportsNameEnum.LTOActivity: return ReportDownloadEnum.LTOActivity;
        case ReportsNameEnum.BrandFingerprints: return ReportDownloadEnum.BrandFingerprints;
        case ReportsNameEnum.Menu: return ReportDownloadEnum.Menu;
        default: return ReportDownloadEnum.None;
    }
}

const getChainDetails = async (chainId: string): Promise<iProfileData> => {
    const data: API__ProfileMainViewModel = await Http.get('chain profile', { chainId });
    const returnData: iProfileData = {
        profileData: {
            permissionLevel: PermissionSection.getPermissionLevel(data.profileData.permissionLevel),
            hasData: data.profileData.hasData,
            data: new ChainDetails(),
        },
        isInTop1000: data.inTop1000,
    };

    if (PermissionSection.getPermissionLevel(data.profileData.permissionLevel) === PermissionLevelEnum.Full) {
        returnData.profileData.data = new ChainDetails({ apiModel: data.profileData.data! });
    }
    return returnData;
};

export default function useChainDetails(chainId: ID) {
    const chainData = useData(new ProfileData(), getChainDetails, chainId);

    const downloadReports = (items: ReportsNameEnum[]) => {
        const link: string = Http.getQuery(
            'download chain reports',
            {
                chainId,
                reportTypes: items
                    .map(getReportDownloadEnumValue)
                    .filter(i => i !== ReportDownloadEnum.None)
            }
        );
        downloadFile(link);
    };

    return {
        ...chainData,
        downloadReports,
    };
}