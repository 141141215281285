import { FC, useCallback, useEffect, useMemo, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Grid } from "../Grid";
import { iGrid, iGridItem } from "../Grid.helpers";
import useLazy from "./useLazy";
import "./LazyGrid.styles.scss";

interface iProps extends iGrid {
    children: iGridItem[];
    initialRows?: number;
    offset?: number;
}

const LazyGrid: FC<iProps> = ({
    children,
    initialRows = 2,
    offset = 0,
    ...restProps
}) => {
    const [itemsPerRow, setItemsPerRow] = useState(0);
    const take = useMemo(() => initialRows * itemsPerRow, [initialRows, itemsPerRow]);
    const totalItemsCount = children.length;
    const { showNextItems, visibleItemsCount, hasNextItems } = useLazy(take, totalItemsCount);
    const [isVisibleSensor, setIsVisibleSensor] = useState(true);

    useEffect(() => {
        if ( isVisibleSensor ) {
            showNextItems(isVisibleSensor);
        }
    }, [isVisibleSensor, showNextItems, itemsPerRow]);

    const slicedChildren = useMemo(() => {
        return children.slice(0, visibleItemsCount);
    }, [children, visibleItemsCount]);

    const handleChangeItemsPerRow = useCallback((count: number) => setItemsPerRow(count), []);
    const sensorOffset = useMemo(() => ({ top: -offset, bottom: -offset }), [offset]);

    return (
        <div className="LazyGrid">
            <Grid
                {...restProps}
                handleChangeItemsPerRow={handleChangeItemsPerRow}
            >
                { slicedChildren }
            </Grid>

            {hasNextItems && (
                <VisibilitySensor
                    onChange={setIsVisibleSensor}
                    partialVisibility
                    offset={sensorOffset}
                >
                    <div className="LazyGrid__sensor" />
                </VisibilitySensor>
            )}
        </div>
    );
};

export default LazyGrid;
