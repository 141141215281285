function generateActionNames(block, actions) {
    const result = {};
    actions.forEach((action) => {
        result[action] = `${block}__${action}`;
    });
    return result;
}

// Page actions
export const SET_SEARCH_STRING = 'SET_SEARCH_STRING';
export const CLEAR_SEARCH_RESULTS_STRING = 'CLEAR_SEARCH_RESULTS_STRING';
export const SET_AVAILABLE_SEARCH_TABS = 'SET_AVAILABLE_SEARCH_TABS';
export const TOGGLE_FILTER_POPUP_OPENED = 'TOGGLE_FILTER_POPUP_OPENED';

export const CLEAR_SEARCH_RESULTS_DATA = 'CLEAR_SEARCH_RESULTS_DATA';
// Food profile actions
// export const GRAPH_HAS_ERRORED = 'GRAPH_HAS_ERRORED';

export const SEARCH_POPUP = {
    CHANGE_QUERY: 'SEARCH_POPUP__CHANGE_QUERY',
    UPDATE_DATA: 'SEARCH_POPUP__UPDATE_DATA',
    UPDATE_CHAINS_WHO_OFFERS_COUNT: 'SEARCH_POPUP__UPDATE_CHAINS_WHO_OFFERS_COUNT',
    OPEN_CATEGORY: 'SEARCH_POPUP__OPEN_CATEGORY',
    CLOSE_CATEGORY: 'SEARCH_POPUP__CLOSE_CATEGORY',
    SET_LOADING: 'SEARCH_POPUP__SET_LOADING',
    UNSET_LOADING: 'SEARCH_POPUP__UNSET_LOADING',
    SHOW_DOCUMENTS_ANYWAY: 'SHOW_DOCUMENTS_ANYWAY',
    DONT_SHOW_DOCUMENTS_ANYWAY: 'DONT_SHOW_DOCUMENTS_ANYWAY',
    SEARCH_POPUP_SHOW_SPINNER: 'SEARCH_POPUP_SHOW_SPINNER',
    SEARCH_POPUP_HIDE_SPINNER: 'SEARCH_POPUP_HIDE_SPINNER',
};

// Menu items actions

export const HEADER_USER_ACTIONS_MENU_CLOSE = 'HEADER_USER_ACTIONS_MENU_CLOSE';
export const HEADER_USER_ACTIONS_MENU_OPEN = 'HEADER_USER_ACTIONS_MENU_OPEN';
export const HEADER_USER_ACTIONS_MENU_TOGGLE = 'HEADER_USER_ACTIONS_MENU_TOGGLE';
export const HEADER_DOCUMENTS_MENU_CLOSE = 'HEADER_DOCUMENTS_MENU_CLOSE';
export const HEADER_DOCUMENTS_MENU_OPEN = 'HEADER_DOCUMENTS_MENU_OPEN';
export const HEADER_DOCUMENTS_MENU_TOGGLE = 'HEADER_DOCUMENTS_MENU_TOGGLE';
export const HEADER_MENU_CLOSE = 'HEADER_MENU_CLOSE';
export const HEADER_MENU_OPEN = 'HEADER_MENU_OPEN';
export const HEADER_MENU_TOGGLE = 'HEADER_MENU_TOGGLE';
export const SEARCH_POPUP_CLOSE = 'SEARCH_POPUP_CLOSE';
export const SEARCH_POPUP_OPEN = 'SEARCH_POPUP_OPEN';
export const SEARCH_POPUP_TOGGLE = 'SEARCH_POPUP_TOGGLE';
export const SEARCH_POPUP_CLEAR_DATA = 'SEARCH_POPUP_CLEAR_DATA';
export const HEADER__OPEN_SNAP_ASSISTANT_POPUP = 'HEADER__OPEN_SNAP_ASSISTANT_POPUP';
export const HEADER__CLOSE_SNAP_ASSISTANT_POPUP = 'HEADER__CLOSE_SNAP_ASSISTANT_POPUP';
export const HEADER__OPEN_FOOD_SCAPE_POPUP = 'HEADER__OPEN_FOOD_SCAPE_POPUP';
export const HEADER__CLOSE_FOOD_SCAPE_POPUP = 'HEADER__CLOSE_FOOD_SCAPE_POPUP';


export const WHATS_NEW_FETCH_DATA_LOADED = 'WHATS_NEW_FETCH_DATA_LOADED';
export const WHATS_NEW_FETCH_DATA_SUCCESS = 'WHATS_NEW_FETCH_DATA_SUCCESS';
export const WHATS_NEW_VISIBILITY_TOGGLE = 'WHATS_NEW_VISIBILITY_TOGGLE';
export const WHATS_NEW_HIDE = 'WHATS_NEW_HIDE';
export const WHATS_NEW_SHOW = 'WHATS_NEW_SHOW';

// Reports actions

export const REPORT_FETCH_DATA_STORE = 'REPORT_FETCH_DATA_STORE';
export const REPORT_FETCH_DATA_STARTED = 'REPORT_FETCH_DATA_STARTED';
export const REPORT_FETCH_DATA_LOADED = 'REPORT_FETCH_DATA_LOADED';
export const REPORT_FETCH_DATA_FULL_LOADED = 'REPORT_FETCH_DATA_FULL_LOADED';

// Trendspottings actions

export const TRENDSPOTTINGS_FETCH_DATA_STORE = 'TRENDSPOTTINGS_FETCH_DATA_STORE';
export const TRENDSPOTTINGS_FETCH_DATA_STARTED = 'TRENDSPOTTINGS_FETCH_DATA_STARTED';
export const TRENDSPOTTINGS_FETCH_DATA_LOADED = 'TRENDSPOTTINGS_FETCH_DATA_LOADED';
export const TRENDSPOTTINGS_FETCH_DATA_FULL_LOADED = 'TRENDSPOTTINGS_FETCH_DATA_FULL_LOADED';
// Page snapShots
export const SNAP_SHOTS_DATA_ACQUISITION = 'SNAP_SHOTS_DATA_ACQUISITION';
export const SNAP_SHOTS_IS_LOADING = 'SNAP_SHOTS_IS_LOADING';
export const SNAP_SHOTS_IS_LOADED = 'SNAP_SHOTS_IS_LOADED';
export const SNAP_SHOTS_ELEMENTS_COUNT_TO_SKIP = 'SNAP_SHOTS_ELEMENTS_COUNT_TO_SKIP';

// Page places

export const PLACES_FETCH_DATA_STORE = 'PLACES_FETCH_DATA_STORE';
export const PLACES_FETCH_DATA_STARTED = 'PLACES_FETCH_DATA_STARTED';
export const PLACES_FETCH_DATA_LOADED = 'PLACES_FETCH_DATA_LOADED';
export const PLACES_FETCH_DATA_STORE_REPLACE = 'PLACES_FETCH_DATA_STORE_REPLACE';
export const SET_PLACES_FILTER_VALUES = 'SET_PLACES_FILTER_VALUES';
export const SET_WHO_OFFERS_PLACES_FILTER_VALUES = 'SET_WHO_OFFERS_PLACES_FILTER_VALUES';
export const PLACES_FETCH_DATA_LIST_STORE_ADD = 'PLACES_FETCH_DATA_LIST_STORE_ADD';
export const PLACES_FETCH_DATA_TABLE_STORE_ADD = 'PLACES_FETCH_DATA_TABLE_STORE_ADD';
export const PLACES_APPLY_TABLE_ORDER = 'PLACES_APPLY_TABLE_ORDER';
export const PLACES_APPLY_WHO_OFFERS_TABLE_ORDER = 'PLACES_APPLY_WHO_OFFERS_TABLE_ORDER';
export const PLACES_FETCH_DATA_STORE_REPLACE_TABLE = 'PLACES_FETCH_DATA_STORE_REPLACE_TABLE';
export const PLACES_CLEAR_DATA = 'PLACES_CLEAR_DATA';
export const PLACES_SAVE_SEARCH_STRING = 'PLACES_SAVE_SEARCH_STRING';
export const PLACES_SELECT_ACTIVE_TAB = 'PLACES_SELECT_ACTIVE_TAB';
export const PLACES_SET_WHO_OFFERS_EXPANDED_ROW_DATA = 'PLACES_SET_WHO_OFFERS_EXPANDED_ROW_DATA';
export const PLACES_SET_WHO_OFFERS_EXPANDED_ROW_ID = 'PLACES_SET_WHO_OFFERS_EXPANDED_ROW_ID';

// Top chains page

export const TOP_CHAINS_FETCH_DATA_STORE = 'TOP_CHAINS_FETCH_DATA_STORE';
export const TOP_CHAINS_FETCH_DATA_STARTED = 'TOP_CHAINS_FETCH_DATA_STARTED';
export const TOP_CHAINS_FETCH_DATA_LOADED = 'TOP_CHAINS_FETCH_DATA_LOADED';
export const TOP_CHAINS_FETCH_DATA_STORE_REPLACE = 'TOP_CHAINS_FETCH_DATA_STORE_REPLACE';
export const TOP_CHAINS_SET_FILTER_VALUES = 'TOP_CHAINS_SET_FILTER_VALUES';
export const TOP_CHAINS_FETCH_DATA_LIST_STORE_ADD = 'TOP_CHAINS_FETCH_DATA_LIST_STORE_ADD';
export const TOP_CHAINS_FETCH_DATA_TABLE_STORE_ADD = 'TOP_CHAINS_FETCH_DATA_TABLE_STORE_ADD';
export const TOP_CHAINS_APPLY_TABLE_ORDER = 'TOP_CHAINS_APPLY_TABLE_ORDER';
export const TOP_CHAINS_FETCH_DATA_STORE_REPLACE_TABLE = 'TOP_CHAINS_FETCH_DATA_STORE_REPLACE_TABLE';

// Page documents

export const DOCUMENTS_FETCH_DATA_STORE = 'DOCUMENTS_FETCH_DATA_STORE';
export const DOCUMENTS_FETCH_DATA_STARTED = 'DOCUMENTS_FETCH_DATA_STARTED';
export const DOCUMENTS_FETCH_DATA_LOADED = 'DOCUMENTS_FETCH_DATA_LOADED';
export const DOCUMENTS_FETCH_MORE_DATA_STARTED = 'DOCUMENTS_FETCH_MORE_DATA_STARTED';
export const DOCUMENTS_FETCH_MORE_DATA_LOADED = 'DOCUMENTS_FETCH_MORE_DATA_LOADED';
export const DOCUMENTS_FETCH_DATA_FULL_LOADED = 'DOCUMENTS_FETCH_DATA_FULL_LOADED';
export const DOCUMENTS_CLEAR_DATA_STORE = 'DOCUMENTS_CLEAR_DATA_STORE';
export const DOCUMENTS_SHARE_ITEM_INIT = 'DOCUMENTS_SHARE_ITEM';
export const DOCUMENTS_SHARE_ITEM_CANCEL = 'DOCUMENTS_SHARE_ITEM_CANCEL';
export const DOCUMENTS_SHARE_EMAILS_FETCH_DATA_STORE = 'DOCUMENTS_SHARE_EMAILS_FETCH_DATA_STORE';
export const DOCUMENTS_NOTIFICATION_SHOW = 'DOCUMENTS_NOTIFICATION_SHOW';
export const DOCUMENTS_NOTIFICATION_HIDE = 'DOCUMENTS_NOTIFICATION_HIDE';
export const DOCUMENTS_CHOP_LINK_STORE = 'DOCUMENTS_CHOP_LINK_STORE';
export const DOCUMENTS_CHOP_LINK_LOAD_STARTED = 'DOCUMENTS_CHOP_LINK_LOAD_STARTED';
export const DOCUMENTS_CHOP_LINK_LOAD_FINISHED = 'DOCUMENTS_CHOP_LINK_LOAD_FINISHED';
export const DOCUMENTS_BY_PUBLICATION_FAMILY_FETCH_DATA_LOADED = 'DOCUMENTS_BY_PUBLICATION_FAMILY_FETCH_DATA_LOADED';
export const DOCUMENTS_BY_PUBLICATION_FAMILY_FETCH_DATA_STARTED = 'DOCUMENTS_BY_PUBLICATION_FAMILY_FETCH_DATA_STARTED';
export const DOCUMENTS_BY_PUBLICATION_FAMILY_FETCH_DATA_STORE = 'DOCUMENTS_BY_PUBLICATION_FAMILY_FETCH_DATA_STORE';
export const DOCUMENTS_SAVE_SEARCH_STRING = 'DOCUMENTS_SAVE_SEARCH_STRING';
export const DOCUMENTS_PURCHASE_START = 'DOCUMENTS_PURCHASE_START';
export const DOCUMENTS_PURCHASE_END = 'DOCUMENTS_PURCHASE_END';
export const DOCUMENTS_PURCHASE_MADE = 'DOCUMENTS_PURCHASE_MADE';
export const DOCUMENTS_CLEAR_PURCHASE_MADE = 'DOCUMENTS_CLEAR_PURCHASE_MADE';
export const DOCUMENTS_LINK_LOADING = 'DOCUMENTS_LINK_LOADING';
export const DOCUMENTS_LINK_LOADING_FINISHED = 'DOCUMENTS_LINK_LOADING_FINISHED';
export const DOCUMENTS_INCREASE_COUNT_TO_SHOW = 'DOCUMENTS_INCREASE_COUNT_TO_SHOW';
export const DOCUMENTS_RESET_COUNT_TO_SHOW = 'DOCUMENTS_RESET_COUNT_TO_SHOW';
export const DOCUMENTS_SET_EXPANDED_REPORT_TILE = 'DOCUMENTS_SET_EXPANDED_REPORT_TILE';
export const DOCUMENTS_SET_DATE_FILTER = 'DOCUMENTS_SET_DATE_FILTER';

// Page viewDocumnet

export const VIEW_DOCUMENT_FETCH_DATA_STORE = 'VIEW_DOCUMENT_FETCH_DATA_STORE';
export const VIEW_DOCUMENT_FETCH_DATA_STARTED = 'VIEW_DOCUMENT_FETCH_DATA_STARTED';
export const VIEW_DOCUMENT_FETCH_DATA_LOADED = 'VIEW_DOCUMENT_FETCH_DATA_LOADED';
export const VIEW_DOCUMENT_CLEAR_DATA_STORE = 'VIEW_DOCUMENT_CLEAR_DATA_STORE';

export const DOCUMENTS_LIBRARY_POPUPS = {
    STORE_DATA: 'DOCUMENTS_LIBRARY_POPUPS__STORE_DATA',
    SET_PAGE_TO_RELOAD: 'DOCUMENTS_LIBRARY_POPUPS__SET_PAGE_TO_RELOAD',
    SET_TYPES: 'DOCUMENTS_LIBRARY_POPUPS__SET_TYPES',
    SET_ACTIVE_COMPANY_ID: 'DOCUMENTS_LIBRARY_POPUPS__SET_ACTIVE_COMPANY_ID',
};

export const DOCUMENT_DELETE_POPUP = {
    OPEN: 'DOCUMENT_DELETE_POPUP__OPEN',
    CLOSE: 'DOCUMENT_DELETE_POPUP__CLOSE',
    SET_DATA: 'DOCUMENT_DELETE_POPUP__SET_DATA',
    RESET_DATA: 'DOCUMENT_DELETE_POPUP__RESET_DATA',
};

export const DOCUMENTS_ADD_POPUP = {
    SET_SAVING_REQUESTED: 'DOCUMENTS_ADD_POPUP__SET_SAVING_REQUESTED',
    UNSET_SAVING_REQUESTED: 'DOCUMENTS_ADD_POPUP__UNSET_SAVING_REQUESTED',
    REMOVE_LAST_SET: 'DOCUMENTS_ADD_POPUP__REMOVE_LAST_SET',
    OPEN: 'DOCUMENTS_ADD_POPUP__OPEN',
    CLOSE: 'DOCUMENTS_ADD_POPUP__CLOSE',
    SET_VIEW: 'DOCUMENTS_ADD_POPUP__SET_VIEW',
    SET_ACTIVE_TYPE_ID: 'DOCUMENTS_ADD_POPUP__SET_ACTIVE_TYPE_ID',
    CANCEL_FILE_UPLOAD: 'DOCUMENTS_ADD_POPUP__CANCEL_FILE_UPLOAD',
    UPDATE_DOCUMENT_DATA: 'DOCUMENTS_ADD_POPUP__UPDATE_DOCUMENT_DATA',
    SET_ACTIVE_PROJECT_ID: 'DOCUMENTS_ADD_POPUP__SET_ACTIVE_PROJECT_ID',
    SET_UPLOADING_STARTED: 'DOCUMENTS_ADD_POPUP__SET_UPLOADING_STARTED',
    STORE_UPLOADING_FILES: 'DOCUMENTS_ADD_POPUP__STORE_UPLOADING_FILES',
    SHOW_VALIDATION_ERRORS: 'DOCUMENTS_ADD_POPUP__SHOW_VALIDATION_ERRORS',
    HIDE_VALIDATION_ERRORS: 'DOCUMENTS_ADD_POPUP__HIDE_VALIDATION_ERRORS',
    SET_UPLOADING_FINISHED: 'DOCUMENTS_ADD_POPUP__SET_UPLOADING_FINISHED',
    CLEAR_FILES_UPLOAD: 'DOCUMENTS_ADD_POPUP__CLEAR_FILES_UPLOAD',
    SET_ACTIVE_COMPANY_ID: 'DOCUMENTS_ADD_POPUP__SET_ACTIVE_COMPANY_ID',
    INC_CURRENT_DOCUMENT_INDEX: 'DOCUMENTS_ADD_POPUP__INC_CURRENT_DOCUMENT_INDEX',
    SET_CURRENT_DOCUMENT_INDEX: 'DOCUMENTS_ADD_POPUP__SET_CURRENT_DOCUMENT_INDEX',
};

export const DOCUMENT_EDIT_POPUP = {
    OPEN: 'DOCUMENT_EDIT_POPUP__OPEN',
    CLOSE: 'DOCUMENT_EDIT_POPUP__CLOSE',
    SET_DATA: 'DOCUMENT_EDIT_POPUP__SET_DATA',
    SHOW_VALIDATION_ERRORS: 'DOCUMENT_EDIT_POPUP__SHOW_VALIDATION_ERRORS',
    HIDE_VALIDATION_ERRORS: 'DOCUMENT_EDIT_POPUP__HIDE_VALIDATION_ERRORS',
    UNSET_SAVING_REQUESTED: 'DOCUMENT_EDIT_POPUP__UNSET_SAVING_REQUESTED',
    SET_SAVING_REQUESTED: 'DOCUMENT_EDIT_POPUP__SET_SAVING_REQUESTED',
    STORE_UPLOADING_FILE: 'DOCUMENT_EDIT_POPUP__STORE_UPLOADING_FILE',
    REMOVE_REQUESTED_SAVE_DATA: 'DOCUMENT_EDIT_POPUP__REMOVE_REQUESTED_SAVE_DATA',
    STORE_REQUESTED_SAVE_DATA: 'DOCUMENT_EDIT_POPUP__STORE_REQUESTED_SAVE_DATA',
};

export const PROJECT_EDIT_POPUP = {
    OPEN: 'PROJECT_EDIT_POPUP__OPEN',
    CLOSE: 'PROJECT_EDIT_POPUP__CLOSE',
    SET_DATA: 'PROJECT_EDIT_POPUP__SET_DATA',
    SET_ACTIVE_COMPANY_ID: 'PROJECT_EDIT_POPUP__SET_ACTIVE_COMPANY_ID',
    SHOW_VALIDATION_ERRORS: 'PROJECT_EDIT_POPUP__SHOW_VALIDATION_ERRORS',
    HIDE_VALIDATION_ERRORS: 'PROJECT_EDIT_POPUP__HIDE_VALIDATION_ERRORS',
};

export const PROJECT_CREATE_POPUP = {
    OPEN: 'PROJECT_CREATE_POPUP__OPEN',
    CLOSE: 'PROJECT_CREATE_POPUP__CLOSE',
    SET_VIEW: 'PROJECT_CREATE_POPUP__SET_VIEW',
    SET_DATA: 'PROJECT_CREATE_POPUP__SET_DATA',
    SHOW_VALIDATION_ERRORS: 'PROJECT_CREATE_POPUP__SHOW_VALIDATION_ERRORS',
    HIDE_VALIDATION_ERRORS: 'PROJECT_CREATE_POPUP__HIDE_VALIDATION_ERRORS',
};

export const PROJECT_DELETE_POPUP = {
    OPEN: 'PROJECT_DELETE_POPUP__OPEN',
    CLOSE: 'PROJECT_DELETE_POPUP__CLOSE',
    SET_DATA: 'PROJECT_DELETE_POPUP__SET_DATA',
    RESET_DATA: 'PROJECT_DELETE_POPUP__RESET_DATA',
};

// page "My Documents"
export const MY_DOCUMENTS__STORE_DATA = 'MY_DOCUMENTS__STORE_DATA';
export const MY_DOCUMENTS__CLEAR_DATA = 'MY_DOCUMENTS__CLEAR_DATA';

// page "Project"
export const PROJECT_DATA_STORE = 'PROJECT_DATA_STORE';
export const PROJECT_DATA_CLEAR = 'PROJECT_DATA_CLEAR';
export const PROJECT_DATA_EDIT = 'PROJECT_DATA_EDIT';
export const PROJECT_REMOVE_DOCUMENT = 'PROJECT_REMOVE_DOCUMENT';
export const PROJECT_DOCUMENT_UPDATE = 'PROJECT_DOCUMENT_UPDATE';

// page "Document Type"
export const DOCUMENT_TYPE_DATA_STORE = 'DOCUMENT_TYPE_DATA_STORE';
export const DOCUMENT_TYPE_DATA_CLEAR = 'DOCUMENT_TYPE_DATA_CLEAR';
export const DOCUMENT_TYPE_REMOVE_DOCUMENT = 'DOCUMENT_TYPE_REMOVE_DOCUMENT';
export const DOCUMENT_TYPE_DOCUMENT_UPDATE = 'DOCUMENT_TYPE_DOCUMENT_UPDATE';

// temp actions
export const PAGE_NOTIFICATION_OPEN = 'PAGE_NOTIFICATION_OPEN';
export const PAGE_NOTIFICATION_CLOSE = 'PAGE_NOTIFICATION_CLOSE';
// Chain details page

export const CHAIN_DETAILS_FETCH_DATA_STORE = 'CHAIN_DETAILS_FETCH_DATA_STORE';
export const CHAIN_DETAILS_MENU_GAPS_STORE = 'CHAIN_DETAILS_MENU_GAPS_STORE';
export const CHAIN_DETAILS_MENU_GAPS_LOADED = 'CHAIN_DETAILS_MENU_GAPS_LOADED';
export const CHAIN_DETAILS_FETCH_NEWS_STORE = 'CHAIN_DETAILS_FETCH_NEWS_STORE';
export const CHAIN_DETAILS_FETCH_DATA_STARTED = 'CHAIN_DETAILS_FETCH_DATA_STARTED';
export const CHAIN_DETAILS_FETCH_DATA_LOADED = 'CHAIN_DETAILS_FETCH_DATA_LOADED';
export const CHAIN_DETAILS_SET_FILTER_QUERY = 'CHAIN_DETAILS_SET_FILTER_QUERY';
export const CHAIN_DETAILS_SAVE_NOTE = 'CHAIN_DETAILS_SAVE_NOTE';
export const CHAIN_DETAILS_EDIT_NOTE = 'CHAIN_DETAILS_EDIT_NOTE';
export const CHAIN_DETAILS_REMOVE_NOTE = 'CHAIN_DETAILS_REMOVE_NOTE';
export const CHAIN_DETAILS_ADD_LOCAL_INSIGHTS = 'CHAIN_DETAILS_ADD_LOCAL_INSIGHTS';
export const CHAIN_DETAILS_RESET_STORE = 'CHAIN_DETAILS_RESET_STORE';
export const CHAIN_DETAILS_SAVE_MENU_COMPETITORS = 'CHAIN_DETAILS_SAVE_MENU_COMPETITORS';
export const CHAIN_DETAILS_CLEAR_MENU_COMPETITORS = 'CHAIN_DETAILS_CLEAR_MENU_COMPETITORS';
export const CHAIN_DETAILS_GAP_REPORT_LOADING = 'CHAIN_DETAILS_GAP_REPORT_LOADING';
export const CHAIN_DETAILS_GAP_REPORT_STORE = 'CHAIN_DETAILS_GAP_REPORT_STORE';
export const CHAIN_DETAILS_CLEAR_GAP_REPORT = 'CHAIN_DETAILS_CLEAR_GAP_REPORT';
export const CHAIN_DETAILS_SELECTED_CHAINS_LOADING = 'CHAIN_DETAILS_SELECTED_CHAINS_LOADING';
export const CHAIN_DETAILS_SELECTED_CHAINS_LOADED = 'CHAIN_DETAILS_SELECTED_CHAINS_LOADED';
export const CHAIN_DETAILS_CHAINS_LIST_BY_NAME_STORE = 'CHAIN_DETAILS_CHAINS_LIST_BY_NAME_STORE';
export const CHAIN_DETAILS_CLEAR_CHAINS_LIST_BY_NAME = 'CHAIN_DETAILS_CLEAR_CHAINS_LIST_BY_NAME';
export const CHAIN_DETAILS_SELECTED_CHAINS_STORE = 'CHAIN_DETAILS_SELECTED_CHAINS_STORE';
export const CHAIN_DETAILS_CLEAR_SELECTED_CHAINS = 'CHAIN_DETAILS_CLEAR_SELECTED_CHAINS';
export const CHAIN_DETAILS_MTD_PRODUCT_PERMISSION_STORE = 'CHAIN_DETAILS_MTD_PRODUCT_PERMISSION_STORE';
export const CHAIN_DETAILS_ADD_REPORTS_PERMISSIONS = 'CHAIN_DETAILS_ADD_REPORTS_PERMISSIONS';
export const CHAIN_DETAILS_SINGLE_MENU_ITEM_UPDATE = 'CHAIN_DETAILS_SINGLE_MENU_ITEM_UPDATE';
export const CHAIN_DETAILS_POPUP_OPEN = 'CHAIN_DETAILS_POPUP_OPEN';
export const CHAIN_DETAILS_POPUP_CLOSE = 'CHAIN_DETAILS_POPUP_CLOSE';

// Page macDetails

export const MAC_DETAILS_FETCH_DATA_STORE = 'MAC_DETAILS_FETCH_DATA_STORE';
export const MAC_DETAILS_FETCH_DATA_STARTED = 'MAC_DETAILS_FETCH_DATA_STARTED';
export const MAC_DETAILS_FETCH_DATA_LOADED = 'MAC_DETAILS_FETCH_DATA_LOADED';
export const MAC_DETAILS_POST_BACKGROUND_STARTED = 'MAC_DETAILS_POST_BACKGROUND_STARTED';
export const MAC_DETAILS_POST_BACKGROUND_UPLOADED = 'MAC_DETAILS_POST_BACKGROUND_UPLOADED';
export const MAC_DETAILS_BACKGROUND_STORE = 'MAC_DETAILS_BACKGROUND_STORE';
export const MAC_DETAILS_POST_DEFAULT_BACKGROUND_STARTED = 'MAC_DETAILS_POST_DEFAULT_BACKGROUND_STARTED';
export const MAC_DETAILS_POST_DEFAULT_BACKGROUND_LOADED = 'MAC_DETAILS_POST_DEFAULT_BACKGROUND_LOADED';
export const MAC_DETAILS_DEFAULT_BACKGROUND_STORE = 'MAC_DETAILS_DEFAULT_BACKGROUND_STORE';
export const MAC_MENUS_FETCH_DATA_STARTED = 'MAC_MENUS_FETCH_DATA_STARTED';
export const MAC_MENUS_FETCH_DATA_LOADED = 'MAC_MENUS_FETCH_DATA_LOADED';
export const MAC_MENUS_FETCH_DATA_STORE = 'MAC_MENUS_FETCH_DATA_STORE';
export const MAC_MENUS_RESET_DATA = 'MAC_MENUS_RESET_DATA';
export const MAC_DOWNLOAD_LINK_STARTED = 'MAC_DOWNLOAD_LINK_STARTED';
export const MAC_DOWNLOAD_LINK_LOADED = 'MAC_DOWNLOAD_LINK_LOADED';
export const MAC_DOWNLOAD_LINK_STORE = 'MAC_DOWNLOAD_LINK_STORE';
export const MAC_DETAILS_BACKGROUND_SET_FILE_SIZE_STATUS = 'MAC_DETAILS_BACKGROUND_SET_FILE_SIZE_STATUS';

// Packages page

export const PACKAGES_SAVE_CURRENT = 'PACKAGES_SAVE_CURRENT';
export const PACKAGES_ADD_ITEM_TO_PACKAGE = 'PACKAGES_ADD_ITEM_TO_PACKAGE';
export const PACKAGES_REMOVE_ITEM_FROM_PACKAGE = 'PACKAGES_REMOVE_ITEM_FROM_PACKAGE';
export const PACKAGES_SAVE_PACKAGE_NAME = 'PACKAGES_SAVE_PACKAGE_NAME';
export const PACKAGES_STORE_ALL_PACKAGES = 'PACKAGES_STORE_ALL_PACKAGES';
export const PACKAGES_CHANGE_ACTIVE_PACKAGE = 'PACKAGES_CHANGE_ACTIVE_PACKAGE';
export const PACKAGES_REMOVE_PACKAGE = 'PACKAGES_REMOVE_PACKAGE';
export const PACKAGES_ADD_NEW_ACTIVE_PACKAGE = 'PACKAGES_ADD_NEW_ACTIVE_PACKAGE';
export const PACKAGES_REMOVE_PACKAGE_FROM_LIST = 'PACKAGES_REMOVE_PACKAGE_FROM_LIST';
export const PACKAGES_ADD_ITEM_LIST_TO_PACKAGE = 'PACKAGES_ADD_ITEM_LIST_TO_PACKAGE';
export const PACKAGES_SAVE_EMAILS = 'PACKAGES_SAVE_EMAILS';
export const PACKAGES_CLOSE = 'PACKAGES_CLOSE';
export const PACKAGES_OPEN = 'PACKAGES_OPEN';
export const PACKAGES_SET_DELETING = 'PACKAGES_SET_DELETING';
export const PACKAGES_RESET_DELETING = 'PACKAGES_RESET_DELETING';

// Dropdown

export const SET_EXPANDED_DROPDOWN = 'SET_EXPANDED_DROPDOWN';
export const RESET_EXPANDED_DROPDOWN = 'RESET_EXPANDED_DROPDOWN';

// Groups

export const GROUPS_FETCH_DATA_STARTED = 'GROUPS_FETCH_DATA_STARTED';
export const GROUPS_FETCH_DATA_LOADED = 'GROUPS_FETCH_DATA_LOADED';
export const GROUPS_FETCH_DATA_STORE = 'GROUPS_FETCH_DATA_STORE';
export const GROUPS_UPDATE_GROUPS_DATA = 'GROUPS_UPDATE_GROUPS_DATA';
export const GROUPS_OPEN = 'GROUPS_OPEN';
export const GROUPS_CLOSE = 'GROUPS_CLOSE';

// Notifications

export const NOTIFICATION__ADD = 'NOTIFICATION__ADD';
export const NOTIFICATION__REMOVE = 'NOTIFICATION__REMOVE';

// reconfirmation popup
export const RECONFIRMATION_POPUP = generateActionNames('RECONFIRMATION_POPUP', [
    'SET_DATA',
]);

// report popup
export const REPORT_POPUP = generateActionNames('RECONFIRMATION_POPUP', [
    'OPEN',
    'CLOSE',
    'SET_DATA',
]);

// logout popup
export const HIDE_LOGOUT_POPUP = 'HIDE_LOGOUT_POPUP';
export const SHOW_LOGOUT_POPUP = 'SHOW_LOGOUT_POPUP';
export const SHOW_HELP_POPUP = 'SHOW_HELP_POPUP';
export const HIDE_HELP_POPUP = 'HIDE_HELP_POPUP';
export const HIDE_SNAP_ASSISTANT_REMINDER = 'HIDE_SNAP_ASSISTANT_REMINDER';
export const SHOW_SNAP_ASSISTANT_REMINDER = 'SHOW_SNAP_ASSISTANT_REMINDER';

// custom research popup
export const OPEN_CUSTOM_RESEARCH_POPUP = 'OPEN_CUSTOM_RESEARCH_POPUP';
export const CLOSE_CUSTOM_RESEARCH_POPUP = 'CLOSE_CUSTOM_RESEARCH_POPUP';
export const OPEN_IMPERSONATE_POPUP = 'OPEN_IMPERSONATE_POPUP';
export const CLOSE_IMPERSONATE_POPUP = 'CLOSE_IMPERSONATE_POPUP';
export const OPEN_AGENCY_MODE_POPUP = 'OPEN_AGENCY_MODE_POPUP';
export const CLOSE_AGENCY_MODE_POPUP = 'CLOSE_AGENCY_MODE_POPUP';
