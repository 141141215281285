import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { requestTrialByFeature } from "@actions/page";
import useQueryParam from "@hooks/useQueryParam";
import { useFoodProfileData } from "@apiHooks/useFoodProfileData";
import useFoodLto from "@apiHooks/useFoodLto";
import FoodProfileRecommendedChart, {
    iItem
} from "@pages/FoodProfile/FoodProfileRecommendedCharts/FoodProfileRecommendedCharts";
import FoodProfilePairedItems from "@pages/FoodProfile/FoodProfilePairedItems/FoodProfilePairedItems";
import FoodProfileLocalIndices, {
    iLocalIndicesData
} from "@pages/FoodProfile/FoodProfileLocalIndices/FoodProfileLocalIndices";
import FoodProfileFoundIn, { iDocumentsData } from "@pages/FoodProfile/FoodProfileFoundIn/FoodProfileFoundIn";
import FoodProfileVersatility, {
    iVersatilityData
} from "@pages/FoodProfile/FoodProfileVersatility/FoodProfileVersatility";
import { iPipelineMenu } from "@pages/FoodProfile/FoodProfileSummary/FoodProfilePipelineMenu/FoodProfilePipelineMenu";
import { getNavigationItems } from "@pages/FoodProfile/FoodProfile.helpers";
import { isAvailable, PermissionLevelEnum } from "@models/PermissionSection";
import FoodProfileNavLink from "@pages/FoodProfile/FoodProfileNavLink/FoodProfileNavLink";
import Scrollbar from "react-custom-scrollbars";
import cn from "classnames";
import Icon from "@components/SpriteIcon/SpriteIcon";
import FoodProfileSummary from "@pages/FoodProfile/FoodProfileSummary/FoodProfileSummary";
import FoodProfileNoData from "@pages/FoodProfile/FoodProfileNoData/FoodProfileNoData";
import MenuItemsSection from "@pages/FoodProfile/FoodProfileMenuItems/FoodProfileMenuItems";
import HaikuPopup from "@pages/FoodProfile/HaikuPopup/HaikuPopup";
import SideNavigation from "@components/SideNavigation/SideNavigation";
import Scroll from "react-scroll";
import {
    AnalyticsAction,
    AnalyticsAttribute,
    AnalyticsData,
    FoodProfileDownloadLocation,
    getAnalyticsComputedData
} from "@models/AnalyticsAttributes";
import { useFoodProfileWidgetsData } from "@apiHooks/useFoodProfileWidgetsData";
import http from "@core/fetch";
import { addNotification } from "@components/GlobalNotification/globalNotification.actions";
import { iAffinityNormsGroup } from "@models/FoodProfileAffinity";
import { sanitizeUrl } from "@helpers";
import FoodProfileHeatScore from "@pages/FoodProfile/FoodProfileHeatScore/FoodProfileHeatScore";
import { AppContext } from "../../AppContext";
import { useFoodProfilePairedFlavorsData } from "@apiHooks/useFoodProfilePairedFlavorsData";
import { allItems, NavigationItems } from '@models/PlatformNavigation';

const { Element } = Scroll;

const FoodProfileContent: FC<{
    onNoData: () => void;
}> = (
    {
        onNoData,
    }
) => {
    const dispatch = useDispatch();
    const showNotification = (notification: any) => dispatch(addNotification(notification));
    const requestTrial: (f: string, hideNotification?: boolean) => any = (featureName, hideNotification) => {
        if (!hideNotification) {
            showNotification({ text: 'Request sent'});
        }
        return dispatch(requestTrialByFeature(featureName));
    };

    const {
        globalVariables: {
            reportProUrl,
        },
    } = useContext(AppContext);

    const searchQuery = useQueryParam("q");
    const {
        isLoading,
        data,
    } = useFoodProfileData(searchQuery);

    const {
        isLoading: isWidgetsDataLoading,
        data: widgetsData,
    } = useFoodProfileWidgetsData(searchQuery);

    const {
        isLoading: isPairedFlavorsLoading,
        data: pairedFlavorsData,
    } = useFoodProfilePairedFlavorsData(searchQuery);

    const {
        summary,
        affinity,
        versatility,
        heatScoreData,
    } = data;

    const {
        recommendedCharts,
        localPopularity,
        documentsInfo,
    } = widgetsData;

    const {
        isLoading: isMenuItemsLoading,
        data: menuItemsData,
    } = useFoodLto(searchQuery);

    const {
        data: {
            flavorCategoryName,
            macWords,
            downloadReportUrl: downloadUrl,
        },
    } = summary;

    const [isHaikuPopupOpened, setIsHaikuPopupOpened] = useState(false);
    const [isLocalTrialRequestSent, setIsLocalTrialRequestSent] = useState(false);
    const [isProfileDownloading, setIsProfileDownloading] = useState(false);

    const recommendedChartData: iItem[] = useMemo(() => {
        return recommendedCharts.data.map(i => ({
            title: i.title,
            subtitle: i.typeLabel,
            link: i.link,
        }));
    }, [recommendedCharts]);

    const localIndicesData: iLocalIndicesData = useMemo(() => ({
        link: localPopularity.data.link,
        states: localPopularity.data.data.map(i => ({
            name: i.title,
            value: i.value,
        }))
    }), [localPopularity]);

    const foundInData: iDocumentsData = useMemo(() => {
        return {
            reports: documentsInfo.data.reports,
            total: documentsInfo.data.totalCount,
            reportUrl: sanitizeUrl(`${reportProUrl}/details/`),
            moreButtonUrl: `${reportProUrl}?q=${encodeURIComponent(searchQuery)}`,
        };
    }, [documentsInfo]);

    const versatilityData: iVersatilityData = useMemo(() => ({
        foodVersatility: versatility.data.foodVersatility,
        menuVersatility: versatility.data.menuVersatility,
    }), [versatility]);

    const pipelineMenuData: iPipelineMenu = useMemo(() => ({
        flavorCategoryName,
        categories: macWords.data.map(category => ({
            id: category.id,
            title: category.title,
            isActive: category.isActive,
            words: category.words.map(word => ({
                id: word.id,
                name: word.name,
            })),
        })),
    }), [macWords, flavorCategoryName]);

    const consumerAffinityCategories: iAffinityNormsGroup[] = useMemo(() => affinity.data.normsGroups, [affinity]);

    const navigationItems = useMemo(() => {
        return getNavigationItems({
            hasWidgetsData: !isWidgetsDataLoading,
            hasChartsData: isAvailable(recommendedCharts),
            hasMenuItemsData: menuItemsData.itemsCount > 0,
        });
    }, [
        isWidgetsDataLoading,
        menuItemsData,
        recommendedCharts,
    ]);

    const downloadButtonAnalyticsAttributes: AnalyticsData = {
        [AnalyticsAttribute.Action]: AnalyticsAction.Download,
        [AnalyticsAttribute.DownloadLocation]: FoodProfileDownloadLocation.FoodProfileSidebar,
        [AnalyticsAttribute.ClickTitle]: 'Food Profile - Sidebar',
    };

    const downloadFile = useCallback(() => {
        setIsProfileDownloading(true);
        showNotification({ text: 'Downloading…', iconDisabled: true,});
        http.downloadFileXHRFromUrl('GET', data.summary.data.downloadReportUrl)
            .then(() => {
                showNotification({ text: 'Download Successful'});
            })
            .catch(() => {
                showNotification({
                    text: 'Something went wrong.',
                    className: 'error',
                    iconDisabled: true,
                    duration: 0
                });
            })
            .finally(() => {
                setIsProfileDownloading(false);
            });
    }, [data, setIsProfileDownloading]);

    const sideNavigationItems = useMemo(() => {
        const result: any[] = navigationItems.map(item => ({
            id: item.id,
            content: (
                <FoodProfileNavLink
                    key={item.id}
                    data={item}
                />
            ),
        }));

        if (isAvailable(summary)) {
            result.push({
                id: 'download-profile',
                content: (
                    <div
                        key="download"
                        {...getAnalyticsComputedData(downloadButtonAnalyticsAttributes)}
                        onClick={downloadFile}
                        className={cn(
                            'SideNavigationButton',
                            'SideNavigationButton--accent',
                            'clickable',
                            isProfileDownloading && 'SideNavigationButton--disabled',
                        )}
                        data-testid="buttonFoodProfileSideNavDownload"
                    >
                        download
                    </div>
                ),
            });
        }

        return result;
    }, [summary, navigationItems, isProfileDownloading]);

    useEffect(() => {
        if (!data.hasData) {
            onNoData();
        }
    }, [data.hasData]);

    const shouldShowLocalAccessMessage: boolean = useMemo(
        () => localPopularity.permissionLevel === PermissionLevelEnum.None && localPopularity.hasData,
        [localPopularity]
    );
    const shouldShowFlavorAccessMessage: boolean = useMemo(
        () => heatScoreData.permissionLevel === PermissionLevelEnum.None && heatScoreData.hasData,
        [heatScoreData]
    );

    const flavorLocalRequestSubscriptionMessage: string = useMemo(() => {
        let msg = '';

        if (shouldShowFlavorAccessMessage) {
            msg = 'Want even more insights?';
        }
        else {
            msg = 'Want to track popularity at the metro level?';
        }

        msg += '<br/>Consider adding ';


        if (shouldShowLocalAccessMessage && shouldShowFlavorAccessMessage) {
            msg += `LOCAL™ and FLAVOR™`;
        }
        else if (shouldShowLocalAccessMessage) {
            msg += `LOCAL™`;
        }
        else if (shouldShowFlavorAccessMessage) {
            msg += `FLAVOR™`;
        }

        msg += ` to your subscription.`;
        return msg;
    }, [shouldShowLocalAccessMessage, shouldShowFlavorAccessMessage]);

    const trialRequestProducts: string[] = useMemo(() => {
        let products = [];

        if (shouldShowLocalAccessMessage) {
            products.push('local');
        }
        if (shouldShowFlavorAccessMessage) {
            products.push('flavor');
        }

        return products;
    }, [shouldShowLocalAccessMessage, shouldShowFlavorAccessMessage]);

    const isAnyWidgetAvailable = useMemo(() => {
        let flag = false;

        flag = flag || versatility.hasData;
        flag = flag || localPopularity.hasData;
        flag = flag || heatScoreData.hasData;
        flag = flag || pairedFlavorsData.hasData;
        flag = flag || shouldShowLocalAccessMessage;
        flag = flag || shouldShowFlavorAccessMessage;

        return flag;
    }, [versatility, pairedFlavorsData, localPopularity, heatScoreData, shouldShowLocalAccessMessage, shouldShowFlavorAccessMessage]);

    if (!data.hasData) {
        return null;
    }

    return (
        <>
            <Scrollbar
                autoHide
                renderView={props => (
                    <div
                        {...props}
                        id="foodScrollbarView"
                        className={cn(
                            "scrollbar-view",
                            isHaikuPopupOpened && "scrollbar-view--hidden",
                        )}
                    />
                )}
                renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            >
                {isLoading && (
                    <div className="spinner spinner-blink">
                        <Icon iconId="logo-dark"/>
                    </div>
                )}
                {!isLoading && (
                    <div className="FoodProfile">
                        <Element
                            className="FoodProfile__section"
                            name="overview"
                        >
                            <FoodProfileSummary
                                summary={summary}
                                searchQuery={searchQuery}
                                pipelineMenuData={pipelineMenuData}
                                permissionLevel={affinity.permissionLevel}
                                consumerAffinity={affinity}
                                consumerAffinityCategories={consumerAffinityCategories}
                                downloadUrl={downloadUrl}
                                openHaiku={() => setIsHaikuPopupOpened(true)}
                                requestTrial={(products: string[]) => products.forEach(
                                    (feature, index) => requestTrial(feature, index !== 0)
                                )}
                            />
                        </Element>
                        <Element
                            className="FoodProfileWidgetSection FoodProfile__section"
                            name="trend-details"
                        >
                            {(!isLoading && isWidgetsDataLoading) && (
                                <div className="spinner spinner-blink">
                                    <Icon iconId="logo-dark"/>
                                </div>
                            )}
                            {(!isLoading && !isWidgetsDataLoading) && (
                                <>
                                    {(isAnyWidgetAvailable || isAvailable(documentsInfo)) && (
                                        <h1
                                            className="FoodProfile__title"
                                            data-testid="textFoodProfileTitleKnowTheTrend"
                                        >
                                            Know The Trend
                                        </h1>
                                    )}
                                    {isAnyWidgetAvailable && (
                                            <div className="FoodProfile__flex-container FoodProfile__flex-container--fixed-height">
                                                {isAvailable(versatility) && (
                                                    <FoodProfileVersatility data={versatilityData}/>
                                                )}
                                                {isAvailable(pairedFlavorsData) && (
                                                    <FoodProfilePairedItems
                                                        searchQuery={searchQuery}
                                                        isLoading={isPairedFlavorsLoading}
                                                        data={pairedFlavorsData}
                                                    />
                                                )}
                                                {isAvailable(localPopularity) && (
                                                    <FoodProfileLocalIndices data={localIndicesData}/>
                                                )}
                                                {isAvailable(heatScoreData) && (
                                                    <FoodProfileHeatScore
                                                        data={heatScoreData.data}
                                                        keywordTitle={searchQuery}
                                                    />
                                                )}
                                                {(shouldShowLocalAccessMessage || shouldShowFlavorAccessMessage) && (
                                                    <FoodProfileNoData
                                                        onButtonClick={() => {
                                                            trialRequestProducts.forEach(product => {
                                                                requestTrial(product);
                                                            })
                                                            setIsLocalTrialRequestSent(true);
                                                        }}
                                                        buttonText={isLocalTrialRequestSent ? ' ✓ FREE TRIAL REQUESTED' : 'REQUEST FREE TRIAL'}
                                                        isActivated={isLocalTrialRequestSent}
                                                    >
                                                        <p dangerouslySetInnerHTML={{ __html: flavorLocalRequestSubscriptionMessage}}/>
                                                    </FoodProfileNoData>
                                                )}
                                            </div>
                                    )}
                                    {isAvailable(documentsInfo) && (
                                        <div className="FoodProfile__flex-container FoodProfile__flex-container--fixed-height">
                                            <FoodProfileFoundIn data={foundInData}/>
                                        </div>
                                    )}
                                </>
                            )}
                        </Element>
                        {isAvailable(recommendedCharts) && (
                            <Element
                                className="FoodProfileWidgetSection FoodProfile__section"
                                name="charts"
                            >
                                <div className="FoodProfile__flex-container">
                                    <FoodProfileRecommendedChart data={recommendedChartData}/>
                                </div>
                            </Element>
                        )}
                        <div className="page-content__gradient-delimiter"/>
                        <MenuItemsSection
                            searchQuery={searchQuery}
                            requestTrial={() => requestTrial(allItems[NavigationItems.LaunchesAndRatings].name)}
                            isLoading={isMenuItemsLoading}
                            data={menuItemsData}
                        />
                    </div>
                )}


            </Scrollbar>
            {!isLoading && isAvailable(summary) && isAvailable(summary.data.haikuPopupData) && (
                <HaikuPopup
                    isOpened={isHaikuPopupOpened}
                    searchQuery={searchQuery}
                    totalUsPenetration={summary.data.haikuPopupData.data}
                    totalPenetration={summary.data.totalPenetration}
                    close={() => setIsHaikuPopupOpened(false)}
                />
            )}
            <SideNavigation
                items={sideNavigationItems}
            />
        </>
    );
};

export default FoodProfileContent;