import { useData } from "../hooks/useData";
import Http from "../fetch";
import { useState } from "react";
import { ID } from "../models";
import { downloadFile } from "../helpers";

interface iInteractiveList {
    childReports: iInteractiveList[];
    id: number;
    name: string;
    order: number;
    restaurnatListId: number;
}

interface iPresetChain {
    fireflyChainId: number;
    fireflyChainName: string;
}

export interface iPreset {
    chains: iPresetChain[];
    chainSegmentGroupId: number;
    companyId: number;
    cusineId: number;
    id: number;
    isCustom: boolean;
    name: string;
    segmentId: number;
}

interface iPresetGroup {
    preset: iPreset[];
    presetClass: "lsrPresets" | "fsrPresets" | "lsrfsrPresets" | "customPresets";
    presetName: string;
}

interface iSavedQuery {
    query: {
        companyId: number;
        created: string;
        creatorId: string;
        creatorName: string;
        description: string;
        id: number;
        isDeleted: boolean;
        isPublic: boolean;
        title: string;
        userId: string;
    };
    words: string[];
}

interface iGapReportData {
    interactiveLists: iInteractiveList[];
    presets: iPresetGroup[];
    savedQueries: iSavedQuery[];
}

async function getGapReportData(): Promise<iGapReportData> {
    return await Http.get('get menu gaps');
}

interface iPrepareReportData {
    downloadLink: string;
    isSuccess: boolean;
}

interface iReportRequestData {
    Restaurants: ID[];
    PresetName: string;
    chainId: ID;
    entityId: ID;
    reportType: 0 | 1;
}

export function useGapReportData(chainId: number) {
    const { isLoading, ...data } = useData<iGapReportData>({
        interactiveLists: [],
        presets: [],
        savedQueries: [],
    }, getGapReportData);
    const [isReportLoading, setIsReportLoading] = useState(false);

    const handleReportDownload = (action: string, data: iReportRequestData): Promise<boolean> => {
        return new Promise((res, rej) => {
            Http.post(action, {}, {
                headers: {
                    'content-type': 'application/json; charset=utf-8',
                },
                body: JSON.stringify(data)
            }).then((data: iPrepareReportData) => {
                setIsReportLoading(false);
                if (data.isSuccess && data.downloadLink) {
                    downloadFile(`${process.env.REACT_APP_API}${data.downloadLink}`);
                    res(true);
                } else {
                    rej(false);
                }
            });
        });
    };

    const downloadRegularReport = (chainsIds: ID[], presetName: string, reportId: ID, isSavedQuery?: boolean): Promise<boolean> => {
        setIsReportLoading(true);
        return handleReportDownload('chain get gap report', {
            Restaurants: chainsIds,
            PresetName: presetName,
            chainId,
            entityId: reportId,
            reportType: isSavedQuery ? 1 : 0,
        });
    };

    const downloadSimpleReport = (chainsIds: ID[], presetName: string) => {
        setIsReportLoading(true);
        return handleReportDownload('chain get simple gap report', {
            Restaurants: chainsIds,
            PresetName: presetName,
            chainId,
            entityId: 0,
            reportType: 0,
        });
    };

    return {
        isLoading,
        isReportLoading,
        ...data,
        downloadReport: downloadRegularReport,
        downloadSimpleReport,
    };
}