import { useData } from "../hooks/useData";
import Http from "../fetch";
import { API__SocialLinks } from "../models/api/SocialLinks";
import { iSocialLink } from "../models/ChainDetails";
import { iSocialLinks, SocialLinks } from "../models/SocialLinks";
import { API__FFSocialLinks } from "../apiSchema";

async function getSocialLinks(links: API__FFSocialLinks): Promise<iSocialLinks> {
    const data: API__SocialLinks = await Http.post(
        'get social links',
        {},
        {
            body: JSON.stringify({ links }),
            headers: {
                "content-type": "application/json; charset=utf-8",
            },
        }
    );

    return new SocialLinks({ apiModel: data });
}

export default function useSocialLinks(arr: API__FFSocialLinks) {
    return useData(new SocialLinks(), getSocialLinks, arr)
}