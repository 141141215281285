import React, { useMemo } from "react";
import "./CircularBar.styles.scss";

interface iProps {
    percentage: number;
    id: string;
}

const CircularBar: React.FC<iProps> = ({ percentage, id }) => {
    const strokeDasharray = percentage ? (percentage === 100 ? 590 : 575) : 590;
    const dashOffset = useMemo(
        () => strokeDasharray - ((strokeDasharray - 5) * percentage) / 100,
        [percentage, strokeDasharray],
    );

    return (
        <div className="CircularBar">
            <div className="CircularBar__circle">
                <svg className="CircularBar__circle-svg">
                    <circle
                        className="CircularBar__background"
                        cx="100"
                        cy="100"
                        r="93"
                    />
                    <circle
                        style={{
                            strokeDashoffset: dashOffset,
                            strokeDasharray,
                        }}
                        stroke="#56CCF2"
                        fill="none"
                        className="CircularBar__line"
                        cx="100"
                        cy="100"
                        r="93"
                    />
                </svg>
            </div>
        </div>
    );
};

export default CircularBar;
