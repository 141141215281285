import { createSelector } from 'reselect';

const documentsData = state => state.documentsData.data;
const shareData = state => state.documentsData.share;

const reportsCount = state => state.documentsData.data.Report.itemsCount;
const macCount = state => state.documentsData.data.Mac.itemsCount;
const documentCount = state => state.documentsData.data.Document.itemsCount;
const mtdfoodprofileCount = state => state.documentsData.data.mtdfoodprofile.itemsCount;
const trendspottingCount = state => state.documentsData.data.Trendspotting.itemsCount;

export const tableColumnsSettings = () => [
    { label: 'Chain', sorting: 'abc', align: 'left' },
    { label: 'Units', sorting: 'num' },
    { label: 'Segment', sorting: 'abc' }
];

export const tableCells = (type) => {
    return createSelector(
        documentsData,
        section => section[type].items.map((item) => {
            let fileSize;
            if (item.fileSize) {
                const kb = Math.round(parseInt(item.fileSize, 10) / 1024);
                let mb;
                if (kb > 1000) {
                    mb = `${(Math.round((kb / 1024) * 10) / 10)} mb`;
                }
                fileSize = {
                    value: mb || `${kb} kb`,
                    units: +item.fileSize
                };
            }
            let dateTime;
            if (item.dateTime) {
                dateTime = item.dateTime.split(' ')[0];
            }
            let publicationFamily = null;
            let title = item.title;
            switch (type) {
                case 'mtdfoodprofile': {
                    publicationFamily = 'Food Profile';
                    const wordsArr = item.title.toLowerCase().split(' ');
                    wordsArr.forEach((elem, index) => {
                        if (wordsArr[index].length > 0) {
                            wordsArr[index] = elem.charAt(0).toUpperCase() + elem.slice(1);
                        }
                    });
                    title = wordsArr.join(' ');
                    break;
                }
                case 'Mac': {
                    publicationFamily = 'Menu Adoption Cycle';
                    break;
                }
                case 'Document': {
                    publicationFamily = item.myDocumentTypeName;
                    break;
                }
                default:
                    break;
            }
            const selected = {
                fullInfo: item,
                rowInfo: [
                    title,
                    publicationFamily !== null ? publicationFamily : item.publicationFamily,
                    dateTime,
                    fileSize
                ]
            };
            if (type === 'Document') {
                if (item.projectId) {
                    selected.rowInfo.splice(2, 0, {
                        to: {
                            pathname: '/project',
                            search: `?id=${item.projectId}`
                        },
                        value: item.projectName,
                        name: item.projectName
                    });
                } else {
                    selected.rowInfo.splice(2, 0, '');
                }
            }
            return selected;
        })
    );
};

// export const tableCellsSelector = createSelector(
//     documentsData,
//     section => section.Report.items.map((item) => {
//         const chainName = {
//             type: 'url',
//             title: item.title,
//             url: ''
//         };
//         const dateTime = item.dateTime.split(' ')[0];
//         return {
//             fullInfo: item,
//             rowInfo: [
//                 item.title,
//                 item.publicationFamily,
//                 dateTime
//             ]
//         };
//     })
// );

export const tableDataSelector = (state, tabType) => {
    const tableCellsSelector = tableCells(tabType);
    return (createSelector(
        tableCellsSelector,
        tableColumnsSettings,
        (data, settings) => {
            return {
                data,
                settings
            };
        }
    ))(state);
};

// export const tableDataSelector = createSelector(
//     tableCellsSelector,
//     tableColumnsSettings,
//     (data, settings) => {
//         return {
//             data,
//             settings
//         };
//     }
// );

export const reportsListDataSelector = createSelector(
    documentsData,
    data => data.Report.items
);

export const trendspottingsListDataSelector = createSelector(
    documentsData,
    data => data.Trendspotting.items
);

export const mtdfoodprofileListDataSelector = createSelector(
    documentsData,
    data => data.mtdfoodprofile.items
);

export const macsListDataSelector = createSelector(
    documentsData,
    data => data.Mac.items
);

export const myDocumentsListDataSelector = createSelector(
    documentsData,
    data => data.Document.items
);

export const loadedTypesSelector = createSelector(
    documentsData,
    (data) => {
        const loadedTypes = {};
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                loadedTypes[key] = data[key].isFullLoaded;
            }
        }
        return loadedTypes;
    }
);

export const shareDataSelector = createSelector(
    shareData,
    data => data.isOpen
);

export const shareUrlSelector = createSelector(
    shareData,
    (data) => {
        return {
            title: data.title,
            id: data.id,
            documentType: data.documentType,
            publicationFamily: data.publicationFamily,
            shareLinkString: data.shareLinkString
        };
    }
);

export const shareEmailsListSelector = createSelector(
    shareData,
    data => data.emails.map((item) => {
        return {
            ...item,
            isCustom: false
        };
    })
);

export const shareLinkSelector = createSelector(
    shareData,
    data => data.shareLinkString
);

export const fileUrlSelector = createSelector(
    documentsData,
    data => data.fileURL
);

export const downloadingFileStateSelector = createSelector(
    state => state.documentsData,
    data => data.downloadingFile
);

export const downloadingChopFileStateSelector = createSelector(
    state => state.documentsData,
    data => data.downloadingChop
);

export const trendspottingsByFamilySelector = createSelector(
    state => state.documentsData.dataByFamily,
    data => data
);

export const myDocumentsCountSelector = createSelector(
    [reportsCount, macCount, documentCount, trendspottingCount, mtdfoodprofileCount],
    (reports = 0, macs = 0, documents = 0, trendspottings = 0, foodprofiles = 0) =>
        reports + documents + trendspottings
    // + macs + foodprofiles
);
