import React, { FC, useContext, useState } from "react";
import cn from "classnames";
import { IconLock } from "../../../icons";
import Button from "../Button/Button";
import "./LockSection.styles.scss";
import { LockContext } from "./LockSection.context";
import { SectionEnum } from "../../../core/enums";
import Http from "../../../core/fetch";
import NotificationPopup from "../../NotificationPopup/NotificationPopup";
import useNotification from "../../../core/hooks/useNotification";

interface iProps {
    title?: string;
    isLocked?: boolean;
}

export const Lock: FC = (
    {
        children
    }
) => {
    return (
        <div className="LockSection">
            <div className="LockSection__wrapper">
                <div className="LockSection__icon">
                    <IconLock />
                </div>
                {children}
            </div>
        </div>
    );
};

export const LockDefaultAction: FC<{section: SectionEnum}> = ({ section }) => {
    const { handleShowNotification, isOpen } = useNotification();
    const [isRequestSent, setIsRequestSend] = useState(false);

    const handleUnlockRequest = () => {
        Http.get('unlock request', { leadSource: section }, {}, "none");
        handleShowNotification();
        setIsRequestSend(true);
    }

    return (
        <div className="LockSection__action">
            <Button
                className="LockSection__action-button"
                isDisabled={isRequestSent}
                onClick={handleUnlockRequest}
            >
                Contact us
            </Button>
            {" "}to learn more

            <NotificationPopup
                isOpened={isOpen}
                hasCloseButton={false}
                style={{ width: "auto", height: "auto" }}
                className="notification-popup--table LockSection__notification"
            >
                <span>Thank you for your request, we will get back to you shortly</span>
            </NotificationPopup>
        </div>
    );
};

export const LockAction: FC<{
    handleClick: () => void;
    showNotification?: boolean;
}> = (
    {
        handleClick,
        showNotification= true,
    }
) => {
    const { handleShowNotification, isOpen } = useNotification();
    const [isRequestSent, setIsRequestSend] = useState(false);

    const handleUnlockRequest = () => {
        handleClick();
        showNotification && handleShowNotification();
        setIsRequestSend(true);
    }

    return (
        <div className="LockSection__action">
            <Button
                className="LockSection__action-button"
                isDisabled={isRequestSent}
                onClick={handleUnlockRequest}
            >
                Contact us
            </Button>
            {" "}to learn more

            <NotificationPopup
                isOpened={isOpen}
                hasCloseButton={false}
                style={{ width: "auto", height: "auto" }}
                className="notification-popup--table LockSection__notification"
            >
                <span>Thank you for your request, we will get back to you shortly</span>
            </NotificationPopup>
        </div>
    );
};

const LockSection: FC<iProps> = (
    {
        isLocked,
        title,
        children
    }
) => {
    const context = useContext(LockContext);

    if (!isLocked && !context.isLocked) {
        return <>{children}</>;
    }

    const content = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            const propsClassNames = child.props.className || "";
            const classNames = cn(
                propsClassNames,
                "child-is-locked",
            );
            return React.cloneElement(
                child,
                { className: classNames },
                <>
                    {child.props.children}
                    <Lock>
                        {title}
                    </Lock>
                </>,
            );
        }
    });

    return (
        <>
            {content}
        </>
    );
};

export default LockSection;
