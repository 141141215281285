import { ReactNode } from "react";
import { equalInLC, getSelectedCounterString } from "@core/helpers";

export interface iTab {
    title?: string;
    subTitle?: ReactNode;
    renderThumb?: ReactNode;
    isModified: boolean;
    isInvalid?: boolean;
    content: ReactNode;
}

export function getTabSubTitle(selectedItems: any[], allItems: any[], itemType: string = ''): string {
    if (selectedItems.length === 0) return '';
    const countString = getSelectedCounterString(selectedItems, allItems);
    return `${countString} ${equalInLC(countString, 'all') ? '' : itemType} selected`;
}

export function getTabOtherSubTitle(
    selectedMinUnits: number,
    minUnits: number,
    selectedMaxUnits: number,
    maxUnits: number,
    selectedAlcohol: any[],
    alcohol: any[],
    selectedMenuTypes: any[],
    menuTypes: any[],
): string {
    const isUnitsModified = isUnitsCountFilterModified(
        selectedMinUnits,
        minUnits,
        selectedMaxUnits,
        maxUnits,
    );
    const isAlcoholModified = getIsFilterModified(selectedAlcohol, alcohol);
    const isAlcoholUnselected = selectedAlcohol.length === 0;
    const isMenuTypesModified = getIsFilterModified(selectedMenuTypes, menuTypes);
    const isMenuTypesUnselected = selectedMenuTypes.length === 0;
    const isAnyUnselected = isAlcoholUnselected || isMenuTypesUnselected;
    const modifiedFiltersCount = +isUnitsModified + (+isAlcoholModified) + (+isMenuTypesModified);

    if (modifiedFiltersCount === 0 && !isAnyUnselected) return 'all selected';
    else if (modifiedFiltersCount > 1) return 'multiple filters applied';
    else if (isUnitsModified) return 'unit count filter applied';
    else if (isAlcoholModified) return 'alcohol service filter applied';
    else if (isMenuTypesModified) return 'menu type filter applied';
    else return '';
}

export function getIsFilterModified(selectedItems: any[], allItems: any[]): boolean {
    if (selectedItems.length === 0) return false;
    return selectedItems.length !== 0 && selectedItems.length !== allItems.length;
}

export function isUnitsCountFilterModified(
    selectedMinUnits: number,
    minUnits: number,
    selectedMaxUnits: number,
    maxUnits: number,
): boolean {
    return (selectedMinUnits !== minUnits || selectedMaxUnits !== maxUnits);
}
