import { createSelector } from 'reselect';
import getFileSize from '../../core/getFileSize';
import { monthValues, capitalize } from '../../core/old_helpers';
import { companiesSelector } from '../../containers/DocumentsLibraryPopups/documentsLibraryPopups.selectors';
import getFileTypeIcon, { getFileTypeNaming } from '../../core/getFileTypeIcon';
import { isImpersonateModeSelector } from '../../selectors/page';

const pageData = state => state.documentTypeData;

export const documentsSelector = createSelector(
    pageData,
    data => data.documents.map((document) => {
        const { createdOn, documentType, projectsName = '', fileSize = 0 } = document;
        let subTitle = '';
        if (projectsName.length > 0) {
            subTitle += `${projectsName.reduce(
                (acc, name, index) => {
                    if (index === 0) return name;
                    return `${acc} | ${name}`;
                }, '')} ◦ `;
        }

        if (documentType) {
            subTitle += `${documentType.name} ◦ `;
        }

        if (createdOn) {
            const date = new Date(createdOn);
            const month = 1 + monthValues[date.getMonth()];
            const year = date.getFullYear();
            subTitle += `${month} ${year} ◦ `;
        }
        const checkedSize = getFileSize(fileSize);

        subTitle += `${checkedSize.value.toFixed(1)} ${checkedSize.units}`;
        return {
            ...document,
            subTitle
        };
    })
);

export const subTitleSelector = createSelector(
    documentsSelector,
    (documents) => {
        let subTitle = '';
        let totalNamedFilesCount = 0;
        const namingCounts = documents.reduce((filesCounts, document) => {
            const fileExtension = document.docTypeString && document.docTypeString.substr(1);
            const fileNaming = getFileTypeNaming(fileExtension);
            // console.log('fileNaming', fileNaming);
            if (fileNaming !== 'other') {
                if (filesCounts.has(fileNaming)) {
                    filesCounts.set(fileNaming, filesCounts.get(fileNaming) + 1);
                } else {
                    filesCounts.set(fileNaming, 1);
                }
            }
            return filesCounts;
        }, new Map());
        namingCounts.forEach((count, naming) => {
            const fileNamingString = `${count} ${count === 1 ? naming : `${naming}s`}`;
            if (subTitle === '') {
                subTitle = fileNamingString;
            } else {
                subTitle += ` | ${fileNamingString}`;
            }
            totalNamedFilesCount += count;
        });
        if (totalNamedFilesCount < documents.length) {
            subTitle += ' and more...';
        }
        return subTitle;
    }
);

export const TABLE_COLUMNS_SETTINGS = [
    { label: 'File name', align: 'left', sorting: 'abc' },
    { label: 'Project name', sorting: 'abc' },
    { label: 'Document type', sorting: 'abc' },
    { label: 'Date', sorting: 'date' },
    { label: 'Added by', sorting: 'abc' },
];

const userCompanyIdSelector = state => state.documentsLibraryPopupsData.currentUserCompanyId;

export const TABLE_COLUMNS_SETTINGS_DS = [
    { label: 'File name', align: 'left', sorting: 'abc' },
    { label: 'Project name', sorting: 'abc' },
    { label: 'Document type', sorting: 'abc' },
    { label: 'Date', sorting: 'date' },
    { label: 'Shared with', sorting: 'num' },
    { label: 'Added by', sorting: 'abc' },
];

export const documentsTableSelector = createSelector(
    documentsSelector,
    userCompanyIdSelector,
    companiesSelector,
    isImpersonateModeSelector,
    (documents, companyId, companies, isImpersonateMode) => ({
        settings: companyId === 1 ? TABLE_COLUMNS_SETTINGS_DS : TABLE_COLUMNS_SETTINGS,
        data: documents.map((document) => {
            const date = new Date(document.createdOn);
            let day = date.getDate();
            let month = 1 + date.getMonth();
            const year = date.getFullYear();
            const fileSize = getFileSize(document.fileSize || 0);

            if (day < 10) day = `0${day}`;
            if (month < 10) month = `0${month}`;

            let sharedWithName = '';
            const companiesCount = document.companyIds.length;
            if (companies.length > 1) {
                sharedWithName = companiesCount > 1
                    ? `${companiesCount} companies`
                    : companies.find(({ value }) => value === document.companyIds[0]).title;
            }
            const sharedWithList = companies.filter(
                company => document.companyIds.includes(company.value) && +company.value !== 1
            );

            if (companyId === 1) {
                return [
                    {
                        type: 'documents library document',
                        value: document.title,
                        data: document,
                        icon: document.docTypeString ? getFileTypeIcon(document.docTypeString.substr(1)) : 'file-other',
                        size: `${fileSize.value.toFixed(1)} ${fileSize.units}`,
                        downloadLink: `${process.env.REACT_APP_API}${document.downloadLink}`,
                        width: 30,
                        height: 36,
                        showFooter: !isImpersonateMode,
                    },
                    document.projectName,
                    document.documentType.name,
                    {
                        type: 'date',
                        name: isNaN(year) ? '--/--/----' : `${month}/${day}/${year}`,
                        value: isNaN(year) ? '--/--/----' : `${month}/${day}/${year}`
                    },
                    {
                        type: 'tooltip',
                        name: sharedWithName,
                        value: companiesCount,
                        data: companiesCount > 1 && sharedWithList
                    },
                    capitalize(document.createdByUserName),
                    // {
                    //     type: 'overlay document',
                    //     value: document
                    // }
                ];
            }

            return [
                {
                    type: 'documents library document',
                    value: document.title,
                    data: document,
                    icon: document.docTypeString ? getFileTypeIcon(document.docTypeString.substr(1)) : 'file-other',
                    size: `${fileSize.value.toFixed(1)} ${fileSize.units}`,
                    downloadLink: `${process.env.REACT_APP_API}${document.downloadLink}`,
                    width: 30,
                    height: 36,
                    showFooter: !isImpersonateMode,
                },
                document.projectName,
                document.documentType.name,
                {
                    type: 'date',
                    name: isNaN(year) ? '--/--/----' : `${month}/${day}/${year}`,
                    value: isNaN(year) ? '--/--/----' : `${month}/${day}/${year}`
                },
                capitalize(document.createdByUserName),
                // {
                //     type: 'overlay document',
                //     value: document
                // }
            ];
        })
    })
);
