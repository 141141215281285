import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SpriteIcon from '../../components/SpriteIcon/SpriteIcon';
import {
    setSharePopupState,
    purchaseDocument,
    setClearDocumentPurchaseMade,
    resetCountToShowByDocType } from '../../actions/documents.actions';
import { addFileToPackage } from '../../actions/packages.actions';
import { addNotification } from '../../components/GlobalNotification/globalNotification.actions';
import DocumentTableCell from './DocumentTableCell';
import MacItem from './Mac/MacItem';
import DocumentItem from './Document/DocumentItem';
import './DocumentsTableStyle.scss';

class DocumentsTable extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            tableHeadData: [
                { label: 'Name', propName: 'title', sorting: 'abc', align: 'left', sortedBy: -1, isLink: true },
                {
                    label: 'Series',
                    propName: 'publicationFamily',
                    sorting: 'abc',
                    align: 'center',
                    sortedBy: -1,
                    isLink: true
                },
                { label: 'Date', propName: 'dateTime', sorting: 'date', align: 'center', sortedBy: -1, isLink: true },
                { label: 'Size', propName: 'fileSize', sorting: 'size', align: 'center', sortedBy: -1, isLink: true },
                { label: 'Project name', propName: 'projectUrl', sorting: 'abc', align: 'center', sortedBy: -1, isLink: true }
            ],
            active: -1
        };
    }

    componentDidMount() {
        if (this.props.data[0].fullInfo.docType === 'document') {
            this.state.tableHeadData.splice(2, 0, this.state.tableHeadData.splice(4, 1)[0]);
        }
        if (this.props.sortByColumn) {
            const defaultHeadData = [].concat(this.state.tableHeadData);
            defaultHeadData.forEach((elem) => {
                if (elem.label === this.props.sortByColumn) {
                    elem.sortedBy = this.props.sortDirection ? this.props.sortDirection : 'asc';
                }
            });
        }
    }

    componentWillUnmount() {
        this.props.resetRowsCount();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data[0].fullInfo.docType === 'document' && this.props.data[0].fullInfo.docType !== 'document') {
            const tableHeadData = [].concat(this.state.tableHeadData);
            tableHeadData.splice(2, 0, tableHeadData.splice(4, 1)[0]);
            this.setState({
                tableHeadData
            });
        } else if (this.props.data[0].fullInfo.docType !== nextProps.data[0].fullInfo.docType) {
            this.setState({
                tableHeadData: [
                        { label: 'Name', propName: 'title', sorting: 'abc', align: 'left', sortedBy: -1, isLink: true },
                    {
                        label: 'Series',
                        propName: 'publicationFamily',
                        sorting: 'abc',
                        align: 'center',
                        sortedBy: -1,
                        isLink: true
                    },
                        { label: 'Date', propName: 'dateTime', sorting: 'date', align: 'center', sortedBy: -1, isLink: true },
                        { label: 'Size', propName: 'fileSize', sorting: 'size', align: 'center', sortedBy: -1, isLink: true },
                        { label: 'Project name', propName: 'projectUrl', sorting: 'abc', align: 'center', sortedBy: -1, isLink: true }
                ]
            });
        }
    }

    sortDatesData(data, sortColumn) {
        return [].concat(data).sort((a, b) => {
            const valA = typeof a.rowInfo[sortColumn] === 'object' ? new Date(a.rowInfo[sortColumn].name) : new Date(a.rowInfo[sortColumn]);
            const valB = typeof b.rowInfo[sortColumn] === 'object' ? new Date(b.rowInfo[sortColumn].name) : new Date(b.rowInfo[sortColumn]);
            if (valA < valB) {
                return -1;
            }
            if (valA > valB) {
                return 1;
            }
            return 0;
        });
    }

    sortSizesData(data, sortColumn) {
        return [].concat(data).sort((a, b) => {
            const valA = a.rowInfo[sortColumn].units;
            const valB = b.rowInfo[sortColumn].units;
            if (valA < valB) {
                return -1;
            }
            if (valA > valB) {
                return 1;
            }
            return 0;
        });
    }

    sortData() {
        let sortParams = {};
        let sortColumn = null;
        this.state.tableHeadData.forEach((item, index) => {
            if (item.sortedBy !== -1) {
                sortColumn = index;
                sortParams = item;
            }
        });
        let sorted;
        if (sortColumn !== null) {
            switch (sortParams.sorting) {
                case 'date':
                    sorted = this.sortDatesData(this.props.data, sortColumn);
                    break;
                case 'size':
                    sorted = this.sortSizesData(this.props.data, sortColumn);
                    break;
                default:
                    sorted = [].concat(this.props.data).sort((a, b) => {
                        const valA = typeof a.rowInfo[sortColumn] === 'object' ? a.rowInfo[sortColumn].name.toUpperCase() : a.rowInfo[sortColumn].toUpperCase();
                        const valB = typeof b.rowInfo[sortColumn] === 'object' ? b.rowInfo[sortColumn].name.toUpperCase() : b.rowInfo[sortColumn].toUpperCase();
                        if (valA < valB) {
                            return -1;
                        }
                        if (valA > valB) {
                            return 1;
                        }
                        return 0;
                    });
            }
        }

        if (sortParams.sortedBy === 'dsc') {
            sorted = sorted.concat([]).reverse();
        }
        return sortColumn !== null ? sorted : this.props.data;
    }

    sortByColumn(index) {
        const newHeadData = [].concat(this.state.tableHeadData);
        switch (newHeadData[index].sortedBy) {
            case 'asc':
                newHeadData[index].sortedBy = 'dsc';
                break;
            default:
                newHeadData[index].sortedBy = 'asc';
        }
        newHeadData.forEach((elem, i) => {
            if (index === i) return false;
            elem.sortedBy = -1;
        });
        this.setState({
            tableHeadData: newHeadData
        });
    }

    renderTableHeadCells() {
        const headerCells = this.state.tableHeadData.map((column, index) => {
            if (this.props.shortMode && index > 1) return false;
            const currDocType = this.props.data[0].fullInfo.docType;
            if (
                (currDocType !== 'document' && index >= 3) &&
                (currDocType.indexOf('trendspotting') === -1 &&
                index >= 3)
            ) return false;
            if (currDocType.indexOf('trendspotting') !== -1 && index >= 4) return false;
            const cellClassList = new Set([
                'documents-table-cell',
                'documents-table-cell--header',
                `documents-table-cell--${column.align}`
            ]);
            if (currDocType === 'document') {
                cellClassList.add('documents-table-cell--document-type');
            }
            const iconClassList = new Set([
                'documents-table__head-icon'
            ]);
            let iconId = '';
            switch (column.sortedBy) {
                case 'asc':
                    iconId = 'order-reverse';
                    break;
                case 'dsc':
                    iconId = 'order';
                    break;
                default:
                    iconId = '';
            }
            return (
                <div key={index} className={Array.from(cellClassList).join(' ')}>
                    <span
                        className="documents-table__head-text"
                        onClick={this.sortByColumn.bind(this, index)}
                        data-testid={`buttonDocumentsTableHead-${index}`}
                    >
                        {
                            ((this.props.shortMode || this.props.data[0].fullInfo.docType === 'document') && index === 1) ?
                                'Type' :
                                column.label
                        }&nbsp;
                        <span
                            className={Array.from(iconClassList).join(' ')}
                        >
                            {
                                iconId.length ?
                                    <SpriteIcon iconId={iconId} className="documents-table__head-icon-svg"/> :
                                    null
                            }
                        </span>
                    </span>
                </div>
            );
        });
        const inlineStyle = {
            visibility: 'hidden'
        };
        headerCells.push(
            <div
                key={headerCells.length}
                style={inlineStyle}
                className="documents-table__btns"
            >
                {
                    this.renderDetailedItem(this.props.data[0].fullInfo)
                }
            </div>
        );
        return headerCells;
    }

    expandTableRow(id, e) {
        this.setState({ active: id });
    }

    collapseTableRows() {
        this.setState({ active: -1 });
    }

    renderDetailedItem(fullInfo) {
        const type = fullInfo.docType;
        switch (type) {
            case 'mac':
                return <MacItem
                    {...fullInfo}
                    tableMode={true}
                    searchWord={this.props.searchWord}
                    handleMouseEnter={this.props.scrollToItem}
                    handleMouseLeave={this.props.preventScrollToItem}
                    from={this.props.from}
                />;
            case 'document':
                return <DocumentItem
                    {...fullInfo}
                    tableMode={true}
                    searchWord={this.props.searchWord}
                />;
            default:
                return '';
        }
    }

    renderTableBodyRows() {
        return this.sortData().map((row, rowIndex) => {
            const classList = new Set(['documents-table-row__wrapper']);
            // if (true) {
            if (this.state.active === rowIndex && !this.props.shortMode) {
                 // classList.add('documents-table-row__wrapper--active');
            }
            return (
                <div
                    key={rowIndex}
                    className={Array.from(classList).join(' ')}
                    onMouseEnter={this.expandTableRow.bind(this, rowIndex)}
                    onMouseLeave={this.collapseTableRows.bind(this)}
                >
                    <div className="documents-table-row">
                        {
                            row.rowInfo.map((cell, cellIndex) => {
                                if (this.props.shortMode && cellIndex > 1) {
                                    return;
                                }
                                if (row.fullInfo.docType === 'report' && cellIndex > 2) {
                                    return;
                                }
                                return (
                                    <DocumentTableCell
                                        key={cellIndex}
                                        align={this.state.tableHeadData[cellIndex].align}
                                        isLink={this.state.tableHeadData[cellIndex].isLink}
                                        cellData={cell}
                                        fullInfo={row.fullInfo}
                                        icon={cellIndex === 0}
                                        cellIndex={cellIndex}
                                        searchWord={this.props.searchWord}
                                        from={this.props.from ? this.props.from : 'fromDocuments'}
                                        testId={`textDocumentsTableData-${rowIndex}-${cellIndex}`}
                                    />
                                );
                            })
                        }
                        <div
                            className="documents-table__btns"
                        >
                            {
                                this.renderDetailedItem(row.fullInfo)
                            }
                        </div>
                    </div>
                </div>
            );
        }).slice(0, this.props.maxToShow);
    }

    render() {
        return (
            <div className={`documents-table__container ${this.props.className}`}>
                <div className="documents-table__outer">
                    <div
                        className="documents-table"
                        data-testid={this.props.testId}
                    >
                        <div className="documents-table__head-row">
                            { this.renderTableHeadCells() }
                        </div>
                        <div>
                            { this.renderTableBodyRows() }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DocumentsTable.propTypes = {
    data: PropTypes.array,
    searchWord: PropTypes.string,
    scrollToItem: PropTypes.func,
    preventScrollToItem: PropTypes.func,
    shortMode: PropTypes.bool,
    sortByColumn: PropTypes.string,
    sortDirection: PropTypes.string,
    testId: PropTypes.number,
};

const mapStateToProps = state => ({
    justPurchased: state.documentsData.justPurchased
});

const mapDispatchToProps = dispatch => ({
    showPopup: data => dispatch(setSharePopupState(data)),
    showNotification: text => dispatch(addNotification(text)),
    addFileToPackage: options => dispatch(addFileToPackage(options)),
    purchaseDocument: params => dispatch(purchaseDocument(params)),
    setClearDocumentPurchaseMade: () => dispatch(setClearDocumentPurchaseMade()),
    resetRowsCount: () => dispatch(resetCountToShowByDocType())
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTable);
