import React, { FC, memo } from "react";
import LTOChart from "./LTOChart/LTOChart";
import LaunchesList from "./List/LaunchesList";
import "./Launches.styles.scss";
import { IconInfoStokes } from "../../../../../icons";
import Button from "../../../../../components/new/Button/Button";
import { YearSeasonsEnum } from "../../../../../core/models/LTOChart";
import { LtoLaunches } from "../../../../../core/models/ChainDetailsLtoSection";
import { PermissionLevelEnum } from "../../../../../core/models/PermissionSection";
import { Lock, LockDefaultAction } from "../../../../../components/new/LockSection/LockSection";
import { ChainSectionsEnum, getSectionData } from "../../../ChainPage.helpers";
import { SectionEnum } from "../../../../../core/enums";

interface iProps {
    data: LtoLaunches;
}

const Launches: FC<iProps> = (
    {
        data: {
            permissionLevel,
            launchesChartData,
            launchesList,
        }
    }
) => {
    return (
        <div className="Launches">
            <div className="Launches__chart">
                <div className="Launches__header">
                    <div className="Launches__header-column">
                        <div className="Launches__info">
                            <Button
                                className="Launches__info-button"
                                tooltipText={
                                    <div className="Launches__info-dropdown">
                                        <div className="Launches__info-item">
                                            {YearSeasonsEnum.Winter} <span>= Dec, Jan, Feb</span>
                                        </div>
                                        <div className="Launches__info-item">
                                            {YearSeasonsEnum.Spring} <span>= Mar, Apr, May</span>
                                        </div>
                                        <div className="Launches__info-item">
                                            {YearSeasonsEnum.Summer} <span>= Jun, Jul, Aug</span>
                                        </div>
                                        <div className="Launches__info-item">
                                            {YearSeasonsEnum.Fall} <span>= Sep, Oct, Nov</span>
                                        </div>
                                    </div>
                                }
                                tooltipPosition={["left", "above"]}
                            >
                                <IconInfoStokes />
                            </Button>
                        </div>
                    </div>
                    <div className="Launches__header-column">
                        <div className="Launches__title"># of menu launches</div>
                        <div className="Launches__sub-title">includes LTOs and full-time items</div>
                    </div>
                </div>
                <LTOChart data={launchesChartData} />
            </div>
            <div className="Launches__list">
                <LaunchesList data={launchesList} />
            </div>
            {permissionLevel === PermissionLevelEnum.None && (
                <Lock>
                    {getSectionData(ChainSectionsEnum.Lto).noAccessMessage}
                    <LockDefaultAction section={SectionEnum.Ltos}/>
                </Lock>
            )}
        </div>
    );
};

export default memo(Launches);
