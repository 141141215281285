import { FC, memo, useContext} from "react";
import { ChainDetailsDataContext } from "../../../ChainPage.helpers";
import ImagesGallery from "../ImageGallery/ImagesGallery";
import ProfileMap from "./ProfileMap";
import { MAP_IDS } from "./ChainMap.helpers";
import "./ChainMap.styles.scss";

interface iProps {
    heatMap: google.maps.LatLng[];
    initedMapLoader: boolean;
}

const ProfileCarousel: FC<iProps> = ({
    heatMap,
    initedMapLoader,
}) => {
    const { data: { mainData: { images } } } = useContext(ChainDetailsDataContext);


    return (
        <ImagesGallery data={images}>
            <ProfileMap
                isInited={initedMapLoader}
                mapId={MAP_IDS.CAROUSEL}
                heatmapData={heatMap}
            />
        </ImagesGallery>
    )
};


export default memo(ProfileCarousel);
