import { iPermissionFields, PermissionLevelEnum, PermissionSection } from "./PermissionSection";
import { LTOChart } from "./LTOChart";
import { iItem } from "../../components/new/DescriptionList/DescriptionList";
import { LTO } from "./LTO";
import { API__LtoViewModel, API__SRChart, API__SRListItem } from "../apiSchema";
import { sortByField } from "../helpers";

const LAUNCHES_LIST_FAKE_DATA: API__SRListItem[] = Array(20).fill({
    name: `Launches Item`,
    value: 0,
});

const LAUNCHES_CHART_FAKE_DATA: API__SRChart[] = [
    {
        year: 2000,
        quarters: [
            { season: "WIN", value: 9 },
            { season: "SPR", value: 11 },
            { season: "SPR", value: 8 },
            { season: "SPR", value: 6 },
        ]
    },
    {
        year: 2001,
        quarters: [
            { season: "WIN", value: 5 },
            { season: "SPR", value: 4 },
            { season: "SPR", value: 0 },
            { season: "SPR", value: 5 },
        ]
    },
    {
        year: 2002,
        quarters: [
            { season: "WIN", value: 6 },
            { season: "SPR", value: 7 },
            { season: "SPR", value: 5 },
            { season: "SPR", value: 12 },
        ]
    },
];

export interface iLtoLaunches extends iPermissionFields {
    launchesChartData: LTOChart[];
    launchesList: iItem[];
}

export class LtoLaunches implements iLtoLaunches {
    static defaultData: iLtoLaunches = {
        ...new PermissionSection(),
        launchesChartData: [],
        launchesList: [],
    };

    permissionLevel = LtoLaunches.defaultData.permissionLevel;
    hasData = LtoLaunches.defaultData.hasData;
    launchesChartData = LtoLaunches.defaultData.launchesChartData;
    launchesList = LtoLaunches.defaultData.launchesList;

    constructor(data?: API__LtoViewModel) {
        if (data) {
            this.mapFromApi(data);
        }
    }

    private setData(data: iLtoLaunches) {
        ({
            hasData: this.hasData,
            permissionLevel: this.permissionLevel,
            launchesList: this.launchesList,
            launchesChartData: this.launchesChartData,
        } = data);
    }

    mapFromApi(data: API__LtoViewModel) {
        const {
            launches,
        } = data;
        const permissionLevel = PermissionSection.getPermissionLevel(launches.permissionLevel);

        const launchesList: iItem[] =
            (permissionLevel === PermissionLevelEnum.None
                    ? LAUNCHES_LIST_FAKE_DATA
                    : launches.data?.list || []
            ).map(i => ({
                label: i.name,
                value: i.value,
            }));

        const launchesChartData: LTOChart[] = (
            permissionLevel === PermissionLevelEnum.None
                ? LAUNCHES_CHART_FAKE_DATA
                : launches.data?.chart || []
        ).map(i => new LTOChart({ apiModel: i }));

        this.setData({
            hasData: launches.hasData,
            permissionLevel,
            launchesList,
            launchesChartData,
        });
    }
}

export interface iChainDetailsLtoSection {
    launches: LtoLaunches;
    ltos: iPermissionFields & {
        data: LTO[];
    };
}

export class ChainDetailsLtoSection implements iChainDetailsLtoSection {
    static defaultData: iChainDetailsLtoSection = {
        launches: new LtoLaunches(),
        ltos: {
            permissionLevel: PermissionLevelEnum.Full,
            hasData: true,
            data: [],
        },
    };

    launches = ChainDetailsLtoSection.defaultData.launches;
    ltos = ChainDetailsLtoSection.defaultData.ltos;

    constructor(data?: API__LtoViewModel) {
        if (data) {
            this.mapFromApi(data);
        }
    }

    private setData(data: iChainDetailsLtoSection) {
        ({
            launches: this.launches,
            ltos: this.ltos,
        } = data);
    }

    mapFromApi(data: API__LtoViewModel) {
        const {
            ltos: {
                permissionLevel,
                hasData,
                data: ltos = [],
            },
        } = data;

        ltos.sort(sortByField("date", "desc"));

        this.setData({
            ltos: {
                permissionLevel: PermissionSection.getPermissionLevel(permissionLevel),
                hasData,
                data: ltos.map(i => new LTO({ apiModel: i })) || ChainDetailsLtoSection.defaultData.ltos,
            },
            launches: new LtoLaunches(data),
        });
    }
}
