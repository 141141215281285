import { FC } from "react";
import cn from "classnames";
import "./MenuDetailsButton.styles.scss";
import { MenuPartsEnum } from "../../../../../../core/models/ChainMenuData";

interface iProps {
    totalItems: number;
    label: MenuPartsEnum | string;
    isSelected?: boolean;
    isSmall?: boolean;
    isDisabled?: boolean;
    className?: string;
}

const MenuDetailsButton: FC<iProps> = ({
    totalItems,
    label,
    isSelected,
    isSmall,
    isDisabled,
    className,
 }) => {
    const classnames = cn(
        "MenuDetailsButton",
        isSelected && "is-selected",
        isSmall && "is-small",
        isDisabled && "is-disabled",
        className
    );

    return (
        <div className={classnames}>
            <div className="MenuDetailsButton__items">{totalItems}</div>
            <div className="MenuDetailsButton__label">{label}</div>
        </div>
    );
};

export default MenuDetailsButton;
