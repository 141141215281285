import Http from '../core/fetch';
import {
    HEADER_USER_ACTIONS_MENU_CLOSE,
    HEADER_USER_ACTIONS_MENU_TOGGLE,
    HEADER_DOCUMENTS_MENU_CLOSE,
    HEADER_DOCUMENTS_MENU_TOGGLE,
    HEADER_MENU_CLOSE,
    HEADER_MENU_TOGGLE,
    SEARCH_POPUP_CLOSE,
    SEARCH_POPUP_OPEN,
    SET_SEARCH_STRING,
    SET_AVAILABLE_SEARCH_TABS,
    TOGGLE_FILTER_POPUP_OPENED,
    CLEAR_SEARCH_RESULTS_STRING,
    HIDE_LOGOUT_POPUP,
    SHOW_LOGOUT_POPUP,
    HIDE_HELP_POPUP,
    SHOW_HELP_POPUP,
    HIDE_SNAP_ASSISTANT_REMINDER,
    SHOW_SNAP_ASSISTANT_REMINDER,
    OPEN_CUSTOM_RESEARCH_POPUP,
    CLOSE_CUSTOM_RESEARCH_POPUP
} from './actionsNamesList';

import { addNotification } from '../components/GlobalNotification/globalNotification.actions';
import { allItems, NavigationItems } from "@models/PlatformNavigation";

function getFeatureId(feature) {
    switch (feature) {
        case 'mtd': {
            return 5;
        }
        case 'local': {
            return 291;
        }
        case 'flavor': {
            return 950;
        }
        case allItems[NavigationItems.LaunchesAndRatings].name: {
            return 6;
        }
        case 'firefly': {
            return 2;
        }
        default: {
            return null;
        }
    }
}

export function closeSearchPopup() {
    document.body.style.overflow = '';
    return {
        type: SEARCH_POPUP_CLOSE
    };
}

export function closeHeaderMenu() {
    return {
        type: HEADER_MENU_CLOSE
    };
}

export function closeHeaderUserActionsMenu() {
    return {
        type: HEADER_USER_ACTIONS_MENU_CLOSE
    };
}

export function closeHeaderDocumentsMenu() {
    return {
        type: HEADER_DOCUMENTS_MENU_CLOSE
    };
}

export function openSearchPopup() {
    document.getElementById('search_popup_input').focus();
    document.body.style.overflow = 'hidden';
    return {
        type: SEARCH_POPUP_OPEN
    };
}

export function toggleHeaderMenu() {
    return {
        type: HEADER_MENU_TOGGLE
    };
}

export function toggleHeaderUserActionsMenu() {
    return {
        type: HEADER_USER_ACTIONS_MENU_TOGGLE
    };
}

export function toggleHeaderDocumentsMenu() {
    return {
        type: HEADER_DOCUMENTS_MENU_TOGGLE
    };
}

export function showLogoutPopup() {
    return {
        type: SHOW_LOGOUT_POPUP
    };
}

export function showHelpPopup() {
    return {
        type: SHOW_HELP_POPUP
    };
}

export function hideLogoutPopup() {
    return {
        type: HIDE_LOGOUT_POPUP
    };
}

export function hideHelpPopup() {
    return {
        type: HIDE_HELP_POPUP
    };
}

export function showSnapAssistantReminder() {
    return {
        type: SHOW_SNAP_ASSISTANT_REMINDER
    };
}

export function hideSnapAssistantReminder() {
    return {
        type: HIDE_SNAP_ASSISTANT_REMINDER
    };
}

export function setSearchString(query) {
    return {
        type: SET_SEARCH_STRING,
        query
    };
}

export function setAvailableSearchTabs(data) {
    return {
        type: SET_AVAILABLE_SEARCH_TABS,
        data
    };
}

export function filterPopupToogle() {
    return {
        type: TOGGLE_FILTER_POPUP_OPENED
    };
}

export function clearSearchString() {
    return {
        type: CLEAR_SEARCH_RESULTS_STRING
    };
}

export function openCustomResearchPopup() {
    return {
        type: OPEN_CUSTOM_RESEARCH_POPUP
    };
}

export function closeCustomResearchPopup() {
    return {
        type: CLOSE_CUSTOM_RESEARCH_POPUP
    };
}

export function requestTrialByFeature(feature) {
    return () => {
        const featureId = getFeatureId(feature);
        if (!featureId) return '';
        return Http.get('request trial', {
            featureId
        });
    };
}

export function sendHelpRequest(subject, details) {
    return (dispatch) => {
        const data = JSON.stringify({ subject, details });
        return Http.post('send help request', {}, {
            body: data,
            headers: {
                'content-type': 'application/json; charset=utf-8'
            }
        }, 'none')
        .then(() => {
            dispatch(addNotification({ text: 'Your message was sent', duration: 4000 }));
        })
        .catch(err => console.log(err));
    };
}

export function sendMobileSnapLinkOnEmail() {
    return (dispatch) => {
        return Http.post('send mail with pocket snap link', {}, {
            body: '',
        }, 'none')
        .then(() => {
            dispatch(addNotification({ text: "We've sent you email", duration: 4000 }));
        })
        .catch(err => console.log(err));
    };
}
