import React, { FC, memo, useCallback, useMemo, useState } from "react";
import { createGetYFn, getChartPoints, iViewBoxProps, iYearPoint, PointType } from "@components/Chart/Chart.helpers";
import ChartAxisX from "@components/Chart/ChartAxisX";
import ChartArea from "@components/Chart/ChartArea";
import './Chart.styles.scss';
import ChartCurve, { LineStyle } from "@components/Chart/ChartCurve";
import ChartDots from "@components/Chart/ChartDots";
import ChartTooltip from "@components/Chart/ChartTooltip/ChartTooltip";
import cn from "classnames";

const ChartPredicted: FC<{
    pastData: iYearPoint[];
    currentData: iYearPoint;
    futureData: iYearPoint[];
    width: number;
    height: number;
    printValue: (value: number) => string;
}> = (
    {
        pastData,
        currentData,
        futureData = [],
        width,
        height,
        printValue,
    }
) => {
    const [isTooltipShown, setIsTooltipShown] = useState(false);

    const viewport = useMemo<iViewBoxProps>(() => {
        const padding = {
            top: 20,
            bottom: 40,
            left: 40,
            right: 40,
        };

        return {
            width: width - padding.left - padding.right,
            height: height - padding.top - padding.bottom,
            canvasWidth: width,
            canvasHeight: height,
            padding,
            proportion: 1,
        }
    }, [width, height, futureData]);

    const allYears: iYearPoint[] = useMemo(() => [
        ...pastData,
        currentData,
        ...futureData
    ], [pastData, futureData]);

    const getY = useMemo(() => {
        return createGetYFn({
            height: viewport.height,
            chartValues: allYears.map(y => y.value),
        });
    }, [allYears, viewport]);

    const realPartWidth = useMemo(() => {
        const intervalWidth = viewport.width / (pastData.length + futureData.length);

        return intervalWidth * (pastData.length + 0.5);
    }, [pastData, futureData, viewport]);

    const allPoints = useMemo(() => {
        return getChartPoints({
            data: allYears,
            getY,
            viewport,
        });
    }, [allYears, getY, viewport]);

    const getPointType = useCallback((pointIndex): PointType => {
        if (pointIndex <= pastData.length) {
            return PointType.Solid;
        } else {
            return PointType.Dashed;
        }
    }, [pastData, currentData]);

    const showTooltip = useCallback(() => {
        setIsTooltipShown(true);
    }, [setIsTooltipShown]);

    const hideTooltip = useCallback(() => {
        setIsTooltipShown(false);
    }, [setIsTooltipShown]);

    return (
        <div
            className="Chart"
            style={{ width, height }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={viewport.width + viewport.padding.left + viewport.padding.right}
                height={viewport.height + viewport.padding.top + viewport.padding.bottom}
                viewBox={`0 0 ${viewport.width + viewport.padding.left + viewport.padding.right} ${viewport.height + viewport.padding.top + viewport.padding.bottom}`}
            >
                <g
                    name="viewport"
                    viewBox={`0 0 ${viewport.width} ${viewport.height}`}
                    style={{
                        width: viewport.width,
                        height: viewport.height,
                        transform: `translate(${viewport.padding.left}px, ${viewport.padding.top}px)`,
                        position: 'relative',
                    }}
                >
                    <clipPath id="solid">
                        <rect
                            x={0}
                            y={0}
                            height={viewport.height + viewport.padding.top + viewport.padding.bottom}
                            width={realPartWidth}
                        />
                    </clipPath>
                    <clipPath id="dashed">
                        <rect
                            x={realPartWidth}
                            y={-viewport.padding.top}
                            height={viewport.height + viewport.padding.top + viewport.padding.bottom}
                            width={viewport.width - realPartWidth}
                        />
                    </clipPath>

                    <g clipPath={'url(#solid)'}>
                        <ChartArea
                            points={allPoints}
                            color="#fff"
                            opacity={0.1}
                            viewport={viewport}
                        />
                        <ChartCurve
                            points={allPoints}
                            color="#fff"
                            lineStyle={LineStyle.Solid}
                        />
                    </g>

                    <g clipPath={'url(#dashed)'}>
                        <ChartCurve
                            points={allPoints}
                            color="#fff"
                            lineStyle={LineStyle.Dashed}
                        />
                        <ChartArea
                            points={allPoints}
                            color="#fff"
                            opacity={0.2}
                            viewport={viewport}
                        />
                    </g>

                    <ChartAxisX
                        labels={allYears.map(y => y.label)}
                        viewport={viewport}
                    />

                </g>
            </svg>
            <ChartDots
                data={allYears.map((year, yearIndex) => ({
                    x: allPoints[yearIndex].x,
                    y: allPoints[yearIndex].y,
                    label: printValue(year.value),
                    type: getPointType(yearIndex),
                    isHoverable: yearIndex === pastData.length,
                    onMouseEnter: yearIndex === pastData.length ? showTooltip : undefined,
                    onMouseLeave: yearIndex === pastData.length ? hideTooltip : undefined,
                }))}
                viewport={viewport}
            />
            <div
                className="Chart__future-label"
                style={{
                    transform: `translate(${realPartWidth + viewport.padding.left}px, ${viewport.padding.top + viewport.height + 20}px)`,
                    width: viewport.width - realPartWidth,
                }}
            >
                future
            </div>
            {!!currentData.tooltipValues && (
                <div className={cn("Chart__tooltip", isTooltipShown && "is-shown")}>
                    <ChartTooltip
                        data={currentData.tooltipValues?.map(printValue)}
                        separatorIndex={currentData.tooltipValuesSeparatorIndex}
                        labels={['Q1', 'Q2', 'Q3', 'Q4']}
                        x={allPoints[pastData.length].x + viewport.padding.left}
                        y={allPoints[pastData.length].y + viewport.padding.top}
                    />
                </div>
            )}
        </div>
    );
};

export default memo(ChartPredicted);