import ReactDOM from 'react-dom';
import { Component, createRef } from 'react';
import PT from 'prop-types';
import Scrollbar from 'react-custom-scrollbars';
import Popup from '../Popup/Popup';
import { contains } from '../../core/old_helpers';

class SelectShare extends Component {
    constructor(props) {
        super(props);

        // const HEIGHT = 480;
        // let top = 0;
        // const { clientY, popupRect, headerRect, footerRect } = this.props;
        // const { top: popupTop, bottom: popupBottom, height: popupHeight } = popupRect;
        // const { height: headerHeight } = headerRect;
        // const { height: footerHeight } = footerRect;

        // if (popupHeight - headerHeight - footerHeight < HEIGHT) {
        //     top = (popupHeight - HEIGHT) / 2;
        // } else if (popupBottom < clientY + HEIGHT) {
        //     top = popupHeight - HEIGHT - footerHeight;
        // } else {
        //     top = (clientY - popupTop);
        // }

        this.state = {
            // top,
            searchValue: '',
            selectedOption: this.props.value.shareType,
            selectedContacts: this.props.value.shareContacts,
        };

        this.inputRef = createRef();

        this.close = this.close.bind(this);
        this.save = this.save.bind(this);
        this.setRef = this.setRef.bind(this);
        this.renderOption = this.renderOption.bind(this);
        this.selectContact = this.selectContact.bind(this);
        this.renderContact = this.renderContact.bind(this);
        this.changeSearchValue = this.changeSearchValue.bind(this);
        this.handleClickOption = this.handleClickOption.bind(this);
        this.closeOnEsc = this.closeOnEsc.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.close, true);
        document.addEventListener('keydown', this.closeOnEsc, true);
        this.dropDown.focus();
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.close, true);
        document.removeEventListener('keydown', this.closeOnEsc, true);
    }

    closeOnEsc(e = false) {
        if (e.code === 'Escape') {
            this.props.close();
            e.stopPropagation();
        }
    }

    close(e = false) {
        if (!e || !ReactDOM.findDOMNode(this.dropDown).contains(e.target)) {
            this.props.close();
        }
    }

    handleClickOption(index) {
        if (index !== this.state.selectedOption) {
            this.setState({
                selectedOption: index,
                selectedContacts: new Map(),
            });
        }
    }

    selectContact(contact) {
        const { selectedContacts } = this.state;
        this.setState({
            selectedContacts: new Map([
                ...Array.from(selectedContacts.keys()).map(key => [key, selectedContacts.get(key)]),
                [contact.value, { ...contact }],
            ]),
        });
    }

    deselectContact(value) {
        const { selectedContacts } = this.state;
        this.setState({
            selectedContacts: new Map([
                ...Array.from(selectedContacts.keys())
                    .filter(key => key !== value)
                    .map(key => [key, selectedContacts.get(key)]),
            ]),
        });
    }

    reselectContact(contact) {
        const { selectedContacts } = this.state;

        if (selectedContacts.has(contact.value)) {
            this.deselectContact(contact.value);
        } else {
            this.selectContact(contact);
        }
    }

    renderContact(contact, index) {
        const { selectedContacts } = this.state;
        const { title, subTitle, initials, value } = contact;
        const classList = new Set([
            'drop-down-contact',
        ]);

        if (selectedContacts.has(value)) {
            classList.add('drop-down-contact--checked');
        }

        return (
            <div
                onClick={() => this.reselectContact(contact)}
                key={index}
                className={Array.from(classList).join(' ')}
                data-testid={`buttonManageDocumentPermissionsPopupSelectContactsOption-${index}`}
            >
                <div
                    className="drop-down-contact__title"
                    title={selectedContacts.has(value) ? title : ''}
                    data-testid="textManageDocumentPermissionsPopupContactTitle"
                >
                    {title}
                </div>
                <div
                    className="drop-down-contact__sub-title"
                    data-testid="textManageDocumentPermissionsPopupContactSubTitle"
                >
                    {subTitle}
                </div>
            </div>
        );
    }

    renderContacts() {
        const { contacts } = this.props;
        const { searchValue } = this.state;
        const filteredContacts = contacts.filter((contact) => {
            if (searchValue === '') {
                return true;
            }
            return contains(contact.title, searchValue)
                || contains(contact.subTitle, searchValue);
        });

        return (
            <div
                className="custom-drop-down__scrollbar-outer"
                data-testid="selectManageDocumentPermissionsPopupContacts"
            >
                {filteredContacts.length > 0 ? (
                    <Scrollbar autoHide autoHideTimeout={1000}>
                        <div className="custom-drop-down__scrollbar-inner">
                            {filteredContacts.map(this.renderContact)}
                        </div>
                    </Scrollbar>
                ) : (
                    <div
                        className="custom-drop-down__msg"
                        data-testid="textManageDocumentPermissionsPopupSelectContactsNoMatches"
                    >
                        no matches
                    </div>
                )}
            </div>

        );
    }

    renderSelectedOptionView() {
        const { searchValue } = this.state;
        const { selectedOption } = this.state;
        const text = selectedOption === 1 ?
            'The following people can edit this document:' :
            'The following people can view this document:';
        setTimeout(() => this.inputRef.current.focus(), 200);
        return (
            <div className="custom-drop-down__search-box">
                <div
                    className="custom-drop-down__search-title"
                    data-testid="textManageDocumentPermissionsPopupResultsTitle"
                >
                    {text}
                </div>
                <input
                    ref={this.inputRef}
                    value={searchValue}
                    onChange={this.changeSearchValue}
                    className="custom-drop-down__input custom-drop-down__input--margin"
                    placeholder="name, group name or email"
                    data-testid="inputManageDocumentPermissionsPopupSearchContacts"
                />
                {this.renderContacts()}
            </div>
        );
    }

    renderOption(option, index) {
        const { selectedOption } = this.state;
        const labelClassList = new Set([
            'custom-drop-down__option-header',
        ]);
        const isSelected = option.value === selectedOption;

        if (isSelected) {
            labelClassList.add('custom-drop-down__option-header--selected');
        }

        return (
            <div
                key={index}
                className="custom-drop-down__radio"
                onClick={() => this.handleClickOption(index)}
                data-testid={`buttonManageDocumentPermissionsPopupOption-${index}`}
            >
                <div className={Array.from(labelClassList).join(' ')}>
                    <div className="custom-drop-down__label">{option.title}</div>
                </div>
            </div>
        );
    }

    renderOptions() {
        const { options } = this.props;
        const { selectedOption } = this.state;
        const wrapStyle = (selectedOption !== 0 ? {height: '192px', transition: 'height 100ms', padding: '10px 25px 15px',
                borderTop: '1px solid #E3E3E3'} :
            {height: '0', borderTop: '0', padding: '0', transition: 'height 100ms'});

        return (<div>
                <div
                    className="custom-drop-down__main"
                    data-testid="buttonManageDocumentPermissionsPopupOptions"
                >
                    {options.map(this.renderOption)}
                </div>
                <div className='custom-drop-down__searchbox-wrap' style={wrapStyle}>
                    {selectedOption !== 0 && this.renderSelectedOptionView()}
                </div>
            </div>
        );
    }

    changeSearchValue(event) {
        this.setState({ searchValue: event.target.value });
    }

    setRef(dropDown) {
        this.dropDown = dropDown;
    }

    save() {
        this.props.setValue({
            shareType: this.state.selectedOption,
            shareContacts: this.state.selectedContacts,
        });
        this.props.close();
    }

    render() {
        const { selectedOption, selectedContacts, } = this.state;
        const { isViewFieldRequired} = this.props;
        return (
            <Popup isOpened={true}>
                <div
                    ref={this.setRef}
                    className="custom-drop-down custom-drop-down--wide custom-drop-down--share"
                >
                    {this.renderOptions()}
                    <div className="custom-drop-down__footer">
                        <div className="popup__right-side">
                            <button
                                onClick={this.props.close}
                                className="popup__link popup__link--comfortaa"
                                data-testid="buttonManageDocumentPermissionsPopupCancel"
                            >
                                cancel
                            </button>
                            <button
                                disabled={isViewFieldRequired && selectedOption === 2 && selectedContacts.size === 0}
                                onClick={this.save}
                                className="popup__btn popup__btn--xmd"
                                data-testid="buttonManageDocumentPermissionsPopupSave"
                            >
                                SAVE
                            </button>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}

SelectShare.propTypes = {
    value: PT.object,
    close: PT.func,
    setValue: PT.func,
    clientY: PT.number,
    popupRect: PT.object,
    headerRect: PT.object,
    footerRect: PT.object,
    options: PT.array,
    contacts: PT.array,
};

export default SelectShare;
