import React, { FC, useContext, useMemo } from "react";
import useChainDetailsLto from "../../../../core/apiHooks/useChainDetailsLto";
import SectionHeader from "../../../../components/new/SectionHeader/SectionHeader";
import LTOItem from "../../../_shared/LTOItem/LTOItem";
import Launches from "./Launches/Launches";
import "./LTO.styles.scss";
import Button, { ButtonTypeEnum } from "../../../../components/new/Button/Button";
import SectionContent from "../../../../components/new/SectionContent/SetionContent";
import { ReportsNameEnum } from "../../../../core/models/ChainDetails";
import { AppContext } from "../../../../AppContext";
import { isAvailable, PermissionLevelEnum } from "../../../../core/models/PermissionSection";
import { ChainDetailsDataContext } from "../../ChainPage.helpers";
import { sanitizeUrl } from "../../../../core/helpers";
import { Grid } from "../../../../components/new/Grid/Grid";

const LtoTile: FC = () => {
    const { globalVariables: { productsConfig } , } = useContext(AppContext);
    const {
        data: {
            mainData: {
                id: chainId,
            },
            reportsData,
        },
        downloadReports,
    } = useContext(ChainDetailsDataContext);

    const {
        data: {
            launches,
            ltos: {
                permissionLevel: ltoPermissionLevel,
                data: ltoItems
            },
        },
        isLoading,
    } = useChainDetailsLto(chainId);

    const scoresLink = productsConfig.find(i => i.title.toLowerCase().indexOf('launches') >= 0)?.url;
    const scoresChainPerformanceMoreLink = useMemo(() => {
        return scoresLink ? sanitizeUrl(`${scoresLink}/chain-performance/${chainId}`) : ""
    }, [chainId, scoresLink]);
    const scoresItesmByChainLink = useMemo(() => {
        return scoresLink ? sanitizeUrl(`${scoresLink}/search?chainId=${chainId}`) : ""
    }, [chainId, scoresLink]);

    const report = reportsData.find(i => i.data.name === ReportsNameEnum.LTOActivity);
    const isReportDownloadAvailable = !!report && isAvailable(report);

    return (
        <div className="LTO">
            <SectionHeader title="Menu Launches & LTOs">
                { !isLoading && (
                    <div className="Launches__download">
                        {isReportDownloadAvailable && (
                            <Button
                                modifiers={["primary"]}
                                onClick={() => downloadReports([ReportsNameEnum.LTOActivity])}
                            >
                                DOWNLOAD ACTIVITY REPORT
                            </Button>
                        )}
                    </div>
                )}
            </SectionHeader>
            <SectionContent isLoading={isLoading && ltoItems.length === 0}>
                <div className="LTO__launches">
                    <Launches data={launches}/>

                    {!!scoresLink && launches.permissionLevel === PermissionLevelEnum.Full && (
                        <div className="LTO__launches-actions">
                            <Button
                                type={ButtonTypeEnum.Anchor}
                                className="LTO__launches-actions-item"
                                link={scoresChainPerformanceMoreLink}
                            >
                                View more
                            </Button>
                        </div>
                    )}
                </div>
                <div className="LTO__title">
                    LTOs
                </div>
                <div className="LTO__grid">
                    <Grid maxRowsCount={3}>
                        {ltoItems.map(item => ({
                            key: item.id,
                            value: <LTOItem data={item} location="chain page"/>,
                        }))}
                    </Grid>
                </div>
                {!!scoresLink && ltoPermissionLevel === PermissionLevelEnum.Full && (
                    <div className="LTO__actions">
                        <Button
                            type={ButtonTypeEnum.Anchor}
                            className="LTO__actions-item"
                            link={scoresItesmByChainLink}
                        >
                            View more
                        </Button>
                    </div>
                )}
            </SectionContent>
        </div>
    );
};

export default LtoTile;
