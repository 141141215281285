import { FC } from "react";
import cn from "classnames";
import { AnalyticsAttribute, getAnalyticsComputedData } from "@models/AnalyticsAttributes";

interface iTab {
    label: string;
    onClick: () => void;
    isActive?: boolean;
}

const ChartTabs: FC<{
    data: iTab[];
}> = (
    {
        data,
    }
) => {
    return (
        <div
            className="ChartTabs"
            data-testId="buttonChartTabs"
        >
            {data.map(((tab, index) => (
                <div
                    key={tab.label}
                    className={cn('ChartTab', tab.isActive && 'ChartTab--active')}
                    onClick={tab.onClick}
                    {...getAnalyticsComputedData({
                        [AnalyticsAttribute.ClickTitle]: 'Segment Filters',
                    })}
                    data-testId={`buttonChartTabsItem${index}`}
                >
                    {tab.label}
                </div>
            )))}
        </div>
    );
};

export default ChartTabs;