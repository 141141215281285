import { useData } from "@hooks/useData";
import http from '@core/fetch';
import { API__InstantChartDetailsModel } from "@apiSchema";
import { ID } from "@models";
import InstantChart, { iInstantChart } from "@models/InstantChart";

async function getInstantChart(id: ID): Promise<iInstantChart> {
    const result: API__InstantChartDetailsModel = await http.getXHR('get instant chart', { id });
    try {
        return new InstantChart({ apiModel: result });
    }
    catch (err) {
        console.error(err);
        return new InstantChart();
    }
}

export default function useInstantChart(id: ID) {
    return useData(new InstantChart(), getInstantChart, id);
}