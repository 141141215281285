import { FC } from "react";
import { getFormattedCurrency } from "../../../../../../core/helpers";
import { iMenuExample } from "../../../../../../core/models/MenuExamples";
import "./MenuExamplesItem.styles.scss";

interface iProps {
    data: iMenuExample;
}

const MenuExamplesItem: FC<iProps> = ({ data }) => {
    return (
        <div className="MenuExamplesItem">
            <div className="MenuExamplesItem__header">
                <div className="MenuExamplesItem__title">{data.title}</div>
                {!!data.price && (
                    <div className="MenuExamplesItem__price">
                        {getFormattedCurrency(data.price)}
                    </div>
                )}
            </div>
            {data.description && <div className="MenuExamplesItem__description">{data.description}</div>}
        </div>
    );
};

export default MenuExamplesItem;
