import {FC} from "react";
import {iFoodProfileNavigationItem} from "@pages/FoodProfile/FoodProfile.helpers";
import Scroll from "react-scroll";
import { AnalyticsAttribute, getAnalyticsComputedData } from "@models/AnalyticsAttributes";

const {Link: ScrollLink} = Scroll;

const FoodProfileNavLink: FC<{
    data: iFoodProfileNavigationItem;
}> = (
    {
        data,
    }
) => {
    return (
        <ScrollLink
            className="SideNavigationButton clickable"
            activeClass="active"
            containerId="foodScrollbarView"
            to={data.id}
            spy={true}
            isDynamic={true}
            smooth={true}
            offset={data.yOffset}
            duration={300}
            {...getAnalyticsComputedData({
                [AnalyticsAttribute.ClickTitle]: 'Food Profile - Sidebar',
            })}
            data-testid={data.testId}
        >
            {data.title}
        </ScrollLink>
    );
};
export default FoodProfileNavLink;