import {
    SET_EXPANDED_DROPDOWN,
    RESET_EXPANDED_DROPDOWN
} from '../../actions/actionsNamesList';



const defaultState = {
    activeDropdown: null
};

export default function dropdownData(state = defaultState, action) {
    switch (action) {
        case SET_EXPANDED_DROPDOWN:
            return {
                ...state,
                activeDropdown: action.id
            };
        case RESET_EXPANDED_DROPDOWN:
            return {
                ...state,
                activeDropdown: null
            };
        default: {
            return { ...state };
        }
    }
}
