import { useCallback, useEffect, useState } from "react";
import useNotes from "../../../core/apiHooks/useNotes";
import { ID } from "../../../core/models";
import { iNote } from "../../../core/models/Note";
import Http from "../../../core/fetch";

const useNotesData = (chainId?: ID) => {
    const {
        data: {
            data: notesData = []
        },
        isLoading,
        reFetch,
        isSuccess
    } = useNotes(chainId);
    const [notes, setNotes] = useState<iNote[]>([]);
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        if ( !isLoading) {
            setNotes(notesData);
            setIsUpdating(false);
        }
    }, [notesData, isLoading]);

    const saveNote = useCallback((item: iNote, chainId: ID, cb: () => void = () => {}) => {
        setIsUpdating(true);

        Http.post('save chain note', {}, {
            body: JSON.stringify({
                ChainId: chainId,
                data: item
            }),
            headers: {
                'content-type': 'application/json; charset=utf-8',
            },
        }, "none")
            .then(() => {
                reFetch();
                cb();
                setIsUpdating(false);
            })
            .catch(() => {
                setIsUpdating(false);
            });
    }, [reFetch]);

    const removeNote = useCallback((id: number) => {
        setIsUpdating(true);

        Http.post('remove chain note', { noteId: id }, {}, "none")
            .then(() => {
                reFetch();
            })
            .catch(() => {
                setIsUpdating(false);
            });
    }, [reFetch]);

    return {
        data: notes,
        isLoading,
        isUpdating,
        isSuccess,
        saveNote,
        removeNote,
        reFetch
    };
};

export default useNotesData;
