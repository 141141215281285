import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dashboardImageSrcSelector } from '@selectors/page';
import { openSearchPopup, closeSearchPopup, sendMobileSnapLinkOnEmail } from '@actions/page';
import PageContent from '../../containers/PageContent/PageContent';
import { randomizeInRange } from '@core/old_helpers';
import Page from '../../containers/Page/Page';
import TypeWriter from '../../libs/react-typewriter';
import './Homepage.scss';
import ReportProNewReleases from "@pages/Homepage/ReportProNewReleases/ReportProNewReleases";
import { PlatformContext, SearchOnKeyDownInit } from "@datassential/platform-ui-lib";
import cn from "classnames";
import { isPackagesPopupOpenedSelector } from "@selectors/packages.selectors";

const BGS = window.pageData.dashboardImagesSrc;

const TYPE_STRINGS = [
    'gochujang',
    'sandwich chains',
    'healthy trends',
    'Just start typing',
    'greek foods',
    'pizza varieties',
    'zaatar',
    'Just start typing',
    'Chicago fast-casual',
    'Publix',
    'Fuzzy’s Taco Shop',
    'Just start typing',
    'organic',
    'ethnic',
    'dessert flavors',
    'Just start typing',
    'top c-stores',
    'healthcare',
    'GPO',
    'Just start typing',
    'elote',
    'Korean BBQ',
    'Hyatt Regency',
    'Just start typing',
    'Los Angeles chains',
    'Nobu',
    'Shake Shack',
    'Just start typing',
    'plant-based',
    'Miami QSR',
    'Texas BBQ chains',
    'Just start typing',
    'Peruvian',
    'tandoori',
    'charred',
    'Just start typing',
    'Mexican fast-casual',
    'Pizza Hut',
    'Olive Garden',
    'Just start typing',
    'Walmart',
    'Kroger',
    'Whole Foods',
    'Just start typing',
    'Keynote reports',
    'Dine Around',
    'FoodBytes',
    'Just start typing',
    'Habanero',
    'Tzatziki',
    'Just start typing',
    'Sandwich cheeses',
    'craft beer',
    'juice flavors',
    'Just start typing',
    'whiskey',
    'mocktails',
    'shawarma'
];

class TypeArray extends Component {
    constructor(props) {
        super(props);

        this.state = {
            typeString: 'Just start typing',
            typingDirection: 1
        };

        this.getNewWord = this.getNewWord.bind(this);
        this.changeTypeString = this.changeTypeString.bind(this);
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    getNewWord() {
        const newWord = TYPE_STRINGS[randomizeInRange(0, TYPE_STRINGS.length)];
        if (newWord === this.state.typeString) {
            return this.getNewWord(this.state.typeString);
        }

        return newWord;
    }

    changeTypeString() {
        const { typingDirection } = this.state;
        if (typingDirection === 1) {
            this.timeout = setTimeout(() => {
                this.setState({
                    typingDirection: -1
                });
            }, 2000);
        } else {
            this.setState({
                typeString: this.getNewWord(),
                typingDirection: 1
            });
        }
    }

    render() {
        return (
            <div className="search-widget__searchfield font-text font-search">
                <TypeWriter
                    minDelay={70}
                    maxDelay={200}
                    initDelay={0}
                    onTypingEnd={this.changeTypeString}
                    typing={this.state.typingDirection}
                >
                    <div className="search-widget__caret-helper">
                        {this.state.typeString}
                    </div>
                </TypeWriter>
            </div>
        );
    }
}

class Homepage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSliderPlaying: true,
            imageSrc: BGS[Math.floor(Math.random() * BGS.length)],
            nextImageSrc: BGS[Math.floor(Math.random() * BGS.length)],
            changing: false,
            isSearchWidgetShown: false,
            showGetSnapNotice: false,
        };
    }

    componentDidMount() {
        this.initialTimer = setTimeout(this.changeImage.bind(this), 5000);
        setTimeout(this.setState.bind(this, { isSearchWidgetShown: true }), 2000);
    }

    componentWillUnmount() {
        clearTimeout(this.sliderTimer);
        clearTimeout(this.slideTimer);
        clearTimeout(this.slideTimer1);
        clearTimeout(this.slideTimer2);
        clearTimeout(this.pauseTimer);
        clearTimeout(this.initialTimer);
        this.props.closeSearchPopup();
    }

    openPopup() {
        this.context.router.history.push('/search');
    }

    changeImage() {
        if (this.state.isSliderPlaying) {
            this.setState({
                changing: true
            });

            this.slideTimer = setTimeout(() => {
                this.setState({
                    imageSrc: this.state.nextImageSrc
                });
            }, 1000);

            this.slideTimer1 = setTimeout(() => {
                this.setState({
                    changing: false
                });
            }, 3000);

            this.slideTimer2 = setTimeout(() => {
                this.setState({
                    nextImageSrc: BGS[Math.floor(Math.random() * BGS.length)]
                });
            }, 5000);

            this.sliderTimer = setTimeout(this.changeImage.bind(this), 10000);
        }
    }

    renderSearchWidget() {
        const {
            globalSearch,
        } = this.context;

        const isWidgetExpanded = globalSearch.isOpened;

        return (
            <div
                className={cn(
                    'search-widget',
                    'search-widget--filled',
                    'clickable',
                    isWidgetExpanded && 'search-widget--collapse',
                )}
                onClick={() => globalSearch.setIsOpened(true)}
                data-testid="buttonHomepageOpenSearch"
            >
                <div className="search-widget__bg-hover">
                    <div className="search-widget__bg"/>
                </div>
                <div className="search-widget__border-mask">
                    <div className="search-widget__border-hover">
                        <div className="search-widget__border"/>
                    </div>
                </div>
                <div className="search-widget__content">
                    <div className="search-widget__title font-search">
                        discover foods, places,<br/> articles, and more
                    </div>
                    <TypeArray/>
                </div>
            </div>
        );
    }

    render() {
        const {
            isSearchPopupOpened: collapseWidget,
            isPackagesPopupOpened,
        } = this.props;
        const { imageSrc, nextImageSrc, changing, shouldPopupShow, shouldCovidPopupShow, shouldFFChainsPopup } = this.state;
        const classList = new Set(['page-content--collapsed', 'page-content--homepage']);
        if (collapseWidget) {
            classList.add('page-content--over');
        }

        return (
            <Page listenKeyDown={true}>
                {!isPackagesPopupOpened && <SearchOnKeyDownInit/>}
                <div>
                    <PageContent className={Array.from(classList).join(' ')}>
                        <div
                            className={`homepage__content ${changing ? 'homepage__content--out' : ''}`}
                            style={{ backgroundImage: `url(${imageSrc})` }}
                        />
                        <div
                            className={`homepage__content homepage__content--next ${changing ? 'homepage__content--in' : ''}`}
                            style={{ backgroundImage: `url(${nextImageSrc})` }}
                        />
                        {this.state.isSearchWidgetShown && this.renderSearchWidget()}
                        <ReportProNewReleases/>
                    </PageContent>
                </div>
            </Page>
        );
    }
}

Homepage.propTypes = {
    imageSrc: PropTypes.string,
    openSearchPopup: PropTypes.func,
    isSearchPopupOpened: PropTypes.bool,
    closeSearchPopup: PropTypes.func
};

Homepage.contextType = PlatformContext;

const mapStateToProps = state => ({
    imageSrc: dashboardImageSrcSelector(state),
    isSearchPopupOpened: state.pageStatus.isSearchPopupOpened,
    isPackagesPopupOpened: isPackagesPopupOpenedSelector(state),
});

const mapDispatchToProps = dispatch => ({
    openSearchPopup: () => dispatch(openSearchPopup()),
    closeSearchPopup: () => dispatch(closeSearchPopup()),
    sendMobileSnapLinkOnEmail: () => dispatch(sendMobileSnapLinkOnEmail()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
