import { useData } from "../hooks/useData";
import Http from "../fetch";
import { API__ProfileLtoViewModel } from "../apiSchema";
import { iFoodLtoSection, FoodLtoSection } from "../models/FoodLtoSection";

async function getLtos(word: string): Promise<iFoodLtoSection> {
    const response: API__ProfileLtoViewModel = await Http.get("food profile lto", { word });
    return new FoodLtoSection(response);
}

export default function useFoodLto(searchQuery: string) {
    return useData(new FoodLtoSection(), getLtos, searchQuery);
}
