import { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { capitalize, contains, makeEnding } from '../../core/old_helpers';
import { Scrollbars } from 'react-custom-scrollbars';
import serializeParams from '../../core/serializeParams';
import getFileSize from '../../core/getFileSize';
import Table from '../../components/Table/Table';
import Icon from '../../components/SpriteIcon/SpriteIcon';
import Page from '../../containers/Page/Page';
import PageContent from '../../containers/PageContent/PageContent';
import SharePopup from '@pages/_shared/SharePopup/SharePopup';
import { addNotification } from '../../components/GlobalNotification/globalNotification.actions';
import { isImpersonateModeSelector } from '../../selectors/page';

import { startDeleting as startDeletingProject } from '../../containers/ProjectDeletePopup/projectDeletePopup.actions';
import { startEditing as openDocumentEditPopup } from '../../containers/DocumentEditPopup/documentEditPopup.actions';
import { shareDataSelector } from '../../selectors/documents.selectors';
import { addFileToPackage } from '../../actions/packages.actions';
import { setData, open as openProjectEditPopup } from '../../containers/ProjectEditPopup/projectEditPopup.actions';
import { open as openProjectCreatePopup } from '../../containers/ProjectCreatePopup/projectCreatePopup.actions';
import {
    setPageToReload,
    setCustomerViewCompanyId
} from '../../containers/DocumentsLibraryPopups/documentsLibraryPopups.actions';
import { startDeleting as startDeletingDocument } from '../../containers/DocumentDeletePopup/documentDeletePopup.actions';
import {
    open as openDocumentsAddPopup,
    setActiveProjectId
} from '../../containers/DocumentsAddPopup/documentsAddPopup.actions';
import {
    companiesSelector,
    companyIdSelector
} from '../../containers/DocumentsLibraryPopups/documentsLibraryPopups.selectors';
import { setSharePopupState } from '../../actions/documents.actions';

import {
    projectsSelector,
    documentTypesSelector,
    documentsCountSelector,
    projectsTableSelector,
    searchResultsTableSelector
} from './myDocuments.selectors';
import { getData, storeSearchQuery, getSearchResults, clearData, storeData } from './myDocuments.actions';
import ProjectTile from './ProjectTile';
import CompaniesListDropDown from './CompaniesListDropDown';
import './MyDocuments.scss';
import './companies-list-drop-down.scss';
import getFormattedThousand from '../../core/getFormattedThousand';

class MyDocuments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchValue: '',
            viewMode: 'tile',
            isCompanyListOpened: false
        };
        this.changeSearchValue = this.changeSearchValue.bind(this);
        this.handleSearchFormSubmit = this.handleSearchFormSubmit.bind(this);
        this.closeCompaniesListDropDown = this.closeCompaniesListDropDown.bind(this);
    }

    componentDidMount() {
        const {
            companyId, storeData, storeSearchQuery, getData, setPageToReload, getSearchResults, setCustomerViewCompanyId
        } = this.props;

        const searchString = new window.URLSearchParams(this.props.location.search);
        const query = decodeURIComponent(searchString.get('q'));
        const customerViewCompanyId = +searchString.get('companyId');
        const isShareMode = searchString.get('isShareMode');

        if (companyId === 1 && customerViewCompanyId === 0) {
            if (isShareMode === 'true') {
                storeData({ projectsViewMode: 'shared' });
            } else {
                storeData({ projectsViewMode: 'internal' });
            }
        }
        storeData({
            customerViewCompanyId: customerViewCompanyId || companyId,
            isCustomerViewMode: customerViewCompanyId !== 0
        });
        if (customerViewCompanyId) {
            setCustomerViewCompanyId(customerViewCompanyId);
        }
        if (query !== 'null') {
            this.setState({ query });
            storeSearchQuery(query);
            Promise.all([getSearchResults(), getData()])
                .then(() => {
                    storeData({ dataLoaded: true });
                });
        } else {
            storeSearchQuery('');
            getData()
                .then(() => {
                    storeData({ dataLoaded: true });
                });
        }
        setPageToReload('myDocuments');
    }

    componentWillUnmount() {
        const { clearData, setCustomerViewCompanyId, setPageToReload, setActiveProjectId } = this.props;
        clearData();
        setCustomerViewCompanyId(null);
        setPageToReload('myDocuments');
        setActiveProjectId(null);
    }

    changeSearchValue(event) {
        this.setState({
            searchValue: event.target.value
        });
    }

    handleSearchFormSubmit(event) {
        event.preventDefault();
        const { isCustomerViewMode, customerViewCompanyId } = this.props;
        const { searchValue } = this.state;
        if (searchValue !== '') {
            const params = {
                q: searchValue
            };
            if (isCustomerViewMode) {
                params.companyId = customerViewCompanyId;
            }
            this.props.history.push(`/myDocuments?${serializeParams(params)}`);
        }
    }

    setViewMode(type) {
        this.setState({
            viewMode: type
        });
    }

    setProjectsViewMode(projectsViewMode) {
        this.props.storeData({ projectsViewMode });
    }

    closeCompaniesListDropDown() {
        this.setState({ isCompanyListOpened: false });
    }

    renderSearchResults() {
        const { searchResultsTable } = this.props;

        if (searchResultsTable.data.length === 0) {
            return <p data-testid="testMyDocsSearchResultsNoResultsMessage">Project not found</p>;
        }

        return <Table
            className={'table-container--my-documents'}
            data={searchResultsTable.data}
            settings={searchResultsTable.settings}
            editProject={this.props.editProject}
            deleteProject={this.props.deleteProject}
            sortType="client"
            showCopyButton={false}
            addFileToPackage={this.props.addFileToPackage}
            openSharePopup={this.props.setSharePopupState}
            editDocument={this.props.editDocument}
            deleteDocument={this.props.deleteDocument}
            showNotification={this.props.showNotification}
            testId="tableMyDocsSearchResults"
        />;
    }

    renderProjects() {
        const { projects, createProject, customerViewCompanyId, isCustomerViewMode, projectsViewMode, isImpersonateMode } = this.props;
        const { searchValue } = this.state;
        const isShareViewMode = projectsViewMode === 'shared';
        const isInternalViewMode = projectsViewMode === 'internal';
        const filteredProjects = projects
            .filter(({ name }) => contains(name, searchValue))
            .filter(({ isShared }) => !isShareViewMode || isShared)
            .filter(({ isInternal }) => !isInternalViewMode || isInternal)
            .sort((a, b) => {
                if (isShareViewMode) {
                    return a.sharedDocumentsCount < b.sharedDocumentsCount ? 1 : -1;
                } else if (isInternalViewMode) {
                    return a.internalDocumentsCount < b.internalDocumentsCount ? 1 : -1;
                }
                return a.documentsCount < b.documentsCount ? 1 : -1;
            });
        if (isCustomerViewMode && filteredProjects.length === 0) {
            return <p data-testid="testMyDocsViewTilesNoResultsMessage">Project not found</p>;
        }
        return (
            <div
                className="my-documents__projects-list"
                data-testid="blockMyDocsProjects"
            >
                { !isCustomerViewMode && !isImpersonateMode && (
                    <div
                        onClick={createProject}
                        className="my-documents-projects-grid__item my-documents-project-tile fadeInUp"
                        data-testid="buttonMyDocsCreateNewProject"
                    >
                        <div className="my-documents-project-tile__button">
                            <div className="my-documents-project-tile__button--inner">
                                <div className="my-documents-project-tile__icon-plus"/>
                                <div
                                    className="my-documents-project-tile__title my-documents-project-tile__title--big"
                                    data-testid="textMyDocsCreateNewProject"
                                >
                                    Create New Project
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {filteredProjects.map(
                    ({
                         id, name, formattedDate, totalDocumentSize = 0, documentsCount, sharedWithCount, isInternal,
                         isModified, initialName, color, internalDocumentFilesSize, internalDocumentsCount,
                         sharedDocumentFilesSize, sharedDocumentsCount, index
                     }, inx) => {
                        const size = getFileSize(totalDocumentSize);
                        const internalSize = getFileSize(internalDocumentFilesSize);
                        const sharedSize = getFileSize(sharedDocumentFilesSize);

                        const params = { id };
                        if (isCustomerViewMode) {
                            params.companyId = customerViewCompanyId;
                        }
                        if (isShareViewMode) {
                            params.isShareMode = true;
                        }

                        let leftTopText = 'created';
                        let leftBottomText = `${formattedDate}`;
                        let rightTopText = `${documentsCount} ${makeEnding('file', documentsCount)}`;
                        let rightBottomText = size.classicFormat;
                        if (isShareViewMode) {
                            leftTopText = `created ${formattedDate}`;
                            leftBottomText = `shared with ${sharedWithCount} ${makeEnding('company', sharedWithCount)}`;
                            rightTopText = `${sharedDocumentsCount} ${makeEnding('file', sharedDocumentsCount)}`;
                            rightBottomText = sharedSize.classicFormat;
                        } else if (isInternalViewMode) {
                            rightTopText = `${internalDocumentsCount} ${makeEnding('file', internalDocumentsCount)}`;
                            rightBottomText = internalSize.classicFormat;
                        }
                        const isBlocked = isCustomerViewMode && isInternal;
                        return (
                            <ProjectTile
                                key={index}
                                name={name}
                                color={color}
                                initialName={initialName}
                                url={`/project?${serializeParams(params)}`}
                                isModified={isModified && isCustomerViewMode}
                                isBlocked={isBlocked}
                                leftTopText={leftTopText}
                                leftBottomText={leftBottomText}
                                rightTopText={rightTopText}
                                rightBottomText={rightBottomText}
                                testId={`buttonMyDocsProjectTile-${inx}`}
                            />
                        );
                    })}
            </div>
        );
    }

    renderProjectsTable() {
        const { projectsTable, projectsViewMode } = this.props;
        const { searchValue } = this.state;
        const isShareViewMode = projectsViewMode === 'shared';
        const isInternalViewMode = projectsViewMode === 'internal';
        const filteredProjectsTableData = projectsTable.data
            .filter(({ 0: project }) => {return contains(project.value, searchValue);})
            .filter(({ 0: project }) => !isShareViewMode || project.data.isShared)
            .filter(({ 0: project }) => !isInternalViewMode || project.data.isInternal);

        if (filteredProjectsTableData.length === 0) {
            return <p data-testid="testMyDocsViewTableNoResultsMessage">Project not found</p>;
        }

        return (
            filteredProjectsTableData.length > 0 && (
                <Table
                    className={'table-container--my-documents'}
                    data={filteredProjectsTableData}
                    settings={projectsTable.settings}
                    editProject={this.props.editProject}
                    deleteProject={this.props.deleteProject}
                    sortType="client"
                    sortingColumn={1}
                    sortingDirection="desc"
                    showCopyButton={false}
                    showNotification={this.props.showNotification}
                    testId="tableMyDocsProjects"
                />
            )
        );
    }

    renderTypes() {
        const { documentTypes, isCustomerViewMode, customerViewCompanyId, projectsViewMode } = this.props;

        return (
            <div className="my-documents-types">
                {documentTypes.map((type, index) => {
                    const filesCount = type.documentTypeDocumentsCount;
                    let url = type.documentTypeIds.reduce(
                        (memo, doctypeId) => `${memo}id=${doctypeId}&`,
                        '/documentType?'
                    );
                    if (projectsViewMode === 'shared') {
                        url = `${url}isShareMode=true&`;
                    }
                    if (isCustomerViewMode) {
                        url = `${url}companyId=${customerViewCompanyId}`;
                    }

                    return (
                        <NavLink
                            key={index}
                            to={url}
                            className="my-documents-types__item font-text"
                            data-testid={`buttonMyDocsDocType-${index}`}
                        >
                            <div
                                className="my-documents-types__count"
                                data-testid="textMyDocsDocTypeFilesCount"
                            >
                                {getFormattedThousand(filesCount)} {makeEnding('file', filesCount)}
                            </div>
                            {capitalize(type.documentTypeName)}
                        </NavLink>
                    );
                })}
            </div>
        );
    }

    renderSidebar() {
        const { documentsCount, projects } = this.props;
        return (
            <div className="my-documents__sidebar">
                <div
                    className="my-documents__sub-title my-documents__sub-title--in-sidebar"
                    data-testid="textMyDocsSidebarTitle"
                >
                    Your documents are grouped by project. You can create a new project or explore the ones here.
                    <br/>
                    <div>
                        <b data-testid="textMyDocsFilesCount"> {getFormattedThousand(documentsCount)} {makeEnding('file', documentsCount)} </b>
                        across
                        <b data-testid="textMyDocsProjectsCount"> {getFormattedThousand(projects.length)} {makeEnding('project', projects.length)}: </b>
                    </div>
                </div>
                <div className="my-documents__input-container my-documents__input-container--in-sidebar">
                    <form onSubmit={this.handleSearchFormSubmit} className="relative">
                        <input
                            className="my-documents__search my-documents__search--in-sidebar"
                            type="search"
                            placeholder="Find"
                            onChange={this.changeSearchValue}
                            value={this.state.searchValue}
                            data-testid="inputMyDocsSearch"
                        />
                        { this.state.searchValue.length ? (
                            <button
                                className="my-documents__submit-btn"
                                data-testid="buttonMyDocsSearch"
                            >
                                <Icon iconId="back_arrow" className="btn-icon-svg"/>
                            </button>
                        ) : null }
                        <Icon
                            width="18"
                            height="18"
                            iconId="search"
                            className="my-documents__search-icon"
                        />
                    </form>
                </div>
                {this.renderTypes()}
            </div>

        );
    }

    renderProjectsViewModeTabs() {
        const { projectsViewMode } = this.props;
        const internalBtnClassList = new Set(['my-documents__tab font-search']);
        const sharedBtnClassList = new Set(['my-documents__tab font-search']);
        if (projectsViewMode === 'internal') {
            internalBtnClassList.add('my-documents__tab--active');
        }
        if (projectsViewMode === 'shared') {
            sharedBtnClassList.add('my-documents__tab--active');
        }
        return (
            <div className="my-documents__tabs">
                <div
                    onClick={() => {
                        const url = window.location.href
                            .replace('&isShareMode=true', '')
                            .replace('isShareMode=true&', '')
                            .replace('?isShareMode=true', '');
                        window.history.replaceState(null, '', url);
                        this.setProjectsViewMode('internal');
                    }}
                    className={Array.from(internalBtnClassList).join(' ')}
                    data-testid="buttonMyDocsViewInternal"
                >
                    internal
                </div>
                <div
                    onClick={() => {
                        let url = window.location.href
                            .replace('&isShareMode=true', '')
                            .replace('isShareMode=true&', '')
                            .replace('?isShareMode=true', '');
                        if (url.indexOf('?') > -1) {
                            url = url.replace('?', '?isShareMode=true');
                        } else {
                            url = url.replace('/myDocuments', '/myDocuments?isShareMode=true');
                        }
                        window.history.replaceState(null, '', url);
                        this.setProjectsViewMode('shared');
                    }}
                    className={Array.from(sharedBtnClassList).join(' ')}
                    data-testid="buttonMyDocsViewShared"
                >
                    shared
                </div>
            </div>
        );
    }

    renderViewModeTabs() {
        const { viewMode } = this.state;
        return (
            <div className="my-documents__tabs">
                <div
                    onClick={() => this.setViewMode('tile')}
                    className={`my-documents__tab font-search ${viewMode === 'tile' ? 'my-documents__tab--active' : ''}`}
                    data-testid="buttonMyDocsViewList"
                >
                    list
                </div>
                <div
                    onClick={() => this.setViewMode('table')}
                    className={`my-documents__tab font-search ${viewMode === 'table' ? 'my-documents__tab--active' : ''}`}
                    data-testid="buttonMyDocsViewTable"
                >
                    table
                </div>
            </div>
        );
    }

    renderHeader() {
        const { customerViewCompanyId, addFiles, companiesList, companyId, searchQuery, isImpersonateMode } = this.props;
        const { isCompanyListOpened } = this.state;
        const chosenCompany = companiesList.find(
            ({ value: id }) => id === customerViewCompanyId
        );
        const chosenCompanyName = chosenCompany ? chosenCompany.title : 'No company selected.';
        const showCompanyField = companyId === 1 && chosenCompany !== undefined;
        const companiesListDropDownData = companiesList
            .map(({ value: id, title: name }) => {
                const params = {};

                if (id !== 1) params.companyId = id;
                if (searchQuery) params.q = searchQuery;
                return {
                    id,
                    name,
                    link: `/myDocuments?${serializeParams(params)}`
                };
            });
        return (
            <div className="my-documents__header">
                <div className="my-documents__wrapper my-documents__wrapper--flex">
                    <div className="my-documents__left-side">
                        <div
                            className="my-documents__title"
                            data-testid="textMyDocsTitle"
                        >
                            DOCUMENTS LIBRARY
                        </div>
                    </div>
                    <div className="my-documents__right-side">
                        { showCompanyField && (
                            <div
                                className="my-documents__company-field"
                                onClick={() => this.setState({ isCompanyListOpened: true })}
                            >
                                <span data-testid="textMyDocsSelectCompanyLabel">Company: </span>
                                <span
                                    className="my-documents__company-name"
                                    data-testid="buttonMyDocsCompanySelect"
                                >
                                    {capitalize(chosenCompanyName)}
                                </span>
                                { isCompanyListOpened && (
                                    <CompaniesListDropDown
                                        data={companiesListDropDownData}
                                        close={this.closeCompaniesListDropDown}
                                        testId="selectMyDocsCompanySelect"
                                    />
                                )}
                            </div>

                        )}
                        {!isImpersonateMode && (
                            <div
                                onClick={addFiles}
                                className="my-documents__btn"
                                data-testid="buttonMyDocsUploadFiles"
                            >
                                Upload Files
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    renderContent() {
        const { isCustomerViewMode, customerViewCompanyId, searchQuery, companyId } = this.props;
        const backFromSearchLinkParams = {};
        if (isCustomerViewMode) {
            backFromSearchLinkParams.companyId = customerViewCompanyId;
        }
        const backFromSearchLink = `/myDocuments?${serializeParams(backFromSearchLinkParams)}`;
        const showProjectsViewModeTabs = companyId === 1 && !isCustomerViewMode && searchQuery === '';

        return (
            <div className="my-documents__content">
                <div className="my-documents__wrapper">
                    <div className="my-documents__left-side">
                        { searchQuery !== '' && (
                            <NavLink
                                to={backFromSearchLink}
                                className="my-documents__link my-documents__tabs"
                                data-testid="buttonMyDocsSearchResultsBack"
                            >
                                « back
                            </NavLink>
                        )}
                        { isCustomerViewMode && searchQuery === '' && (
                            <NavLink
                                to={'/myDocuments'}
                                className="my-documents__link my-documents__tabs"
                                data-testid="buttonMyDocsBackToMain"
                            >
                                « back to Datassential
                            </NavLink>
                        )}
                        { showProjectsViewModeTabs && this.renderProjectsViewModeTabs() }
                    </div>
                    <div className="my-documents__right-side">
                        { searchQuery === '' && this.renderViewModeTabs()}
                    </div>
                </div>
                <div className="my-documents__wrapper">
                    {this.renderSidebar()}
                    { searchQuery === '' && (
                        <div className="my-documents__main">
                            {this.state.viewMode === 'tile' && this.renderProjects()}
                            {this.state.viewMode === 'table' && this.renderProjectsTable()}
                        </div>
                    )}
                    { searchQuery !== '' && (
                        <div className="my-documents__main">
                            {this.renderSearchResults()}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    render() {
        const { dataLoaded } = this.props;

        return (
            <Page>
                <PageContent>
                    {this.renderHeader()}
                    <div className="my-documents__scrollbar-outer--doclib">
                        <Scrollbars>
                            <div className="my-documents">
                                { dataLoaded ? this.renderContent() : (
                                    <div className="spinner spinner-blink">
                                        <Icon iconId="logo-dark"/>
                                    </div>
                                )}
                            </div>

                        </Scrollbars>
                    </div>

                    {this.props.showSharePopup && <SharePopup/>}
                </PageContent>
            </Page>
        );
    }
}

MyDocuments.propTypes = {
    data: PT.object,
    getData: PT.func,
    createProject: PT.func,
    addFiles: PT.func,
    getSearchResults: PT.func,
    storeSearchQuery: PT.func,
    editProject: PT.func,
    deleteProject: PT.func,
    clearData: PT.func,
    storeData: PT.func,
    projects: PT.array,
    documentsCount: PT.number,
    documentTypes: PT.array,
    dataLoaded: PT.bool,
    showSharePopup: PT.bool,
    projectsTable: PT.object,
    location: PT.object,
    searchQuery: PT.string,
    projectsViewMode: PT.string,
    searchResultsTable: PT.object,
    history: PT.object,
    addFileToPackage: PT.func,
    setSharePopupState: PT.func,
    editDocument: PT.func,
    deleteDocument: PT.func,
    setActiveProjectId: PT.func,
    setPageToReload: PT.func,
    setCustomerViewCompanyId: PT.func,
    companyId: PT.number,
    companiesList: PT.array,
    isCustomerViewMode: PT.bool,
    customerViewCompanyId: PT.number
};

const MapStateToProps = state => ({
    dataLoaded: state.myDocumentsData.dataLoaded,
    isCustomerViewMode: state.myDocumentsData.isCustomerViewMode,
    projectsViewMode: state.myDocumentsData.projectsViewMode,
    customerViewCompanyId: state.myDocumentsData.customerViewCompanyId,
    projects: projectsSelector(state),
    companiesList: companiesSelector(state),
    companyId: companyIdSelector(state),
    documentTypes: documentTypesSelector(state),
    documentsCount: documentsCountSelector(state),
    showSharePopup: shareDataSelector(state),
    projectsTable: projectsTableSelector(state),
    searchQuery: state.myDocumentsData.searchQuery,
    searchResultsTable: searchResultsTableSelector(state),
    isImpersonateMode: isImpersonateModeSelector(state),
});
const MapDispatchToProps = dispatch => ({
    getData: () => dispatch(getData()),
    deleteProject: project => dispatch(startDeletingProject(project)),
    editProject: (id, name, description) => {
        dispatch(setData({ id, name, initialName: name, description }));
        dispatch(openProjectEditPopup());
    },
    addFiles: () => dispatch(openDocumentsAddPopup()),
    createProject: () => dispatch(openProjectCreatePopup()),
    addFileToPackage: options => dispatch(addFileToPackage(options)),
    getSearchResults: () => dispatch(getSearchResults()),
    storeSearchQuery: query => dispatch(storeSearchQuery(query)),
    setPageToReload: name => dispatch(setPageToReload(name)),
    clearData: () => dispatch(clearData()),
    storeData: data => dispatch(storeData(data)),
    setActiveProjectId: id => dispatch(setActiveProjectId(id)),
    setSharePopupState: params => dispatch(setSharePopupState(params)),
    deleteDocument: document => dispatch(startDeletingDocument(document)),
    openSharePopup: data => dispatch(setSharePopupState(data)),
    editDocument: data => dispatch(openDocumentEditPopup(data)),
    setCustomerViewCompanyId: id => dispatch(setCustomerViewCompanyId(id)),
    showNotification: text => dispatch(addNotification(text)),
});

export default connect(MapStateToProps, MapDispatchToProps)(MyDocuments);
