import { useData } from "@hooks/useData";
import { iReportProPublication, ReportProPublication } from "@models/ReportProPublication";
import http from '@core/fetch';
import { API__ReportProPublicationClientWrapper } from "@apiSchema";

async function getReportProNewReleases (): Promise<iReportProPublication[]> {
    const response: API__ReportProPublicationClientWrapper = await http.get('reportpro new releases');
    return response.data.map(apiModel => new ReportProPublication({ apiModel }));
}

export function useReportProNewReleases() {
    return useData([], getReportProNewReleases);
}