import { FC, useMemo, useState } from "react";
import cn from "classnames";
import SelectGrid from "../../../components/new/SelectGrid/SelectGrid";
import InputField from "../../../components/new/InputField/InputField";
import { IconSearchWhite } from "../../../icons";
import { minusArrayUnique, sanitizeForSearch, uniteArraysUnique } from "../../../core/helpers";
import { CustomBaseScrollbars } from "../../../components/CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";

interface iProps {
    data: string[];
    value: string[];
    setValue: (value: string[]) => void;
    isDisabled?: boolean;
    itemsPerRow?: number;
    isBodyFluid?: boolean;
    isSearchable?: boolean;
    title?: string;
}

const FilterSelectGrid: FC<iProps> = (
    {
        value,
        data: rawData,
        setValue,
        isDisabled,
        itemsPerRow,
        isSearchable = true,
        title = '',
        isBodyFluid= false,
    }
) => {
    const [searchQuery, setSearchQuery] = useState('');
    const searchStartRE = new RegExp(`^(${searchQuery.trim()})`, 'i');
    const searchRE = new RegExp(`(${searchQuery.trim()})`, 'i');

    const data = rawData.filter(i => searchRE.test(i));

    if (searchQuery.trim()) {
        data.sort((a, b) => {
            if (searchStartRE.test(a)) return -1;
            else if (searchStartRE.test(b)) return 1;
            else return 1;
        });
    }

    function selectAll() {
        setValue(uniteArraysUnique(value, rawData));
    }

    function deselectAll() {
        setValue(minusArrayUnique(value, rawData));
    }

    function getHighlightedMatches(title: string): string {
        if (searchQuery.trim()) {
            return title.replace(searchRE, '<b class="hhh">$1</b>')
        }
        return title;
    }

    const headerClassnames = cn("FiltersContent__header", isDisabled && "is-disabled");
    const bodyClassnames = cn(
        "FiltersContent__body",
        isBodyFluid && "FiltersContent__body--fluid",
        isDisabled && "is-disabled"
    );
    const style = useMemo(
        () =>
            ({
                "--gridItems": itemsPerRow || 3,
            } as React.CSSProperties),
        [itemsPerRow],
    );

    const renderSelectGrid = (
        <SelectGrid
            data={data.map(i => ({
                value: i,
                title: getHighlightedMatches(i),
            }))}
            value={value}
            setValue={setValue}
        />
    );
    return (
        <>
            <div className={headerClassnames}>
                <div className="FiltersContent__title">
                    {isSearchable && (
                        <InputField
                            onChange={value => setSearchQuery(sanitizeForSearch(value))}
                            value={searchQuery}
                            placeholder="Search"
                            style={{
                                input: {
                                    width: 200,
                                    paddingLeft: 32,
                                    paddingTop: 0,
                                },
                            }}
                        >
                            <IconSearchWhite style={{
                                position: 'absolute',
                                top: 2,
                                left: 0,
                            }}/>
                        </InputField>
                    )}
                    {title}
                </div>
                <div className="FiltersContent__part FiltersContent__part--pr">
                    <div
                        className="FiltersContent__filter"
                        onClick={selectAll}
                    >
                        select all
                    </div>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <div
                        className="FiltersContent__filter"
                        onClick={deselectAll}
                    >
                        deselect all
                    </div>
                </div>
            </div>
            <div className={bodyClassnames} style={style}>
                {isBodyFluid ? renderSelectGrid : (
                    <CustomBaseScrollbars>
                        {renderSelectGrid}
                    </CustomBaseScrollbars>
                )}
            </div>
        </>
    )
};

export default FilterSelectGrid;