import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import "./TableRows.styles.scss";


interface iItem {
	title: string;
	value: string | ReactNode;
	testId?: string;
}

interface iProps {
	data: iItem[];
	className?: string;
	isTwoColumns?: boolean;
}

const Table: FC<{data: iItem[]}> = (
	{
		data,
	}
) => {
	return (
		<div className="TableRows__table">
            {data.map((item, index) => {
				if (!item.value) return null;
                return (
                    <div
						key={index}
						className="TableRows__table-row"
						data-testid={`tableRowsItem${index}`}
					>
                        <div
							className="TableRows__table-cell TableRows__table-cell-title"
							data-testid={item.testId ? `${item.testId}Title` : 'textTableRowsItemTitle'}
						>
                            {item.title}
                        </div>
                        <div
							className="TableRows__table-cell"
							data-testid={item.testId ? `${item.testId}Value` : 'textTableRowsItemValue'}
						>
							{item.value}
						</div>
                    </div>
                );
            })}
        </div>
	)
}


const TableRows: FC<iProps> = ({
	data,
	className = '',
	isTwoColumns = false,
}) => {
	return (
		<div className={classNames('TableRows', className)}>

			{isTwoColumns === true ? (
				<div className="TableRows__two-columns">
					<Table
						data={data.slice(0, Math.round(data.length / 2))}
					/>

					<Table
						data={data.slice(Math.round(data.length / 2))}
					/>
				</div>
			) : (
                <Table
					data={data}
				/>
            )}
		</div>
	);
};

export default TableRows;
