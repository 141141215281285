import { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import Modal from "../../../../components/Modal/Modal";
import { iPreset, useGapReportData } from "../../../../core/apiHooks/useGapReportData";
import Button from "../../../../components/new/Button/Button";
import './GapReportModal.styles.scss';
import NestedTiles from "../../../../components/new/Tiles/NestedTiles";
import cn from "classnames";
import Table from "../../../../components/new/Table/Table";
import { iTableConfigItem } from "../../../../components/new/Table/Table.helpers";
import { getFormattedDate } from "../../../../core/helpers";
import ChainsCompareSearch from "./ChainsCompareSearch/ChaisCompareSearch";
import { ID } from "../../../../core/models";
import { ChainDetailsDataContext } from "../../ChainPage.helpers";
import Loader from "../../../../components/new/Loader/Loader";
import { CustomBaseScrollbars } from "../../../../components/CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";

interface iProps {
    close: () => void;
}

interface iStep {
    content: ReactNode;
    footer: ReactNode;
}

interface iSteps {
    start: iStep;
    choosePreset: iStep;
    createPreset: iStep;
    chooseReport: iStep;
    chooseCustomReport: iStep;
}

const GapReportModal: FC<iProps> = (
    {
        close,
    }
) => {
    const {
        data: {
            mainData: {
                id: chainId,
                name: chainName,
            }
        },
    } = useContext(ChainDetailsDataContext);

    const {
        isLoading,
        isReportLoading,
        data,
        reFetch,
        downloadReport,
        downloadSimpleReport,
    } = useGapReportData(chainId);

    const [currentStep, setCurrentStep] = useState<keyof iSteps>('start');
    const [selectedChainsIds, setSelectedChainsIds] = useState<ID[]>([]);
    const [selectedPresetName, setSelectedPresetName] = useState<string>('');

    const interactiveListsAsTilesItems = useMemo(() => {
        return data.interactiveLists.map((lvl1) => ({
            id: lvl1.id,
            title: lvl1.name,
            subItems: lvl1.childReports.map((lvl2) => ({
                id: lvl2.id,
                title: lvl2.name,
                subItems: lvl2.childReports.map((lvl3) => ({
                    id: lvl3.id,
                    title: lvl3.name,
                    subItems: [],
                }))
            }))
        }))
    }, [data.interactiveLists]);

    const handlePresetClick = (preset: iPreset) => {
        setSelectedChainsIds(preset.chains.map(i => i.fireflyChainId));
        setSelectedPresetName(preset.name);
        setCurrentStep("chooseReport");
    };

    const handleReportSelect = (id: number) => {
        downloadReport(selectedChainsIds, selectedPresetName, id).then(close);
    }

    const handleCustomReportSelect = (id: number) => {
        downloadReport(selectedChainsIds, selectedPresetName, id, true).then(close);
    }

    const handleSimpleReportClick = () => {
        downloadSimpleReport(selectedChainsIds, selectedPresetName).then(close);
    }

    useEffect(() => {
        if (currentStep === 'choosePreset' || currentStep === 'createPreset') {
            setSelectedChainsIds([]);
            setSelectedPresetName('');
        }
    }, [currentStep]);

    const savedQueriesTableConfig: iTableConfigItem[] = useMemo(() => ([
        {
            title: 'Report name',
            Value: ({ index }) => (
                <div
                    className="GapReportModal__link"
                    onClick={() => handleCustomReportSelect(data.savedQueries[index].query.id)}
                >
                    {data.savedQueries[index].query.title}
                </div>
            ),
        },
        {
            title: 'Date',
            width: 100,
            Value: ({ index }) => <>
                {getFormattedDate(data.savedQueries[index].query.created, 'MM/DD/YYYY')}
            </>
        },
        {
            title: '#  of words',
            width: 100,
            Value: ({ index }) => (
                <div style={{ textAlign: 'center', width: '100%' }}>
                    {data.savedQueries[index].words.length}
                </div>
            ),
        },
    ]), [data.savedQueries, handleCustomReportSelect]);


    const steps: iSteps = {
        start: {
            content: (
                <div className="GapReportModal__start">
                    <div className="GapReportModal__heading2">
                        Create an instant MENU GAP ANALYSIS <br/>that benchmarks the&nbsp;
                        <b className="GapReportModal__link GapReportModal__link--text">{chainName}</b>
                        &nbsp;competitors.
                    </div>
                    <br/>
                    <img src={`${process.env.PUBLIC_URL}/gaps.png`}/>
                </div>
            ),
            footer: (
                <div className="GapReportModal__section GapReportModal__section--center">
                    <div className="GapReportModal__button">
                        <Button
                            modifiers={["secondary"]}
                            onClick={close}
                        >
                            cancel
                        </Button>
                    </div>
                    <div className="GapReportModal__button">
                        <Button
                            modifiers={["primary"]}
                            onClick={() => setCurrentStep("choosePreset")}
                            isLoading={isLoading || isReportLoading}
                        >
                            Let's go
                        </Button>
                    </div>
                </div>
            ),
        },
        choosePreset: {
            content: (
                <>
                    <div className="GapReportModal__heading2 mb">
                        compare {chainName} to
                    </div>
                    <div className="GapReportModal__columns">
                        {data.presets.map((presetGroup) => (
                            <div
                                key={presetGroup.presetClass}
                                className="GapReportModal__column"
                            >
                                <div className="GapReportModal__heading3 mb">
                                    {presetGroup.presetName}
                                </div>
                                <div className="GapReportModal__list">
                                    <CustomBaseScrollbars>
                                        {presetGroup.preset.map((preset) => {
                                            const className = cn(
                                                'GapReportModal__link',
                                                preset.isCustom && 'GapReportModal__link--accent',
                                            );
                                            return (
                                                <div
                                                    key={preset.id}
                                                    className={className}
                                                    onClick={() => handlePresetClick(preset)}
                                                >
                                                    {preset.name} ({preset.chains.length})
                                                </div>
                                            );
                                        })}
                                    </CustomBaseScrollbars>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ),
            footer: (
                <div className="GapReportModal__section GapReportModal__section--justify">
                    <div className="GapReportModal__button">
                        <Button
                            modifiers={["secondary"]}
                            onClick={() => setCurrentStep("start")}
                        >
                            {`<< back`}
                        </Button>
                    </div>
                    <div className="GapReportModal__button">
                        <p>
                            You can also&nbsp;
                            <span
                                className="GapReportModal__link"
                                onClick={() => setCurrentStep("createPreset")}
                            >
                                build
                            </span>
                            &nbsp;your own competitive set
                        </p>
                    </div>
                </div>
            ),
        },
        createPreset: {
            content: (
                <>
                    <div className="GapReportModal__heading2">
                        compare {chainName} to
                    </div>
                    <div className="GapReportModal__form">
                        <div className="GapReportModal__autocomplete">
                            <ChainsCompareSearch
                                chainId={chainId}
                                setSelected={(options) => {
                                    setSelectedChainsIds(options.map(o => o.id));
                                    setSelectedPresetName('Custom');
                                }}
                            />
                        </div>
                    </div>
                </>
            ),
            footer: (
                <div className="GapReportModal__section">
                    <div className="GapReportModal__button">
                        <Button
                            modifiers={["secondary"]}
                            onClick={() => setCurrentStep("choosePreset")}
                        >
                            {`<< back`}
                        </Button>
                    </div>
                    <div className="GapReportModal__button">
                        <Button
                            modifiers={["primary"]}
                            isDisabled={setSelectedChainsIds.length === 0}
                            onClick={() => setCurrentStep("chooseReport")}
                        >
                            next
                        </Button>
                    </div>
                </div>
            ),
        },
        chooseReport: {
            content: (
                <>
                    <div className="GapReportModal__heading2 mb">
                        GREAT! NOW JUST SELECT A REPORT AND YOU'RE GOOD TO GO.
                    </div>
                    <div className="GapReportModal__section GapReportModal__justify">
                        <div className="GapReportModal__heading3 mb">
                            Select a category report:
                        </div>
                    </div>
                    <div className="GapReportModal__interactive-lists">
                        <NestedTiles
                            data={interactiveListsAsTilesItems}
                            handleLastNestedClick={handleReportSelect}
                        />
                    </div>
                </>
            ),
            footer: (
                <div className="GapReportModal__section GapReportModal__section--justify">
                    <div className="GapReportModal__button">
                        <Button
                            modifiers={["secondary"]}
                            onClick={() => setCurrentStep("choosePreset")}
                        >
                            {`<< back`}
                        </Button>
                    </div>
                    <div className="GapReportModal__section-line">
                        <div className="GapReportModal__heading2">
                            or&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        <button
                            className="GapReportModal__high-button"
                            onClick={() => setCurrentStep("chooseCustomReport")}
                        >
                            use one of your saved<br/>MenuTrends BYO reports
                        </button>
                        <button
                            className="GapReportModal__high-button"
                            onClick={handleSimpleReportClick}
                        >
                            view a simple report<br/>by major menu part
                        </button>
                    </div>
                </div>
            ),
        },
        chooseCustomReport: {
            content: (
                <>
                    <div className="GapReportModal__heading2">
                        GREAT! NOW JUST SELECT A REPORT AND YOU'RE GOOD TO GO.
                    </div>
                    <div className="GapReportModal__saved-queries-table">
                        <Table
                            config={savedQueriesTableConfig}
                            rowsCount={data.savedQueries.length}
                        />
                    </div>
                </>
            ),
            footer: (
                <div className="GapReportModal__section GapReportModal__section--justify">
                    <div className="GapReportModal__button">
                        <Button
                            modifiers={["secondary"]}
                            onClick={() => setCurrentStep("chooseReport")}
                        >
                            {`<< back`}
                        </Button>
                    </div>
                </div>
            )
        }
    };

    return (
        <Modal isOpened={true} close={close} className="ChainPage__modal">
            <div className="GapReportModal">
                {isReportLoading && <Loader/>}
                <div className="GapReportModal__header">
                    <div className="GapReportModal__heading1">
                        MENU GAPS
                    </div>
                </div>
                <div className="GapReportModal__body">
                    {steps[currentStep].content}
                </div>
                <div className="GapReportModal__footer">
                    {steps[currentStep].footer}
                </div>
            </div>
        </Modal>
    );
}
export default GapReportModal;