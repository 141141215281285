import { IconAddToPackage, IconCopy, IconDownload, IconEdit, IconShare } from "@icons";
import { FC } from "react";
import cn from "classnames";
import { DocumentActionButtons } from "@models/Documents/Documents";
import "./ActionButton.styles.scss";

interface iProps {
    type: DocumentActionButtons;
    title?: string;
    onClick?: () => void;
    testId?: string;
}

const getButtonIcon = (type: DocumentActionButtons) => {
    switch (type) {
        case DocumentActionButtons.Download:
            return <IconDownload />;
        case DocumentActionButtons.Package:
            return <IconAddToPackage />;
        case DocumentActionButtons.Share:
            return <IconShare />;
        case DocumentActionButtons.Edit:
            return <IconEdit />;
        case DocumentActionButtons.Copy:
            return <IconCopy />;
        default:
            return <></>;
    }
};

const ActionButton: FC<iProps> = ({
    type,
    title = '',
    onClick = () => {},
    testId,
}) => {
    return (
        <div
            className={cn("ActionButton", {
                "is-accent": type === DocumentActionButtons.Download,
            })}
            title={title}
            onClick={onClick}
            data-testid={testId}
        >
            <div className="ActionButton__icon">{getButtonIcon(type)}</div>
            <div className="ActionButton__text">{type}</div>
        </div>
    );
};

export default ActionButton;
