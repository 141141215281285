import {
    API__ChainPermissionWrapper,
    API__MacAndInstantChartViewModel,
} from "@apiSchema";
import { ID } from "@models/index";
import { prepareForMatching } from "@helpers";
import { routeNames } from "@core/routeNames";
import {
    iPermissionFields,
    iPermissionSection,
    PermissionLevelEnum,
    PermissionSection
} from "@models/PermissionSection";

interface iFoodProfileRecommendedChart {
    id: ID;
    title: string;
    typeLabel: string;
    link: string;
}

export interface iFoodProfileRecommendedCharts extends iPermissionFields {
    data: iFoodProfileRecommendedChart[];
}

type API_Model = API__ChainPermissionWrapper<API__MacAndInstantChartViewModel>;

interface iData {
    apiModel?: API_Model;
    searchQuery: string;
}

export class FoodProfileRecommendedCharts implements iFoodProfileRecommendedCharts {
    hasData = true;
    permissionLevel = PermissionLevelEnum.None;
    data: iFoodProfileRecommendedChart[] = [];

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel, data.searchQuery);
        }
    }

    private setData(model: iFoodProfileRecommendedCharts
    ) {
        ({
            permissionLevel: this.permissionLevel,
            hasData: this.hasData,
            data: this.data,
        } = model);
    }

    private mapFromApi(rawData: API_Model, searchQuery: string) {
        if (rawData.data) {
            const {
                mac: {
                    items: macItems,
                },
                instantChart: {
                    items: instantChartsItems,
                },
            } = rawData.data;

            const allItems: iFoodProfileRecommendedChart[] = [
                ...macItems.map(i => ({
                    id: i.id,
                    title: i.name,
                    typeLabel: "MAC",
                    link: `${routeNames.viewDocument}?id=${i.id}&doctype=3&fromDocuments=${encodeURIComponent(searchQuery)}`,
                })),
                ...instantChartsItems.map(i => ({
                    id: i.id,
                    title: i.name,
                    typeLabel: "Instant Chart",
                    link: `${routeNames.instantCharts}?q=${encodeURIComponent(searchQuery)}&id=${i.id}`,
                })),
            ];

            allItems.sort(({ title: a }, { title: b }) => {
                return prepareForMatching(a) < prepareForMatching(b) ? 1 : -1;
            });

            this.setData({
                permissionLevel: PermissionSection.getPermissionLevel(rawData.permissionLevel),
                hasData: rawData.hasData || allItems.length > 0,
                data: allItems,
            });


        }
    }
}