import { CSSProperties, FC } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import "./Button.styles.scss";
import NewTooltip, { positionType } from "../NewTooltip/NewTooltip";
import Loader from "../Loader/Loader";
import { AnalyticsData, getAnalyticsComputedData } from "@models/AnalyticsAttributes";

export enum ButtonTypeEnum {
    Button = 1,
    Link,
    NavLink,
    Anchor,
}

export type ButtonModifiers = "primary" | "secondary" | "accent" | "accent-gradient" | "icon" | "danger";

const ButtonLoader = () => (<div className="Button__loader"><Loader isSimple /></div>);

const Button: FC<{
    type?: ButtonTypeEnum;
    link?: string;
    onClick?: (e?: any) => void;
    className?: string;
    isDisabled?: boolean;
    tooltipText?: string | React.ReactElement;
    tooltipPosition?: positionType;
    modifiers?: Array<ButtonModifiers>;
    isLoading?: boolean;
    isWrapper?: boolean;
    testId?: string;
    style?: {
        tooltip?: CSSProperties;
    };
    analyticsData?: AnalyticsData;
}> = ({
    type = ButtonTypeEnum.Button,
    link,
    onClick,
    className = "",
    children,
    isDisabled = false,
    modifiers = [],
    tooltipText,
    tooltipPosition,
    isLoading,
    isWrapper = false,
    style,
    analyticsData,
    testId,
}) => {
    const classNames = isWrapper ? cn(
        'Button',
        'Button--wrapper',
        className,
    ) : cn(
        "Button",
        'clickable',
        modifiers.map(m => `Button--${m}`),
        className,
        isDisabled ? "Button--disabled" : "",
    );

    const tooltip = (
        <NewTooltip
            position={tooltipPosition}
            style={style?.tooltip}
        >
            {tooltipText}
        </NewTooltip>
    );

    const renderTooltip = tooltipText ? tooltip : null;

    const props = {
        onClick,
        className: classNames,
        ['data-testid']: testId,
        ...(analyticsData ? getAnalyticsComputedData(analyticsData) : {}),
    };

    switch (type) {
        case ButtonTypeEnum.Button: {
            return (
                <button {...props} disabled={isDisabled || isLoading}>
                    {children}
                    {renderTooltip}
                    {isLoading && <ButtonLoader/>}
                </button>
            );
        }
        case ButtonTypeEnum.Link: {
            return (
                <Link to={link || "#"} {...props}>
                    <>
                        {children}
                        {renderTooltip}
                    </>
                </Link>
            );
        }
        case ButtonTypeEnum.NavLink: {
            return (
                <NavLink to={link || "#"} {...props} activeClassName="Button--active">
                    <>
                        {children}
                        {renderTooltip}
                    </>
                </NavLink>
            );
        }
        case ButtonTypeEnum.Anchor: {
            return (
                <a target="_blank" href={link || "#"} {...props} rel="noreferrer">
                    {children}
                    {renderTooltip}
                </a>
            );
        }
        default:
            return null;
    }
};

export default Button;
