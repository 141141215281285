import { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import Popup from '../../components/Popup/Popup';
import { close, resetData, deleting } from './documentDeletePopup.actions';

class DocumentDeletePopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            confirmationInputValue: ''
        };

        this.changeConfirmationInputValue = this.changeConfirmationInputValue.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    changeConfirmationInputValue(event) {
        this.setState({ confirmationInputValue: event.target.value });
    }

    deleteDocument(event) {
        event.preventDefault();
        if (this.state.confirmationInputValue.toLowerCase() === 'delete') {
            const { deleting } = this.props;
            deleting();
        }
    }

    handleOpen() {
        this.setState({ confirmationInputValue: '' });
    }

    handleClose() {
        const { close, clearData } = this.props;
        close();
        resetData();
    }

    render() {
        return (
            <Popup
                isOpened={this.props.isOpened}
                onOpen={this.handleOpen}
                onClose={this.handleClose}
                className="popup--fade-in"
            >
                <div className="popup__inner popup-red popup__inner--small">
                    <div className="popup__header">
                        <div
                            className="popup__title"
                            data-testid="textDocumentDeletePopupTitle"
                        >
                            Are you sure?
                        </div>
                    </div>
                    <div
                        className="popup__text popup__text--indented popup__text--white popup__text--light"
                        data-testid="textDocumentDeletePopupContent"
                    >
                        This process can not be reversed and will delete this file from SNAP permanently.
                    </div>
                    <div className="popup__form-wrapper popup__form-wrapper--bordered">
                        <form onSubmit={this.deleteDocument}>
                            <div
                                className="popup__input-label popup__input-label--no-transform popup__input-label--light"
                                data-testid="textDocumentDeletePopupConfirmInputLabel"
                            >
                                Type <strong>"DELETE"</strong> to confirm
                            </div>
                            <input
                                type="text"
                                value={this.state.confirmationInputValue.toUpperCase()}
                                onChange={this.changeConfirmationInputValue}
                                autoFocus={true}
                                className={`
                                    popup__input popup__input--big popup__input--underlined
                                    popup__input--backgrounded popup__margin--big
                                `}
                                data-testid="inputDocumentDeletePopupConfirm"
                            />
                            <input type="submit" className="hidden"></input>
                        </form>
                    </div>
                    <div className="popup__button-wrapper">
                        <div className="popup__right-side">
                            <button
                                onClick={this.handleClose}
                                className="popup__link popup__link--light popup__link--white"
                                data-testid="buttonDocumentDeletePopupCancel"
                            >
                                cancel
                            </button>
                            <button
                                disabled={this.state.confirmationInputValue.toLowerCase() !== 'delete'}
                                onClick={this.deleteDocument}
                                className="popup__btn popup__btn--white popup__btn--md"
                                data-testid="buttonDocumentDeletePopupDelete"
                            >
                                DELETE
                            </button>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}

DocumentDeletePopup.propTypes = {
    isOpened: PT.bool,
    id: PT.oneOfType([
        PT.number,
        PT.string
    ]),
    filesCount: PT.number,
    close: PT.func,
    clearData: PT.func,
    deleting: PT.func
};

const MapStateToProps = state => ({
    isOpened: state.documentDeletePopupData.isOpened,
    id: state.documentDeletePopupData.id,
    filesCount: state.documentDeletePopupData.filesCount
});

const MapDispatchToProps = dispatch => ({
    close: () => dispatch(close()),
    resetData: () => dispatch(resetData()),
    deleting: () => dispatch(deleting())
});

export default connect(MapStateToProps, MapDispatchToProps)(DocumentDeletePopup);
