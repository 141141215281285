import { API__SocialLinks } from "./api/SocialLinks";

export interface iYoutubeVideo {
    url: string;
    title: string;
    description: string;
    thumbnailUrl: string;
}

export interface iSocialLinks {
    youtube: iYoutubeVideo[];
    twitter: string[];
}

interface iData {
    apiModel: API__SocialLinks;
}

export class SocialLinks implements iSocialLinks {
    static defaultData: iSocialLinks = {
        youtube: [],
        twitter: []
    }

    youtube = SocialLinks.defaultData.youtube;
    twitter = SocialLinks.defaultData.twitter;

    constructor(data?: iData) {
        if ( data && data.apiModel ) {
            this.mapFromApi(data.apiModel);
        }
    }

    private setData(model: iSocialLinks) {
        ({
            youtube: this.youtube,
            twitter: this.twitter
        } = model);
    }

    private mapFromApi(data: API__SocialLinks) {
        const { tweets, youTubeVideos, ...rest } = data;

        this.setData({
            ...rest,
            twitter: tweets || [],
            youtube: youTubeVideos.map(el => ({
                url: el.VideoId || "",
                title: el.Title || "",
                description: el.Description || "",
                thumbnailUrl: el.ThumbnailUrl || "",
            }))
        })
    }
}