import { useEffect, useState } from "react";
import NotificationPopup from "../NotificationPopup/NotificationPopup";
import { getCookie, setCookieEnhanced } from "../../core/old_helpers";

const ChangePassNotification = () => {
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        const timer = () =>
            setTimeout(() => {
                setIsShown(false);
            }, 5000);

        setIsShown(!!getCookie("passwordRestored"));

        setCookieEnhanced("passwordRestored", "", {
            expires: -1,
            domain: ".datassential.com",
            path: "/",
        });

        const timerId = timer();

        return () => {
            clearTimeout(timerId);
        };
    }, []);

    return (
        <NotificationPopup
            isOpened={isShown}
            hasCloseButton={false}
            style={{ width: 270, height: 75 }}
        >
            <span>
                You have successfully changed <br />
                your password. Enjoy SNAP!
            </span>
        </NotificationPopup>
    );
};

export default ChangePassNotification;
