import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import SpriteIcon from '../SpriteIcon/SpriteIcon';
import './NotificationStyle.scss';

class Notification extends Component {
    render() {
        const message = this.props.storeText || this.props.text;
        return (
            <div className={classNames('component-notification', { visible: this.props.isVisible })}>
                <span className="component-notification__icon">
                    <SpriteIcon iconId="success"/>
                </span>
                {message}
            </div>
        );
    }
}

Notification.propTypes = {
    isVisible: PropTypes.bool,
    text: PropTypes.string
};

const mapStateToProps = state => ({
    storeText: state.documentsData.notificationText
    // isVisible: state.documentsData.notificationVisible
});

export default connect(mapStateToProps)(Notification);
