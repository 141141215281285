import { FC } from "react";
import ActionButton from "./ActionButton/ActionButton";
import "./DocumentActions.styles.scss";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { downloadFile, sanitizeUrl } from "@helpers";
import PackageAddWrapper from "@components/PackageAddWrapper/PackageAddWrapper";
import { addFileToPackage } from "@actions/packages.actions";
import { setSharePopupState } from "@actions/documents.actions";
import { useCopyToClipboard } from "@hooks/useCopyToClipboard";
import { DOCUMENTS_DOCTYPE, PUBLICATION_FAMILY_DEPRECATED } from "@pages/NewDocuments/Documents/Documents.helpers";
import { startEditing } from "@containers/DocumentEditPopup/documentEditPopup.actions";
import { DocumentActionButtons } from "@models/Documents/Documents";
import { ID } from "@models";

interface iSharePopupDataProps {
    id: number;
    shareLinkString: string;
    title: string;
}

interface iSharePopupData extends iSharePopupDataProps {
    documentType: number;
    popupType: string;
    publicationFamily: string;
}

interface iEditData {
    id: ID;
    typeId: ID;
    name: string;
    initialName: string;
    fileSize: number;
    size: number;
    projectId: ID;
    thumnailExist: boolean;
    thumnailUrl: string;
    shareWithIds?: number[];
}
type ActionModifiers = "vertical";

interface iProps {
    copyUrl?: string;
    downloadUrl?: string;
    packageData?: { id: number; docType: string; }
    shareData?: iSharePopupDataProps;
    editData?: iEditData;
    modifiers?: Array<ActionModifiers>;
}

const DocumentActions: FC<iProps> = ({
    copyUrl = '',
    downloadUrl = '',
    packageData,
    shareData,
    editData,
    modifiers = [],
}) => {
    const dispatch = useDispatch();
    const fileToPackage = (options: { itemId: number; itemDocumentType: string; }) => dispatch(addFileToPackage(options));
    const showPopup = (data: iSharePopupData) => dispatch(setSharePopupState(data));
    const editDocument = (data: iEditData) => dispatch(startEditing(data));
    const copyToClipboard = useCopyToClipboard();

    const handleCopy = () => {
        copyToClipboard(
            copyUrl,
            'Link copied to your clipboard. You can paste it into email or any else you need.'
        );
    };

    const handleDownload = (url: string) => downloadFile(sanitizeUrl(`${process.env.REACT_APP_API}${url}`));

    const handleAddFileToPackage = () => {
        if ( packageData ) {
            const { id, docType } = packageData;
            fileToPackage({
                itemId: id,
                itemDocumentType: docType
            });
        }
    };

    const handleShare = () => {
        if ( shareData ) {
            const { id, shareLinkString, title } = shareData;
            showPopup({
                id,
                shareLinkString,
                publicationFamily: PUBLICATION_FAMILY_DEPRECATED,
                title,
                documentType: DOCUMENTS_DOCTYPE,
                popupType: 'allDocuments'
            });
        }
    }

    const handleEdit = () => {
        if ( !editData ) return;
        editDocument(editData);
    }

    return (
        <div className={cn("DocumentActions", modifiers.map(m => `DocumentActions--${m}`))}>
            {downloadUrl && (
                <ActionButton
                    type={DocumentActionButtons.Download}
                    onClick={() => handleDownload(downloadUrl)}
                    testId="buttonDocumentPageDownload"
                />
            )}

            {packageData && (
                <PackageAddWrapper>
                    <ActionButton
                        type={DocumentActionButtons.Package}
                        onClick={handleAddFileToPackage}
                        testId="buttonDocumentPageAddToPackage"
                    />
                </PackageAddWrapper>
            )}

            {shareData && (
                <ActionButton
                    type={DocumentActionButtons.Share}
                    onClick={handleShare}
                    testId="buttonDocumentPageShare"
                />
            )}

            {copyUrl && (
                <ActionButton
                    type={DocumentActionButtons.Copy}
                    onClick={handleCopy}
                    testId="buttonDocumentPageCopyLink"
                />
            )}

            {editData && (
                <ActionButton
                    type={DocumentActionButtons.Edit}
                    onClick={handleEdit}
                    testId="buttonDocumentPageEdit"
                />
            )}
        </div>
    );
};

export default DocumentActions;
