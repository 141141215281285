import { FC } from "react";
import DescriptionList, { iItem } from "../../../../../../components/new/DescriptionList/DescriptionList";

import "./LaunchesList.styles.scss";
import { equalInLC } from "../../../../../../core/helpers";

interface iProps {
    data: iItem[];
}

const LaunchesList: FC<iProps> = ({ data }) => {
    const MAX_COUNT_VISIBLE_ITEMS = 50;
    const allItemIndex = data.findIndex(i => equalInLC(i.label, 'all'));

    return (
        <div className="LaunchesList">
            <div className="LaunchesList__title"># of menu launches for the last 12 seasons</div>
            <DescriptionList
                data={data.slice(allItemIndex === 0 ? 1 : 0, MAX_COUNT_VISIBLE_ITEMS)}
                columns={2}
            />
            {allItemIndex === 0 && (
                <div className="LaunchesList__total">
                    <div>Total</div>
                    <div>{data[0].value}</div>
                </div>
            )}
        </div>
    );
};

export default LaunchesList;
