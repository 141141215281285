import { iPermissionFields, PermissionLevelEnum, PermissionSection } from "./PermissionSection";
import { iLTO, LTO } from "./LTO";
import { API__ProfileLtoViewModel } from "../apiSchema";

export interface iSummaryItem {
    chainId: number;
    chainName: string;
    pastThreeMonth: number;
    pastSixMonth: number;
    pastOneYear: number;
    pastTwoYears: number;
}

export interface iFoodLtoSection {
    summary: iSummaryItem[];
    ltos: iPermissionFields & {
        data: iLTO[];
    };
    itemsCount: number;
}

export class FoodLtoSection implements iFoodLtoSection {
    static defaultData: iFoodLtoSection = {
        summary: [],
        ltos: {
            permissionLevel: PermissionLevelEnum.Full,
            hasData: true,
            data: [],
        },
        itemsCount: 0,
    };

    summary = FoodLtoSection.defaultData.summary;
    ltos = FoodLtoSection.defaultData.ltos;
    itemsCount = FoodLtoSection.defaultData.itemsCount;

    constructor(apiModel?: API__ProfileLtoViewModel) {
        if (apiModel) {
            this.mapFromApi(apiModel);
        }
    }

    setData(data: iFoodLtoSection) {
        ({
            ltos: this.ltos,
            summary: this.summary,
            itemsCount: this.itemsCount,
        } = data);
    }

    mapFromApi(apiModel: API__ProfileLtoViewModel) {
        const {
            ltos,
            summary,
            itemsCount,
        } = apiModel;

        this.setData({
            ltos: {
                permissionLevel: PermissionSection.getPermissionLevel(ltos.permissionLevel),
                hasData: ltos.hasData,
                data: (ltos.data || []).map(i => new LTO({ apiModel: i }))
            },
            summary: summary.map(i => ({
                chainName: i.chainName,
                chainId: i.chainId,
                pastTwoYears: i.twoYearscount,
                pastOneYear: i.twelveMonthcount,
                pastSixMonth: i.sixMonthcount,
                pastThreeMonth: i.threeMonthcount,
            })),
            itemsCount,
        });
    }
}