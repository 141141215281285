export const POSSIBLE_ICONS_LIST = new Map([
    ['pdf', { naming: 'PDF', icon: 'pdf' }],
    ['doc', { naming: 'Word document', icon: 'doc' }],
    ['docx', { naming: 'Word document', icon: 'doc' }],
    ['xls', { naming: 'Excel document', icon: 'xls' }],
    ['xlsx', { naming: 'Excel document', icon: 'xls' }],
    ['ppt', { naming: 'Presentation', icon: 'ppt' }],
    ['pptx', { naming: 'Presentation', icon: 'ppt' }],
    ['rtf', { naming: 'Text file', icon: 'rtf' }],
    ['txt', { naming: 'Text file', icon: 'txt' }],
    ['csv', { naming: 'Text file', icon: 'csv' }],
    ['png', { naming: 'Image', icon: 'png' }],
    ['jpg', { naming: 'Image', icon: 'jpg' }],
    ['bmp', { naming: 'Image', icon: 'bmp' }],
    ['gif', { naming: 'Image', icon: 'gif' }],
    ['zip', { naming: 'Archive File', icon: 'zip' }],
    ['mov', { naming: 'Video file', icon: 'mov' }],
    ['mp4', { naming: 'Video file', icon: 'mp4' }],
    ['wmv', { naming: 'Video file', icon: 'wmv' }],
    ['flv', { naming: 'Video file', icon: 'flv' }],
    ['avi', { naming: 'Video file', icon: 'avi' }],
    ['mp3', { naming: 'Music file', icon: 'mp3' }],
    ['aif', { naming: 'Music file', icon: 'aif' }],
    ['wma', { naming: 'Music file', icon: 'wma' }]
]);

export default function getFileTypeIcon(fileExtension) {
    if (POSSIBLE_ICONS_LIST.has(fileExtension)) {
        return `file-${POSSIBLE_ICONS_LIST.get(fileExtension).icon}`;
    }

    return 'file-other';
}

export function getFileTypeNaming(fileExtension) {
    if (POSSIBLE_ICONS_LIST.has(fileExtension)) {
        return POSSIBLE_ICONS_LIST.get(fileExtension).naming;
    }

    return 'other';
}
