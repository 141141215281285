import { useData } from "@hooks/useData";
import http from '@core/fetch';
import { API__InstantChartListItem, API__InstantChartsListModel } from "@apiSchema";
import { iPermissionFields, PermissionLevelEnum, PermissionSection } from "@models/PermissionSection";

interface iInstantChartItemsList extends iPermissionFields {
    data: API__InstantChartListItem[];
}

async function getInstantCharts(query: string): Promise<iInstantChartItemsList> {
    const result: API__InstantChartsListModel = await http.getXHR('get instant charts list', { searchWord: query });

    return {
        permissionLevel: PermissionSection.getPermissionLevel(result.mtPermissionLevel),
        hasData: result.data.length > 0,
        data: result.data,
    };
}

export default function useInstantCharts(query: string) {
    return useData({
        permissionLevel: PermissionLevelEnum.Full,
        data: [],
        hasData: true,
    }, getInstantCharts, query);
}