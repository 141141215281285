import {
    IconType,
    IconProductFfChains,
    IconProductFingerprints,
    IconProductFirefly,
    IconProductFlavor,
    IconProductConceptIdeation,
    IconProductLocal,
    IconProductMenuTrends,
    IconProductPriceMonitor,
    IconProductReportPro,
    IconProductScores,
    IconProductFfChainsGs,
    IconProductFingerprintsGs,
    IconProductFireflyGs,
    IconProductFlavorGs,
    IconProductConceptIdeationGs,
    IconProductLocalGs,
    IconProductMenuTrendsGs,
    IconProductPriceMonitorGs,
    IconProductReportProGs,
    IconProductScoresGs, IconProductConceptLocker, IconProductConceptLockerGs,
} from '@icons';
import { routeNames } from "@core/routeNames";
import { useContext } from "react";
import { AppContext } from "../../AppContext";
import { iGlobalUserData } from "@models/GlobalUserData";

enum NavigationSectionsIds {
    Industry,
    MenuConsumer,
    Chains,
    SalesIntelligence,
    More,
}

export enum NavigationItems {
    ReportPro = 'report-pro', /* 'ReportPro' */
    MenuTrends = 'menutrends', /* 'MenuTrends' */
    ConsumerPreferences = 'flavor', /* 'Flavor' */
    ConceptIdeation = 'food-studio', /* 'Food Studio' */
    LaunchesAndRatings = 'scores', /* 'Scores' */
    ConceptLocker = 'scores-cl', /* 'Scores CL' */
    Chains = 'ff-chains', /* 'Firefly Chains' */
    BrandPerformance = 'fingerprints', /* 'Fingerprints' */
    Operators = 'firefly', /* 'Firefly' */
    LocalInsights = 'local', /* 'Local' */
    GlobalOperators = 'sales-intelligence', /* 'Local' */
    PriceMonitor = 'price-monitor', /*  */
}

export interface INavigationItem {
    name: string;
    legacyName?: string;
    dbName: string;
    Icon?: IconType;
    IconGs?: IconType;
    hoverData?: {
        description: string;
        bullets: string[];
        color: string;
    };
    appId?: NavigationItems;
    url?: string;
    isDisabled?: boolean;
    isInternal?: boolean;
    isDisabledGlobalVariableName?: keyof iGlobalUserData;
    hiddenFeatureFlag?: keyof iGlobalUserData['featureFlags'];
    visibleFeatureFlag?: keyof iGlobalUserData['featureFlags'];
}

export  interface INavigationSection {
    id: NavigationSectionsIds;
    title: string;
    items: INavigationItem[];
    hasIcons: boolean;
}

type AllItemsList = {
    [key in NavigationItems]: INavigationItem;
}

export const allItems: AllItemsList = {
    [NavigationItems.ReportPro]: {
        appId: NavigationItems.ReportPro,
        dbName: '',
        name: 'Report Pro',
        Icon: IconProductReportPro,
        IconGs: IconProductReportProGs,
        hoverData: {
            description: 'Vast reporting & insights library',
            color: '#D2000A',
            bullets: [
                'Access on-demand reports across hundreds of topics',
                'Create your own reading list from thousands of titles',
                'New presentation-ready content added weekly',
            ],
        }
    },
    [NavigationItems.MenuTrends]: {
        appId: NavigationItems.MenuTrends,
        dbName: '',
        name: 'Menu Trends',
        Icon: IconProductMenuTrends,
        IconGs: IconProductMenuTrendsGs,
        hoverData: {
            description: 'Food, beverage, and flavor trends',
            color: '#00B4FF',
            bullets: [
                'Identify trends early in their life cycle',
                'Track the fastest-moving trends and their predicted growth on menus',
                'Pull examples from over 10 million menu items',
            ],
        }
    },
    [NavigationItems.ConsumerPreferences]: {
        appId: NavigationItems.ConsumerPreferences,
        dbName: '',
        name: 'Consumer Preferences',
        Icon: IconProductFlavor,
        IconGs: IconProductFlavorGs,
        hoverData: {
            description: 'Consumer interest for thousands of foods, beverages, and flavors',
            color: '#EB0087',
            bullets: [
                'Track consumer awareness, consumption, and affinity',
                'Drive new product & concept development with MAC phases as your guide',
                'Narrow insights by gender, generation, ethnicity, eater type, and more',
            ],
        }
    },
    [NavigationItems.ConceptIdeation]: {
        appId: NavigationItems.ConceptIdeation,
        dbName: '',
        name: 'Concept Ideation',
        Icon: IconProductConceptIdeation,
        IconGs: IconProductConceptIdeationGs,
        hoverData: {
            description: 'Data-backed concept creation',
            color: '#D554F4',
            bullets: [
                'Create crave-worthy dishes in real-time',
                'Get instant feedback & validation as you create',
                'Use AI-enabled suggestions for boosting consumer appeal or uniqueness',
            ],
        }
    },
    [NavigationItems.LaunchesAndRatings]: {
        appId: NavigationItems.LaunchesAndRatings,
        dbName: '',
        name: 'Launches & Ratings',
        Icon: IconProductScores,
        IconGs: IconProductScoresGs,
        hoverData: {
            description: 'Monthly LTO tracking & deep analysis',
            color: '#FFCD00',
            bullets: [
                'Track LTOs & new items as they hit the menu',
                'Identify menu whitespace & seasonal launch patterns for nearly 300 chains',
                'Create your own customized watch list with new activity alerts',
                'Explore on-screen analytics for chain, c-store, grocery deli & meal kit introductions',
            ],
        }
    },
    [NavigationItems.ConceptLocker]: {
        appId: NavigationItems.ConceptLocker,
        dbName: '',
        name: 'Concept Locker',
        Icon: IconProductConceptLocker,
        IconGs: IconProductConceptLockerGs,
        hoverData: {
            description: 'Consumer ratings for all chain LTOs and your proprietary ideas',
            color: '#FFCD00',
            bullets: [
                'Access consumer ratings for new items & LTOs to pinpoint what’s working',
                'Test your concepts via quick screen or full, detailed exploration',
                'Benchmark your concepts against over 40k real-world LTOs',
                'Monitor the performance of competitive chain launches',
            ],
        }
    },
    [NavigationItems.Chains]: {
        appId: NavigationItems.Chains,
        dbName: 'Firefly Chains',
        name: 'U.S. Chains',
        Icon: IconProductFfChains,
        IconGs: IconProductFfChainsGs,
        hoverData: {
            description: 'Chain operator database',
            color: '#F52D00',
            bullets: [
                'Search & target over 17,000 chains',
                'Identify prospects by segment, geography, size, and more',
                'Track prior year sales growth & average unit volume',
                'Access the included annual Top 500 chains report',
            ],
        },
        isInternal: true,
        url: routeNames.fireflyChains,
        isDisabledGlobalVariableName: 'isFfChainsDisabled',

    },
    [NavigationItems.BrandPerformance]: {
        appId: NavigationItems.BrandPerformance,
        dbName: '',
        name: 'Brand Performance',
        Icon: IconProductFingerprints,
        IconGs: IconProductFingerprintsGs,
        hoverData: {
            description: 'Consumer ratings for chains',
            color: '#D200D7',
            bullets: [
                'Determine strengths & weaknesses for 200 restaurant chains<br/>plus the top grocery and convenience stores',
                'Track NPS, visitation, preference, performance, and more',
                'Generate instant reports and performance rankings for 100+ key metrics',
            ],
        }
    },
    [NavigationItems.Operators]: {
        appId: NavigationItems.Operators,
        dbName: '',
        name: 'U.S. & Canada Operators',
        Icon: IconProductFirefly,
        IconGs: IconProductFireflyGs,
        hoverData: {
            description: 'Sales Intelligence Platform',
            color: '#F52D00',
            bullets: [
                'Search & target over 1.8 million operators across 100+ key attributes',
                'Determine the true competitive set & demographic snapshot for any operator',
                'Explore the operator landscape for any area - from single zip to city, state, or region',
            ],
        }
    },
    [NavigationItems.LocalInsights]: {
        appId: NavigationItems.LocalInsights,
        dbName: '',
        name: 'Local Insights',
        Icon: IconProductLocal,
        IconGs: IconProductLocalGs,
        hoverData: {
            description: 'Hyper regional flavor analysis',
            color: '#0064F5',
            bullets: [
                'Identify where foods & flavors are most common or under-represented',
                'Optimize offerings for a local market',
                'Uncover regional insights for any specific chain footprint',
            ],
        }
    },
    [NavigationItems.PriceMonitor]: {
        appId: NavigationItems.PriceMonitor,
        dbName: 'Price Monitor',
        name: 'Price Monitor',
        Icon: IconProductPriceMonitor,
        IconGs: IconProductPriceMonitorGs,
        hoverData: {
            description: 'AI-powered benchmarking',
            color: '#FF9600',
            bullets: [
                'Identify price variations by item and geographic location for any chain restaurant',
                'Index menu items against nearby competitive locations or nationwide',
                'Track changes in price and availability of competitive menu items over time',
            ],
        }
    },
    [NavigationItems.GlobalOperators]: {
        appId: NavigationItems.GlobalOperators,
        dbName: '',
        name: 'Global Operators',
        Icon: IconProductFirefly,
        IconGs: IconProductFireflyGs,
        hoverData: {
            description: 'Sales Intelligence Platform',
            color: '#F52D00',
            bullets: [
                'Search and target millions of foodservice operators across more than 60 countries',
                'Uncover operator insights with more than 70 key attributes',
                'Explore the operator landscape for any area - from broad regions down to single postal codes',
            ],
        }
    },

}

export const sections: INavigationSection[] = [
    {
        id: NavigationSectionsIds.Industry,
        title: 'Industry',
        hasIcons: true,
        items: [
            allItems[NavigationItems.ReportPro],
        ],
    },
    {
        id: NavigationSectionsIds.MenuConsumer,
        title: 'Menu & Consumer',
        hasIcons: true,
        items: [
            allItems[NavigationItems.MenuTrends],
            allItems[NavigationItems.ConsumerPreferences],
            allItems[NavigationItems.ConceptIdeation],
            allItems[NavigationItems.LaunchesAndRatings],
            allItems[NavigationItems.ConceptLocker],
            allItems[NavigationItems.LocalInsights],
        ],
    },
    {
        id: NavigationSectionsIds.Chains,
        title: 'Chains',
        hasIcons: true,
        items: [
            allItems[NavigationItems.Chains],
            allItems[NavigationItems.BrandPerformance],
            allItems[NavigationItems.LaunchesAndRatings],
            allItems[NavigationItems.PriceMonitor],
        ],
    },
    {
        id: NavigationSectionsIds.SalesIntelligence,
        title: 'Sales Intelligence',
        hasIcons: true,
        items: [
            allItems[NavigationItems.Chains],
            allItems[NavigationItems.Operators],
            allItems[NavigationItems.GlobalOperators],
        ],
    },
];

export function useNavigationData(excludeRestProducts: boolean = false): INavigationSection[] {
    const { globalVariables } = useContext(AppContext);

    const result: INavigationSection[] = sections.map(section => ({
        ...section,
        items: section.items
            .filter(item => {
                if (item.hiddenFeatureFlag) {
                    return !globalVariables.featureFlags[item.hiddenFeatureFlag];
                } else if (item.visibleFeatureFlag) {
                    return globalVariables.featureFlags[item.visibleFeatureFlag];
                }
                return true;
            })
            .map(item => {
                if (!!item.isDisabledGlobalVariableName) {
                    return {
                        ...item,
                        isDisabled: item.isDisabledGlobalVariableName
                            ? !!globalVariables[item.isDisabledGlobalVariableName]
                            : true,
                    };
                }

                const navigationItemData = globalVariables.navigationItems.find(i => i.appId === item.appId);

                return {
                    ...item,
                    dbName: navigationItemData?.title || item.dbName || '',
                    url: navigationItemData?.url || '',
                    isDisabled: !navigationItemData?.hasAccess,
                };
            }),
    }));

    const restProducts = globalVariables.navigationItems.filter(i => !i.appId);

    if (restProducts.length > 0 && !excludeRestProducts) {
        result.push({
            id: NavigationSectionsIds.More,
            title: '...more',
            hasIcons: false,
            items: restProducts.map(i => ({
                name: i.title,
                dbName: i.title,
                url: i.url,
            })),
        });
    }

    return result;
}
