import { useCallback, useMemo, useState } from "react";
import { getUrlWithHttp } from "../../../../core/helpers";
import { Contact, iContact } from "../../../../core/models/Contact";
import Http from "../../../../core/fetch";
import useContacts from "../../../../core/apiHooks/useContacts";
import { PermissionLevelEnum } from "../../../../core/models/PermissionSection";
import { API__UserContactViewModel } from "../../../../core/apiSchema";
import { prepareContactsData } from "./Contacts.helpers";

interface iContactsData {
    chainId: string;
    chainLogo: string;
    searchQuery: string;
}

const useContactsData = ({ chainId, chainLogo, searchQuery }: iContactsData) => {
    const {
        data: {
            fireflyContacts: {
                data: fireflyContactsData = [],
                permissionLevel: fireflyContactsPermissionLevel,
            },
            myContacts: {
                data: myContactsData = [],
                permissionLevel: customContactsPermissionLevel,
            }
        },
        isLoading,
        reFetch
    } = useContacts(chainId);

    const customContacts: iContact[] = useMemo(() => {
        const data = myContactsData.map(el => ({
            ...el,
            linkedInProfilePhoto: chainLogo
        }));
        return prepareContactsData(data, searchQuery)
    }, [chainLogo, myContactsData, searchQuery]);

    const fireflyContacts: iContact[] = useMemo(() => {
        if ( fireflyContactsPermissionLevel === PermissionLevelEnum.None ) {
            return [new Contact(), new Contact()];
        }
        return prepareContactsData(fireflyContactsData, searchQuery);
    }, [fireflyContactsData, fireflyContactsPermissionLevel, searchQuery]);

    const [isUpdatingCustomContact, setIsUpdatingCustomContact] = useState(false);

    const saveContact = useCallback((item: iContact) => {
        setIsUpdatingCustomContact(true);
        const apiUrl = item.id ? 'edit chain contact' : 'create chain contact';

        const reqData: API__UserContactViewModel = {
            ...item,
            chainId,
            linkedInProfile: getUrlWithHttp(item.linkedInProfile)
        };

        Http.post(apiUrl, {}, {
            body: JSON.stringify(reqData),
            headers: {
                'content-type': 'application/json; charset=utf-8',
            },
        }, "none")
        .then(() => {
            reFetch();
        })
        .finally(() => {
            setIsUpdatingCustomContact(false);
        });
    }, [chainId, reFetch]);

    const removeContact = useCallback((id: number) => {
        setIsUpdatingCustomContact(true);
        Http.post('remove chain contact', { contactId: id }, {}, "none")
            .then(() => {
                reFetch();
            })
            .finally(() => {
                setIsUpdatingCustomContact(false);
            });
    }, [reFetch]);

    const requestForChange = useCallback(({item, message}: { item: iContact; message: string;}) => {
        setIsUpdatingCustomContact(true);

        const reqData: API__UserContactViewModel = {
            ...item,
            chainId,
        };

        Http.post('request for change contact', { message }, {
            body: JSON.stringify(reqData),
            headers: {
                'content-type': 'application/json; charset=utf-8',
            },
        }, "none")
        .then(() => {
            reFetch();
        })
        .finally(() => {
            setIsUpdatingCustomContact(false);
        });
    }, [chainId, reFetch]);

    const requestForAdd = useCallback((item: iContact) => {
        setIsUpdatingCustomContact(true);

        const reqData: API__UserContactViewModel = {
            ...item,
            chainId,
            linkedInProfile: getUrlWithHttp(item.linkedInProfile)
        };

        Http.post('request for add contact', {}, {
                body: JSON.stringify(reqData),
                headers: {
                    'content-type': 'application/json; charset=utf-8',
                },
            }, "none")
            .then(() => {
                reFetch();
            })
            .finally(() => {
                setIsUpdatingCustomContact(false);
            });
    }, [chainId, reFetch]);

    return {
        isLoading: isLoading || isUpdatingCustomContact,
        saveContact,
        removeContact,
        customContacts,
        fireflyContacts,
        requestForAdd,
        requestForChange,
        fireflyContactsPermissionLevel,
        customContactsPermissionLevel,
    };
};

export default useContactsData;
