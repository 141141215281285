import {
    MAC_DETAILS_FETCH_DATA_LOADED,
    MAC_DETAILS_FETCH_DATA_STARTED,
    MAC_DETAILS_FETCH_DATA_STORE,
    MAC_DETAILS_POST_BACKGROUND_STARTED,
    MAC_DETAILS_POST_BACKGROUND_UPLOADED,
    MAC_DETAILS_BACKGROUND_STORE,
    MAC_DETAILS_POST_DEFAULT_BACKGROUND_STARTED,
    MAC_DETAILS_POST_DEFAULT_BACKGROUND_LOADED,
    MAC_DETAILS_DEFAULT_BACKGROUND_STORE,
    MAC_MENUS_FETCH_DATA_STARTED,
    MAC_MENUS_FETCH_DATA_LOADED,
    MAC_MENUS_FETCH_DATA_STORE,
    MAC_MENUS_RESET_DATA,
    MAC_DOWNLOAD_LINK_STARTED,
    MAC_DOWNLOAD_LINK_LOADED,
    MAC_DOWNLOAD_LINK_STORE,
    MAC_DETAILS_BACKGROUND_SET_FILE_SIZE_STATUS
} from '../actions/actionsNamesList';

const defaultState = {
    hasCustomBgndImage: false,
    loading: false,
    uploadingBgImage: false,
    loadingDownloadLink: false,
    data: {},
    customBgUrl: '',
    defaultBgUrl: '',
    downloadUrl: '',
    dataTable: {
        data: [],
        recordsFiltered: 0,
        recordsTotal: 0
    },
    sizeIsExceeded: false
};

export default function macData(state = defaultState, action) {
    switch (action.type) {
        case MAC_DETAILS_BACKGROUND_SET_FILE_SIZE_STATUS: {
            return {
                ...state,
                sizeIsExceeded: action.data.sizeIsExceeded
            };
        }
        case MAC_DETAILS_FETCH_DATA_STARTED: {
            return {
                ...state,
                loading: true
            };
        }
        case MAC_DETAILS_FETCH_DATA_LOADED: {
            return {
                ...state,
                loading: false
            };
        }
        case MAC_DETAILS_FETCH_DATA_STORE: {
            return {
                ...state,
                data: action.data
            };
        }
        case MAC_DETAILS_POST_BACKGROUND_STARTED: {
            return {
                ...state,
                uploadingBgImage: true
            };
        }
        case MAC_DETAILS_POST_BACKGROUND_UPLOADED: {
            return {
                ...state,
                uploadingBgImage: false
            };
        }
        case MAC_DETAILS_BACKGROUND_STORE: {
            return {
                ...state,
                customBgUrl: action.data,
                data: Object.assign({},
                    state.data,
                    {options: Object.assign({},
                        state.data.options,
                        { hasCustomBgndImage: true })}),
            };
        }
        case MAC_DETAILS_POST_DEFAULT_BACKGROUND_STARTED: {
            return {
                ...state,
                resettingBgImage: true
            };
        }
        case MAC_DETAILS_POST_DEFAULT_BACKGROUND_LOADED: {
            return {
                ...state,
                resettingBgImage: false
            };
        }
        case MAC_DETAILS_DEFAULT_BACKGROUND_STORE: {
            return {
                ...state,
                defaultBgUrl: action.data,
                customBgUrl: '',
                data: Object.assign({},
                    state.data,
                    {options: Object.assign({},
                        state.data.options,
                        { hasCustomBgndImage: false })}),
            }
        }
        case MAC_MENUS_FETCH_DATA_STARTED: {
                return {
                    ...state,
                    loadingMenus: true
                };
            }
        case MAC_MENUS_FETCH_DATA_LOADED: {
            return {
                ...state,
                loadingMenus: false
            };
        }
        case MAC_MENUS_FETCH_DATA_STORE: {
            return {
                ...state,
                dataTable: action.data
            };
        }
        case MAC_MENUS_RESET_DATA: {
            // return {
            //     ...state,
            //     dataTable: {
            //         data: [],
            //         recordsFiltered: 0,
            //         recordsTotal: 0
            //     }
            // };
            return defaultState;
        }
        case MAC_DOWNLOAD_LINK_STARTED: {
            return {
                ...state,
                loadingDownloadLink: true
            };
        }
        case MAC_DOWNLOAD_LINK_LOADED: {
            return {
                ...state,
                loadingDownloadLink: false
            };
        }
        case MAC_DOWNLOAD_LINK_STORE: {
            return {
                ...state,
                downloadUrl: action.data
            };
        }
        default: {
            return state;
        }
    }
}
