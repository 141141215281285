import { FC, useState } from "react";
import Http from "../../core/fetch";
import Modal from "../Modal/Modal";
import LeadImage from "../../img/top1000popup.png";
import "./ChainPermissionPopup.styles.scss";
import Button from "../new/Button/Button";
import { Link } from "react-router-dom";
import { routeNames } from "../../core/routeNames";
import NotificationPopup from "../NotificationPopup/NotificationPopup";
import useNotification from "../../core/hooks/useNotification";
import { SectionEnum } from "../../core/enums";

interface iProps {
    isOpen: boolean;
}

const ChainPermissionPopup: FC<iProps> = ({ isOpen }) => {
    const { handleShowNotification, isOpen: isOpenNotification } = useNotification();
    const [isRequestSent, setIsRequestSend] = useState(false);

    const handleUnlockRequest = () => {
        Http.get('unlock request', { leadSource: SectionEnum.Chain }, {}, "none");
        handleShowNotification();
        setIsRequestSend(true);
    }

    return (
        <Modal
            isOpened={isOpen}
            close={() => {}}
            className="ChainPermissionPopup__modal"
            isHiddenCloseButton
            closeOnOutsideClick={false}
            closeOnEsc={false}
        >
            <div className="ChainPermissionPopup">
                <div className="ChainPermissionPopup__title">
                    Ooops, it looks like this page is only available<br/>
                    to subscribers of Firefly features
                </div>
                <img src={LeadImage} alt="" className="ChainPermissionPopup__image" />

                <div className="ChainPermissionPopup__footer">
                    <div className="ChainPermissionPopup__message">
                        If you are interested in gaining access to Top Chains data, please contact <br/>
                        <a href="mailto:saleslead@datassential.com">saleslead@datassential.com</a>

                        <NotificationPopup
                            isOpened={isOpenNotification}
                            hasCloseButton={false}
                            style={{ width: "auto", height: "auto" }}
                            className="notification-popup--table ChainPermissionPopup__notification"
                        >
                            <span>Thank you for your request, we will get back to you shortly</span>
                        </NotificationPopup>
                    </div>
                    <div className="ChainPermissionPopup__actions">
                        <div className="ChainPermissionPopup__actions-item">

                            <Link
                                className="ChainPermissionPopup__link"
                                to={{ pathname: routeNames.home }}
                            >
                                Back to Homepage
                            </Link>
                        </div>
                        <div className="ChainPermissionPopup__actions-item">
                            <Button
                                modifiers={["primary"]}
                                onClick={handleUnlockRequest}
                                isDisabled={isRequestSent}
                            >
                                Get In Touch
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ChainPermissionPopup;
