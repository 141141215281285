import React, { FC, ReactNode } from "react";

export const highlightValue = (value: string, searchQuery: string) => {
    const searchRegExp = new RegExp(`(${searchQuery})`, "gi");
    const renderTitle = searchQuery === "" ? value : value.replace(searchRegExp, "<b>$1</b>");
    return <span dangerouslySetInnerHTML={{ __html: renderTitle }} />;
}
export const TABLE_ROW_SIZE = 40;
export const TABLE_HEAD_SIZE = 60;

export interface iTableCellStyles {
    cell?: React.CSSProperties;
    head?: React.CSSProperties;
}

export interface iTableConfigItem {
    title?: string;
    renderTitle?: ReactNode;
    width?: number | string;
    Value: FC<{ index: number }>;
    tooltip?: string;
    textCentered?: boolean;
    styles?: iTableCellStyles;
    handleHeaderClick?: () => void;
    icon?: ReactNode;
    isActive?: boolean;
    isVisibileOnHover?: boolean;
}
