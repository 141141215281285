import { useData } from "@hooks/useData";
import {
    API__ChainPermissionWrapper,
    API__PenetrationPairedFlavorsMostCommonViewModel,
} from "@apiSchema";
import http from '../fetch';
import { FoodProfilePairedItems } from "@models/FoodProfilePairedItems";

async function getFoodProfilePairedFlavorsData(word: string): Promise<FoodProfilePairedItems> {
    try {
        const response: API__ChainPermissionWrapper<API__PenetrationPairedFlavorsMostCommonViewModel[]> = await http.get('food tab paired data', { word });

        return new FoodProfilePairedItems({
            apiModel: response,
        });
    }
    catch (err) {
        return new FoodProfilePairedItems();
    }
}

export function useFoodProfilePairedFlavorsData(word: string) {
    return useData(new FoodProfilePairedItems(), getFoodProfilePairedFlavorsData, word);

}