import { API__SRLtoWithScoresDataViewModel } from "../apiSchema";
import { iPermissionFields, PermissionLevelEnum, PermissionSection } from "./PermissionSection";

const DEFAULT_LTO_PRICE = 350;

interface iLtoScoresData {
    downloadPptLink: string;
    score: number;
    link: string;
    viability: string;
    indexes: iScore[];
    price: number;
}

export interface iLTO {
    id: number;
    chainName: string;
    date: string;
    description: string;
    imageUrl: string;
    isScoresItem: boolean;
    name: string;
    price: string;
    scoresData: iPermissionFields & {
        data: iLtoScoresData;
    };
}

export interface iScore {
    key: string;
    value: number;
    starsCount: number;
}

interface iData {
    apiModel?: API__SRLtoWithScoresDataViewModel;
}

export class LTO implements iLTO {
    static defaultData: iLTO = {
        id: 0,
        chainName: "",
        date: "",
        description: "",
        imageUrl: "",
        isScoresItem: false,
        name: "",
        price: '-',
        scoresData: {
            ...new PermissionSection(),
            data: {
                downloadPptLink: '',
                indexes: [],
                score: 0,
                viability: '',
                link: '',
                price: 0,
            },
        },
    };

    id = LTO.defaultData.id;
    chainName = LTO.defaultData.chainName;
    date = LTO.defaultData.date;
    description = LTO.defaultData.description;
    imageUrl = LTO.defaultData.imageUrl;
    isScoresItem = LTO.defaultData.isScoresItem;
    name = LTO.defaultData.name;
    price = LTO.defaultData.price;
    scoresData = LTO.defaultData.scoresData;

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private setData(data: iLTO) {
        ({
            id: this.id,
            chainName: this.chainName,
            date: this.date,
            description: this.description,
            imageUrl: this.imageUrl,
            isScoresItem: this.isScoresItem,
            name: this.name,
            price: this.price,
            scoresData: this.scoresData,
        } = data);
    }

    private mapFromApi(data: API__SRLtoWithScoresDataViewModel) {
        const result: iLTO = {
            id: data.id || LTO.defaultData.id,
            chainName: data.chainName || LTO.defaultData.chainName,
            name: data.name || LTO.defaultData.name,
            date: data.date || LTO.defaultData.date,
            description: data.description || LTO.defaultData.description,
            imageUrl: data.imageUrl || LTO.defaultData.imageUrl,
            isScoresItem: data.isScoresItem || LTO.defaultData.isScoresItem,
            price: data.price || LTO.defaultData.price,
            scoresData: {
                permissionLevel: PermissionSection.getPermissionLevel(data.scoresData.permissionLevel),
                hasData: data.scoresData.hasData,
                data: {
                    link: data.link,
                    downloadPptLink: data.scoresData.data?.downloadPptLink || LTO.defaultData.scoresData.data.downloadPptLink,
                    score: data.scoresData.data?.score || LTO.defaultData.scoresData.data.score,
                    viability: data.scoresData.data?.viability || LTO.defaultData.scoresData.data.viability,
                    indexes: (data.scoresData.data?.indexes || []).map(({ key, value, starsCount }) => {
                        return {
                            key,
                            value: value || 0,
                            starsCount,
                        };
                    }),
                    price: DEFAULT_LTO_PRICE,
                },
            },
        }
        this.setData(result);
    }
}
