import React, { CSSProperties, Ref, useCallback } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import "./CustomScrollbarsVirtualList.styles.scss";

export const RenderVerticalThumb: React.FC = (props: any) => {
    const thumbStyle = {
        borderRadius: "20px",
        zIndex: 200,
    };
    return (
        <div
            {...props}
            style={{ ...props.style, ...thumbStyle }}
            className="CustomScrollbarsVirtualList__thumb"

        >
            <div className="CustomScrollbarsVirtualList__thumb-decor"/>
        </div>
    );
};

interface iCustomBaseScrollbars {
    refSetter?: any;
    style?: any;
    onScroll?: any;
    children: any;
    id?: string;
    autoHeight?: boolean;
    autoHeightMax?: number | string;
}
export const CustomBaseScrollbars: React.FC<iCustomBaseScrollbars> = (
    {
        refSetter,
        style,
        onScroll,
        children,
        id,
        autoHeight = false,
        autoHeightMax,
    }
) => {
    return (
        <Scrollbars
            renderThumbVertical={RenderVerticalThumb}
            renderTrackHorizontal={() => <div style={{ display: "none" }}/>}
            renderTrackVertical={props => <div {...props} className="CustomScrollbarsVirtualList__track" style={{width: 8}}/>}
            renderView={props => <div {...props} id={id} className="CustomScrollbarsVirtualList__view"/>}
            ref={refSetter}
            hideTracksWhenNotNeeded
            universal
            style={{
                ...style,
                overflow: "hidden",
            }}
            onScroll={onScroll}
            className="CustomScrollbarsVirtualList"
            autoHeight={autoHeight}
            autoHeightMax={autoHeightMax}
        >
            {children}
        </Scrollbars>
    )
}

interface iProps {
    onScroll: () => void;
    forwardedRef: Ref<any>;
    style: CSSProperties;
}

const CustomScrollbarsVirtualList: React.FC<any> = ({
    onScroll,
    forwardedRef,
    style,
    children,
}) => {
    const refSetter = useCallback(
        scrollbarsRef => {
            if (scrollbarsRef) {
                forwardedRef(scrollbarsRef.view);
            } else {
                forwardedRef(null);
            }
        },
        [forwardedRef],
    );

    return (
        <CustomBaseScrollbars refSetter={refSetter} style={style} onScroll={onScroll}>
            {children}
        </CustomBaseScrollbars>
    );
};

export default React.forwardRef((props: any, ref: any) => (
    <CustomScrollbarsVirtualList {...props} forwardedRef={ref}/>
));
