import { ReactComponent as AddToPackage } from "./add-to-package.svg";
import { ReactComponent as Add } from "./add-icon.svg";
import { ReactComponent as Admin } from "./admin.svg";
import { ReactComponent as Adoption } from "./adoption.svg";
import { ReactComponent as ArrowProject } from "./arrow_project.svg";
import { ReactComponent as AutoSuggestDocuments } from "./auto_suggest_documents.svg";
import { ReactComponent as AutoSuggestFood } from "./auto_suggest_food.svg";
import { ReactComponent as AutoSuggestGo } from "./auto_suggest_go.svg";
import { ReactComponent as AutoSuggestPlaces } from "./auto_suggest_places.svg";
import { ReactComponent as BackArrow } from "./back_arrow.svg";
import { ReactComponent as BrandfingerprintsLogoVioletWhite } from "./BrandFingerprints_Logo_Violet_White.svg";
import { ReactComponent as Buzz } from "./buzz.svg";
import { ReactComponent as BuzzGrayscale } from "./buzz-grayscale.svg";
import { ReactComponent as Check } from "./check.svg";
import { ReactComponent as CheckNew } from "./check-new.svg";
import { ReactComponent as CheckboxFake } from "./checkbox-fake.svg";
import { ReactComponent as Chop } from "./chop.svg";
import { ReactComponent as ChopGreenDark } from "./chop-green-dark.svg";
import { ReactComponent as Close } from "./close.svg";
import { ReactComponent as Copy } from "./copy-icon.svg";
import { ReactComponent as Cuisine } from "./cuisine.svg";
import { ReactComponent as Custom } from "./custom.svg";
import { ReactComponent as CustomGrayscale } from "./custom-grayscale.svg";
import { ReactComponent as DatassentialLogo } from "./datassential-logo.svg";
import { ReactComponent as Delete } from "./delete.svg";
import { ReactComponent as Discover } from "./discover.svg";
import { ReactComponent as Docs } from "./docs.svg";
import { ReactComponent as DocumentAdd } from "./document-add.svg";
import { ReactComponent as DoubleArrowDown } from "./double-arrow-down.svg";
import { ReactComponent as Download } from "./download.svg";
import { ReactComponent as DragNDrop } from "./drag-n-drop.svg";
import { ReactComponent as Dragonfly } from "./dragonfly.svg";
import { ReactComponent as DragonflyGrayscale } from "./dragonfly-grayscale.svg";
import { ReactComponent as DragonflyLogoGreenWhite } from "./Dragonfly_Logo_Green_White.svg";
import { ReactComponent as DropdownArrow } from "./dropdown-arrow.svg";
import { ReactComponent as Edit } from "./edit.svg";
import { ReactComponent as EditBlue } from "./edit_blue.svg";
import { ReactComponent as EditDark } from "./edit_dark.svg";
import { ReactComponent as Expand } from "./expand.svg";
import { ReactComponent as Explore } from "./explore.svg";
import { ReactComponent as ExploreGradient } from "./explore-gradient.svg";
import { ReactComponent as ExploreGradientText } from "./explore-gradient-text.svg";
import { ReactComponent as F3Title } from "./F3_title.svg";
import { ReactComponent as F3logo } from "./F3Logo.svg";
import { ReactComponent as F3logoDark } from "./F3Logo-dark.svg";
import { ReactComponent as F3logoText } from "./F3Logo-text.svg";
import { ReactComponent as Facebook } from "./facebook-icon.svg";
import { ReactComponent as FileAif } from "./file-aif.svg";
import { ReactComponent as FileAifBw } from "./file-aif-bw.svg";
import { ReactComponent as FileAifInner } from "./file-aif-inner.svg";
import { ReactComponent as FileArc } from "./file-arc.svg";
import { ReactComponent as FileArcBw } from "./file-arc-bw.svg";
import { ReactComponent as FileArcInner } from "./file-arc-inner.svg";
import { ReactComponent as FileAvi } from "./file-avi.svg";
import { ReactComponent as FileAviBw } from "./file-avi-bw.svg";
import { ReactComponent as FileAviInner } from "./file-avi-inner.svg";
import { ReactComponent as FileBmp } from "./file-bmp.svg";
import { ReactComponent as FileBmpBw } from "./file-bmp-bw.svg";
import { ReactComponent as FileBmpInner } from "./file-bmp-inner.svg";
import { ReactComponent as FileCsv } from "./file-csv.svg";
import { ReactComponent as FileCsvBw } from "./file-csv-bw.svg";
import { ReactComponent as FileCsvInner } from "./file-csv-inner.svg";
import { ReactComponent as FileDoc } from "./file-doc.svg";
import { ReactComponent as FileDocBw } from "./file-doc-bw.svg";
import { ReactComponent as FileDocInner } from "./file-doc-inner.svg";
import { ReactComponent as FileFvl } from "./file-fvl.svg";
import { ReactComponent as FileFvlBw } from "./file-fvl-bw.svg";
import { ReactComponent as FileFvlInner } from "./file-fvl-inner.svg";
import { ReactComponent as FileGif } from "./file-gif.svg";
import { ReactComponent as FileGifBw } from "./file-gif-bw.svg";
import { ReactComponent as FileGifInner } from "./file-gif-inner.svg";
import { ReactComponent as FileJpg } from "./file-jpg.svg";
import { ReactComponent as FileJpgBw } from "./file-jpg-bw.svg";
import { ReactComponent as FileJpgInner } from "./file-jpg-inner.svg";
import { ReactComponent as FileMov } from "./file-mov.svg";
import { ReactComponent as FileMovBw } from "./file-mov-bw.svg";
import { ReactComponent as FileMovInner } from "./file-mov-inner.svg";
import { ReactComponent as FileMp3 } from "./file-mp3.svg";
import { ReactComponent as FileMp3Bw } from "./file-mp3-bw.svg";
import { ReactComponent as FileMp3Inner } from "./file-mp3-inner.svg";
import { ReactComponent as FileMp4 } from "./file-mp4.svg";
import { ReactComponent as FileMp4Bw } from "./file-mp4-bw.svg";
import { ReactComponent as FileMp4Inner } from "./file-mp4-inner.svg";
import { ReactComponent as FileOther } from "./file-other.svg";
import { ReactComponent as FileOtherBw } from "./file-other-bw.svg";
import { ReactComponent as FileOtherInner } from "./file-other-inner.svg";
import { ReactComponent as FilePdf } from "./file-pdf.svg";
import { ReactComponent as FilePdfBw } from "./file-pdf-bw.svg";
import { ReactComponent as FilePdfInner } from "./file-pdf-inner.svg";
import { ReactComponent as FilePng } from "./file-png.svg";
import { ReactComponent as FilePngBw } from "./file-png-bw.svg";
import { ReactComponent as FilePngInner } from "./file-png-inner.svg";
import { ReactComponent as FilePpt } from "./file-ppt.svg";
import { ReactComponent as FilePptBw } from "./file-ppt-bw.svg";
import { ReactComponent as FilePptInner } from "./file-ppt-inner.svg";
import { ReactComponent as FileRtf } from "./file-rtf.svg";
import { ReactComponent as FileRtfBw } from "./file-rtf-bw.svg";
import { ReactComponent as FileRtfInner } from "./file-rtf-inner.svg";
import { ReactComponent as FileTxt } from "./file-txt.svg";
import { ReactComponent as FileTxtBw } from "./file-txt-bw.svg";
import { ReactComponent as FileTxtInner } from "./file-txt-inner.svg";
import { ReactComponent as FileWma } from "./file-wma.svg";
import { ReactComponent as FileWmaBw } from "./file-wma-bw.svg";
import { ReactComponent as FileWmaInner } from "./file-wma-inner.svg";
import { ReactComponent as FileWmf } from "./file-wmf.svg";
import { ReactComponent as FileWmfBw } from "./file-wmf-bw.svg";
import { ReactComponent as FileWmfInner } from "./file-wmf-inner.svg";
import { ReactComponent as FileXls } from "./file-xls.svg";
import { ReactComponent as FileXlsBw } from "./file-xls-bw.svg";
import { ReactComponent as FileXlsInner } from "./file-xls-inner.svg";
import { ReactComponent as FileZip } from "./file-zip.svg";
import { ReactComponent as FileZipBw } from "./file-zip-bw.svg";
import { ReactComponent as FileZipInner } from "./file-zip-inner.svg";
import { ReactComponent as Files } from "./files.svg";
import { ReactComponent as Filters } from "./filters.svg";
import { ReactComponent as Fingerprints } from "./fingerprints.svg";
import { ReactComponent as FingerprintsGrayscale } from "./fingerprints-grayscale.svg";
import { ReactComponent as Firefly } from "./firefly.svg";
import { ReactComponent as FireflyGrayscale } from "./firefly-grayscale.svg";
import { ReactComponent as FireflyLogo } from "./firefly_logo.svg";
import { ReactComponent as FireflyLogoOrangeWhite } from "./Firefly_Logo_Orange_White.svg";
import { ReactComponent as Flavor } from "./flavor.svg";
import { ReactComponent as FlavorEnchanced } from "./flavor-enchanced.svg";
import { ReactComponent as FlavorGrayscale } from "./flavor-grayscale.svg";
import { ReactComponent as FlavorLogoPinkWhite } from "./Flavor_Logo_Pink_White.svg";
import { ReactComponent as FoodscapeLogoBig } from "./foodscape-logo-big.svg";
import { ReactComponent as FoodscapeLogoWhite } from "./foodscape-logo-white.svg";
import { ReactComponent as FoodstudioLogoLimeWhite } from "./FoodStudio_Logo_Lime_White.svg";
import { ReactComponent as FoodstudioSidebar } from "./foodstudio-sidebar-icon.svg";
import { ReactComponent as FoodstudioSidebarGrayscale } from "./foodstudio-sidebar-icon-grayscale.svg";
import { ReactComponent as Foursquare } from "./foursquare-icon.svg";
import { ReactComponent as Fullscreen } from "./fullscreen.svg";
import { ReactComponent as GoLogo } from "./GO_logo.svg";
import { ReactComponent as Google } from "./google-icon.svg";
import { ReactComponent as GrandeDragonfly } from "./grande_dragonfly.svg";
import { ReactComponent as GrandeDragonflyGrayscale } from "./grande_dragonfly-grayscale.svg";
import { ReactComponent as Group } from "./group-icon.svg";
import { ReactComponent as Groups } from "./groups.svg";
import { ReactComponent as Haiku } from "./haiku.svg";
import { ReactComponent as HaikuBig } from "./haiku-big.svg";
import { ReactComponent as HaikuBigNew } from "./haiku-big-new.svg";
import { ReactComponent as HaikuColored } from "./haiku-colored.svg";
import { ReactComponent as HaikuLine } from "./haiku-line.svg";
import { ReactComponent as Heart } from "./heart.svg";
import { ReactComponent as Help } from "./help-icon.svg";
import { ReactComponent as ExportExcel } from "./icon-export-excel.svg";
import { ReactComponent as ExportPdf } from "./icon-export-pdf.svg";
import { ReactComponent as Pdf } from "./icon-pdf.svg";
import { ReactComponent as Ppt } from "./icon-ppt.svg";
import { ReactComponent as Xls } from "./icon-xls.svg";
import { ReactComponent as Impersonate } from "./impersonate.svg";
import { ReactComponent as Inception } from "./inception.svg";
import { ReactComponent as Info } from "./info.svg";
import { ReactComponent as InfoStokes } from "./info_stokes.svg";
import { ReactComponent as Insider } from "./insider.svg";
import { ReactComponent as InsiderGrayscale } from "./insider-grayscale.svg";
import { ReactComponent as InsiderLogoOrangeWhite } from "./Insider_Logo_Orange_White.svg";
import { ReactComponent as Instagram } from "./instagram-icon.svg";
import { ReactComponent as Linex4x3 } from "./line_4_3.svg";
import { ReactComponent as Linex16x9 } from "./line_16_9.svg";
import { ReactComponent as Link } from "./link.svg";
import { ReactComponent as LinkBlue } from "./link_blue.svg";
import { ReactComponent as LinkDark } from "./link_dark.svg";
import { ReactComponent as Linkedin } from "./linkedin-icon.svg";
import { ReactComponent as LinkedinColored } from "./linkedin-icon-colored.svg";
import { ReactComponent as Lock } from "./lock.svg";
import { ReactComponent as Local } from "./local.svg";
import { ReactComponent as LocalEnchanced } from "./local-enchanced.svg";
import { ReactComponent as LocalGrayscale } from "./local-grayscale.svg";
import { ReactComponent as LocalLogoBlueWhite } from "./Local_Logo_Blue_White.svg";
import { ReactComponent as Location } from "./location.svg";
import { ReactComponent as LogOut } from "./log-out.svg";
import { ReactComponent as LogoClicked } from "./logo-clicked.svg";
import { ReactComponent as LogoCollapsed } from "./logo-collapsed.svg";
import { ReactComponent as LogoDark } from "./logo-dark.svg";
import { ReactComponent as LogoDefault } from "./logo-default.svg";
import { ReactComponent as LogoGradient } from "./logo-gradient.svg";
import { ReactComponent as LogoHovered } from "./logo-hovered.svg";
import { ReactComponent as LogoNormal } from "./logo-normal.svg";
import { ReactComponent as LogoPin } from "./logo-pin.svg";
import { ReactComponent as Mac } from "./mac.svg";
import { ReactComponent as Menutrends } from "./menutrends.svg";
import { ReactComponent as MenutrendsGrayscale } from "./menutrends-grayscale.svg";
import { ReactComponent as MenutrendsLogoBlueWhite } from "./MenuTrends_Logo_Blue_White.svg";
import { ReactComponent as MiAddToPackage } from "./mi-add-to-package.svg";
import { ReactComponent as MiChain } from "./mi-chain.svg";
import { ReactComponent as MiDownload } from "./mi-download.svg";
import { ReactComponent as MiInsider } from "./mi-insider.svg";
import { ReactComponent as MiScores } from "./mi-scores.svg";
import { ReactComponent as MyDocuments } from "./my-documents.svg";
import { ReactComponent as Nestle } from "./nestle.svg";
import { ReactComponent as Order } from "./order.svg";
import { ReactComponent as OrderArrows } from "./order-arrows.svg";
import { ReactComponent as OrderReverse } from "./order-reverse.svg";
import { ReactComponent as Packages } from "./packages-icon.svg";
import { ReactComponent as PackagesRemove } from "./packages-remove-icon.svg";
import { ReactComponent as Pause } from "./pause.svg";
import { ReactComponent as Phone } from "./phone.svg";
import { ReactComponent as PinnacleDragonfly } from "./pinnacle_dragonfly.svg";
import { ReactComponent as PinnacleDragonflyGrayscale } from "./pinnacle_dragonfly-grayscale.svg";
import { ReactComponent as Pinterest } from "./pinterest-icon.svg";
import { ReactComponent as Play } from "./play.svg";
import { ReactComponent as PptO } from "./ppt-o.svg";
import { ReactComponent as PrivateNote } from "./private-note.svg";
import { ReactComponent as Project } from "./project-icon.svg";
import { ReactComponent as ProjectBw } from "./project-icon-bw.svg";
import { ReactComponent as Proliferation } from "./proliferation.svg";
import { ReactComponent as PublicNote } from "./public-note.svg";
import { ReactComponent as Remove } from "./remove.svg";
import { ReactComponent as Sample } from "./sample.svg";
import { ReactComponent as Scores } from "./scores.svg";
import { ReactComponent as ScoresNew } from "./scores_new.svg";
import { ReactComponent as ScoresGrayscale } from "./scores-grayscale.svg";
import { ReactComponent as ScoresLaunchNewButton } from "./Scores_Launch_NEW_Button.svg";
import { ReactComponent as ScoresLogoYellowWhite } from "./Scores_Logo_Yellow_White.svg";
import { ReactComponent as Search } from "./search.svg";
import { ReactComponent as SearchSmall } from "./search-small.svg";
import { ReactComponent as SearchWidgetBg } from "./search-widget-bg.svg";
import { ReactComponent as Segment } from "./segment.svg";
import { ReactComponent as SendEmail } from "./send-email.svg";
import { ReactComponent as Share } from "./share.svg";
import { ReactComponent as ShareLink } from "./share_link.svg";
import { ReactComponent as Snap } from "./snap.svg";
import { ReactComponent as SnapAssistantQa } from "./snap-assistant-qa.svg";
import { ReactComponent as SnapAssistantTrends } from "./snap-assistant-trends.svg";
import { ReactComponent as SnapAssistantVoice } from "./snap-assistant-voice.svg";
import { ReactComponent as Speaker } from "./speaker.svg";
import { ReactComponent as SpeakerMuted } from "./speaker-muted.svg";
import { ReactComponent as Star } from "./star.svg";
import { ReactComponent as State } from "./state.svg";
import { ReactComponent as Success } from "./success.svg";
import { ReactComponent as Successx2 } from "./success-2.svg";
import { ReactComponent as Strengths } from "./strengths.svg";
import { ReactComponent as StrengthsItem } from "./strengths-item.svg";
import { ReactComponent as Twitter } from "./twitter-icon.svg";
import { ReactComponent as TwitterNew } from "./twitter-new.svg";
import { ReactComponent as Ubiquity } from "./ubiquity.svg";
import { ReactComponent as Units } from "./units.svg";
import { ReactComponent as User } from "./user.svg";
import { ReactComponent as Wiki } from "./wiki-icon.svg";
import { ReactComponent as Web } from "./web.svg";
import { ReactComponent as XlsO } from "./xls-o.svg";
import { ReactComponent as Yelp } from "./yelp.svg";
import { ReactComponent as YelpNew } from "./yelp-new.svg";
import { ReactComponent as Youtube } from "./youtube-icon.svg";
import { ReactComponent as YoutubeNew } from "./youtube-new.svg";
import { ReactComponent as Arrow } from "./arrow.svg";
import { ReactComponent as ViewList } from "./view_list.svg";
import { ReactComponent as ViewTiles } from "./view_tiles.svg";
import { ReactComponent as EditWhite } from "./edit_white.svg";
import { ReactComponent as LinkedinSimple } from "./linkedin.svg";
import { ReactComponent as CopyTable } from "./copy-table.svg";
import { ReactComponent as DownloadSimple } from "./download-simple.svg";
import { ReactComponent as Menu } from "./menu.svg";
import { ReactComponent as ReportPro } from "./ReportPro.svg";
import { ReactComponent as SearchWhite } from "./icon_search_white.svg";
import { ReactComponent as FireflyChains } from "./FireflyChains.svg";
import { ReactComponent as FireflyChainsInverse } from "./firefly-logo-inverse.svg";
import { ReactComponent as PublicNoteNew } from "./public.svg";
import { ReactComponent as PrivateNoteNew } from "./private.svg";
import { ReactComponent as Funnel } from "./funnel.svg";
import { ReactComponent as FunnelDescription } from "./funnel-description.svg";
import { ReactComponent as Weaknesses } from "./weaknesses.svg";
import { ReactComponent as WeaknessesItem } from "./weaknesses-item.svg";
import { ReactComponent as NotesAdd } from "./notes_add.svg";
import { ReactComponent as SocialZomato } from './zomato.svg';
import { ReactComponent as SocialTumblr } from './tumblr.svg';
import { ReactComponent as SocialSnapchat } from './snapchat.svg';
import { ReactComponent as SocialPinterest } from './pinterest.svg';
import { ReactComponent as ArrowLeft } from './Arrow Left.svg';
import { ReactComponent as ArrowRight } from './Arrow Right.svg';
import { ReactComponent as DsLogoNewSmall } from './DS_logo_new_small.svg';
import { ReactComponent as ProductFfChains } from './products/ff-chains.svg';
import { ReactComponent as ProductFingerprints } from './products/fingerprints.svg';
import { ReactComponent as ProductFirefly } from './products/firefly.svg';
import { ReactComponent as ProductFlavor } from './products/flavor.svg';
import { ReactComponent as ProductFlavorMinimal } from './products/flavor-minimal.svg';
import { ReactComponent as ProductFoodStudio } from './products/foodstudio.svg';
import { ReactComponent as ProductConceptIdeation } from './products/concept-ideation.svg';
import { ReactComponent as ProductInsider } from './products/insider.svg';
import { ReactComponent as ProductLocal } from './products/local.svg';
import { ReactComponent as ProductMenuTrends } from './products/menutrends.svg';
import { ReactComponent as ProductPriceMonitor } from './products/price-monitor.svg';
import { ReactComponent as ProductReportPro } from './products/reportpro.svg';
import { ReactComponent as ProductScores } from './products/scores.svg';
import { ReactComponent as ProductConceptLocker } from './products/concept-locker.svg';
import { ReactComponent as ProductMenucast } from './products/menucast.svg';
import { ReactComponent as ProductFfChainsGs } from './products/ff-chains-grayscale.svg';
import { ReactComponent as ProductFingerprintsGs } from './products/fingerprints-grayscale.svg';
import { ReactComponent as ProductFireflyGs } from './products/firefly-grayscale.svg';
import { ReactComponent as ProductFlavorGs } from './products/flavor-grayscale.svg';
import { ReactComponent as ProductFoodStudioGs } from './products/foodstudio-grayscale.svg';
import { ReactComponent as ProductConceptIdeationGs } from './products/concept-ideation-grayscale.svg';
import { ReactComponent as ProductInsiderGs } from './products/insider-grayscale.svg';
import { ReactComponent as ProductLocalGs } from './products/local-grayscale.svg';
import { ReactComponent as ProductMenuTrendsGs } from './products/menutrends-grayscale.svg';
import { ReactComponent as ProductPriceMonitorGs } from './products/price-monitor-grayscale.svg';
import { ReactComponent as ProductReportProGs } from './products/reportpro-grayscale.svg';
import { ReactComponent as ProductScoresGs } from './products/scores-grayscale.svg';
import { ReactComponent as ProductConceptLockerGs } from './products/concept-locker-grayscale.svg';
import { ReactComponent as LogoSnapDs } from './logo-snap-ds.svg';
import { ReactComponent as LogoSnapDsIcon } from './logo-snap-ds-icon.svg';
import { ReactComponent as Question } from './question.svg';

import { FunctionComponent, SVGProps } from "react";

export type IconType = FunctionComponent<SVGProps<SVGSVGElement>>;

export const IconAdd: IconType = Add;
export const IconAddToPackage: IconType = AddToPackage;
export const IconAdmin: IconType = Admin;
export const IconAdoption: IconType = Adoption;
export const IconArrowProject: IconType = ArrowProject;
export const IconAutoSuggestDocuments: IconType = AutoSuggestDocuments;
export const IconAutoSuggestFood: IconType = AutoSuggestFood;
export const IconAutoSuggestGo: IconType = AutoSuggestGo;
export const IconAutoSuggestPlaces: IconType = AutoSuggestPlaces;
export const IconBackArrow: IconType = BackArrow;
export const IconBrandfingerprintsLogoVioletWhite: IconType = BrandfingerprintsLogoVioletWhite;
export const IconBuzz: IconType = Buzz;
export const IconBuzzGrayscale: IconType = BuzzGrayscale;
export const IconCheck: IconType = Check;
export const IconCheckNew: IconType = CheckNew;
export const IconCheckboxFake: IconType = CheckboxFake;
export const IconChop: IconType = Chop;
export const IconChopGreenDark: IconType = ChopGreenDark;
export const IconClose: IconType = Close;
export const IconCopy: IconType = Copy;
export const IconCuisine: IconType = Cuisine;
export const IconCustom: IconType = Custom;
export const IconCustomGrayscale: IconType = CustomGrayscale;
export const IconDatassentialLogo: IconType = DatassentialLogo;
export const IconDelete: IconType = Delete;
export const IconDiscover: IconType = Discover;
export const IconDocs: IconType = Docs;
export const IconDocumentAdd: IconType = DocumentAdd;
export const IconDoubleArrowDown: IconType = DoubleArrowDown;
export const IconDownload: IconType = Download;
export const IconDragNDrop: IconType = DragNDrop;
export const IconDragonfly: IconType = Dragonfly;
export const IconDragonflyGrayscale: IconType = DragonflyGrayscale;
export const IconDragonflyLogoGreenWhite: IconType = DragonflyLogoGreenWhite;
export const IconDropdownArrow: IconType = DropdownArrow;
export const IconEdit: IconType = Edit;
export const IconEditBlue: IconType = EditBlue;
export const IconEditDark: IconType = EditDark;
export const IconExpand: IconType = Expand;
export const IconExplore: IconType = Explore;
export const IconExploreGradient: IconType = ExploreGradient;
export const IconExploreGradientText: IconType = ExploreGradientText;
export const IconF3Title: IconType = F3Title;
export const IconF3logo: IconType = F3logo;
export const IconF3logoDark: IconType = F3logoDark;
export const IconF3logoText: IconType = F3logoText;
export const IconFacebook: IconType = Facebook;
export const IconFileAif: IconType = FileAif;
export const IconFileAifBw: IconType = FileAifBw;
export const IconFileAifInner: IconType = FileAifInner;
export const IconFileArc: IconType = FileArc;
export const IconFileArcBw: IconType = FileArcBw;
export const IconFileArcInner: IconType = FileArcInner;
export const IconFileAvi: IconType = FileAvi;
export const IconFileAviBw: IconType = FileAviBw;
export const IconFileAviInner: IconType = FileAviInner;
export const IconFileBmp: IconType = FileBmp;
export const IconFileBmpBw: IconType = FileBmpBw;
export const IconFileBmpInner: IconType = FileBmpInner;
export const IconFileCsv: IconType = FileCsv;
export const IconFileCsvBw: IconType = FileCsvBw;
export const IconFileCsvInner: IconType = FileCsvInner;
export const IconFileDoc: IconType = FileDoc;
export const IconFileDocBw: IconType = FileDocBw;
export const IconFileDocInner: IconType = FileDocInner;
export const IconFileFvl: IconType = FileFvl;
export const IconFileFvlBw: IconType = FileFvlBw;
export const IconFileFvlInner: IconType = FileFvlInner;
export const IconFileGif: IconType = FileGif;
export const IconFileGifBw: IconType = FileGifBw;
export const IconFileGifInner: IconType = FileGifInner;
export const IconFileJpg: IconType = FileJpg;
export const IconFileJpgBw: IconType = FileJpgBw;
export const IconFileJpgInner: IconType = FileJpgInner;
export const IconFileMov: IconType = FileMov;
export const IconFileMovBw: IconType = FileMovBw;
export const IconFileMovInner: IconType = FileMovInner;
export const IconFileMp3: IconType = FileMp3;
export const IconFileMp3Bw: IconType = FileMp3Bw;
export const IconFileMp3Inner: IconType = FileMp3Inner;
export const IconFileMp4: IconType = FileMp4;
export const IconFileMp4Bw: IconType = FileMp4Bw;
export const IconFileMp4Inner: IconType = FileMp4Inner;
export const IconFileOther: IconType = FileOther;
export const IconFileOtherBw: IconType = FileOtherBw;
export const IconFileOtherInner: IconType = FileOtherInner;
export const IconFilePdf: IconType = FilePdf;
export const IconFilePdfBw: IconType = FilePdfBw;
export const IconFilePdfInner: IconType = FilePdfInner;
export const IconFilePng: IconType = FilePng;
export const IconFilePngBw: IconType = FilePngBw;
export const IconFilePngInner: IconType = FilePngInner;
export const IconFilePpt: IconType = FilePpt;
export const IconFilePptBw: IconType = FilePptBw;
export const IconFilePptInner: IconType = FilePptInner;
export const IconFileRtf: IconType = FileRtf;
export const IconFileRtfBw: IconType = FileRtfBw;
export const IconFileRtfInner: IconType = FileRtfInner;
export const IconFileTxt: IconType = FileTxt;
export const IconFileTxtBw: IconType = FileTxtBw;
export const IconFileTxtInner: IconType = FileTxtInner;
export const IconFileWma: IconType = FileWma;
export const IconFileWmaBw: IconType = FileWmaBw;
export const IconFileWmaInner: IconType = FileWmaInner;
export const IconFileWmf: IconType = FileWmf;
export const IconFileWmfBw: IconType = FileWmfBw;
export const IconFileWmfInner: IconType = FileWmfInner;
export const IconFileXls: IconType = FileXls;
export const IconFileXlsBw: IconType = FileXlsBw;
export const IconFileXlsInner: IconType = FileXlsInner;
export const IconFileZip: IconType = FileZip;
export const IconFileZipBw: IconType = FileZipBw;
export const IconFileZipInner: IconType = FileZipInner;
export const IconFiles: IconType = Files;
export const IconFilters: IconType = Filters;
export const IconFingerprints: IconType = Fingerprints;
export const IconFingerprintsGrayscale: IconType = FingerprintsGrayscale;
export const IconFirefly: IconType = Firefly;
export const IconFireflyLogoInverse: IconType = FireflyChainsInverse;
export const IconFireflyGrayscale: IconType = FireflyGrayscale;
export const IconFireflyLogo: IconType = FireflyLogo;
export const IconFireflyLogoOrangeWhite: IconType = FireflyLogoOrangeWhite;
export const IconFlavor: IconType = Flavor;
export const IconFlavorEnchanced: IconType = FlavorEnchanced;
export const IconFlavorGrayscale: IconType = FlavorGrayscale;
export const IconFlavorLogoPinkWhite: IconType = FlavorLogoPinkWhite;
export const IconFoodscapeLogoBig: IconType = FoodscapeLogoBig;
export const IconFoodscapeLogoWhite: IconType = FoodscapeLogoWhite;
export const IconFoodstudioLogoLimeWhite: IconType = FoodstudioLogoLimeWhite;
export const IconFoodstudioSidebar: IconType = FoodstudioSidebar;
export const IconFoodstudioSidebarGrayscale: IconType = FoodstudioSidebarGrayscale;
export const IconFoursquare: IconType = Foursquare;
export const IconFullscreen: IconType = Fullscreen;
export const IconGoLogo: IconType = GoLogo;
export const IconGoogle: IconType = Google;
export const IconGrandeDragonfly: IconType = GrandeDragonfly;
export const IconGrandeDragonflyGrayscale: IconType = GrandeDragonflyGrayscale;
export const IconGroup: IconType = Group;
export const IconGroups: IconType = Groups;
export const IconHaiku: IconType = Haiku;
export const IconHaikuBig: IconType = HaikuBig;
export const IconHaikuBigNew: IconType = HaikuBigNew;
export const IconHaikuColored: IconType = HaikuColored;
export const IconHaikuLine: IconType = HaikuLine;
export const IconHeart: IconType = Heart;
export const IconHelp: IconType = Help;
export const IconExportExcel: IconType = ExportExcel;
export const IconExportPdf: IconType = ExportPdf;
export const IconPdf: IconType = Pdf;
export const IconPpt: IconType = Ppt;
export const IconXls: IconType = Xls;
export const IconImpersonate: IconType = Impersonate;
export const IconInception: IconType = Inception;
export const IconInfo: IconType = Info;
export const IconInfoStokes: IconType = InfoStokes;
export const IconInsider: IconType = Insider;
export const IconInsiderGrayscale: IconType = InsiderGrayscale;
export const IconInsiderLogoOrangeWhite: IconType = InsiderLogoOrangeWhite;
export const IconInstagram: IconType = Instagram;
export const IconLinex4x3: IconType = Linex4x3;
export const IconLinex16x9: IconType = Linex16x9;
export const IconLink: IconType = Link;
export const IconLinkBlue: IconType = LinkBlue;
export const IconLinkDark: IconType = LinkDark;
export const IconLinkedin: IconType = Linkedin;
export const IconLinkedinColored: IconType = LinkedinColored;
export const IconLinkedinSimple: IconType = LinkedinSimple;
export const IconLock: IconType = Lock;
export const IconLocal: IconType = Local;
export const IconLocalEnchanced: IconType = LocalEnchanced;
export const IconLocalGrayscale: IconType = LocalGrayscale;
export const IconLocalLogoBlueWhite: IconType = LocalLogoBlueWhite;
export const IconLocation: IconType = Location;
export const IconLogOut: IconType = LogOut;
export const IconLogoClicked: IconType = LogoClicked;
export const IconLogoCollapsed: IconType = LogoCollapsed;
export const IconLogoDark: IconType = LogoDark;
export const IconLogoDefault: IconType = LogoDefault;
export const IconLogoGradient: IconType = LogoGradient;
export const IconLogoHovered: IconType = LogoHovered;
export const IconLogoNormal: IconType = LogoNormal;
export const IconLogoPin: IconType = LogoPin;
export const IconMac: IconType = Mac;
export const IconMenutrends: IconType = Menutrends;
export const IconMenutrendsGrayscale: IconType = MenutrendsGrayscale;
export const IconMenutrendsLogoBlueWhite: IconType = MenutrendsLogoBlueWhite;
export const IconMiAddToPackage: IconType = MiAddToPackage;
export const IconMiChain: IconType = MiChain;
export const IconMiDownload: IconType = MiDownload;
export const IconMiInsider: IconType = MiInsider;
export const IconMiScores: IconType = MiScores;
export const IconMyDocuments: IconType = MyDocuments;
export const IconNestle: IconType = Nestle;
export const IconOrder: IconType = Order;
export const IconOrderArrows: IconType = OrderArrows;
export const IconOrderReverse: IconType = OrderReverse;
export const IconPackages: IconType = Packages;
export const IconPackagesRemove: IconType = PackagesRemove;
export const IconPause: IconType = Pause;
export const IconPhone: IconType = Phone;
export const IconPinnacleDragonfly: IconType = PinnacleDragonfly;
export const IconPinnacleDragonflyGrayscale: IconType = PinnacleDragonflyGrayscale;
export const IconPinterest: IconType = Pinterest;
export const IconPlay: IconType = Play;
export const IconPptO: IconType = PptO;
export const IconPrivateNote: IconType = PrivateNote;
export const IconProject: IconType = Project;
export const IconProjectBw: IconType = ProjectBw;
export const IconProliferation: IconType = Proliferation;
export const IconPublicNote: IconType = PublicNote;
export const IconRemove: IconType = Remove;
export const IconSample: IconType = Sample;
export const IconScores: IconType = Scores;
export const IconScoresNew: IconType = ScoresNew;
export const IconScoresGrayscale: IconType = ScoresGrayscale;
export const IconScoresLaunchNewButton: IconType = ScoresLaunchNewButton;
export const IconScoresLogoYellowWhite: IconType = ScoresLogoYellowWhite;
export const IconSearch: IconType = Search;
export const IconSearchSmall: IconType = SearchSmall;
export const IconSearchWidgetBg: IconType = SearchWidgetBg;
export const IconSegment: IconType = Segment;
export const IconSendEmail: IconType = SendEmail;
export const IconShare: IconType = Share;
export const IconShareLink: IconType = ShareLink;
export const IconSnap: IconType = Snap;
export const IconSnapAssistantQa: IconType = SnapAssistantQa;
export const IconSnapAssistantTrends: IconType = SnapAssistantTrends;
export const IconSnapAssistantVoice: IconType = SnapAssistantVoice;
export const IconSpeaker: IconType = Speaker;
export const IconSpeakerMuted: IconType = SpeakerMuted;
export const IconStar: IconType = Star;
export const IconState: IconType = State;
export const IconStrengths: IconType = Strengths;
export const IconStrengthsItem: IconType = StrengthsItem;
export const IconSuccess: IconType = Success;
export const IconSuccessx2: IconType = Successx2;
export const IconTwitter: IconType = Twitter;
export const IconTwitterNew: IconType = TwitterNew;
export const IconUbiquity: IconType = Ubiquity;
export const IconUnits: IconType = Units;
export const IconUser: IconType = User;
export const IconWiki: IconType = Wiki;
export const IconWeb: IconType = Web;
export const IconXlsO: IconType = XlsO;
export const IconYelp: IconType = Yelp;
export const IconYelpNew: IconType = YelpNew;
export const IconYoutube: IconType = Youtube;
export const IconYoutubeNew: IconType = YoutubeNew;
export const IconArrow: IconType = Arrow;
export const IconViewList: IconType = ViewList;
export const IconViewTiles: IconType = ViewTiles;
export const IconEditWhite: IconType = EditWhite;
export const IconCopyTable: IconType = CopyTable;
export const IconDownloadSimple: IconType = DownloadSimple;
export const IconMenu: IconType = Menu;
export const IconReportPro: IconType = ReportPro;
export const IconSearchWhite: IconType = SearchWhite;
export const IconFireflyChains: IconType = FireflyChains;
export const IconPublicNoteNew: IconType = PublicNoteNew;
export const IconPrivateNoteNew: IconType = PrivateNoteNew;
export const IconFunnel: IconType = Funnel;
export const IconFunnelDescription: IconType = FunnelDescription;
export const IconWeaknesses: IconType = Weaknesses;
export const IconWeaknessesItem: IconType = WeaknessesItem;
export const IconNotesAdd: IconType = NotesAdd;
export const IconSocialZomato: IconType = SocialZomato;
export const IconSocialTumblr: IconType = SocialTumblr;
export const IconSocialSnapchat: IconType = SocialSnapchat;
export const IconSocialPinterest: IconType = SocialPinterest;
export const IconArrowLeft: IconType = ArrowLeft;
export const IconArrowRight: IconType = ArrowRight;
export const IconDsLogoNewSmall: IconType = DsLogoNewSmall;
export const IconProductFfChains: IconType = ProductFfChains;
export const IconProductFingerprints: IconType = ProductFingerprints;
export const IconProductFirefly: IconType = ProductFirefly;
export const IconProductFlavor: IconType = ProductFlavor;
export const IconProductFlavorMinimal: IconType = ProductFlavorMinimal;
export const IconProductFoodStudio: IconType = ProductFoodStudio;
export const IconProductConceptIdeation: IconType = ProductConceptIdeation;
export const IconProductInsider: IconType = ProductInsider;
export const IconProductLocal: IconType = ProductLocal;
export const IconProductMenuTrends: IconType = ProductMenuTrends;
export const IconProductPriceMonitor: IconType = ProductPriceMonitor;
export const IconProductReportPro: IconType = ProductReportPro;
export const IconProductScores: IconType = ProductScores;
export const IconProductConceptLocker: IconType = ProductConceptLocker;
export const IconProductMenucast: IconType = ProductMenucast;
export const IconProductFfChainsGs: IconType = ProductFfChainsGs;
export const IconProductFingerprintsGs: IconType = ProductFingerprintsGs;
export const IconProductFireflyGs: IconType = ProductFireflyGs;
export const IconProductFlavorGs: IconType = ProductFlavorGs;
export const IconProductFoodStudioGs: IconType = ProductFoodStudioGs;
export const IconProductConceptIdeationGs: IconType = ProductConceptIdeationGs;
export const IconProductInsiderGs: IconType = ProductInsiderGs;
export const IconProductLocalGs: IconType = ProductLocalGs;
export const IconProductMenuTrendsGs: IconType = ProductMenuTrendsGs;
export const IconProductPriceMonitorGs: IconType = ProductPriceMonitorGs;
export const IconProductReportProGs: IconType = ProductReportProGs;
export const IconProductScoresGs: IconType = ProductScoresGs;
export const IconProductConceptLockerGs: IconType = ProductConceptLockerGs;
export const IconLogoSnapDs: IconType = LogoSnapDs;
export const IconLogoSnapDsIcon: IconType = LogoSnapDsIcon;
export const IconQuestion: IconType = Question;