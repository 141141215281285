import { FC, useMemo } from "react";
import CircularBar from "../../../../../components/new/CircularBar/CircularBar";
import InfoBlock from "../../../../../components/new/InfoBlock/InfoBlock";
import BrandsHeader from "../BrandsHeader/BrandsHeader";
import "./BrandsAttributes.styles.scss";
import { API__FPChainData } from "../../../../../core/apiSchema";
import { getValueWithSign } from "../../../../../core/helpers";
import { IconInfoStokes } from "../../../../../icons";
import Button from "../../../../../components/new/Button/Button";

interface iProps {
    data: API__FPChainData;
}

const BrandsAttributes: FC<iProps> = (
    {
        data: { headerData: data }
    }
) => {
    const additionalRatings: Array<{
        label: string;
        value: string;
    }> = useMemo(() => [
        { label: 'NPS', value: getValueWithSign(data.nps), },
        { label: 'promoter', value: `${data.promoter}%`, },
        { label: 'passive', value: `${data.passive}%`, },
        { label: 'detractor', value: `${data.detractor}%`, },
    ], [data]);

    return (
        <div className="BrandsAttributes">
            <div className="BrandsAttributes__header">
                <BrandsHeader
                    title="Key Metrics"
                    classNames="is-lead"
                />
            </div>
            <InfoBlock className="BrandsAttributes__info-block">
                <div className="BrandsAttributes__content">
                    <div className="BrandsAttributes__charts">
                        <div className="BrandsAttributes__wrapper">
                            <BrandsHeader
                                title="Mega Attribute Ratings"
                                subTitle="(% rating “best in class” or “above average”)"
                            />

                            <div className="BrandsAttributes__columns">
                                {data.circles.map(el => {
                                    return (
                                        <div key={el.title} className="BrandsAttributes__column">
                                            <div className="BrandsAttributes__circular">
                                                <CircularBar
                                                    percentage={el.chainValue}
                                                    id={`chart-${el.title}`}
                                                />
                                                <div className="BrandsAttributes__description">
                                                    <div className="BrandsAttributes__circular-total">
                                                        {el.chainValue}%
                                                    </div>
                                                    <div className="BrandsAttributes__circular-label">
                                                        {el.title}
                                                    </div>
                                                    <div className="BrandsAttributes__circular-value">
                                                        vs. segment avg: {el.averageValue}%
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="BrandsAttributes__additional">
                        <div className="BrandsAttributes__wrapper">
                            <div className="BrandsAttributes__additional-header">
                                <BrandsHeader
                                    title="Net Promoter Score"
                                    subTitle=" "
                                >
                                    <div className="BrandsAttributes__info">
                                        <Button
                                            className="BrandsAttributes__info-button"
                                            tooltipPosition={["below", "left"]}
                                            tooltipText={
                                                <div className="BrandsAttributes__info-dropdown">
                                                    <p>The Net Promoter Score is a measure of brand appeal and loyalty; higher scores = better</p>
                                                    <p>Consumers answer: “How likely are you to recommend this brand to your colleagues and peers?” on a 0-10 scale, where: <br/>0 to 6 = detractor <br/>7 to 8 = passive<br/>9 to 10 = promoter</p>
                                                    <p>PROMOTERS – DETRACTORS = NET PROMOTER SCORE</p>
                                                </div>
                                            }
                                        >
                                            <IconInfoStokes />
                                        </Button>
                                    </div>
                                </BrandsHeader>
                            </div>

                            <div className="BrandsAttributes__additional-list">
                                {additionalRatings.map((el) => {
                                    return (
                                        <div
                                            key={`${el.value}${el.label}`}
                                            className="BrandsAttributes__additional-list-item"
                                        >
                                            <div className="BrandsAttributes__additional-value">
                                                {el.value}
                                            </div>
                                            <div className="BrandsAttributes__additional-title">
                                                {el.label}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </InfoBlock>
        </div>
    );
};

export default BrandsAttributes;
