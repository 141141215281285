import { FC } from "react";
import cn from "classnames";

const ContentWidgetCaption: FC<{
    className?: string;
    testId?: string;
}> = (
    {
        children,
        testId,
        className,
    }
) => {
    return (
        <div
            className={cn('ContentWidget__caption', className)}
            data-testid={testId}
        >
            {children}
        </div>
    );
}

export default ContentWidgetCaption;