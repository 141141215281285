import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MacItem from './MacItem';
import './MacList.scss';

export default class MacList extends PureComponent {
    renderItems() {
        const items = this.props.items.map((item, index) =>
            (<MacItem
                key={index}
                {...item}
                index={index}
                searchWord={this.props.searchWord}
                handleMouseEnter={this.props.scrollToItem}
                handleMouseLeave={this.props.preventScrollToItem}
                from={this.props.from}
            />)
        ).slice(0, this.props.maxToShow);
        return items;
    }

    componentWillReceiveProps(nextProps){

    }

    render() {
        return (
            <div className="mac-list" data-testid="blockAllMacsGrid">
                {
                    (this.props.items === undefined || this.props.items.length < 1) &&
                    (<div>
                        nothing to see here!
                    </div>)
                }
                {this.props.items && this.renderItems()}
            </div>
        );
    }
}

MacList.propTypes = {
    items: PropTypes.array,
    className: PropTypes.string,
    searchWord: PropTypes.string,
    scrollToItem: PropTypes.func,
    preventScrollToItem: PropTypes.func,
    from: PropTypes.string
};
