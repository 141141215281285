import { combineReducers } from 'redux';
import myDocumentsData from '../pages/MyDocuments/myDocuments.reducers';
import documentTypeData from '../pages/DocumentType/documentType.reducers';
import projectData from '../pages/Project/project.reducers';
import dropdownData from '../components/Dropdown/Dropdown.reducers';
import notificationsData from '../components/GlobalNotification/globalNotification.reducers';
import documentEditPopupData from '../containers/DocumentEditPopup/documentEditPopup.reducers';
import projectEditPopupData from '../containers/ProjectEditPopup/projectEditPopup.reducers';
import projectCreatePopupData from '../containers/ProjectCreatePopup/projectCreatePopup.reducers';
import documentsAddPopupData from '../containers/DocumentsAddPopup/documentsAddPopup.reducers';
import documentDeletePopupData from '../containers/DocumentDeletePopup/documentDeletePopup.reducers';
import projectDeletePopupData from '../containers/ProjectDeletePopup/projectDeletePopup.reducers';
import documentsLibraryPopupsData from '../containers/DocumentsLibraryPopups/documentsLibraryPopups.reducers';
import reconfirmationPopupData from '../containers/ReconfirmationPopup/ReconfirmationPopup.reducers';
import reportPopupData from '../containers/ReportPopup/ReportPopup.reducers';
import searchPopup from './searchPopup.reducers';
import pageStatus from './page';
import documentsData from './documents.reducers';
import macData from './macDetails.reducers';
import viewDocumentData from './viewDocument.reducers';
import chainDetailData from './chainDetails.reducers';
import packagesData from './packages.reducers';
import groupsData from './groups.reducers';

export default combineReducers({
    documentsData,
    macData,
    viewDocumentData,
    myDocumentsData,
    documentTypeData,
    projectData,
    chainDetailData,
    packagesData,
    documentsLibraryPopupsData,
    documentEditPopupData,
    projectEditPopupData,
    projectCreatePopupData,
    documentsAddPopupData,
    documentDeletePopupData,
    projectDeletePopupData,
    pageStatus,
    searchPopup,
    dropdownData,
    groupsData,
    notificationsData,
    reconfirmationPopupData,
    reportPopupData,
});
