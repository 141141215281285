import { iTableConfigItem } from "../../../../components/new/Table/Table.helpers";
import { getNumberWithDecimal } from "../../../../core/helpers";
import { iCompetitorsItem, iMarket, iPopulation } from "../../../../core/models/Location";
import {
    COMPETITORS_CELL_METROS_WIDTH,
    COMPETITORS_CELL_OVERLAP_WIDTH,
    highlightCellStyles,
} from "./Locations.helpers";

type IndexType = { index: number };

interface iTableConfigBase {
    columnIcon: (field: string) => {};
    handleSort: (field: string) => () => void;
    sortingField: string;
}
export interface iMarketTableConfig extends iTableConfigBase{
    data: iMarket[];
}
export interface iCompetitorsTableConfig extends iTableConfigBase {
    data: iCompetitorsItem[];
}

export interface iPopulationsTableConfig extends iTableConfigBase {
    data: iPopulation[];
    chainName: string;
}

export const getStateTableConfig = ({
    data,
    columnIcon,
    handleSort,
    sortingField,
}: iMarketTableConfig): iTableConfigItem[] => {
    return [
        {
            title: "State",
            Value: ({ index }: IndexType) => <>{data[index].region}</>,
            icon: columnIcon("region"),
            handleHeaderClick: handleSort("region"),
            isActive: sortingField === "region",
        },
        {
            title: "# of stores",
            Value: ({ index }: IndexType) => <>{data[index].numberOfStores}</>,
            width: 150,
            textCentered: true,
            styles: highlightCellStyles,
            icon: columnIcon("numberOfStores"),
            handleHeaderClick: handleSort("numberOfStores"),
            isActive: sortingField === "numberOfStores",
        },
        {
            title: "% of all stores",
            Value: ({ index }: IndexType) => <>{getNumberWithDecimal(data[index].percentOfAllStores)}%</>,
            width: 200,
            textCentered: true,
            icon: columnIcon("percentOfAllStores"),
            handleHeaderClick: handleSort("percentOfAllStores"),
            isActive: sortingField === "percentOfAllStores",
        },
        {
            title: "Density",
            Value: ({ index }: IndexType) => <>{getNumberWithDecimal(data[index].density)}</>,
            tooltip: "density = stores per 1 million people",
            width: 200,
            textCentered: true,
            icon: columnIcon("density"),
            handleHeaderClick: handleSort("density"),
            isActive: sortingField === "density",
        },
    ];
};

export const getMetrosTableConfig = ({
    data,
    columnIcon,
    handleSort,
    sortingField,
}: iMarketTableConfig): iTableConfigItem[] => {
    return [
        {
            title: "Metro Area",
            icon: columnIcon("region"),
            handleHeaderClick: handleSort("region"),
            isActive: sortingField === "region",
            Value: ({ index }: IndexType) => <>{data[index].region}</>,
        },
        {
            title: "# of stores",
            Value: ({ index }: IndexType) => <>{data[index].numberOfStores}</>,
            width: 150,
            textCentered: true,
            styles: highlightCellStyles,
            icon: columnIcon("numberOfStores"),
            handleHeaderClick: handleSort("numberOfStores"),
            isActive: sortingField === "numberOfStores",
        },
        {
            title: "% of all stores",
            Value: ({ index }: IndexType) => <>{getNumberWithDecimal(data[index].percentOfAllStores)}%</>,
            width: 200,
            textCentered: true,
            icon: columnIcon("percentOfAllStores"),
            handleHeaderClick: handleSort("percentOfAllStores"),
            isActive: sortingField === "percentOfAllStores",
        },
        {
            title: "Density",
            Value: ({ index }: IndexType) => <>{getNumberWithDecimal(data[index].density)}</>,
            tooltip: "density = stores per 1 million people",
            width: 200,
            textCentered: true,
            icon: columnIcon("density"),
            handleHeaderClick: handleSort("density"),
            isActive: sortingField === "density",
        },
    ];
};

export const getCompetitorsTableConfig = ({
    data,
    columnIcon,
    handleSort,
    sortingField,
}: iCompetitorsTableConfig): iTableConfigItem[] => {
    return [
        {
            title: "Chain Name",
            Value: ({ index }: IndexType) => <>{data[index].chainName}</>,
            icon: columnIcon("chainName"),
            handleHeaderClick: handleSort("chainName"),
            isActive: sortingField === "chainName",
        },
        {
            title: "# of common metros",
            Value: ({ index }: IndexType) => <>{data[index].commonMetros}</>,
            width: COMPETITORS_CELL_METROS_WIDTH,
            textCentered: true,
            styles: highlightCellStyles,
            icon: columnIcon("commonMetros"),
            handleHeaderClick: handleSort("commonMetros"),
            isActive: sortingField === "commonMetros",
        },
        {
            title: "avg. distance in those metros",
            Value: ({ index }: IndexType) => <>{getNumberWithDecimal(data[index].averageDistance)}</>,
            width: COMPETITORS_CELL_METROS_WIDTH,
            textCentered: true,
            styles: {
                ...highlightCellStyles,
                cell: {
                    ...highlightCellStyles.cell,
                    padding: "0 16px",
                    textAlign: "left"
                },
            },
            icon: columnIcon("averageDistance"),
            handleHeaderClick: handleSort("averageDistance"),
            isActive: sortingField === "averageDistance",
        },
        {
            title: "0.5 miles",
            Value: ({ index }: IndexType) => <>{getNumberWithDecimal(data[index].percents.miles05)}</>,
            width: COMPETITORS_CELL_OVERLAP_WIDTH,
            textCentered: true,
            icon: columnIcon("miles05"),
            handleHeaderClick: handleSort("miles05"),
            isActive: sortingField === "miles05",
        },
        {
            title: "1 mile",
            Value: ({ index }: IndexType) => <>{getNumberWithDecimal(data[index].percents.miles1)}</>,
            width: COMPETITORS_CELL_OVERLAP_WIDTH,
            textCentered: true,
            icon: columnIcon("miles1"),
            handleHeaderClick: handleSort("miles1"),
            isActive: sortingField === "miles1",
        },
        {
            title: "3 miles",
            Value: ({ index }: IndexType) => <>{getNumberWithDecimal(data[index].percents.miles3)}</>,
            width: COMPETITORS_CELL_OVERLAP_WIDTH,
            textCentered: true,
            icon: columnIcon("miles3"),
            handleHeaderClick: handleSort("miles3"),
            isActive: sortingField === "miles3",
        },
    ];
};

export const getPopulationTableConfig = ({
    data,
    chainName
}: iPopulationsTableConfig): iTableConfigItem[] => {
    return [
        {
            title: `(residents who live or work near ${chainName})`,
            Value: ({ index }: { index: number }) => <>{data[index].name}</>,
        },
        {
            title: "%",
            Value: ({ index }: { index: number }) => <>{`${getNumberWithDecimal(data[index].percent)}%`}</>,
            width: 150,
            textCentered: true,
            styles: highlightCellStyles,
        },
        {
            title: "INDEX vs TOTAL US",
            Value: ({ index }: { index: number }) => <>{`${data[index].index}`}</>,
            width: 300,
            textCentered: true,
        },
    ];
};
