import {
    PROJECT_DATA_STORE,
    PROJECT_DATA_CLEAR,
    PROJECT_REMOVE_DOCUMENT,
    PROJECT_DOCUMENT_UPDATE
} from '../../actions/actionsNamesList';

const defaultState = {
    dataLoaded: false,
    id: null,
    documents: [],
    customerViewCompanyId: 0,
    isShareMode: false,
    name: '',
    companyId: window.pageData.currentUsercompanyId
};

export default function projectData(state = defaultState, action) {
    switch (action.type) {
        case PROJECT_DATA_STORE: {
            return {
                ...state,
                ...action.data
            };
        }
        case PROJECT_DATA_CLEAR: {
            return {
                ...defaultState
            };
        }
        case PROJECT_REMOVE_DOCUMENT: {
            return {
                ...state,
                documents: state.documents.filter(item => item.id !== action.id)
            };
        }
        case PROJECT_DOCUMENT_UPDATE: {
            return {
                ...state,
                documents: [
                    {
                        ...state.documents.find(item => item.id === action.data.id),
                        ...action.data
                    },
                    ...state.documents.filter(item => item.id !== action.data.id)
                ]
            };
        }
        default: {
            return { ...state };
        }
    }
}
