import { getFormattedCurrency } from "../../../../core/helpers";
import { MenuPartsEnum } from "../../../../core/models/ChainMenuData";
import { iMenuDetailsItem } from "../../../../core/models/MenuDetailsItem";
import { iMenuExample } from "../../../../core/models/MenuExamples";

export interface iMenuExamplesData {
    id: MenuPartsEnum | string;
    title: string;
    data: iMenuExample[];
}

export const getClipboardData = ({ head, data }: { head: string[], data: iMenuDetailsItem[]}) => {
    return [
        head,
        ...data.map(item => {
            const priceRange = item.minPrice === 0 && item.maxPrice === 0 ? "-" : `${getFormattedCurrency(item.minPrice || 0)} - ${getFormattedCurrency(item.maxPrice || 0)}`;
            return [
                item.title,
                item.itemCount ? item.itemCount.toString() : "-",
                item.medianPrice ? item.medianPrice.toString() : "-",
                priceRange
            ]
    })]
}