import { useEffect, useMemo, useState } from "react";
import useMenu from "../../../../core/apiHooks/useMenu";
import { deepEqual, getUnique, sortByKey } from "../../../../core/helpers";
import { getMenuPartType } from "../../../../core/models/ChainMenuData";
import { iMenuDetailsItem } from "../../../../core/models/MenuDetailsItem";
import { iMenuExamplesData } from "./Menu.helpers";

const useMenuData = (chainId: number) => {
    const { data, isLoading } = useMenu(chainId);
    const [ menuExamples, setMenuExamples ] = useState<iMenuExamplesData[]>([]);
    const [ menuDetailsItems, setMenuDetailsItems ] = useState<iMenuDetailsItem[]>([]);

    useEffect(() => {
        if (!data) return;

        const menuParts = getUnique(data.menuExamplesByMenuPart.map(i => i.menuPart));

        const menuExamplesData = menuParts.map(menuPart => {
            return {
                id: getMenuPartType(menuPart) || menuPart,
                title: menuPart,
                data: data.menuExamplesByMenuPart.filter(item => item.menuPart === menuPart )
            }
        });

        const sortedMenuExamples = menuExamplesData.sort(sortByKey("id"));
        if ( !deepEqual(menuExamples, sortedMenuExamples) ) {
            setMenuExamples(sortedMenuExamples);
        }

        const newMenuDetailsItems = data.itemsData.data.map(item => ({
            ...item,
            id: getMenuPartType(item.title)
        }));
        if ( !deepEqual(menuDetailsItems, newMenuDetailsItems) ) {
            setMenuDetailsItems(newMenuDetailsItems);
        }

    }, [data, menuExamples, menuDetailsItems]);

    return useMemo(() => ({
        isLoading,
        menuExamples,
        menuDetailsItems,
        permissionLevel: data.itemsData.permissionLevel,
        hasData: data.itemsData.hasData,
    }), [data.itemsData.hasData, data.itemsData.permissionLevel, isLoading, menuDetailsItems, menuExamples]);
};

export default useMenuData;
