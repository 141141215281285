import { FC, useState } from "react";
import Scrollbar from 'react-custom-scrollbars';
import "./TilesView.styles.scss";
import cn from "classnames";
import Tile from "./Tile";

export interface iItem {
    id: number;
    title: string;
    subItems: iItem[];
}

interface iProps {
    data: iItem[];
    zoomLevel: number;
    handleItemClick: (index:  number) => void;
}

const TilesView: FC<iProps> = (
    {
        data = [],
        zoomLevel,
        handleItemClick,
        children,
    }
) => {

    const className = cn([
        'TilesView',
        zoomLevel > 0 ? `TilesView--zoomed-${zoomLevel}` : 'TilesView--zoomed-out',
    ]);

    return (
        <div className={className}>
            <Scrollbar>
                <div className="TilesView__inner">
                    {children}
                    {data.map((item, index) => (
                        <Tile
                            key={index}
                            title={item.title}
                            onClick={() => handleItemClick(index)}
                        />
                    ))}
                </div>
            </Scrollbar>
        </div>
    )
};

export default TilesView;