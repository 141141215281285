import React, { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import TextArea from 'react-textarea-autosize';
import DropZone from 'react-dropzone';
import Scrollbar from 'react-custom-scrollbars';
import Popup from '../../components/Popup/Popup';
import Icon from '../../components/SpriteIcon/SpriteIcon';
import {
    setView as setDocumentAddPopupView, open as openDocumentAddPopup, storeUploadingFiles,
} from '../DocumentsAddPopup/documentsAddPopup.actions';

import {
    close, save, setData, showValidationErrors, checkNameUnique, hideValidationErrors, setView, resetData,
} from './projectCreatePopup.actions';

class ProjectCreatePopup extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.save = this.save.bind(this);
        this.close = this.close.bind(this);
        this.goToDescription = this.goToDescription.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.goToUploading = this.goToUploading.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(!prevProps.data.isOpened && this.props.data.isOpened) {
            setTimeout(() => {
                this.inputRef.current.focus();
            }, 1000);
        }
    }

    handleNameChange(event) {
        const { setData, checkNameUnique } = this.props;
        checkNameUnique(event.target.value);
        setData({ name: event.target.value });
    }

    save() {
        this.props.save();
        this.props.setView('upload');
    }

    open() {
        document.body.style.overflowY = 'hidden';
    }

    close() {
        this.props.close();
        this.props.resetData();
        document.body.style.overflowY = 'initial';
    }

    goToDescription() {
        this.props.setView('description');
    }

    goToUploading(files) {
        this.close();
        this.props.startUploading(files);
    }

    renderUploadView() {
        const activeStateStyles = {
            boxShadow: 'inset 0 0 5px 5px rgba(128, 195, 232, 1)',
            backgroundColor: 'rgba(128, 195, 232, 0.1)',
        };
        return (
            <DropZone
                onDrop={this.goToUploading} multiple={true} disableClick={true} activeStyle={activeStateStyles}
                className="popup__main"
            >
                <div className="popup__header">
                    <div className="popup__header-title">
                        Upload Files
                    </div>
                </div>
                <div className="popup__main popup__flex-container--aligned">
                    <div className="popup__left-side popup__left-side--column">
                        <div className="popup__text popup__margin--big">
                            Your project has been created and is now searchable in SNAP.
                        </div>
                        <div className="popup__text popup__margin--big">
                            Would you like to add files to your project?
                        </div>
                        <DropZone onDrop={this.goToUploading} multiple={true} className="popup__btn">
                            Add files
                        </DropZone>
                        <button onClick={this.close} className="popup__link popup__link--indent-left">
                            not now
                        </button>
                    </div>
                    <div className="popup__right-side">
                        <Icon width="260" height="263" iconId="drag-n-drop" className="popup__bg-image"/>
                    </div>
                </div>
            </DropZone>
        );
    }

    render() {
        const {
            isOpened, name, initialName, description, isNameUnique, isNameUniquenessChecking, view,
        } = this.props.data;

        let errorMsgText = '';

        if (name.trim() !== '' && !isNameUnique && name.trim() !== initialName && !isNameUniquenessChecking) {
            errorMsgText = 'this name is not available';
        }

        return (
            <Popup
                isOpened={isOpened}
                onClose={this.close}
                onOpen={this.open}
                className="popup-project-create popup--fade-in"
            >
                <div ref="inner" className="popup__inner popup__inner--small">
                    {view === 'name' ? (
                        <div>
                            <div className="popup__header">
                                <div
                                    className="popup__header-title"
                                    data-testid="textProjectCreatePopupTitle"
                                >
                                    Add Project
                                </div>
                                <div
                                    className="popup__header-secondary-text"
                                    data-testid="textProjectCreatePopupSecondaryText"
                                >
                                    this will create a new project folder to organize files
                                </div>
                            </div>
                            <div className="popup__input-container popup__input-container--fixed">
                                <div className="popup__input-wrapper">
                                    <label
                                        className="popup__input-label"
                                        data-testid="textProjectCreatePopupProjectNameLabel"
                                    >
                                        project name
                                    </label>
                                    <input
                                        type="text"
                                        className="popup__project-input"
                                        value={name}
                                        onChange={this.handleNameChange}
                                        autoFocus={true}
                                        ref={this.inputRef}
                                        data-testid="inputProjectCreatePopupProjectName"
                                    />
                                </div>
                                <div className="popup__input-wrapper">
                                    <label
                                        className="popup__input-label popup__description-label"
                                        data-testid="textProjectCreatePopupProjectDescriptionLabel"
                                    >
                                        description
                                    </label>
                                    <div className="popup__scrollbar-inner popup__scrollbar-inner--fixed">
                                        <Scrollbar>
                                            <TextArea
                                                autoFocus={false}
                                                minRows={3}
                                                maxRows={8}
                                                value={description}
                                                className="popup__project-text-area popup__textarea"
                                                onChange={event => this.props.setData({ description: event.target.value })}
                                                data-testid="inputProjectCreatePopupProjectDescription"
                                            />
                                        </Scrollbar>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="popup__error-msg popup__error-msg--proj-create"
                                data-testid="textProjectCreatePopupErrorMessage"
                            >
                                {errorMsgText}
                            </div>
                            <div className="popup__options popup__options--bottom">
                                <button
                                    disabled={
                                        (!isNameUnique && name !== initialName)
                                        || isNameUniquenessChecking
                                        || name.trim() === ''
                                        || description.trim() === ''
                                    }
                                    className="popup__btn popup__btn--md"
                                    onClick={this.save}
                                    data-testid="buttonProjectCreatePopupSubmit"
                                >
                                    create
                                </button>
                            </div>
                        </div>
                    ) : ''}
                    {view === 'upload' ? this.renderUploadView() : null}
                </div>
            </Popup>
        );
    }
}

ProjectCreatePopup.propTypes = {
    data: PT.object,
    setData: PT.func,
    checkNameUnique: PT.func,
    save: PT.func,
    close: PT.func,
    setView: PT.func,
    resetData: PT.func,
    startUploading: PT.func,
};

const MapStateToProps = state => ({
    data: state.projectCreatePopupData,
});

const MapDispatchToProps = dispatch => ({
    close: () => dispatch(close()),
    setData: data => dispatch(setData(data)),
    save: () => dispatch(save()),
    showValidationErrors: () => dispatch(showValidationErrors()),
    hideValidationErrors: () => dispatch(hideValidationErrors()),
    checkNameUnique: name => dispatch(checkNameUnique(name)),
    setView: type => dispatch(setView(type)),
    resetData: () => dispatch(resetData()),
    startUploading: (files) => {
        dispatch(storeUploadingFiles(files));
        dispatch(setDocumentAddPopupView('manage'));
        dispatch(openDocumentAddPopup());
    },
});

export default connect(MapStateToProps, MapDispatchToProps)(ProjectCreatePopup);
