import { FC } from "react";
import "./NotesHeader.styles.scss";

interface iProps {
    title: string;
}

const NotesHeader: FC<iProps> = ({
    title,
    children
}) => {
    return (
        <div className="NotesHeader">
            <div className="NotesHeader__title">{title}</div>
            <div className="NotesHeader__actions">
                {children}
            </div>
        </div>
    );
};

export default NotesHeader;
