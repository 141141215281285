import { FC } from "react";
import LazyComponent from "../LazyComponent/LazyComponent";
import Loader from "../Loader/Loader";
import "./SectionContent.styles.scss";
import cn from "classnames";

interface iProps {
    isLoading?: boolean;
    className?: string;
}

const SectionContent: FC<iProps> = (
    {
        isLoading,
        className,
        children,
    }
) => {
    const classNames = cn(
        'SectionContent',
        className,
    );
    return (
        <LazyComponent fadeIn>
        <div className={classNames}>
            {isLoading && (
                <div className="SectionContent__loader">
                    <Loader className="is-no-bg" />
                </div>
            )}
            {!isLoading && children}
        </div>
        </LazyComponent>
    );
};

export default SectionContent;
