import { PROJECT_DELETE_POPUP } from '../../actions/actionsNamesList';

const { OPEN, CLOSE, SET_DATA, RESET_DATA } = PROJECT_DELETE_POPUP;

const defaultState = {
    isOpened: false,
    id: null,
    documentsCount: 0
};

export default function projectDeletePopupData(state = defaultState, action) {
    switch (action.type) {
        case OPEN: {
            return {
                ...state,
                isOpened: true
            };
        }
        case CLOSE: {
            return {
                ...state,
                isOpened: false
            };
        }
        case SET_DATA: {
            return {
                ...state,
                ...action.data
            };
        }
        case RESET_DATA: {
            return {
                isOpened: state.isOpened
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
}
