import { RECONFIRMATION_POPUP } from '../../actions/actionsNamesList';
import { getMsgNumber, getExpireTimeStamp } from './reconfirmationPopupHelpers';

const { SET_DATA } = RECONFIRMATION_POPUP;
const daysLeft = window.pageData.daysToExpire;
const currentNotificationNumber = getMsgNumber(daysLeft);
const expireTimeStamp = getExpireTimeStamp(daysLeft);
const storedExpireTimeStamp = window.localStorage.getItem('expireTimeStamp');

if (+storedExpireTimeStamp !== +expireTimeStamp) {
    window.localStorage.setItem('expireTimeStamp', `${expireTimeStamp}`);
    window.localStorage.setItem('msg1', 'show');
    window.localStorage.setItem('msg2', 'show');
    window.localStorage.setItem('msg3', 'show');
}

const defaultState = {
    period: window.pageData.confirmationPeriod,
    daysLeft: window.pageData.daysToExpire,
    currentNotificationNumber,
};

export default function reconfirmationPopupData(state = defaultState, action) {
    switch (action.type) {
        case SET_DATA: {
            return {
                ...state,
                ...action.data,
            };
        }
        default: {
            return { ...state };
        }
    }
}
