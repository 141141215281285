import React, { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import cn from "classnames";
import ChainsDetailsContacts from "./ChainsDetailsContacts/ChainsDetailsContacts";
import InfoBlock from "../../../../components/new/InfoBlock/InfoBlock";
import TableRows from "../../../../components/new/TableRows/TableRows";
import NewsCarousel from "./NewsCarousel/NewsCarousel";
import { LockContext } from "../../../../components/new/LockSection/LockSection.context";
import {
    ChainDetailsDataContext,
    ChainSectionsEnum,
    getSectionData,
    mapSocialLinksToArray
} from "../../ChainPage.helpers";
import "./Profile.styles.scss";
import SectionContent from "../../../../components/new/SectionContent/SetionContent";
import { PermissionLevelEnum } from "../../../../core/models/PermissionSection";
import { Lock, LockDefaultAction } from "../../../../components/new/LockSection/LockSection";
import ProfileCarousel from "./ProfileCarousel/ProfileCarousel";
import ProfileMap from "./ProfileCarousel/ProfileMap";
import useChainMapData from "./ProfileCarousel/useChainMapData";
import useQueryParam from "../../../../core/hooks/useQueryParam";
import { postChainMapDataURL, MAP_IDS } from "./ProfileCarousel/ChainMap.helpers";
import { NO_VALUE_PLACEHOLDER } from "../../../../core/models/ChainDetails";
import { SectionEnum } from "../../../../core/enums";

const GroupGrow: React.FC<{ value: string }> = ({ value }) => {
    const classnames = cn(
        "GroupGrow__value",
        Number.parseFloat(value) === 0 && "value-is-zero",
        Number.parseFloat(value) > 0 && "value-is-up",
        Number.parseFloat(value) < 0 && "value-is-down",
    );

    return (
        <div className="GroupGrow">
            Prior Year Growth
            <span className={classnames}>{value}</span>
        </div>
    );
};

const Profile: FC = () => {
    const { data } = useContext(ChainDetailsDataContext);
    const {
        mainData: {
            address,
            city = 'No city',
            state,
            zip,
            phone,
            website,
            blurb,
            socialLinks,
        },
        keyAttributesData,
    } = data;

    const {
        keyAttributes,
        auv,
        units,
        stocks,
        sales,
    } = keyAttributesData.data;

    const hasAuvs = auv.values.filter(i => i.value !== NO_VALUE_PLACEHOLDER).length > 0;
    const hasUnits = units.values.filter(i => i.value !== NO_VALUE_PLACEHOLDER).length > 0;
    const hasSales = sales.values.filter(i => i.value !== NO_VALUE_PLACEHOLDER).length > 0;

    const isHiddenInfoBlocks = !hasAuvs && !hasUnits && !hasSales && stocks.length === 0

    const chainId = useQueryParam("id");
    const chainMapData = useChainMapData(chainId);
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const isLoadingScreenshot = useRef(false);

    const handleLoadMapWithHeatmap = useCallback(() => {
        setIsMapLoaded(true);
    }, []);

    useEffect(() => {
        const isAllowSendScreenshot = isMapLoaded && !chainMapData.heatMap.isLoading && !chainMapData.isSaved && !isLoadingScreenshot.current;

        if (isAllowSendScreenshot) {
            isLoadingScreenshot.current = true;
            postChainMapDataURL(".Profile__screenshot [aria-label='Map']", chainMapData.saveHeatmap);
        }
    }, [chainMapData, isMapLoaded]);

    return (
        <div className={cn("Profile", isHiddenInfoBlocks && "is-compact-view")}>
            <SectionContent>
                <div className={cn("Profile__section")}>
                    <div className="Profile__map">
                        <div className="Profile__map-wrapper">
                            <ProfileCarousel
                                heatMap={chainMapData.heatMap.data}
                                initedMapLoader={chainMapData.initedMapLoader}
                            />
                        </div>

                        <div className="ChainPage__description">{blurb}</div>

                        <div className="Profile__screenshot">
                            <ProfileMap
                                onLoad={handleLoadMapWithHeatmap}
                                isUnload={chainMapData.isSaved}
                                mapId={MAP_IDS.PROFILE}
                                isInited={chainMapData.initedMapLoader}
                                heatmapData={chainMapData.heatMap.data}
                            />
                        </div>
                    </div>

                    <div className="Profile__info-blocks">
                        <LockContext.Provider value={{ isLocked: false }}>
                            <ChainsDetailsContacts
                                address={address}
                                addressSecondRow={city && state && zip ? `${city}, ${state}, ${zip}` : ''}
                                phone={phone}
                                website={website}
                                socialLinks={mapSocialLinksToArray(socialLinks)}
                            />
                        </LockContext.Provider>

                        {!isHiddenInfoBlocks && (
                            <>
                                {hasSales && (
                                    <InfoBlock
                                        title="Sales"
                                        subTitle="($mm)"
                                        className="Profile__info-block"
                                    >
                                        <div className="Profile-info-block">
                                            <div className="Profile-info-block__label">
                                                <GroupGrow value={sales.grow}/>
                                            </div>

                                            <div className="Profile-info-block__table">
                                                <TableRows data={sales.values}/>
                                            </div>
                                        </div>
                                    </InfoBlock>
                                )}

                                {hasAuvs && (
                                    <InfoBlock
                                        title="AUV"
                                        subTitle="(average unit volume)"
                                        className="Profile__info-block"
                                    >
                                        <div className="Profile-info-block">
                                            <div className="Profile-info-block__label">
                                                <GroupGrow value={auv.grow}/>
                                            </div>

                                            <div className="Profile-info-block__table">
                                                <TableRows data={auv.values}/>
                                            </div>
                                        </div>
                                    </InfoBlock>
                                )}

                                {hasUnits && (
                                    <InfoBlock
                                        title="Units"
                                        className="Profile__info-block"
                                    >
                                        <div className="Profile-info-block">
                                            <div className="Profile-info-block__label">
                                                <GroupGrow value={units.grow}/>
                                            </div>

                                            <div className="Profile-info-block__table">
                                                <TableRows data={units.values}/>
                                            </div>
                                        </div>
                                    </InfoBlock>
                                )}

                                {stocks.length > 0 && (
                                    <InfoBlock
                                        title="Stock"
                                        className="Profile__info-block"
                                    >
                                        <div className="Profile-info-block">
                                            <div className="Profile-info-block__table">
                                                <TableRows
                                                    data={stocks}
                                                    className="is-full"
                                                />
                                            </div>
                                        </div>
                                    </InfoBlock>
                                )}
                            </>
                        )}
                    </div>

                    <div className="Profile__row">
                        {keyAttributes && (
                            <InfoBlock
                                title="Key Attributes"
                                testId={"widgetChainPageKeyAttributes"}
                            >
                                <TableRows
                                    data={keyAttributes}
                                    isTwoColumns={!isHiddenInfoBlocks}
                                    className="is-large white-labels"
                                />
                                {keyAttributesData.permissionLevel === PermissionLevelEnum.None && (
                                    <Lock>
                                        {getSectionData(ChainSectionsEnum.Profile).noAccessMessage}
                                        <LockDefaultAction section={SectionEnum.Profile}/>
                                    </Lock>
                                )}
                            </InfoBlock>
                        )}
                    </div>
                </div>
                <div className="Profile__section">
                    <NewsCarousel/>
                </div>
            </SectionContent>
        </div>
    );
};

export default React.memo(Profile);
