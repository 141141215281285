import React, { FC } from 'react';
import { iLTO } from "@models/LTO";
import LTOItem from "@pages/_shared/LTOItem/LTOItem";
import LazyGrid from "@components/new/Grid/LazyGrid/LazyGrid";

const FoodLtoGrid: FC<{
    data: iLTO[];
}> = (
    {
        data,
    }
) => {
    return (
        <div className="FoodProfileMenuItemsGrid">
            <LazyGrid
                itemWidth={300}
                offset={300}
            >
                {data.map((item, index) => ({
                    key: item.id,
                    value: (
                        <LTOItem
                            style={{ wrapper: { boxShadow: 'none', border: '1px solid #EAEAEA' }}}
                            data={item}
                            location="food profile"
                            testId={`widgetFoodProfileMenuItem${index}`}
                        />
                    ),
                }))}
            </LazyGrid>
        </div>
    );
}

export default FoodLtoGrid;