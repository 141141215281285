import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import ContentWidget from "@components/ContentWidget/ContentWidget";
import ChartLine from "@components/ChartLine/ChartLine";
import Icon from "@components/SpriteIcon/SpriteIcon";
import { splitRows } from "@helpers";
import ContentWidgetCaption from "@components/ContentWidget/ContentWidgetCaption";
import ContentWidgetLink from "@components/ContentWidget/ContentWidgetLink";
import './FoodProfileLocalIndices.scss';
import { AnalyticsAttribute, getAnalyticsComputedData } from "@models/AnalyticsAttributes";
import useOutsideClick from "@hooks/useOutsideClick";

export interface iLocalIndicesData {
    link: string;
    states: {
        name: string;
        value: number;
    }[];
}

const COLUMNS_COUNT = 5;
const ROWS_COUNT = 8;
const MAX_ITEMS_COUNT = COLUMNS_COUNT * ROWS_COUNT;

const FoodProfileLocalIndices: FC<{
    data: iLocalIndicesData
}> = (
    {
        data,
    }
) => {
    const [isOpened, setIsOpened] = useState(false);
    const close = useCallback(() => setIsOpened(false), []);
    const container = useRef<HTMLDivElement>(null);

    useOutsideClick({
        ref: container,
        isDisabled: !isOpened,
        fn: close,
    });

    const dataSort: iLocalIndicesData['states'] = useMemo(() => {
        const newData = [...data.states];
        newData.sort(function (a, b) {
            return a.value >= b.value ? -1 : 1;
        });
        return newData;
    }, data.states);

    const dataMaxVal = dataSort[0]?.value || 1;

    const showDetailInfo = function () {
        setIsOpened(!isOpened);
    }

    const itemsListShort = dataSort.slice(0, 7);
    const itemsListColumnsSet = splitRows(dataSort.slice(0, MAX_ITEMS_COUNT), COLUMNS_COUNT) || [];

    const buttonShowDetails = (
        <ContentWidgetLink
            onClick={showDetailInfo}
            analyticsData={{
                [AnalyticsAttribute.ClickTitle]: 'Local Popularity',
            }}
            className="font-text"
            testId="buttonFoodProfileLocalPopularityExpandCollapse"
        >
            {isOpened ? 'hide details' : 'view details'}
        </ContentWidgetLink>
    );

    const caption = (
        <ContentWidgetCaption testId="textFoodProfileLocalPopularityDescription">
            indices shown; 100 = average
        </ContentWidgetCaption>
    );

    return (
        <ContentWidget
            title="Local Popularity"
            titleTestId={"textFoodProfileLocalPopularityTitle"}
            className="FoodProfileLocalIndices"
        >
            <div ref={container}>
                <div className="ChartLine">
                    <div className="ChartLine__items-list">
                        {itemsListShort.map((item, i) => (
                            <ChartLine
                                key={i}
                                name={item.name}
                                value={`${Math.floor(item.value)}`}
                                widthPercentage={100 * (item.value / dataMaxVal)}
                                modifiers={["is-blue", "is-data-top"]}
                                testId={`textFoodProfileLocalPopularityItem${i}`}
                            />
                        ))}
                    </div>
                </div>
                {caption}
                {buttonShowDetails}
                <ContentWidget
                    title="Local indices"
                    titleTestId={"textFoodProfileLocalPopularityExpandedTitle"}
                    className={"FoodProfilePairedItems" + (isOpened ? ' is-opened' : '')}
                    modifiers={['local-indices', 'hover-data', 'no-hover']}
                >
                    <div className="ChartLine__columns">
                        {itemsListColumnsSet.map((col, c) => (
                            <div className="ChartLine__col-5" key={c}>
                                <div className="ChartLine__items-list">
                                    <div className="ChartLine">
                                        {col.map((item, i) => (
                                            <ChartLine
                                                key={i}
                                                name={item.name}
                                                value={`${Math.floor(item.value)}`}
                                                widthPercentage={100 * (item.value / dataMaxVal)}
                                                modifiers={["is-blue", "is-data-top"]}
                                                testId={`textFoodProfileLocalPopularityExpandItem${c}-${i}`}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <a
                        href={data.link}
                        target="_blank"
                        data-testid="buttonFoodProfileLocalPopularityExternalLinkt"
                        className="FoodProfileLocalIndices__logo clickable"
                        {...getAnalyticsComputedData({
                            [AnalyticsAttribute.ClickTitle]: 'Local Redirect',
                        })}
                    >
                        <Icon iconId="local-enchanced"/>
                    </a>
                    {caption}
                    {buttonShowDetails}
                </ContentWidget>
            </div>
        </ContentWidget>
    );
}
export default FoodProfileLocalIndices;