import { FC, useEffect, useState } from 'react';
import Scroll from 'react-scroll';
import cn from "classnames";
import Grid from "./FoodProfileMenuItemsGrid";
import Summary from "./FoodProfileMenuItemsSummary";
import useFoodLto from "@apiHooks/useFoodLto";
import { isAvailable, PermissionLevelEnum } from "@models/PermissionSection";
import Icon from "@components/SpriteIcon/SpriteIcon";
import './FoodProfileMenuItems.scss';
import { iFoodLtoSection } from "@models/FoodLtoSection";
import { AnalyticsAttribute, getAnalyticsComputedData } from "@models/AnalyticsAttributes";
import { allItems, NavigationItems } from '@models/PlatformNavigation';

const { Element } = Scroll;

interface iProps {
    searchQuery: string;
    requestTrial: () => Promise<any>;
    setLtoCount: (count: number) => void;
}

const FoodProfileMenuItems: FC<{
    searchQuery: string;
    requestTrial: () => void;
    data: iFoodLtoSection;
    isLoading: boolean;
}> = (
    {
        searchQuery,
        requestTrial,
        isLoading,
        data
    }
) => {
    const {
        ltos,
        summary,
        itemsCount,
    } = data;

    const [isTrialSent, setIsTrialSent] = useState(false);
    const [activeTab, setActiveTab] = useState<'items' | 'summary'>('items');

    const className = cn(
        'FoodProfileMenuItems',
        !isAvailable(ltos) && 'FoodProfileMenuItems--centered',
    );

    const sendTrialRequest = () => {
        setIsTrialSent(true);
        requestTrial();
    };

    if (!ltos.hasData && !isLoading) {
        return null;
    }

    return (
        <Element
            name="chain-activity"
            className={className}
        >
            {isLoading && (
                <div className="spinner spinner-blink">
                    <Icon iconId="logo-dark"/>
                </div>
            )}
            {!isLoading && <>
                {isAvailable(ltos) && (
                    <div className="FoodProfile__section">
                        <div className="FoodProfileMenuItems__head">
                            <h2
                                className="FoodProfileMenuItems__title"
                                data-testid="textFoodProfileMenuItemsTitle"
                            >
                                Chains featured
                                <div>
                                    &nbsp;{ltos.data.length} new items
                                </div>
                                &nbsp;with
                                <div className="FoodProfileMenuItems__title--mark">
                                    &nbsp;{searchQuery.toLowerCase()}
                                </div>.
                            </h2>
                            <div className="FoodProfileMenuItems__button-group">
                                <button
                                    className={cn(
                                        'FoodProfileMenuItems__button',
                                        'font-search',
                                        activeTab === 'items' && 'FoodProfileMenuItems__button--active',
                                    )}
                                    onClick={() => setActiveTab('items')}
                                    {...getAnalyticsComputedData({
                                        [AnalyticsAttribute.ClickTitle]: 'Chains Featuring Keyword - View',
                                    })}
                                    data-testid="buttonFoodProfileMenuItemsTiles"
                                >
                                    items
                                </button>
                                <button
                                    className={cn(
                                        'FoodProfileMenuItems__button',
                                        'font-search',
                                        activeTab === 'summary' && 'FoodProfileMenuItems__button--active',
                                    )}
                                    onClick={() => setActiveTab('summary')}
                                    {...getAnalyticsComputedData({
                                        [AnalyticsAttribute.ClickTitle]: 'Chains Featuring Keyword - View',
                                    })}
                                    data-testid="buttonFoodProfileMenuItemsTable"
                                >
                                    summary
                                </button>
                            </div>
                        </div>
                        <div className="FoodProfileMenuItems__body">
                            {activeTab === 'items' ? (
                                <Grid
                                    data={ltos.data}
                                />
                            ) : (
                                <Summary
                                    data={summary}
                                />
                            )}
                        </div>
                    </div>
                )}
                {ltos.hasData && ltos.permissionLevel === PermissionLevelEnum.None && (
                    <div className="FoodProfileMenuItems__head is-centered">
                        <h2 className="FoodProfileMenuItems__title">
                            Chains featured
                            <div>
                                &nbsp;{itemsCount} new items
                            </div>
                            &nbsp;with
                            <div>
                                &nbsp;{searchQuery}
                            </div>
                            &nbsp;in the past year.
                        </h2>
                        <h2 className="FoodProfileMenuItems__title">
                            <div>Get full details</div>
                            &nbsp;on these items with a subscription to&nbsp;
                            <div>{allItems[NavigationItems.LaunchesAndRatings].name}</div>
                        </h2>
                        <div
                            onClick={sendTrialRequest}
                            className={cn(
                                'FoodProfileMenuItems__trial-button',
                                isTrialSent && 'FoodProfileMenuItems__trial-button--hover'
                            )}
                        >
                            {isTrialSent ? ' ✓ FREE TRIAL REQUESTED' : 'REQUEST FREE TRIAL'}
                        </div>
                    </div>
                )}
            </>}
        </Element>

    );
};


export default FoodProfileMenuItems;
