import { FC, useEffect, useMemo, useRef, useState } from "react";
import Page from "@containers/Page/Page";
import PageContent from "@containers/PageContent/PageContent";
import {
    ChainDetailsDataContext,
    ChainPageScrollId,
    getAvailableSections,
    getSectionId,
} from "./ChainPage.helpers";
import ChainsDetailsHeader from "./ChainsDetailsHeader/ChainsDetailsHeader";
import { useHistory } from "react-router-dom";
import useChainDetails from "@core/apiHooks/useChainDetails";
import useQueryParam from "@core/hooks/useQueryParam";
import "./ChainPage.styles.scss";
import { routeNames } from "@core/routeNames";
import GapReportModal from "./Modals/GapReportModal/GapReportModal";
import Loader from "@components/new/Loader/Loader";
import { CustomBaseScrollbars } from "@components/CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";
import { PermissionLevelEnum } from "@core/models/PermissionSection";
import ChainPermissionPopup from "@components/ChainPermissionPopup/ChainPermissionPopup";
import UsChainsHeader from "@pages/_shared/UsChainsHeader/UsChainsHeader";
import useFireflyChains from "@apiHooks/useFireflyChains";

const ChainPage: FC = () => {
    const history = useHistory();
    const chainId = useQueryParam("id");
    const { isLoading: isAllChainsLoading, data: allChainsData } = useFireflyChains();
    const {
        data: {
            profileData: {
                data,
                permissionLevel
            },
            isInTop1000,
        },
        isLoading,
        downloadReports,
    } = useChainDetails(chainId);

    const scrollContainerRef = useRef<any>(null);
    const [isGapReportPopupOpened, setIsGapReportPopupOpened] = useState(false);

    useEffect(() => {
        if (!chainId) {
            history.push(routeNames.topChains);
        }
    }, [chainId, history]);

    const handleScrollbarsMount = (scrollbars: any) => {
        if (scrollbars) {
            const viewElm = scrollbars.view;
            scrollContainerRef.current = viewElm;
        }
    };

    const availableSections = useMemo(
        () => getAvailableSections(data.sectionsData),
        [data.sectionsData]
    );

    return (
        <ChainDetailsDataContext.Provider value={{
            data,
            sections: availableSections,
            openGapReportPopup: () => setIsGapReportPopupOpened(true),
            downloadReports: downloadReports,
        }}>
        {/* @ts-ignore */}
        <Page>
            <PageContent>
                <CustomBaseScrollbars
                    refSetter={handleScrollbarsMount}
                    id={ChainPageScrollId}
                >
                    <UsChainsHeader
                        style={{ top: 0, position: "sticky" }}
                        data={allChainsData}
                        isLoading={isAllChainsLoading || isLoading}
                    />
                    <div className="ChainPage">
                        {isLoading ? (
                            <div className="ChainPage__loader">
                                <Loader className="is-no-bg"/>
                            </div>
                        ) : (
                            <>
                                {permissionLevel === PermissionLevelEnum.Full && (
                                    <>
                                        <div className="ChainPage__spacer"/>
                                        <div className="ChainPage__header">
                                            <ChainsDetailsHeader
                                                ref={scrollContainerRef}
                                            />
                                        </div>
                                        <div className="ChainPage__content">
                                            {availableSections.map((section) => (
                                                <div
                                                    key={section.id}
                                                    className="ChainPage__section"
                                                    id={getSectionId(section.id)}
                                                >
                                                    {section.content}
                                                </div>
                                            ))}
                                        </div>
                                        {isGapReportPopupOpened && (
                                            <GapReportModal
                                                close={() => setIsGapReportPopupOpened(false)}
                                            />
                                        )}
                                    </>
                                )}

                                <ChainPermissionPopup isOpen={!isInTop1000 && permissionLevel === PermissionLevelEnum.None} />
                            </>
                        )}
                    </div>
                </CustomBaseScrollbars>
            </PageContent>
        </Page>
        </ChainDetailsDataContext.Provider>
    );
};

export default ChainPage;
