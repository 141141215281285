import { FC, useMemo } from "react";
import Modal from "@components/Modal/Modal";
import "./HaikuPopup.scss";
import Icon from "@components/SpriteIcon/SpriteIcon";
import ChartPredicted from "@components/Chart/ChartPredicted";
import AutoSizer from "react-virtualized-auto-sizer";
import { getValueWithSign } from "@helpers";
import { API__NewAllMenuTrendsPenetrationViewModel } from "@apiSchema";
import { useFoodProfileHaikuData } from "@apiHooks/useFoodProfileHaikuData";
import { IconProductMenucast } from '@icons';

const HaikuPopup: FC<{
    isOpened: boolean;
    searchQuery: string;
    totalPenetration: number;
    close: () => void;
    totalUsPenetration?: API__NewAllMenuTrendsPenetrationViewModel;
}> = (
    {
        isOpened,
        searchQuery,
        totalPenetration,
        close,
        totalUsPenetration,
    }
) => {
    const {
        isLoading,
        data,
    } = useFoodProfileHaikuData(searchQuery, totalUsPenetration);

    const fourYearGrowth = useMemo(() => {
        if (data && data.futureData && data.futureData.length) {
            const currentPenetration = data.currentYearValue;
            const lastPredictedPenetration = data.futureData[data.futureData.length - 1].value;

            if (currentPenetration !== 0) {
                return ((lastPredictedPenetration - currentPenetration) / currentPenetration) * 100;
            }
        }

        return 0;
    }, [data]);

    const lastYearLabel = useMemo(() => {
        if (data && data.futureData && data.futureData.length) {
            return data.futureData[data.futureData.length - 1].label;
        }
        return ''
    }, [data]);

    return (
        <Modal
            isOpened={isOpened}
            close={close}
            hasInner={false}
            closeOnEsc={true}
            closeOnOutsideClick={true}
        >
            <div className="HaikuPopup">
                <div className="HaikuPopup__content">
                    <div className="HaikuPopup__haiku-icon">
                        <IconProductMenucast
                            width={160}
                            height={45}
                        />
                    </div>
                    {isLoading && (
                        <div className="spinner spinner-blink">
                            <Icon iconId="logo-dark"/>
                        </div>
                    )}
                    {!isLoading && (
                        <>
                            <div className="HaikuPopup__content-inner">
                                <div className="HaikuPopup__header">
                                    <div className="HaikuPopup__header-title">
                                        {searchQuery.toLowerCase()}
                                    </div>
                                    <div className="HaikuPopup__header-subtitle">
                                        is on
                                        <div className="HaikuPopup__header-mark">{totalPenetration.toFixed(1)}%</div>
                                        of US menus
                                    </div>
                                    {isFinite(fourYearGrowth) && lastYearLabel !== '' && (
                                        <div className="font-text">
                                            projected to grow&nbsp;
                                            <b>{getValueWithSign(Math.round(+fourYearGrowth))}%</b>
                                            &nbsp;through {lastYearLabel}
                                        </div>
                                    )}
                                </div>
                                <div className="HaikuPopup__chart-wrapper">
                                    <AutoSizer>
                                        {({ width, height }) => (
                                            <ChartPredicted
                                                width={width}
                                                height={height}
                                                pastData={data.pastData}
                                                currentData={{
                                                    value: data.currentYearValue,
                                                    label: data.currentYearLabel,
                                                    tooltipValues: [...data.currentData, ...data.currentDataPredicted],
                                                    tooltipValuesSeparatorIndex: data.currentData.length,
                                                }}
                                                futureData={data.futureData}
                                                printValue={(v) => `${v.toFixed(1)}%`}
                                            />

                                        )}
                                    </AutoSizer>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="HaikuPopup__footer">
                    <div className="HaikuPopup__footer-wrapper">
                        <div className="HaikuPopup__footer-title">Prediction</div>
                        <div className="HaikuPopup__footer-caption">
                            <div className="HaikuPopup__footer-paragraph">
                                Here’s a look at where {searchQuery} has been, and where it’s headed tomorrow.
                            </div>
                            <div className="HaikuPopup__footer-paragraph">
                                How are we able to see into the future? Our Menucast™ engine accurately predicts future
                                menu penetration through machine learning, a form of artificial intelligence. We’ve
                                trained our learning algorithm with millions of cases of historic menu data going back
                                more than a decade, and today Menucast™ can forecast future menu growth with high
                                confidence.
                            </div>
                            <div className="HaikuPopup__footer-paragraph">
                                You can use these forecasts to assess the direction and velocity of future trends.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default HaikuPopup;