import { useData } from "@hooks/useData";
import { API__FoodProfileWidgetsDataViewModel } from "@apiSchema";
import http from '../fetch';
import { FoodProfileWidgets } from "@models/FoodProfileWidgets";

async function getFoodProfileWidgetsData(word: string): Promise<FoodProfileWidgets> {
    try {
        const response: API__FoodProfileWidgetsDataViewModel = await http.get('food tab widgets data', { word });

        return new FoodProfileWidgets({ apiModel: response, searchQuery: word });
    }
    catch (err) {
        debugger
        return new FoodProfileWidgets({ searchQuery: word });
    }
}

export function useFoodProfileWidgetsData(word: string) {
    return useData(new FoodProfileWidgets({ searchQuery: word }), getFoodProfileWidgetsData, word);
}