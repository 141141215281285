import { createSelector } from 'reselect';

export const emailsData = state => state.documentsData.share;
export const groupsPopupDataSelector = state => state.groupsData;

export const companyMembersEmailsListSelector = createSelector(
    emailsData,
    (data) => {
        const onlyMembers = data.emails.filter(item => item.groupId === 0);
        return onlyMembers.map(item => ({
            ...item,
            emailSubTitle: item.emailSubTitle || '',
            isCustomEmail: false
        }));
    }
);

export const isGroupsPopupOpenedSelector = createSelector(
    groupsPopupDataSelector,
    (popupData) => popupData.popupIsOpened
);
