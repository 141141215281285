import { Component } from 'react';
import PT from 'prop-types';
import './PageContent.scss';
import './search-results-content-widget.scss';

class PageContent extends Component {
    render() {
        const { children, className = '' } = this.props;
        const classList = new Set(['page-content', ...className.split(' ')]);

        return (
            <div className={Array.from(classList).join(' ')}>
                {/*<Scrollbars>*/}
                    {children}
                {/*</Scrollbars>*/}
            </div>
        );
    }
}

PageContent.propTypes = {
    children: PT.any,
    className: PT.string
};
PageContent.defaultProps = {};

// const mapStateToProps = state => ({});
//
// const mapDispatchToProps = dispatch => ({});

// export default connect(mapStateToProps, mapDispatchToProps)(PageContent);
export default PageContent;
