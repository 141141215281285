import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import './Tooltip.scss';

class Tooltip extends Component {
    constructor(props) {
        super(props);
        this.elementRef = createRef();
        this.containerRef = createRef();
        this.state = {
            isVisible: false
        };
        this.mouseEnterHandler = this.mouseEnterHandler.bind(this);
        this.mouseLeaveHandler = this.mouseLeaveHandler.bind(this);
    }

    mouseEnterHandler() {
        this.setState({
            isVisible: true
        });
    }

    mouseLeaveHandler() {
        this.setState({
            isVisible: false
        });
    }

    renderTooltipData() {
        const { tooltipData } = this.props;
        return tooltipData.map((item, index) => {
            return (
                <li className="tooltip__text" key={index}>{item.fireflyChainName || item.title}</li>
            );
        });
    }

    render() {
        const { className = '', children } = this.props;
        const { offsetHeight: containerHeight = 0 } = this.containerRef.current || { offsetHeight: 0 };
        const elTop = this.elementRef.current ? this.elementRef.current.getBoundingClientRect().top : 0;
        const windowHeight = window.innerHeight;
        const offsetTop = 0;
        const offsetBottom = 20;
        const marginTop = Math.min(0, windowHeight - elTop - offsetTop - offsetBottom - containerHeight);
        return (
            <div
                ref={this.elementRef}
                className={`tooltip ${className}`}
                onMouseMove={this.mouseEnterHandler}
                onClick={this.mouseEnterHandler}
                onMouseLeave={this.mouseLeaveHandler}
            >
                {children}
                {this.state.isVisible && (
                    <ul
                        onMouseMove={event => event.stopPropagation()}
                        onScroll={event => event.stopPropagation()}
                        ref={this.containerRef}
                        style={{
                            marginTop: `${marginTop}px`,
                            opacity: this.containerRef.current ? 1 : 0
                        }}
                        className="tooltip__container"
                    >
                        {this.renderTooltipData()}
                    </ul>
                )}
            </div>
        );
    }
}

Tooltip.propTypes = {
    tooltipData: PropTypes.array,
    className: PropTypes.string,
};

export default Tooltip;
