import { IconAdd } from "@icons";
import { FC } from "react";
import "./ContactAddButton.styles.scss";

interface iProps {
    text: string;
    onClick: () => void;
}

const ContactAddButton: FC<iProps> = ({ text, onClick }) => {
    return (
        <div
            className="ContactAddButton"
            onClick={onClick}
        >
            <div className="ContactAddButton__button">
                <IconAdd className="ContactAddButton__icon" />
                <div className="ContactAddButton__label">{text}</div>
            </div>
        </div>
    );
};

export default ContactAddButton;
