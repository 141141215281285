import { Context, createContext, FC, useContext, useEffect, useRef } from "react";
import { IconCopyTable } from "../../../icons";
import Clipboard from "clipboard";
import classNames from "classnames";
import { getFormattedDate } from "../../../core/helpers";
import Button from "../Button/Button";
import { AnalyticsData, getAnalyticsComputedData } from "@models/AnalyticsAttributes";

class CopyButtonContextData {
    data: string[][] = [['no data']];
    tooltip?: string;
    analyticsData?: AnalyticsData;
}

export const CopyButtonContext: Context<CopyButtonContextData> = createContext(new CopyButtonContextData());

const CopyButton: FC<{
    data?: string[][];
    tooltip?: string;
    className?: string;
    testId?: string;
}> = ({
    className,
    data: propData,
    tooltip: propTooltip,
    testId
}) => {
    const buttonRef = useRef<HTMLDivElement>(null);
    const { data: ctxData, tooltip: ctxTooltip, analyticsData = {}, } = useContext(CopyButtonContext);

    const data = propData || ctxData;
    const tooltip = propTooltip || ctxTooltip || '';

    const classes = classNames("TableNew__copy", className);

    useEffect(() => {
        if (buttonRef.current) {
            const clip = new Clipboard(buttonRef.current, {
                text() {
                    const currentDate = `Extracted ${getFormattedDate(new Date().toISOString(), "M/DD/YYYY")}`;
                    const text = [[currentDate], [" "], ...data].map(row => row.join("\t")).join("\n");
                    return text;
                },
            });

            clip.on("success", e => e.clearSelection());

            return () => clip.destroy();
        }
    }, [data]);

    return (
        <Button
            isWrapper
            tooltipPosition={["right", "below"]}
            className={classes}
            tooltipText={tooltip}
        >
            <div
                ref={buttonRef}
                className="clickable"
                {...getAnalyticsComputedData(analyticsData)}
                data-testid={testId}
            >
                <IconCopyTable/>
            </div>
        </Button>
    );
};

export default CopyButton;
