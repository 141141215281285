import { FC, useContext } from "react";
import Loader from "@components/new/Loader/Loader";
import Slider from "@components/Slider/Slider";
import { SLIDER_CONFIG_COLUMNS } from "@components/Slider/Slider.settings";
import useChainNews from "@core/apiHooks/useChainNews";
import { sanitizeUrl } from "@core/helpers";
import { ChainDetailsDataContext } from "../../../ChainPage.helpers";
import "./NewsCarousel.styles.scss";

const NewsCarousel: FC = () => {
    const { data: { mainData: { name } } } = useContext(ChainDetailsDataContext);
    const { data, isLoading } = useChainNews(name);

    return (
        <div className="NewsCarousel">
            <div className="NewsCarousel__title">News</div>

            <div className="NewsCarousel__content">
                {isLoading && <Loader className="is-no-bg" />}

                {!isLoading && data.length > 0 &&  (
                    <div className="NewsCarousel__slider">
                        <Slider isShownExtraSpace sliderConfig={SLIDER_CONFIG_COLUMNS}>
                            {data.map((item, index) => {
                                const { title, date, url, shortUrl, description } = item;
                                return (
                                    <div key={index} className="NewsCarouselItem">
                                        <div className="NewsCarouselItem__title">{title}</div>
                                        <div className="NewsCarouselItem__info">
                                            <div className="NewsCarouselItem__date">{date}</div>
                                            <div className="NewsCarouselItem__link">
                                                <a href={sanitizeUrl(url)} target="_blank" rel="noreferrer">{shortUrl}</a>
                                            </div>
                                        </div>
                                        <div className="NewsCarouselItem__text">{description}</div>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                )}

                {data.length === 0 && !isLoading && <div className="NewsCarousel__no-data">No current News updates</div>}
            </div>
        </div>
    );
};

export default NewsCarousel;
