import { FC } from "react";
import Modal from "../../Modal/Modal";
import "./ContactsForm.styles.scss";

interface iProps {
    isShown: boolean;
    onClose: () => void;
}

const ContactsForm: FC<iProps> = ({ isShown, onClose, children }) => {
    return (
        <Modal isOpened={isShown} close={onClose} hasInner={false}>
            <div className="ContactsForm">{children}</div>
        </Modal>
    );
};

export default ContactsForm;
