import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import Page from "@containers/Page/Page";
import PageContent from "@containers/PageContent/PageContent";
import Scrollbar from "react-custom-scrollbars";
import SpriteIcon from "@components/SpriteIcon/SpriteIcon";
import cn from "classnames";
import useQueryParam from "@hooks/useQueryParam";
import useInstantCharts from "@apiHooks/useInstantCharts";
import DownloadButton from "./DownloadButton";
import { equalInLC, sanitizeUrl } from "@helpers";
import { IconDsLogoNewSmall, IconMenutrends } from "@icons";
import useInstantChart from "@apiHooks/useInstantChart";
import { PermissionLevelEnum } from "@models/PermissionSection";
import { AppContext } from "../../AppContext";
import useTableSort from "@components/new/Table/useTableSort";
import Table from "@components/new/Table/Table";
import "./InstantCharts.style.scss";
import { CopyButtonContext } from "@components/new/Table/CopyButton";
import { useTableConfig, prepareNumberValue, prepareWholeNumberValue } from "./useInstantChartsTableConfig";
import { CustomBaseScrollbars, } from "@components/CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";
import { useDispatch } from "react-redux";
import { addNotification } from "@components/GlobalNotification/globalNotification.actions";
import { requestTrialByFeature } from "@actions/page";
import { LockAction } from "@components/new/LockSection/LockSection";
import { TABLE_HEAD_SIZE, TABLE_ROW_SIZE } from "@components/new/Table/Table.helpers";

const InstantCharts: FC = () => {
    const dispatch = useDispatch();
    const showNotification = (notification: any) => dispatch(addNotification(notification));
    const requestTrial: (f: string) => any = (featureName: string) => {
        showNotification({ text: 'Request sent'});
        return dispatch(requestTrialByFeature(featureName));
    };
    const {
        globalVariables: {
            mtdUrl,
        },
    } = useContext(AppContext);

    const [isRequestTrialSent, setIsRequestTrialSent] = useState(false);
    const query = useQueryParam('q');
    const instantChartId = useQueryParam('id') || 0;
    const [selectedInstantChartId, setSelectedInstantChartId] = useState(+instantChartId);

    const {
        isLoading,
        data: {
            data,
            permissionLevel: itemPermissionLevel,
        },
    } = useInstantChart(selectedInstantChartId);
    const {
        isLoading: isListLoading,
        data: {
            data: listData,
            permissionLevel,
        },
    } = useInstantCharts(query);

    const {
        handleSort,
        sortingField,
        sortingDirection,
        getColumnIcon,
        setSortingDirection,
        sortedData,
    } = useTableSort(data.words, "penetration");

    const tableConfig = useTableConfig(
        sortedData,
        sortingField,
        sortingDirection,
        handleSort,
        getColumnIcon,
        itemPermissionLevel !== PermissionLevelEnum.Limited,
    );

    useEffect(() => {
        if (sortingField === 'title') {
            setSortingDirection("asc");
        } else {
            setSortingDirection("desc");
        }
    }, [sortingField])

    const hasPermission = permissionLevel !== PermissionLevelEnum.None;

    const copyTableData = useMemo(() => {
        return [
            tableConfig.map(i => i.title || ''),
            ...sortedData.map(i => {
                return [
                    i.title.toUpperCase(),
                    `${i.penetration !== undefined ? prepareNumberValue(+(i.penetration), '%') : '-'}`,
                    `${i.oneYearTrend !== undefined ? prepareNumberValue(+(i.oneYearTrend), '%') : '-'}`,
                    `${i.fourYearTrend !== undefined ? prepareNumberValue( +(i.fourYearTrend), '%') : '-'}`,
                    `${i.knowIt !== undefined ? prepareWholeNumberValue( +(i.knowIt), '%') : '-'}`,
                    `${i.haveTriedIt !== undefined ? prepareWholeNumberValue( +(i.haveTriedIt), '%') : '-'}`,
                    `${i.loveOrLikeIt !== undefined ? prepareWholeNumberValue( +(i.loveOrLikeIt), '%') : '-'}`,
                ];
            })
        ];
    }, [sortedData, tableConfig]);

    return (
        /* @ts-ignore */
        <Page>
            <PageContent>
                <Scrollbar>
                    <div className="InstantCharts__title-wrapper">
                        <div className="InstantCharts__container InstantCharts__container--top">
                            <h2
                                className="InstantCharts__title"
                                data-testid="textInstantChartsPageTitle"
                            >
                                Instant charts
                            </h2>
                        </div>
                        <div className="InstantCharts__title-divider"/>
                    </div>
                    <div className="InstantCharts__content">
                        <div className="InstantCharts__container clearfix">

                            {!hasPermission && (
                                <div className="InstantCharts__trial-overlay">
                                    <SpriteIcon iconId="menutrends" width={83} height={100}/>
                                    <p className="InstantCharts__trial-text">MenuTrends</p>
                                    <p className="InstantCharts__trial-text">subscription required</p>
                                    <div className="InstantChartsPermissionOverlay__footer">
                                        <button
                                            className={cn(
                                                "InstantChartsPermissionOverlay__button",
                                                isRequestTrialSent && 'InstantChartsPermissionOverlay__button--disabled',
                                            )}
                                            onClick={() => {
                                                setIsRequestTrialSent(true);
                                                requestTrial('mtd');
                                            }}
                                            disabled={isRequestTrialSent}
                                        >
                                            REQUEST FREE TRIAL
                                        </button>
                                    </div>
                                </div>
                            )}
                            {isListLoading && (
                                <div className="spinner spinner-blink">
                                    <SpriteIcon iconId="logo-dark"/>
                                </div>
                            )}
                            {!isListLoading && (
                                <>
                                    <div className="InstantCharts__left" data-testid={`selectInstantChartsList`}>
                                        <CustomBaseScrollbars>
                                            {listData.map((item, index) => (
                                                <div
                                                    key={item.id}
                                                    onClick={() => {
                                                        setSelectedInstantChartId(item.id);
                                                    }}
                                                    className={cn(
                                                        'InstantCharts__item',
                                                        'font-text',
                                                        item.id === selectedInstantChartId && 'is-active'
                                                    )}
                                                    data-testid={`buttonInstantChartsItem${index}`}
                                                >
                                                    <span className="instant-charts__item-bold">
                                                        {item.fullName.substring(0, item.fullName.indexOf(':'))}:
                                                    </span>
                                                    {item.fullName.substring(item.fullName.indexOf(':') + 1, item.fullName.length)}
                                                </div>
                                            ))}
                                        </CustomBaseScrollbars>
                                    </div>
                                    <div className={cn(
                                        'InstantCharts__right',
                                        isLoading && 'loading',
                                    )}>
                                        {!isLoading ? (
                                            <div className="InstantChartReport">
                                                <div className="InstantChartReport__header">

                                                    <div className="InstantChartReport__header-left">
                                                        <div
                                                            className="InstantChartReport__text is-bold"
                                                            data-testid={`textInstantChartsItemTitle`}
                                                        >
                                                            {data.title}
                                                        </div>
                                                        <div
                                                            className="InstantChartReport__text"
                                                            data-testid={`textInstantChartsItemDescription`}
                                                        >
                                                            {data.description}
                                                        </div>
                                                        <div
                                                            className="InstantChartReport__text is-small"
                                                            data-testid={`textInstantChartsItemBaseSizeDescription`}
                                                        >
                                                            {data.baseSizeDescription}
                                                        </div>
                                                    </div>

                                                    <div className="InstantChartReport__header-right">
                                                        <DownloadButton reportId={`${data.id}`}/>
                                                        <a
                                                            href={sanitizeUrl(`${mtdUrl}/byo?instantChartId=${data.id}&wordsCount=${data.words.length}`)}
                                                            target="_blank"
                                                            title="view or edit in MenuTrends"
                                                            className="InstantChartMtLink clickable"
                                                            data-testid={`buttonInstantChartsExternalLink`}
                                                        >
                                                            view or edit in Menu Trends
                                                            <IconMenutrends width={40} height={48}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="InstantChartReport__table">
                                                    <div className="InstantChartReport__table-header">
                                                        <div
                                                            className="InstantChartReport__table-header-item"
                                                            style={{ background: '#00b4ff' }}
                                                            data-testid={`textInstantChartsTableSupHeaderPenetration`}
                                                        >
                                                            menu penetration
                                                        </div>
                                                        <div
                                                            className="InstantChartReport__table-header-item clickable"
                                                            style={{ background: '#eb0087' }}
                                                            data-testid={`textInstantChartsTableSupHeaderConsumer`}
                                                        >
                                                            consumer perception
                                                            <div className="InstantChartReport__tooltip">
                                                                Consumer perceptions are
                                                                in reference
                                                                <br/>
                                                                to each listed food or flavor <u>in general</u>
                                                                <br/>(not relative to a specific category)
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <CopyButtonContext.Provider
                                                        value={{
                                                            data: copyTableData,
                                                        }}
                                                    >
                                                        <Table
                                                            config={tableConfig}
                                                            rowsCount={data.words.length}
                                                            theme="light"
                                                            testId={`tableInstantChartsData`}
                                                        />
                                                    </CopyButtonContext.Provider>
                                                    {itemPermissionLevel === PermissionLevelEnum.Limited && (
                                                        <div
                                                            className={cn(
                                                                "InstantChartReport__permission-overlay",
                                                                "InstantChartFlavorOverlay",
                                                                data.words.length === 1 && "is-minimal",
                                                                data.words.length === 2 && "is-small",
                                                            )}
                                                            style={{
                                                                height: TABLE_ROW_SIZE * data.words.length,
                                                                maxHeight: `calc(100% - ${30 + TABLE_HEAD_SIZE}px)`,
                                                            }}
                                                        >
                                                            <div>
                                                                Unlock more data with <br/>
                                                                additional subscription
                                                            </div>
                                                            <div className="InstantChartFlavorOverlay__action">
                                                                <LockAction
                                                                    handleClick={() => requestTrial('flavor')}
                                                                    showNotification={false}
                                                                />
                                                                <IconDsLogoNewSmall
                                                                    width={25}
                                                                    height={25}
                                                                    className="InstantChartFlavorOverlay__icon"
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="spinner spinner-blink">
                                                <SpriteIcon iconId="logo-dark"/>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}

                        </div>
                    </div>
                </Scrollbar>
            </PageContent>
        </Page>
    );
};

export default InstantCharts;