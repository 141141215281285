import { FC, useContext } from "react";
import InfoBlock from "../../../../../components/new/InfoBlock/InfoBlock";
import { IconFunnel, IconFunnelDescription } from "../../../../../icons";
import BrandsHeader from "../BrandsHeader/BrandsHeader";
import "./BrandsFunnel.styles.scss";
import { API__FPChainData } from "../../../../../core/apiSchema";
import { ChainDetailsDataContext } from "../../../ChainPage.helpers";

interface iProps {
    data: API__FPChainData;
}

const LABELS = [
    '% who know<br> the brand',
    '% who would<br> consider it',
    '% who<br> have tried it',
    '% who<br> go back',
    '% who <br> recommend it',
];

const BrandsFunnel: FC<iProps> = (
    {
        data: {
            brandFunnelData,
        },
    }
) => {
    const {
        data: {
            mainData: {
                name: chainName,
            }
        }
    } = useContext(ChainDetailsDataContext);
    const data: Array<{
        title: string;
        value: number;
        label: string;
    }> = (brandFunnelData || []).map((item, index) => ({
        title: item.name,
        value: item.value,
        label: LABELS[index],
    }));
    return (
        <div className="BrandsFunnel">
            <BrandsHeader
                title="Brand Funnel"
                classNames="is-lead"
            />

            <div className="BrandsFunnel__columns">
                <IconFunnel className="BrandsFunnel__image" />
                {data.map(item => {
                    return (
                        <InfoBlock
                            className="BrandsFunnel__column-wrapper"
                            key={item.title}
                        >
                            <div className="BrandsFunnel__column">
                                <div className="BrandsFunnel__title">{item.title}</div>
                                <div className="BrandsFunnel__value">{item.value}%</div>
                                <div
                                    className="BrandsFunnel__label"
                                    dangerouslySetInnerHTML={{
                                        __html: item.label,
                                    }}
                                />
                            </div>
                        </InfoBlock>

                    );
                })}
            </div>

            <InfoBlock className="BrandsFunnel__description-wrapper">
                <div className="BrandsFunnel__description">
                    <div className="BrandsFunnel__description-text">
                        The brand funnel traces the consumer journey from discovery of the brand to becoming a brand advocate.
                        <br/>
                        <br/>
                        Use this to determine the key points where <b>{chainName}</b> should drive awareness, encourage trial, or improve retention and advocacy.
                    </div>
                    <IconFunnelDescription className="BrandsFunnel__description-icon" />
                </div>
            </InfoBlock>
        </div>
    );
};

export default BrandsFunnel;
