import {
    NOTIFICATION__ADD,
    NOTIFICATION__REMOVE
} from '@actions/actionsNamesList';

export function setNotification(notification) {
    return {
        type: NOTIFICATION__ADD,
        notification
    };
}

export function removeNotification(id) {
    return {
        type: NOTIFICATION__REMOVE,
        id
    };
}

export function addNotification(notification) {
    const id = notification.id || Date.now();
    const duration = notification.duration || 5000;
    const isIconDisabled = notification.iconDisabled || false;
    const improvedNotification = Object.assign({}, {...notification, duration, isIconDisabled, id });
    return (dispatch) => {
        dispatch(setNotification({ ...improvedNotification }));
        if (duration > 0) {
            setTimeout(() => {
                dispatch(removeNotification(id));
            }, improvedNotification.duration);
        }
    };
}
