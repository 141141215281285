import { Component, createRef } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import Scrollbar from 'react-custom-scrollbars';
import TextArea from 'react-textarea-autosize';
import { shareEmailsListSelector } from '../../selectors/documents.selectors';
import { sharePackage } from '../../actions/packages.actions';
import { getEmailsData } from '../../actions/documents.actions';
import { addNotification } from '../../components/GlobalNotification/globalNotification.actions';
import Popup from '../../components/Popup/Popup';
import SpriteIcon from '../../components/SpriteIcon/SpriteIcon';
import Http from '../../core/fetch';
import { emailRegExp } from '../../core/old_helpers';
import { currentCompanyNameSelector } from '../../selectors/page';

class PackagesSharePopup extends Component {
    constructor(props) {
        super(props);

        this.closePopupInstance = this.closePopupInstance.bind(this);
        this.handleClickInPopup = this.handleClickInPopup.bind(this);
        this.handleClickTagInputArea = this.handleClickTagInputArea.bind(this);
        this.state = {
            emailInvalid: false,
            suggestedList: [],
            selectedList: [],
            isCustomEmailAdded: false,
            itemsWithoutAccess: [],
            emailsToValidate: [],
            itemsNoAccessBlockVisible: false,
            isInputMultiline: false,
            messageText: 'Check out this interesting package from SNAP!',
            selectedOfSuggested: null,
        };

        this.searchEmailRef = createRef();
        this.scrollbarRef = createRef();
        this.inputOuterRef = createRef();
        this.suggestListRef = createRef();
    }

    handleClickTagInputArea() {
        this.searchEmailRef.current.focus();
    }

    componentDidMount() {
        this.props.getEmailsData();
    }

    componentDidUpdate(prevProps, prevState) {
        const currentLength = this.state.selectedList.length;
        const prevLength = prevState.selectedList.length;
        const scrollBar = this.scrollbarRef.current;
        const input = this.inputOuterRef.current;

        if (this.props.sharePopupOpen) {
            document.addEventListener('click', this.closeOnClickOutside.bind(this));
            if (this.props.listOfEmails.length < 1) this.props.getEmailsData();
        } else {
            document.removeEventListener('click', this.closeOnClickOutside.bind(this));
        }

        if (currentLength !== prevLength) {
            setTimeout(() => {
                scrollBar.scrollToBottom();
                const scrollBarHeight = scrollBar.container.firstElementChild.firstElementChild.offsetHeight;
                if (scrollBarHeight > input.offsetHeight) {
                    this.setState({
                        isInputMultiline: true,
                    });
                } else if (scrollBarHeight < input.offsetHeight) {
                    this.setState({
                        isInputMultiline: false,
                    });
                }
            }, 100);
        }

        if (this.state.selectedOfSuggested !== null) {
            const suggestList = this.suggestListRef.current;
            const item = suggestList ? suggestList.children[this.state.selectedOfSuggested] : false;

            if (item) {
                if (item.offsetTop + item.offsetHeight >= suggestList.scrollTop + suggestList.offsetHeight) {
                    suggestList.scrollTop = item.offsetTop + item.offsetHeight - suggestList.offsetHeight;
                }

                if (item.offsetTop <= suggestList.scrollTop) {
                    suggestList.scrollTop = item.offsetTop;
                }
            }
        }
    }

    closeOnClickOutside(event) {
        if (event.target.classList.contains('popup')) {
            this.props.closeSharePopup();
        }
    }

    handleChange(e) {
        const searchQuery = e.target.value.toLowerCase();
        const items = this.props.listOfEmails.filter((elem) => {
            const searchName = elem.emailSubTitle.toLowerCase();
            const searchEmail = elem.email.toLowerCase();
            return (searchName.indexOf(searchQuery) !== -1 || searchEmail.indexOf(searchQuery) !== -1) &&
                searchQuery.length > 0;
        });

        this.setState({
            suggestedList: items,
            searchQuery,
        });
    }

    handleKeyUp(e) {
        if (this.state.emailInvalid) this.setState({ emailInvalid: false });
        const inputValue = e.target.value.trim();
        if (e.which === 13) {
            if (emailRegExp.test(inputValue)) {
                let newList;
                if (this.state.emailInvalid) this.setState({ emailInvalid: false });
                const coincidenceEmail = this.props.listOfEmails.find((elem) => {
                    return elem.email.toLowerCase() === inputValue.toLowerCase();
                });
                if (coincidenceEmail) {
                    if (this.isAlreadySelected(inputValue)) {
                        this.searchEmailRef.current.value = '';
                        return;
                    }
                    newList = [].concat(this.state.selectedList, coincidenceEmail);
                    this.setState({
                        selectedList: newList,
                        suggestedList: [],
                    });
                } else {
                    if (this.isAlreadySelected(inputValue)) {
                        this.searchEmailRef.current.value = '';
                        return;
                    }
                    const customEmail = {
                        isCustom: true,
                        email: inputValue,
                        emailSubTitle: '',
                        type: 3,
                        groupId: null,
                        userId: null,
                    };
                    newList = [].concat(this.state.selectedList, customEmail);
                    const customEmailForCheck = {
                        isUserGroup: false,
                        destination: inputValue,
                    };
                    this.setState({
                        suggestedList: [],
                        selectedList: newList,
                        emailsToValidate: [...this.state.emailsToValidate, customEmailForCheck],
                        selectedOfSuggested: null,
                    });
                    Http.post('packages check items permissions', {}, {
                        body: JSON.stringify([...this.state.emailsToValidate, customEmailForCheck]),
                        headers: {
                            'content-type': 'application/json; charset=utf-8',
                        },
                    }).then((list) => {
                        this.setState({
                            itemsWithoutAccess: list,
                            isCustomEmailAdded: !!list.length,
                        });
                    });
                }
                this.searchEmailRef.current.value = '';
            } else {
                if (this.state.selectedOfSuggested === null) {
                    this.setState({ emailInvalid: true });
                }
            }
        }
        this.allowArrowsSelection(e, inputValue);
    }

    allowArrowsSelection(e, inputValue) {
        e.preventDefault();

        const { suggestedList, selectedOfSuggested, selectedList } = this.state;


        if (suggestedList.length < 1) return;

        switch (e.which) {
            case 38: // 'up'
                if(selectedOfSuggested !== null) {
                    const node = this.searchEmailRef.current;
                    node.setSelectionRange(node.value.length, node.value.length);
                }
                if (selectedOfSuggested === 0) {
                    break;
                }
                this.setState({
                    selectedOfSuggested: selectedOfSuggested - 1
                });
                break;

            case 40: // 'down'
                if(selectedOfSuggested !== null) {
                    const node = this.searchEmailRef.current;
                    node.setSelectionRange(node.value.length, node.value.length);
                }
                if (selectedOfSuggested === suggestedList.length - 1) {
                    break;
                }
                this.setState({
                    selectedOfSuggested: selectedOfSuggested + 1
                });

                if (selectedOfSuggested === null) this.setState({ selectedOfSuggested: 0 });
                break;

            case 13: // 'Enter'
                if(this.state.selectedOfSuggested !== null) {
                    // this.selectEmail(suggestedList.filter(elem => selectedList.filter(el => el === elem).length === 0)[selectedOfSuggested]);
                    const emailSelectedByArrows = suggestedList.filter(elem => selectedList.filter(el => el === elem).length === 0)[selectedOfSuggested];
                    const emailsArray = inputValue.split(',').map(email => email.trim());
                    const uniqueEmails = Array.from(new Set(emailsArray)).splice(0, emailsArray.length - 1);
                    let newList = [...this.state.selectedList];
                    uniqueEmails.map(email => {
                        if (/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
                            if (this.state.emailInvalid) this.setState({ emailInvalid: false });
                            const suggestInList = this.props.listOfEmails.filter((elem) => {
                                return elem.email.toLowerCase() === email.toLowerCase();
                            });
                            const suggestInSelection = this.state.selectedList.filter((elem) => {
                                return elem.email.toLowerCase() === email.toLowerCase();
                            });
                            if (suggestInList.length) {
                                if (this.isAlreadySelected(email)) return;
                                newList.push(suggestInList[0]);
                            } else if (!suggestInSelection.length) {
                                const customEmail = {
                                    isCustomEmail: true,
                                    email: email,
                                    emailSubTitle: '',
                                    type: 3,
                                    groupId: null,
                                    userId: null,
                                };
                                newList.push(customEmail);
                            }
                        }
                    });
                    newList.push(emailSelectedByArrows);

                    this.setState({
                        suggestedList: [],
                        selectedList: newList,
                        selectedOfSuggested: null,
                    });
                    e.target.value = '';
                }
                break;

            default: break;
        }
    }

    showAllList(e) {
        e.stopPropagation();
        this.setState({ suggestedList: this.props.listOfEmails });
        this.searchEmailRef.current.focus();
    }

    renderSuggestList() {
        return this.state.suggestedList
            .filter(elem => this.state.selectedList.filter(el => el === elem).length === 0)
            .map((elem, index) => {
                const arr = elem.emailSubTitle.split(' ');
                let Abbr = '';
                arr.forEach((word, index) => {
                    if (index >= (elem.groupId !== 0 ? 1 : 2)) return;
                    Abbr += word.charAt(0);
                });
                if (elem.groupId !== 0) {
                    Abbr = elem.email.charAt(0);
                }
                return (<li
                    className={`popup__suggest-item ${this.state.selectedOfSuggested === index ? 'popup__suggest-item--selected' : ''}`}
                    key={index}
                    onClick={this.selectEmail.bind(this, elem)}
                >
                    <div className={`popup__suggest-circle ${elem.groupId && 'popup__suggest-circle--group'}`}>
                        {Abbr}
                    </div>
                    {
                        elem.groupId !== 0 ? (
                            <div>
                                <div className="popup__suggest-name">
                                    {elem.email}
                                </div>
                                <div className="popup__suggest-email">
                                    {elem.emailSubTitle === '?' ? '' : elem.emailSubTitle}
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="popup__suggest-name">
                                    {elem.emailSubTitle}
                                </div>
                                <div className="popup__suggest-email">
                                    {elem.email}
                                </div>
                            </div>
                        )
                    }
                </li>);
            });
    }

    renderSelectedList() {
        return this.state.selectedList.map((elem, index) => {
            const classList = new Set([
                'popup__suggest-item',
                elem.groupId !== 0 ? 'popup__suggest-item--group' : '',
            ]);
            let title = '';

            if (elem.groupId !== 0) {
                title = elem.email;
            } else if (elem.emailSubTitle.length) {
                title = elem.emailSubTitle;
            } else {
                title = elem.email;
            }

            return (
                <div
                    className={Array.from(classList).join(' ')}
                    key={index}
                >
                    <div className="popup__suggest-name">
                        {title}
                    </div>
                    <div
                        className="popup__remove"
                        onClick={this.removeSelectedItem.bind(this, index)}
                    >
                        <SpriteIcon iconId="remove"/>
                    </div>
                </div>
            );
        });
    }

    handleShareClick() {
        if (this.state.selectedList.length < 1) return;
        const emailsToSend = [];
        const groupsToSend = [];
        this.state.selectedList.forEach((elem) => {
            if (elem.groupId === 0) {
                emailsToSend.push({
                    email: elem.email,
                    isCustomEmail: false,
                    emailSubTitle: elem.emailSubTitle,
                });
            } else if (elem.groupId !== null) groupsToSend.push(elem.groupId);
            if (elem.isCustom) {
                emailsToSend.push({
                    email: elem.email,
                    isCustomEmail: true,
                });
            }
        });
        const data = {
            message: document.getElementById('share_msg').value,
            packageId: this.props.activePackage.id,
            emails: emailsToSend,
            groupsIds: groupsToSend,
        };
        this.props.sharePackage(data);
        this.closePopupInstance();
        this.props.showNotification({text: 'Package successfully sent', duration: 6000});
    }

    isAlreadySelected(email) {
        return this.state.selectedList.some(elem => elem.email.toLowerCase() === email.toLowerCase());
    }

    selectEmail(item) {
        if (this.isAlreadySelected(item.email)) return;
        const newList = [].concat(this.state.selectedList, item);
        this.setState({
            selectedList: newList,
            selectedOfSuggested: null,
        });
        const searchInput = document.getElementById('search_email');
        searchInput.value = '';
        this.handleChange({ target: { value: '' } });

        this.searchEmailRef.current.focus();
    }

    removeSelectedItem(index) {
        const item = { ...this.state.selectedList[index] };
        let filteredEmailsToValidate;
        if (item.isCustom || item.groupId !== 0) {
            filteredEmailsToValidate = this.state.emailsToValidate.filter(email => email.destination !== item.email);
            this.setState({
                emailsToValidate: filteredEmailsToValidate,
            });
            if (filteredEmailsToValidate.length) {
                Http.post('packages check items permissions', {},
                    {
                        body: JSON.stringify(filteredEmailsToValidate),
                        headers: {
                            'content-type': 'application/json; charset=utf-8',
                        },
                    },
                )
                    .then((list) => {
                        if (list.length) {
                            this.setState({
                                itemsWithoutAccess: list,
                                isCustomEmailAdded: true,
                            });
                        }
                    });
            } else {
                this.setState({
                    itemsWithoutAccess: filteredEmailsToValidate,
                    isCustomEmailAdded: false,
                    itemsNoAccessBlockVisible: false,
                });
            }
        }
        this.setState({
            selectedList: this.state.selectedList.filter((elem, i) => i !== index),
        });
    }

    closePopupInstance() {
        this.setState({
            selectedList: [],
            suggestedList: [],
            isCustomEmailAdded: false,
            emailInvalid: false,
            emailsToValidate: [],
            itemsNoAccessBlockVisible: false,
        });
        this.props.closeSharePopup();
    }

    openWarningItems() {
        this.setState({
            itemsNoAccessBlockVisible: true,
        });
    }

    handleClickInPopup(e) {
        e.stopPropagation();
        if (this.state.suggestedList.length > 1) {
            this.setState({ suggestedList: [], selectedOfSuggested: null });
        }
    }

    renderAccessMessage() {
        const { companyName } = this.props;
        return (
            <span className="popup__attention-text">
                Due to sharing rules, package recipients outside of {companyName} will not be able to access&nbsp;
                <span
                    className="popup__attention-text-link"
                    onClick={this.openWarningItems.bind(this)}
                >
                    these items.
                </span>
            </span>
        );
    }

    render() {
        const { packageName, filesCount } = this.props;
        return (
            <Popup
                className="popup-share popup--fade-in"
                isOpened={this.props.sharePopupOpen}
                onClose={this.closePopupInstance}
            >
                <div className="popup__inner popup__inner--small" onClick={this.handleClickInPopup}>
                    <div className="popup__header">
                        <div className="popup__title popup__title--centered popup__title--smaller-font">
                            Send your {packageName} package that contains {filesCount} file{filesCount !== 1 && 's'}
                        </div>
                    </div>
                    <div className="popup__main">
                        <div
                            className={'popup-share__input-wrapper'}
                            ref={this.inputOuterRef} onClick={this.handleClickTagInputArea}>
                            <label className="popup__input-label">To:</label>
                            <div className="popup__input-message">
                                {this.state.emailInvalid ? (
                                    <div className="popup__error-email">
                                        invalid email
                                    </div>
                                ) : (
                                    <div
                                        className="popup__all-list"
                                        onClick={this.showAllList.bind(this)}
                                    >
                                        view contacts
                                    </div>
                                )}
                            </div>
                            <span className="popup__input popup__input--fake">
                                        <input
                                            className="popup__input"
                                            type="text"
                                            id="search_email"
                                            ref={this.searchEmailRef}
                                            onChange={this.handleChange.bind(this)}
                                            onKeyUp={this.handleKeyUp.bind(this)}
                                            placeholder={this.state.selectedList.length === 0 ? 'email or group' : ''}
                                        />
                                {this.state.searchQuery}
                                    </span>
                        </div>
                        <div className="popup__suggest">
                            {
                                this.state.suggestedList.length > 0 &&
                                (<ul className="popup__suggest-list" ref={this.suggestListRef}>
                                    {this.renderSuggestList()}
                                </ul>)
                            }
                        </div>
                        <div
                            className="popup__input-outer"
                            ref={this.inputOuterRef} onClick={this.handleClickTagInputArea}
                        >
                            <Scrollbar
                                className="share-popup__scrollbar-vertical"
                                ref={this.scrollbarRef}
                                renderView={props => <div {...props} className="scrollbar-view"/>}
                                renderTrackVertical={props => <div {...props} className="scrollbar-vertical"/>}
                                autoHide
                            >
                                <div className="popup__scrollbar-inner">
                                    {this.state.selectedList.length > 0 && this.renderSelectedList()}
                                </div>
                            </Scrollbar>
                        </div>
                    </div>
                    <div className="popup__textarea-outer popup__textarea-outer--share-popup">
                        <label
                            className="popup__textarea-label popup__textarea-label--share-popup"
                            htmlFor="share_msg"
                        >
                            Add Message (optional):
                        </label>
                        <div
                            className={'popup__textarea-scrollbar'}
                        >
                            <Scrollbar>
                                <div className="popup__textarea-wrapper">
                                    <TextArea
                                        useCacheForDOMMeasurements
                                        autoHide
                                        className="popup__textarea"
                                        id="share_msg"
                                        defaultValue={'Check out this interesting package from SNAP!'}
                                        maxLength="300"
                                        onKeyDown={({ target }) => this.setState({ messageText: target.value })}
                                    />
                                    <div className="popup__textarea-fake">
                                        {this.state.messageText}
                                    </div>
                                </div>
                            </Scrollbar>
                        </div>
                    </div>
                    <div className="popup__footer popup__footer--share-package">
                        {this.state.isCustomEmailAdded && (
                            <div className="popup__attention">
                                {this.renderAccessMessage()}
                            </div>
                        )}
                        <div className="popup__right-side popup__right-side--flex">
                            <button
                                className="popup-share__link popup__link"
                                onClick={this.closePopupInstance}
                            >
                                cancel
                            </button>
                            <button
                                className="popup__btn popup__btn--md"
                                onClick={this.handleShareClick.bind(this)}
                                disabled={this.state.selectedList.length < 1}
                            >
                                send
                            </button>
                        </div>
                    </div>
                    {this.state.itemsNoAccessBlockVisible && (
                        <div className="popup__packages-warning-items">
                            <ul className="popup__packages-warning-items-list">
                                {this.state.itemsWithoutAccess.map((item, index) => (
                                    <li key={index}>
                                        <span className="popup__packages-report-name">
                                            {item.name}
                                        </span>
                                        <span className="popup__packages-report-type">
                                            ({item.type})
                                         </span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </Popup>
        );
    }
}

PackagesSharePopup.propTypes = {
    getEmailsData: PT.func,
    activePackage: PT.object,
    packageName: PT.string,
    filesCount: PT.number,
    companyName: PT.string,
    sharePopupOpen: PT.bool,
    closeSharePopup: PT.func,
    sharePackage: PT.func,
    showNotification: PT.func,
    listOfEmails: PT.array,
};

const mapStateToProps = state => ({
    listOfEmails: shareEmailsListSelector(state),
    companyName: currentCompanyNameSelector(),
});

const mapDispatchToProps = dispatch => ({
    getEmailsData: () => dispatch(getEmailsData()),
    showNotification: text => dispatch(addNotification(text)),
    sharePackage: data => dispatch(sharePackage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PackagesSharePopup);
