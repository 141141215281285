import { equalInLC, includesInLC } from '@helpers';
import { API__RootUserInfoViewModel, API__SidebarProductViewModel } from "@apiSchema";

export enum FeatureFlags {
    TestFeatureFlag = 'Test Feature Flag',
}

function getFeatureFlagValue(items: API__RootUserInfoViewModel['featureFlags'], featureFlag: FeatureFlags): boolean {
    return items.some(i =>
        equalInLC(i.name, `${featureFlag}`) && i.isOn
    );
}

export interface iGlobalUserData {
    hasFFChainsEnableLoginPermission: boolean;
    hasFFChainsFeaturePermission: boolean;
    hasVideoFeature: boolean;
    reportProUrl: string;
    mtdUrl: string;
    productsConfig: API__RootUserInfoViewModel['sidebarMenuItems'];
    navigationItems: API__RootUserInfoViewModel['navigationItems'];
    isFfChainsDisabled: boolean;
    featureFlags: {
        isTestFeatureFlagEnabled?: boolean;
    };
    hasAdminAccess: boolean;
    hasMacAdminAccess: boolean;
    helpUrl: string;
}

export default class GlobalUserData implements iGlobalUserData{
    hasFFChainsEnableLoginPermission= false;
    hasFFChainsFeaturePermission: boolean = false;
    hasVideoFeature = false;
    reportProUrl = '';
    mtdUrl = '';
    productsConfig: iGlobalUserData['productsConfig'] = [];
    navigationItems: iGlobalUserData['navigationItems'] = [];
    isFfChainsDisabled: boolean = false;
    featureFlags: iGlobalUserData['featureFlags'] = {
        isTestFeatureFlagEnabled: false,
    };
    hasAdminAccess: boolean = true;
    hasMacAdminAccess: boolean = true;
    helpUrl: string = '';

    constructor(pageData?: API__RootUserInfoViewModel | null) {
        if (pageData) {
            this.hasFFChainsEnableLoginPermission = pageData.hasSnapFeaturesProduct || false;
            this.hasFFChainsFeaturePermission = pageData.hasSnapFeaturesFfChains || false;
            this.hasVideoFeature = pageData.hasVideoFeature || false;
            this.reportProUrl = pageData.reportsProUrl || '';
            this.mtdUrl = pageData.sidebarMenuItems.find(i => i.title.toLowerCase().includes('menutrends'))?.url || '';
            this.productsConfig = pageData.sidebarMenuItems;
            this.navigationItems = pageData.navigationItems;
            this.isFfChainsDisabled = !pageData.hasSnapFeaturesProduct;
            this.featureFlags = {
                isTestFeatureFlagEnabled: getFeatureFlagValue(pageData.featureFlags, FeatureFlags.TestFeatureFlag),
            };
            this.hasAdminAccess = pageData.headerUserActionsMenuItems.some(i => includesInLC(i.title, 'admin'));
            this.hasMacAdminAccess = pageData.headerUserActionsMenuItems.some(i => includesInLC(i.title, 'mac'));
            this.helpUrl = pageData.helpUrl;
        }
    }
}