import Page from "@containers/Page/Page";
import PageContent from "@containers/PageContent/PageContent";
import MacDetails from "@pages/Documents/Mac/MacDetails";
import Scrollbars from "react-custom-scrollbars";

const Mac = () => {
    return (
        /* @ts-ignore */
        <Page>
            <PageContent>
                <Scrollbars renderView={props => <div {...props} id="view-document__scrollbar-view"/>}>
                    <MacDetails documentInfo={{ docType: "mac" }} />
                </Scrollbars>
            </PageContent>
        </Page>
    );
};

export default Mac;
