import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import serializeParams from '../../../core/serializeParams';

import SpriteIcon from '../../../components/SpriteIcon/SpriteIcon';
import { addNotification } from '@components/GlobalNotification/globalNotification.actions';
import { setSharePopupState, } from '@actions/documents.actions';
import { startEditing } from '@containers/DocumentEditPopup/documentEditPopup.actions';
import { addFileToPackage } from '@actions/packages.actions';
import PackageAddWrapper from '@components/PackageAddWrapper/PackageAddWrapper';
import getFileSize from '../../../core/getFileSize';
import { isImpersonateModeSelector } from '@selectors/page';
import './DocumentItemStyle.scss'

const btns = [
    {
        id: 0,
        text: 'Download',
        iconId: 'download'
    },
    {
        id: 1,
        text: 'Package',
        iconId: 'add-to-package',
        title: 'add this item to a package; you can then share your package with colleagues'
    },
    {
        id: 2,
        text: 'Share',
        iconId: 'share'
    },
    {
        id: 3,
        text: 'Edit',
        iconId: 'edit',
        title: 'edit document'
    }
];

class DocumentItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            downloadActionsVisible: false
        };
    }

    renderBtns(callbacks) {
        return btns.map((item, index) => {
            if (item.text === 'Edit' && !this.props.canEdit) {
                return null;
            }
            const classList = new Set([
                'document-item__btn',
                item.iconId === 'download' ? 'document-item__btn--blue document-item__btn--download' : ''
            ]);
            return (
                this.props.isSingle && item.isLink ?
                    '' :
                    (
                        <button
                            className={Array.from(classList).join(' ')}
                            key={index}
                            onClick={callbacks[index].method}
                        >
                            {item.isLink ? (
                                    <Link
                                        to={{
                                            pathname: '/view-document',
                                            search: `?id=${
                                                this.props.id
                                                }&doctype=${
                                                this.props.documentType
                                                }&fromDocuments=${
                                                encodeURIComponent(this.props.searchWord)
                                                }`
                                        }}
                                        className="document-item__btn-link"
                                        title={item.title}
                                    >
                                    <span className="document-item__btn-icon">
                                        <SpriteIcon iconId={item.iconId} className="btn-icon-svg"/>
                                    </span>
                                        <p className="document-item__btn-text">
                                            {item.text}
                                        </p>

                                    </Link>) :
                                (<div title={item.title}>
                                    {
                                        item.iconId === 'add-to-package' ?
                                            (
                                                <PackageAddWrapper>
                                                    <span className="document-item__btn-icon">
                                                        <SpriteIcon iconId={item.iconId} className="btn-icon-svg"/>
                                                    </span>
                                                    <p className="document-item__btn-text">
                                                        {item.text}
                                                    </p>
                                                </PackageAddWrapper>
                                            ) :
                                            (
                                                <div>
                                                    <span className="document-item__btn-icon">
                                                        <SpriteIcon iconId={item.iconId} className="btn-icon-svg"/>
                                                    </span>
                                                    <p className="document-item__btn-text">
                                                        {item.text}
                                                    </p>
                                                </div>
                                            )
                                    }
                                </div>)
                            }
                        </button>
                    ));
        });
    }

    btnsOnClickCallbacks() {
        const context = this;
        return [
            {
                key: 'download',
                method(e) {
                    e.stopPropagation();
                    e.preventDefault();
                    if (context.props.downloadLinks.length <= 1) {
                        window.location.href = process.env.REACT_APP_API + context.props.downloadLinks[0].linkToDownload;
                    } else {
                        context.setState({ downloadActionsVisible: true });
                    }
                }
            },
            {
                key: 'add-to-package',
                method(e) {
                    e.stopPropagation();
                    e.preventDefault();
                    context.props.addFileToPackage({
                        itemId: context.props.id,
                        itemDocumentType: context.props.docType
                    });
                }
            },
            {
                key: 'share',
                method(e) {
                    e.stopPropagation();
                    e.preventDefault();
                    context.props.showPopup({
                        id: context.props.id,
                        documentType: context.props.documentType,
                        shareLinkString: context.props.shareLinkString,
                        publicationFamily: context.props.publicationFamily,
                        title: context.props.title,
                        popupType: 'allDocuments'
                    });
                }
            },
            {
                key: 'edit',
                method(e) {
                    e.stopPropagation();
                    e.preventDefault();
                    context.props.editDocument({
                        ...context.props,
                        shareWith: context.props.companyIds,
                        name: context.props.title,
                        initialName: context.props.title,
                        size: context.props.fileSize,
                        projectId: context.props.projectId,
                        typeId: context.props.documentTypes.find(({ title }) => title.toLowerCase() === context.props.myDocumentTypeName.toLowerCase()).value
                    });
                }
            }
            // {
            //     key: 'sample',
            //     method: function (e) {
            //         alert('sample btn clicked');
            //     }
            // }
        ];
    }

    renderDownloadActions() {
        const btns = this.props.downloadLinks.map((elem, index) => {
            return (
                <button
                    key={index}
                    className="report-item__btn"
                    onClick={e => console.log(e.target)}
                >
                    <a href={process.env.REACT_APP_API + elem.linkToDownload}>
                        <span className="report-item__btn-icon">
                            <SpriteIcon iconId={`icon-${elem.fileExtension}`} className="btn-icon-svg"/>
                        </span>
                        {elem.title}
                    </a>
                </button>
            );
        });

        return (
            <div>
                <button
                    className="document-item__btn document-item__btn--back"
                    onClick={this.goBackFromDownload.bind(this)}
                >
                    <span className="document-item__btn-icon">
                        <SpriteIcon iconId="back_arrow" className="btn-icon-svg"/>
                    </span>
                    go back
                </button>
                {btns}
            </div>
        );
    }

    goBackFromDownload() {
        this.setState({ downloadActionsVisible: false });
    }

    copyToClipboard(e) {
        e.preventDefault();
        const dummy = document.createElement('input');
        document.body.appendChild(dummy);
        dummy.setAttribute('id', 'dummy_id');
        document.getElementById('dummy_id').value = this.props.shareLinkString;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
        this.props.showNotification({
            text: 'Link copied to your clipboard. You can paste it into email or any else you need.',
            duration: 3000,
        });
    }

    renderProjectLink() {
        const { projectId, projectName, isShared, isSingle, } = this.props;
        const params = {
            id: projectId
        };
        if (isShared) {
            params.isShareMode = isShared;
        }

        const classList = new Set(['document-item__project-link']);
        if (!isSingle) {
            classList.add('document-item__project-link--list')
        }

        return (
            <Link
                to={{
                    pathname: '/project',
                    search: `?${
                        serializeParams(params)
                        }`
                }}
                className={Array.from(classList).join(' ')}
            >
                {projectId ? projectName : ''}
            </Link>
        );
    }

    renderfileSize() {
        const { fileSize } = this.props;
        if (fileSize) {
            const size = getFileSize(parseInt(fileSize));
            return (
                <span>
                    · {Math.round(size.value)} {size.units}
                </span>
            );
        }
        return null;
    }

    render() {
        const {
            fileExtention, thumnailUrl = '', title, subTitle, description = '',
            isSingle, thumnailExist, tableMode, pages, isImpersonateMode,
        } = this.props;
        const { downloadActionsVisible } = this.state;
        const classList = new Set([
            'document-item',
            isSingle ? 'document-item--single' : (!tableMode ? 'document-item--in-list' : ''),
            downloadActionsVisible ? 'document-item--hovered' : '',
            tableMode ? 'document-item--in-table' : ''
        ]);
        if (isImpersonateMode) {
            classList.add('no-blur');
        }
        const imgClassList = new Set([
            !isSingle ? 'document-item__image-block' : 'document-item__image-block--big',
            thumnailExist ? '' : 'document-item__image-block--no-thumbnail'
        ]);
        let iconType = fileExtention ? fileExtention.replace('.', '').substr(0, 3) : 'other';
        if (iconType.length < 3) {
            iconType = 'ppt';
        }
        if (fileExtention === '.webm') iconType = 'other';
        return (
            <div className={Array.from(classList).join(' ')}>
                {isSingle && (
                    <div className="document-item__inner">
                        <div className={Array.from(imgClassList).join(' ')}>
                            {thumnailExist ? (
                                <img
                                    src={
                                        isSingle
                                            ? thumnailUrl.replace(/[^&size=]{3,}$/, '675')
                                            : thumnailUrl
                                    }
                                    alt={title} className="document-item__image"
                                />
                            ) : <SpriteIcon iconId={`file-${iconType}`}/>
                            }
                        </div>
                        <div className="document-item__content">
                            {!isImpersonateMode && (
                                <a
                                    className="document-item__link document-item__link--no-float"
                                    title="create an easy link to this document you can share with others"
                                    onClick={this.copyToClipboard.bind(this)}
                                >
                                    Copy link
                                </a>
                            )}
                            {!isImpersonateMode && (
                                <div className="document-item__btns-block--expanded">
                                {
                                    downloadActionsVisible
                                        ? this.renderDownloadActions()
                                        : this.renderBtns(this.btnsOnClickCallbacks.call(this))
                                }
                                </div>
                            )}
                            {!tableMode && (
                                <div className="document-item__title">
                                    {title}
                                </div>
                            )}
                            {!tableMode && (
                                <div className="document-item__sub-title">
                                    {subTitle} · {this.renderProjectLink()} {this.renderfileSize()}
                                </div>
                            )}
                            <div
                                className="document-item__text content"
                                dangerouslySetInnerHTML={{
                                    __html: `<div>${description}</div>`
                                }}
                            >
                            </div>
                        </div>
                    </div>
                )}
                {tableMode ? (
                    <div className="document-item__btns-block">
                        {!isImpersonateMode && (
                            downloadActionsVisible ?
                                this.renderDownloadActions() :
                                this.renderBtns(this.btnsOnClickCallbacks.call(this))
                        )}
                    </div>
                ) : !isSingle && (
                    <div className="document-item__container">
                        <div
                            className={`document-item__img-container${!thumnailExist ? ' document-item__img-container--flex' : '' }`}>
                            {thumnailExist ? (
                                <img
                                    src={thumnailUrl.replace(/[^&size=]{3,}$/, '170')}
                                    alt={title}
                                />
                            ) : (
                                <SpriteIcon
                                    iconId={`file-${iconType}`}
                                    width="105" height="130"
                                />
                            )}
                        </div>
                        <div className="document-item__info-container">
                            <div className="document-item__info-title">
                                <SpriteIcon
                                    className="document-item__type-icon"
                                    iconId={`file-${iconType}-inner`}
                                />
                                <span className="document-item__info-title-text" title={title}>
                                     {title}
                                </span>
                            </div>
                            <div className="document-item__info-footer-wrap">
                                <div className="document-item__info-footer">
                                    <div className="document-item__info-project">
                                        {this.renderProjectLink()}
                                    </div>
                                    <div className="document-item__info-bottom">
                                        <div className="document-item__info-sub-title">
                                            {subTitle.split('·')[0]}{this.renderfileSize()}
                                        </div>
                                        <div className="document-item__info-pages">
                                            {pages > 0 && `${pages} pages`}
                                        </div>
                                    </div>
                                </div>
                                {!isImpersonateMode && (
                                    <div className="document-item__btn-wrap">
                                        {this.renderBtns(this.btnsOnClickCallbacks.call(this))}
                                        <button
                                            className="document-item__btn"
                                            onClick={this.copyToClipboard.bind(this)}
                                        >
                                            <div title='create an easy link to this document you can share with others'>
                                            <span className="document-item__btn-icon">
                                                <SpriteIcon iconId='share_link' className="btn-icon-svg"/>
                                            </span>
                                                <p className="document-item__btn-text">
                                                    COPY
                                                </p>
                                            </div>
                                        </button>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                )}
            </div>
        );
    }
}

DocumentItem.propTypes = {
    className: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    description: PropTypes.any,
    thumnailUrl: PropTypes.string,
    showNotification: PropTypes.func,
    id: PropTypes.number,
    documentType: PropTypes.number,
    isSingle: PropTypes.bool,
    searchWord: PropTypes.string,
    shareLinkString: PropTypes.string,
    tableMode: PropTypes.bool,
    fileExtention: PropTypes.string,
    thumnailExist: PropTypes.bool,
    canEdit: PropTypes.bool,
    downloadLinks: PropTypes.array,
    docType: PropTypes.string,
    addFileToPackage: PropTypes.func,
    projectId: PropTypes.string,
    projectName: PropTypes.string,
    isShared: PropTypes.bool
};

const mapStateToProps = state => ({
    documentTypes: state.documentsLibraryPopupsData.types,
    isImpersonateMode: isImpersonateModeSelector(state),
});

const mapDispatchToProps = dispatch => ({
    showPopup: data => dispatch(setSharePopupState(data)),
    showNotification: text => dispatch(addNotification(text)),
    addFileToPackage: options => dispatch(addFileToPackage(options)),
    editDocument: data => dispatch(startEditing(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentItem);
