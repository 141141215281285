import { Component } from 'react';
import PropTypes from 'prop-types';
import { isPrimitive } from '../../../core/old_helpers';
import getThousand from '../../../core/getFormattedThousand';
import Icon from '../../SpriteIcon/SpriteIcon';
import Tooltip from '../../Tooltip/Tooltip';
import { Link } from 'react-router-dom';
import PackageAddWrapper from '../../PackageAddWrapper/PackageAddWrapper';
import SpriteIcon from '../../SpriteIcon/SpriteIcon';
import { routeNames } from "../../../core/routeNames";

class TableCell extends Component {
    shouldComponentUpdate(nextProps) {
        if (this.props.isRowExpandable) {
            const isRowExpanding = nextProps.isRowExpanded !== this.props.isRowExpanded;
            return isRowExpanding || this.props.isRowExpanded;
        }
        return true;
    }

    renderTableBodyCellObject() {
        const {
            type, url, value, icon, width, height, downloadLink, id, name, pathname, search, component, trimmedValue,
            data, disabled, size, path, text, showFooter,
        } = this.props.data;
        const { searchString } = this.props;

        switch (type) {
            case 'urlWithIcon': {
                return (
                    <a href={url} className="table__data-link">
                        <Icon
                            className="table__data-icon"
                            iconId={icon}
                            width={`${width}`}
                            height={`${height}`}
                        />
                        {value}
                    </a>
                );
            }
            case 'alt': {
                return (
                    <span title={value}>{name}</span>
                );
            }
            case 'removeMember': {
                return value;
            }
            case 'thousand': {
                return (
                    <span>{getThousand(value)}</span>
                );
            }
            case 'url': {
                return (
                    <a href={url} className="table__data-link">{value}</a>
                );
            }
            case 'navLinkPlaces': {
                return (
                    <Link to={{
                        pathname: routeNames.chainDetailsOld,
                        search: search || `?id=${id}`
                    }} className="table__data-link">{value}</Link>
                );
            }
            case 'plusMoreBtn': {
                return (
                    <button
                        onClick={this.props.expandRow.bind(null, {
                            chainId: data.isRowExpanded ? null : data.chainId
                        })}
                        className="table__data-btn"
                    >
                        {data.isRowExpanded ? 'show less' : text}
                    </button>
                );
            }
            case 'menuItemsList': {
                const mainMenuItem = data.menuItems[0];
                const collapsedMenuItems = data.menuItems.slice(1);

                return (
                    <div className="table__data-description">
                        <p
                            className="table__main-menu-item"
                            dangerouslySetInnerHTML={{
                                __html: `${mainMenuItem.title.toUpperCase()}${mainMenuItem.description ? ` - ${mainMenuItem.description}` : ''}`
                            }}
                        />
                        {data.isRowExpanded && collapsedMenuItems.length ? (
                            <div className="table__data-collapsed">
                                {collapsedMenuItems.map((menuItem, idx) => (
                                    <p key={idx} className="table__data-inner-row">
                                        <p dangerouslySetInnerHTML={{
                                            __html: `${menuItem.title.toUpperCase()}${menuItem.description ? ` - ${menuItem.description}` : ''}`
                                        }}/>
                                    </p>
                                ))}
                            </div>
                        ) : null}
                        {data.isRowExpanded && !collapsedMenuItems.length ? (
                            <div className="spinner-pow">
                                <SpriteIcon iconId="logo-dark" width="200" height="200"/>
                            </div>
                        ) : null}
                    </div>
                );
            }
            case 'link': {
                return (
                    <Link to={{ pathname, search }} className="table__data-link">{value}</Link>
                );
            }
            case 'menuGaps': {
                return (
                    <div
                        className="table__data-link"
                        onClick={this.props.getGapReport.bind(this, { id, reportType: 1 })}
                    >{value}</div>
                );
            }
            case 'date': {
                return <span>{name}</span>;
            }
            case 'size': {
                return <span>{name}</span>;
            }
            case 'icon': {
                return downloadLink.length ? (
                    <a href={`${process.env.REACT_APP_API}${downloadLink}`}>
                        <Icon
                            className="table__data-icon"
                            iconId={icon}
                            width={`${width}`}
                            height={`${height}`}
                        />
                        <span className="table__data-title">{value}</span>
                    </a>
                ) : (
                    <span>
                        <Icon
                            className="table__data-icon"
                            iconId={icon}
                            width={`${width}`}
                            height={`${height}`}
                        />
                        <span className="table__data-title">{value}</span>
                    </span>
                )

            }
            case 'html':
                return (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: { value }
                        }}
                    />
                );
            case 'trimmed_with_title':
                return <span title={value}>{trimmedValue}</span>;
            case 'overlay':
                return (
                    <div className="header-packages__package-controls">
                        <a href={downloadLink}
                            className="header-packages__button header-packages__button--download">
                            <Icon className="header-packages__button-icon  header-packages__button-icon--table"
                                width="25" height="19"
                                iconId="download"/>
                            <span className="header-packages__button-text">
                                Download
                            </span>
                        </a>
                        <button className="header-packages__button header-packages__button--download"
                            onClick={this.props.removePackageItem.bind(this, { id, name })}>
                            <Icon
                                className="header-packages__button-icon  header-packages__button-icon--table" width="25"
                                height="19"
                                iconId="packages-remove-icon"/>
                            <span className="header-packages__button-text">
                            Remove
                        </span>
                        </button>
                    </div>
                );
            case 'documents library project': {
                return (
                    <div className="table__cell-container">
                        {icon ? [
                            <Icon
                                key="1"
                                className="table__data-icon"
                                iconId={icon}
                                width={`${width}`}
                                height={`${height}`}
                            />,
                            <span key="2">
                                 <Link
                                     to={{
                                         pathname: path,
                                         search,
                                     }}
                                     className="table__data-title table__data-title--pr"
                                     data-testid="buttonProjectTableCellItemLink"
                                 >
                                    {value}
                                 </Link>
                                <span
                                    className="my-documents__table-sub-title"
                                    data-testid="textProjectTableCellItemFileSize"
                                >
                                    ({size})
                                </span>
                            </span>
                        ] : (
                            <span>
                                 <Link
                                     to={{
                                         pathname: path,
                                         search,
                                     }}
                                     className="table__data-title--pr"
                                    data-testid="buttonProjectTableCellItemLink"
                                 >
                                {value}
                            </Link>
                            <span
                                className="my-documents__table-sub-title"
                                data-testid="textProjectTableCellItemFileSize"
                            >
                                ({size})
                            </span>
                            </span>
                        )}
                        {showFooter && (
                            <div className="table__cell-footer">
                                {!(data.canOverride || data.canEdit) && data.name.toLowerCase() !== 'others' && <div/>}
                                <a className="my-documents-project-tile__action my-documents-project-tile__action--line font-text"
                                    title="Copy Link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const dummy = document.createElement('input');
                                        dummy.value = data.sharedLink;
                                        document.body.appendChild(dummy);
                                        dummy.select();
                                        document.execCommand('copy');
                                        document.body.removeChild(dummy);
                                        this.props.showNotification({
                                            text: 'Link copied to your clipboard. You can paste it into email or any else you need.',
                                            duration: 5000,
                                        });
                                    }}
                                   data-testid="buttonProjectTableCellItemCopyLink"
                                >
                                    <Icon
                                        iconId="share_link"
                                        className="my-documents-project-tile__icon"
                                        width={10}
                                        style={{ width: 15 }}
                                    />
                                </a>
                                <Link
                                    title="Open"
                                    to={{
                                        pathname: path,
                                        search,
                                    }}
                                    className="my-documents-project-tile__action my-documents-project-tile__action--highlighted my-documents-project-tile__action--line font-text"
                                    data-testid="buttonProjectTableCellItemOpen"
                                >
                                    <Icon
                                        className="my-documents-project-tile__icon"
                                        iconId="explore"
                                    />
                                </Link>
                                {!(data.canOverride || data.canEdit) && data.name.toLowerCase() !== 'others' && <div/>}
                                {(data.canOverride || data.canEdit) && data.name.toLowerCase() !== 'others' && (
                                    <div
                                        title="Edit"
                                        onClick={() => this.props.editProject(data.id, data.name, data.description)}
                                        className="my-documents-project-tile__action my-documents-project-tile__action--line font-text"
                                        data-testid="buttonProjectTableCellItemEdit"
                                    >
                                        <Icon
                                            className="my-documents-project-tile__icon"
                                            iconId="edit"
                                        />
                                    </div>
                                )}
                                {data.canEdit && data.name.toLowerCase() !== 'others' && (
                                    <div
                                        title="Delete"
                                        onClick={() => this.props.deleteProject({
                                            id: data.id,
                                            documentsCount: data.documentsCount
                                        })}
                                        className={`
                                            my-documents-project-tile__action
                                            my-documents-project-tile__action--line
                                            font-text
                                        `}
                                        data-testid="buttonProjectTableCellItemDelete"
                                    >
                                        <Icon
                                            className="my-documents-project-tile__icon"
                                            iconId="delete"
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                );
            }
            case 'documents library document': {
                return (
                    <div className="table__cell-container">
                        <Icon
                            className="table__data-icon"
                            iconId={icon}
                            width={`${width}`}
                            height={`${height}`}
                        />
                        {downloadLink && showFooter ? (
                            <span>
                                 <a
                                     href={downloadLink}
                                     className="table__data-title table__data-title--pr  table__data-title--no-decor"
                                     data-testid="buttonDocTableCellItemLink"
                                 >
                                     {value}
                                 </a>
                                <span
                                    className="my-documents__table-sub-title"
                                    data-testid="textDocTableCellItemFileSize"
                                >
                                    ({size})
                                </span>
                            </span>
                        ) : (
                            <span>
                                <span
                                    className="table__data-title table__data-title--pr"
                                    data-testid="textDocTableCellItemTitle"
                                >
                                    {value}
                                </span>
                                <span
                                    className="my-documents__table-sub-title"
                                    data-testid="textDocTableCellItemFileSize"
                                >
                                    ({size})
                                </span>
                            </span>
                        )}
                        {showFooter && (
                            <div className="table__cell-footer">
                                <a
                                    href={`${process.env.REACT_APP_API}${data.downloadLink}`}
                                    className={`
                                    my-documents-document-tile__action
                                    my-documents-document-tile__action--highlighted
                                    font-text
                                `}
                                    title="Download"
                                    data-testid="buttonDocTableCellItemDownload"
                                >
                                    <Icon
                                        height="16"
                                        width="24"
                                        className="my-documents-document-tile__icon"
                                        iconId="download"
                                    />
                                </a>
                                <div
                                    className="my-documents-document-tile__action font-text"
                                    onClick={() => this.props.addFileToPackage({
                                        itemId: data.id,
                                        itemDocumentType: 'document'
                                    })}
                                    title="Add to Package"
                                    data-testid="buttonDocTableCellItemAddToPackage"
                                >
                                    <PackageAddWrapper>
                                        <Icon
                                            className="my-documents-document-tile__icon"
                                            iconId="add-to-package"
                                        />
                                    </PackageAddWrapper>
                                </div>
                                <div
                                    className="my-documents-document-tile__action font-text"
                                    onClick={() => this.props.openSharePopup({
                                        id: data.id,
                                        documentType: 5,
                                        generateLink: null,
                                        shareLinkString: data.shareLinkString,
                                        title: data.title,
                                        popupType: 'myDocuments'
                                    })}
                                    title="Share"
                                    data-testid="buttonDocTableCellItemShare"
                                >
                                    <Icon
                                        className="my-documents-document-tile__icon"
                                        iconId="share"
                                    />
                                </div>
                                {(data.canOverride || data.canEdit) && (
                                    <div
                                        onClick={() => this.props.editDocument({
                                            id: data.id,
                                            typeId: data.documentType.docTypeId,
                                            typeName: data.documentType.name,
                                            shareWithIds: data.companyIds,
                                            name: data.title,
                                            initialName: data.title,
                                            description: data.description,
                                            size: data.fileSize,
                                            projectId: data.projectId,
                                            docTypeString: data.docTypeString,
                                            fileSize: data.fileSize,
                                            thumnailExist: data.thumnailExist,
                                            thumnailUrl: data.thumnailUrl,
                                        })}
                                        className="my-documents-document-tile__action font-text"
                                        title="Edit"
                                        data-testid="buttonDocTableCellItemEdit"
                                    >
                                        <Icon
                                            className="my-documents-document-tile__icon"
                                            iconId="edit"
                                        />
                                    </div>
                                )}
                                {data.canEdit && (
                                    <div
                                        className="my-documents-document-tile__action font-text"
                                        onClick={() => this.props.deleteDocument(data)}
                                        title="Delete"
                                        data-testid="buttonDocTableCellItemDelete"
                                    >
                                        <Icon
                                            className="my-documents-document-tile__icon"
                                            iconId="delete"
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                );
            }
            case 'tooltip':
                if (data) {
                    return (
                        <Tooltip tooltipData={data} className="tooltip--in-table">
                            {name}
                        </Tooltip>
                    );
                }
                return (
                    <span>
                        {name}
                    </span>
                );
            case 'text': {
                return name;
            }
            default:
                return '';
        }
    }

    render() {
        const { data, align = 'center' } = this.props;
        const classList = new Set(['table__data', `table__data--${align}`]);

        if (data && data.type === 'documents library project') {
            classList.add('table__data--tall');
        }

        return (
            <td
                className={Array.from(classList).join(' ')}
                data-testid={this.props.testId}
            >
                {isPrimitive(data) ? (data) : this.renderTableBodyCellObject()}
            </td>
        );
    }
}

TableCell.propTypes = {
    testId: PropTypes.string,
    align: PropTypes.string,
    data: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string
    ]),
    getGapReport: PropTypes.func,
    expandRow: PropTypes.func,
    searchString: PropTypes.string,
    isRowExpanded: PropTypes.bool,
    isRowExpandable: PropTypes.bool,
};

export default TableCell;
