import React, { ReactChild } from 'react';
import dayjs from 'dayjs';
import { iReportProPublication } from "@models/ReportProPublication";

interface iProps {
    data: iReportProPublication;
    uppercase?: boolean;
}

const TileSubTitle: React.FC<iProps> = (
    {
        data,
        uppercase,
    }
) => {
    const { createdDate, numberOfPages, minutesToRead } = data;
    const spans: ReactChild[] = [];

    if (createdDate) {
        spans.push((
            <>{dayjs(createdDate).format('MMMM YYYY')}</>
        ));
    }
    if (numberOfPages) {
        spans.push((
            <>{numberOfPages} pages</>
        ));
    }
    if (minutesToRead) {
        spans.push((
            <>{minutesToRead} min read</>
        ));
    }
    return (
        <div className="ReportProTile__sub-title">
            {spans.map((span, index) => (
                <div key={index} style={{ textTransform: uppercase ? 'uppercase' : 'none' }}>
                    {span}
                    {index < spans.length - 1 && <>&nbsp;·&nbsp;</>}
                </div>
            ))}
        </div>
    );
};

export default TileSubTitle;
