import { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import DropZone from 'react-dropzone';
import Icon from '../../components/SpriteIcon/SpriteIcon';
import Popup from '../../components/Popup/Popup';
import SelectShare from '../../components/SelectShare/SelectShare';
import SelectCompany from '../../components/SelectCompany/SelectCompany';
import SelectProject from '../../components/SelectProject/SelectProject';
import SelectType from '../../components/SelectType/SelectType';
import { makeEnding, cutText, } from '../../core/old_helpers';
import {
    typesSelector, projectsSelector, contactsSelector, companiesSelector, companyIdSelector,
} from '../DocumentsLibraryPopups/documentsLibraryPopups.selectors';
import {
    setView, open, close, save, showValidationErrors, hideValidationErrors, storeUploadingFiles, updateDocumentData,
    updateAllDocumentsData, cancelLastSetUpload, cancelFileUpload, setActiveProjectId, updateDocumentTypes,
    createNewProject, checkDocumentNameUniqueness, incCurrentDocumentIndex, setCurrentDocumentIndex,
} from './documentsAddPopup.actions';
import {
    documentsSelector,
    globalProjectSelector,
    isNamesUniqueOnClientSelector,
    isNamesUniqueOnServerSelector,
    isFilesSizeValidSelector,
    isFormValidSelector,
    selectedCompaniesProjectsListSelector,
    currentDocumentDataSelector,
    currentDocumentIndexSelector,
    isCurrentDocumentNameUniqueOnClientSelector,
    isCurrentDocumentValidSelector,
} from './documentsAddPopup.selectors';
import { getArcLength } from '../../core/circles';
import getFileTypeIcon from '../../core/getFileTypeIcon';
import DocumentUploadGuidelinesPopup from "@components/DocumentUploadGudelinesPopup/DocumentUploadGuidelinesPopup";

const ACTIVE_STATE_STYLES = {
    boxShadow: 'inset 0 0 5px 5px rgba(128, 195, 232, 1)',
    backgroundColor: 'rgba(128, 195, 232, 0.5)',
};

class DocumentsAddPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openedSelectType: '',
            dropZoneActive: false,
        };
        this.save = this.save.bind(this);
        this.openSelect = this.openSelect.bind(this);
        this.closeSelect = this.closeSelect.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.startUploading = this.startUploading.bind(this);
        this.setDocumentData = this.setDocumentData.bind(this);
        this.cancelFileUpload = this.cancelFileUpload.bind(this);
        this.setDocumentShareData = this.setDocumentShareData.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { documents, selectedCompaniesProjectsList, updateDocumentData } = this.props;
        const { documents: oldDocuments, activeProjectId } = prevProps;

        if (documents.length && oldDocuments.length) {
            const isSharedWIthChanged = documents[0].shareWith.join('') !== oldDocuments[0].shareWith.join('');
            if (isSharedWIthChanged) {
                documents.forEach((document) => {
                    const { projectId, id } = document;
                    const isProjectSharedToAll = selectedCompaniesProjectsList.find(({ value }) => value === projectId);
                    if (!isProjectSharedToAll && projectId !== null && projectId !== activeProjectId) {
                        updateDocumentData(id, { projectId: null });
                    }
                });
            }
        }

        if(prevProps.currentDocumentData && this.props.currentDocumentData && this.props.currentDocumentData.projectId !== prevProps.currentDocumentData.projectId) {
            setTimeout(() => {
                const projectInnerSpan = document.querySelector('.file-upload-credential__project-inner');
                const projectInnerSpanWidth = projectInnerSpan.getBoundingClientRect().width;
                const projectSelect = document.querySelector('.file-upload-credential__project');
                const projectSelectWidth = projectSelect.getBoundingClientRect().width - 50;

                if(projectInnerSpanWidth > projectSelectWidth) {
                    projectInnerSpan.innerHTML = cutText(projectInnerSpan.textContent, 15);
                }
            }, 0);
        }
    }

    openSelect(type) {
        this.setState({
            openedSelectType: type,
        });

        if (type === 'type') {
            this.props.updateDocumentTypes();
        }
    }

    closeSelect() {
        this.setState({
            openedSelectType: '',
        });
    }

    cancelFileUpload() {
        this.props.cancelFileUpload(this.props.currentDocumentData.id);
    }

    setDocumentShareData(data) {
        const { currentDocumentIndex, documents } = this.props;
        documents.slice(currentDocumentIndex).forEach(({ id }) => {
            this.props.updateDocumentData(id, data)
        });
        this.closeSelect();
    }

    setDocumentData(type, value) {
        const { currentDocumentIndex, documents } = this.props;
        documents.slice(currentDocumentIndex).forEach(({ id }) => {
            this.props.updateDocumentData(id, { [type]: value });
        });
        this.closeSelect();
    }

    startUploading(files) {
        const { setView, startUploadingFiles } = this.props;
        const filteredFiles = files
            .filter(({ size = 0 }) => size > 0)
            .sort(({ size: a }, { size: b }) => (a < b ? 1 : -1));
        if (filteredFiles.length) {
            startUploadingFiles(filteredFiles);
            setView('manage');
        }
    }

    handleOpen() {
        document.body.style.overflowY = 'hidden';
    }

    handleClose() {
        const { close, hideValidationErrors, cancelLastSetUpload, setView, resetCurrentDocumentIndex } = this.props;
        close();
        hideValidationErrors();
        cancelLastSetUpload();
        setView('upload');
        resetCurrentDocumentIndex();
        document.body.style.overflowY = 'initial';
        this.setState({
            dropZoneActive: false,
        });
    }

    onDragEnter() {
        this.setState({
            dropZoneActive: true,
        });
    }

    onDragLeave() {
        this.setState({
            dropZoneActive: false,
        });
    }

    renderUploadView() {
        const classList = new Set([
            "popup__inner",
            "popup__inner--small",
            this.state.dropZoneActive && "popup__dropzone-active"
        ]);

        return (
            <DropZone
                onDrop={this.startUploading}
                multiple={true}
                disableClick={true}
                onDragEnter={this.onDragEnter.bind(this)}
                onDragLeave={this.onDragLeave.bind(this)}
                activeStyle={ACTIVE_STATE_STYLES}
                className={Array.from(classList).join(" ")}
                onDropRejected={() => {
                    this.setState({ dropZoneActive: false })
                }}
                disablePreview={true}
            >
                <div className="popup__header">
                    <div
                        className="popup__header-title"
                        data-testid="textUploadFilePopupTitle"
                    >
                        Upload Files
                    </div>
                </div>
                <div className="popup__main popup__flex-container--aligned">
                    <div className="popup__left-side popup__left-side--column">
                        <div
                            className="popup__text popup__margin--big"
                            data-testid="textUploadFilePopupTextChooseFilesToUpload"
                        >
                            Choose files to upload to your Library. You can select more than one file at a time.
                        </div>
                        <div
                            className="popup__text popup__margin--big"
                            data-testid="textUploadFilePopupTextYouCanAlsoDragAndDropFiles"
                        >
                            You can also drag and drop files anywhere on this page to start uploading.
                        </div>
                        <DropZone
                            onDrop={this.startUploading}
                            multiple={true}
                            className="popup__btn"
                            data-testid="buttonUploadFilePopupSelectFiles"
                        >
                            Select files
                        </DropZone>
                        <button
                            onClick={this.props.close}
                            className="popup__link popup__link--indent-left"
                            data-testid="buttonUploadFilePopupNotNow"
                        >
                            not now
                        </button>
                    </div>
                    <div className="popup__right-side">
                        <Icon width="260" height="263" iconId="drag-n-drop" className="popup__bg-image"/>
                    </div>
                </div>
            </DropZone>

        );
    }

    save() {
        const { isFormValid } = this.props;

        if (isFormValid) {
            this.props.save();
            this.props.close();
        } else {
            this.props.showValidationErrors();
        }
    }

    getShareFieldText() {
        const {
            isCustomerViewMode,
            companiesList,
            customerViewCompanyId,
        } = this.props;
        const {
            shareType,
            shareContacts,
        } = this.props.currentDocumentData;

        const customerViewModeCompany = companiesList.find(company => company.value === customerViewCompanyId);
        const contactsText = `${shareContacts.size} ${makeEnding('person', shareContacts.size)}`;

        if (isCustomerViewMode) {
            switch (shareType) {
                case 0: {
                    return `everyone at ${customerViewModeCompany.title} can view and edit`;
                }
                case 1: {
                    if (shareContacts.size === 0) {
                        return `everyone at ${customerViewModeCompany.title} can view, no one can edit`;
                    }
                    return `everyone at ${customerViewModeCompany.title} can view, ${contactsText} can edit`;
                }
                case 2: {
                    if (shareContacts.size === 0) {
                        return 'no one can view or edit';
                    }
                    return `${contactsText} can view, no one can edit`;
                }
                default: {
                    return '';
                }
            }
        } else {
            switch (shareType) {
                case 0: {
                    return 'everyone at my company can view and edit';
                }
                case 1: {
                    if (shareContacts.size === 0) {
                        return 'everyone at my company can view, only me can edit';
                    }
                    return `everyone at my company can view, me and ${contactsText} can edit`;
                }
                case 2: {
                    if (shareContacts.size === 0 || (shareContacts.size === 1 && shareContacts.keys().next().value === window.pageData.userId)) {
                        return 'I can view and edit';
                    }
                    return `${contactsText} can view, only me can edit`;
                }
                default: {
                    return '';
                }
            }
        }

    }

    renderCompaniesField() {
        const {
            documents,
            customerViewCompanyId,
            companiesList,
            isCustomerViewMode,
            currentDocumentIndex,
        } = this.props;
        const selectedCompanies = companiesList
            .filter(company => documents[currentDocumentIndex].shareWith.includes(company.value))
            .sort((a, b) => (a.value < b.value ? -1 : 1));
        let companiesDropDownTitle = '';
        const companiesCount = selectedCompanies.length;

        if (companiesCount === 0) {
            companiesDropDownTitle = 'Datassential';
        } else if (companiesCount === 1) {
            companiesDropDownTitle = selectedCompanies[0].title;
        } else if (companiesCount === 2) {
            companiesDropDownTitle = `${selectedCompanies[1].title}`;
        } else {
            companiesDropDownTitle = `${selectedCompanies[1].title} and ${selectedCompanies.length - 2} more`;
        }
        const classList = new Set([
            'popup__header-company-name',
        ]);
        if (customerViewCompanyId) {
            classList.add('file-upload__drop-down--disabled');
        }
        return (
            <div>
                <div
                    className="popup__header-files"
                    data-testid="textContinueUploadFilePopupFilesCount"
                >
                    file #{currentDocumentIndex + 1} of {documents.length}
                </div>
                <div className="popup__header-company">
                    <span data-testid="textContinueUploadFilePopupSelectCompanyLabel">
                        company
                    </span>
                    <div
                        className={Array.from(classList).join(' ')}
                        onClick={() => {
                            if (!isCustomerViewMode) {
                                this.openSelect('company');
                            }
                        }}
                        data-testid="buttonContinueUploadFilePopupSelectCompany"
                    >
                        {companiesDropDownTitle}
                    </div>
                </div>

            </div>
        );
    }

    renderManageViewHeader() {
        const {
            companyId,
        } = this.props;

        return (
            <div ref="header" className="popup__header">
                <div
                    className="popup__header-title"
                    data-testid="textContinueUploadFilePopupTitle"
                >
                    Complete your upload
                </div>
                <div className="popup__header-right">
                    {companyId === 1 && this.renderCompaniesField()}
                </div>
            </div>
        );
    }

    renderManageViewFooter() {
        const {
            isFormValid,
            documents,
            currentDocumentIndex,
            isCurrentDocumentValid,
        } = this.props;

        const isLastDocument = currentDocumentIndex === documents.length - 1;

        return (
            <div ref="footer" className="popup__footer popup__footer--gray popup__footer--thin">
                <div className="popup__right-side popup__right-side--flex">
                    <button
                        onClick={this.handleClose}
                        className="popup__link popup__link--comfortaa popup__link--white"
                        data-testid="buttonContinueUploadFilePopupCancel"
                    >
                        cancel
                    </button>
                    <button
                        onClick={isLastDocument ? this.save : this.props.incCurrentDocumentIndex}
                        className="popup__btn popup__btn--xmd"
                        disabled={!isCurrentDocumentValid}
                        data-testid={`buttonContinueUploadFilePopup${isLastDocument ? 'Finish' : 'Next'}`}
                    >
                        {isLastDocument ? 'FINISH' : 'NEXT'}
                    </button>
                </div>
            </div>
        );
    }

    renderManageViewTips() {
        return (
            <DocumentUploadGuidelinesPopup/>
        );
    }

    renderManageViewProgress() {
        const {
            documents,
            // isCurrentDocumentNameUniqueOnClient,
        } = this.props;
        const {
            progress = 0,
            subTitle,
            isSizeValid,
            file,
            backEndData,
        } = this.props.currentDocumentData;
        const circleStyle = {
            stroke: '#bfbfbf',
        };
        let errorSizeMessage = '';

        if (!isSizeValid) {
            errorSizeMessage = 'You can upload file less than 1GB';
        }

        const progressArc = getArcLength(progress, 30);
        const restProgressArc = getArcLength(100, 30);

        let showProgress = false;
        let extension;
        let fileIcon;
        let isThumbnailExist;
        let thumbnail;
        extension = file.name.substr(file.name.lastIndexOf('.') + 1);
        fileIcon = `${getFileTypeIcon(extension)}-bw`;
        if (progress < 100 || !backEndData.size) {
            showProgress = isSizeValid;
        }
        if (backEndData.thumnailExist) {
            isThumbnailExist = backEndData.thumnailExist;
            if (isThumbnailExist) {
                thumbnail = backEndData.thumnailUrl.replace('182', '160');
            }
        }

        if (!isSizeValid) {
            errorSizeMessage = 'You can upload file less than 1GB';
        }

        return (
            <div className="file-upload__progress file-upload-progress">
                {!!errorSizeMessage && (
                    <div
                        className="file-upload__error-msg file-upload__error-msg--size"
                        data-testid="textContinueUploadFilePopupFileSizeError"
                    >
                        {errorSizeMessage}
                    </div>
                )}
                {documents.length > 1 ? (
                    <div
                        className="file-upload__cross-icon"
                        onClick={this.cancelFileUpload}
                        data-testid="buttonContinueUploadFilePopupCancelUpload"
                    />
                ) : null}
                <div className="file-upload-progress__img-wrap">
                    {showProgress ? (
                        <div className="file-upload-progress__wrap">
                            <svg
                                width="100"
                                height="100"
                                className="file-upload-progress__icon"
                            >
                                <circle
                                    className="file-upload-progress__circle"
                                    cx="50"
                                    cy="50"
                                    r="30"
                                    strokeDasharray={`${progressArc}, ${restProgressArc}`}
                                    style={circleStyle}
                                />
                                <circle
                                    className="file-upload-progress__circle"
                                    cx="50"
                                    cy="50"
                                    r="30"
                                    stroke="rgba(200, 200, 200, 0.3)"
                                />
                            </svg>
                            <div className="file-upload-progress__text">
                                {`${parseInt(progress, 10)}%`}
                            </div>
                        </div>
                    ) : (
                        <div>
                            {isThumbnailExist ? (
                                <img
                                    className="file-upload-progress__img"
                                    src={thumbnail}
                                />
                            ) : (
                                <Icon
                                    className="file-upload-progress__file-icon"
                                    iconId={fileIcon}
                                />
                            )}
                        </div>
                    )}
                </div>
                <div
                    className="file-upload__file-info"
                    dangerouslySetInnerHTML={{
                        __html: subTitle,
                    }}
                />
            </div>
        );
    }

    renderManageViewText() {
        const {
            updateDocumentData,
            checkNameUniqueness,
            isCurrentDocumentNameUniqueOnClient,
        } = this.props;
        const {
            name,
            id,
            isNameUniqueOnServer,
            isNameUniquenessChecking,
        } = this.props.currentDocumentData;

        const shareText = this.getShareFieldText();
        let errorNameMessage = '';

        if (name === '' || isNameUniquenessChecking) {
            errorNameMessage = '';
        } else if (!isCurrentDocumentNameUniqueOnClient) {
            errorNameMessage = 'You are already using this name';
        } else if (!isNameUniqueOnServer) {
            errorNameMessage = 'This document name already exists';
        }

        return (
            <div className="file-upload__text">
                <label
                    htmlFor="doc_name"
                    className="popup__label popup__label--uppercase popup__label--transp"
                    data-testid="textContinueUploadFilePopupNameLabel"
                >
                    Give this document a name
                </label>
                <input
                    id="doc_name"
                    value={name}
                    autoFocus="autofocus"
                    onChange={(event) => {
                        checkNameUniqueness(id, event.target.value);
                        updateDocumentData(id, { name: event.target.value });
                    }}
                    className="file-upload__input"
                    placeholder="Document name"
                    data-testid="inputContinueUploadFilePopupName"
                />
                <div
                    className="file-upload__error-msg file-upload__error-msg--name"
                    data-testid="textContinueUploadFilePopupFileTitleError"
                >
                    {errorNameMessage}
                </div>
                <div className="file-upload-credential file-upload-credential--small file-upload-credential__share">
                    <span
                        dangerouslySetInnerHTML={{ __html: shareText }}
                        onClick={this.openSelect.bind(this, 'share')}
                        className="popup__content-link--share"
                        data-testid="buttonContinueUploadFilePopupSelectPermissions"
                    />
                </div>
            </div>
        );
    }

    renderManageViewCredentials() {
        const {
            typesList,
            projectsList,
            currentDocumentData,
            showErrors,
        } = this.props;
        const {
            typeId,
            projectId,
        } = currentDocumentData;

        const projectClassList = new Set(['file-upload-credential__status']);
        const projectBtnClassList = new Set(['popup__select', 'popup__select--lg', 'file-upload-credential__project']);
        const typeClassList = new Set(['file-upload-credential__status']);
        const typeBtnClassList = new Set(['popup__select', 'popup__select--md']);

        if (projectId) {
            projectClassList.add('file-upload-credential__status--filled');
            projectBtnClassList.add('popup__select--fulfilled');
        } else if (showErrors) {
            projectClassList.add('file-upload-credential__status--invalid');
        } else {
            projectClassList.add('file-upload-credential__status--need-to-fill');
        }

        if (typeId) {
            typeClassList.add('file-upload-credential__status--filled');
            typeBtnClassList.add('popup__select--fulfilled');
        }

        let projectTitle;

        if (projectId && projectsList.find(project => project.value === projectId)) {
            projectTitle = projectsList.find(project => project.value === projectId).title.toLowerCase();


            setTimeout(() => {
                const projectInnerSpan = document.querySelector('.file-upload-credential__project-inner');
                const projectInnerSpanWidth = projectInnerSpan.getBoundingClientRect().width;
                const projectSelect = document.querySelector('.file-upload-credential__project');
                const projectSelectWidth = projectSelect.getBoundingClientRect().width - 50;

                if(projectInnerSpanWidth > projectSelectWidth) {
                    projectInnerSpan.innerHTML = cutText(projectInnerSpan.textContent, 15);
                }
            }, 0);
        }

        if (projectTitle && projectTitle.length > 20) {
            projectBtnClassList.add('popup__select--small-font');
        }

        projectTitle = projectTitle && projectTitle.length > 83 ? projectTitle.slice(0, 80) + '...' : projectTitle;

        return (
            <div className="file-upload__credentials">
                <div
                    className="file-upload-credential file-upload-credential__type"
                >
                    <span data-testid="textContinueUploadFilePopupSelectTypeLabel">this is a</span>
                    <div
                        className={Array.from(typeBtnClassList).join(' ')}
                        onClick={this.openSelect.bind(this, 'type')}
                        data-testid="buttonContinueUploadFilePopupSelectType"
                    >
                        <div className={Array.from(typeClassList).join(' ')}>✓</div>
                        <span data-testid="textContinueUploadFilePopupTypeName">
                            {typeId && typesList.find(type => type.value === typeId)
                                ? typesList.find(type => type.value === typeId).title.toLowerCase()
                                : 'select'
                            }
                        </span>
                    </div>
                </div>
                <div className="file-upload-credential file-upload-credential__project">
                    <span data-testid="textContinueUploadFilePopupSelectProjectLabel">
                        link to this project
                    </span>
                    <div
                        className={Array.from(projectBtnClassList).join(' ')}
                        onClick={this.openSelect.bind(this, 'project')}
                        data-testid="buttonContinueUploadFilePopupProjectSelect"
                    >
                        <div className={Array.from(projectClassList).join(' ')}>✓</div>
                        {projectTitle ? (
                            <span
                                className="file-upload-credential__project-inner"
                                data-testid="textContinueUploadFilePopupProjectName"
                            >
                                {projectTitle}
                            </span>
                        ) : (
                            <div
                                className='file-upload__msg'
                                data-testid="textContinueUploadFilePopupProjectName"
                            >
                                choose project
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    createNewProject(...args) {
        this.props.createNewProject(...args)
            .then((data) => {
                this.setDocumentData('projectId', data.id);
            });
    }

    renderManageView() {
        const {
            documents,
            companyId,
            projectsList,
            typesList,
            companiesList,
            contactsList,
            isCustomerViewMode,
            selectedCompaniesProjectsList,
            currentDocumentData,
        } = this.props;
        const selectedCompanies = companiesList
            .filter(company => currentDocumentData.shareWith.includes(company.value))
            .sort((a, b) => (a.value < b.value ? -1 : 1));

        let multipleCompaniesModeTitle = '';
        const companiesCount = selectedCompanies.length;

        switch (companiesCount) {
            case 0: {
                multipleCompaniesModeTitle = 'Datassential projects only';
                break;
            }
            case 1: {
                multipleCompaniesModeTitle = `${selectedCompanies[0].title} projects only`;
                break;
            }
            case 2: {
                multipleCompaniesModeTitle = `${selectedCompanies[1].title} projects only`;
                break;
            }
            default: {
                multipleCompaniesModeTitle = `${selectedCompanies.length - 1} companies projects only`;
                break;
            }
        }

        const {
            openedSelectType,
        } = this.state;
        return (
            <div className="popup__inner popup__inner--small">
                {this.renderManageViewHeader()}
                <div className="file-upload">
                    <div className="popup__upload-left">
                        {this.renderManageViewText()}
                        {this.renderManageViewCredentials()}
                    </div>
                    <div className="popup__upload-right">
                        {this.renderManageViewProgress()}
                    </div>
                </div>
                {this.renderManageViewFooter()}
                {openedSelectType === 'project' && (
                    <SelectProject
                        saveNew={this.createNewProject.bind(this)}
                        close={this.closeSelect}
                        options={projectsList}
                        alternativeOptions={selectedCompaniesProjectsList}
                        isMultipleCompaniesMode={!isCustomerViewMode && companyId === 1 && companiesCount > 1}
                        multipleCompaniesModeTitle={multipleCompaniesModeTitle}
                        value={currentDocumentData.projectId}
                        setValue={this.setDocumentData.bind(this, 'projectId')}
                    />
                )}
                {openedSelectType === 'type' && (
                    <SelectType
                        close={this.closeSelect}
                        options={typesList}
                        value={currentDocumentData.typeId}
                        setValue={this.setDocumentData.bind(this, 'typeId')}
                    />
                )}
                {openedSelectType === 'company' && (
                    <SelectCompany
                        close={this.closeSelect}
                        options={companiesList.filter(company => company.value !== companyId)}
                        value={currentDocumentData.shareWith}
                        currentCompanyId={companyId}
                        setValue={this.setDocumentData.bind(this, 'shareWith')}
                    />
                )}
                {openedSelectType === 'share' && (
                    <SelectShare
                        close={this.closeSelect}
                        options={[
                            { value: 0, title: 'anyone can view or edit' },
                            { value: 1, title: 'anyone can view, only certain people can edit' },
                            { value: 2, title: 'only certain people can view' },
                        ]}
                        contacts={[
                            ...contactsList.filter(contacts => currentDocumentData.shareContacts.has(contacts.value)),
                            ...contactsList.filter(contacts => !currentDocumentData.shareContacts.has(contacts.value)),
                        ]}
                        value={{
                            shareType: currentDocumentData.shareType,
                            shareContacts: currentDocumentData.shareContacts,
                        }}
                        setValue={this.setDocumentShareData}
                        isViewFieldRequired={isCustomerViewMode}
                    />
                )}
                {companyId === 1 && this.renderManageViewTips()}
            </div>
        );
    }

    renderContent() {
        switch (this.props.view) {
            case 'manage':
                return this.renderManageView();
            default:
                return this.renderUploadView();
        }
    }

    render() {
        return this.props.isOpened && (
            <Popup
                isOpened={this.props.isOpened}
                onOpen={this.handleOpen}
                onClose={this.handleClose}
                className="popup-upload-files popup--fade-in"
            >
                {this.renderContent()}
            </Popup>
        );
    }
}

DocumentsAddPopup.propTypes = {
    view: PT.string,
    isOpened: PT.bool,
    showErrors: PT.bool,
    files: PT.array,
    projects: PT.array,
    types: PT.array,
    companies: PT.array,
    contacts: PT.array,
    setView: PT.func,
    open: PT.func,
    close: PT.func,
    showValidationErrors: PT.func,
    hideValidationErrors: PT.func,
    startUploadingFiles: PT.func,
    globalProject: PT.object,
    companyId: PT.number,
    updateDocumentData: PT.func,
    projectsList: PT.array,
    typesList: PT.array,
    companiesList: PT.array,
    contactsList: PT.array,
    activeProjectId: PT.string,
    updateAllDocumentsData: PT.func,
    documents: PT.array,
    cancelLastSetUpload: PT.func,
    cancelFileUpload: PT.func,
    save: PT.func,
    incCurrentDocumentIndex: PT.func,
    createNewProject: PT.func,
    setActiveProjectId: PT.func,
    updateDocumentTypes: PT.func,
    isNamesUniqueOnClient: PT.bool,
    isNamesUniqueOnServer: PT.bool,
    newProjectAdded: PT.bool,
    customerViewCompanyId: PT.number,
    isFilesSizeValid: PT.bool,
    isFormValid: PT.bool,
    isCustomerViewMode: PT.bool,
    selectedCompaniesProjectsList: PT.array,
    getSelectedCompaniesProjectsList: PT.func,
    resetCurrentDocumentIndex: PT.func,
    currentDocumentData: PT.object,
    currentDocumentIndex: PT.number,
    isCurrentDocumentValid: PT.bool,
    isCurrentDocumentNameUniqueOnClient: PT.bool,
};

const mapStateToProps = (state) => ({
    isOpened: state.documentsAddPopupData.isOpened,
    backEndData: state.documentsAddPopupData.backEndData,
    isCustomerViewMode: state.documentsAddPopupData.isCustomerViewMode,
    customerViewCompanyId: state.documentsAddPopupData.customerViewCompanyId,
    showErrors: state.documentsAddPopupData.showErrors,
    view: state.documentsAddPopupData.view,
    newProjectAdded: state.documentsAddPopupData.newProjectAdded,
    selectedCompaniesProjectsList: selectedCompaniesProjectsListSelector(state),
    globalProject: globalProjectSelector(state),
    typesList: typesSelector(state),
    projectsList: projectsSelector(state),
    companiesList: companiesSelector(state),
    contactsList: contactsSelector(state),
    companyId: companyIdSelector(state),
    documents: documentsSelector(state),
    isCurrentDocumentNameUniqueOnClient: isCurrentDocumentNameUniqueOnClientSelector(state),
    isCurrentDocumentValid: isCurrentDocumentValidSelector(state),
    isNamesUniqueOnClient: isNamesUniqueOnClientSelector(state),
    isNamesUniqueOnServer: isNamesUniqueOnServerSelector(state),
    isFilesSizeValid: isFilesSizeValidSelector(state),
    isFormValid: isFormValidSelector(state),
    activeProjectId: state.documentsAddPopupData.activeProjectId,
    currentDocumentData: currentDocumentDataSelector(state),
    currentDocumentIndex: currentDocumentIndexSelector(state),
});

const mapDispatchToProps = dispatch => ({
    setView: viewName => dispatch(setView(viewName)),
    open: () => dispatch(open()),
    close: () => dispatch(close()),
    save: () => dispatch(save()),
    showValidationErrors: () => dispatch(showValidationErrors()),
    hideValidationErrors: () => dispatch(hideValidationErrors()),
    startUploadingFiles: (files, isFromLastSet) => dispatch(storeUploadingFiles(files, isFromLastSet)),
    updateDocumentData: (id, data) => dispatch(updateDocumentData(id, data)),
    updateAllDocumentsData: data => dispatch(updateAllDocumentsData(data)),
    cancelLastSetUpload: () => dispatch(cancelLastSetUpload()),
    cancelFileUpload: id => dispatch(cancelFileUpload(id)),
    createNewProject: (documentId, name, description) => dispatch(createNewProject(documentId, name, description)),
    setActiveProjectId: id => dispatch(setActiveProjectId(id)),
    updateDocumentTypes: () => dispatch(updateDocumentTypes()),
    incCurrentDocumentIndex: () => dispatch(incCurrentDocumentIndex()),
    checkNameUniqueness: (id, name) => dispatch(checkDocumentNameUniqueness(id, name)),
    resetCurrentDocumentIndex: () => dispatch(setCurrentDocumentIndex(0))
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsAddPopup);
