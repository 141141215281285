import { Link } from "react-router-dom";
import { iChainBase } from "@models/ChainBase";
import { ColumnIconType } from "@components/new/Table/useTableSort";
import { iTableConfigItem } from "@components/new/Table/Table.helpers";
import { useCallback, useMemo } from "react";
import { routeNames } from "@core/routeNames";
import { getFormattedThousand } from "@helpers";
import { iSummaryItem } from "@models/FoodLtoSection";
import { AnalyticsAttribute, getAnalyticsComputedData } from "@models/AnalyticsAttributes";

export function useTableConfig(
    data: iSummaryItem[],
    sortingField: keyof iSummaryItem | null,
    sortingDirection: 'asc' | 'desc',
    handleSort: (fieldName: keyof iSummaryItem) => () => void,
    getColumnIcon: ColumnIconType
): iTableConfigItem[] {
    const columnIcon = useCallback(
        (fieldName: keyof iSummaryItem) => getColumnIcon(fieldName, sortingField, sortingDirection),
        [getColumnIcon, sortingDirection, sortingField]
    );

    return useMemo((): iTableConfigItem[] => [
        {
            title: 'Chain',
            styles: {
                cell: {
                    paddingLeft: 0,
                }
            },
            icon: columnIcon('chainName'),
            isActive: sortingField === 'chainName',
            Value: ({ index }) => (
                <Link
                    className="NewTopChains__link"
                    to={{
                        pathname: routeNames.chainDetails,
                        search: `id=${data[index].chainId}`,
                    }}
                    {...getAnalyticsComputedData({
                        [AnalyticsAttribute.ClickTitle]: 'FF Chains Redirect',
                    })}
                >
                    {data[index].chainName}
                </Link>
            ),
            handleHeaderClick: handleSort("chainName"),
        },
        {
            title: 'past 3 month',
            icon: columnIcon('pastThreeMonth'),
            isActive: sortingField === 'pastThreeMonth',
            Value: ({ index }) => <>{data[index].pastThreeMonth}</>,
            handleHeaderClick: handleSort("pastThreeMonth"),
            textCentered: true,
        },
        {
            title: 'past 6 month',
            icon: columnIcon('pastSixMonth'),
            isActive: sortingField === 'pastSixMonth',
            Value: ({ index }) => <>{data[index].pastSixMonth}</>,
            handleHeaderClick: handleSort("pastSixMonth"),
            textCentered: true,
        },
        {
            title: 'past 12 month',
            icon: columnIcon('pastOneYear'),
            isActive: sortingField === 'pastOneYear',
            Value: ({ index }) => <>{data[index].pastOneYear}</>,
            handleHeaderClick: handleSort("pastOneYear"),
            textCentered: true,
        },
        {
            title: 'past 2 years',
            icon: columnIcon('pastTwoYears'),
            isActive: sortingField === 'pastTwoYears',
            Value: ({ index }) => <>{data[index].pastTwoYears}</>,
            handleHeaderClick: handleSort("pastTwoYears"),
            textCentered: true,
        },
    ], [columnIcon, handleSort, data, sortingField])
}