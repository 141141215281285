import React, {FC} from "react";
import './ContentWidget.styles.scss';
import cn from "classnames";

export type FoodProfileWidgetModifier =
    'no-hover'
    | 'no-margin'
    | 'col-5'
    | 'versatility'
    | 'paired-items'
    | 'hover-data'
    | 'local-indices'
    | 'transparent'
    | 'font-text'
    ;

const ContentWidget: FC<{
    title?: string;
    className?: string;
    modifiers?: FoodProfileWidgetModifier[];
    testId?: string;
    titleTestId?: string;
}> = (
    {
        title = '',
        className,
        modifiers = [],
        children,
        testId,
        titleTestId
    }
) => {
    const classNameConcat = cn(
        'ContentWidget',
        modifiers.map(c => `ContentWidget--${c}`),
        className
    );
    return (
        <div
            className={classNameConcat}
            data-test={testId}
        >
            {title !== '' && <header className="ContentWidget__header">
                <h3
                    className="ContentWidget__title"
                    data-testid={titleTestId}
                >
                    {title}
                </h3>
            </header>}
            <section>
                {children}
            </section>
        </div>
    );
};
export default ContentWidget;