import { FC, useCallback, useEffect, useMemo, useState } from "react";
import DescriptionList from "../../../../../components/new/DescriptionList/DescriptionList";
import InfoBlock from "../../../../../components/new/InfoBlock/InfoBlock";
import { SortDirectionEnum } from "../../../../../core/enums";
import { sortByKey } from "../../../../../core/helpers";
import { IconOrder, IconOrderReverse } from "../../../../../icons";
import BrandsHeader from "../BrandsHeader/BrandsHeader";
import "./BrandsDetailedAttributes.styles.scss";
import { API__FPChainData } from "../../../../../core/apiSchema";
import { iItem as iDescriptionListItem } from '../../../../../components/new/DescriptionList/DescriptionList';
import CopyNotification from "../../../../../components/new/Table/CopyNotification";
import useNotification from "../../../../../core/hooks/useNotification";

interface iProps {
    data: API__FPChainData;
}

interface iItem {
    name: string;
    value: number;
}

interface iColumnProps {
    title: string;
    data: iItem[];
}

const BrandsDetailedAttributesColumn: FC<iColumnProps> = ({
    title,
    data,
}) => {
    const [sortingDirection, setSortingDirection] = useState<SortDirectionEnum>(SortDirectionEnum.DESC);
    const [attributes, setAttributes] = useState<iItem[]>([]);

    const getSortIcon = useCallback((direction: SortDirectionEnum) => {
        return direction === SortDirectionEnum.ASC ? <IconOrderReverse /> : <IconOrder />
    }, []);

    const toggleSortDirection = () => {
        const direction = sortingDirection === SortDirectionEnum.DESC ? SortDirectionEnum.ASC : SortDirectionEnum.DESC;
        setSortingDirection(direction);
        setAttributes(data.sort(sortByKey("value", direction)))
    }

    useEffect(() => {
        setAttributes(data.sort(sortByKey("value", SortDirectionEnum.DESC)))
    }, [data]);

    const finalAttributes: iDescriptionListItem[] = attributes.map(i => ({
        label: i.name,
        value: i.value,
    }));


    return (
        <div className="BrandsDetailedAttributes__column">
            <div className="BrandsDetailedAttributes__title">
                {title}

                {data.length > 0 && (
                    <div
                        className="BrandsDetailedAttributes__sort-icon"
                        onClick={toggleSortDirection}
                    >
                        {getSortIcon(sortingDirection)}
                    </div>
                )}
            </div>
            <div className="BrandsDetailedAttributes__list">
                {data.length > 0 && (
                    <DescriptionList
                        data={finalAttributes.map(i => ({
                            ...i,
                            value: i.value === null ? '-' : `${i.value}%`,
                        }))}
                        columns={1}
                    />
                )}

                {data.length === 0 && <div className="BrandsDetailedAttributes__no-data">N/A</div>}
            </div>
        </div>
    );
};

interface iBrandCategory {
    name: string;
    values: iItem[];
}

const BrandsDetailedAttributes: FC<iProps> = (
    {
        data: {
            foodAndBeverageQuality,
            valueProposition,
            customerExperience,
        }
    }
) => {
    const notification = useNotification();
    const categories: iBrandCategory[] = [
        {
            name: 'CUSTOMER EXPERIENCE',
            values: customerExperience,
        },
        {
            name: 'VALUE',
            values: valueProposition,
        },
        {
            name: 'FOOD & BEV QUALITY',
            values: foodAndBeverageQuality,
        },
    ];

    const getClipboardData = useMemo(() => (data: iBrandCategory[]) => (
        data.map(column => [
            [column.name],
            ...[...column.values.map(item => [
                item.name,
                item.value === null ? '-' : `${item.value}%`,
            ])],
            [" "]
        ]).flat()
    ), [])

    return (
        <div className="BrandsDetailedAttributes">
            <BrandsHeader
                title="Detailed Attribute Ratings"
                subTitle="(% rating as “excellent” or “very good”)"
                classNames="is-lead"
                clipboardData={getClipboardData(categories)}
                onCopy={notification.handleShowNotification}
            />

            <InfoBlock>
                <div className="BrandsDetailedAttributes__content">
                    <div className="BrandsDetailedAttributes__columns">
                        {categories.map(({ name, values }) => {
                            return (
                                <BrandsDetailedAttributesColumn
                                    key={name}
                                    data={values}
                                    title={name}
                                />
                            )
                        })}
                    </div>
                </div>

                <CopyNotification isOpen={notification.isOpen} />
            </InfoBlock>
        </div>
    );
};

export default BrandsDetailedAttributes;
