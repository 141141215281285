import { RECONFIRMATION_POPUP } from '../../actions/actionsNamesList';
import http from '../..//core/fetch';
import { getMsgNumber, getExpireTimeStamp } from './reconfirmationPopupHelpers';

const { SET_DATA } = RECONFIRMATION_POPUP;

export function close() {
    return (dispatch, getState) => {
        const { currentNotificationNumber } = getState().reconfirmationPopupData;
        window.localStorage.setItem(`msg${currentNotificationNumber}`, 'hide');
    };
}

export function setData(data) {
    return {
        type: SET_DATA,
        data,
    };
}

export function getData() {
    return (dispatch) => {
        return http.get('get reconfirmation data')
            .then((response) => {
                const { confirmationPeriod, daysForExpiring: daysLeft } = response;
                const currentNotificationNumber = getMsgNumber(daysLeft);
                const expireTimeStamp = getExpireTimeStamp(daysLeft);
                const storedExpireTimeStamp = window.localStorage.getItem('expireTimeStamp');

                if (+storedExpireTimeStamp !== +expireTimeStamp) {
                    window.localStorage.setItem('expireTimeStamp', `${expireTimeStamp}`);
                    window.localStorage.setItem('msg1', 'show');
                    window.localStorage.setItem('msg2', 'show');
                    window.localStorage.setItem('msg3', 'show');
                }

                dispatch(setData({
                    period: confirmationPeriod,
                    daysLeft,
                    currentNotificationNumber,
                }));
            });
    };
}

export function sendReconfirmationEmail() {
    return () => {
        return http.get('send email reconfirmation');
    };
}
