import React, { useCallback, useMemo, useRef, useState } from "react";
import { MAP_DEFAULT_ZOOM } from "../ProfileCarousel/ChainMap.helpers";
import IMG_THUMB_MAP from "../../../../../img/thumb-map.png";
import PopupCarousel from "../../../../../components/PopupCarousel/PopupCarousel";
import "./ImagesGallery.styles.scss";
import Slider from "../../../../../components/Slider/Slider";

interface iProps {
    data: string[];
}


const ImagesGallery: React.FC<iProps> = ({ data, children }) => {
    const [isDraggable, setIsDraggable] = useState(false);
    const [isShownModal, setIsShowModal] = useState(false);
    const isDraggingRef = useRef(false);
    const currentSlideRef = useRef(0);
    const additionalSlidesCount = useMemo(() => React.Children.count(children), [children]);

    const handleChangeSlide = useCallback(
        (currentSlideIndex: number) => {
            currentSlideRef.current = currentSlideIndex;
            if (!additionalSlidesCount) return;

            if (currentSlideIndex > additionalSlidesCount - 1 && !isDraggable) {
                setIsDraggable(true);
            }

            if (currentSlideIndex === additionalSlidesCount - 1) {
                setIsDraggable(false);
            }
        },
        [isDraggable, additionalSlidesCount],
    );

    const handleBeforeChange = useCallback(() => {
        isDraggingRef.current = true;
    }, []);

    const handleAfterChange = useCallback(
        (currentSlideIndex: number) => {
            isDraggingRef.current = false;
            handleChangeSlide(currentSlideIndex);
        },
        [handleChangeSlide],
    );

    const handleOnItemClick = useCallback(() => {
        if (!isDraggingRef.current) {
            setIsShowModal(true);
        }
    }, []);

    const additionalSlides = useMemo(
        () =>
            React.Children.map(children, child => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, {
                        onMapClick: handleOnItemClick,
                        isPreventSlideClick: true,
                        styles: profileMapStyles,
                    });
                }
            }),
        [children, handleOnItemClick],
    );

    const additionalPopupSlides = useMemo(
        () => (zoom?: number ) =>
        React.Children.map(children, child => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                    styles: profileMapStyles,
                    zoom: zoom || MAP_DEFAULT_ZOOM
                });
            }
        }),
        [children],
    );

    const totalCounter = useMemo(() => {
        return (
            <div className="ImagesGallery__counter" >
                +{data.length + additionalSlidesCount - 1} photos
            </div>
        )
    }, [additionalSlidesCount, data.length])

    return (
        <div className="ImagesGallery">
            <Slider
                isShownThumbnails
                sliderConfig={{
                    touchMove: isDraggable,
                    draggable: isDraggable,
                    beforeChange: handleBeforeChange,
                    afterChange: handleAfterChange,
                    touchThreshold: 40,
                }}
                customThumbnails={[
                    {
                        slideIndex: 0,
                        data: <img src={IMG_THUMB_MAP} alt="" />,
                    },
                ]}
                onClick={handleOnItemClick}
                totalCounter={data.length > 1 && currentSlideRef.current === 0 ? totalCounter : null}
            >
                {additionalSlides}

                {data.map((item, idx) => (
                    <img key={`ImagesGallery${idx}`} src={item} alt="" draggable={false} />
                ))}
            </Slider>

            <PopupCarousel
                isShown={isShownModal}
                onClose={() => setIsShowModal(false)}
                initialSlideIndex={currentSlideRef.current}
                additionalSlidesCount={additionalSlidesCount}
            >
                {additionalPopupSlides(5)}

                {data.map((item, idx) => (
                    <img key={`PopupCarousel${idx}`} src={item} alt="" draggable={false} />
                ))}
            </PopupCarousel>
        </div>
    );
};

const profileMapStyles = { backgroundColor: "#333" };
export default ImagesGallery;
