import { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import serializeParams from '../../core/serializeParams';

import Page from '../../containers/Page/Page';
import Table from '../../components/Table/Table';
import Icon from '../../components/SpriteIcon/SpriteIcon';
import PageContent from '../../containers/PageContent/PageContent';
import { contains } from '../../core/old_helpers';
import getFileTypeIcon from '../../core/getFileTypeIcon';
import PackageAddWrapper from '@components/PackageAddWrapper/PackageAddWrapper';
import { Scrollbars } from 'react-custom-scrollbars';
import { addFileToPackage } from '../../actions/packages.actions';
import SharePopup from '@pages/_shared/SharePopup/SharePopup';
import { setSharePopupState } from '../../actions/documents.actions';
import { shareDataSelector } from '../../selectors/documents.selectors';
import { addNotification } from '../../components/GlobalNotification/globalNotification.actions';
import { startEditing as openDocumentEditPopup } from '../../containers/DocumentEditPopup/documentEditPopup.actions';
import {
    open as openDocumentsAddPopup,
    setActiveProjectId
} from '../../containers/DocumentsAddPopup/documentsAddPopup.actions';

import { startDeleting as startDeletingDocument } from '../../containers/DocumentDeletePopup/documentDeletePopup.actions';
import { startDeleting } from '../../containers/ProjectDeletePopup/projectDeletePopup.actions';
import { setData, open as openProjectEditPopup } from '../../containers/ProjectEditPopup/projectEditPopup.actions';
import {
    setPageToReload,
    setCustomerViewCompanyId
} from '../../containers/DocumentsLibraryPopups/documentsLibraryPopups.actions';

import { documentsSelector, documentsTableSelector } from './project.selectors';
import { getData, clearData, storeData } from './project.actions';
import { isImpersonateModeSelector } from '../../selectors/page';

class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            sortBy: 'Name',
            customerViewCompanyId: 1
        };
        this.changeSearchValue = this.changeSearchValue.bind(this);
        this.setSorting = this.setSorting.bind(this);
        this.onInternalTabClick = this.onInternalTabClick.bind(this);
        this.onSharedTabClick = this.onSharedTabClick.bind(this);
        this.addFiles = this.addFiles.bind(this);
    }

    componentDidMount() {
        const { setCustomerViewCompanyId, getData, setActiveProjectId, setPageToReload, storeData } = this.props;
        const params = new window.URLSearchParams(this.props.location.search);
        const id = params.get('id');
        const customerViewCompanyId = +params.get('companyId');
        const isShareMode = params.get('isShareMode');
        this.setState({ customerViewCompanyId });
        if (customerViewCompanyId) {
            setCustomerViewCompanyId(customerViewCompanyId);
        }
        storeData({
            id,
            isShareMode: !!isShareMode,
            customerViewCompanyId
        });

        getData();
        setActiveProjectId(id);
        setPageToReload('project');

    }

    componentDidUpdate(prevProps) {
        const changeInternal = prevProps.data.isInternal !== this.props.data.isInternal;
        const changeShared = prevProps.data.isShared !== this.props.data.isShared;
        if (changeInternal || changeShared) {
            if (this.props.data.isInternal && !this.props.data.isShared) {
                this.onInternalTabClick();
            }
            if (!this.props.data.isInternal && this.props.data.isShared) {
                this.onSharedTabClick();
            }
        }
    }

    componentWillUnmount() {
        const { setActiveProjectId, clearData, setCustomerViewCompanyId, setPageToReload } = this.props;
        setCustomerViewCompanyId(null);
        setActiveProjectId(null);
        clearData();
        setPageToReload();
    }

    changeSearchValue(event) {
        this.setState({
            searchValue: event.target.value
        });
    }

    setSorting(type) {
        this.setState({
            sortBy: type
        });
    }

    renderDocumentsTable() {
        const { documentsTable } = this.props;
        const filteredDocuments = documentsTable.data
            .filter(document => contains(document[0].value, this.state.searchValue));
        return filteredDocuments.length ? (
            <Table
                useLazyLoad={true}
                className={'table-container--my-documents'}
                settings={documentsTable.settings}
                data={filteredDocuments}
                sortType="client"
                addFileToPackage={this.props.addFileToPackage}
                openSharePopup={this.props.openSharePopup}
                editDocument={this.props.editDocument}
                deleteDocument={this.props.deleteDocument}
                showCopyButton={false}
                sortingDirection="desc"
                sortingColumn={2}
                from="project"
                testId="tableProjectFiles"
            />
        ) : null;
    }

    addFiles() {
        this.props.openDocumentsAddPopup();
    }

    renderHeader() {
        const { data, edit, isImpersonateMode } = this.props;
        const { customerViewCompanyId } = this.state;
        const goBackLinkParams = {};
        if (customerViewCompanyId && customerViewCompanyId !== 1) {
            goBackLinkParams.companyId = customerViewCompanyId;
        }
        if (data.isShareMode) {
            goBackLinkParams.isShareMode = 'true';
        }
        const goBackLink = `/myDocuments?${serializeParams(goBackLinkParams)}`;
        return (
            <div className="my-documents__header my-documents__header--transparent">
                <div className="my-documents__wrapper my-documents__wrapper--flex">
                    <div className="my-documents__left-side my-documents__left-side--cutted">
                        <div className="my-documents-project-tile my-documents-project-tile--transparent">
                            <div
                                className="my-documents__title"
                                data-testid="textProjectTitle"
                            >
                                {data.name.toUpperCase()}
                            </div>
                            {data.description && data.description !== '' ? (
                                <div className="my-documents-project-tile__main">
                                    <div
                                        className="my-documents-project-tile__description"
                                        data-testid="textProjectDescription"
                                    >
                                        {data.description}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="my-documents__right-side my-documents__right-side--flex">
                        {!isImpersonateMode && (
                            <div className="my-documents-project-tile__action--inline">
                                <div className="my-documents-project-tile__actions-list">
                                    {(data.canOverride || data.canEdit) && data.name.toLowerCase() !== 'others' && (
                                        <div
                                            onClick={() => edit(data.id, data.name, data.description)}
                                            className={`
                                                my-documents-project-tile__action font-text
                                                my-documents-project-tile__action--in-one-line
                                            `}
                                            data-testid="buttonProjectEdit"
                                        >
                                            <Icon
                                                className="my-documents-project-tile__icon"
                                                iconId="edit"
                                            />
                                            EDIT
                                        </div>
                                    )}
                                    {data.canEdit && data.name.toLowerCase() !== 'others' && (
                                        <div
                                            onClick={() => this.props.deleteProject({
                                                id: data.id,
                                                documentsCount: data.documentsCount,
                                                redirect: true
                                            })}
                                            className={`
                                                my-documents-project-tile__action font-text
                                                my-documents-project-tile__action--in-one-line
                                            `}
                                            data-testid="buttonProjectDelete"
                                        >
                                            <Icon
                                                className="my-documents-project-tile__icon"
                                                iconId="delete"
                                            />
                                            DELETE
                                        </div>
                                    )}
                                </div>
                                <div
                                    onClick={(e) => {
                                        e.preventDefault();
                                        const dummy = document.createElement('input');
                                        dummy.value = data.sharedLink;
                                        document.body.appendChild(dummy);
                                        dummy.select();
                                        document.execCommand('copy');
                                        document.body.removeChild(dummy);
                                        this.props.showNotification('Link copied to your clipboard. You can paste it into email or any else you need.');
                                    }}
                                    data-testid="buttonProjectShare"
                                    className={`
                                            my-documents-project-tile__action
                                            my-documents-project-tile__action--in-one-line
                                        `}
                                    title="Create a link to these files that you can share with your colleagues"
                                >
                                    <Icon
                                        className="my-documents-project-tile__icon"
                                        iconId="share_link"
                                    />
                                    SHARE PROJECT
                                </div>
                            </div>
                        )}
                        <NavLink
                            to={goBackLink}
                            className="my-documents__link"
                            data-testid="buttonProjectBack"
                        >
                            « go back
                        </NavLink>
                        {!isImpersonateMode && (
                            <div
                                onClick={this.addFiles}
                                className="my-documents__btn"
                                data-testid="buttonProjectAddFiles"
                            >
                                Upload Files
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    onInternalTabClick() {
        const url = window.location.href
            .replace('&isShareMode=true', '')
            .replace('isShareMode=true&', '')
            .replace('?isShareMode=true', '');
        window.history.replaceState(null, '', url);
        this.props.storeData({ isShareMode: false });
    }

    onSharedTabClick() {
        let url = window.location.href
            .replace('&isShareMode=true', '')
            .replace('isShareMode=true&', '')
            .replace('?isShareMode=true', '');
        if (url.indexOf('?') > -1) {
            url = url.replace('?', '?isShareMode=true&');
        } else {
            url = url.replace('/myDocuments', '/myDocuments?isShareMode=true');
        }
        window.history.replaceState(null, '', url);
        this.props.storeData({ isShareMode: true });
    }

    renderProjectsViewModeTabs() {
        const { data } = this.props;
        const internalBtnClassList = new Set(['my-documents__tab font-search']);
        const sharedBtnClassList = new Set(['my-documents__tab font-search']);
        if (data.isShareMode) {
            sharedBtnClassList.add('my-documents__tab--active');
        } else {
            internalBtnClassList.add('my-documents__tab--active');
        }
        return (
            <div className="my-documents__tabs">
                <div
                    onClick={this.onInternalTabClick}
                    className={Array.from(internalBtnClassList).join(' ')}
                    data-testid="buttonProjectViewInternal"
                >
                    internal
                </div>
                <div
                    onClick={this.onSharedTabClick}
                    className={Array.from(sharedBtnClassList).join(' ')}
                    data-testid="buttonProjectViewShared"
                >
                    shared
                </div>
            </div>
        );
    }

    render() {
        const { dataLoaded, data, companyId, documents } = this.props;
        const showProjectsViewModeTabs = companyId === 1
            && data.customerViewCompanyId === 0
            && data.isInternal
            && data.isShared;
        return (
            <Page>
                <PageContent>
                    <Scrollbars>
                        {dataLoaded ? (
                            <div className="my-documents">
                                {this.renderHeader()}
                                <div className="my-documents__content">
                                    <div className="my-documents__wrapper">
                                        <div
                                            className="my-documents__sub-title"
                                            data-testid="textProjectContentTitle"
                                        >
                                            THERE ARE <b data-testid="textProjectFilesCount">{this.props.documents.length} FILES</b> ASSOCIATED
                                            WITH THIS PROJECT
                                        </div>
                                    </div>
                                    <div className="my-documents__right-side">
                                        {showProjectsViewModeTabs && this.renderProjectsViewModeTabs()}
                                    </div>
                                    {documents.length > 0 && (
                                        <div className="my-documents__wrapper">
                                            <div className="my-documents__input-container my-documents__left-side">
                                                <input
                                                    className="my-documents__search font-search"
                                                    type="search"
                                                    placeholder="Find"
                                                    onChange={this.changeSearchValue}
                                                    value={this.state.searchValue}
                                                    data-testid="inputProjectSearchFiles"
                                                />
                                                <Icon
                                                    width="22"
                                                    height="22"
                                                    iconId="search"
                                                    className="my-documents__search-icon"
                                                />
                                            </div>

                                        </div>
                                    )}
                                    <div className="wrapper">
                                        {this.renderDocumentsTable()}
                                    </div>

                                </div>
                            </div>
                        ) : (<div className="spinner spinner-blink">
                                <Icon iconId="logo-dark"/>
                            </div>
                        )}

                        {this.props.showSharePopup && <SharePopup/>}
                    </Scrollbars>
                </PageContent>
            </Page>
        );
    }
}

Project.propTypes = {
    data: PT.object,
    getData: PT.func,
    location: PT.object,
    setPageToReload: PT.func,
    openSharePopup: PT.func,
    clearData: PT.func,
    setActiveProjectId: PT.func,
    showSharePopup: PT.bool,
    dataLoaded: PT.bool,
    edit: PT.func,
    deleteProject: PT.func,
    openDocumentsAddPopup: PT.func,
    documents: PT.array,
    deleteDocument: PT.func,
    editDocument: PT.func,
    addFileToPackage: PT.func,
    storeData: PT.func,
    documentsTable: PT.object,
    history: PT.object,
    redirectId: PT.string,
    id: PT.string,
    setCustomerViewCompanyId: PT.func,
    companyId: PT.number
};

const MapStateToProps = state => ({
    data: state.projectData,
    id: state.projectData.id,
    dataLoaded: state.projectData.dataLoaded,
    documents: documentsSelector(state),
    documentsTable: documentsTableSelector(state),
    showSharePopup: shareDataSelector(state),
    isDocumentEditPopupOpened: state.documentEditPopupData.isOpened,
    redirectId: state.projectData.redirectId,
    companyId: state.documentsLibraryPopupsData.currentUserCompanyId,
    isImpersonateMode: isImpersonateModeSelector(state),
});

const MapDispatchToProps = dispatch => ({
    getData: () => dispatch(getData()),
    deleteProject: project => dispatch(startDeleting(project)),
    edit: (id, name, description) => {
        dispatch(setData({ id, name, initialName: name, description }));
        dispatch(openProjectEditPopup());
    },
    clearData: () => dispatch(clearData()),
    addFileToPackage: options => dispatch(addFileToPackage(options)),
    deleteDocument: document => dispatch(startDeletingDocument(document)),
    openSharePopup: data => dispatch(setSharePopupState(data)),
    editDocument: data => dispatch(openDocumentEditPopup(data)),
    setPageToReload: name => dispatch(setPageToReload(name)),
    setActiveProjectId: id => dispatch(setActiveProjectId(id)),
    openDocumentsAddPopup: () => dispatch(openDocumentsAddPopup()),
    storeData: data => dispatch(storeData(data)),
    setCustomerViewCompanyId: id => dispatch(setCustomerViewCompanyId(id)),
    showNotification: text => dispatch(addNotification({ text, duration: 2000 })),
});

export default connect(MapStateToProps, MapDispatchToProps)(Project);
