import http from '../../core/fetch';
import { updateData as updateMyDocumentsData } from '../../pages/MyDocuments/myDocuments.actions';
import { getData as updateProjectData } from '../../pages/Project/project.actions';
import { getData as updateDocumentTypeData } from '../../pages/DocumentType/documentType.actions';
import {
    setActiveTypeId,
    setCustomerViewCompanyId as setCustomerViewCompanyIdForDocumentsAddPopup
} from '../DocumentsAddPopup/documentsAddPopup.actions';
import { getOneDoctypeData } from '../../actions/documents.actions';
import { getViewDocumentData } from '../../actions/viewDocument.actions';

import { DOCUMENTS_LIBRARY_POPUPS } from '../../actions/actionsNamesList';

const { STORE_DATA, SET_PAGE_TO_RELOAD, SET_TYPES, SET_ACTIVE_COMPANY_ID } = DOCUMENTS_LIBRARY_POPUPS;

export function storeData(data) {
    return {
        type: STORE_DATA,
        data
    };
}

export function storeTypes(types) {
    return {
        type: SET_TYPES,
        types
    };
}

export function normalizeForSelect({ id: value, name: title = '', isActive }) {
    return { value, title, isActive };
}

export function normalizeProjectsForSelect({ id: value, name: title = '', sharedToCompanies = [], createdByUser: author, createdDate: date, documentsCount: docCount }) {
    return { value, title, sharedToCompanies, author, date, docCount };
}

function normalizeUserForSelect({ id: value, firstName = '', lastName = '' }) {
    return {
        value,
        type: 'user',
        title: `${firstName} ${lastName}`,
        initials: firstName.substr(0, 1) + lastName.substr(0, 1)
    };
}

function normalizeGroupForSelect({ id: value, groupName: title = '' }) {
    return {
        value,
        type: 'group',
        title,
        subTitle: 'Users group',
        initials: title.substr(0, 2)
    };
}

export function getData() {
    return (dispatch, getState) => {
        const params = {
            t: Date.now(),
        };
        const { customerViewCompanyId } = getState().documentsLibraryPopupsData;
        if (customerViewCompanyId !== null) {
            params.companyId = customerViewCompanyId;
        }
        return http.get('get doctypes and users', params)
            .then((response) => {
                const { projects = [], documentTypes = [], companiesList = [], users = [], groups = [] } = response;
                const currentActiveTypeId = getState().documentsAddPopupData.activeTypeId;
                const normalizedTypes = documentTypes
                    .map(normalizeForSelect)
                    .sort(
                        ({title: a}, {title: b}) => {
                            if (a.toLowerCase() === 'research report') return -1;
                            if (b.toLowerCase() === 'research report') return 1;
                            return -1;
                        }
                    );
                dispatch(storeData({
                    projects: projects.map(normalizeProjectsForSelect),
                    types: normalizedTypes,
                    companies: companiesList.map(normalizeForSelect),
                    contacts: [
                        ...users.map(normalizeUserForSelect),
                        ...groups.map(normalizeGroupForSelect)
                    ]
                }));
                if (currentActiveTypeId === null && normalizedTypes.length > 0) {
                    dispatch(setActiveTypeId(normalizedTypes[0].value));
                }
            });
    };
}

export function setPageToReload(name) {
    return {
        type: SET_PAGE_TO_RELOAD,
        name
    };
}

export function updatePageToReload() {
    return (dispatch, getState) => {
        const { pageToReload } = getState().documentsLibraryPopupsData;
        switch (pageToReload) {
            case 'myDocuments': {
                dispatch(updateMyDocumentsData());
                break;
            }
            case 'project': {
                dispatch(updateProjectData());
                break;
            }
            case 'documentType': {
                dispatch(updateDocumentTypeData());
                break;
            }
            case 'docTabsDocument': {
                const word = getState().pageStatus.searchString;
                dispatch(getOneDoctypeData({ doctype: 'MyDocument', word }));
                break;
            }
            case 'viewDocDocument': {
                const id = getState().viewDocumentData.data.info.id;
                const doctype = getState().viewDocumentData.data.info.documentType;
                dispatch(getViewDocumentData({ id, doctype }));
                break;
            }
            default: {
                break;
            }
        }
    };
}

export function separateContacts(contacts) {
    return contacts.reduce(
        (usersAndGroups, contact) => {
            if (contact.type === 'user') { /* contact is the user */
                return [
                    [...usersAndGroups[0], contact.value], /* add to the users list */
                    usersAndGroups[1]
                ];
            } else if (contact.type === 'group') { /* contact is the group */
                return [
                    usersAndGroups[0],
                    [...usersAndGroups[1], contact.value] /* add to the groups list */
                ];
            }
            return usersAndGroups;
        }, [[], []] /* first array is for users, second is for groups */
    );
}

let getDataOnChangeCustomerId;

export function setCustomerViewCompanyId(id) {
    return (dispatch) => {
        dispatch({
            type: SET_ACTIVE_COMPANY_ID,
            id
        });
        dispatch(setCustomerViewCompanyIdForDocumentsAddPopup(id));
        clearTimeout(getDataOnChangeCustomerId);
        getDataOnChangeCustomerId = setTimeout(() => {
            dispatch(getData());
        }, 2000);
    };
}
