import { FC } from "react";
import cn from "classnames";
import { IconLogoDark } from "../../../icons";
import "./Spinner.styles.scss";

type Modifiers = "full";

interface iProps {
    className?: string;
    modifiers?: Array<Modifiers>
}

const Spinner: FC<iProps> = ({ className, modifiers = [] }) => {
    const classNames = cn(
        "Spinner",
        modifiers.map(m => `Spinner--${m}`),
        className,
    );

    return (
        <div className={classNames}>
            <IconLogoDark className="Spinner__logo" />
        </div>
    );
};

export default Spinner;
