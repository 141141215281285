import { getStringMatchRank, StringMatchRanks } from "@helpers";
import { iContact } from "../../../../core/models/Contact";

export const MQ_VIEWPORT_SIZE = 1400;
export function getResponsiveScale(num: number, viewportWidth: number) {
    const scale =
        viewportWidth > MQ_VIEWPORT_SIZE
            ? Math.round(1000 * (viewportWidth / MQ_VIEWPORT_SIZE)) / 1000
            : 1;
    return num * scale;
}
export function getSlidesPerView(viewportWidth: number, approxTileWidth: number) {
    return Math.floor(viewportWidth / getResponsiveScale(approxTileWidth, viewportWidth));
}

export interface iUpdateRequest {
    message: string;
    contact: iContact;
}

export enum ContactsTypeEnum {
    Firefly = "Firefly",
    Custom = "Custom"
}

export enum ViewTypeEnum {
    Tiles = "Tiles",
    List = "List",
}

export const prepareContactsData = (data: iContact[], searchQuery: string) => {
    const query = searchQuery.toLowerCase().trim();

    if ( !query ) return data;

    const filteredData = data.filter(({ name, title }) => {
        const matchName = getStringMatchRank(name, query) >= StringMatchRanks.Includes;
        const matchTitle = getStringMatchRank(title, query) >= StringMatchRanks.Includes;
        return matchName || matchTitle;
    });

    return filteredData
        .sort((a, b) => {
            const aTitleRank = getStringMatchRank(a.title, query);
            const bTitleRank = getStringMatchRank(b.title, query);
            return bTitleRank > aTitleRank ? 1 : bTitleRank < aTitleRank ? -1 : 0;
        })
        .sort((a, b) => {
            const aNameRank = getStringMatchRank(a.name, query);
            const bNameRank = getStringMatchRank(b.name, query);
            return bNameRank > aNameRank ? 1 : bNameRank < aNameRank ? -1 : 0;
        });
};