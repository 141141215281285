import { FC, memo, useMemo } from "react";
import { iTableConfigItem } from "@components/new/Table/Table.helpers";
import TableCellValue from "@components/new/Table/TableCellValue";
import useTableSort from "@components/new/Table/useTableSort";
import DocumentActions from "@components/new/DocumentActions/DocumentActions";
import Table from "@components/new/Table/Table";
import { iDocument } from "@models/Documents/Documents";
import { getFormattedDate } from "@helpers";
import getFileSize from "@core/getFileSize";
import { FileExtention } from "@components/new/FileExtention/FileExtention";
import { DOCUMENTS_DOCTYPE, getFileType } from "../Documents.helpers";
import { Link } from "react-router-dom";
import serializeParams from "@core/serializeParams";
import { routeNames } from "@core/routeNames";
import "./DocumentsTable.styles.scss";

interface iProps {
    data: iDocument[];
    searchQuery: string;
}

const DocumentsTable: FC<iProps> = ({ data, searchQuery }) => {
    const {
        handleSort,
        sortingField,
        sortingDirection,
        getColumnIcon,
        sortedData: tableData,
    } = useTableSort(data, 'date', 'desc')

    const tableConfig: iTableConfigItem[] = useMemo(() => {
        const columnIcon = (field: keyof iDocument) => getColumnIcon(field, sortingField, sortingDirection);

        return [
            {
                title: "Name",
                icon: columnIcon("title"),
                handleHeaderClick: handleSort("title"),
                isActive: sortingField === "title",
                Value: ({ index }) => {
                    const fileType = getFileType(tableData[index].fileExtention, '');
                    return (
                        <div className="DocumentsTable__cell-title">
                            <FileExtention type={fileType} classNames="DocumentsTable__cell-title-icon" />
                            <div className="DocumentsTable__cell-title-text">
                                <Link
                                    className="DocumentsTable__cell-title-link"
                                    to={{
                                        pathname: routeNames.viewDocument,
                                        search: `?id=${tableData[index].id}&doctype=${DOCUMENTS_DOCTYPE}&fromDocuments=${encodeURIComponent(searchQuery)}`
                                    }}
                                >
                                    <TableCellValue lines={2}>
                                        {tableData[index].title}
                                    </TableCellValue>
                                </Link>
                            </div>
                        </div>
                    )
                },
            },
            {
                title: "Type",
                icon: columnIcon("type"),
                handleHeaderClick: handleSort("type"),
                isActive: sortingField === "type",
                width: "15%",
                Value: ({ index }) => <>{tableData[index].type}</>,
            },
            {
                title: "Project name",
                icon: columnIcon("project"),
                handleHeaderClick: handleSort("project"),
                isActive: sortingField === "project",
                width: "15%",
                Value: ({ index }) => (
                    <Link
                        className="DocumentsTable__cell-title-link"
                        to={{
                            pathname: routeNames.projects,
                            search: `?${serializeParams({ id: tableData[index].projectId })}`
                        }}
                    >
                        {tableData[index].project}
                    </Link>
                ),
            },
            {
                title: "Date",
                icon: columnIcon("date"),
                handleHeaderClick: handleSort("date"),
                isActive: sortingField === "date",
                width: 150,
                Value: ({ index }) => <>{getFormattedDate(`${tableData[index].date}`, "MMMM YYYY")}</>,
            },
            {
                title: "Size",
                icon: columnIcon("size"),
                handleHeaderClick: handleSort("size"),
                isActive: sortingField === "size",
                width: 100,
                Value: ({ index }) => {
                    const { value, units } = getFileSize(parseInt(tableData[index].size));
                    return <>{Math.round(value)} {units}</>
                },
            },
            {
                title: "",
                styles: {
                    cell: {
                        paddingRight: 24,
                    }
                },
                width: 250,
                isVisibileOnHover: true,
                Value: ({ index }) => (
                    <div className="DocumentsTable__row-actions">
                        <DocumentActions
                            copyUrl={tableData[index].copyUrl}
                            downloadUrl={tableData[index].downloadUrl}
                            packageData={{
                                id: tableData[index].id,
                                docType: tableData[index].docType,
                            }}
                            shareData={{
                                id: tableData[index].id,
                                title: tableData[index].title,
                                shareLinkString: tableData[index].copyUrl
                            }}
                            modifiers={["vertical"]}
                        />
                    </div>
                ),
            },
        ];
    }, [getColumnIcon, handleSort, searchQuery, sortingDirection, sortingField, tableData]);

    return (
        <div className="DocumentsTable">
            <div className="DocumentsTable__wrapper">
                <Table
                    rowsCount={tableData.length}
                    config={tableConfig}
                    theme={"light"}
                    isCopyButtonHidden
                />
            </div>
        </div>
    );
};

export default memo(DocumentsTable);
