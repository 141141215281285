import SpriteIcon from '../../components/SpriteIcon/SpriteIcon';
import { Link } from 'react-router-dom';
import './Errors.scss';

const ErrorPage = () => (
    <div className="error-page">
        <div className="error-page__inner">
            <div className="error-page__content">
                <SpriteIcon iconId="logo-dark" width="100" height="100" className="error-page__logo"/>
                <p className="error-page__title">Page not found</p>
                <p className="error-page__text">The page you are looking for was moved, renamed or might never existed</p>
                <Link
                    to={'/'}
                    className="error-page__home-link"
                >
                    back to home page
                </Link>
            </div>
        </div>
    </div>
);

export default ErrorPage;
