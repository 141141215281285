import { createSelector } from 'reselect';
import { groupsPopupDataSelector } from './groups.selectors';

const allPackages = state => state.packagesData.allPackages;
export const packagesPopupDataSelector = state => state.packagesData;

const currentPackage = (state, props) => {
    const id = props.id;
    const filteredPackage = state.packagesData.allPackages.find(item => item.id === id);
    return filteredPackage;
};

export const getCurrentPackage = createSelector(
    currentPackage,
    packageItem => packageItem
);

const packageColumnSettings = [
    { label: 'ITEM', align: 'left', sorting: 'abc' },
    { label: 'TYPE', sorting: 'abc' },
    { label: 'FORMAT', sorting: 'abc' },
    { label: '' }
];

export const packagesTableSelector = createSelector(
    getCurrentPackage,
    (currentPackage) => {
        return {
            settings: packageColumnSettings,
            data: currentPackage.items.map(item => [
                item.name,
                item.type,
                item.format,
                {
                    downloadLink: item.downloadLink,
                    type: 'overlay',
                    id: item.id,
                    name: item.name
                }
            ]
            )
        };
    }
);

export const isPackagesPopupOpenedSelector = createSelector(
    packagesPopupDataSelector,
    (popupData) => popupData.isOpened
);
