import { iPermissionFields, PermissionLevelEnum, PermissionSection } from "@models/PermissionSection";
import {
    API__ChainPermissionWrapper,
    API__FlavorWordInfo,
} from "@apiSchema";

export enum HeatScoreLevel {
    None,
    Declining,
    Flat,
    GainingSlow,
    GainingFast,
    OnFire,
}

interface iHeatScoreLevelItem {
    id: HeatScoreLevel;
    name: string;
    description: string;
}

export function getHeatScoreLevel (id: number): HeatScoreLevel {
    switch (id) {
        case 1: return HeatScoreLevel.Declining;
        case 2: return HeatScoreLevel.Flat;
        case 3: return HeatScoreLevel.GainingSlow;
        case 4: return HeatScoreLevel.GainingFast;
        case 5: return HeatScoreLevel.OnFire;
        default: return HeatScoreLevel.None;
    }
}

export interface iFoodProfileHeatScoreData {
    config: iHeatScoreLevelItem[];
    tooltipText: string;
    heatLevelId: HeatScoreLevel;
    knowIt: number;
    triedIt: number;
    loveIt: number;
}

export interface iFoodProfileHeatScore extends iPermissionFields {
    data: iFoodProfileHeatScoreData;
}

type ApiModel = API__ChainPermissionWrapper<API__FlavorWordInfo>;

interface iData {
    apiModel?: ApiModel;
}

export class FoodProfileHeatScore implements iFoodProfileHeatScore {
    static defaultData: iFoodProfileHeatScoreData = {
        config: [],
        heatLevelId: HeatScoreLevel.None,
        tooltipText: '',
        loveIt: 0,
        knowIt: 0,
        triedIt: 0,
    };

    permissionLevel = PermissionLevelEnum.None;
    hasData = false;
    data = FoodProfileHeatScore.defaultData;

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private setData(model: iFoodProfileHeatScore) {
        ({
            permissionLevel: this.permissionLevel,
            hasData: this.hasData,
            data: this.data,
        } = model);
    }

    private mapFromApi (rawData: ApiModel) {
        let parsedData = FoodProfileHeatScore.defaultData;

        if (rawData.hasData && rawData.data) {
            const {
                config: {
                    heatLevelInfo,
                    heatScoresTooltip,
                },
                data: {
                    heatScore: {
                        loveIt,
                        triedIt,
                        knowIt,
                        heatLevel,
                    },
                }
            } = rawData.data;

            parsedData = {
                config: heatLevelInfo.map(level => ({
                    id: getHeatScoreLevel(level.id),
                    name: level.name,
                    description: level.description,
                })),
                tooltipText: heatScoresTooltip,
                heatLevelId: getHeatScoreLevel(heatLevel),
                loveIt,
                triedIt,
                knowIt,
            };
        }

        parsedData.config.sort(({ id: a }, { id: b}) => a >= b ? -1 : 1);

        this.setData({
            permissionLevel: PermissionSection.getPermissionLevel(rawData.permissionLevel),
            hasData: rawData.hasData,
            data: parsedData,
        });
    }
}