import GlobalUserData, { iGlobalUserData } from "@models/GlobalUserData";
import { useData } from "@hooks/useData";
import { API__RootUserInfoViewModel } from "@apiSchema";
import Http from "@core/fetch";

async function getGlobalUserData(): Promise<iGlobalUserData> {
    const response: API__RootUserInfoViewModel = await Http.get('get global user data');;
    return new GlobalUserData(response);
}

export default function useGlobalUserData() {
    return useData(new GlobalUserData(), getGlobalUserData);
}