import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    isDashboardSelector,
    templateTypeSelector,
    isAnyPopupOpenedSelector,
} from '@selectors/page';
import {
    closeSearchPopup,
    openSearchPopup,
    clearSearchString,
    showSnapAssistantReminder,
    hideSnapAssistantReminder,
} from '@actions/page';
import { changeSearchPopupQuery, initSearchPopupData } from '@actions/searchPopup.actions';
import { addNotification } from '@components/GlobalNotification/globalNotification.actions';

import { getCurrentPackage } from '@actions/packages.actions';
import http from '../../core/fetch';
import './Page.scss';
import { routeNames } from "@core/routeNames";

const LETTERS_KEYS_LIST = new Set([
    81, 87, 69, 82, 84, 89, 85, 73, 79, 80, 76, 75, 74, 72, 71, 70, 68, 83, 65, 90, 88, 67, 86, 66,
    78, 77, 49, 50, 51, 52, 53, 54, 55, 56, 57, 48, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105
]);

class Page extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDashboardRoute: false,
        };

        if (window.ga) {
            window.ga('send', 'pageview', window.location.pathname);
        }
    }

    componentDidMount() {
        const { location, clearSearchString, getCurrentPackage } = this.props;

        if (location.pathname === '/') {
            this.setState({ isDashboardRoute: true });
        }

        getCurrentPackage();
        const isSearchResultsRoute = location.pathname === routeNames.food
            || location.pathname === routeNames.places
            || location.pathname === routeNames.documents;
        if (!isSearchResultsRoute) {
            clearSearchString();
        }

        if(this.state.isDashboardRoute && this.props.shouldRenderReminder) {
            this.showReminderTimeout = setTimeout(() => {
                this.props.showSnapAssistantReminder();
            }, 3000);
            this.hideReminderTimeout = setTimeout(() => {
                this.closeReminderAd();
                this.props.hideSnapAssistantReminder();
            }, 8000);
        }
    }

    componentWillUnmount() {
        this.props.closeSearchPopup();
        clearTimeout(this.showReminderTimeout);
        clearTimeout(this.hideReminderTimeout);
    }

    componentDidUpdate(prevProps) {
        if ( this.props.location.pathname !== prevProps.location.pathname ) {
            this.setState({isDashboardRoute: this.props.location.pathname === '/'});
        }

        if (prevProps.isNotificationShown !== this.props.isNotificationShown && this.props.isNotificationShown) {
            this.props.showNotification({ text: 'File successfully uploaded', duration: 5000 })
        }
    }

    closeReminderAd() {
        let { ads } = window.pageData;
        const indexOfAd = ads.indexOf('addExtension');

        const params = {
            ads: ['addExtension'],
        };

        http.post('close ad', {}, {
            body: JSON.stringify(params),
            headers: {
                'content-type': 'application/json; charset=utf-8',
            },
        });

        if (indexOfAd !== -1) {
            ads.splice(indexOfAd, 1);
        }
    }


    render() {
        const { children, templateType, isDashboard, isNotificationShown, isBlurred, isFoodScapePopupOpened } = this.props;
        let classList = ['container', 'page-container', templateType];

        if (!isDashboard) {
            classList.push('page--collapsed');
        }

        classList = classList.join(' ');
        return (
            <div className={classList}>
                { children }
            </div>
        );
    }
}

Page.propTypes = {
    name: PropTypes.string,
    children: PropTypes.element,
    isDashboard: PropTypes.bool,
    isNotificationShown: PropTypes.bool,
    isSearchPopupOpened: PropTypes.bool,
    closeSearchPopup: PropTypes.func,
    initSearchPopupData: PropTypes.func,
    openSearchPopup: PropTypes.func,
    templateType: PropTypes.string,
    listenKeyDown: PropTypes.bool,
    changeSearchQuery: PropTypes.func,
    isDocumentsPopupOpened: PropTypes.bool,
    location: PropTypes.object,
    clearSearchString: PropTypes.func,
    getCurrentPackage: PropTypes.func,
    showNotification: PropTypes.func,
    isFoodScapePopupOpened: PropTypes.bool,
    isAnyPopupOpened: PropTypes.bool,
};

Page.defaultProps = {};

const mapStateToProps = state => ({
    isDashboard: isDashboardSelector(state),
    isSearchPopupOpened: state.pageStatus.isSearchPopupOpened,
    templateType: templateTypeSelector(state),
    isNotificationShown: state.pageStatus.showNotification,
    isAnyPopupOpened: isAnyPopupOpenedSelector(state),
    isFoodScapePopupOpened: state.pageStatus.isFoodScapePopupOpened,
    shouldRenderReminder: state.pageStatus.shouldRenderReminder,
});

const mapDispatchToProps = dispatch => ({
    closeSearchPopup: () => dispatch(closeSearchPopup()),
    openSearchPopup: () => dispatch(openSearchPopup()),
    changeSearchQuery: string => dispatch(changeSearchPopupQuery(string)),
    initSearchPopupData: () => dispatch(initSearchPopupData()),
    clearSearchString: () => dispatch(clearSearchString()),
    getCurrentPackage: () => dispatch(getCurrentPackage()),
    showNotification: text => dispatch(addNotification(text)),
    hideSnapAssistantReminder: () => dispatch(hideSnapAssistantReminder()),
    showSnapAssistantReminder: () => dispatch(showSnapAssistantReminder()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Page));
