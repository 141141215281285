import Http from '../core/fetch';
import {
    VIEW_DOCUMENT_FETCH_DATA_LOADED,
    VIEW_DOCUMENT_FETCH_DATA_STARTED,
    VIEW_DOCUMENT_FETCH_DATA_STORE,
    VIEW_DOCUMENT_CLEAR_DATA_STORE
} from './actionsNamesList';
import { setClearDocumentPurchaseMade } from './documents.actions';

export function setViewDocumentDataLoaded() {
    return {
        type: VIEW_DOCUMENT_FETCH_DATA_LOADED
    };
}

export function setViewDocumentFetchingData() {
    return {
        type: VIEW_DOCUMENT_FETCH_DATA_STARTED
    };
}

export function setReceivedViewDocumentData(data) {
    return {
        type: VIEW_DOCUMENT_FETCH_DATA_STORE,
        data
    };
}

export function resetViewDocumentData() {
    return {
        type: VIEW_DOCUMENT_CLEAR_DATA_STORE
    };
}

export function getViewDocumentData(params) {
    return (dispatch, getState) => {
        const justPurchased = getState().documentsData.justPurchased;
        if (!justPurchased) dispatch(setViewDocumentFetchingData());
        const data = {
            documentId: params.id,
            documentType: params.doctype
        };
        if (params.signature) data.signature = params.signature;
        Http.get('view-document', data).then((data) => {
            dispatch(setViewDocumentDataLoaded());
            dispatch(setReceivedViewDocumentData(data));
            if (justPurchased) dispatch(setClearDocumentPurchaseMade());
        });
    };
}
