import { useState } from "react";

const useNotification = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleShowNotification = () => {
        const timer = () =>
            setTimeout(() => {
                setIsOpen(false);
            }, 2500);

            setIsOpen(true);

        const timerId = timer();

        return () => {
            clearTimeout(timerId);
        };
    }

    return { isOpen, handleShowNotification }
}

export default useNotification;