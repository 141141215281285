import React, { FC, memo, useCallback, useMemo, useRef, useState } from "react";
import {
    createGetYFn,
    getChartPoints,
    iPoint,
    iViewBoxProps,
    iYearPoint,
    PointType
} from "@components/Chart/Chart.helpers";
import ChartAxisX from "@components/Chart/ChartAxisX";
import ChartArea from "@components/Chart/ChartArea";
import './Chart.styles.scss';
import ChartCurve, { LineStyle } from "@components/Chart/ChartCurve";
import Icon from "@components/SpriteIcon/SpriteIcon";
import cn from "classnames";
import ChartTabs from "@components/Chart/ChartTabs";
import ChartDots from "@components/Chart/ChartDots";
import ChartTooltipMultiline from "@components/Chart/ChartTooltipMultiline/ChartTooltipMultiline";
import { iTooltipRow } from "@components/Chart/ChartTooltipMultiline/ChartTooltipMultiline.helpers";
import { AnalyticsAttribute, getAnalyticsComputedData } from "@models/AnalyticsAttributes";

const Chart: FC<{
    data: iYearPoint[][];
    chartsTitles: string[];
    width: number;
    height: number;
    printValue: (value: number) => string;
    showHaiku: boolean;
    openHaiku?: () => void;
    activeChartIndex: number;
    setActiveChartIndex: (index: number) => void;
    testId?: string;
}> = (
    {
        data: allData,
        chartsTitles,
        width,
        height,
        printValue,
        showHaiku,
        openHaiku,
        activeChartIndex,
        setActiveChartIndex,
        testId,
    }
) => {
    const [activePointIndex, setActivePointIndex] = useState(-1);
    const [isTooltipShown, setIsTooltipShown] = useState(false);
    const tooltipHideTimer = useRef<any>(null);

    const data = useMemo(() => {
        if (allData.length === 0) return [];
        return allData[activeChartIndex];
    }, [allData, activeChartIndex]);

    const viewport = useMemo<iViewBoxProps>(() => {
        const padding = {
            top: 20,
            bottom: 80,
            left: 40,
            right: showHaiku ? 60 : 40,
        };

        return {
            width: width - padding.left - padding.right,
            height: height - padding.top - padding.bottom,
            canvasWidth: width,
            canvasHeight: height,
            padding,
            proportion: 1,
        }
    }, [width, height, showHaiku]);

    const getY = useMemo(() => {
        return createGetYFn({
            height: viewport.height,
            chartValues: data.map(y => y.value),
        });
    }, [data, viewport]);

    const points = useMemo(() => {
        return getChartPoints({
            data,
            getY,
            viewport,
        });
    }, [data, viewport, getY]);

    const pointsPrediction = useMemo(() => {
        const beforeLastPoint = points[points.length - 2];
        const lastPoint = points[points.length - 1];
        return [
            ...points,
            {
                x: lastPoint.x + (lastPoint.x - beforeLastPoint.x),
                y: lastPoint.y/* + (lastPoint.y - beforeLastPoint.y)*/,
            },
        ];
    }, [points]);

    const haikuIconPoint = useMemo(() => {
        const lastPoint = points[points.length - 1];
        return {
            x: lastPoint.x + viewport.padding.left,
            y: viewport.padding.top + lastPoint.y,
            height: viewport.height - lastPoint.y,
        };

    }, [viewport, points]);

    const tooltipCoords: iPoint = useMemo(() => {
        if (activePointIndex >= 0) {
            return {
                x: points[activePointIndex].x + viewport.padding.left,
                y: points[activePointIndex].y + viewport.padding.top,
            };
        }
        return { x: 0, y: 0, };
    }, [activePointIndex, points, viewport]);

    const tooltipData: iTooltipRow[] = useMemo(() => {
        if (activePointIndex >= 0) {
            return allData.map((chart, chartIndex) => {
                const yearData = chart[activePointIndex];
                let values = [];
                let isSubValuesMode = false;

                if (yearData.tooltipValues && yearData.tooltipValues.length > 0) {
                    isSubValuesMode = true;
                    values = yearData.tooltipValues.map(printValue);
                } else {
                    values = [printValue(yearData.value)];
                }

                return {
                    title: chartsTitles[chartIndex],
                    isActive: activeChartIndex === chartIndex,
                    isSubValuesMode,
                    values,
                };
            });
        }
        return [];
    }, [activeChartIndex, activePointIndex, allData, chartsTitles, printValue]);

    const tooltipColumns = useMemo(() => {
        const isSubValuesMode = tooltipData.some(i => i.isSubValuesMode);
        if (tooltipData[0] && isSubValuesMode) return ['Q1', 'Q2', 'Q3', 'Q4'].slice(0, tooltipData[0].values.length);
        else return [''];
    }, [tooltipData]);

    const showTooltip = useCallback((index: number) => {
        setIsTooltipShown(true);
        setActivePointIndex(index);
        clearTimeout(tooltipHideTimer.current);
    }, [setIsTooltipShown, setActivePointIndex, tooltipHideTimer.current]);

    const hideTooltip = useCallback(() => {
        setIsTooltipShown(false);
        tooltipHideTimer.current = setTimeout(setActivePointIndex, 500);
    }, [setIsTooltipShown, setActivePointIndex, tooltipHideTimer.current]);

    return (
        <div
            className="Chart"
            data-testid={testId}
            style={{ width, height }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={viewport.width + viewport.padding.left + viewport.padding.right}
                height={viewport.height + viewport.padding.top + viewport.padding.bottom}
                viewBox={`0 0 ${viewport.width + viewport.padding.left + viewport.padding.right} ${viewport.height + viewport.padding.top + viewport.padding.bottom}`}
            >
                <g
                    name="viewport"
                    viewBox={`0 0 ${viewport.width} ${viewport.height}`}
                    style={{
                        width: viewport.width,
                        height: viewport.height,
                        transform: `translate(${viewport.padding.left}px, ${viewport.padding.top}px)`,
                        position: 'relative',
                    }}
                >
                    {showHaiku ? (
                        <>
                            <linearGradient id="prediction-area-gradient">
                                <stop offset="0%" stop-color="#3869e7" />
                                <stop offset="90%" stop-color="#3869e7" />
                                <stop offset="99%" stop-color="#00EBF5" />
                            </linearGradient>
                            <ChartArea
                                points={pointsPrediction}
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                    bottom: 0,
                                }}
                                gradientId="prediction-area-gradient"
                                viewport={viewport}
                            />
                        </>
                    ) : (
                        <ChartArea
                            points={points}
                            color="#fff"
                            opacity={0.1}
                            viewport={viewport}
                        />
                    )}
                    <ChartCurve
                        points={points}
                        color="#fff"
                        lineStyle={LineStyle.Solid}
                    />

                    <ChartAxisX
                        labels={data.map(y => y.label)}
                        viewport={viewport}
                    />

                </g>
            </svg>
            <ChartDots
                data={data.map((year, yearIndex) => ({
                    ...points[yearIndex],
                    label: printValue(year.value),
                    type: PointType.Solid,
                    isHoverable: true,
                    onMouseEnter: () => showTooltip(yearIndex),
                    onMouseLeave: hideTooltip,
                }))}
                viewport={viewport}
            />
            {showHaiku && (
                <div
                    className="Chart__prediction-thumb-container"
                    style={{
                        transform: `translate(${haikuIconPoint.x}px, ${haikuIconPoint.y}px)`,
                        height: haikuIconPoint.height,
                    }}
                    {...getAnalyticsComputedData({
                        [AnalyticsAttribute.ClickTitle]: 'Haiku',
                    })}
                    onClick={openHaiku}
                    data-testid="buttonChartOpenPopup"
                >
                    <div className="Chart__prediction-thumb clickable">
                        <div
                            className="Chart__prediction-thumb-title"
                            data-testid="textChartMenucastTitle"
                        >
                            Menucast
                        </div>
                        <div
                            className="Chart__prediction-thumb-sub-title"
                            data-testid="textChartMenucastSubTitle"
                        >
                            AI-POWERED PREDICTIONS
                        </div>
                    </div>
                </div>
            )}
            <ChartTabs
                data={allData.map((tabData, tabIndex) => ({
                    label: `${chartsTitles[tabIndex]} ${printValue(tabData[tabData.length - 1].value)}`,
                    isActive: tabIndex === activeChartIndex,
                    onClick: () => setActiveChartIndex(tabIndex),
                }))}
            />
            {activePointIndex >= 0 && (
                <div className={cn("Chart__tooltip", isTooltipShown && "is-shown")}>
                    <ChartTooltipMultiline
                        title={data[activePointIndex]?.label || ''}
                        rows={tooltipData}
                        columns={tooltipColumns}
                        x={tooltipCoords.x}
                        y={tooltipCoords.y}
                        // position={tooltipCoords.y < viewport.canvasHeight / 2 ? "bottom" : "top"}
                        position={"bottom"}
                        align={activePointIndex === data.length - 1 ? 'right' : 'center'}
                        hasHeader={tooltipColumns.length > 0 && !tooltipColumns.includes('')}
                    />
                </div>
            )}
        </div>
    );
};

export default memo(Chart);