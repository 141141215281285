import { FC, useEffect, useState } from "react";
import Button, { ButtonTypeEnum } from "../../../../../components/new/Button/Button";
import Tabs from "../../../../../components/new/Tabs/Tabs";
import TabsItem from "../../../../../components/new/Tabs/TabsItem";
import { iMenuExamplesData } from "../Menu.helpers";
import MenuExamplesItem from "./MenuExamplesItem/MenuExamplesItem";
import "./MenuExamples.styles.scss";
import { MenuPartsEnum } from "../../../../../core/models/ChainMenuData";
import { ID } from "../../../../../core/models";
import { splitColumns } from "../../../../../core/helpers";

interface iProps {
    data: iMenuExamplesData[];
    setSelectedButton: (id: MenuPartsEnum) => void;
    selectedButton: string;
}

const MenuExamples: FC<iProps> = ({ data, selectedButton, setSelectedButton }) => {
    const columnsNumber = 4;
    const maxCollapsedItems = columnsNumber * 2;
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        setIsExpanded(false);
    }, [data, selectedButton]);

    const handleChange = (value: ID) => {
        setSelectedButton(value as MenuPartsEnum);
    };

    const currentTab = data.find(i => i.id === selectedButton);

    return (
        <div className="MenuExamples">
            <Tabs
                items={data.map(({ id, title }) => ({ id, title }))}
                onChange={handleChange}
                selectedId={selectedButton}
            >
                {data.map(menuExamples => {
                    const slicedItems = splitColumns(menuExamples.data, columnsNumber);

                    return (
                        <TabsItem
                            isShown={selectedButton === menuExamples.id}
                            key={menuExamples.id}
                            className="MenuExamples__examples-tab"
                        >
                            <div className="MenuExamples__grid">
                                {slicedItems.map((column, index) => {
                                    return (
                                        <div className="MenuExamples__column" style={{ width: `calc(${100 / columnsNumber}% - ${columnsNumber}px)` }}
                                        key={index}>
                                            {column
                                                .slice(0, isExpanded ? column.length : 2)
                                                .map((item, index) => {
                                                    return (
                                                        <div
                                                            className="MenuExamples__item"
                                                            key={index}
                                                        >
                                                            <MenuExamplesItem data={item} />
                                                        </div>
                                                    );
                                            })}
                                            <div style={{ height: 1 }} />
                                        </div>
                                    );
                                })}
                            </div>
                        </TabsItem>
                    );
                })}
            </Tabs>

            {currentTab && currentTab.data.length > maxCollapsedItems && (
                <div className="MenuExamples__footer">
                        <Button
                            type={ButtonTypeEnum.Button}
                            className="MenuExamples__footer-action"
                            onClick={() => setIsExpanded(!isExpanded)}
                        >
                            {isExpanded ? "View less" : "View more"}
                        </Button>
                </div>
            )}
        </div>
    );
};

export default MenuExamples;
