import { FC } from "react";
import NotificationPopup from "../../NotificationPopup/NotificationPopup";

interface iProps {
    isOpen: boolean;
}

const CopyNotification:FC<iProps> = ({ isOpen }) => {
    return (
        <NotificationPopup
            isOpened={isOpen}
            hasCloseButton={false}
            style={CopyNotificationStyles}
            className="notification-popup--table"
        >
            {CopyNotificationContent}
        </NotificationPopup>
    );
};
const CopyNotificationContent = <span>Table successfully copied</span>;
const CopyNotificationStyles = { width: "auto", height: "auto" };
export default CopyNotification;
