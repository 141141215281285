import { iPermissionFields, PermissionLevelEnum, PermissionSection } from "@models/PermissionSection";
import { API__InstantChartDetailsModel } from "@apiSchema";
import { getFormattedThousand } from "@helpers";
import { ID } from "@models/index";

export const DEFAULT_VALUE_PLACEHOLDER = undefined;

type ApiModel = API__InstantChartDetailsModel;

interface iData {
    apiModel?: ApiModel;
}

export interface iInstantChartWord {
    title: string;
    penetration?: number;
    oneYearTrend?: number;
    fourYearTrend?: number;
    knowIt?: number;
    haveTriedIt?: number;
    loveOrLikeIt?: number;
}

interface iInstantChartData {
    id: ID;
    title: string;
    description: string;
    baseSizeDescription: string;
    words: iInstantChartWord[];
}

export interface iInstantChart extends iPermissionFields {
    data: iInstantChartData;
}

export default class InstantChart implements iInstantChart {
    static defaultData: iInstantChartData = {
        id: 0,
        title: '',
        description: '',
        baseSizeDescription: '',
        words: [],
    };
    static fakeData: iInstantChartData = {
        id: 0,
        title: 'Fake Instant Chart',
        description: 'fake description',
        baseSizeDescription: 'fake description',
        words: InstantChart.generateFakeWords(8),
    };

    permissionLevel = PermissionLevelEnum.None;
    hasData = true;
    data = InstantChart.defaultData;

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private setData (model: iInstantChart) {
        ({
            data: this.data,
            hasData: this.hasData,
            permissionLevel: this.permissionLevel,
        } = model);
    }

    private mapFromApi (apiModel: ApiModel) {
        const {
            id,
            flavorPermissionLevel: API_flavorPermissionLevel,
            description,
            baseSizes: {
                incidence: baseSizeIncidence,
                penetration: baseSizePenetration,
            },
            title,
            wordData: {
                data: wordsData = [],
                permissionLevel: API_mtPermissionLevel,
            },
        } = apiModel;
        let data: iInstantChartData = InstantChart.fakeData;
        let permissionLevel = PermissionLevelEnum.None;
        let flavorPermissionLevel = PermissionSection.getPermissionLevel(API_flavorPermissionLevel);
        let mtPermissionLevel = PermissionSection.getPermissionLevel(API_mtPermissionLevel);

        if (mtPermissionLevel !== PermissionLevelEnum.None) {
            if (!flavorPermissionLevel) {
                permissionLevel = PermissionLevelEnum.Limited;
            } else {
                permissionLevel = PermissionLevelEnum.Full;
            }

            data = {
                id,
                title,
                description,
                baseSizeDescription: `The figures are based on analysis of ${getFormattedThousand(baseSizeIncidence)} items on ${getFormattedThousand(baseSizePenetration)} distinct restaurant menus nationwide.`,
                words: wordsData.map(word => ({
                    title: word.name,
                    penetration: word.penetrations.currentYear,
                    oneYearTrend: word.penetrations.oneYearTrend,
                    fourYearTrend: word.penetrations.fourYearTrend,
                    knowIt: word.flavorData?.knowIt,
                    haveTriedIt: word.flavorData?.haveTriedIt,
                    loveOrLikeIt: word.flavorData?.loveOrLikeIt,
                }))
            }
        } else {
            data.title = apiModel.title;
        }

        this.setData({
            permissionLevel,
            hasData: true,
            data,
        });
    }

    static generateFakeWords(count: number = 1): iInstantChartWord[] {
        return Array(count).fill(null).map((_, index) => ({
            title: `Keyword ${index + 1}`,
            penetration: DEFAULT_VALUE_PLACEHOLDER,
            oneYearTrend: DEFAULT_VALUE_PLACEHOLDER,
            fourYearTrend: DEFAULT_VALUE_PLACEHOLDER,
            knowIt: DEFAULT_VALUE_PLACEHOLDER,
            haveTriedIt: DEFAULT_VALUE_PLACEHOLDER,
            loveOrLikeIt: DEFAULT_VALUE_PLACEHOLDER,
        }));
    }
}