export function getMsgNumber(daysLeft) {
    switch (daysLeft) {
        case 14:
        case 13:
        case 12:
        case 11:
        case 10:
        case 9:
        case 8: {
            return 3;
        }
        case 7:
        case 6:
        case 5:
        case 4: {
            return 2;
        }
        case 3:
        case 2:
        case 1:
        case 0: {
            return 1;
        }
        default: {
            return 0;
        }
    }
}

export function getExpireTimeStamp(daysLeft) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today.setDate(today.getDate() + daysLeft);
}
