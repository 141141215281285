import { FC } from "react";
import { IconClose } from "../../../../icons";
import "./OptionTag.styles.scss";

interface iProps {
    title: string;
    onRemove: () => void;
}

const OptionTag: FC<iProps> = ({ title, onRemove }) => {
    return (
        <div className="OptionTag">
            <div className="OptionTag__label">{title}</div>
            <div className="OptionTag__remove" onClick={onRemove}>
                <IconClose className="OptionTag__remove-icon" />
            </div>
        </div>
    );
};

export default OptionTag;