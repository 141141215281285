import { Component } from 'react';
import Scrollbar from 'react-custom-scrollbars';
import './SelectCompany.styles.scss';

export default class SelectCompany extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchQuery: '',
        };
        this.selectOption = this.selectOption.bind(this);
    }

    selectOption(id) {
        this.props.setValue(id);
    }

    renderOptions() {
        const { options, value } = this.props;
        const { searchQuery } = this.state;
        const matchRE = new RegExp(searchQuery, 'i');

        return options
            .filter(option => matchRE.test(option.title))
            .map((option) => {
                const isChecked = option.id === value;
                const classList = new Set(['custom-drop-down__option', 'custom-drop-down__multi-option', 'multi-option']);
                const iconClassList = new Set(['multi-option__marker']);

                if (isChecked) {
                    iconClassList.add('multi-option__marker--checked');
                }
                return (
                    <div
                        key={option.id}
                        onClick={() => this.selectOption(option.id)}
                        className={Array.from(classList).join(' ')}
                    >
                        <div className={Array.from(iconClassList).join(' ')}>
                            { isChecked ? '✓' : '' }
                        </div>
                        {option.title}
                    </div>
                );
            })
    }

    render() {
        return (
            <div className="SelectCompany">
                <div className="SelectCompany__search">
                    <input
                        ref="input"
                        value={this.state.searchQuery}
                        type="search"
                        autoFocus="autofocus"
                        className="custom-drop-down__input custom-drop-down__input--comp"
                        onChange={({ target }) => this.setState({ searchQuery: target.value })}
                        placeholder="Search company..."
                    />
                </div>
                <div className="SelectCompany__options">
                    <Scrollbar>
                        {this.renderOptions()}
                    </Scrollbar>
                </div>
            </div>
        );
    }
}
