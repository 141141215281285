import { iPermissionFields, PermissionLevelEnum, PermissionSection } from "@models/PermissionSection";
import {
    API__ChainPermissionWrapper,
    API__LocalProductIndexArrayViewModel,
} from "@apiSchema";

type ApiModel = API__ChainPermissionWrapper<API__LocalProductIndexArrayViewModel>;

interface iData {
    apiModel?: ApiModel;
}

interface iLocalPopularityItem {
    title: string;
    value: number;
}

interface iFoodProfileLocalPopularityData {
    data: iLocalPopularityItem[];
    termId: number;
    link: string;
}

export interface iFoodProfileLocalPopularity extends iPermissionFields {
    data: iFoodProfileLocalPopularityData;
}

export class FoodProfileLocalPopularity implements iFoodProfileLocalPopularity {
    permissionLevel = PermissionLevelEnum.None;
    hasData = false;
    data = FoodProfileLocalPopularity.defaultData;

    static defaultData: iFoodProfileLocalPopularityData = {
        data: [],
        termId: 0,
        link: '',
    };

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private setData(data: iFoodProfileLocalPopularity) {
        ({
            data: this.data,
            permissionLevel: this.permissionLevel,
            hasData: this.hasData,
        } = data);
    }

    private mapFromApi(rawData: ApiModel) {
        let data = FoodProfileLocalPopularity.defaultData;

        if (rawData.data) {
            data = {
                termId: rawData.data.termId,
                link: rawData.data.link,
                data: rawData.data.state.map(i => ({
                    title: i.key,
                    value: i.value,
                })),
            };
        }

        this.setData({
            permissionLevel: PermissionSection.getPermissionLevel(rawData.permissionLevel),
            hasData: rawData.hasData,
            data,
        });
    }
}