import { FC } from 'react';
import "./ScrollbarThumb.styles.scss";

const thumbStyle = {
    borderRadius: '20px',
    zIndex: 200
};

const trackStyle = {
    borderRadius: '3px',
};

export const renderVerticalThumb: FC = (props: any) => {
    return (
        <div
            {...props}
            style={{
                ...props.style,
                ...thumbStyle
            }}
            className="ScrollbarThumb is-vertical"
        />
    );
};

export const renderHorizontalThumb: FC = (props: any) => {
    return (
        <div
            {...props}
            style={{
                ...props.style,
                ...thumbStyle
            }}
            className="ScrollbarThumb is-horizontal"
        />
    );
};


export const renderVerticalTrack: FC = (props: any) => {
    return (
        <div
            {...props}
            style={{
                ...props.style,
                ...trackStyle
            }}
            className="ScrollbarTrack is-vertical"
        />
    );
};

export const renderHorizontalTrack: FC = (props: any) => {
    return (
        <div
            {...props}
            style={{
                ...props.style,
                ...trackStyle
            }}
            className="ScrollbarTrack is-horizontal"
        />
    );
};