import { useData } from "@hooks/useData";
import { API__FoodProfilePageDataLiteViewModel } from "@apiSchema";
import http from '../fetch';
import { FoodProfile } from "@models/FoodProfile";

async function getFoodProfileData(word: string): Promise<FoodProfile> {
    try {
        const response: API__FoodProfilePageDataLiteViewModel = await http.get('food tab data', { word });

        return new FoodProfile({ apiModel: response });
    }
    catch (err) {
        console.error(err);
        return new FoodProfile({ noData: true });
    }
}

export function useFoodProfileData(word: string) {
    return useData(new FoodProfile(), getFoodProfileData, word);
}