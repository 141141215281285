import { Component } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import SpriteIcon from '../SpriteIcon/SpriteIcon';
import TableCell from '../Table/TableCell/TableCell';
import { Scrollbars } from 'react-custom-scrollbars';
import Clipboard from 'clipboard';
import './Table.scss';
import TableWorker from './table.worker';

const DEFAULT_LAZY_LOAD_RANGE = 50;

const iconsIds = {
    asc: 'order-reverse',
    desc: 'order',
    default: 'order-arrows',
};

// class TableWorker {
//     onmessage() {}
//     postMessage() {}
// }

class SuperTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            direction: this.props.sortingDirection || 'asc',
            column: this.props.sortingColumn || 0,
            lazyLoadRange: this.props.lazyLoadRange || DEFAULT_LAZY_LOAD_RANGE,
            data: [],
            columnsCount: this.props.settings.reduce((count, col) => {
                if (col.label !== '') {
                    return count + 1;
                }
                return count;
            }, 0),
        };
        this.toggleSort = this.toggleSort.bind(this);
        this.increaseLazyLoadRange = this.increaseLazyLoadRange.bind(this);
        this.handleChangeVisibility = this.handleChangeVisibility.bind(this);
        this.worker = new TableWorker();
        this.worker.onmessage = ({ data }) => {
            this.setState({ data: data.data });
            this.forceUpdate();
        };
        this.worker.onerror = console.error;
    }

    shouldComponentUpdate(nextProps, nextState) {
        let shouldUpdate = false;
        if (nextProps.scrollLoad) {
            return true;
        }
        shouldUpdate = shouldUpdate || nextState.direction !== this.state.direction;
        shouldUpdate = shouldUpdate || nextState.column !== this.state.column;
        shouldUpdate = shouldUpdate || nextProps.className !== this.props.className;
        shouldUpdate = shouldUpdate || nextState.lazyLoadRange !== this.state.lazyLoadRange;
        shouldUpdate = shouldUpdate || JSON.stringify(nextState.data) !== JSON.stringify(this.state.data);
        shouldUpdate = shouldUpdate || JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data);
        return shouldUpdate;
    }

    componentDidMount() {
        this.sortData();
        this.clipboardButton = new Clipboard('#copyTable', {
            target: (trigger) => {
                return trigger.nextElementSibling;
            }
        });

        this.clipboardButton.on('success', (e) => {
            const shouldCopy = !this.props.className.split(' ').includes('places__table--hidden')
                && !this.props.className.split(' ').includes('places__offers-view--hidden');
            if (shouldCopy) {
                e.clearSelection();
                console.log('copy');
                this.props.showNotification({
                    text: 'Successfully copied to your clipboard. You can now paste the results directly into Excel.',
                    duration: 4000,
                });
            }
        });
    }

    sortData(data = this.props.data) {
        const { column, direction } = this.state;
        const { sorting } = this.props.settings[column];
        this.worker.postMessage({
            data,
            sorting,
            column,
            direction,
        });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.sortData(this.props.data);
            this.setState({
                expandedRowIndex: null
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
        this.clipboardButton.destroy();
        this.worker.onmessage = null;
    }

    toggleSort(index) {
        const { column, direction } = this.state;

        if (column === index) {
            this.setState({
                direction: direction === 'asc' ? 'desc' : 'asc'
            });
        } else {
            this.setState({
                column: index,
                direction: 'asc'
            });
        }
        setTimeout(() => {
            this.sortData();
        }, 100);
    }

    backendSort(columnName) {
        this.props.backEndSorting(columnName);
    }

    renderTableHeadCells() {
        const { settings, order } = this.props;
        return settings.map((column, index) => {
            let iconId = iconsIds[column.sorting];
            if (this.props.sortType === 'client') {
                iconId = this.state.column === index ?
                    iconsIds[this.state.direction] :
                    iconsIds.default;
            }
            let backendSortIcon;
            if (column.backSorting) {
                backendSortIcon = order.colName === column.backSorting
                    ? (order.direction === 'desc' ? 'order' : 'order-reverse')
                    : 'order-arrows';
            }
            const tooltipText = column.label === 'Recent LTO\'s'
                ? '# of matching LTOs within the past 12 months'
                : '';
            return (
                <th
                    key={index}
                    className="table__head"
                    title={tooltipText}
                    data-testid={`textTableHead-${index}`}
                >
                    {column.sorting && !this.props.notSortable &&
                    <div className="table__head-icon" onClick={this.toggleSort.bind(this, index)}>
                        <SpriteIcon key={index} iconId={iconId} className="order-icon-dark"/>
                    </div>}
                    {column.backSorting &&
                    <div className="table__head-icon" onClick={this.backendSort.bind(this, column.backSorting)}>
                        <SpriteIcon key={index} iconId={backendSortIcon} className="order-icon-dark"/>
                    </div>}
                    <span
                        onClick={() => {
                            if (column.backSorting) {
                                this.backendSort(column.backSorting);
                            } else if (column.sorting) {
                                this.toggleSort(index);
                            }
                        }}
                        className={`table__head-text${!column.sorting ? ' table__head-text--no-sort' : ''}`}
                        data-testid={`textTableHeadLabel`}
                    >
                        {column.label}
                    </span>
                </th>
            );
        });
    }

    renderTableBodyRows() {
        const {
            settings,
            removePackageItem = {},
            component,
            deleteProject,
            editProject,
            addFileToPackage,
            openSharePopup,
            deleteDocument,
            editDocument,
            showNotification,
            searchString,
        } = this.props;
        let data = this.state.data;

        if (this.props.useLazyLoad) {
            data = data.slice(0, this.state.lazyLoadRange);
        }

        if (data && data.length) {
            return data.map((row, rowIndex) => {
                const isRowExpanded = row[1].type === 'menuItemsList' && row[1].data.isRowExpanded;
                const isRowExpandable = row[1].type === 'menuItemsList';
                const rowClassList = new Set([
                    'table__data-row',
                    isRowExpanded ? 'table__data-row--expanded' : '',
                ]);

                return (
                    <tr
                        key={isRowExpandable ? row[0].id : rowIndex}
                        className={Array.from(rowClassList).join(' ')}
                    >
                        {row.map((cell, index) => (
                            <TableCell key={index}
                                index={rowIndex}
                                removePackageItem={removePackageItem}
                                deleteProject={deleteProject}
                                editProject={editProject}
                                editDocument={editDocument}
                                addFileToPackage={addFileToPackage}
                                openSharePopup={openSharePopup}
                                deleteDocument={deleteDocument}
                                getGapReport={this.props.getGapReport}
                                align={settings[index] && settings[index].align}
                                data={cell}
                                component={component}
                                showNotification={showNotification}
                                expandRow={this.props.expandRow}
                                searchString={searchString}
                                isRowExpanded={isRowExpanded}
                                isRowExpandable={isRowExpandable}
                               testId={`textTableCell-${rowIndex}-${index}`}
                            />
                        ))}
                    </tr>
                );
            });
        } else if (this.props.data.length) {
            return (
                <tr>
                    <td colSpan={this.state.columnsCount} style={{ textAlign: 'center' }} className="spinner-pow">
                        <SpriteIcon iconId="logo-dark" width="200" height="200"/>
                    </td>
                </tr>
            );
        }
        return null;
    }

    handleChangeVisibility(isVisible) {
        console.log('handleChangeVisibility');
        if (isVisible) {
            this.props.loadItems(this.props.order);
        }
    }

    renderAdditionalLoad() {
        const { scrollLoad, isTableView, isLazyLoading } = this.props;
        if (scrollLoad) {
            return (
                isLazyLoading ? (
                    <div className="spinner-pow">
                        <SpriteIcon iconId="logo-dark" width="200" height="200"/>
                    </div>
                ) : (
                    <div>
                        <VisibilitySensor
                            scrollDelay={100}
                            intervalDelay={500}
                            delayedCall={true}
                            partialVisibility={true}
                            active={isTableView}
                            onChange={this.handleChangeVisibility}
                        >
                            <div className="table__visibility-sensor-after"/>
                        </VisibilitySensor>
                    </div>
                )
            );
        }
        return null;
    }

    renderTable() {
        return (
            <table className="table">
                <thead style={{ whiteSpace: 'nowrap' }}>
                <tr className="table__head-row">
                    {this.renderTableHeadCells()}
                </tr>
                </thead>
                <tbody>
                {this.renderTableBodyRows()}
                </tbody>
            </table>
        );
    }

    increaseLazyLoadRange(isVisible) {
        if (isVisible) {
            const { lazyLoadRange = DEFAULT_LAZY_LOAD_RANGE } = this.props;
            this.setState({
                lazyLoadRange: this.state.lazyLoadRange + lazyLoadRange,
            });
        }
    }

    render() {
        const { className = '', backEndSorting, isLazyLoading = false } = this.props;
        const classList = new Set(['table-container', (isLazyLoading ? 'table-container--blurred' : ''), ...className.split(' ')]);
        return (
            <div
                className={Array.from(classList).join(' ')}
                data-testid={this.props.testId}
            >
                {
                    this.props.showCopyButton &&
                    <button
                        id="copyTable"
                        className="table__button"
                        data-testid="buttonTableCopy"
                    >
                        <span className="table__button-icon">
                            <SpriteIcon iconId="copy-icon"/>
                        </span>
                        Copy Table
                    </button>
                }
                {
                    this.props.isScrollableTable ?
                        (
                            <Scrollbars>
                                {this.renderTable()}
                            </Scrollbars>
                        ) :
                        this.renderTable()
                }
                {backEndSorting && isLazyLoading && (
                    <div className="spinner-pow spinner-pow--top">
                        <SpriteIcon iconId="logo-dark" width="200" height="200"/>
                    </div>
                )}
                {this.renderAdditionalLoad()}
                {(this.props.useLazyLoad && (this.state.data.length > this.state.lazyLoadRange)) ? (
                    <VisibilitySensor
                        scrollDelay={100}
                        intervalDelay={500}
                        partialVisibility={true}
                        onChange={this.increaseLazyLoadRange}
                    >
                        <div className="table__visibility-sensor"/>
                    </VisibilitySensor>
                ) : null}
            </div>
        );
    }
}

SuperTable.defaultProps = {
    showCopyButton: true
};

SuperTable.propTypes = {
    testId: PropTypes.string,
    data: PropTypes.array,
    className: PropTypes.string,
    sortType: PropTypes.string,
    settings: PropTypes.array,
    order: PropTypes.object,
    scrollLoad: PropTypes.bool,
    from: PropTypes.string,
    useLazyLoad: PropTypes.bool,
    isTableView: PropTypes.bool,
    showNotification: PropTypes.func,
    isLazyLoading: PropTypes.bool,
    loadItems: PropTypes.func,
    deleteDocument: PropTypes.func,
    notSortable: PropTypes.bool,
    openSharePopup: PropTypes.func,
    editDocument: PropTypes.func,
    removePackageItem: PropTypes.func,
    expandRow: PropTypes.func,
    editProject: PropTypes.func,
    getGapReport: PropTypes.func,
    showCopyButton: PropTypes.bool,
    component: PropTypes.any,
    sortingDirection: PropTypes.string,
    sortingColumn: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    addFileToPackage: PropTypes.func,
    deleteProject: PropTypes.func,
    isScrollableTable: PropTypes.bool,
    lazyLoadRange: PropTypes.number,
    backEndSorting: PropTypes.func,
    searchString: PropTypes.string,
};

export default SuperTable;
