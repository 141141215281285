import { iPermissionFields, PermissionLevelEnum, PermissionSection } from "@models/PermissionSection";
import {
    API__ChainPermissionWrapper,
    API__DocumentsBlockViewModel,
} from "@apiSchema";
import { iReportProPublication, ReportProPublication } from "@models/ReportProPublication";

interface iFoodProfileDocumentsInfoData {
    reports: iReportProPublication[];
    totalCount: number;
}

export interface iFoodProfileDocumentsInfo extends iPermissionFields {
    data: iFoodProfileDocumentsInfoData;
}

type ApiModelType = API__ChainPermissionWrapper<API__DocumentsBlockViewModel>;

interface iData {
    apiModel?: ApiModelType;
}

export class FoodProfileDocumentsInfo implements iFoodProfileDocumentsInfo {
    permissionLevel = PermissionLevelEnum.None;
    hasData = false;
    data = FoodProfileDocumentsInfo.fakeData;

    static fakeData: iFoodProfileDocumentsInfoData = {
        reports: [],
        totalCount: 0,
    };

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private setData(model: iFoodProfileDocumentsInfo) {
        ({
            permissionLevel: this.permissionLevel,
            hasData: this.hasData,
            data: this.data,
        } = model);
    }

    private mapFromApi(rawData: ApiModelType) {
        const { data } = rawData;
        if (data) {
            const {
                totalCount,
                items,
            } = data;

            this.setData({
                permissionLevel: PermissionSection.getPermissionLevel(rawData.permissionLevel),
                hasData: rawData.hasData && totalCount > 0,
                data: {
                    totalCount,
                    reports: items.map(item => new ReportProPublication({ apiModel: item })),
                }
            });
        }

    }
}