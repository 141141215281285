import { FC, useState } from "react";
import "./NestedTiles.styles.scss";
import TilesView, { iItem } from "./TilesView";
import Tile from "./Tile";

interface iProps {
    data: iItem[];
    handleLastNestedClick: (id: number) => void;
}

const NestedTiles: FC<iProps> = (
    {
        data,
        handleLastNestedClick,
    }
) => {
    const [firstLevelTilesIndex, setFirstLevelTilesIndex] =  useState<number>(-1);
    const [secondLevelTilesIndex, setSecondLevelTilesIndex] =  useState<number>(-1);

    let zoomIncrement = 1;
    if (firstLevelTilesIndex >= 0) zoomIncrement -= 1;
    if (secondLevelTilesIndex >= 0) zoomIncrement -= 1;

    const handleLastLevelItemClick = (index: number) => {
        handleLastNestedClick(data[firstLevelTilesIndex].subItems[secondLevelTilesIndex].subItems[index].id);
    }

    return (
        <div className="NestedTiles">
            <TilesView
                data={data}
                zoomLevel={zoomIncrement}
                handleItemClick={setFirstLevelTilesIndex}
            />
            <TilesView
                data={data[firstLevelTilesIndex]?.subItems || []}
                zoomLevel={zoomIncrement + 1}
                handleItemClick={setSecondLevelTilesIndex}
            >
                <Tile
                    onClick={() => setFirstLevelTilesIndex(-1)}
                    title="<< back"
                    modifiers={["opaque"]}
                />
            </TilesView>
            <TilesView
                data={data[firstLevelTilesIndex]?.subItems[secondLevelTilesIndex]?.subItems || []}
                zoomLevel={zoomIncrement + 2}
                handleItemClick={handleLastLevelItemClick}
            >
                <Tile
                    onClick={() => setSecondLevelTilesIndex(-1)}
                    title="<< back"
                    modifiers={["opaque"]}
                />
            </TilesView>
        </div>
    )
};

export default NestedTiles;