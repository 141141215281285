import {
    DOCUMENT_TYPE_DATA_STORE,
    DOCUMENT_TYPE_DATA_CLEAR
} from '../../actions/actionsNamesList';

const defaultState = {
    dataLoaded: false,
    documents: [],
    customerViewCompanyId: null,
    isShareMode: false,
    name: ''
};

export default function documentTypeData(state = defaultState, action) {
    switch (action.type) {
        case DOCUMENT_TYPE_DATA_STORE: {
            return {
                ...state,
                ...action.data
            };
        }
        case DOCUMENT_TYPE_DATA_CLEAR: {
            return {
                ...defaultState
            };
        }
        default: {
            return { ...state };
        }
    }
}
