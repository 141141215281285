import { FC } from "react";
import { iTableConfigItem } from "./Table.helpers";
import TableCellWrapper, { iProps as iCellWrapperProps } from "./TableCellWrapper";
interface iProps {
    columnData: iTableConfigItem;
    props: Partial<iCellWrapperProps>;
    isHoverable?: boolean;
    isActive?: boolean;
    testId?: string;
}

const TableCell: FC<iProps> = (
    {
        columnData,
        props,
        children,
        isHoverable = false,
        isActive = false,
        testId,
    }
) => {
    const getCellCustomStyles = () => {
        const isHead = props.head;

        if ( columnData.styles ) {
            const {
                head = {},
                cell = {}
            } = columnData.styles;

            return isHead ? { ...head, ...cell } : { ...cell };
        }

        return {};
    };

    const allProps: iCellWrapperProps = {
        ...props,
        isHoverable,
        isActive,
        isVisibileOnHover: columnData.isVisibileOnHover || false,
        textCentered: columnData.textCentered || false,
        style: {
            ...props.style,
            ...getCellCustomStyles()
        },
        testId,
    };

    if (columnData.width) {
        allProps.width = columnData.width;
    } else {
        allProps.fillRow = true;
    }
    return (
        <TableCellWrapper {...allProps}>
            {children}
        </TableCellWrapper>
    );

};

export default TableCell;