import { ColumnIconType } from "@components/new/Table/useTableSort";
import { iTableConfigItem } from "@components/new/Table/Table.helpers";
import { FC, useCallback, useMemo } from "react";
import { iInstantChartWord } from "@models/InstantChart";
import { getValueWithSign } from "@helpers";
import { Link } from "react-router-dom";
import { routeNames } from "@core/routeNames";

const FLAVOR_COLUMNS: Array<keyof iInstantChartWord> = [
    "knowIt",
    "loveOrLikeIt",
    "haveTriedIt",
];

export function prepareNumberValue(n: number, suffix: string = '', prefix: string = ''): string {
    if (n === Infinity || Number.isNaN(n)) return 'N/A';
    return `${prefix}${((n * 10) / 10).toFixed(1)}${suffix}`
}

export function prepareWholeNumberValue(n: number, suffix: string = '', prefix: string = ''): string {
    if (n === Infinity || Number.isNaN(n)) return 'N/A';
    return `${prefix}${Math.round(n)}${suffix}`;
}

export function useTableConfig(
    data: iInstantChartWord[],
    sortingField: keyof iInstantChartWord | null,
    sortingDirection: 'asc' | 'desc',
    handleSort: (fieldName: keyof iInstantChartWord) => () => void,
    getColumnIcon: ColumnIconType,
    hasFlavorPermission: boolean,
): iTableConfigItem[] {
    const columnIcon = useCallback(
        (fieldName: keyof iInstantChartWord) => getColumnIcon(fieldName, sortingField, sortingDirection),
        [getColumnIcon, sortingDirection, sortingField]
    );

    const LinkOverlay: FC<{ index: number }> = useCallback(({ index }) => (
        <Link
            to={{
                pathname: routeNames.food,
                search: `?q=${data[index]['title']}`
            }}
            className="absolute-overlay"
        />
    ), [data]);

    function getDefaultColSettings(col: keyof iInstantChartWord): iTableConfigItem {
        let icon: iTableConfigItem['icon'] = columnIcon(col);
        let handleHeaderClick: iTableConfigItem['handleHeaderClick'] = handleSort(col);

        if (FLAVOR_COLUMNS.includes(col) && !hasFlavorPermission) {
            icon = null;
            handleHeaderClick = undefined;
        }

        return {
            title: col,
            styles: {
                head: {
                    fontSize: 12,
                },
                cell: {
                    flexBasis: 100,
                    flexGrow: 0,
                    flexShrink: 0,
                }
            },
            icon,
            isActive: sortingField === col,
            Value: ({ index }) => <>
                {data[index][col] || '-'}
                <LinkOverlay index={index}/>
            </>,
            handleHeaderClick,
            textCentered: true,
        };
    }

    return useMemo((): iTableConfigItem[] => [
        {
            ...getDefaultColSettings('title'),
            title: 'keyword',
            renderTitle: 'keyword',
            textCentered: false,
            Value: ({ index }) => <>
                {(data[index]['title'] || '-').toUpperCase()}
                <LinkOverlay index={index}/>
            </>,
            styles: {},
        },
        {
            ...getDefaultColSettings('penetration'),
            title: 'menu penetration',
            renderTitle: 'menu penetration',
            Value: ({ index }) => {
                try {
                    return (
                        <>
                            {data[index]["penetration"]?.toFixed(1) || 0}%
                            <LinkOverlay index={index}/>
                        </>
                    )
                } catch (err) {
                    return <>
                        N/A
                        <LinkOverlay index={index}/>
                    </>
                }
            },
        },
        {
            ...getDefaultColSettings('oneYearTrend'),
            title: '12-mo trend',
            renderTitle: '12-mo trend',
            Value: ({ index }) => {
                try {
                    return (
                        <>
                            {getValueWithSign(Number(data[index]["oneYearTrend"]?.toFixed(1)) || 0)}%
                            <LinkOverlay index={index}/>
                        </>
                    )
                } catch (err) {
                    return <>
                        N/A
                        <LinkOverlay index={index}/>
                    </>
                }
            },
        },
        {
            ...getDefaultColSettings('fourYearTrend'),
            title: '4-year trend',
            renderTitle: '4-year trend',
            Value: ({ index }) => {
                try {
                    return (
                        <>
                            {getValueWithSign(Number(data[index]["fourYearTrend"]?.toFixed(1)) || 0)}%
                            <LinkOverlay index={index}/>
                        </>
                    )
                } catch (err) {
                    return <>
                        N/A
                        <LinkOverlay index={index}/>
                    </>
                }
            },
        },
        {
            ...getDefaultColSettings('knowIt'),
            title: '% know it',
            renderTitle: '% know it',
            Value: ({ index }) => {
                try {
                    return (
                        <>
                            {data[index]["knowIt"]
                                ? `${Math.round(data[index]["knowIt"] || 0)}%`
                                : '-'
                            }
                            <LinkOverlay index={index}/>
                        </>
                    )
                } catch (err) {
                    return <>
                        N/A
                        <LinkOverlay index={index}/>
                    </>
                }
            },
        },
        {
            ...getDefaultColSettings('haveTriedIt'),
            title: '% have tried it',
            renderTitle: <>% have <br/>tried it</>,
            Value: ({ index }) => {
                try {
                    return (
                        <>
                            {data[index]["haveTriedIt"]
                                ? `${Math.round(data[index]["haveTriedIt"] || 0)}%`
                                : '-'
                            }
                            <LinkOverlay index={index}/>
                        </>
                    )
                } catch (err) {
                    return <>
                        N/A
                        <LinkOverlay index={index}/>
                    </>
                }
            },
        },
        {
            ...getDefaultColSettings('loveOrLikeIt'),
            title: '% love or like it',
            renderTitle: <>% love or<br/>like it</>,
            Value: ({ index }) => {
                try {
                    return (
                        <>
                            {data[index]["loveOrLikeIt"]
                                ? `${Math.round(data[index]["loveOrLikeIt"] || 0)}%`
                                : '-'
                            }
                            <LinkOverlay index={index}/>
                        </>
                    )
                } catch (err) {
                    return <>
                        N/A
                        <LinkOverlay index={index}/>
                    </>
                }
            },
        },
    ], [columnIcon, handleSort, data, sortingField])
}