import { API__ContactViewModel, API__UserContactViewModel } from "../apiSchema";
import { sortByKey } from "../helpers";
import { iPermissionFields, PermissionSection } from "./PermissionSection";

export interface iContact {
    id: number;
    email: string;
    linkedInProfile: string;
    name: string;
    title: string;
    linkedInProfilePhoto: string;
    department: string;
    date: string;
    phone: string;
    isCustom: boolean;
    isPublic: boolean;
    canEdit: boolean;
}

interface iContactsSectionData extends iPermissionFields {
    data: iContact[];
}

export interface iContactsData {
    fireflyContacts: iContactsSectionData;
    myContacts: iContactsSectionData;
}

export class ContactsData implements iContactsData {
    static defaultData: iContactsData = {
        fireflyContacts: {
            ...new PermissionSection(),
            data: [],
        },
        myContacts: {
            ...new PermissionSection(),
            data: [],
        },
    };

    fireflyContacts = ContactsData.defaultData.fireflyContacts;
    myContacts = ContactsData.defaultData.myContacts;

    constructor(data?: API__ContactViewModel) {
        if (data) {
            this.mapFromApi(data);
        }
    }

    private setData(model: iContactsData) {
        ({
            fireflyContacts: this.fireflyContacts,
            myContacts: this.myContacts
        } = model);
    }

    private mapFromApi(data : API__ContactViewModel) {
        if (data.fireflyContacts.data) {
            data.fireflyContacts.data.sort(sortByKey("isCustom"));
        }
        if (data.myContacts.data) {
            data.myContacts.data.sort(sortByKey("isCustom"));
        }

        this.setData(
            {
                fireflyContacts: {
                    permissionLevel: PermissionSection.getPermissionLevel(data.fireflyContacts.permissionLevel),
                    hasData: data.fireflyContacts.hasData,
                    data: (data.fireflyContacts.data || []).map(c => new Contact(c)),
                },
                myContacts: {
                    permissionLevel: PermissionSection.getPermissionLevel(data.myContacts.permissionLevel),
                    hasData: data.myContacts.hasData,
                    data: (data.myContacts.data || []).map(c => new Contact(c)),
                },
            }
        );
    }
}

export class Contact implements iContact {
    static defaultData: iContact = {
        id: 0,
        email: "",
        linkedInProfile: "",
        name: "",
        title: "",
        linkedInProfilePhoto: "",
        department: "",
        date: "",
        phone: "",
        isCustom: false,
        isPublic: false,
        canEdit: false
    };

    id = Contact.defaultData.id;
    email = Contact.defaultData.email;
    linkedInProfile = Contact.defaultData.linkedInProfile;
    name = Contact.defaultData.name;
    title = Contact.defaultData.title;
    linkedInProfilePhoto = Contact.defaultData.linkedInProfilePhoto;
    department = Contact.defaultData.department;
    date = Contact.defaultData.date;
    phone = Contact.defaultData.phone;
    isCustom = Contact.defaultData.isCustom;
    isPublic = Contact.defaultData.isPublic;
    canEdit = Contact.defaultData.canEdit;

    constructor(data?: API__UserContactViewModel) {
        if (data) {
            this.mapFromApi(data);
        }
    }

    private setData(model: iContact) {
        ({
            id: this.id,
            email: this.email,
            linkedInProfile: this.linkedInProfile,
            name: this.name,
            title: this.title,
            linkedInProfilePhoto: this.linkedInProfilePhoto,
            department: this.department,
            date: this.date,
            phone: this.phone,
            isCustom: this.isCustom,
            isPublic: this.isPublic,
            canEdit: this.canEdit
        } = model);
    }

    private mapFromApi (data: API__UserContactViewModel) {
        this.setData({
            id: data.id,
            email: (data.email || Contact.defaultData.email).trim(),
            linkedInProfile: data.linkedInProfile || Contact.defaultData.linkedInProfile,
            name: data.name || Contact.defaultData.name,
            title: data.title || Contact.defaultData.title,
            linkedInProfilePhoto: data.linkedInProfilePhoto || Contact.defaultData.linkedInProfilePhoto,
            department: data.department || Contact.defaultData.department,
            date: data.date || Contact.defaultData.date,
            phone: data.phone || Contact.defaultData.phone,
            isCustom: data.isCustom,
            isPublic: data.isPublic,
            canEdit: data.canEdit,
        })
    }
}