import { iPermissionFields, PermissionLevelEnum, PermissionSection } from "@models/PermissionSection";
import { API__ChainPermissionWrapper, API__NewPenetrationRootObjectViewModel } from "@apiSchema";

interface iFoodProfileVersatilityData {
    foodVersatility: number;
    menuVersatility: number;
}

export interface iFoodProfileVersatility extends iPermissionFields {
    data: iFoodProfileVersatilityData;
}

type ApiModelType = API__ChainPermissionWrapper<API__NewPenetrationRootObjectViewModel>;

interface iData {
    apiModel?: ApiModelType;
}

export class FoodProfileVersatility implements iFoodProfileVersatility {
    permissionLevel = PermissionLevelEnum.None;
    hasData = false;
    data = FoodProfileVersatility.fakeData;

    static fakeData: iFoodProfileVersatilityData = {
        foodVersatility: 0,
        menuVersatility: 0,
    };

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private setData(model: iFoodProfileVersatility) {
        ({
            permissionLevel: this.permissionLevel,
            hasData: this.hasData,
            data: this.data,
        } = model);
    }

    private mapFromApi(rawData: ApiModelType) {
        const { data } = rawData;
        if (data) {
            const {
                penetrationItems: {
                    all: {
                        foodVersatility,
                        menuVersatility,
                    },
                },
            } = data;

            this.setData({
                permissionLevel: PermissionSection.getPermissionLevel(rawData.permissionLevel),
                hasData: rawData.hasData && foodVersatility > 0 && menuVersatility > 0,
                data: {
                    menuVersatility,
                    foodVersatility,
                }
            });
        }

    }
}