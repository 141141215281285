import { FC, useCallback } from "react";
import Page from "@containers/Page/Page";
import PageContent from "@containers/PageContent/PageContent";
import useQueryParam from "@hooks/useQueryParam";
import '@pages/FoodProfile/FoodProfile.scss';
import FoodProfileContent from "@pages/FoodProfile/FoodProfileContent";
import { routeNames } from "@core/routeNames";
import { useHistory } from "react-router-dom";


const FoodProfile: FC = () => {
    const history = useHistory();
    const searchQuery = useQueryParam("q");

    const onNoData = useCallback(
        () => {
            history.push(`${routeNames.search}?q=${searchQuery}`);
        },
        [searchQuery]
    );

    return (
        /* @ts-ignore */
        <Page>
            <PageContent>
                <FoodProfileContent onNoData={onNoData}/>
            </PageContent>
        </Page>
    );
}

export default FoodProfile;