import ReactDOM from 'react-dom';
import { Component } from 'react';
import PT from 'prop-types';

class SelectType extends Component {
    constructor(props) {
        super(props);

        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.close = this.close.bind(this);
        this.setRef = this.setRef.bind(this);
        this.renderOption = this.renderOption.bind(this);
        this.handleClickOption = this.handleClickOption.bind(this);
    }

    componentDidMount() {
        this.dropDown.focus();
        this.optionBinding();
        document.addEventListener('click', this.close, true);
        document.addEventListener('keydown', this.handleKeyDown, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.close, true);
        document.removeEventListener('keydown', this.handleKeyDown, true);
        this.optionUnbinding();
    }

    optionBinding() {
        const focused = 'custom-drop-down__option--focus';
        const collection = document.getElementsByClassName('custom-drop-down__option');
        const array = Array.prototype.slice.call(collection);
        array.forEach((item) => {
            item.addEventListener('mousemove', () => {
                array.forEach((item) => item.classList.remove(focused));
                item.classList.add(focused);
            }, true);
        });
    }

    optionUnbinding() {
        const focused = 'custom-drop-down__option--focus';
        const collection = document.getElementsByClassName('custom-drop-down__option');
        const array = Array.prototype.slice.call(collection);
        array.forEach((item) => {
            item.removeEventListener('mousemove', () => {
                array.forEach(item => item.classList.remove('custom-drop-down__option--focus'));
                item.classList.add(focused);
            }, true);
        });
    }

    handleKeyDown(e = false) {
        const focused = 'custom-drop-down__option--focus';
        const option = 'custom-drop-down__option';
        const current = document.getElementsByClassName(focused)[0];
        const prev = current.previousSibling;
        const next = current.nextSibling;

        switch (e.code) {

            case 'Escape':
                this.props.close();
                e.stopPropagation();
                break;

            case 'ArrowUp':
                current.classList.remove(focused);
                prev ?
                    prev.classList.add(focused) :
                    document.getElementsByClassName(option)[document.getElementsByClassName(option).length - 1]
                        .classList.add(focused);
                break;

            case 'ArrowDown':
                current.classList.remove(focused);
                if (next) {
                    next.classList.add(focused);
                } else {
                    document.getElementsByClassName('custom-drop-down__option')[0].classList.add(focused);
                }
                break;

            case 'Enter':
                this.handleClickOption(parseInt(current.dataset.value));
                break;

            default:
                break;
        }
    }

    close(e = false) {
        if (!e || !ReactDOM.findDOMNode(this.dropDown).contains(e.target)) {
            this.props.close();
        }
    }

    handleClickOption(value) {
        const { setValue } = this.props;
        setValue(value);
    }

    renderOption(option, index) {
        const { value = null } = this.props;
        const isChecked = value === option.value;
        const classList = new Set(['custom-drop-down__option']);

        if (isChecked) {
            classList.add('custom-drop-down__option--active custom-drop-down__option--focus');
        }

        return (
            <div
                className={Array.from(classList).join(' ')}
                key={index}
                data-value={option.value}
                onClick={() => this.handleClickOption(option.value)}
                data-testid={`buttonSelectDocumentTypeOption-${index}`}
            >
                {option.title}
            </div>
        );
    }

    renderOptions() {
        const { options } = this.props;
        const filteredOptions = options
            .concat([])
            .sort((a, b) => {
                if (a.title.toLowerCase() === 'research report') {
                    return -1;
                }
                if (b.title.toLowerCase() === 'research report') {
                    return 1;
                }
                return a.title < b.title ? -1 : 1;
            });

        return (
            <div className="custom-drop-down__options custom-drop-down__options--small">
                {filteredOptions.map(this.renderOption)}
            </div>
        );
    }

    setRef(dropDown) {
        this.dropDown = dropDown;
    }

    render() {
        const classList = new Set(['custom-drop-down', 'custom-drop-down__type']);

        return (
            <div
                ref={this.setRef}
                className={Array.from(classList).join(' ')}
                data-testid="selectDocumentType"
            >
                <div className="custom-drop-down__main custom-drop-down__main--small">
                    {this.renderOptions()}
                </div>
            </div>
        );
    }
}

SelectType.propTypes = {
    options: PT.array,
    multiple: PT.bool,
    value: PT.oneOfType([
        PT.array,
        PT.string,
        PT.number
    ]),
    setValue: PT.func,
    placeholder: PT.string,
    search: PT.bool,
    checkAll: PT.bool,
    clear: PT.bool,
    className: PT.string,
    type: PT.string,
    createNew: PT.func,
    createNewWhenNotFound: PT.bool
};

export default SelectType;
