import { Component } from 'react';
import { connect } from 'react-redux';
import {
    setActivePackage,
    closePackage,
    removePackageFromList,
    open,
    close,
} from '../../actions/packages.actions';
import {
    setFileLoadStart,
    setFileLoaded } from '../../actions/documents.actions';
import { addNotification } from '../../components/GlobalNotification/globalNotification.actions';
import { getCurrentPackage, packagesTableSelector } from '../../selectors/packages.selectors';
import SpriteIcon from '../../components/SpriteIcon/SpriteIcon';
import Table from '../../components/Table/Table';
import PackagesSharePopup from '../../containers/PageHeader/PackagesSharePopup';
import PackageConfirmDelete from './PackageConfirmDelete';
import { monthValues } from '../../core/old_helpers';

const emailEntity = {
    single: 1,
    group: 2,
    custom: 3
};

class EditPackage extends Component {
    constructor(props) {
        super(props);

        this.openSharePopup = this.openSharePopup.bind(this);
        this.closeSharePopup = this.closeSharePopup.bind(this);
        this.state = {
            sharePopupOpen: false,
            deletePopupOpen: false,
            itemToDelete: {},
            deleteType: null
        };
    }

    openSharePopup() {
        this.props.open();
        this.setState({
            sharePopupOpen: true
        });
        document.body.style.overflow = 'hidden';
    }

    closeSharePopup() {
        this.props.close();
        this.setState({
            sharePopupOpen: false
        });
        document.body.style.overflow = '';
    }

    openDeletePackagePopup(item) {
        this.props.open();
        this.setState({ deletePopupOpen: true, itemToDelete: item, deleteType: 'packageDelete' });
    }

    openDeleteItemPackagePopup(item) {
        this.props.removePackageFromList(item.id, this.props.currentPackage.id);
        // const composedItem = {
        //     ...item,
        //     packageId: this.props.currentPackage.id
        // };
        // this.setState({ deletePopupOpen: true, itemToDelete: composedItem, deleteType: 'packageItemDeleteFromList' });
    }

    closeDeletePopup() {
        this.props.close();
        this.setState({ deletePopupOpen: false, itemToDelete: {}, deleteType: null });
    }

    immitateLoading() {
        this.props.showNotification({text: 'Preparing your package...', duration: 4000});
        this.props.showLoadingLine();
        setTimeout(() => {
            this.props.hideLoadingLine();
        }, 4000);
    }

    render() {
        const { currentPackage, packagesTableSelector } = this.props;
        const packageDate = new Date(currentPackage.createDate);
        return (
            <div className="package-info">
                <div className="package-info__section">
                    <div className="package-info__main-info">
                        <p className="package-info__name">
                            {currentPackage.name}
                        </p>
                        <p className="package-info__creator-info">
                            {`${currentPackage.items.length} files
                        ◦ Created ${`${packageDate.getDate()}  ${monthValues[packageDate.getMonth()]} ${packageDate.getFullYear()}`}`}
                            {/*<span className="package-info__author">*/}
                            {/*{currentPackage.author}*/}
                            {/*</span>*/}
                        </p>
                    </div>
                    <div className="package-info__controls">
                        {currentPackage.isActive &&
                        <button className="header-packages__link-button header-packages__link-button--package-list-item"
                            onClick={this.props.closePackage.bind(this)}>
                            Close package
                        </button>}
                        <button className="packages__button packages__button--send"
                            title="Share the contents of this package with your colleagues."
                            onClick={this.openSharePopup}>
                            send package
                        </button>
                        <a href={`${process.env.REACT_APP_API}Package/DownloadPackage?packageid=${currentPackage.id}`}
                            title="Download all of the items in this package"
                            className="header-packages__link-button header-packages__link-button--underline  header-packages__link-button--package-list-item"
                            onClick={this.immitateLoading.bind(this)}
                        >
                            download package
                        </a>
                        {!currentPackage.isActive &&
                        <div onClick={this.props.setActivePackage.bind(this, currentPackage.id)}
                            title="Make this your current active package. You can then add other items to this package."
                            className="package-info__action font-text">
                            <SpriteIcon className="package-info__icon"
                                iconId="edit"
                            />
                            OPEN
                        </div>}
                        <div onClick={this.openDeletePackagePopup.bind(this, currentPackage.id)}
                            className="package-info__action font-text">
                            <SpriteIcon className="package-info__icon"
                                iconId="delete"
                            />
                            DELETE
                        </div>
                    </div>
                </div>
                {currentPackage.items.length ? (
                        <div className="package-info__section">
                            <Table {...packagesTableSelector}
                                controlsOverlay={true}
                                removePackageItem={this.openDeleteItemPackagePopup.bind(this)}
                                className="table-container--packages-page"
                                sortType="client"
                                showCopyButton={false}
                                showNotification={this.props.showNotification}
                            >
                            </Table>
                        </div>
                    )
                    : null
                }
                {currentPackage.emails.length ? (
                        <div className="package-info__section">
                            <p className="package-info__section-header">
                                DELIVERED TO
                            </p>
                        </div>
                    )
                    : null
                }
                {currentPackage.emails.length ? (
                        <div className="package-info__section package-info__section--emails">
                            {currentPackage.emails.map((elem, index) => {
                                if (elem.type === emailEntity.single) {
                                    elem.emailSubTitle = elem.emailSubTitle || 'Name';
                                }

                                const Abbr = elem.type === emailEntity.single ? elem.emailSubTitle.match(/\b(\w)/g).slice(0, 2).join('') : elem.email.substr(0, 1);
                                return (<div
                                    className="popup__suggest-item popup__suggest-item--packages-page"
                                    key={index}
                                >
                                    <div
                                        className={`popup__suggest-circle ${elem.groupId && 'popup__suggest-circle--group'} ${(elem.type === emailEntity.custom) && 'popup__suggest-circle--custom'}`}>
                                        {Abbr}
                                    </div>
                                    {
                                        elem.groupId !== 0 ? (
                                            <div>
                                                <div className="popup__suggest-name">
                                                    {elem.email}
                                                </div>
                                                <div className="popup__suggest-email">
                                                    {elem.emailSubTitle}
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="popup__suggest-name">
                                                    {elem.emailSubTitle}
                                                </div>
                                                <div className="popup__suggest-email">
                                                    {elem.email}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>);
                            })}
                        </div>
                    )
                    : null
                }
                <PackagesSharePopup
                    activePackage={currentPackage}
                    closeSharePopup={this.closeSharePopup}
                    sharePopupOpen={this.state.sharePopupOpen}
                    filesCount={currentPackage.items.length}
                    packageName={currentPackage.name}
                />
                {/* TODO rewrite this shit !!! */}
                <PackageConfirmDelete
                    deletePopupOpen={this.state.deletePopupOpen}
                    item={this.state.itemToDelete}
                    removePackage={this.props.deletePackage}
                    deleteType={this.state.deleteType}
                    deletePopupClose={this.closeDeletePopup.bind(this)}/>
            </div>
        );
    }
}



const mapStateToProps = (state, props) => ({
    currentPackage: getCurrentPackage(state, props),
    packagesTableSelector: packagesTableSelector(state, props)
});

const mapDispatchToProps = dispatch => ({
    setActivePackage: id => dispatch(setActivePackage(id)),
    closePackage: () => dispatch(closePackage()),
    showNotification: text => dispatch(addNotification(text)),
    showLoadingLine: () => dispatch(setFileLoadStart()),
    hideLoadingLine: () => dispatch(setFileLoaded()),
    removePackageFromList: (id, packageId) => dispatch(removePackageFromList(id, packageId)),
    open: () => dispatch(open()),
    close: () => dispatch(close()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPackage);
