import { API__ContactViewModel } from "../apiSchema";
import Http from "../fetch";
import { useData } from "../hooks/useData";
import { ContactsData, iContactsData } from "../models/Contact";

async function getContacts(chainId: string): Promise<iContactsData> {
    const response: API__ContactViewModel = await Http.get("get chain contacts", { chainId });
    return new ContactsData(response);
}

export default function useContacts(chainId: string) {
    return useData(new ContactsData(), getContacts, chainId);
}
