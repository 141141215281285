import { createSelector } from 'reselect';
import { monthValues, capitalize } from '../../core/old_helpers';
import getFileSize from '../../core/getFileSize';
import getFileTypeIcon from '../../core/getFileTypeIcon';
import { companiesSelector } from '../../containers/DocumentsLibraryPopups/documentsLibraryPopups.selectors';
import { isImpersonateModeSelector } from '../../selectors/page';

const pageData = state => state.projectData;

export const documentsSelector = createSelector(
    pageData,
    data => data.documents
        .filter((document) => {
            if (data.companyId === 1 && data.customerViewCompanyId === 0) {
                if (data.isShareMode) {
                    if (data.isShared) {
                        return document.isShared;
                    }
                    return false;
                }
                return document.isInternal;
            }
            return true;
        })
        .map((document) => {
            const { createdOn, documentType, projectsName = '', fileSize = 0 } = document;
            let subTitle = '';
            if (projectsName.length > 0) {
                subTitle += `${projectsName.reduce(
                    (acc, name, index) => {
                        if (index === 0) return name;
                        return `${acc} | ${name}`;
                    }, '')} ◦ `;
            }

            if (documentType) {
                subTitle += `${documentType.name} ◦ `;
            }

            if (createdOn) {
                const date = new Date(createdOn);
                const month = 1 + monthValues[date.getMonth()];
                const year = date.getFullYear();
                subTitle += `${month} ${year} ◦ `;
            }
            const checkedSize = getFileSize(fileSize);

            subTitle += `${checkedSize.value.toFixed(1)} ${checkedSize.units}`;
            return {
                ...document,
                subTitle
            };
        })
);

export const TABLE_COLUMNS_SETTINGS = [
    { label: 'File name', align: 'left', sorting: 'abc' },
    { label: 'Document type', sorting: 'abc' },
    { label: 'Date', sorting: 'date' },
    { label: 'Added by', sorting: 'abc' },
];

const userCompanyIdSelector = state => state.documentsLibraryPopupsData.currentUserCompanyId;

export const TABLE_COLUMNS_SETTINGS_DS = [
    { label: 'File name', align: 'left', sorting: 'abc' },
    { label: 'Document type', sorting: 'abc' },
    { label: 'Date', sorting: 'date' },
    { label: 'Shared with', sorting: 'num' },
    { label: 'Added by', sorting: 'abc' },
];

export const documentsTableSelector = createSelector(
    documentsSelector,
    userCompanyIdSelector,
    companiesSelector,
    isImpersonateModeSelector,
    (documents, companyId, companies, isImpersonateMode) => ({
        settings: companyId === 1 ? TABLE_COLUMNS_SETTINGS_DS : TABLE_COLUMNS_SETTINGS,
        data: documents.map((document) => {
            const date = new Date(document.createdOn);
            let day = date.getDate();
            let month = 1 + date.getMonth();
            const year = date.getFullYear();
            const fileSize = getFileSize(document.fileSize || 0);

            if (day < 10) day = `0${day}`;
            if (month < 10) month = `0${month}`;

            let sharedWithName = '--';
            const companiesCount = document.companyIds.length;
            if (companies.length > 1 && companiesCount) {
                sharedWithName = companiesCount > 1
                    ? `${companiesCount} companies`
                    : companies.find(({ value }) => value === document.companyIds[0]).title;
            }
            const sharedWithList = companies.filter(
                company => document.companyIds.includes(company.value) && +company.value !== 1
            );

            if (companyId === 1) {
                return [
                    {
                        type: 'documents library document',
                        value: document.title,
                        data: document,
                        icon: document.docTypeString ? getFileTypeIcon(document.docTypeString.substr(1)) : 'file-other',
                        downloadLink: `${process.env.REACT_APP_API}${document.downloadLink}`,
                        size: `${fileSize.value.toFixed(1)} ${fileSize.units}`,
                        width: 30,
                        height: 36,
                        showFooter: !isImpersonateMode,
                    },
                    document.documentType.name,
                    {
                        type: 'date',
                        name: isNaN(year) ? '--/--/----' : `${month}/${day}/${year}`,
                        value: isNaN(year) ? '--/--/----' : `${month}/${day}/${year}`
                    },
                    {
                        type: 'tooltip',
                        name: sharedWithName,
                        value: companiesCount,
                        data: companiesCount > 1 && sharedWithList
                    },
                    capitalize(document.createdByUserName),
                ];
            }

            return [
                {
                    type: 'documents library document',
                    value: document.title,
                    data: document,
                    icon: document.docTypeString ? getFileTypeIcon(document.docTypeString.substr(1)) : 'file-other',
                    size: `${fileSize.value.toFixed(1)} ${fileSize.units}`,
                    downloadLink: `${process.env.REACT_APP_API}${document.downloadLink}`,
                    width: 30,
                    height: 36,
                    showFooter: !isImpersonateMode,
                },
                document.documentType.name,
                {
                    type: 'date',
                    name: isNaN(year) ? '--/--/----' : `${month}/${day}/${year}`,
                    value: isNaN(year) ? '--/--/----' : `${month}/${day}/${year}`
                },
                capitalize(document.createdByUserName),
            ];
        })
    })
);
