import { iPermissionFields, PermissionLevelEnum, PermissionSection } from "@models/PermissionSection";
import {
    API__ChainPermissionWrapper, API__FlavorRespondentGroupType,
    API__FlavorWordInfo,
    API__NormsViewModel
} from "@apiSchema";
import { ID } from "@models/index";

export interface iNormValue {
    raw: number;
    norm: number;
}

export interface iNorm {
    id: ID;
    title: string;
    affinityValue: iNormValue;
    experienceValue: iNormValue;
}

export interface iAffinityNormsGroup {
    title: string;
    norms: iNorm[];
}

const AFFINITY_GROUPS_CONFIG: Array<{
    title: string;
    norms: Array<{
        title: string;
        field: keyof API__NormsViewModel;
    }>;
}> = [
    {
        title: '',
        norms: [
            { field: 'wholeUs', title: 'Total US' }
        ]
    },
    {
        title: 'Gender',
        norms: [
            { field: 'female', title: 'Female' },
            { field: 'male', title: 'Male' }
        ]
    },
    {
        title: 'Generation',
        norms: [
            { field: 'genZ', title: 'Gen Z' },
            { field: 'millennials', title: 'Millennials' },
            { field: 'genX', title: 'Gen X' },
            { field: 'boomers', title: 'Boomers' }
        ]
    },
    {
        title: 'Ethnicity',
        norms: [
            { field: 'white', title: 'White' },
            { field: 'black', title: 'Black' },
            { field: 'hispanic', title: 'Hispanic' },
            { field: 'asian', title: 'Asian' }
        ]
    },
    {
        title: 'Region',
        norms: [
            { field: 'west', title: 'West' },
            { field: 'midwest', title: 'Midwest' },
            { field: 'south', title: 'South' },
            { field: 'northeast', title: 'North East' },
        ],
    },
];

const AFFINITY_TYPES_IDS: ID[] = [
    1,  // Total US
    3,  // Female
    2,  // Male
    4,  // Gen Z
    5,  // Millennials
    6,  // Gen X
    7,  // Boomers
    8,  // White
    9,  // Black
    10, // Hispanic
    11, // Asian
    12, // West
    13, // Midwest
    15, // South
    14, // North East
]

export interface iFoodProfileAffinityData {
    loveOrLikeIt: number;
    knowIt: number;
    haveTriedIt: number;
    url: string;
    normsGroups: iAffinityNormsGroup[];
}

export interface iFoodProfileAffinity extends iPermissionFields {
    data: iFoodProfileAffinityData;
}

type ApiModel = API__ChainPermissionWrapper<API__FlavorWordInfo>;

interface iData {
    apiModel?: ApiModel;
}

export class FoodProfileAffinity implements iFoodProfileAffinity {
    static defaultData: iFoodProfileAffinityData = {
        loveOrLikeIt: 0,
        knowIt: 0,
        haveTriedIt: 0,
        url: '',
        normsGroups: [],
    };

    permissionLevel = PermissionLevelEnum.None;
    hasData = false;
    data = FoodProfileAffinity.defaultData;

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private setData(model: iFoodProfileAffinity) {
        ({
            permissionLevel: this.permissionLevel,
            hasData: this.hasData,
            data: this.data,
        } = model);
    }

    private mapFromApi (rawData: ApiModel) {
        let parsedData = FoodProfileAffinity.defaultData;

        if (rawData.hasData && rawData.data) {
            const {
                config: {
                    respondentGroupTypeInfo,
                },
                data: {
                    keywordUrl,
                    loveLikeIt,
                    knowIt,
                    haveTriedIt,
                },
            } = rawData.data;

            const filteredGroupTypes: API__FlavorRespondentGroupType[] = respondentGroupTypeInfo
                .map(group => ({
                    groupName: group.groupName,
                    types: group.types.filter(item => AFFINITY_TYPES_IDS.includes(item.id)),
                }))
                .filter(group => group.types.length > 0);

            parsedData = {
                knowIt: knowIt.generalData.raw,
                haveTriedIt: haveTriedIt.generalData.raw,
                loveOrLikeIt: loveLikeIt.generalData.raw,
                url: keywordUrl,
                normsGroups: filteredGroupTypes.map(group => ({
                    title: group.groupName,
                    norms: group.types.map(item => ({
                        id: item.id,
                        title: item.name,
                        affinityValue: loveLikeIt.dataByRespondentType.find(i => i.respondentTypeId === item.id)!.data,
                        experienceValue: knowIt.dataByRespondentType.find(i => i.respondentTypeId === item.id)!.data,
                    })),
                })),
            };

            parsedData.normsGroups.forEach(({ norms }) => {
                norms.sort((norm1, norm2) => {
                    const norm1Index = AFFINITY_TYPES_IDS.findIndex(id => norm1.id === id);
                    const norm2Index = AFFINITY_TYPES_IDS.findIndex(id => norm2.id === id);
                    return norm1Index <= norm2Index ? -1 : 1;
                });
            })
        }

        this.setData({
            permissionLevel: PermissionSection.getPermissionLevel(rawData.permissionLevel),
            hasData: rawData.hasData,
            data: parsedData,
        });
    }
}