import React, { FC, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import "./LazyComponent.styles.scss";

const FadeInDirection: React.FC<{ isVisible: boolean, style: any }> = ({ style, isVisible, children }) => {
    return <div style={style} className={`FadeIn ${isVisible ? "is-visible" : "is-hidden"}`}>{children}</div>;
};


const LazyComponent: FC<{ fadeIn?: boolean; style?: React.CSSProperties }> = ({ style, fadeIn = false, children }) => {
    const [isVisible, setVisibility] = useState(false);

    const onChange = (isVisibleSensor: boolean) => {
        if (isVisible) return;
        setVisibility(isVisibleSensor);
    };

    return (
        <VisibilitySensor onChange={onChange} partialVisibility active={!isVisible}>
            <>
                {!isVisible && <div style={{ minHeight: 300 }} />}

                {!fadeIn && isVisible && children}
                { fadeIn && <FadeInDirection style={style} isVisible={isVisible}>{children}</FadeInDirection>}

            </>
        </VisibilitySensor>
    );
};

export default LazyComponent;
