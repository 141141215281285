import {
    DOCUMENTS_FETCH_DATA_STORE,
    DOCUMENTS_FETCH_DATA_STARTED,
    DOCUMENTS_FETCH_DATA_LOADED,
    DOCUMENTS_FETCH_MORE_DATA_STARTED,
    DOCUMENTS_FETCH_MORE_DATA_LOADED,
    DOCUMENTS_FETCH_DATA_FULL_LOADED,
    DOCUMENTS_CLEAR_DATA_STORE,
    DOCUMENTS_SHARE_ITEM_INIT,
    DOCUMENTS_SHARE_ITEM_CANCEL,
    DOCUMENTS_SHARE_EMAILS_FETCH_DATA_STORE,
    DOCUMENTS_NOTIFICATION_SHOW,
    DOCUMENTS_NOTIFICATION_HIDE,
    DOCUMENTS_CHOP_LINK_STORE,
    DOCUMENTS_CHOP_LINK_LOAD_STARTED,
    DOCUMENTS_CHOP_LINK_LOAD_FINISHED,
    DOCUMENTS_LINK_LOADING,
    DOCUMENTS_LINK_LOADING_FINISHED,
    DOCUMENTS_BY_PUBLICATION_FAMILY_FETCH_DATA_STARTED,
    DOCUMENTS_BY_PUBLICATION_FAMILY_FETCH_DATA_LOADED,
    DOCUMENTS_BY_PUBLICATION_FAMILY_FETCH_DATA_STORE,
    DOCUMENTS_SAVE_SEARCH_STRING,
    CLEAR_SEARCH_RESULTS_STRING,
    DOCUMENTS_PURCHASE_START,
    DOCUMENTS_PURCHASE_END,
    DOCUMENTS_PURCHASE_MADE,
    DOCUMENTS_CLEAR_PURCHASE_MADE,
    DOCUMENTS_INCREASE_COUNT_TO_SHOW,
    DOCUMENTS_RESET_COUNT_TO_SHOW,
    DOCUMENTS_SET_EXPANDED_REPORT_TILE,
    DOCUMENTS_SET_DATE_FILTER,
} from '../actions/actionsNamesList';

const MAX_ITEMS_TO_SHOW = 45;

const defaultState = {
    expandedTileId: null,
    data: {
        Report: {
            items: [],
            isFullLoaded: false
        },
        Mac: {
            items: [],
            isFullLoaded: false
        },
        Document: {
            items: [],
            isFullLoaded: false
        },
        mtdfoodprofile: {
            items: [],
            isFullLoaded: false
        },
        Trendspotting: {
            items: [],
            isFullLoaded: false
        }
    },
    share: {
        isOpen: false,
        title: '',
        id: null,
        documentType: null,
        shareLinkString: null,
        publicationFamily: null,
        emails: []
    },
    notification: false,
    notificationText: '',
    notificationVisible: false,
    fileURL: '',
    downloadingFile: false,
    downloadingChop: false,
    dataByFamily: [],
    loading: false,
    loadingMore: false,
    documentsData: '',
    justPurchased: false,
    purchasing: false,
    maxToShow: MAX_ITEMS_TO_SHOW,
    selectedFilter: {
        id: 5,
        months: null,
    },
};

export default function documentsData(state = defaultState, action) {
    switch (action.type) {
        case DOCUMENTS_SET_EXPANDED_REPORT_TILE: {
            return {
                ...state,
                expandedTileId: action.id
            };
        }
        case DOCUMENTS_PURCHASE_START: {
            return {
                ...state,
                purchasing: true
            };
        }
        case DOCUMENTS_PURCHASE_END: {
            return {
                ...state,
                purchasing: false
            };
        }
        case DOCUMENTS_PURCHASE_MADE: {
            return {
                ...state,
                justPurchased: true
            };
        }
        case DOCUMENTS_CLEAR_PURCHASE_MADE: {
            return {
                ...state,
                justPurchased: false
            };
        }
        case DOCUMENTS_NOTIFICATION_SHOW: {
            return {
                ...state,
                notification: true,
                notificationText: action.text
            };
        }
        case DOCUMENTS_NOTIFICATION_HIDE: {
            return {
                ...state,
                notification: false,
                notificationText: ''
            };
        }
        case DOCUMENTS_SHARE_ITEM_INIT: {
            return {
                ...state,
                share: {
                    ...state.share,
                    isOpen: true,
                    title: action.params.title,
                    id: action.params.id,
                    documentType: action.params.documentType,
                    shareLinkString: action.params.shareLinkString,
                    publicationFamily: action.params.publicationFamily
                }

            };
        }
        case DOCUMENTS_SHARE_ITEM_CANCEL: {
            return {
                ...state,
                share: {
                    ...state.share,
                    isOpen: false,
                    title: '',
                    id: null,
                    documentType: null,
                    shareLinkString: null,
                    publicationFamily: null,
                }
            };
        }
        case DOCUMENTS_SHARE_EMAILS_FETCH_DATA_STORE: {
            return {
                ...state,
                share: {
                    ...state.share,
                    emails: action.data
                }
            };
        }
        case DOCUMENTS_FETCH_DATA_STARTED: {
            return {
                ...state,
                loading: true
            };
        }
        case DOCUMENTS_FETCH_DATA_LOADED: {
            return {
                ...state,
                loading: false
            };
        }
        case DOCUMENTS_FETCH_DATA_FULL_LOADED: {
            const newState = {
                ...state
            };
            newState.data[action.doctype].isFullLoaded = true;
            return newState;
        }
        case DOCUMENTS_FETCH_DATA_STORE: {
            const newState = {
                ...state,
                data: {
                    ...state.data
                }
            };
            for (let docType in action.data) {
                if (action.data.hasOwnProperty(docType)) {
                    newState.data[docType] = {
                        ...state.data[docType],
                        ...action.data[docType],
                        items: [
                            ...action.data[docType].items
                        ],
                        isFullLoaded: state.data[docType].isFullLoaded
                    };
                }
            }
            return newState;
        }
        case DOCUMENTS_INCREASE_COUNT_TO_SHOW: {
            const newState = {
                ...state,
                maxToShow: state.maxToShow += MAX_ITEMS_TO_SHOW,
                data: {
                    ...state.data
                }
            };
            // newState.data[action.data.docType].maxToShow += MAX_ITEMS_TO_SHOW;
            return newState;
        }
        case DOCUMENTS_RESET_COUNT_TO_SHOW: {
            const newState = {
                ...state,
                maxToShow: MAX_ITEMS_TO_SHOW,
                data: {
                    ...state.data
                }
            };
            // newState.data[action.data.docType].maxToShow = MAX_ITEMS_TO_SHOW;
            return newState;
        }
        case DOCUMENTS_CLEAR_DATA_STORE: {
            return {
                ...state,
                data: {
                    Report: {
                        items: [],
                        isFullLoaded: false
                    },
                    Mac: {
                        items: [],
                        isFullLoaded: false
                    },
                    Document: {
                        items: [],
                        isFullLoaded: false
                    },
                    mtdfoodprofile: {
                        items: [],
                        isFullLoaded: false
                    },
                    Trendspotting: {
                        items: [],
                        isFullLoaded: false
                    }
                }
            };
        }
        case DOCUMENTS_FETCH_MORE_DATA_STARTED: {
            return {
                ...state,
                loadingMore: true
            };
        }
        case DOCUMENTS_FETCH_MORE_DATA_LOADED: {
            return {
                ...state,
                loadingMore: false
            };
        }
        case DOCUMENTS_CHOP_LINK_STORE: {
            return {
                ...state,
                fileURL: action.url
            };
        }
        case DOCUMENTS_CHOP_LINK_LOAD_STARTED: {
            return {
                ...state,
                downloadingFile: true,
                downloadingChop: true
            };
        }
        case DOCUMENTS_CHOP_LINK_LOAD_FINISHED: {
            return {
                ...state,
                downloadingFile: false,
                downloadingChop: false
            };
        }
        case DOCUMENTS_LINK_LOADING: {
            return {
                ...state,
                downloadingFile: true
            };
        }
        case DOCUMENTS_LINK_LOADING_FINISHED: {
            return {
                ...state,
                downloadingFile: false
            };
        }
        case DOCUMENTS_BY_PUBLICATION_FAMILY_FETCH_DATA_STARTED: {
            return {
                ...state,
                loading: true
            };
        }
        case DOCUMENTS_BY_PUBLICATION_FAMILY_FETCH_DATA_LOADED: {
            return {
                ...state,
                loading: false
            };
        }
        case DOCUMENTS_BY_PUBLICATION_FAMILY_FETCH_DATA_STORE: {
            return {
                ...state,
                dataByFamily: action.data
            };
        }
        case DOCUMENTS_SAVE_SEARCH_STRING: {
            return {
                ...state,
                searchString: action.query
            };
        }
        case CLEAR_SEARCH_RESULTS_STRING: {
            return {
                ...state,
                searchString: ''
            };
        }
        case DOCUMENTS_SET_DATE_FILTER: {
            return {
                ...state,
                selectedFilter: action.data,
            };
        }
        default: {
            return state;
        }
    }
}
