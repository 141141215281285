import React, { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { renderVerticalThumb } from "../ScrollbarThumb/ScrollbarThumb";
import "./Textarea.styles.scss";

interface iProps {
    placeholder?: string;
    value: string;
    onChange: (value: string) => void;
    isDisabled?: boolean;
    maxHeight?: number;
    minHeight?: number;
    hasError?: boolean;
    autoFocus?: boolean;
    caretAtEnd?: boolean;
    styles?: {
        container?: React.CSSProperties,
        scrollbar?: React.CSSProperties,
        textarea?: React.CSSProperties,
    };
}

const Textarea: React.FC<iProps> = ({
    onChange,
    placeholder = "",
    value,
    isDisabled,
    maxHeight,
    minHeight = 0,
    hasError,
    autoFocus,
    styles = {},
    caretAtEnd,
}) => {
    const SCROLLBAR_WIDTH = 6;
    const MAX_HEIGHT = maxHeight || 170;
    const INITIAL_HEIGHT = MAX_HEIGHT - SCROLLBAR_WIDTH;
    const [height, setHeight] = useState(INITIAL_HEIGHT);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const textareaVisibleRef = useRef<HTMLTextAreaElement>(null);
    const scrollbarRef = useRef<Scrollbars>(null);
    const [isFocused, setIsFocused] = useState(false);

    const handleChange = (elem: (HTMLTextAreaElement)) => {
        onChange(elem.value);

        if (textareaRef.current) {
            textareaRef.current.value = elem.value;
            const currentHeight = elem ? textareaRef.current.scrollHeight : INITIAL_HEIGHT;
            setHeight(currentHeight);
        }
    };

    useEffect(() => {
        if ( textareaVisibleRef.current && caretAtEnd ) {
            textareaVisibleRef.current.setSelectionRange(value.length, value.length);
        }

        if (textareaRef.current && value) {
            textareaRef.current.value = value;
            const currentHeight = textareaRef.current.scrollHeight;
            setHeight(currentHeight);
        }

        setTimeout(() => {
            if ( scrollbarRef.current && caretAtEnd ) {
                scrollbarRef.current.scrollToBottom();
            }
        });
    }, [caretAtEnd, value]);

    return (
        <div
            className={`Textarea ${hasError ? "Textarea--has-error" : ""}`}
            style={{ height: MAX_HEIGHT, ...styles.container }}
        >
            <Scrollbars
                className={`Textarea__scrollbars ${isFocused ? "is-focus" : ""}`}
                autoHeight
                autoHeightMin={minHeight || MAX_HEIGHT}
                autoHeightMax={MAX_HEIGHT}
                renderThumbVertical={renderVerticalThumb}
                style={{ ...styles.scrollbar }}
                ref={scrollbarRef}
                hideTracksWhenNotNeeded
            >
                <textarea
                    className="Textarea__input is-visible"
                    style={{
                        height: value ? height : minHeight,
                        minHeight: minHeight ? minHeight - SCROLLBAR_WIDTH : 0,
                        ...styles.textarea
                    }}
                    placeholder={placeholder}
                    onChange={e => handleChange(e.target as HTMLTextAreaElement)}
                    onPaste={e => handleChange(e.target as HTMLTextAreaElement)}
                    value={value}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    disabled={isDisabled}
                    spellCheck={false}
                    autoFocus={autoFocus}
                    ref={textareaVisibleRef}
                />
            </Scrollbars>

            <textarea
                ref={textareaRef}
                className="Textarea__input is-hidden"
                disabled={isDisabled}
                rows={1}
                style={{
                    ...styles.textarea,
                    minHeight: minHeight ? minHeight : 0,
                }}
            />
        </div>
    );
};

export default Textarea;
