import { FC, useMemo } from "react";
import Table from "../../../../../components/new/Table/Table";
import { iTableConfigItem } from "../../../../../components/new/Table/Table.helpers";
import { getFormattedCurrency } from "../../../../../core/helpers";
import { highlightCellStyles } from "../../Locations/Locations.helpers";
import "./MenuDetails.styles.scss";
import ButtonGrid from "./ButtonGrid/ButtonGrid";
import { iMenuDetailsItem } from "../../../../../core/models/MenuDetailsItem";
import { getClipboardData } from "../Menu.helpers";
import { CopyButtonContext } from "../../../../../components/new/Table/CopyButton";

interface iProps {
    data: iMenuDetailsItem[];
    setSelectedButton: (id: string) => void;
    selectedButton: string;
}

const MenuDetails: FC<iProps> = ({ data, selectedButton, setSelectedButton }) => {
    const tableConfig: iTableConfigItem[] = useMemo(() => {
        return [
            {
                title: "Categories",
                Value: ({ index }) => <>{data[index].title}</>,
            },
            {
                title: "Item count",
                width: "20%",
                textCentered: true,
                styles: highlightCellStyles,
                Value: ({ index }) => {
                    const count = data[index].itemCount;
                    return <>{count ? count : "-"}</>;
                },
            },
            {
                title: "Median Price",
                width: "20%",
                textCentered: true,
                Value: ({ index }) => {
                    const num = data[index].medianPrice;
                    return <>{num ? getFormattedCurrency(num) : "-"}</>;
                },
            },
            {
                title: "Price Range",
                width: "20%",
                textCentered: true,
                Value: ({ index }) => {
                    const min = data[index].minPrice || 0;
                    const max = data[index].maxPrice || 0;
                    if (min === 0 && max === 0) return <>-</>;
                    return (
                        <>
                            {getFormattedCurrency(min)} -{" "}
                            {getFormattedCurrency(max)}
                        </>
                    );
                },
            },
        ];
    }, [data]);


    return (
        <div className="MenuDetails">
            {data.length > 0 && (
                <>
                    <div className="MenuDetails__table">
                        <CopyButtonContext.Provider value={{
                            data: getClipboardData({
                                head: tableConfig.map(item => item.title || ""),
                                data,
                            })
                        }}>
                            <Table
                                config={tableConfig}
                                rowsCount={data.length}
                                hasInnerScroll={false}
                            />
                        </CopyButtonContext.Provider>
                    </div>
                    <div className="MenuDetails__buttons">
                        <ButtonGrid
                            data={data}
                            selectedButton={selectedButton}
                            handleSelectButton={setSelectedButton}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default MenuDetails;
