// TODO: Request new API with cleared out model
interface API__DOCUMENT {
    canEdit: boolean;
    canPurchase: boolean;
    childCppReports: any[];
    companiesIds: number[];
    companyId: number;
    companyName: string;
    content: string;
    dateTime: string;
    docType: string;
    documentType: number;
    documentTypeString: string;
    downloadLinks: { title: string; linkToDownload: string }[];
    editFeatureByUserGroupId: number;
    editFeatureId: number;
    featureId: number;
    fileSize: string;
    generateLink: string;
    hasCustomThumbnail: boolean;
    hasPermission: boolean;
    hidden: boolean;
    id: number;
    ideaToolFile: string;
    instanceId: number;
    isLandscape: boolean;
    isPrivate: boolean;
    isShared: boolean;
    modifiedByUser: string;
    modifiedDate: string;
    operatorIdeaToolFile: string;
    pages: number;
    parentId: number;
    parentPages: number;
    penetration: number;
    price: number;
    priority: number;
    projectsJson: string;
    projectId: string;
    publicationFamily: string;
    publicationFamilyId: number;
    shareLinkString: string;
    subTitle: string;
    thumbnailUrls: string[];
    thumnailExist: boolean;
    title: string;
    trendspottingPdfFile: string;
    viewFeatureByUserGroupId: number;
    projectName: string;
    thumnailUrl: string;
    myDocumentTypeName: string;
    fileExtention: string;
    description: string;
    myDocumentTypeId: number;
}

export interface API__DOCUMENTS_DATA {
    docType: 'document' | 'mac',
    docTypeInt: number;
    items: API__DOCUMENT[];
    itemsCount: number;
    thumnailUrl: string;
}

export enum DocumentActionButtons {
    Download = "download",
    Package = "package",
    Share = "share",
    Edit = "edit",
    Copy = "copy",
}

export interface API__DOCUMENT_DATA {
    info: API__DOCUMENT;
    similarDocuments: API__DOCUMENT[];
    trendspottingArticles: API__DOCUMENT[];
}



export interface iDocument {
    id: number;
    imageUrl: string;
    fileExtention: string;
    title: string;
    project: string;
    date: Date;
    size: string;
    pages: number;
    type: string;
    copyUrl: string;
    downloadUrl: string;
    docType: string;
    projectId: string;
    canEdit: boolean;
    documentTypeId: number;
}

export class Document implements iDocument {
    static defaultData: iDocument = {
        id: 0,
        imageUrl: '',
        fileExtention: '',
        title: '',
        project: '',
        date: new Date(),
        size: '0',
        pages: 0,
        type: '',
        copyUrl: '',
        downloadUrl: '',
        docType: '',
        projectId: '',
        canEdit: false,
        documentTypeId: 0,
    }

    id = Document.defaultData.id;
    imageUrl = Document.defaultData.imageUrl;
    fileExtention = Document.defaultData.fileExtention;
    title = Document.defaultData.title;
    project = Document.defaultData.project;
    date = Document.defaultData.date;
    size = Document.defaultData.size;
    pages = Document.defaultData.pages;
    type = Document.defaultData.type;
    copyUrl = Document.defaultData.copyUrl;
    downloadUrl = Document.defaultData.downloadUrl;
    docType = Document.defaultData.docType;
    projectId = Document.defaultData.projectId;
    canEdit = Document.defaultData.canEdit;
    documentTypeId = Document.defaultData.documentTypeId;

    constructor(data?: API__DOCUMENT) {
        if ( data ) {
            this.mapFromApi(data);
        }
    }

    private mapFromApi(data: API__DOCUMENT) {
        this.setData({
            id: data.id,
            imageUrl: data.thumnailUrl || Document.defaultData.imageUrl,
            fileExtention: data.fileExtention || Document.defaultData.fileExtention,
            title: data.title,
            project: data.projectName || Document.defaultData.project,
            date: new Date(data.dateTime) || Document.defaultData.date,
            size: data.fileSize || Document.defaultData.size,
            pages: data.pages || Document.defaultData.pages,
            type: data.myDocumentTypeName || Document.defaultData.type,
            copyUrl: data.shareLinkString || Document.defaultData.copyUrl,
            downloadUrl: data.downloadLinks.length > 0 ? data.downloadLinks[0].linkToDownload : Document.defaultData.downloadUrl,
            docType: data.docType || Document.defaultData.docType,
            projectId: data.projectId || Document.defaultData.projectId,
            canEdit: data.canEdit || Document.defaultData.canEdit,
            documentTypeId: data.myDocumentTypeId || Document.defaultData.documentTypeId,
        })
    }

    private setData(model: iDocument) {
        ({
            id: this.id,
            imageUrl: this.imageUrl,
            fileExtention: this.fileExtention,
            title: this.title,
            project: this.project,
            date: this.date,
            size: this.size,
            pages: this.pages,
            type: this.type,
            copyUrl: this.copyUrl,
            downloadUrl: this.downloadUrl,
            docType: this.docType,
            projectId: this.projectId,
            canEdit: this.canEdit,
            documentTypeId: this.documentTypeId
        } = model);
    }
}