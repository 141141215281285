import { FC } from "react";
import Modal from "../../Modal/Modal";
import Button from "../Button/Button";
import "./ConfirmPopup.styles.scss";

interface iProps {
    isShown: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

const ConfirmPopup: FC<iProps> = ({
    isShown,
    onClose,
    onSubmit,
    children
}) => {
    return (
        <Modal
            isOpened={isShown}
            close={onClose}
            hasInner={false}
            className="is-nested"
        >
            <div className="ConfirmPopup">
                <div className="ConfirmPopup__content">{children}</div>
                <div className="ConfirmPopup__footer">
                    <Button
                        className="ConfirmPopup__button"
                        onClick={onClose}
                        modifiers={["secondary"]}
                    >
                        Cancel
                    </Button>

                    <Button
                        className="ConfirmPopup__button"
                        onClick={onSubmit}
                        modifiers={["danger"]}
                    >
                        Yes, delete
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmPopup;
