import { FC, useEffect, useState } from "react";
import { iLTOChart } from "../../../../../../core/models/LTOChart";
import ChartLine from "./ChartLine/ChartLine";
import "./LTOChart.styles.scss";

interface iProps {
    data: iLTOChart[];
}

const LTOChart: FC<iProps> = ({ data }) => {
    const [maxValue, setMaxValue] = useState(0);

    useEffect(() => {
        if (!data.length) return;

        const values = data
            .map(({ quarters }) => quarters.filter(({ value }) => value).map(({ value }) => value as number))
            .flat();

        setMaxValue(Math.max(...values, 0));
    }, [data]);

    const getColumnSize = (value: number) => {
        return {
            height: `${value * (100 / maxValue)}%`,
        }
    };

    return (
        <div className="LTOChart">
            <div className="LTOChart__wrapper">
                <div className="LTOChart__lines">
                    {data.map((i => (
                        <>
                            {i.quarters.map(bar => (
                                <div
                                    key={`${i.year}-${bar.season}`}
                                    className="LTOChart__line"
                                    style={getColumnSize(bar.value)}
                                >
                                    <ChartLine value={bar.value} quarter={bar.season}/>
                                </div>
                            ))}
                            <div className="LTOChart__delimiter"/>
                        </>
                    )))}
                </div>
                <div className="LTOChart__years">
                    {data.map((i => (
                        <div className={`LTOChart__group-label LTOChart__group-label-${i.quarters.length}`}>
                            <div className="LTOChart__year-label">
                                {i.year}
                            </div>
                        </div>
                    )))}
                </div>
            </div>
        </div>
    );
};

export default LTOChart;
