import useDocuments from "@apiHooks/useDocuments";
import { iDocument } from "@models/Documents/Documents";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DOCUMENTS_FILTER } from "./Documents.helpers";
import Http from "@core/fetch";
import { routeNames } from "@core/routeNames";
import { useHistory, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { setAvailableSearchTabs } from "@actions/page";

interface iDocumentsData {
    searchQuery: string;
}

interface API__DOCUMENTS_EXISTS {
    documentsCount: number;
    documentsExists: boolean;
    foodProfilesCount: number;
    foodProfilesExists: boolean;
    placesExists: boolean;
    placesExistsCount: number;
}

const useDocumentsData = ({ searchQuery }: iDocumentsData) => {
    const { data, isLoading } = useDocuments(searchQuery);
    const [filterByDate, setFilterByDate] = useState(DOCUMENTS_FILTER);
    const [filteredData, setFilteredData] = useState<iDocument[]>([]);
    const location = useLocation();
    let history = useHistory();
    let dispatch = useDispatch();
    const [isLoadingExists, setIsLoadingExists] = useState(true);
    const setSearchTabs = useCallback((data: API__DOCUMENTS_EXISTS) => dispatch(setAvailableSearchTabs(data)), [dispatch])
    const totalDocuments = useMemo(() => data.length, [data.length]);
    const selectedFilter = useMemo(() => {
        const DEFAULT_SELECTED_FILTER = DOCUMENTS_FILTER[DOCUMENTS_FILTER.length - 1];
        return filterByDate.find(filter => filter.isSelected) || DEFAULT_SELECTED_FILTER;
    }, [filterByDate]);

    const handleChangeFilter = (id: number) => {
        setFilterByDate(prevState =>
            prevState.map(filter => ({
                ...filter,
                isSelected: filter.id === id,
            })),
        );
    };

    useEffect(() => {
        const { months } = selectedFilter;

        if ( !months ) {
            setFilteredData(data);
        } else {
            setFilteredData(data.filter(document => {
                let date = new Date();
                date.setMonth(date.getMonth() - months);
                return Date.parse(`${document.date}`) > Date.parse(`${date}`);
            }));
        }

    }, [data, filterByDate, selectedFilter]);

    useEffect(() => {
        const getExists = async () => {
            setIsLoadingExists(true);
            // TODO: before request check store.pageStatus.searchResultsExist (selector - availableSearchTabs) for data (also include food and places tab)
            const data: API__DOCUMENTS_EXISTS = await Http.get('active tabs', { word: searchQuery });

            if ( !data.documentsExists ) {
                if ( !data.foodProfilesExists ) {
                    if (!data.placesExists) {
                        history.push(routeNames.error);
                    } else {
                        history.push(`${routeNames.places}${location.search}`);
                    }
                } else {
                    history.push(`${routeNames.food}${location.search}`);
                }
            };
            setIsLoadingExists(false);
            setSearchTabs(data);
        };

        getExists();
    }, [history, location.search, searchQuery, setSearchTabs]);

    return {
        isLoading: isLoading || isLoadingExists,
        data: filteredData,
        handleChangeFilter,
        selectedFilter,
        totalDocuments,
    }
}

export default useDocumentsData;