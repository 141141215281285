import Http from '../core/fetch';
import {
    DOCUMENTS_FETCH_DATA_LOADED,
    DOCUMENTS_FETCH_DATA_STARTED,
    DOCUMENTS_FETCH_DATA_STORE,
    DOCUMENTS_FETCH_MORE_DATA_STARTED,
    DOCUMENTS_FETCH_MORE_DATA_LOADED,
    DOCUMENTS_CLEAR_DATA_STORE,
    DOCUMENTS_SHARE_ITEM_INIT,
    DOCUMENTS_SHARE_ITEM_CANCEL,
    DOCUMENTS_SHARE_EMAILS_FETCH_DATA_STORE,
    DOCUMENTS_NOTIFICATION_SHOW,
    DOCUMENTS_NOTIFICATION_HIDE,
    DOCUMENTS_FETCH_DATA_FULL_LOADED,
    DOCUMENTS_CHOP_LINK_STORE,
    DOCUMENTS_CHOP_LINK_LOAD_STARTED,
    DOCUMENTS_CHOP_LINK_LOAD_FINISHED,
    DOCUMENTS_BY_PUBLICATION_FAMILY_FETCH_DATA_LOADED,
    DOCUMENTS_BY_PUBLICATION_FAMILY_FETCH_DATA_STARTED,
    DOCUMENTS_BY_PUBLICATION_FAMILY_FETCH_DATA_STORE,
    DOCUMENTS_SAVE_SEARCH_STRING,
    DOCUMENTS_PURCHASE_START,
    DOCUMENTS_PURCHASE_END,
    DOCUMENTS_PURCHASE_MADE,
    DOCUMENTS_CLEAR_PURCHASE_MADE,
    DOCUMENTS_LINK_LOADING,
    DOCUMENTS_LINK_LOADING_FINISHED,
    DOCUMENTS_INCREASE_COUNT_TO_SHOW,
    DOCUMENTS_RESET_COUNT_TO_SHOW,
    DOCUMENTS_SET_EXPANDED_REPORT_TILE,
    DOCUMENTS_SET_DATE_FILTER,
} from '../actions/actionsNamesList';

import { addNotification } from '../components/GlobalNotification/globalNotification.actions';


const ELEMENTS_TO_TAKE = 50;

export function setExpandedReportTile(id) {
    return {
        type: DOCUMENTS_SET_EXPANDED_REPORT_TILE,
        id
    };
}

export function setSharePopupState(params) {
    return {
        type: DOCUMENTS_SHARE_ITEM_INIT,
        params
    };
}

export function setShareCancel() {
    return {
        type: DOCUMENTS_SHARE_ITEM_CANCEL
    };
}

export function setReceivedEmailsData(data) {
    return {
        type: DOCUMENTS_SHARE_EMAILS_FETCH_DATA_STORE,
        data
    };
}

export function setDocumentsDataLoaded() {
    return {
        type: DOCUMENTS_FETCH_DATA_LOADED
    };
}

export function setDocumentsDataFullLoaded(doctype) {
    return {
        type: DOCUMENTS_FETCH_DATA_FULL_LOADED,
        doctype
    };
}

export function setDocumentsFetchingData() {
    return {
        type: DOCUMENTS_FETCH_DATA_STARTED
    };
}

export function setReceivedDocumentsData(data) {
    return {
        type: DOCUMENTS_FETCH_DATA_STORE,
        data
    };
}

export function setResetDocumentsData() {
    return {
        type: DOCUMENTS_CLEAR_DATA_STORE
    };
}

export function setNotificationShown(text) {
    return {
        type: DOCUMENTS_NOTIFICATION_SHOW,
        text
    };
}

export function setNotificationHidden() {
    return {
        type: DOCUMENTS_NOTIFICATION_HIDE
    };
}

export function storeFileUrl(url) {
    return {
        type: DOCUMENTS_CHOP_LINK_STORE,
        url
    };
}

export function setFileLoadStart() {
    return {
        type: DOCUMENTS_LINK_LOADING
    };
}

export function setFileLoaded() {
    return {
        type: DOCUMENTS_LINK_LOADING_FINISHED
    };
}

export function setChopLinkLoadStart() {
    return {
        type: DOCUMENTS_CHOP_LINK_LOAD_STARTED
    };
}

export function setChopLinkLoaded() {
    return {
        type: DOCUMENTS_CHOP_LINK_LOAD_FINISHED
    };
}

export function setDocumentsByPublicationFamilyDataLoaded() {
    return {
        type: DOCUMENTS_BY_PUBLICATION_FAMILY_FETCH_DATA_LOADED
    };
}

export function setDocumentsByPublicationFamilyFetchingData() {
    return {
        type: DOCUMENTS_BY_PUBLICATION_FAMILY_FETCH_DATA_STARTED
    };
}

export function setReceivedDocumentsByPublicationFamilyData(data) {
    return {
        type: DOCUMENTS_BY_PUBLICATION_FAMILY_FETCH_DATA_STORE,
        data
    };
}

export function setDocumentPurchaseStart() {
    return {
        type: DOCUMENTS_PURCHASE_START
    };
}

export function setDocumentPurchaseEnd() {
    return {
        type: DOCUMENTS_PURCHASE_END
    };
}

export function setDocumentPurchaseMade() {
    return {
        type: DOCUMENTS_PURCHASE_MADE
    };
}

export function setClearDocumentPurchaseMade() {
    return {
        type: DOCUMENTS_CLEAR_PURCHASE_MADE
    };
}

export function purchaseDocument(params) {
    return (dispatch, getState) => {
        dispatch(setDocumentPurchaseStart());
        Http.post('request purchase', {}, {
            body: JSON.stringify({ ...params }),
            headers: {
                'content-type': 'application/json; charset=utf-8'
            }
        })
            .then((res) => {
                dispatch(setDocumentPurchaseMade());
                dispatch(addNotification({text: 'Item has been successfully purchased', duration: 6000}));
                dispatch(setDocumentPurchaseEnd());
            })
            .catch((res) => {
                console.error(res);
            });
    };
}

function parseData(data) {
    const result = {};
    const newDocTypes = {
        report: 'Report',
        mac: 'Mac',
        document: 'Document',
        mtdfoodprofile: 'mtdfoodprofile',
        //snapshot: 'Snapshot',
        trendspotting: 'Trendspotting'
        // InstantChartDocument: 'Report',
        // MacDocument: 'Mac',
        // trendspottingonly: 'Trendspotting'
        // DatassentialReportDocument: 'Trendspotting'
    };
    data.forEach((elem) => {
        let items;
        if (elem.docType === 'mac') {
            items = elem.items.map((elem) => {
                return {
                    ...elem,
                    thumnailUrl: elem.thumnailUrl.replace(/[^&size=]{3,}$/, '437')
                };
            });
        }
        result[newDocTypes[elem.docType]] = {
            itemsCount: elem.itemsCount,
            thumbnail: elem.thumnailUrl,
            items: items !== undefined ? items : elem.items,
            isFullLoaded: false
        };
    });
    return result;
}

export function getDocumentsData(params) {
    return (dispatch, getState) => {
        const query = getState().documentsData.searchString;
        const justPurchased = getState().documentsData.justPurchased;
        const data = {
            word: query,
            skip: params && params.skip || 0,
            take: params && params.take || 1000
        };
        if (params) {
            // const dataOfType = getState().documentsData.data[params.doctype];
            // if (dataOfType.itemsCount && dataOfType.itemsCount > 50 && !dataOfType.isFullLoaded) {
            //     const elementsCountToSkip = getState().documentsData.data[params.doctype].items.length;
            //     if (elementsCountToSkip > dataOfType.itemsCount) return;
            //     data.skip = elementsCountToSkip;
            //     data.take = ELEMENTS_TO_TAKE;
            //     data.doctype = params.doctype;
            // } else {
            //     return;
            // }
        }
        if (!justPurchased){
            dispatch(setDocumentsFetchingData());
        }
        return Http.get('documents', data)
            .then((res) => {
                const parsedData = parseData(res);
                if (justPurchased) dispatch(setResetDocumentsData());
                dispatch(setReceivedDocumentsData(parsedData));
                dispatch(setDocumentsDataLoaded());
                if (justPurchased) dispatch(setClearDocumentPurchaseMade());
                if (params && parsedData[params.doctype].items.length < 50) {
                    dispatch(setDocumentsDataFullLoaded(params.doctype));
                }
            });
    };
}

export function getOneDoctypeData(params) {
    return (dispatch, getState) => {
        const justPurchased = getState().documentsData.justPurchased;
        const data = {
            word: params.word || '',
            doctype: params.doctype,
            skip: params.skip || 0,
            take: params.take || 1000,
            time: Date.now(),
        };

        if (!justPurchased){
            dispatch(setDocumentsFetchingData());
        }
        Http.get('documents', data)
            .then((res) => {
                const parsedData = parseData(res);
                if (justPurchased) dispatch(setResetDocumentsData());
                dispatch(setReceivedDocumentsData(parsedData));
                dispatch(setDocumentsDataLoaded());
                if (justPurchased) dispatch(setClearDocumentPurchaseMade());
            })
            .catch((res) => {
                console.error(res);
                // window.history.back();
            });
    };
}

export function setMoreOneDoctypeFetchingData() {
    return {
        type: DOCUMENTS_FETCH_MORE_DATA_STARTED
    };
}

export function setMoreOneDoctypeDataLoaded() {
    return {
        type: DOCUMENTS_FETCH_MORE_DATA_LOADED
    };
}

export function getMoreOneDoctypeData(params) {
    return (dispatch, getState) => {
        const query = getState().documentsData.searchString;
        const data = {
            word: '',
            doctype: params.doctype,
            skip: params.skip || 0,
            take: params.take || 1000
        };
        dispatch(setMoreOneDoctypeFetchingData());
        Http.get('documents', data)
            .then((res) => {
                const parsedData = parseData(res);
                dispatch(setReceivedDocumentsData(parsedData));
                dispatch(setMoreOneDoctypeDataLoaded());
                dispatch(setClearDocumentPurchaseMade());
            })
            .catch((res) => {
                console.error(res);
                // window.history.back();
            });
    };
}

export function getEmailsData() {
    return (dispatch) => {
        Http.get('emails').then((data) => {
            dispatch(setReceivedEmailsData(data));
        });
    };
}

export function shareDocumentLink(data) {
    return (dispatch) => {
        Http.post('post-share', {
            ...data
        }).then(() => {
            dispatch(addNotification({text: 'Document successfully shared', duration: 5000}));
        })
        .catch((error) => {
            console.error('!!!error', error);
        });
    };
}

export function getChopLink(data, anchorLink) {
    return (dispatch) => {
        dispatch(setChopLinkLoadStart());
        Http
            .get(
                'chop',
                { ...data },
                {},
                'file')
            .then((res) => {
                dispatch(storeFileUrl(window.URL.createObjectURL(res)));
                setTimeout(() => {
                    anchorLink.click();
                    dispatch(setChopLinkLoaded());
                }, 100);
            })
            .catch(console.error);
    };
}

export function getByPublicationFamily(params) {
    return (dispatch, getState) => {
        const data = {
            word: params.word
        };
        const url = params.doctype ? 'reports-by-family' : 'trendspottings-by-family';
        dispatch(setDocumentsByPublicationFamilyFetchingData());
        Http.get(url, data)
            .then((res) => {
                dispatch(setDocumentsByPublicationFamilyDataLoaded());
                dispatch(setReceivedDocumentsByPublicationFamilyData(res));
                dispatch(setClearDocumentPurchaseMade());
            });
    };
}

export function setSearchStringForDocuments(query) {
    return {
        type: DOCUMENTS_SAVE_SEARCH_STRING,
        query
    };
}

export function updateCountToShowByDocType(data) {
    return {
        type: DOCUMENTS_INCREASE_COUNT_TO_SHOW,
        data
    };
}

export function resetCountToShowByDocType(data) {
    return {
        type: DOCUMENTS_RESET_COUNT_TO_SHOW,
        data
    };
}

export function setDateFilter(data) {
    return {
        type: DOCUMENTS_SET_DATE_FILTER,
        data
    }
}
