import { FC, FunctionComponent, SVGProps, useMemo } from "react";
import cn from "classnames";
import { ReactComponent as EditPencilIcon } from "../../../icons/edit-pencil-icon.svg";
import { ReactComponent as MobilePhoneIcon } from "../../../icons/phone-icon.svg";
import { ReactComponent as EmailIcon } from "../../../icons/email-icon.svg";
import { ReactComponent as IconLinkedinSimple } from "../../../icons/linkedin.svg";
import Button, { ButtonTypeEnum } from "../Button/Button";
import { iContact } from "../../../core/models/Contact";
import { IconMenu } from "../../../icons";
import "./ContactCard.styles.scss";
import { getInitialsName } from "@pages/ChainPage/ChainPage.helpers";

interface iProps {
    data: iContact;
    className?: string;
    onEdit: (id: number) => void;
    isPreview?: boolean;
}

interface iEditButton {
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    isShown: boolean;
}

const ContactCard: FC<iProps> = ({ data, className = "", onEdit, isPreview = false }) => {
    const {
        linkedInProfilePhoto,
        name,
        department,
        title,
        phone,
        email,
        linkedInProfile,
        isCustom,
        canEdit,
    } = data;

    const classNames = cn(
        "ContactCard",
        className,
        { "ContactCard__custom-card": isCustom },
        { "ContactCard--preview": isPreview },
    );

    const EditButton = useMemo(
        () =>
            ({ icon: Icon, isShown }: iEditButton) => {
                if (!isShown) return null;
                return <Icon className="ContactCard__edit" onClick={() => onEdit(data.id)} />;
            },
        [data.id, onEdit],
    );

    return (
        <div className={classNames}>
            {!isPreview && (
                <>
                    <EditButton icon={IconMenu} isShown={!isCustom} />
                    <EditButton icon={EditPencilIcon} isShown={isCustom && canEdit} />
                </>
            )}

            <div className="ContactCard__no-photo">
                {getInitialsName(name)}

                {linkedInProfilePhoto && (
                    <div className="ContactCard__photo" style={{backgroundImage: `url(${linkedInProfilePhoto})`}} />
                )}
            </div>

            <div className="ContactCard__name">{name}</div>
            <div className="ContactCard__department">{department}</div>
            <div className="ContactCard__position">{title}</div>

            <div className="ContactCard__contacts">
                {phone && (
                    <Button
                        type={ButtonTypeEnum.Button}
                        tooltipText={phone}
                        modifiers={["icon"]}
                        className="ContactCard__contacts-item"
                    >
                        <MobilePhoneIcon />
                    </Button>
                )}

                {email && (
                    <a href={`mailto:${email}`} className="ContactCard__contacts-item">
                        <EmailIcon />
                    </a>
                )}

                {linkedInProfile && (
                    <a
                        href={linkedInProfile}
                        target="_blank"
                        rel="noreferrer"
                        className="ContactCard__contacts-item"
                    >
                        <IconLinkedinSimple width="32" height="32" />
                    </a>
                )}
            </div>
        </div>
    );
};

export default ContactCard;
