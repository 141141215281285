export default function getFormattedThousand(number = '') {
    let numberString = number.toString();
    let result = '';
    while (numberString.length > 3) {
        result = `,${numberString.substr(numberString.length - 3)}${result}`;
        numberString = numberString.substr(0, numberString.length - 3);
    }

    result = `${numberString}${result}`;
    return result;
}
