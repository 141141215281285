import "./App.styles.scss";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider, useDispatch } from "react-redux";
import RouterComponent from "./pages/router";

import rootReducer from "./reducers";
import { AppContext } from "./AppContext";
import useGlobalUserData from "@apiHooks/useGlobalUserData";
import 'react-toastify/dist/ReactToastify.css';
import {
    AppId,
    cssX,
    Env,
    GtmInit,
    IDropdownItem,
    PlatformContextProvider,
    useGtm
} from "@datassential/platform-ui-lib";
import { useContext, useMemo } from "react";
import { open as openPackagesPopup } from "@actions/packages.actions";
import cn from "classnames";

// @ts-ignore-next-line
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const createStoreWithInitialState = (initialState = {}) => {
    return createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk)));
};
const store = createStoreWithInitialState();

function AppContent () {
    const dispatch = useDispatch();
    const {
        globalVariables: {
            hasMacAdminAccess,
        },
    } = useContext(AppContext);

    const env = useMemo(
        () => {
            switch ((process.env.REACT_APP_PLATFORM_ENV || '').toLowerCase()) {
                case 'qa':
                    return Env.QA;
                case 'staging':
                    return Env.Staging;
                default:
                    return Env.Live;
            }
        },
        []
    );

    const userActions = useMemo(
        () => {
            const result: IDropdownItem[] = [
                {
                    id: 'packages',
                    content: (
                        <div
                            className={cn(cssX("Dropdown__item"), 'is-tall')}
                            onClick={() => dispatch(openPackagesPopup())}
                        >
                            Packages
                        </div>
                    ),
                }
            ];

            if (hasMacAdminAccess) {
                result.push({
                    id: 'admin',
                    content: (
                        <a
                            href={`${process.env.REACT_APP_API}Admin/Mac`}
                            target={'_blank'}
                            className={cn(cssX("Dropdown__item"), 'is-tall')}
                        >
                            MAC Admin
                        </a>
                    ),
                });
            }

            return result;
        },
        [hasMacAdminAccess, dispatch]
    );

    return (
        <PlatformContextProvider
            appId={AppId.Snap}
            env={env}
            enableDevTools={env !== Env.Live}
            additionalUserMenu={userActions}
        >
            {process.env.NODE_ENV !== 'development' && <GtmInit/>}
            <RouterComponent/>
        </PlatformContextProvider>
    );
}

function App() {
    const { data: globalVariables, isLoading } = useGlobalUserData();

    if (isLoading) return null;

    return (
        <Provider store={store}>
            <AppContext.Provider value={{ globalVariables }}>
                <AppContent/>
            </AppContext.Provider>
        </Provider>
    );
}

export default App;
