import { createSelector } from 'reselect';
import { monthValues } from '../core/old_helpers';
import { groupBy, uniqBy } from 'lodash';
import { cutDomainName } from '../core/old_helpers';

export const SCORES_INDEXES_FIELDS = {
    'Unbranded PI': 'purchaseIntent',
    'Branded PI': 'appeal',
    'Uniqueness': 'uniquiness',
    'Frequency': 'frequency',
    'Draw': 'draw',
    'Value': 'value',
};

const chainData = state => state.chainDetailData;
const chainDataNews = state => state.chainDetailData.data.news;
const chainDataContacts = state => state.chainDetailData.data.contacts;
const chainDataMenuLTO = state => state.chainDetailData.data.menuItems;
const filterQuery = state => state.chainDetailData.filterQuery.toLocaleLowerCase();
const chainDataTweets = state => state.chainDetailData.data.tweets;
const chainDataFacebookData = state => state.chainDetailData.data.facebookPosts;
const chainDataYoutubeData = state => state.chainDetailData.data.youTubeVideos;
const chainDataMenuItems = state => state.chainDetailData.data.chainInfoWrapper.menuData;
const chainDetails = state => state.chainDetailData.data.chainInfoWrapper.chainDetails;
const chainName = state => state.chainDetailData.data.chainInfoWrapper.chainDetails.name;
const stateMarketAreas = state => state.chainDetailData.localInsight.marketAreas.stateMarketAreas;
const metroMarketAreas = state => state.chainDetailData.localInsight.marketAreas.metroMarketAreas;
const competitorsData = state => state.chainDetailData.chainCompetitors;
const savedQueries = state => state.chainDetailData.data.savedQueries;
const chainsSuggestList = state => state.chainDetailData.chainsSuggestList;

export const chainDataNewsSelector = createSelector(
    chainDataNews,
    news => news.map((item) => {
        const date = new Date(item.createdOnDate);
        const formattedDate = `${date.getDate()}  ${monthValues[date.getMonth()]} ${date.getFullYear()}`;
        return {
            ...item,
            date: formattedDate,
            shortLink: cutDomainName(item.source)
        };
    })
);

export const chainDataMenuLTOSelector = createSelector(
    chainDataMenuLTO,
    menuItems => menuItems.map((item) => {
        const updatedItem = {
            ...item
        };
        const date = new Date(item.date);
        updatedItem.date = `${monthValues[date.getMonth()]} ${date.getFullYear()}`;

        if (item.indexes.length) {
            updatedItem.indexes = item.indexes.map(i => ({
                key: i.key,
                value: i.value,
                starsCount: item[SCORES_INDEXES_FIELDS[i.key] + 'Star'],
            }));
            updatedItem.hasGraph = true;
        }
        return updatedItem;
    })
);

export const spotlightNewsSelector = createSelector(
    chainDataNewsSelector,
    news => news.slice(0, 4)
);

export const spotlightContactsSelector = createSelector(
    chainDataContacts,
    contacts => contacts.slice(0, 4)
);

export const spotlightMenuLTOSelector = createSelector(
    chainDataMenuLTOSelector,
    menuItems => menuItems.slice(0, 12)
);

export const allNewsFiltered = createSelector(
    [chainDataNewsSelector, filterQuery],
    (news, query) => {
        if (query.trim() !== '') {
            return news.filter(item => item.title.toLowerCase().includes(query) || item.description.toLowerCase().includes(query));
        }
        return news;
    }
);

export const allContactsFiltered = createSelector(
    [chainDataContacts, filterQuery],
    (contacts, query) => {
        if (query.trim() !== '') {
            return contacts.filter(item => item.name.toLowerCase().includes(query) || item.title.toLowerCase().includes(query));
        }
        return contacts;
    }
);

export const allMenuLTOFiltered = createSelector(
    [chainDataMenuLTOSelector, filterQuery],
    (menuItems, query) => {
        if (query.trim() !== '') {
            return menuItems.filter(item => item.name.toLowerCase().includes(query) || item.description.toLowerCase().includes(query));
        }
        return menuItems;
    }
);

export const tweetsSelector = createSelector(
    chainDataTweets,
    tweets => tweets.slice(0, 100)
);

export const facebookPostsSelector = createSelector(
    chainDataFacebookData,
    post => post.slice(0, 4).map((item) => {
        const postDate = new Date(item.createdOn);
        // TODO add post time
        item.date = `${monthValues[postDate.getMonth()]} ${postDate.getDate()}`;
        return item;
    })
);

export const facebookPostsFiltered = createSelector(
    [facebookPostsSelector, filterQuery],
    (posts, query) => {
        if (query.trim() !== '') {
            return posts.filter(item => item.postMessage && item.postMessage.toLowerCase().includes(query));
        }
        return posts;
    }
);

export const youtubeVideosSelector = createSelector(
    chainDataYoutubeData,
    videos => videos.slice(0, 4)
);

export const youtubeVideosFiltered = createSelector(
    [youtubeVideosSelector, filterQuery],
    (videos, query) => {
        if (query.trim() !== '') {
            return videos.filter(item => item.title && item.title.toLowerCase().includes(query));
        }
        return videos;
    }
);

export const TABLE_COLUMNS_SETTINGS = [
    { label: 'STATE', align: 'left', sorting: 'abc' },
    { label: '# OF STORES', sorting: 'num' },
    { label: '% OF ALL STORES', sorting: 'num' },
    { label: 'DENSITY', sorting: 'num' }
];

export const stateTableDataSelector = createSelector(
    stateMarketAreas,
    (states) => {
        const filteredStates = states.sort((a, b) => b.number_of_stores - a.number_of_stores);
        return {
            settings: TABLE_COLUMNS_SETTINGS,
            data: filteredStates.map(state => [
                state.region,
                {
                    type: 'thousand',
                    value: state.number_of_stores
                },
                state.percent_of_all_stores.toFixed(1),
                state.density.toFixed(1)
            ])
        };
    }
);

export const metroTableDataSelector = createSelector(
    metroMarketAreas,
    (metros) => {
        const filteredMetros = metros.sort((a, b) => b.number_of_stores - a.number_of_stores);
        return {
            settings: TABLE_COLUMNS_SETTINGS,
            data: filteredMetros.map(metro => [
                metro.region,
                {
                    type: 'thousand',
                    value: metro.number_of_stores
                },
                metro.percent_of_all_stores.toFixed(1),
                metro.density.toFixed(1)
            ])
        };
    }
);

export const menuItemsSelector = createSelector(
    [chainDataMenuItems, filterQuery],
    (menuItems, query) => {
        if (query.trim() !== '') {
            return menuItems.filter(menuItem => menuItem.item.toLowerCase().includes(query) || menuItem.item_description.toLowerCase().includes(query));
        }
        return menuItems;
    }
);

export const menuItemsFiltered = createSelector(
    menuItemsSelector,
    (menuData) => {
        const groupedByMenuPart = groupBy(menuData, item => item.menu_part);
        const result = [];
        for (const prop in groupedByMenuPart) {
            result.push({
                menuPart: prop,
                menuItems: groupedByMenuPart[prop]
            })
        }
        return result;
    }
);

// export const menuPartsList = createSelector(
//     [chainDataMenuItems, filterQuery, chainName],
//     (menuItems, query, name) => {
//         if (query.trim() !== '') {
//             return [name];
//         } else {
//             return uniqBy(menuItems, item => item.menu_part).map((item => item.menu_part));
//         }
//     }
// );

export const menuPartsList = createSelector(
    menuItemsSelector,
    (menuItems) => {
        return uniqBy(menuItems, item => item.menu_part).map((item => item.menu_part));
    }
);

export const menuChainsWithCompetitorsNames = createSelector(
    [competitorsData, chainName],
    (competitors, name) => [name, ...competitors.map((item => item.chainName))]
);

export const menuDataWithCompetitors = createSelector(
    [competitorsData, menuItemsFiltered, chainName],
    // competitors => competitors.reduce(((arr, item) => arr.concat(item.items)), [])
    (competitors, mainChain, name) => {
        const competitorsFormatted = {};
        competitors.forEach((item) => {
            competitorsFormatted[item.chainName] = item.items;
        });
        return {
            [name]: mainChain,
            ...competitorsFormatted
        };
    }
);

export const savedQueriesTableDataSelector = createSelector(
    savedQueries,
    (queries) => {
        const list = [];
        queries.forEach((elem) => {
            const date = new Date(elem.query.created);
            list.push([
                {
                    type: 'menuGaps',
                    id: elem.query.id,
                    value: elem.query.title
                },
                {
                    type: 'menuGaps',
                    id: elem.query.id,
                    value: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
                },
                {
                    type: 'menuGaps',
                    id: elem.query.id,
                    value: elem.words.length
                }
            ]);
        });
        const filteredStates = queries.sort((a, b) => b.number_of_stores - a.number_of_stores);
        return list;
        // return {
        //     data: filteredStates.map((state) => [
        //         state.region,
        //         state.number_of_stores,
        //         state.percent_of_all_stores,
        //         state.density
        //     ])
        // }
    }
);

export const chainsListSelector = createSelector(
    chainsSuggestList,
    list => list
);

export const chainDetailsSelector = createSelector(
    chainDetails,
    (data) => {
        if (data.city){
            const selected = Object.assign({}, data);
            selected.city = selected.city.toLowerCase();
            // const addressWords = selected.address.toLowerCase().split(' ');
            // addressWords.splice(1, 1, addressWords[1].charAt(0).toUpperCase() + addressWords[1].slice(1));
            // selected.address = addressWords.join(' ');
            return selected;
        } else {
            return data;
        }

    }
);

export const isChainsPopupOpenedSelector = createSelector(
    chainData,
    (popupData) => popupData.isOpened
);
