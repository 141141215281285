import { API__ChainPermissionWrapper, API__FPChainData } from "../apiSchema";
import { useData } from "../hooks/useData";
import { PermissionFields, PermissionSection } from "../models/PermissionSection";
import Http from "../fetch";
import { ID } from "../models";
import { iBrandData, FPChainData } from "../models/Brands";

async function getBrands(chainId: ID): Promise<iBrandData> {
    const data: API__ChainPermissionWrapper<API__FPChainData> = await Http.get('get brand data', { chainId });
    return {
        permissionLevel: PermissionSection.getPermissionLevel(data.permissionLevel),
        hasData: data.hasData,
        data: data.data || new FPChainData(),
    };
}

export default function useBrands(chainId: ID) {
    return useData({
        ...new PermissionFields(),
        data: new FPChainData(),
    }, getBrands, chainId);
}