import { createSelector } from 'reselect';
import { shareDataSelector } from './documents.selectors';

import { isGroupsPopupOpenedSelector } from './groups.selectors';
import { isAddDocumentPopupOpenedSelector } from '@containers/DocumentsAddPopup/documentsAddPopup.selectors';
import { isProjectEditPopupOpenedSelector } from '@containers/ProjectEditPopup/projectEditPopup.selectors';
import { isProjectCreatePopupOpenedSelector } from '@containers/ProjectCreatePopup/projectCreatePopup.selectors';
import { isProjectDeletePopupOpenedSelector } from '@containers/ProjectDeletePopup/projectDeletePopup.selectors';
import { isEditDocumentPopupOpenedSelector } from '@containers/DocumentEditPopup/documentEditPopup.selectors';
import { isDeleteDocumentPopupOpenedSelector } from '@containers/DocumentDeletePopup/documentDeletePopup.selectors';
import { isPackagesPopupOpenedSelector } from './packages.selectors';
import { isChainsPopupOpenedSelector } from './chainDetails.selectors';

const pageData = window.pageData;
const templateType = window.templateType;
const searchString = window.searchString;
const pageStatus = state => state.pageStatus;
const isHelpPopupOpened = state => state.pageStatus.isHelpPopupOpened;
export const isImpersonateModeSelector = state => state.pageStatus.isImpersonateMode;

export const templateTypeSelector = createSelector(
    () => templateType
);

export const isDashboardSelector = createSelector(
    () => templateType === 'dashboard'
);


export const isCustomerResearchPopupOpenedSelector = createSelector(
    pageStatus,
    (pageStatus) => pageStatus.isCustomResearchPopupOpened,
);


export const currentCompanyNameSelector = createSelector(
    () => pageData.currentUsercompanyName
);

export const anyMacExistSelector = createSelector(
    () => pageData.anyMacExist
);

export const headerNavigationItemsSelector = createSelector(
    () => {
        const allLinks = [
            {
                title: 'MACs',
                url: '/allMacs'
            },
            {
                title: 'Industry Research',
                url: '/allReports'
            },
            {
                title: 'Trendspotting',
                url: '/allTrendspotting'
            },
            {
                title: 'Videos',
                url: '/videos'
            },
        ];
        if (!pageData.hasVideoFeature) allLinks.splice(4, 1);
        if (!pageData.anyMacExist) allLinks.splice(1, 1);
        return ([
            {
                title: 'Reports',
                action: 'menu_opened_toggle'
            },
            // ...pageData.headerNavigationItems
            ...allLinks
        ]);
    }
);

export const headerHomeUrlSelector = createSelector(
    () => pageData.homeUrl
);

export const dashboardImageSrcSelector = createSelector(
    () => pageData.dashboardImageSrc
);

export const userInitialsSelector = createSelector(
    () => pageData.userInitials
);

export const shouldRenderLogoutPopupSelector = createSelector(
    (state) => state,
    (state) => state.pageStatus.shouldRenderLogoutPopup
);


export const availableSearchTabsSelector = state => state.pageStatus.searchResultsExist;


const isImpersonatePopupOpenedSelector = createSelector(
    pageStatus,
    data => data.isImpersonatePopupOpened
);

export const isAnyPopupOpenedSelector = createSelector(
    isGroupsPopupOpenedSelector,
    isAddDocumentPopupOpenedSelector,
    isEditDocumentPopupOpenedSelector,
    isProjectEditPopupOpenedSelector,
    isProjectCreatePopupOpenedSelector,
    isProjectDeletePopupOpenedSelector,
    isDeleteDocumentPopupOpenedSelector,
    shareDataSelector,
    isPackagesPopupOpenedSelector,
    isChainsPopupOpenedSelector,
    isCustomerResearchPopupOpenedSelector,
    isImpersonatePopupOpenedSelector,
    isHelpPopupOpened,
    (
        isGroupsOpened,
        isDocUploadOpened,
        isDocEditOpened,
        isProjectEditOpened,
        isProjectCreateOpened,
        isProjectDeleteOpened,
        isDeleteDocOpened,
        shareIsOpened,
        packagesIsOpened,
        chainDetailsIsOpened,
        isCustomerResearchOpened,
        isHelpPopupOpened,
        isImpersonatePopupOpened,
    ) => (
        isGroupsOpened
        || isDocUploadOpened
        || isDocEditOpened
        || isProjectEditOpened
        || isProjectCreateOpened
        || isProjectDeleteOpened
        || isDeleteDocOpened
        || shareIsOpened
        || packagesIsOpened
        || chainDetailsIsOpened
        || isCustomerResearchOpened
        || isHelpPopupOpened
        || isImpersonatePopupOpened
    )
);
