import { FoodProfileRecommendedCharts, iFoodProfileRecommendedCharts } from "@models/FoodProfileRecommendedCharts";
import { FoodProfileLocalPopularity, iFoodProfileLocalPopularity } from "@models/FoodProfileLocalPopularity";
import { FoodProfileDocumentsInfo, iFoodProfileDocumentsInfo } from "@models/FoodProfileDocumentsInfo";
import { API__FoodProfileWidgetsDataViewModel } from "@apiSchema";

type API_Model = API__FoodProfileWidgetsDataViewModel;

interface iData {
    apiModel?: API_Model;
    searchQuery: string;
}

interface iFoodProfileWidgets {
    recommendedCharts: iFoodProfileRecommendedCharts;
    localPopularity: iFoodProfileLocalPopularity;
    documentsInfo: iFoodProfileDocumentsInfo;
}

export class FoodProfileWidgets implements iFoodProfileWidgets {
    recommendedCharts = FoodProfileWidgets.defaultData.recommendedCharts;
    localPopularity = FoodProfileWidgets.defaultData.localPopularity;
    documentsInfo = FoodProfileWidgets.defaultData.documentsInfo;

    static defaultData: iFoodProfileWidgets = {
        recommendedCharts: new FoodProfileRecommendedCharts(),
        localPopularity: new FoodProfileLocalPopularity(),
        documentsInfo: new FoodProfileDocumentsInfo(),
    }

    constructor(data?: iData) {
        if (data) {
            if ( data.apiModel) {
                this.mapFromApi(data.apiModel, data.searchQuery);
            }
        }
    }

    private setData(model: iFoodProfileWidgets) {
        ({
            recommendedCharts: this.recommendedCharts,
            localPopularity: this.localPopularity,
            documentsInfo: this.documentsInfo,
        } = model);
    }

    private mapFromApi(data: API_Model, searchQuery: string): void {

        this.setData({
            recommendedCharts: new FoodProfileRecommendedCharts({
                apiModel: data.macAndInstantChart,
                searchQuery,
            }),
            localPopularity: new FoodProfileLocalPopularity({ apiModel: data.productIndexByMetroState }),
            documentsInfo: new FoodProfileDocumentsInfo({ apiModel: data.document }),
        });
    }

}