import React, { FC, useState, CSSProperties, useRef, useCallback } from "react";
import { iFoodProfileHeatScoreData, HeatScoreLevel } from "@models/FoodProfileHeatScore";
import ContentWidgetLink from "@components/ContentWidget/ContentWidgetLink";
import ContentWidget from "@components/ContentWidget/ContentWidget";
import { capitalize, getValueWithSign } from "@helpers";
import './FoodProfileHeatScore.scss';
import cn from "classnames";
import useOutsideClick from "@hooks/useOutsideClick";

const COLORS_CONFIG = {
    [HeatScoreLevel.None]: {
        color: '#000000',
        borderColor: '#000000',
        backgroundColor: '#FFFFFF',
    },
    [HeatScoreLevel.Declining]: {
        color: '#DFA103',
        borderColor: '#DFA103',
        backgroundColor: '#FFFBEC',
    },
    [HeatScoreLevel.Flat]: {
        color: '#828282',
        borderColor: '#BCBCBC',
        backgroundColor: '#F2F2F2',
    },
    [HeatScoreLevel.GainingSlow]: {
        color: '#2295C7',
        borderColor: '#2295C7',
        backgroundColor: '#DCF4FF',
    },
    [HeatScoreLevel.GainingFast]: {
        color: '#189E2E',
        borderColor: '#189E2E',
        backgroundColor: '#DBFFEA',
    },
    [HeatScoreLevel.OnFire]: {
        color: '#F55353',
        borderColor: '#F55353',
        backgroundColor: '#FFECEC',
    },
};

const FoodProfileHeatScore: FC<{
    data: iFoodProfileHeatScoreData;
    keywordTitle: string;
}> = (
    {
        data,
        keywordTitle,
    }
) => {
    const {
        config,
        heatLevelId,
        loveIt,
        triedIt,
        knowIt,
        tooltipText,
    } = data;
    const [isOpened, setIsOpened] = useState(false);
    const container = useRef<HTMLDivElement>(null);
    const close = useCallback(() => setIsOpened(false), []);

    useOutsideClick({
        ref: container,
        isDisabled: !isOpened,
        fn: close,
    });

    const heatLevel = config.find(item => item.id === heatLevelId);

    return (
        <ContentWidget
            title="Heat Score"
            titleTestId={"textFoodProfileHeatScoreTitle"}
            className="FoodProfileLocalIndices"
        >
            <div className="FoodProfileHeatScore" ref={container}>
                <div className="ContentWidget__section-wrapper">
                    <div className="ContentWidget__section FoodProfileHeatScore__top">
                        <div
                            className="FoodProfileHeatScore__title"
                            data-testid={"textFoodProfileHeatScoreLabelConsumer"}
                        >
                            consumer familiarity with <b>{keywordTitle.toUpperCase()}</b> indicates that it is:
                        </div>
                        <div className="FoodProfileHeatScore__center">
                            <div
                                className="FoodProfileHeatScore__round-box"
                                style={COLORS_CONFIG[heatLevelId]}
                                data-testid={"textFoodProfileHeatScoreHeatLevel"}
                            >
                                {heatLevel?.name || 'No data'}
                            </div>
                        </div>
                    </div>
                    <div className="ContentWidget__section FoodProfileHeatScore__bottom">
                        <div
                            className="FoodProfileHeatScore__title"
                            data-testid={"textFoodProfileHeatScoreLabelChange"}
                        >
                            change vs. 2 years ago:
                        </div>
                        <div className="FoodProfileHeatScore__metrics FoodProfileHeatScore__center">
                            <div className="FoodProfileHeatScore__metric">
                                <div data-testid={"textFoodProfileHeatScoreLabelKnowIt"}>know it</div>
                                <div
                                    className={cn(
                                        "FoodProfileHeatScore__metric-value",
                                        knowIt > 0 && 'is-positive',
                                        knowIt < 0 && 'is-negative',
                                    )}
                                    data-testid={"textFoodProfileHeatScoreKnowItValue"}
                                >
                                    {getValueWithSign(+knowIt.toFixed(1))}%
                                </div>
                            </div>
                            <div className="FoodProfileHeatScore__metric">
                                <div data-testid={"textFoodProfileHeatScoreLabelTriedIt"}>tried it</div>
                                <div
                                    data-testid={"textFoodProfileHeatScoreTriedItValue"}
                                    className={cn(
                                        "FoodProfileHeatScore__metric-value",
                                        triedIt > 0 && 'is-positive',
                                        triedIt < 0 && 'is-negative',
                                    )}
                                >
                                    {getValueWithSign(+triedIt.toFixed(1))}%
                                </div>
                            </div>
                            <div className="FoodProfileHeatScore__metric">
                                <div data-testid={"textFoodProfileHeatScoreLabelLoveIt"}>love it</div>
                                <div
                                    data-testid={"textFoodProfileHeatScoreLabelLoveIt"}
                                    className={cn(
                                        "FoodProfileHeatScore__metric-value",
                                        loveIt > 0 && 'is-positive',
                                        loveIt < 0 && 'is-negative',
                                    )}
                                >
                                    {getValueWithSign(+loveIt.toFixed(1))}%
                                </div>
                            </div>
                        </div>
                        <ContentWidgetLink
                            onClick={() => setIsOpened(true)}
                            className="font-text"
                            testId={"buttonFoodProfileHeatScoreExpand"}
                        >
                            what's this
                        </ContentWidgetLink>
                    </div>
                </div>
                <div className={cn(
                    "FoodProfileHeatScore__info",
                    isOpened && 'is-shown',
                )}>
                    <div className="ContentWidget__section FoodProfileHeatScore__info-top">
                        <div
                            data-testid={"textFoodProfileHeatScoreDescription"}
                            dangerouslySetInnerHTML={{ __html: tooltipText }}
                        />
                    </div>
                    <div className="ContentWidget__section FoodProfileHeatScore__info-bottom">
                        <div className="FoodProfileHeatScore__center">
                            {config.map((item, index) => (
                                <div className="FoodProfileHeatScore__config-item">
                                    <div
                                        className="FoodProfileHeatScore__round-box FoodProfileHeatScore__round-box--small"
                                        data-testid={`textFoodProfileDescriptionItemValue${index}`}
                                        style={COLORS_CONFIG[item.id]}
                                    >
                                        {item.name}
                                    </div>
                                    &nbsp;&nbsp;
                                    <div data-testid={`textFoodProfileDescriptionItemLabel${index}`}>
                                        {item.description}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <ContentWidgetLink
                            modifiers={["light"]}
                            onClick={() => setIsOpened(false)}
                            className="font-text"
                            testId={"buttonFoodProfileHeatScoreCollapse"}
                        >
                            okay, thanks
                        </ContentWidgetLink>
                    </div>
                </div>
            </div>
        </ContentWidget>
    );
};

export default FoodProfileHeatScore;