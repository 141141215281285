export default function DocumentUploadGuidelinesPopup() {
    return (
        <div
            className="popup__helper-wrapper"
            data-testid="blockDocumentUploadGuidelinesPopup"
        >
            <div
                className="popup__helper-topside"
                data-testid="blockDocumentUploadGuidelinesPopupSection-0"
            >
                <div
                    className="popup__helper-title popup__helper-title--main"
                    data-testid="textDocumentUploadGuidelinesPopupTitle"
                >
                    Guidelines for naming documents
                </div>
                <div className="popup__helper-text">
                    <div
                        className="popup__helper-item--list"
                        data-testid="textDocumentUploadGuidelinesPopupContent-0"
                    >
                        Keep the names short
                    </div>
                    <div
                        className="popup__helper-item--list"
                        data-testid="textDocumentUploadGuidelinesPopupContent-1"
                    >
                        Do not include the client's name
                    </div>
                    <div
                        className="popup__helper-item--list"
                        data-testid="textDocumentUploadGuidelinesPopupContent-2"
                    >
                        You can include the year (but not the month)
                    </div>
                    <div
                        className="popup__helper-item--list"
                        data-testid="textDocumentUploadGuidelinesPopupContent-3"
                    >
                        Do not include "Datassential" unless it describes the content of the document
                        (such as "Datassential Top Trends 2017")
                    </div>
                </div>
            </div>
            <div
                className="popup__helper-downside"
                data-testid="blockDocumentUploadGuidelinesPopupSection-1"
            >
                <div
                    className="popup__helper-title"
                    data-testid="textDocumentUploadGuidelinesPopupTitle"
                >
                    Good names include:
                </div>
                <div className="popup__helper-text">
                    <div
                        className="popup__helper-item"
                        data-testid="textDocumentUploadGuidelinesPopupContent-0"
                    >
                        Mexican Beverages 2017
                    </div>
                    <div
                        className="popup__helper-item"
                        data-testid="textDocumentUploadGuidelinesPopupContent-1"
                    >
                        Stouffer's Lasagna Innovation
                    </div>
                    <div
                        className="popup__helper-item"
                        data-testid="textDocumentUploadGuidelinesPopupContent-2"
                    >
                        Fast Casual Overview 2018
                    </div>
                </div>
            </div>
        </div>
    );
}