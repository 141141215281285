import { Link } from "react-router-dom";
import React, { FC } from "react";
import "./ActionButtons.styles.scss";

export enum ActionButtonTypeEnum {
    IconButton,
    LinkIconButton,
}

interface iActionButtonConfig {
    Icon: any;
    type?: ActionButtonTypeEnum;
    onClick?: () => void;
    link?: string;
    size?: "inherit" | "default" | "small" | "large";

}

interface iProps {
    config: iActionButtonConfig[];
    style?: React.CSSProperties;
}

const ActionButtons: FC<iProps> = ({ config, style }) => {
    return (
        <div className="ActionButtons" style={style}>
            {config.map(
                (
                    {
                        onClick,
                        link = "",
                        type = ActionButtonTypeEnum.IconButton,
                        Icon,
                        size = "small",
                    },
                    index,
                ) => {
                    const renderButton = (
                        <div className="ActionButtons__button" onClick={onClick}>
                            <Icon fontSize={size} />
                        </div>
                    );
                    switch (type) {
                        case ActionButtonTypeEnum.LinkIconButton:
                            return (
                                <Link key={index} to={link}>
                                    {renderButton}
                                </Link>
                            );
                        default:
                            return <div key={index}>{renderButton}</div>;
                    }
                },
            )}
        </div>
    );
};

export default ActionButtons;
