import { Component } from 'react';
import AllPage from './AllPage';


export default class AllMacs extends Component {
    render() {
        return (
            <AllPage type="Mac" isAllMac={true} />
        );
    }
}
