import { FC } from "react";
import InfoBlock from "../../../../../components/new/InfoBlock/InfoBlock";
import {
    IconStrengths,
    IconStrengthsItem,
    IconWeaknesses,
    IconWeaknessesItem,
} from "../../../../../icons";
import BrandsHeader from "../BrandsHeader/BrandsHeader";
import "./BrandsParties.styles.scss";
import { API__FPChainData } from "../../../../../core/apiSchema";

interface iProps {
    data: API__FPChainData;
}

enum IconTypeEnum {
    Strength,
    Weakness,
}

const Item: FC<{ icon: IconTypeEnum }> = (
    {
        icon,
        children,
    }
) => {
    return (
        <div className="BrandsParties__list-item">
            {icon === IconTypeEnum.Strength && (
                <IconStrengthsItem className="BrandsParties__list-item-icon"/>
            )}
            {icon === IconTypeEnum.Weakness && (
                <IconWeaknessesItem className="BrandsParties__list-item-icon"/>
            )}
            {children}
        </div>
    );
};

const BrandsParties: FC<iProps> = (
    {
        data: {
            situationAssessmentData: {
                strengths,
                weakness,
            }
        },
    }
) => {

    return (
        <div className="BrandsParties">
            <BrandsHeader title="Strengths & Weaknesses" classNames="is-lead"/>

            <div className="BrandsParties__content">
                <InfoBlock className="BrandsParties__column-wrapper">
                    <div className="BrandsParties__column">
                        <BrandsHeader
                            title={"Strengths"}
                            icon={<IconStrengths/>}
                        />

                        <div className="BrandsParties__list">
                            {strengths.map((el, index) => {
                                return (
                                    <Item icon={IconTypeEnum.Strength} key={el.name}>
                                        {el.name}
                                    </Item>
                                );
                            })}
                        </div>
                    </div>
                </InfoBlock>
                <InfoBlock className="BrandsParties__column-wrapper">
                    <div className="BrandsParties__column">
                        <BrandsHeader
                            title={"Weaknesses"}
                            icon={<IconWeaknesses />}
                        />

                        <div className="BrandsParties__list">
                            {weakness.map((el, index) => {
                                return (
                                    <Item icon={IconTypeEnum.Weakness} key={el.name}>
                                        {el.name}
                                    </Item>
                                );
                            })}
                        </div>
                    </div>
                </InfoBlock>
            </div>
        </div>
    );
};

export default BrandsParties;
