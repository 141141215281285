import { FC, memo, useCallback, useEffect, useState } from "react";
import { iNote, Note } from "../../../core/models/Note";
import useNotesData from "@pages/ChainPage/hooks/useNotesData";
import useQueryParam from "../../../core/hooks/useQueryParam";
import NotesEdit from "./NotesEdit/NotesEdit";
import "./Notes.styles.scss";
import NotesItems from "./NotesItems/NotesItems";

interface iProps {
    isOpen?: boolean;
    onClose: () => void;
    setNotesCount?: (count: number) => void;
}

const DEFAULT_NOTE = new Note();

const Notes: FC<iProps> = ({ onClose, isOpen, setNotesCount = () => {} }) => {
    const chainId = useQueryParam("id");
    const {
        isLoading,
        isUpdating,
        data,
        isSuccess,
        reFetch,
        saveNote,
        removeNote,
    } = useNotesData(chainId);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedNote, setSelectedNote] = useState<iNote>(DEFAULT_NOTE);
    const [isNoteChanged, setIsNoteChanged] = useState(false);
    const isNotesLoading = isLoading || isUpdating;

    const resetForm = useCallback(() => {
        setSelectedNote(DEFAULT_NOTE);
        setIsFormOpen(false);
        setIsNoteChanged(false);
    }, []);

    useEffect(() => {
        if (isSuccess && !isNotesLoading) {
            setNotesCount(data.length);
        }
    }, [isNotesLoading, data, isSuccess, setNotesCount]);

    useEffect(() => {
        resetForm();
    }, [data, resetForm]);

    useEffect(() => {
        // reset form on close popup, when no changes
        if (!isOpen && !isNoteChanged) {
            resetForm();
        }
    }, [isOpen, isNoteChanged, resetForm]);

    useEffect(() => {
        if ( isOpen ) {
            reFetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const handleToggleForm = () => {
        setIsFormOpen(!isFormOpen);
        setSelectedNote(new Note());
        setIsNoteChanged(false);
    };

    const handleOpenEdit = (note: iNote) => {
        setSelectedNote(note);
        setIsFormOpen(true);
        setIsNoteChanged(false);
    };

    const handleSave = (note: iNote) => saveNote(note, chainId);

    const handleRemove = (id: number) => removeNote(id);

    const handleCancelForm = () => {
        setIsFormOpen(false);
        setSelectedNote(new Note());
        setIsNoteChanged(false);
    };

    const handleChangeField = useCallback((field: keyof iNote, value: boolean | string) => {
        setSelectedNote(prevState => ({
            ...prevState,
            [field]: value,
        }));

        if (field === "text") {
            setIsNoteChanged((value as string).length !== 0);
        }
    }, []);

    if (!isOpen) return null;

    return (
        <div className="Notes">
            <NotesItems
                onClose={onClose}
                data={data}
                onRemove={handleRemove}
                onEdit={handleOpenEdit}
                onToggleForm={handleToggleForm}
                isLoading={isNotesLoading}
            />

            {isFormOpen && (
                <div className="Notes__form">
                    <NotesEdit
                        data={selectedNote}
                        onCancel={handleCancelForm}
                        handleChangeField={handleChangeField}
                        onSave={handleSave}
                        isLoading={isNotesLoading}
                    />
                </div>
            )}
        </div>
    );
};

export default memo(Notes);
