import { Link } from "react-router-dom";
import {
    AppId,
    CountriesSelect, cssX, FCX,
    Header,
    HeaderLogo, IDropdownColumn, includesInLC, markMatch,
    Navigation,
    ProductLogo,
    SearchBox, SearchBoxNoResults, startsFromInLC
} from "@datassential/platform-ui-lib";
import { useMemo, useState } from "react";
import { routeNames } from "@core/routeNames";
import cn from "classnames";
import './UsChainsHeader.scss';
import { iTopChainsData } from "@models/TopChainsData";

const UsChainsHeader:FCX<{
    data: iTopChainsData;
    isLoading: boolean;
}> = ({
    style,
    data,
    isLoading,
}) => {
    const [searchQuery, setSearchQuery] = useState<string>("");

    const searchAutocomplete = useMemo(
        (): IDropdownColumn[] | undefined => {
            const results = [
                ...data.places.filter(i => startsFromInLC(i.name, searchQuery.trim())),
                ...data.places.filter(i => !startsFromInLC(i.name, searchQuery.trim()) && includesInLC(i.name, searchQuery.trim())),
            ];

            if (results.length === 0) return undefined;

            return [{
                id: 'chains',
                items: results.slice(0, 10).map((chain) => ({
                    id: chain.id,
                    content: (
                        <Link
                            to={`${routeNames.chainDetails}?id=${chain.id}`}
                            className={cn(cssX("Dropdown__item"))}
                        >
                            <div dangerouslySetInnerHTML={{ __html: markMatch(chain.name, searchQuery.trim())}}/>
                        </Link>
                    ),
                })),
            }];
        },
        [data, searchQuery]
    );

    return (
        <Header
            className="UsChainsHeader"
            style={style}
            leftContent={(<>
                <Link to={routeNames.fireflyChains} data-testid={"buttonDEMOHeaderLogo"}>
                    <HeaderLogo>
                        <ProductLogo appId={AppId.Chains}/>
                    </HeaderLogo>
                </Link>
                <CountriesSelect
                    options={[
                        { id: 'usa', shortName: 'USA', name: '' },
                    ]}
                    value={'usa'}
                    setValue={() => {}}
                    isDisabled
                    style={{ marginLeft: 10 }}
                    testId={"selectHeaderCountry"}
                />
            </>)}
            rightContent={<Navigation data={[
                {
                    id: 'search',
                    content: (
                        <SearchBox
                            testId={"selectChainsHeaderSearch"}
                            tooltipId={"chains-header-search"}
                            style={{ pointerEvents: isLoading ? 'none' : undefined }}
                            inputProps={{
                                value: searchQuery,
                                setValue: (value: string) => setSearchQuery(value),
                                placeholder: 'Search for chain',
                                testId: 'inputChainsHeaderSearch',
                            }}
                            dropdownProps={{
                                isOpened: !!searchQuery.trim(),
                                groups: searchAutocomplete,
                                id: 'chains-header-search',
                                testId: "selectChainsHeaderSearchDropdown",
                                children: searchAutocomplete === undefined ? (
                                    <SearchBoxNoResults/>
                                ) : null,
                            }}
                        />
                    ),
                }
            ]}/>}
        />
    );
}

export default UsChainsHeader;