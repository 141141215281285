import Http from '../core/fetch';
import {
    PACKAGES_SAVE_CURRENT,
    PACKAGES_ADD_ITEM_TO_PACKAGE,
    PACKAGES_REMOVE_ITEM_FROM_PACKAGE,
    PACKAGES_SAVE_PACKAGE_NAME,
    PACKAGES_STORE_ALL_PACKAGES,
    PACKAGES_CHANGE_ACTIVE_PACKAGE,
    PACKAGES_REMOVE_PACKAGE,
    PACKAGES_ADD_NEW_ACTIVE_PACKAGE,
    PACKAGES_REMOVE_PACKAGE_FROM_LIST,
    PACKAGES_ADD_ITEM_LIST_TO_PACKAGE,
    PACKAGES_SAVE_EMAILS,
    PACKAGES_OPEN,
    PACKAGES_CLOSE,
    PACKAGES_SET_DELETING,
    PACKAGES_RESET_DELETING,
} from '../actions/actionsNamesList';

import { addNotification } from '../components/GlobalNotification/globalNotification.actions';

export function storeActivePackage(data) {
    return {
        type: PACKAGES_SAVE_CURRENT,
        data
    };
}

export function getCurrentPackage() {
    return (dispatch) => {
        Http.get('packages get current')
            .then((data) => {
                dispatch(storeActivePackage(data));
            })
            .catch((error) => {
                console.error('!!!error', error);
            });
    };
}

export function storeItemToPackage(item) {
    return {
        type: PACKAGES_ADD_ITEM_TO_PACKAGE,
        item
    };
}

export function storeItemListToPackage(list) {
    return {
        type: PACKAGES_ADD_ITEM_LIST_TO_PACKAGE,
        list
    };
}

export function addFileToPackage(params) {
    return (dispatch, getState) => {
        const currentPackageId = getState().packagesData.activePackage.id;
        Http.post('packages add item', {}, {
            body: JSON.stringify({
                ...params,
                packageId: currentPackageId
            }),
            headers: {
                'content-type': 'application/json; charset=utf-8'
            }
        })
            .then((data) => {
                if (
                    /MSIE 10/i.test(window.navigator.userAgent) ||  // ie10
                    /rv:11.0/i.test(window.navigator.userAgent) ||  // ie11
                    /Edge\/\d./i.test(window.navigator.userAgent)   // MSedge
                ) {
                    dispatch(addNotification({
                        text: "Package was successfully added!",
                        duration: 3000,
                    }));
                }

                if (data.id !== 0) {
                    dispatch(storeItemToPackage(data));
                }
            })
            .catch((error) => {
                console.error('!!!error', error);
            });
    };
}

export function addFileListToPackage(params) {
    return (dispatch, getState) => {
        const packageId = getState().packagesData.activePackage.id;
        return Http.post('add package list', {}, {
            body: JSON.stringify({
                ...params,
                packageId
            }),
            headers: {
                'content-type': 'application/json; charset=utf-8'
            }
        })
            .then((data) => {
                const result = [];
                data.forEach((pack) => {
                    if (pack.id !== 0) {
                        result.push(pack);
                    }
                });
                dispatch(storeItemListToPackage(result));
            })
            .catch((error) => {
                console.error('!!!error', error);
            });
    };
}

export function closePackage() {
    return (dispatch) => {
        Http.get('packages close package')
            .then((data) => {
                dispatch(storeActivePackage(data));
            })
            .catch((error) => {
                console.error('!!!error', error);
            });
    };
}

export function removeItemFromPackage(id) {
    return {
        type: PACKAGES_REMOVE_ITEM_FROM_PACKAGE,
        id
    };
}

export function setDeletingStart() {
    return {
        type: PACKAGES_SET_DELETING,
    };
}

export function setDeletingEnd() {
    return {
        type: PACKAGES_RESET_DELETING,
    };
}

export function removeFileFromPackage(id) {
    return (dispatch, getState) => {
        const currentPackageId = getState().packagesData.activePackage.id;
        const isAllPackagesLoaded = !!getState().packagesData.allPackages.length;
        dispatch(setDeletingStart());
        Http.post('packages remove item', {}, {
            body: JSON.stringify({
                packageItemId: id,
                packageId: currentPackageId
            }),
            headers: {
                'content-type': 'application/json; charset=utf-8'
            }
        }, 'bool')
            .then(() => {
                dispatch(removeItemFromPackage(id));
                if (isAllPackagesLoaded) {
                    dispatch(removePackageFromListById({
                        packageId: currentPackageId,
                        id
                    }));
                }
                dispatch(setDeletingEnd());
            })
            .catch((error) => {
                console.error('!!!error', error);
            });
    };
}

export function saveSharedEmails(params) {
    return {
        type: PACKAGES_SAVE_EMAILS,
        params
    };
}

export function sharePackage(data) {
    return (dispatch) => {
        let params = {
            id: data.packageId
        };
        Http.post('packages share', {}, {
            body: JSON.stringify({
                ...data
            }),
            headers: {
                'content-type': 'application/json; charset=utf-8'
            }
        })
            .then((response) => {
                params.emails = response.emails;
                dispatch(saveSharedEmails(params));
            })
            .catch((error) => {
                console.error('!!!error', error);
            });
    };
}

export function changePackageName(name) {
    return {
        type: PACKAGES_SAVE_PACKAGE_NAME,
        name
    };
}

export function savePackageName(name) {
    return (dispatch) => {
        Http.post('packages change name', { name }, {}, 'bool')
            .then(() => {
                dispatch(changePackageName(name));
            })
            .catch((error) => {
                console.error('!!!error', error);
            });
    };
}

export function storeAllPackages(data) {
    return {
        type: PACKAGES_STORE_ALL_PACKAGES,
        data
    };
}

export function getAllPackages() {
    return (dispatch) => {
        return Http.get('packages get all')
            .then((data) => {
                dispatch(storeAllPackages(data));
            })
            .catch((error) => {
                console.error('!!!error', error);
            });
    };
}

export function changeActivePackage(id) {
    return {
        type: PACKAGES_CHANGE_ACTIVE_PACKAGE,
        id
    };
}

export function setActivePackage(id) {
    return (dispatch) => {
        Http.post('packages set active', {
            packageId: id
        }, {}, 'bool')
            .then(() => {
                dispatch(changeActivePackage(id));
            })
            .catch((error) => {
                console.error('!!!error', error);
            });
    };
}

export function removePackage(id) {
    return {
        type: PACKAGES_REMOVE_PACKAGE,
        id
    };
}

export function addNewActivePackage(data) {
    return {
        type: PACKAGES_ADD_NEW_ACTIVE_PACKAGE,
        data
    };
}

export function deletePackage(id) {
    return (dispatch) => {
        return Http.get('packages delete', {
            packageId: id
        })
            .then((data) => {
                dispatch(removePackage(id));
                if (data.isActive) {
                    dispatch(addNewActivePackage(data));
                    dispatch(setActivePackage(data.id));
                }
            })
            .catch((error) => {
                console.error('!!!error', error);
            });
    };
}

export function removePackageFromListById(data) {
    return {
        type: PACKAGES_REMOVE_PACKAGE_FROM_LIST,
        data
    };
}

export function removePackageFromList(id, packageId) {
    return (dispatch, getState) => {
        const isCurrent = getState().packagesData.activePackage.id === packageId;
        Http.post('packages remove item', {}, {
            body: JSON.stringify({
                packageItemId: id,
                packageId
            }),
            headers: {
                'content-type': 'application/json; charset=utf-8'
            }
        }, 'bool')
            .then(() => {
                dispatch(removePackageFromListById({
                    id,
                    packageId
                }));
                if (isCurrent) {
                    dispatch(removeItemFromPackage(id));
                }
            })
            .catch((error) => {
                console.error('!!!error', error);
            });
    };
}

export function open() {
    return {
        type: PACKAGES_OPEN,
    };
}

export function close() {
    return {
        type: PACKAGES_CLOSE,
    };
}
