import React, { FC, memo, useContext, useMemo, useState } from "react";
import Button, { ButtonTypeEnum } from "../../../../components/new/Button/Button";
import SectionHeader from "../../../../components/new/SectionHeader/SectionHeader";
import useBrands from "../../../../core/apiHooks/useBrands";
import BrandsAttributes from "./BrandsAttributes/BrandsAttributes";
import BrandsParties from "./BrandsParties/BrandsParties";
import BrandsFunnel from "./BrandsFunnel/BrandsFunnel";
import BrandsDetailedAttributes from "./BrandsDetailedAttributes/BrandsDetailedAttributes";
import SectionContent from "../../../../components/new/SectionContent/SetionContent";
import { ReportsNameEnum } from "../../../../core/models/ChainDetails";
import "./Brands.styles.scss";
import { isAvailable, PermissionLevelEnum } from "../../../../core/models/PermissionSection";
import { Lock, LockDefaultAction } from "../../../../components/new/LockSection/LockSection";
import { ChainDetailsDataContext, ChainSectionsEnum, getSectionData } from "../../ChainPage.helpers";
import { SectionEnum } from "../../../../core/enums";
import { AppContext } from "../../../../AppContext";
import { sanitizeUrl } from "../../../../core/helpers";

const Brands: FC = () => {
    const {
        data: {
            mainData: {
                id: chainId,
            },
            reportsData,
        },
        downloadReports,
    } = useContext(ChainDetailsDataContext);
    const { globalVariables: { productsConfig } , } = useContext(AppContext);
    const fingerprintsLink = productsConfig.find(i => i.title.toLowerCase().indexOf('fingerprints') >= 0)?.url;
    const fingerprintsViewMoreLink = useMemo(() => {
        return fingerprintsLink ? sanitizeUrl(`${fingerprintsLink}/restaurants/chain/${chainId}/overview`) : ""
    }, [chainId, fingerprintsLink]);
    const {
        isLoading,
        data: {
            permissionLevel,
            data,
        },
    } = useBrands(chainId);

    const report = reportsData.find(i => i.data.name === ReportsNameEnum.BrandFingerprints);
    const isReportDownloadAvailable = !!report && isAvailable(report);

    return (
        <div className="Brands">
            <SectionHeader title="Brand">
                <div className="Brands__actions-item">
                    {isReportDownloadAvailable && (
                        <Button
                            modifiers={["primary"]}
                            onClick={() => downloadReports([ReportsNameEnum.BrandFingerprints])}
                        >
                            DOWNLOAD BRAND REPORT
                        </Button>
                    )}
                </div>
            </SectionHeader>
            <SectionContent isLoading={isLoading}>
                <div className="Brands__content">
                    {data && (
                        <>
                            <div className="Brands__section">
                                <BrandsAttributes data={data}/>
                            </div>

                            <div className="Brands__section is-half">
                                <BrandsParties data={data}/>
                            </div>

                            <div className="Brands__section is-full">
                                <BrandsFunnel data={data} />
                            </div>

                            <div className="Brands__section is-sidebar">
                                <BrandsDetailedAttributes data={data} />
                            </div>

                            {!!fingerprintsLink && (
                                <div className="Brands__footer">
                                    <Button
                                        type={ButtonTypeEnum.Anchor}
                                        className="Brands__footer-action"
                                        link={fingerprintsViewMoreLink}
                                    >
                                        View more
                                    </Button>
                                </div>
                            )}
                        </>
                    )}

                    {permissionLevel === PermissionLevelEnum.None && (
                        <Lock>
                            {getSectionData(ChainSectionsEnum.Brand).noAccessMessage}
                            <LockDefaultAction section={SectionEnum.Brand}/>
                        </Lock>
                    )}
                </div>
            </SectionContent>
        </div>
    );
};

export default memo(Brands);
