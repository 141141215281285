import { FC, ReactNode, useState } from "react";
import'./Tile.styles.scss';
import cn from "classnames";

interface iProps {
    onClick: () => void;
    title: string;
    modifiers?: Array<"opaque">;
}

const Tile: FC<iProps> = (
    {
        onClick,
        title,
        modifiers = [],
    }
) => {
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);

    const className = cn(
        'Tile',
        ...modifiers.map(m => `Tile--${m}`),
    );

    return (
        <div
            onMouseEnter={(event) => {
                setX(event.clientX - event.currentTarget.getBoundingClientRect().left);
                setY(event.clientY - event.currentTarget.getBoundingClientRect().top);
            }}
            onMouseLeave={(event) => {
                setX(event.clientX - event.currentTarget.getBoundingClientRect().left);
                setY(event.clientY - event.currentTarget.getBoundingClientRect().top);
            }}
            className={className}
            onClick={onClick}
        >
            <div
                className="Tile__fill"
                style={{
                    top: y,
                    left: x,
                }}
            />
            <div className="Tile__text">
                {title}
            </div>
        </div>
    )
};

export default Tile;