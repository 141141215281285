import Page from "@containers/Page/Page";
import PageContent from "@containers/PageContent/PageContent";
import Scrollbars from "react-custom-scrollbars";
import useDocumentViewData from "../useDocumentViewData";
import { useMemo } from "react";
import { useLocation } from "react-router";
import { iDocumentQueryParams } from "../DocumentView.helpers";
import { Link } from "react-router-dom";
import Spinner from "@components/new/Spinner/Spinner";
import SharePopup from "@pages/_shared/SharePopup/SharePopup";
import DocumentViewItem from "../DocumentViewItem/DocumentViewItem";
import { useSelector } from "react-redux";
import { shareDataSelector } from "@selectors/documents.selectors";
import qs from "query-string";

const Document = () => {
    const location = useLocation();
    const params = useMemo(
        () => qs.parse(location.search) as unknown as iDocumentQueryParams,
        [location.search],
    );
    const { getBackwardRoute, isLoading, data } = useDocumentViewData(params);
    const isSharePopupOpen = useSelector(shareDataSelector);
    return (
        /* @ts-ignore */
        <Page>
            <PageContent>
                <Scrollbars>
                    <div className="DocumentView">
                        <div className="DocumentView__header">
                            <Link
                                to={getBackwardRoute(params)} className="DocumentView__backward"
                                data-testid="buttonDocumentPageBack"
                            >
                                « more results
                            </Link>
                        </div>

                        <div className="DocumentView__body">
                            {isLoading && <Spinner modifiers={["full"]} />}
                            {!isLoading && <DocumentViewItem data={data} />}
                            {/* @ts-ignore */}
                            {isSharePopupOpen && <SharePopup />}
                        </div>
                    </div>
                </Scrollbars>
            </PageContent>
        </Page>
    );
};

export default Document;
