import {
    API__FoodProfilePageDataLiteViewModel,
    API__NewAllMenuTrendsPenetrationViewModel,
    API__NewPenetrationItemsViewModel,
    API__NewPenetrationRootObjectViewModel,
} from "@apiSchema";
import {
    iPermissionFields,
    iPermissionSection,
    PermissionLevelEnum,
    PermissionSection
} from "@models/PermissionSection";
import { ID } from "@models/index";
import { FoodProfileSegment, iFoodProfileSegment } from "@models/FoodProfileSegment";
import { equalInLC, prepareForMatching } from "@helpers";
import http from "@core/fetch";

const MAC_STAGE_ORDER = [
    'inception',
    'adoption',
    'proliferation',
    'ubiquity',
];

type API_Model = API__FoodProfilePageDataLiteViewModel;

interface iData {
    apiModel?: API_Model;
}

interface iMacWord {
    id: ID;
    name: string;
}

interface iMacStage {
    id: ID;
    title: string;
    isActive?: boolean;
    words: iMacWord[];
}

export interface iFoodProfileSummaryData {
    wordId: ID;
    wordName: string;
    totalPenetration: number;
    loveOrLikePercentage?: number;
    popularityTitle?: string;
    restaurantsCommonTitle: string;
    encryptedReportId: string;
    encryptedReportName: string;
    haikuPopupData: iPermissionSection<API__NewAllMenuTrendsPenetrationViewModel>;
    segments: iFoodProfileSegment[];
    filterLabel: string;
    flavorCategoryId: ID;
    flavorCategoryName: string;
    downloadReportUrl: string;
    macWords: iPermissionFields & {
        data: iMacStage[];
    };
}

export interface iFoodProfileSummary extends iPermissionFields {
    data: iFoodProfileSummaryData;
}

interface iSegmentItem {
    fieldName: keyof API__NewPenetrationItemsViewModel;
    title: string;
}

const segmentsConfig: iSegmentItem[] = [
    { title: 'Total US', fieldName: "all" },
    { title: 'QSR', fieldName: "qsr" },
    { title: 'Fast Casual', fieldName: "fastCasual" },
    { title: 'Midscale', fieldName: "midscale" },
    { title: 'Casual Dining', fieldName: "casualDining" },
    { title: 'Fine Dining', fieldName: "fineDining" },
];

export class FoodProfileSummary implements iFoodProfileSummary {
    permissionLevel = FoodProfileSummary.defaultData.permissionLevel;
    hasData = FoodProfileSummary.defaultData.hasData;
    data = FoodProfileSummary.defaultData.data;

    static defaultData: iFoodProfileSummary = {
        permissionLevel: PermissionLevelEnum.None,
        hasData: true,
        data: {
            wordId: 0,
            wordName: '',
            totalPenetration: 0,
            encryptedReportId: '',
            encryptedReportName: '',
            loveOrLikePercentage: 0,
            popularityTitle: '',
            restaurantsCommonTitle: '',
            filterLabel: '',
            haikuPopupData: {
                hasData: true,
                permissionLevel: PermissionLevelEnum.None,
            },
            segments: [],
            flavorCategoryId: 0,
            flavorCategoryName: '',
            downloadReportUrl: '',
            macWords: {
                hasData: false,
                permissionLevel: 0,
                data: [],
            },
        },
    };

    static fakeData: iFoodProfileSummaryData = {
        wordId: 0,
        wordName: '',
        totalPenetration: 50,
        encryptedReportId: '',
        encryptedReportName: '',
        loveOrLikePercentage: 100,
        popularityTitle: '',
        restaurantsCommonTitle: '',
        filterLabel: '',
        haikuPopupData: {
            hasData: true,
            permissionLevel: PermissionLevelEnum.None,
        },
        segments: [FoodProfileSegment.fakeData],
        flavorCategoryId: 0,
        flavorCategoryName: '',
        downloadReportUrl: '',
        macWords: {
            hasData: false,
            permissionLevel: 0,
            data: [],
        },
    };

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private setData(model: iFoodProfileSummary) {
        ({
            permissionLevel: this.permissionLevel,
            hasData: this.hasData,
            data: this.data,
        } = model);
    }

    private mapFromApi(rawData: API_Model) {
        let data: iFoodProfileSummaryData = FoodProfileSummary.fakeData;

        if (rawData.menuTrendsPenetration.data) {
            const {
                menuTrendsPenetration: {
                    data: {
                        penetrationItems,
                        suggestedMacWords,
                        categoryName,
                        categoryId,
                        macStage,
                    },
                },
                foodProfileId: wordId,
                foodProfileWord: wordName,
            } = rawData;

            const { all } = penetrationItems;

            const segments: FoodProfileSegment[] = segmentsConfig.map(i => (
                new FoodProfileSegment({
                    apiModel: {
                        title: i.title,
                        ...penetrationItems[i.fieldName],
                    },
                })
            ));

            const macWords: iMacStage[] = suggestedMacWords.map(stage => ({
                id: stage.macStageId,
                title: stage.macStageName,
                isActive: equalInLC(stage.macStageName, macStage || ''),
                words: stage.words.map(i => ({
                    id: i.id,
                    name: i.name,
                })),
            }));

            macWords.sort(({ title: a }, { title: b }) => {
                const aIndex = MAC_STAGE_ORDER.indexOf(prepareForMatching(a));
                const bIndex = MAC_STAGE_ORDER.indexOf(prepareForMatching(b));

                return aIndex < bIndex ? -1 : 1;
            });

            data = {
                wordId,
                wordName,
                encryptedReportId: all.encryptedReportId,
                encryptedReportName: all.encryptedReportName,
                totalPenetration: all.totalUsPenetration,
                restaurantsCommonTitle: FoodProfileSummary.getRestaurantsCommonTitle(all),
                popularityTitle: FoodProfileSummary.getPopularityTitle(rawData),
                filterLabel: FoodProfileSummary.getFilterLabel(rawData.menuTrendsPenetration.data),
                loveOrLikePercentage: rawData.flavorWordInfo.data?.data?.loveLikeIt?.generalData?.raw || 0,
                haikuPopupData: new PermissionSection({
                    hasData: true,
                    permissionLevel: PermissionSection.getPermissionLevel(all.predictedData.permissionLevel),
                    data: all,
                }),
                segments,
                flavorCategoryId: categoryId,
                flavorCategoryName: categoryName,
                downloadReportUrl: http.getQuery('download-report', {
                    reportId: all.encryptedReportId,
                    reportName: all.encryptedReportName,
                    wordName: 'foodProfile'
                }),
                macWords: {
                    hasData: suggestedMacWords.length > 0,
                    permissionLevel: PermissionLevelEnum.Full,
                    data: macWords,
                },
            };
        }

        this.setData({
            permissionLevel: PermissionSection.getPermissionLevel(rawData.menuTrendsPenetration.permissionLevel),
            hasData: rawData.menuTrendsPenetration.hasData,
            data,
        });
    }

    static getRestaurantsCommonTitle(data: API__NewAllMenuTrendsPenetrationViewModel): string {
        const { commonRestaurantType = '' } = data;

        switch (commonRestaurantType.toLowerCase()) {
            case 'found equally at chains and independents': {
                return 'Found equally at chain and independent restaurants.';
            }
            case 'independents': {
                return 'More common at independent restaurants.';
            }
            case 'chain': {
                return 'More common at chain restaurants.';
            }
            case 'regional': {
                return `More common at regional chain restaurants.`;
            }
            default: {
                return `More common at ${commonRestaurantType.toLowerCase()} restaurants.`;
            }
        }
    }

    static getPopularityTitle(data: API_Model): string {
        if (data.flavorWordInfo.hasData && data.flavorWordInfo.data) {
            let {
                config: {
                    respondentGroupTypeInfo,
                },
                data: {
                    mostPopular: {
                        ethnicities,
                        genders,
                        generations,
                        regions,
                    },
                },
            } = data.flavorWordInfo.data;

            const respondentsTypes = respondentGroupTypeInfo.flatMap(g => g.types);

            let ethnicities10 = (ethnicities || []).map(id => respondentsTypes.find(i => i.id === id)?.name).join(' | ');
            let genders10 = (genders || []).map(id => respondentsTypes.find(i => i.id === id)?.name).join(' | ');
            let generations10 = (generations || []).map(id => respondentsTypes.find(i => i.id === id)?.name).join(' | ');
            let regions10 = (regions || []).map(id => respondentsTypes.find(i => i.id === id)?.name).join(' | ');

            let popularityTitle;

            if (ethnicities10 === '' && genders10 === '' && generations10 === '' && regions10 === '') {
                popularityTitle = 'Equally popular with all consumer types.';
            } else if (ethnicities10 !== '' && genders10 !== '' && generations10 !== '' && regions10 !== '') {
                popularityTitle = `
                    Most popular with <b>${ethnicities10}</b>, <b>${genders10}</b>, <b>${generations10}</b> consumers.
                `;
            } else {
                popularityTitle = 'Most popular with';
                if (ethnicities10 !== '') {
                    popularityTitle = `${popularityTitle} <b>${ethnicities10}</b>,`;
                }
                if (genders10 !== '') {
                    popularityTitle = `${popularityTitle} <b>${genders10}</b>,`;
                }
                if (generations10 !== '') {
                    popularityTitle = `${popularityTitle} <b>${generations10}</b>,`;
                }
                if (regions10 !== '') {
                    popularityTitle = `${popularityTitle} <b>${regions10}</b>,`;
                }
                popularityTitle = `${popularityTitle.substring(0, popularityTitle.length - 1)} consumers.`;
            }

            return popularityTitle;
        }

        return '';
    }

    static getFilterLabel(data: API__NewPenetrationRootObjectViewModel): string {
        let { baseSize, penetrationItems: { all: { filterDescription } } } = data;
        let result = '';

        filterDescription = filterDescription || '';

        if (filterDescription.length > 0) result += `menu data filtered on <b>${filterDescription}</b>`;
        if (filterDescription.length > 0 && !!baseSize) result += ' | ';
        if (!!baseSize) result += `n=${String(baseSize).replace(/(.)(?=(\d{3})+$)/g, '$1,')} menu items`;

        return result;
    }
}