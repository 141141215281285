
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Scrollbar from 'react-custom-scrollbars';
import SpriteIcon from '../../components/SpriteIcon/SpriteIcon';
import Popup from '../../components/Popup/Popup';
import { setShareCancel, getEmailsData } from '../../actions/documents.actions';
import { companyMembersEmailsListSelector } from '../../selectors/groups.selectors';
import Http from '../../core/fetch';

class GroupsPopup extends Component {
    constructor(props) {
        super(props);
        const { groupMembers = [] } = this.props.data || {};
        this.scrollbarRef = createRef();
        this.suggestListRef = createRef();
        this.groupNameRef = createRef();
        this.searchEmailRef = createRef();
        this.inputOuterRef = createRef();
        this.state = {
            suggestedList: [],
            selectedList: groupMembers
                .map((member) => {
                    if (member.isCustomEmail) {
                        return {
                            ...member,
                            emailSubTitle: '',
                            userId: null,
                            type: 3,
                        };
                    }
                    return this.props.listOfEmails.find(({ userId }) => member.userId === userId);
                }),
            emailInvalid: false,
            groupName: this.props.data ? this.props.data.groupName : '',
            isNameUnique: false,
            isNameUniquenessChecking: false,
            initialName: this.props.data ? this.props.data.groupName : '',
            selectedOfSuggested: null,
        };
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.escKeyAction = this.escKeyAction.bind(this);
        this.handleClickTagInputArea = this.handleClickTagInputArea.bind(this);
        this.emailSizer = this.emailSizer.bind(this);
        this.handleClickInPopup = this.handleClickInPopup.bind(this);
    }

    componentDidMount() {
        this.props.getEmails();

        if (this.groupNameRef.current) {
            const length = this.groupNameRef.current.value.length;
            this.groupNameRef.current.focus();
            this.groupNameRef.current.setSelectionRange(length, length);
        }
        document.addEventListener('keyup', this.escKeyAction, false);
        document.querySelector('body').style.overflow = 'hidden';
    }

    componentDidUpdate(...args) {
        if (args[1].selectedList !== this.state.selectedList) {
            this.scrollbarRef.current.scrollToBottom();
        }

        if (this.state.selectedOfSuggested !== null) {
            const suggestList = this.suggestListRef.current;
            const item = suggestList ? suggestList.children[this.state.selectedOfSuggested] : false;

            if (item) {
                if (item.offsetTop + item.offsetHeight >= suggestList.scrollTop + suggestList.offsetHeight) {
                    suggestList.scrollTop = item.offsetTop + item.offsetHeight - suggestList.offsetHeight;
                }

                if (item.offsetTop <= suggestList.scrollTop) {
                    suggestList.scrollTop = item.offsetTop;
                }
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.escKeyAction, false);
        document.querySelector('body').style.overflow = 'visible';
    }

    escKeyAction(e) {
        if (e.which === 27) {
            if (this.state.suggestedList.length > 1) {
                this.setState({ suggestedList: [], selectedOfSuggested: null });
            } else {
                this.props.onCancel();
            }
        }
    }

    emailSizer(email) {
        let newEmail = '';

        if (email.length > 26) {
            newEmail = `${email.substring(0, 25)}...`;
        } else {
            newEmail = email;
        }

        return newEmail;
    }

    handleClickInPopup(e) {
        e.stopPropagation();
        if (this.state.suggestedList.length > 1) {
            this.setState({ suggestedList: [], selectedOfSuggested: null });
        }
    }

    handleCancelClick() {
        this.props.onCancel();
    }

    isAlreadySelected(email) {
        return this.state.selectedList.some(elem => elem.email.toLowerCase() === email.toLowerCase());
    }

    selectEmail(item) {
        if (this.isAlreadySelected(item.email)) return;
        const searchInput = document.getElementById('search_email');

        const emailsArray = searchInput.value.split(',').map(email => email.trim());
        const uniqueEmails = Array.from(new Set(emailsArray)).splice(0, emailsArray.length - 1);
        let newList = [...this.state.selectedList];
        uniqueEmails.map(email => {
            if (/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
                if (this.state.emailInvalid) this.setState({ emailInvalid: false });
                const suggestInList = this.props.listOfEmails.filter((elem) => {
                    return elem.email.toLowerCase() === email.toLowerCase();
                });
                const suggestInSelection = this.state.selectedList.filter((elem) => {
                    return elem.email.toLowerCase() === email.toLowerCase();
                });
                if (suggestInList.length) {
                    if (this.isAlreadySelected(email)) return;
                    newList.push(suggestInList[0]);
                } else if (!suggestInSelection.length) {
                    const customEmail = {
                        isCustomEmail: true,
                        email: email,
                        emailSubTitle: '',
                        type: 3,
                        groupId: null,
                        userId: null,
                    };
                    newList.push(customEmail);
                }
            }
        });
        newList.push(item);

        this.setState({
            suggestedList: [],
            selectedList: newList,
            selectedOfSuggested: null,
        });
        searchInput.value = '';

        this.handleChange({ target: { value: '' } });
        this.searchEmailRef.current.focus();
    }

    renderSuggestList() {
        return this.state.suggestedList
            .filter(elem => this.state.selectedList.findIndex(el => el.userId === elem.userId) < 0)
            .map((elem, index) => {
                const arr = elem.groupId !== 0 ? elem.email.split(' ') : elem.emailSubTitle.split(' ');
                let Abbr = '';
                arr.forEach((word, index) => {
                    if (index >= (elem.groupId !== 0 ? 1 : 2)) return;
                    Abbr += word.charAt(0);
                });
                return (<li
                    className={`popup__suggest-item ${this.state.selectedOfSuggested === index ? 'popup__suggest-item--selected' : ''}`}
                    key={index}
                    onClick={this.selectEmail.bind(this, elem)}
                >
                    <div className={`popup__suggest-circle ${elem.groupId && 'popup__suggest-circle--group'}`}>
                        {Abbr}
                    </div>

                    {
                        elem.groupId !== 0 ? (
                            <div>
                                <div className="popup__suggest-name">
                                    {elem.email}
                                </div>
                                <div className="popup__suggest-email">
                                    {elem.emailSubTitle}
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="popup__suggest-name">
                                    {elem.emailSubTitle}
                                </div>
                                <div className="popup__suggest-email" title={elem.email.length > 25 ? elem.email : ''}>
                                    {this.emailSizer(elem.email)}
                                </div>
                            </div>
                        )
                    }
                </li>);
            });
    }

    allowArrowsSelection(e, inputValue) {
        e.preventDefault();
        const { suggestedList, selectedOfSuggested, selectedList } = this.state;

        if (suggestedList.length < 1) return;

        switch (e.which) {
            case 38: // 'up'
                if (selectedOfSuggested === 0) {
                    break;
                }
                this.setState({
                    selectedOfSuggested: selectedOfSuggested - 1
                });
                break;

            case 40: // 'down'
                if (selectedOfSuggested === suggestedList.length - 1) {
                    break;
                }
                this.setState({
                    selectedOfSuggested: selectedOfSuggested + 1
                });

                if (selectedOfSuggested === null) this.setState({ selectedOfSuggested: 0 });
                break;

            case 13: // 'Enter'
                if(this.state.selectedOfSuggested !== null) {
                    // this.selectEmail(suggestedList.filter(elem => selectedList.filter(el => el === elem).length === 0)[selectedOfSuggested]);
                    const emailSelectedByArrows = suggestedList.filter(elem => selectedList.filter(el => el === elem).length === 0)[selectedOfSuggested];
                    const emailsArray = inputValue.split(',').map(email => email.trim());
                    const uniqueEmails = Array.from(new Set(emailsArray)).splice(0, emailsArray.length - 1);
                    let newList = [...this.state.selectedList];
                    uniqueEmails.map(email => {
                        if (/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
                            if (this.state.emailInvalid) this.setState({ emailInvalid: false });
                            const suggestInList = this.props.listOfEmails.filter((elem) => {
                                return elem.email.toLowerCase() === email.toLowerCase();
                            });
                            const suggestInSelection = this.state.selectedList.filter((elem) => {
                                return elem.email.toLowerCase() === email.toLowerCase();
                            });
                            if (suggestInList.length) {
                                if (this.isAlreadySelected(email)) return;
                                newList.push(suggestInList[0]);
                            } else if (!suggestInSelection.length) {
                                const customEmail = {
                                    isCustomEmail: true,
                                    email: email,
                                    emailSubTitle: '',
                                    type: 3,
                                    groupId: null,
                                    userId: null,
                                };
                                newList.push(customEmail);
                            }
                        }
                    });
                    newList.push(emailSelectedByArrows);

                    this.setState({
                        suggestedList: [],
                        selectedList: newList,
                        selectedOfSuggested: null,
                    });
                    e.target.value = '';
                }
                break;

            default: break;
        }
    }

    handleChange(e) {
        const searchQuery = e.target.value.toLowerCase();
        const lastCommaIndex = searchQuery.lastIndexOf(',');
        const updatedSearchQuery = searchQuery.substring(lastCommaIndex+1, ).trim();
        const items = this.props.listOfEmails.filter((elem) => {
            const searchName = elem.emailSubTitle.toLowerCase();
            const searchEmail = elem.email.toLowerCase();
            return (searchName.indexOf(updatedSearchQuery) !== -1 || searchEmail.indexOf(updatedSearchQuery) !== -1) &&
                updatedSearchQuery.length > 0;
        });

        this.setState({ suggestedList: items, searchQuery });
    }

    handleKeyUp(e) {
        if (this.state.emailInvalid) this.setState({ emailInvalid: false });
        const inputValue = e.target.value.trim();
        if (e.which === 13) { // enter
            const uniqueEmails = Array.from(new Set(inputValue.split(',').map(email => email.trim())));
            let newList = [...this.state.selectedList];
            uniqueEmails.map(email => {
                if (/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
                    if (this.state.emailInvalid) this.setState({ emailInvalid: false });
                    const suggestInList = this.props.listOfEmails.filter((elem) => {
                        return elem.email.toLowerCase() === email.toLowerCase();
                    });
                    const suggestInSelection = this.state.selectedList.filter((elem) => {
                        return elem.email.toLowerCase() === email.toLowerCase();
                    });
                    if (suggestInList.length) {
                        if (this.isAlreadySelected(email)) return;
                        newList.push(suggestInList[0]);
                    } else if (!suggestInSelection.length) {
                        const customEmail = {
                            isCustomEmail: true,
                            email: email,
                            emailSubTitle: '',
                            type: 3,
                            groupId: null,
                            userId: null,
                        };
                        newList.push(customEmail);
                    }
                } else {
                    if (this.state.selectedOfSuggested === null) {
                        this.setState({ emailInvalid: true });
                    }
                }
            });
            e.target.value = '';
            this.setState({
                selectedOfSuggested: null,
                suggestedList: [],
                selectedList: newList,
            })
        }
        this.allowArrowsSelection(e, inputValue);
    }

    showAllList(e) {
        e.stopPropagation();
        this.setState({ suggestedList: this.props.listOfEmails });
        this.searchEmailRef.current.focus();
    }

    removeSelectedItem(index) {
        this.setState({
            selectedList: this.state.selectedList.filter((elem, i) => i !== index),
        });
    }

    renderSelectedList() {
        return this.state.selectedList.map((elem, index) => {
            const classList = new Set([
                'popup__suggest-item',
                elem.groupId !== 0 ? 'popup__suggest-item--group' : '',
            ]);

            return (<div
                className={Array.from(classList).join(' ')}
                key={index}
            >
                <div className="popup__suggest-name">
                    {elem.emailSubTitle && elem.emailSubTitle.length ? elem.emailSubTitle : elem.email}
                </div>
                <div
                    className="popup__remove"
                    onClick={this.removeSelectedItem.bind(this, index)}
                >
                    <SpriteIcon iconId="remove"/>
                </div>
            </div>);
        });
    }

    handleClickTagInputArea() {
        this.searchEmailRef.current.focus();
    }

    handleChangeGroupName(e) {
        const name = e.target.value;
        this.setState({
            groupName: name,
            isNameUniquenessChecking: true,
        });
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            Http.post('groups check name uniqueness', { name })
                .then((response) => {
                    this.setState({
                        isNameUnique: response,
                        isNameUniquenessChecking: false,
                    });
                });
        }, 300);
    }

    handleSaveClick() {
        const groupNameInput = document.getElementById('group_name');
        if (groupNameInput && groupNameInput.value.trim().length < 1) return;
        const members = [];
        this.state.selectedList.forEach((elem) => {
            members.push({
                email: elem.email,
                isCustomEmail: elem.isCustomEmail,
            });
        });
        const data = {};
        switch (this.props.popupType) {
            case 'CREATE_GROUP':
                data.name = document.getElementById('group_name').value;
                data.members = members;
                break;
            case 'EDIT_GROUP':
                data.name = document.getElementById('group_name').value;
                data.members = members;
                data.id = this.props.data.groupId;
                break;
            default:
                break;
        }
        this.props.onSave(data);
    }

    renderGroupNameEditInput() {
        const { groupName, initialName, isNameUnique, isNameUniquenessChecking } = this.state;

        return (
            <div className="popup__input-wrapper relative">
                <label className="popup__input-label">Team name</label>
                <input
                    className="popup__project-input"
                    type="text"
                    id="group_name"
                    ref={this.groupNameRef}
                    onChange={this.handleChangeGroupName.bind(this)}
                    placeholder="Give me a name"
                    value={this.state.groupName}
                    autoFocus={true}
                />
            </div>
        );
    }

    renderMembersInput() {
        return (
            <div className="popup__input-wrapper">
                <label className="popup__input-label">Members</label>
                <span className="popup__input popup__input--fake">
                    <input
                        className="popup__input"
                        type="text"
                        id="search_email"
                        ref={this.searchEmailRef}
                        onChange={this.handleChange.bind(this)}
                        onKeyUp={this.handleKeyUp.bind(this)}
                        placeholder="Search colleagues or enter an email"
                    />
                    {this.state.searchQuery}
                </span>
                <div className="popup__suggest">
                    {
                        this.state.suggestedList.length > 0 &&
                        (<ul className="popup__suggest-list" ref={this.suggestListRef}>
                            {this.renderSuggestList()}
                        </ul>)
                    }
                </div>
                <div
                    className="popup__input-outer popup__input-outer--no-indent"
                    ref={this.inputOuterRef}
                    onClick={this.handleClickTagInputArea}
                >
                    <div className="popup__input-message">
                        {this.state.emailInvalid ? (
                            <div className="popup__error-email">
                                invalid email
                            </div>
                        ) : (
                            <div
                                className="popup__all-list"
                                onClick={this.showAllList.bind(this)}
                            >
                                view contacts
                            </div>
                        )}
                    </div>
                    <Scrollbar
                        ref={this.scrollbarRef}
                        renderTrackVertical={props => <div {...props} className="scrollbar-vertical"/>}
                    >
                        {this.state.selectedList.length > 0 && this.renderSelectedList()}
                    </Scrollbar>
                </div>
            </div>
        );
    }

    renderMainTools() {
        const { groupName, initialName, isNameUnique, isNameUniquenessChecking, selectedList } = this.state;
        const isDisabledSave = selectedList.length < 1
            || (groupName !== undefined && groupName.trim().length < 1)
            || isNameUniquenessChecking
            || (!isNameUnique && groupName.trim() !== initialName.trim());
        const { title } = this.props;

        let errorMsgText = '';

        if (groupName.trim() !== '' && (!isNameUnique && groupName.trim() !== initialName) && !isNameUniquenessChecking) {
            errorMsgText = 'this name is not available';
        }

        return (
            <div>
                <div className="popup__header">
                    <div className="popup__header-title">
                        {title}
                    </div>
                </div>
                <div className="popup__main-content">
                    {this.renderGroupNameEditInput()}
                    {this.renderMembersInput()}
                    <div className="popup__footer-wrapper">
                        <div className="popup__options popup__options--bottom">
                            <button
                                className="popup__link popup__link--md"
                                onClick={this.handleCancelClick}
                            >
                                cancel
                            </button>
                            <button
                                className="popup__btn popup__btn--md"
                                onClick={this.handleSaveClick.bind(this)}
                                disabled={isDisabledSave}
                            >
                                {this.props.popupType === 'EDIT_GROUP' ? 'Save' : 'Create'}
                            </button>
                        </div>
                        <div className="popup__error-msg popup__error-msg--team-create">
                            {errorMsgText}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleClickInPopup(e) {
        e.stopPropagation();
        if (this.state.suggestedList.length > 1) {
            this.setState({ suggestedList: [], selectedOfSuggested: null });
        }
    }

    render() {
        return (
            <Popup
                className="popup groups-popup popup--fade-in"
                onClose={this.props.onCancel}
                isOpened={this.props.isOpened}
            >
                <div className="popup__inner popup__inner--xs" onClick={this.handleClickInPopup}>
                    <div className="popup__main">
                        {
                            this.renderMainTools()
                        }
                    </div>
                </div>
            </Popup>
        );
    }
}

GroupsPopup.propTypes = {
    cancelShare: PropTypes.func,
    data: PropTypes.object,
    getEmails: PropTypes.func,
    listOfEmails: PropTypes.array,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    popupType: PropTypes.string,
    shareLinkString: PropTypes.string,
    title: PropTypes.string,
};

const mapStateToProps = state => ({
    listOfEmails: companyMembersEmailsListSelector(state),
});

const mapDispatchToProps = dispatch => ({
    cancelShare: data => dispatch(setShareCancel(data)),
    getEmails: () => dispatch(getEmailsData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsPopup);
