import { FC, useContext } from "react";
import { sanitizeUrl } from "@helpers";
import TileSubTitle from "./TileSubTitle";
import { iReportProPublication } from "@models/ReportProPublication";
import { AppContext } from "../../AppContext";
import './ReportProTile.style.scss';
import cn from "classnames";

export enum ReportProTileOption {
    Regular = '',
    Wide = 'wide',
    DynamicHeight = 'dyn-h',
    SmallTitle = 'title-s',
}

const ReportProTile: FC<{
    data?: iReportProPublication;
    options?: ReportProTileOption[];
    testId?: string;
}> = (
    {
        data,
        options,
        testId,
    }
) => {
    const {
        globalVariables: {
            reportProUrl,
        },
    } = useContext(AppContext);

    if (data) {
        return (
            <a
                key={data.id}
                target="_blank"
                href={sanitizeUrl(`${reportProUrl}/details/${data.id}`)}
                className={cn(
                    "ReportProTile",
                    !!options && options.map(option => `is-${option}`),
                )}
                data-testid={testId}
            >
                <img src={data.thumbnail} alt="" className="ReportProTile__img"/>
                <div className="ReportProTile__content">
                    <div className="ReportProTile__title">
                        {data.title}
                    </div>
                    <TileSubTitle data={data}/>
                    <div className="ReportProTile__description">
                        {data.description}
                    </div>
                </div>
            </a>
        );
    }

    return (
        <div className="ReportProTile"/>
    );
};

export default ReportProTile;