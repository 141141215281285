import { FC, useCallback, useRef, useState } from "react";
import ContentWidget from "@components/ContentWidget/ContentWidget";
import './FoodProfileVersatility.scss';
import cn from "classnames";
import ContentWidgetLink from "@components/ContentWidget/ContentWidgetLink";
import { AnalyticsAttribute } from "@models/AnalyticsAttributes";
import useOutsideClick from "@hooks/useOutsideClick";

export interface iVersatilityData {
    menuVersatility: number,
    foodVersatility: number,
}

const FoodProfileVersatility: FC<{
    data: iVersatilityData;
}> = (
    {
        data,
    }
) => {
    const container = useRef<HTMLDivElement>(null);
    const [isOpened, setIsOpened] = useState(false);

    const close = useCallback(() => setIsOpened(false), []);

    useOutsideClick({
        ref: container,
        isDisabled: !isOpened,
        fn: close,
    });

    const showDetailInfo = function () {
        setIsOpened(!isOpened);
    }

    return (
        <ContentWidget
            title="Versatility"
            className={cn("FoodProfileVersatility",isOpened && 'is-opened')}
            titleTestId="textFoodProfileVersatilityTitle"
        >
            <div className="FoodProfileVersatility__section-wrapper" ref={container}>
                <div className="FoodProfileVersatility__section">
                    <div
                        className="FoodProfileVersatility__number"
                        data-testid="textFoodProfileVersatilityMenuVersatilityValue"
                    >
                        {data.menuVersatility}
                    </div>
                    <div
                        className="FoodProfileVersatility__title"
                        data-testid="textFoodProfileVersatilityMenuVersatilityTitle"
                    >
                        menu versatility <br/>score
                    </div>
                </div>
                <div className="FoodProfileVersatility__section">
                    <div
                        className="FoodProfileVersatility__number"
                        data-testid="textFoodProfileVersatilityFoodVersatilityValue"
                    >
                        {data.foodVersatility}
                    </div>
                    <div
                        className="FoodProfileVersatility__title"
                        data-testid="textFoodProfileVersatilityFoodVersatilityTitle"
                    >
                        food versatility <br/>score
                    </div>
                </div>
            </div>
            <ContentWidgetLink
                onClick={showDetailInfo}
                analyticsData={{
                    [AnalyticsAttribute.ClickTitle]: 'Versatility',
                }}
                testId={"buttonFoodProfileVersatilityExpand"}
            >
                what's this
            </ContentWidgetLink>

            {/*  Versatility Info  */}
            <div className={cn(
                "FoodProfileVersatility__info",
                isOpened && "FoodProfileVersatility__info--show"
            )}>
                <div className="FoodProfileVersatility__section-wrapper">
                    <div
                        className="FoodProfileVersatility__section FoodProfileVersatility__section--small font-text"
                        data-testid="textFoodProfileVersatilityDescription1"
                    >
                        is measured on a scale from 0 to 100 (with 50 being an item with average versatility).
                    </div>
                    <div
                        className="FoodProfileVersatility__section FoodProfileVersatility__section--small font-text"
                        data-testid="textFoodProfileVersatilityDescription2"
                    >
                        <b>MENU VERSATILITY</b> indicates whether an item is used in many different cuisines / types of
                        restaurants.
                    </div>
                    <div
                        className="FoodProfileVersatility__section FoodProfileVersatility__section--small font-text"
                        data-testid="textFoodProfileVersatilityDescription3"
                    >
                        <b>FOOD VERSATILITY</b> indicates whether an item is used in many different applications /
                        dishes.
                    </div>
                </div>
                <ContentWidgetLink
                    modifiers={["light"]}
                    onClick={showDetailInfo}
                    analyticsData={{
                        [AnalyticsAttribute.ClickTitle]: 'Versatility',
                    }}
                    testId={"buttonFoodProfileVersatilityCollapse"}
                >
                    okay, thanks
                </ContentWidgetLink>
            </div>
            {/*  Versatility Info  */}

        </ContentWidget>
    );
};
export default FoodProfileVersatility;