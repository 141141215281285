import { iTerrain, Terrain, TerrainEnum } from "./Terrain";
import { API__ChainPermissionWrapper, API__InsightsWrapper } from "../apiSchema";
import { iPermissionFields, PermissionLevelEnum, PermissionSection } from "./PermissionSection";

interface API__CompetitorsItem {
    averageDistance: string;
    chainIdFrom: string;
    chainIdTo: string;
    chainName: string;
    common_metros: string;
    percent_1_miles: number;
    percent_3_miles: number;
    percent_05_miles: number;
    percent_5_miles: number;
    percent_10_miles: number;
    percent_20_miles: number;
    percent_50_miles: number;
    summaryActionName: string;
}

interface API__Market {
    density: number;
    number_of_stores: string;
    percent_of_all_stores: number;
    region: string;
}

export interface API__Population {
    index: number;
    name: string;
    percent: string;
}

export interface iPopulation {
    index: number;
    name: string;
    percent: number;
}


export interface API_Location {
    competitors: {
        chainName: string;
        currentPage: string;
        items: API__CompetitorsItem[];
        rowsPerPage: string;
        status: string;
        totalCount: number;
        totalPages: number;
    };
    marketAreas: {
        metroMarketAreas: API__Market[];
        stateMarketAreas: API__Market[];
    };
    residentPopulation: {
        population: string;
        statistic: {
            birthPlace: API__Population[];
            ethnicity: API__Population[];
            gender: API__Population[];
            generations: API__Population[];
            languages: API__Population[];
        };
    };
}
export interface iCompetitorsItemPercents {
    miles05: number;
    miles1: number;
    miles3: number;
}
export interface iCompetitorsItem {
    averageDistance: number;
    chainIdFrom: string;
    chainIdTo: string;
    chainName: string;
    commonMetros: number;
    percents: iCompetitorsItemPercents;
}

export interface iMarket {
    density: number;
    numberOfStores: number;
    percentOfAllStores: number;
    region: string;
}

export interface iPopulationStatistic {
    birthPlace: iPopulation[];
    ethnicity: iPopulation[];
    gender: iPopulation[];
    generations: iPopulation[];
    languages: iPopulation[];
}

export interface iLocation {
    competitors: {
        chainName: string;
        items: iCompetitorsItem[];
    };
    marketAreas: {
        metroMarketAreas: iMarket[];
        stateMarketAreas: iMarket[];
    };
    residentPopulation: {
        population: string;
        statistic: iPopulationStatistic;
    };
    terrains: iTerrain[],
}

interface iData {
    apiModel: API__InsightsWrapper | undefined;
}

export class Location implements iLocation {
    competitors = Location.defaultData.competitors;
    marketAreas = Location.defaultData.marketAreas;
    residentPopulation = Location.defaultData.residentPopulation;
    terrains = Location.defaultData.terrains;

    static defaultData: iLocation = {
        competitors: {
            chainName: "",
            items: [],
        },
        marketAreas: {
            metroMarketAreas: [],
            stateMarketAreas: [],
        },
        residentPopulation: {
            population: "",
            statistic: {
                birthPlace: [],
                ethnicity: [],
                gender: [],
                generations: [],
                languages: [],
            },
        },
        terrains: [],
    };

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private setData(model: iLocation) {
        ({
            competitors: this.competitors,
            marketAreas: this.marketAreas,
            residentPopulation: this.residentPopulation,
            terrains: this.terrains,
        } = model);
    }

    private mapPopulationStatistic(data: API__Population[] = []) {
        return data.map(item => ({
            index: item.index || 0,
            percent: Number(item.percent) || 0,
            name: item.name || ""
        }))
    }

    private mapFromApi(data: API__InsightsWrapper) {
        const {
            competitors,
            marketAreas,
            residentPopulation,
            locations,
        } = data;

        this.setData({
            competitors: {
                chainName: competitors.chainName,
                items: (competitors.items || []).map(item => ({
                    averageDistance: item.averageDistance || 0,
                    chainIdFrom: item.chainIdFrom || "",
                    chainIdTo: item.chainIdTo || "",
                    chainName: item.chainName || "",
                    commonMetros: Number(item.common_metros) || 0,
                    percents: {
                        miles05: item.percent_05_miles || 0,
                        miles1: item.percent_1_miles || 0,
                        miles3: item.percent_3_miles || 0,
                    },
                })),
            },
            marketAreas: {
                metroMarketAreas: (marketAreas.metroMarketAreas || []).map(item => ({
                    density: item.density || 0,
                    numberOfStores: Number(item.number_of_stores) || 0,
                    percentOfAllStores: item.percent_of_all_stores || 0,
                    region: item.region || "",
                })),
                stateMarketAreas: (marketAreas.stateMarketAreas || []).map(item => ({
                    density: item.density || 0,
                    numberOfStores: Number(item.number_of_stores) || 0,
                    percentOfAllStores: item.percent_of_all_stores || 0,
                    region: item.region || "",
                })),
            },
            residentPopulation: {
                population: residentPopulation.population,
                statistic: {
                    gender: this.mapPopulationStatistic(residentPopulation.statistic.gender),
                    ethnicity: this.mapPopulationStatistic(residentPopulation.statistic.ethnicity),
                    languages: this.mapPopulationStatistic(residentPopulation.statistic.languages),
                    generations: this.mapPopulationStatistic(
                        residentPopulation.statistic.generations.map(i => ({ ...i, percent: `${i.percent}` }))
                    ),
                    birthPlace: this.mapPopulationStatistic(residentPopulation.statistic.birthPlace),
                }
            },
            terrains: Terrain.parseAllFromApi(locations),
        });
    }
}

export interface iLocationsData extends iPermissionFields {
    data: iLocation;
}

interface iLocationsDataProps {
    apiModel?: API__ChainPermissionWrapper<API__InsightsWrapper>;
}

export class LocationsData implements iLocationsData {
    static defaultData: iLocationsData = {
        ...new PermissionSection(),
        data: new Location(),
    };

    permissionLevel = LocationsData.defaultData.permissionLevel;
    hasData = LocationsData.defaultData.hasData;
    data = LocationsData.defaultData.data;

    constructor(data?: iLocationsDataProps) {
        if (data) {
            if (data.apiModel) {
                this.mapFromApi(data.apiModel);
            }
        }
    }

    private setData(data: iLocationsData) {
        ({
            permissionLevel: this.permissionLevel,
            hasData: this.hasData,
            data: this.data,
        } = data);
    }

    mapFromApi(data: API__ChainPermissionWrapper<API__InsightsWrapper>) {
        const permissionLevel = PermissionSection.getPermissionLevel(data.permissionLevel);

        this.setData({
            permissionLevel,
            hasData: data.hasData,
            data:
                permissionLevel === PermissionLevelEnum.None
                    ? {
                        ...new Location(),
                        terrains: [
                            {
                                name: TerrainEnum.Urban,
                                stores: 0,
                                percentLocation: 0,
                            },
                            {
                                name: TerrainEnum.Suburban,
                                stores: 0,
                                percentLocation: 0,
                            },
                            {
                                name: TerrainEnum.Rural,
                                stores: 0,
                                percentLocation: 0,
                            }
                        ],
                    }
                    : new Location({ apiModel: data.data }),
        });
    }
}