import http from '../..//core/fetch';
import { DOCUMENT_DELETE_POPUP } from '../../actions/actionsNamesList';
import { updatePageToReload } from '..//DocumentsLibraryPopups/documentsLibraryPopups.actions';

const { OPEN, CLOSE, SET_DATA, RESET_DATA } = DOCUMENT_DELETE_POPUP;

export function startDeleting(data) {
    return (dispatch) => {
        dispatch(setData(data));
        dispatch(open());
    };
}

export function open() {
    return {
        type: OPEN
    };
}

export function close() {
    return {
        type: CLOSE
    };
}

export function setData(project) {
    return {
        type: SET_DATA,
        data: project
    };
}

export function resetData() {
    return {
        type: RESET_DATA
    };
}

export function deleting() {
    return (dispatch, getState) => {
        const stateData = getState().documentDeletePopupData;
        const { customerViewCompanyId } = getState().documentsLibraryPopupsData;
        const params = { docId: stateData.id };
        if (customerViewCompanyId !== null) {
            params.companyId = customerViewCompanyId;
        }
        http.post(
            'delete document',
            params,
            { headers: { accept: 'application/json' } }
        )
            .then(() => {
                dispatch(close());
                dispatch(updatePageToReload());
            });
    };
}
