import { FC, useEffect, useState } from "react";
import './Filters.styles.scss';
import cn from "classnames";
import { ChainsFilters, iChainsFilters } from "../../../core/models/ChainsFilters";
import { iTab } from "./Filters.helpers";
import { useFiltersTabs } from "./useTabsData";
import Button from "../../../components/new/Button/Button";
import { LocationsTab } from "../NewTopChains";
import { Scrollbars } from "react-custom-scrollbars";
import { CustomBaseScrollbars } from "@components/CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";

interface iProps {
    data: iChainsFilters;
    searchQuery: string;
    setSearchQuery: (searchQuery: string) => void;
    selectedFilters: iChainsFilters;
    applyFilters: (data: iChainsFilters) => void;
    selectedLocationsTab: LocationsTab;
    setSelectedLocationsTab: (tab: LocationsTab) => void;
    testId?: string;
}

const Filters: FC<iProps> = (
    {
        data,
        selectedFilters,
        applyFilters,
        selectedLocationsTab,
        setSelectedLocationsTab,
        searchQuery,
        setSearchQuery,
        testId,
    }
) => {
    const [isOpened, setIsOpened] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [localSelectedFilters, setLocalSelectedFilters] = useState<iChainsFilters>(new ChainsFilters());
    const filtersClassName = cn('Filters', isOpened && 'is-opened');

    const resetFilters = () => {
        setLocalSelectedFilters(new ChainsFilters({
            subModel: {
                maxUnits: data.maxUnits,
            }
        }));
    }

    const handleApplyFilters = () => {
        applyFilters(localSelectedFilters);
    };

    const handleFilterChange = (filterName: keyof ChainsFilters) => (value: any) => {
        setLocalSelectedFilters(new ChainsFilters({
            subModel: {
                ...localSelectedFilters,
                [filterName]: value,
            }
        }));
    };

    const tabs: iTab[] = useFiltersTabs(
        localSelectedFilters,
        data,
        handleFilterChange,
        selectedLocationsTab,
        setSelectedLocationsTab,
        searchQuery,
        setSearchQuery,
    );
    const isValid = tabs.every(i => !i.isInvalid);

    useEffect(() => {
        setLocalSelectedFilters(new ChainsFilters({ subModel: selectedFilters }));
        setIsOpened(false);
    }, [selectedFilters]);

    useEffect(() => {
        if (!isOpened) {
            setLocalSelectedFilters(new ChainsFilters({ subModel: selectedFilters }));
        }
    }, [isOpened]);

    return (
        <div
            className={filtersClassName}
            data-testid={testId}
        >
            <div className="Filters__header FiltersHeader">
                {tabs.map((tab, index) => {
                    const className = cn(
                        'FiltersHeader__item',
                        'FiltersThumb',
                        isOpened && index === activeTabIndex && !!tab.content && 'FiltersThumb--active',
                        tab.isModified && 'FiltersThumb--modified',
                        tab.isInvalid && 'FiltersThumb--invalid',
                    );

                    return (
                        <div
                            key={tab.title}
                            className={className}
                            onClick={() => {
                                if (!isOpened) setIsOpened(true);
                                setActiveTabIndex(index);
                            }}
                        >
                            {!!tab.title && (
                                <div
                                    className={cn(
                                        'FiltersThumb__title',
                                        !!tab.subTitle && 'is-compressed',
                                    )}
                                >
                                    {tab.title}
                                </div>
                            )}
                            {!!tab.subTitle && (
                                <div className="FiltersThumb__sub-title">
                                    {tab.subTitle}
                                </div>
                            )}
                            {!!tab.renderThumb && (
                                <div className="FiltersThumb__thumb-content">
                                    {tab.renderThumb}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
            {!!tabs[activeTabIndex].content && (
                <>
                    <div className="Filters__overlay" onClick={() => setIsOpened(false)}/>
                    <div className="Filters__content FiltersContent">
                        <div className="FiltersContent__body FiltersContent__body--scroll">
                            <CustomBaseScrollbars>
                                {tabs[activeTabIndex].content}
                            </CustomBaseScrollbars>
                        </div>
                        <div className="FiltersContent__footer">
                            <div className="FiltersContent__part">
                                <Button onClick={resetFilters} modifiers={["secondary"]}>
                                    RESET FILTERS
                                </Button>
                            </div>
                            <div className="FiltersContent__part">
                                <Button onClick={() => setIsOpened(false)} modifiers={["secondary"]}>
                                    CANCEL
                                </Button>
                                <Button
                                    onClick={handleApplyFilters}
                                    modifiers={["primary"]}
                                    isDisabled={!isValid}
                                >
                                    APPLY
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Filters