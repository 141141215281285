import { FC } from "react";
import classNames from "classnames";
import { IconStar } from "../../../../../../icons";
import { iScore } from "../../../../../../core/models/LTO";
import "./Scores.styles.scss";

interface iProps {
    data: iScore[];
    score: number;
    title: string;
    className?: string;
    message?: string;
}
const DEFAULT_VALUE_PLACEHOLDER = "--";
const Scores: FC<iProps> = ({ data, score, title, className, message }) => {
    const classes = classNames("Scores", className);

    return (
        <div className={classes}>
            <div className="Scores__wrapper">
                <div
                    className="Scores__list"
                    data-testid="widgetLtoMetrics"
                >
                    {data.map((item, index) => {
                        const value = item.value ? `${Math.round(item.value)}%` : DEFAULT_VALUE_PLACEHOLDER;
                        return (
                            <div
                                className="Scores__item" key={item.key}
                                data-testid={`widgetLtoMetric${index}`}
                            >
                                <div
                                    className="Scores__label"
                                    data-testid={`textLtoMetricKey`}
                                >
                                    {item.key}
                                </div>
                                <div
                                    className="Scores__value"
                                    data-testid={`textLtoMetricValue`}
                                >
                                    {value}
                                </div>
                                <div
                                    className={`Scores__stars Scores__stars--${item.starsCount}`}
                                    data-testid={`textLtoMetricStars`}
                                >
                                    {Array(item.starsCount)
                                        .fill(0)
                                        .map((_, i) => (
                                            <IconStar key={i} width={10} height={10} />
                                        ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="Scores__total">
                    <div
                        className="Scores__total-title"
                        data-testid={`textLtoScoreLabel`}
                    >
                        Score
                    </div>
                    <div
                        className="Scores__total-value"
                        data-testid={`textLtoScoreValue`}
                    >
                        {score}
                    </div>
                    <div
                        className="Scores__total-label"
                        data-testid={`textLtoScoreTitle`}
                    >
                        {title}
                    </div>
                </div>
            </div>
            {message && <div className="Scores__message">{message}</div>}
        </div>
    );
};

export default Scores;
