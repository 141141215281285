import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Scrollbar from 'react-custom-scrollbars';
import SpriteIcon from '../../../components/SpriteIcon/SpriteIcon';
import Popup from '../../../components/Popup/Popup';
import TextArea from 'react-textarea-autosize';
import {
    setShareCancel,
    getEmailsData,
    shareDocumentLink,
} from '../../../actions/documents.actions';
import { addNotification } from '../../../components/GlobalNotification/globalNotification.actions';
import { shareUrlSelector, shareEmailsListSelector } from '../../../selectors/documents.selectors';
import './SharePopup.scss';

const sharePopupConfig = {
    allDocuments: {
        placeholder: 'Check out this item from SNAP!',
    },
    myDocuments: {
        placeholder: 'Check out this interesting file from SNAP!',
    },
};

class SharePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestedList: [],
            selectedList: [],
            emailInvalid: false,
            inputWidth: 280,
            decorateTextarea: false,
            isInputMultiline: false,
            selectedOfSuggested: null,
        };
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.escKeyAction = this.escKeyAction.bind(this);
        this.handleClickInPopup = this.handleClickInPopup.bind(this);
        this.handleClickTagInputArea = this.handleClickTagInputArea.bind(this);
    }

    componentDidMount() {
        this.props.getEmails();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedList.length !== this.state.selectedList.length) {
            setTimeout(() => {
                const scrollBar = this.refs.scrollbar;
                const inputInnerHeight = scrollBar.container.firstElementChild.firstElementChild.offsetHeight;
                const inputOuterHeight = this.refs.input_outer.offsetHeight;
                scrollBar.scrollToBottom();
                if (inputInnerHeight > inputOuterHeight) {
                    this.setState({
                        isInputMultiline: true
                    });
                } else {
                    if (inputInnerHeight < inputOuterHeight && Math.round( inputOuterHeight / inputInnerHeight) !== 1)
                        this.setState({
                            isInputMultiline: false
                        });
                }
            }, 400);
        }

        if (this.state.selectedOfSuggested !== null) {
            const suggestList = this.refs.suggest_list;
            const item = suggestList ? suggestList.children[this.state.selectedOfSuggested] : false;

            if (item) {
                if (item.offsetTop + item.offsetHeight >= suggestList.scrollTop + suggestList.offsetHeight) {
                    suggestList.scrollTop = item.offsetTop + item.offsetHeight - suggestList.offsetHeight;
                }

                if (item.offsetTop <= suggestList.scrollTop) {
                    suggestList.scrollTop = item.offsetTop;
                }
            }
        }
    }

    copyToClipboard() {
        const dummy = document.createElement('input');
        document.body.appendChild(dummy);
        dummy.setAttribute('id', 'dummy_id');
        document.getElementById('dummy_id').value = this.props.shareLinkString || this.props.itemData.shareLinkString;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
        this.props.showNotification({
            text: 'Link copied to your clipboard. You can paste it into email or any else you need.',
            duration: 3000,
        });
    }

    componentDidMount() {
        document.addEventListener('keyup', this.escKeyAction, false);
        document.querySelector('body').style.overflow = 'hidden';
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.escKeyAction, false);
        document.querySelector('body').style.overflow = 'visible';
    }

    escKeyAction(e) {
        if (e.which === 27) {
            if (this.state.suggestedList.length > 1) {
                this.setState({ suggestedList: [], selectedOfSuggested: null });
            } else {
                this.props.cancelShare();
            }
        }
    }

    handleClickInPopup(e) {
        e.stopPropagation();
        if (this.state.suggestedList.length > 1) {
            this.setState({ suggestedList: [], selectedOfSuggested: null });
        }
    }

    handleCancelClick() {
        this.props.cancelShare();
    }

    isAlreadySelected(email) {
        return this.state.selectedList.some(elem => elem.email.toLowerCase() === email.toLowerCase());
    }

    selectEmail(item) {
        if (this.isAlreadySelected(item.email)) return;
        const newList = [].concat(this.state.selectedList, item);
        this.setState({
            selectedList: newList,
        });
        const searchInput = document.getElementById('search_email');
        searchInput.value = '';
        this.handleChange({ target: { value: '' } });

        this.refs.search_email.focus();
    }

    renderSuggestList() {
        return this.state.suggestedList
            .filter(elem => this.state.selectedList.filter(el => el === elem).length === 0)
            .map((elem, index) => {
                const arr = elem.groupId !== 0 ? elem.email.split(' ') : elem.emailSubTitle.split(' ');
                let Abbr = '';
                arr.forEach((word, index) => {
                    if (index >= (elem.groupId !== 0 ? 1 : 2)) return;
                    Abbr += word.charAt(0);
                });
                const classList = new Set([
                    'popup__suggest-item',
                    this.state.selectedOfSuggested === index ? 'popup__suggest-item--selected' : ''
                ]);
                return (<li
                    className={Array.from(classList).join(' ')}
                    key={index}
                    onClick={this.selectEmail.bind(this, elem)}
                    data-testid={`buttonSharePopupSelectContactOption-${index}`}
                >
                    <div
                        className={`popup__suggest-circle ${elem.groupId && 'popup__suggest-circle--group'}`}
                        data-testid={`textSharePopupSelectContactInitials`}
                    >
                        {Abbr}
                    </div>

                    {
                        elem.groupId !== 0 ? (
                            <div>
                                <div
                                    className="popup__suggest-name"
                                    data-testid={`textSharePopupSelectContactTitle`}
                                >
                                    {elem.email}
                                </div>
                                <div
                                    className="popup__suggest-email"
                                    data-testid={`textSharePopupSelectContactSubTitle`}
                                >
                                    {elem.emailSubTitle}
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div
                                    className="popup__suggest-name"
                                    data-testid={`textSharePopupSelectContactTitle`}
                                >
                                    {elem.emailSubTitle}
                                </div>
                                <div
                                    className="popup__suggest-email"
                                    data-testid={`textSharePopupSelectContactSubTitle`}
                                >
                                    {elem.email}
                                </div>
                            </div>
                        )
                    }
                </li>);
            });
    }

    handleChange(e) {
        const searchQuery = e.target.value.toLowerCase();
        const items = this.props.listOfEmails.filter((elem) => {
            const searchName = elem.emailSubTitle.toLowerCase();
            const searchEmail = elem.email.toLowerCase();
            return (searchName.indexOf(searchQuery) !== -1 || searchEmail.indexOf(searchQuery) !== -1) &&
                searchQuery.length > 0;
        });

        this.setState({
            suggestedList: items,
            searchQuery,
        });
    }

    allowArrowsSelection(e) {
        e.preventDefault();
        const { suggestedList, selectedOfSuggested, selectedList } = this.state;

        if (suggestedList.length < 1) return;

        switch (e.which) {
            case 38: // 'up'
                if (selectedOfSuggested === 0) {
                    break;
                }
                this.setState({
                    selectedOfSuggested: selectedOfSuggested - 1
                });
                break;

            case 40: // 'down'
                if (selectedOfSuggested === suggestedList.length - 1) {
                    break;
                }
                this.setState({
                    selectedOfSuggested: selectedOfSuggested + 1
                });

                if (selectedOfSuggested === null) this.setState({ selectedOfSuggested: 0 });
                break;

            case 13: // 'Enter'
                this.selectEmail(suggestedList.filter(elem => selectedList.filter(el => el === elem).length === 0)[selectedOfSuggested]);
                this.setState({
                    selectedOfSuggested: null
                });
                break;

            default: break;
        }
    }

    handleKeyUp(e) {
        if (this.state.emailInvalid) this.setState({ emailInvalid: false });
        if (e.which === 13) {
            if (/(.+)@(.+){2,}\.(.+){2,}/.test(e.target.value.trim())) {
                if (this.state.emailInvalid) this.setState({ emailInvalid: false });
                const coincidenceArray = this.props.listOfEmails.filter((elem) => {
                    return elem.email.toLowerCase() === e.target.value.toLowerCase().trim();
                });
                let newList;
                if (coincidenceArray.length) {
                    if (this.isAlreadySelected(e.target.value.trim())) {
                        this.refs.search_email.value = '';
                        return;
                    }
                    newList = [].concat(this.state.selectedList, coincidenceArray[0]);
                } else {
                    if (this.isAlreadySelected(e.target.value.trim())) {
                        e.target.value = '';
                        return;
                    }
                    const customEmail = {
                        isCustom: true,
                        email: e.target.value.trim(),
                        emailSubTitle: '',
                        type: 3,
                        groupId: null,
                        userId: null,
                    };
                    newList = [].concat(this.state.selectedList, customEmail);
                }
                this.setState({
                    selectedList: newList,
                });
                e.target.value = '';
            } else {
                if(this.state.selectedOfSuggested === null) {
                    this.setState({ emailInvalid: true });
                }
            }
        }
        this.allowArrowsSelection(e);
    }


    showAllList(e) {
        e.stopPropagation();
        this.setState({ suggestedList: this.props.listOfEmails });
        this.refs.search_email.focus();
    }

    removeSelectedItem(index) {
        this.setState({
            selectedList: this.state.selectedList.filter((elem, i) => i !== index),
        });
    }

    renderSelectedList() {
        return this.state.selectedList.map((elem, index) => {
            const classList = new Set([
                'popup__suggest-item',
                elem.groupId !== 0 ? 'popup__suggest-item--group' : '',
            ]);
            let title = '';

            if (elem.groupId !== 0) {
                title = elem.email;
            } else if (elem.emailSubTitle.length) {
                title = elem.emailSubTitle;
            } else {
                title = elem.email;
            }

            return (
                <div
                    className={Array.from(classList).join(' ')}
                    key={index}
                    data-testid={`blockSharePopupSelectedContact-${index}`}
                >
                    <div
                        className="popup__suggest-name"
                        data-testid="textSharePopupSelectedContactTitle"
                    >
                        {title}
                    </div>
                    <div
                        className="popup__remove"
                        onClick={this.removeSelectedItem.bind(this, index)}
                        data-testid="buttonSharePopupRemoveSelectedContact"
                    >
                        <SpriteIcon iconId="remove" className="popup-share__icon--no-pe"/>
                    </div>
                </div>
            );
        });
    }

    handleClickTagInputArea() {
        this.refs.search_email.focus();
    }

    handleShareClick() {
        if (this.state.selectedList.length < 1) return;
        const emailsToSend = [];
        const groupsToSend = [];
        const customEmails = [];
        this.state.selectedList.forEach((elem) => {
            if (elem.groupId === 0) {
                emailsToSend.push(elem.userId);
            } else if (elem.groupId !== null) groupsToSend.push(elem.groupId);
            if (elem.isCustom) {
                customEmails.push(elem.email);
            }
        });
        const data = {
            message: document.getElementById('share_msg').value,
            documentId: this.props.itemData.id,
            documentType: this.props.itemData.documentType,
            publicationFamily: this.props.itemData.publicationFamily,
            usersIds: emailsToSend,
            groupsIds: groupsToSend,
            customEmails,
        };
        this.props.postShare(data);
        this.props.cancelShare();
    }

    resizeIt(e) {
        const text = e.target.value.replace(/\n/g, '<br/>');
        this.refs.textareaFake.innerHTML = text;
    }

    render() {
        const { type } = this.props;
        const { title, popupType = 'allDocuments', shareLinkString } = this.props.itemData;

        const classList = new Set([
            'popup__inner',
            type === 'report' ? 'popup__inned--md' : 'popup__inner--small',
        ]);

        return (
            <Popup
                onClose={this.handleCancelClick}
                className="popup popup-share popup--fade-in"
                isOpened={true}
            >
                <div className={Array.from(classList).join(' ')} onClick={this.handleClickInPopup}>
                    <div className="popup__header">
                        <div
                            className="popup__title popup__title--centered"
                            data-testid="textSharePopupTitle"
                        >
                            Share this item
                        </div>
                    </div>
                    <div className="popup__main">
                        <div
                            className="popup-share__input-wrapper"
                            ref="input_outer"
                            onClick={this.handleClickTagInputArea}
                        >
                            <label
                                className="popup__input-label"
                                data-testid="textSharePopupSelectContactsLabel"
                            >
                                To:
                            </label>
                            <div className="popup__input-message">
                                {this.state.emailInvalid ? (
                                    <div
                                        className="popup__error-email"
                                        data-testid="textSharePopupInvalidEmail"
                                    >
                                        invalid email
                                    </div>
                                ) : (
                                    <div
                                        className="popup__all-list"
                                        onClick={this.showAllList.bind(this)}
                                        data-testid="buttonSharePopupViewContacts"
                                    >
                                        view contacts
                                    </div>
                                )}
                            </div>
                            <span className="popup__input popup__input--fake">
                                <input
                                    className="popup__input"
                                    type="text"
                                    id="search_email"
                                    ref="search_email"
                                    onChange={this.handleChange.bind(this)}
                                    onKeyUp={this.handleKeyUp.bind(this)}
                                    placeholder='email, name or group'
                                    data-testid="inputSharePopupSearchContacts"
                                />
                                {this.state.searchQuery}
                            </span>
                        </div>
                        <div className="popup__suggest">
                            {this.state.suggestedList.length > 0 && (
                                <ul
                                    ref="suggest_list"
                                    className="popup__suggest-list"
                                    data-testid="selectSharePopupSelectContacts"
                                >
                                    {this.renderSuggestList()}
                                </ul>
                            )}
                        </div>
                        <div
                            className="popup__input-outer"
                            ref="input_outer" onClick={this.handleClickTagInputArea}
                        >
                            <Scrollbar
                                className="share-popup__scrollbar-vertical"
                                ref="scrollbar"
                                renderView={props => <div {...props} className="scrollbar-view"/>}
                                // renderTrackVertical={props => <div {...props} className="scrollbar-vertical"/>}
                                autoHide
                            >
                                <div
                                    className="popup__scrollbar-inner"
                                    data-testid="blockSharePopupSelectedContacts"
                                >
                                    {this.state.selectedList.length > 0 && this.renderSelectedList()}
                                </div>
                            </Scrollbar>
                        </div>
                    </div>
                    <div className="popup__textarea-outer popup__textarea-outer--share-popup">
                        <label
                            className="popup__textarea-label popup__textarea-label--share-popup"
                            htmlFor="share_msg"
                            data-testid="textSharePopupMessageInputLabel"
                        >
                            Add Message (optional):
                        </label>
                        <div
                            className={'popup__textarea-scrollbar'}
                        >
                            <Scrollbar
                                autoHide
                                renderView={props => <div className="share-popup__scroll-view" {...props} />}
                                renderTrackHorizontal={props => <div className="share-popup__track-horiz" {...props} />}
                            >
                                    <TextArea
                                        useCacheForDOMMeasurements
                                        className="popup__textarea"
                                        id="share_msg"
                                        defaultValue={sharePopupConfig[popupType].placeholder}
                                        maxLength="300"
                                        onKeyDown={this.resizeIt.bind(this)}
                                        data-testid="inputSharePopupMessage"
                                    />
                                <div
                                    className="popup__textarea-fake"
                                    ref="textareaFake"
                                >
                                    {sharePopupConfig[popupType].placeholder}
                                </div>
                            </Scrollbar>
                        </div>
                    </div>
                    <div className="popup__footer">
                        <div className="popup__left-side">
                            {(this.props.shareLinkString || shareLinkString) && (
                                <button
                                    className="popup__link popup__link--highlighted"
                                    onClick={this.copyToClipboard.bind(this)}
                                    data-testid="buttonSharePopupCopyLink"
                                >
                                    <SpriteIcon iconId="share_link" className="popup__link-icon"/>
                                    Copy link
                                </button>
                            )}
                        </div>
                        <div className="popup__right-side">
                            <button
                                className="popup-share__link popup__link"
                                onClick={this.handleCancelClick}
                                data-testid="buttonSharePopupCancel"
                            >
                                cancel
                            </button>
                            <button
                                className={`popup__btn popup__btn--md${type === 'report' ? ' popup__btn--rounded' : ''}`}
                                onClick={this.handleShareClick.bind(this)}
                                disabled={this.state.selectedList.length < 1}
                                data-testid="buttonSharePopupShare"
                            >
                                Share
                            </button>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}

SharePopup.propTypes = {
    title: PropTypes.string,
    cancelShare: PropTypes.func,
    itemData: PropTypes.object,
    showNotification: PropTypes.func,
    getEmails: PropTypes.func,
    listOfEmails: PropTypes.array,
    postShare: PropTypes.func,
    shareLinkString: PropTypes.string,
    decorateTextarea: PropTypes.bool,
    type: PropTypes.string,
};

const mapStateToProps = state => ({
    itemData: shareUrlSelector(state),
    listOfEmails: shareEmailsListSelector(state),
});

const mapDispatchToProps = dispatch => ({
    cancelShare: data => dispatch(setShareCancel(data)),
    showNotification: text => dispatch(addNotification(text)),
    getEmails: () => dispatch(getEmailsData()),
    postShare: data => dispatch(shareDocumentLink(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SharePopup);
