import { Component } from 'react';
import { connect } from 'react-redux';
import Popup from '../../components/Popup/Popup';
import {
    removeFileFromPackage,
    removePackageFromList
} from '../../actions/packages.actions';

// TODO think how make reusable unique popup with callbacks
class PackageConfirmDelete extends Component {
    constructor(props) {
        super(props);

        this.closePopupInstance = this.closePopupInstance.bind(this);
        this.state = {
            deletionInputValue: ''
        };
    }

    closePopupInstance() {
        this.setState({
            deletionInputValue: ''
        });
        this.props.deletePopupClose();
    }

    changeDeletionInputValue(event) {
        this.setState({
            deletionInputValue: event.target.value
        });
    }

    delete() {
        if (this.props.deleteType === 'packageItemDelete') {
            this.props.removeFileFromPackage(this.props.item.id);
        }
        if (this.props.deleteType === 'packageDelete') {
            this.props.removePackage(this.props.item);
        }
        if (this.props.deleteType === 'packageItemDeleteFromList') {
            this.props.removePackageFromList(this.props.item.id, this.props.item.packageId);
        }
        this.closePopupInstance();
    }

    renderDeleteText() {
        switch (this.props.deleteType) {
            case 'packageItemDeleteFromList':
            case 'packageItemDelete': {
                return `This process can not be reversed and will remove document '${this.props.item.name}' from package.`;
            }
            case 'packageDelete': {
                return `This process can not be reversed and will permanently deleted '${this.props.item.name}'.`;
            }
            default: {
                return `This process can not be reversed and will remove document '${this.props.item.name}' from package.`;
            }
        }
    }

    render() {
        return (
            <Popup
                isOpened={this.props.deletePopupOpen}
                onClose={this.closePopupInstance}
                className="popup--fade-in"
            >
                <div className="popup__inner popup-red popup__inner--small">
                    <div className="popup__header">
                        <div className="popup__title">Are you sure?</div>
                    </div>
                    <div
                        className="popup__text popup__text--indented popup__text--white popup__text--light"
                    >
                        {this.renderDeleteText()}
                    </div>
                    <div className="popup__form-wrapper popup__form-wrapper--bordered">
                        <div>
                            <div className="popup__input-label">type "delete" to confirm</div>
                            <input
                                id="package-delete-input"
                                type="text"
                                autoFocus={true}
                                value={this.state.deletionInputValue.toUpperCase()}
                                onChange={this.changeDeletionInputValue.bind(this)}
                                className="popup__input popup__input--big popup__input--underlined popup__input--backgrounded popup__margin--big"
                            />
                        </div>
                    </div>
                    <div className="popup__button-wrapper">
                        <div className="popup__right-side">
                            <button
                                onClick={this.closePopupInstance.bind(this)}
                                className="popup__link popup__link--light popup__link--white"
                            >
                                cancel
                            </button>
                            <button
                                disabled={this.state.deletionInputValue.toLowerCase() !== 'delete'}
                                onClick={this.delete.bind(this)}
                                className="popup__btn popup__btn--white popup__btn--md"
                            >
                                DELETE
                            </button>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}



const mapDispatchToProps = dispatch => ({
    removeFileFromPackage: id => dispatch(removeFileFromPackage(id)),
    removePackageFromList: (id, packageId) => dispatch(removePackageFromList(id, packageId)),
});

export default connect(null, mapDispatchToProps)(PackageConfirmDelete);
