import './PackageAddWrapper.scss';
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addNotification } from '@components/GlobalNotification/globalNotification.actions';
import { AnalyticsAttribute, getAnalyticsComputedData } from '@models/AnalyticsAttributes';

const PackageAddWrapper: FC<{
    className?: string;
    onClick?: () => void;
    testId?: string;
}> = ({
    className,
    onClick,
    testId,
    children,
}) => {
    const dispatch = useDispatch();
    const showNotification = (notification: any) => dispatch(addNotification(notification));

    const handleClick = useCallback(
        () => {
            showNotification({ text: 'Added to package', iconDisabled: true,});
            if (onClick) onClick();
        },
        [showNotification, onClick]
    );

    return (
        <div
            className={`package-animation-container ${className}`}
            onClick={handleClick}
            data-testid={testId}
            {...getAnalyticsComputedData({
                [AnalyticsAttribute.ClickTitle]: 'Add To Package',
            })}
        >
            {children}
        </div>
    );
}

export default PackageAddWrapper;