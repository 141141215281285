import { FC, memo, ReactNode, useCallback, useEffect, useState } from "react";
import {
    AnalyticsAction,
    AnalyticsAttribute,
    FoodProfileDownloadLocation,
    getAnalyticsComputedData
} from "@models/AnalyticsAttributes";
import cn from "classnames";
import ProgressBar from "@components/ProgressBar/ProgressBar";
import http from "@core/fetch";
import { IconClose, IconDownload, IconSuccess } from '@icons';

enum ButtonText {
    Default = 'Download profile',
    Loading = 'Downloading...',
    Success = 'Download Successful',
    Error = 'Something went wrong.',
}

const ButtonIcon = {
    Default: (<IconDownload className="icon" width={20} height={20}/>),
    Loading: null,
    Success: (<IconSuccess className="icon" width={20} height={20}/>),
    Error: (<IconClose className="icon" width={20} height={20}/>),
}

const ProfileDownloadButton: FC<{
    url: string;
    onClick: () => void;
    testId?: string;
}> = (
    {
        url,
        onClick,
        testId,
    }
) => {
    const [isProfileDownloading, setIsProfileDownloading] = useState(false);
    const [text, setText] = useState<ButtonText>(ButtonText.Default);
    const [icon, setIcon] = useState<ReactNode>(ButtonIcon.Default);
    const [isError, setIsError] = useState(false);

    const downloadFile = useCallback(() => {
        setIsProfileDownloading(true);
        setText(ButtonText.Loading);
        setIcon(ButtonIcon.Loading);
        http.downloadFileXHRFromUrl('GET', url)
            .then(() => {
                setText(ButtonText.Success);
                setIcon(ButtonIcon.Success);
            })
            .catch(() => {
                setIsError(true);
                setText(ButtonText.Error);
                setIcon(ButtonIcon.Error);
            })
            .finally(() => {
                setIsProfileDownloading(false);
                setTimeout(() => {
                    setIsError(false);
                    setText(ButtonText.Default);
                    setIcon(ButtonIcon.Default);
                }, 5000);
            });
    }, [url]);

    return (
            <div
                {...getAnalyticsComputedData({
                    [AnalyticsAttribute.Action]: AnalyticsAction.Download,
                    [AnalyticsAttribute.DownloadLocation]: FoodProfileDownloadLocation.FoodProfile,
                    [AnalyticsAttribute.ClickTitle]: 'Download Profile – Main',
                })}
                className={cn(
                    'FoodProfileSummaryChartInfo__button',
                    'cube',
                    'font-text',
                    'clickable',
                    isError && 'is-error',
                    isProfileDownloading && 'FoodProfileSummaryChartInfo__button--downloading',
                )}
                onClick={() => {
                    onClick();
                    downloadFile();
                }}
                data-testid={testId}
            >
                    <div className={cn(
                        'FoodProfileSummaryChartInfo__button--download',
                        'cube__part',
                    )}>
                        {icon}
                        {text}
                        {isProfileDownloading && (
                            <div className="FoodProfileSummaryChartInfo__button--progress-bar">
                                <ProgressBar/>
                            </div>
                        )}
                    </div>
                    <div className={cn(
                        'FoodProfileSummaryChartInfo__button--download',
                        'cube__part',
                        'cube__part-hover',
                    )}>
                        {icon}
                        {text}
                        {isProfileDownloading && (
                            <div className="FoodProfileSummaryChartInfo__button--progress-bar">
                                <ProgressBar/>
                            </div>
                        )}
                    </div>
            </div>
    );
};

export default memo(ProfileDownloadButton);