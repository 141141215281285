import { FC, useMemo } from "react";
import { iSocialLink, SocialLinkEnum } from "../../../../../core/models/ChainDetails";
import {
    IconLocation,
    IconPhone,
    IconWeb,
    IconWiki,
    IconFoursquare,
    IconLinkedin,
    IconFacebook,
    IconInstagram,
    IconTwitter,
    IconYoutube, IconSocialZomato, IconSocialTumblr, IconSocialSnapchat, IconSocialPinterest,
} from "../../../../../icons";
import "./ChainsDetailsContacts.styles.scss";
import InfoBlock from "../../../../../components/new/InfoBlock/InfoBlock";
import { sanitizeUrl } from "../../../../../core/helpers";

interface iProps {
    address: string;
    addressSecondRow: string;
    phone: string;
    website: string;
    socialLinks: iSocialLink[];
}

const ChainsDetailsContacts: FC<iProps> = (
    {
        address,
        addressSecondRow,
        phone,
        website,
        socialLinks,
    }
) => {
    const getLinkIcon = (title: string) => {
        switch (title) {
            case SocialLinkEnum.facebook:
                return <IconFacebook/>;
            case SocialLinkEnum.foursquare:
                return <IconFoursquare/>;
            case SocialLinkEnum.instagram:
                return <IconInstagram/>;
            case SocialLinkEnum.linkedin:
                return <IconLinkedin/>;
            case SocialLinkEnum.twitter:
                return <IconTwitter/>;
            case SocialLinkEnum.wikipedia:
                return <IconWiki/>;
            case SocialLinkEnum.youtube:
                return <IconYoutube/>;
            case SocialLinkEnum.pinterest:
                return <IconSocialPinterest/>;
            case SocialLinkEnum.snapchat:
                return <IconSocialSnapchat/>;
            case SocialLinkEnum.tumblr:
                return <IconSocialTumblr/>;
            case SocialLinkEnum.zomato:
                return <IconSocialZomato/>;
            default:
                return <IconWeb/>;
        }
    };

    const hasAddress = !!address || !!addressSecondRow;
    const hasPhone = !!phone;
    const hasFirstColumn = hasAddress || hasPhone;
    const hasWebsite = !!website;
    const hasSocialLinks = socialLinks.length > 0;
    const hasSecondColumn = hasWebsite || hasSocialLinks;
    const sanitizedWebsite = useMemo(() => sanitizeUrl(website), [website]);
    if (!hasFirstColumn && !hasSecondColumn) return null;

    return (
        <InfoBlock className="Profile__info-block">
            <div className="ChainsDetailsContacts">
                <div className="ChainsDetailsContacts__row">
                    {hasFirstColumn && (
                        <div className="ChainsDetailsContacts__column">
                            {hasAddress && (
                                <div className="ChainsDetailsContacts__item">
                                    <div className="ChainsDetailsContacts__icon">
                                        <IconLocation/>
                                    </div>
                                    <div>
                                        <div>{address}</div>
                                        <div>{addressSecondRow}</div>
                                    </div>
                                </div>
                            )}
                            {hasPhone && (
                                <div className="ChainsDetailsContacts__item">
                                    <div className="ChainsDetailsContacts__icon">
                                        <IconPhone/>
                                    </div>
                                    <div>{phone}</div>
                                </div>
                            )}
                        </div>
                    )}
                    {hasSecondColumn && (
                        <div className="ChainsDetailsContacts__column">
                            {hasWebsite && (
                                <a
                                    className="ChainsDetailsContacts__web-link ChainsDetailsContacts__item"
                                    href={sanitizedWebsite}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <div className="ChainsDetailsContacts__icon">
                                        <IconWeb/>
                                    </div>
                                    {website}
                                </a>
                            )}
                            {hasSocialLinks && (
                                <div
                                    className="ChainsDetailsContacts__social-link-list ChainsDetailsContacts__item">
                                    {socialLinks.map(({ title, url }) => (
                                        <a
                                            key={title}
                                            className="ChainsDetailsContacts__social-link"
                                            href={url}
                                            target="_blank"
                                            title={title}
                                            rel="noreferrer"
                                        >
                                            {getLinkIcon(title)}
                                        </a>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </InfoBlock>
    );
};

export default ChainsDetailsContacts;
