import React, { FC } from "react";
import "./ChartTooltip.styles.scss";

const ChartTooltip: FC<{
    data: string[];
    labels: string[];
    separatorIndex?: number;
    x: number;
    y: number;
}> = (
    {
        x,
        y,
        data,
        labels,
        separatorIndex,
    }
) => {
    const actualData = (separatorIndex && separatorIndex >= 0) ? data.slice(0, separatorIndex) : data;
    const predictedData = (separatorIndex && separatorIndex >= 0) ? data.slice(separatorIndex) : [];
    return (
        <div
            className="ChartTooltip"
            style={{
                transform: `translate(${x}px, ${y}px)`,
            }}
        >
            <div className="ChartTooltip__inner">
                {actualData.length > 0 && (
                    <div className="ChartTooltip__group ChartTooltip__group--actual">
                        {actualData.map((d, dIndex) => (
                            <div key={labels[dIndex]} className="ChartTooltip__col">
                                <div className="ChartTooltip__cell ChartTooltip__head">
                                    {labels[dIndex]}
                                </div>
                                <div className="ChartTooltip__cell">{d}</div>
                            </div>
                        ))}
                        <div className="ChartTooltip__label">Actual</div>
                    </div>
                )}

                {predictedData.length > 0 && (
                    <div className="ChartTooltip__group ChartTooltip__group--predicted">
                        {predictedData.map((d, dIndex) => (
                            <div key={labels[actualData.length + dIndex]} className="ChartTooltip__col">
                                <div className="ChartTooltip__cell ChartTooltip__head">
                                    {labels[actualData.length + dIndex]}
                                </div>
                                <div className="ChartTooltip__cell">{d}</div>
                            </div>
                        ))}
                        <div className="ChartTooltip__label">Predicted</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ChartTooltip;
