import React, { FC, useCallback, useMemo, useState } from "react";
import './FoodProfileSummary.scss';
import FoodProfileSummaryInfo from "@pages/FoodProfile/FoodProfileSummary/FoodProfileSummaryInfo";
import FoodProfilePipelineMenu, {
    iPipelineMenu
} from "@pages/FoodProfile/FoodProfileSummary/FoodProfilePipelineMenu/FoodProfilePipelineMenu";
import FoodProfileConsumerAffinity from "@pages/FoodProfile/FoodProfileSummary/FoodProfileConsumerAffinity/FoodProfileConsumerAffinity";
import cn from "classnames";
import { iFoodProfileSummary } from "@models/FoodProfileSummary";
import { iYearPoint } from "@components/Chart/Chart.helpers";
import Chart from "@components/Chart/Chart";
import AutoSizer from "react-virtualized-auto-sizer";
import { isAvailable, isBlockedAccessible, PermissionLevelEnum } from "@models/PermissionSection";
import { iAffinityNormsGroup, iFoodProfileAffinity } from "@models/FoodProfileAffinity";
import { IconDsLogoNewSmall } from "@icons";
import { LockAction } from "@components/new/LockSection/LockSection";

const FoodProfileSummary: FC<{
    searchQuery: string;
    summary: iFoodProfileSummary;
    pipelineMenuData: iPipelineMenu;
    consumerAffinity: iFoodProfileAffinity;
    consumerAffinityCategories: iAffinityNormsGroup[];
    openHaiku: () => void;
    downloadUrl: string;
    requestTrial: (products: string[]) => void;
    permissionLevel: PermissionLevelEnum;
}> = (
    {
        summary,
        searchQuery,
        pipelineMenuData,
        consumerAffinity,
        consumerAffinityCategories,
        openHaiku,
        permissionLevel,
        requestTrial,
    }
) => {
    const [activeSegmentIndex, setActiveSegmentIndex] = useState(0);
    const [isRequestSent, setIsRequestSent] = useState(false);

    const isOnlyFlavor = (isAvailable(summary) && !isAvailable(consumerAffinity));
    const isOnlyMt = (!isAvailable(summary) && isAvailable(consumerAffinity));

    const handleRequestButtonClick = useCallback(() => {
        setIsRequestSent(true);
        if (isOnlyFlavor) requestTrial(['flavor']);
        else if (isOnlyMt) requestTrial(['mtd']);
        else requestTrial(['mtd', 'flavor']);
    }, [isOnlyFlavor, isOnlyMt]);

    const { data } = summary;
    const { segments, haikuPopupData, filterLabel } = data;

    const chartData: iYearPoint[][] = useMemo(() => {
        return segments.map(s => s.chartData.map(y => ({
            label: y.label,
            value: y.value,
            tooltipValues: y.subValues,
        })));
    }, [segments]);

    const isOverlayShown = useMemo(
        () => isBlockedAccessible(summary) || isBlockedAccessible(consumerAffinity),
        [summary, consumerAffinity]
    );

    return (
        <div className="FoodProfileSummary">
            {consumerAffinity.hasData && (
                <FoodProfileConsumerAffinity
                    data={consumerAffinity.data}
                    consumerAffinityCategories={consumerAffinityCategories}
                    permissionLevel={permissionLevel}
                />
            )}
            <div className="FoodProfileSummary__main-content">
                <div className="FoodProfileSummary__cols">
                    <div className="FoodProfileSummary__col-info">
                        <FoodProfileSummaryInfo
                            data={data}
                            activeSegmentIndex={activeSegmentIndex}
                            searchQuery={searchQuery}
                            showHaiku={isAvailable(haikuPopupData)}
                            openHaiku={openHaiku}
                            filterLabel={filterLabel}
                        />
                    </div>
                    <div className="FoodProfileSummary__col-float">
                        <div className="FoodProfileSummary__cols FoodProfileSummary__cols--v">
                            <FoodProfilePipelineMenu
                                data={pipelineMenuData}
                            />
                            {segments.length > 0 && (
                                <div
                                    className="FoodProfileChartInfo"
                                    style={{
                                        paddingRight: isAvailable(haikuPopupData) ? 10 : undefined,
                                    }}
                                >
                                    <AutoSizer>
                                        {({width, height}) => (
                                            <Chart
                                                width={width}
                                                height={height}
                                                data={chartData}
                                                chartsTitles={segments.map(s => s.title)}
                                                printValue={(v) => `${v.toFixed(1)}%`}
                                                showHaiku={isAvailable(haikuPopupData)}
                                                openHaiku={openHaiku}
                                                activeChartIndex={activeSegmentIndex}
                                                setActiveChartIndex={setActiveSegmentIndex}
                                                testId="chartFoodProfile"
                                            />

                                        )}
                                    </AutoSizer>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {isOverlayShown && (
                <div className={cn(
                    "FoodProfileOverlay",
                    isAvailable(summary) && consumerAffinity.hasData && 'is-narrow',
                    !isAvailable(summary) && isAvailable(consumerAffinity) && 'is-wide',
                )}>
                    {isOnlyFlavor && (
                        <div>
                            Upgrade your subscription to learn what consumers think of <b>{searchQuery.toUpperCase()}</b>
                        </div>
                    )}
                    {isOnlyMt && (
                        <div>
                            Upgrade your subscription to learn about <b>{searchQuery.toUpperCase()}</b>
                            <br/>
                            on menus (penetration, growth, and more)
                        </div>
                    )}
                    {(!isAvailable(summary) && !isAvailable(consumerAffinity)) && (
                        <div>
                            Insights about <b>{searchQuery.toUpperCase()}</b> are available.
                            <br/>
                            Unlock this food profile by contacting us today
                        </div>
                    )}
                    <div className="FoodProfileOverlay__action">
                        <LockAction
                            handleClick={handleRequestButtonClick}
                            showNotification={false}
                        />
                        <IconDsLogoNewSmall
                            width={25}
                            height={25}
                            className="FoodProfileOverlay__icon"
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default FoodProfileSummary;