import { FC } from 'react';
import './Popup.scss';
import Modal from "../Modal/Modal";

interface iProps {
    isOpened: boolean;
    className?: string;
    onClose?: () => void;
    closeOnEsc?: boolean;
    closeOnOverlayClick?: boolean;
    onlyLegacyUsageParam: boolean;
}

const Popup: FC<iProps> = (
    {
        isOpened,
        className = '',
        onClose = () => null,
        closeOnEsc = true,
        closeOnOverlayClick = true,
        children,
        onlyLegacyUsageParam = true,
    }
) => {
    if (!onlyLegacyUsageParam) {
        /* todo: add TypeScript compilation error */
        throw new Error('Component is legacy, use components/Modal instead');
    }

    return (
        <Modal
            isOpened={isOpened}
            close={onClose}
            className={className}
            closeOnEsc={closeOnEsc}
            hasInner={false}
            closeOnOutsideClick={closeOnOverlayClick}
        >
            {children}
        </Modal>
    );
}

export default Popup;
