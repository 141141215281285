import React, { FC, useEffect, useRef, useState } from "react";
import ContentWidget from "@components/ContentWidget/ContentWidget";
import "./FoodProfileFoundIn.scss";
import { getFormattedThousand } from "@helpers";
import ContentWidgetButton, { ButtonTypeEnum } from "@components/ContentWidget/ContentWidgetButton";
import { AnalyticsAttribute } from "@models/AnalyticsAttributes";
import { makeEnding } from "@core/old_helpers";
import ReportProTile, { ReportProTileOption } from "@components/ReportProTile/ReportProTile";
import { iReportProPublication } from "@models/ReportProPublication";
import cn from "classnames";

export interface iDocumentsData {
    reports: iReportProPublication[];
    reportUrl: string;
    total: number;
    moreButtonUrl: string;
}

const FoodProfileFoundIn: FC<{
    data: iDocumentsData;
}> = (
    {
        data,
    }
) => {
    const sizer = useRef<HTMLDivElement>(null);
    const [itemsCount, setItemsCount] = useState(0);

    console.log('itemsCount', itemsCount);

    useEffect(() => {
        let timer: any;
        const recalculateItemsCount = () => {
            if (sizer.current) {
                const elWidth = sizer.current.getBoundingClientRect().width;
                clearTimeout(timer);
                timer = setTimeout(() => {
                    setItemsCount(Math.floor(elWidth / 180));
                }, 300);
            }
        }
        recalculateItemsCount();
        window.addEventListener('resize', recalculateItemsCount);
        return () => {
            window.removeEventListener('resize', recalculateItemsCount);
        };
    }, [sizer]);

    return (
        <ContentWidget
            title="Found In"
            titleTestId={"textFoodProfileFoundInTitle"}
            className="FoodProfileFoundIn"
        >
            <div className="FoodProfileFoundIn__container">
                <div className="FoodProfileFoundIn__images-container">
                    <div className="FoodProfileFoundIn__sizer" ref={sizer}>
                        {data.reports.slice(0, itemsCount).map((report, index) => (
                            <div
                                key={report.id}
                                className={cn(
                                    "FoodProfileFoundIn__image-container",
                                    index === 0 && 'is-first-visible',
                                )}
                            >
                                <ReportProTile
                                    data={report}
                                    options={[
                                        ReportProTileOption.Wide,
                                        ReportProTileOption.DynamicHeight,
                                        ReportProTileOption.SmallTitle,
                                    ]}
                                    testId={`widgetFoodProfileFoundInTile${index}`}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="FoodProfileFoundIn__info font-text">
                    <ContentWidgetButton
                        type={ButtonTypeEnum.Anchor}
                        link={data.moreButtonUrl}
                        modifiers={["mt"]}
                        analyticsData={{
                            [AnalyticsAttribute.ClickTitle]: 'ReportPro Redirect',
                        }}
                        testId={"buttonFoodProfileFoundInExternalLink"}
                    >
                        view all {getFormattedThousand(data.total)} {makeEnding('document', data.total)}
                    </ContentWidgetButton>
                </div>
            </div>
        </ContentWidget>
    );
}
export default FoodProfileFoundIn;