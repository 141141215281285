import { useCallback, useState } from "react";
import Http from "../../../../../core/fetch";
import useHeatmapData from "../../../../../core/apiHooks/useHeatmapData";
import { ID } from "../../../../../core/models";
import { iUseData } from "../../../../../core/hooks/useData";
import { useJsApiLoader as useMapLoader } from "@react-google-maps/api";
import { MAP_LOADER_OPTIONS } from "./ChainMap.helpers";

export interface iChainMapData {
    heatMap: iUseData<google.maps.LatLng[]>;
    saveHeatmap: (imageData: string) => void;
    isSaved: boolean;
    initedMapLoader: boolean;
}

const useChainMapData = (chainId: ID): iChainMapData => {
    const heatMap = useHeatmapData(chainId);
    const mapLoader = useMapLoader(MAP_LOADER_OPTIONS);
    const [isSaved, setIsSaved] = useState(false);

    const saveHeatmap = useCallback((imageData: string) => {
        Http.post('save map state', {}, {
            body: JSON.stringify({
                chainId,
                map: imageData
            }),
            headers: {
                'content-type': 'application/json; charset=utf-8',
            },
        }, "none")
            .then(() =>{
                setIsSaved(true);
            });
    }, [chainId]);

    return { heatMap, saveHeatmap, isSaved, initedMapLoader: mapLoader.isLoaded }
}

export default useChainMapData;