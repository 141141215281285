import { FC, useContext, useMemo } from "react";
import cn from "classnames";
import { iReportData, ReportsNameEnum } from "../../../core/models/ChainDetails";
import {
    IconCheckboxFake,
    IconFingerprints,
    IconFirefly,
    IconLock,
    IconMenutrends,
    IconFingerprintsGrayscale,
    IconFireflyGrayscale,
    IconMenutrendsGrayscale,
    IconLogoSnapDsIcon, IconScores, IconScoresGrayscale,
} from "../../../icons";
import { getReportDescriptionByPermission } from "../ReportsDownload.helpers";
import "./ReportsItem.styles.scss";
import { isAvailable, PermissionLevelEnum } from "../../../core/models/PermissionSection";
import { ChainDetailsDataContext } from "@pages/ChainPage/ChainPage.helpers";

interface iProps {
    data: iReportData;
    onClick: (reportName: ReportsNameEnum) => void;
    isSelected?: boolean;
}

const ReportsItem: FC<iProps> = ({
    data,
    onClick,
    isSelected,
}) => {
    const { data: { mainData: { name: chainName } } } = useContext(ChainDetailsDataContext);

    const {
        data: { name },
        permissionLevel,
    } = data;

    const description = useMemo(() => getReportDescriptionByPermission({
        chainName: chainName,
        permissionLevel,
        reportName: name,
    }), [name, permissionLevel, chainName]);

    const isReportAvailable = isAvailable(data);

    const itemIcon = useMemo(() => {
        switch (name) {
            case ReportsNameEnum.BrandFingerprints:
                return isReportAvailable ? <IconFingerprints/> : <IconFingerprintsGrayscale/>;
            case ReportsNameEnum.Firefly:
                return isReportAvailable ? <IconFirefly/> : <IconFireflyGrayscale/>;
            case ReportsNameEnum.Menu:
                return isReportAvailable ? <IconMenutrends/> : <IconMenutrendsGrayscale/>;
            case ReportsNameEnum.Profile:
                return <IconLogoSnapDsIcon style={{
                    width: 56,
                    height: 56,
                    marginTop: -3,
                    marginLeft: -3,
                }}/>;
            case ReportsNameEnum.LTOActivity:
                return isReportAvailable ? <IconScores/> : <IconScoresGrayscale/>;
            default:
                return <></>;
        }
    }, [name, isReportAvailable]);

    const itemPriceLabel = useMemo(() => {
        let result = '';
        switch (permissionLevel) {
            case PermissionLevelEnum.Limited: {
                result = 'limited';
                break;
            }
            case PermissionLevelEnum.None: {
                result = 'subscription required'
                break;
            }
            default:
                result = '';
        }
        return result;
    }, [permissionLevel, name]);

    const classNames = cn(
        "ReportsItem",
        !isReportAvailable && "is-disabled",
        isSelected && "is-selected",
    );

    return (
        <div
            className={classNames}
            onClick={() => onClick(name)}
        >
            {isSelected && (
                <div className="ReportsItem__status-icon">
                    <IconCheckboxFake/>
                </div>
            )}
            {!isReportAvailable && (
                <div className="ReportsItem__status-icon">
                    <IconLock/>
                </div>
            )}
            <div className="ReportsItem__content">
                <div className="ReportsItem__icon">{itemIcon}</div>
                <div className="ReportsItem__title">
                    {name}
                    <div className="ReportsItem__title-price">
                        {!!itemPriceLabel && <>({itemPriceLabel})</>}
                    </div>
                </div>
                <div className="ReportsItem__description">{description}</div>
            </div>
        </div>
    );
};

export default ReportsItem;
