import ReactDOM from 'react-dom';
import { Component } from 'react';
import PT from 'prop-types';
import Scrollbar from 'react-custom-scrollbars';
import { contains, capitalize } from '../../core/old_helpers';
import Popup from '../Popup/Popup';

class SelectCompany extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchValue: '',
            companiesIds: [...this.props.value].sort((a, b) => (a < b ? -1 : 1))
        };

        this.closeOnEsc = this.closeOnEsc.bind(this);
        this.save = this.save.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.deselectAll = this.deselectAll.bind(this);
        this.setRef = this.setRef.bind(this);
        this.renderOption = this.renderOption.bind(this);
        this.changeSearchValue = this.changeSearchValue.bind(this);
        this.handleClickOption = this.handleClickOption.bind(this);
        this.close = this.close.bind(this);
    }

    componentDidMount() {
        this.dropDown.focus();
        document.addEventListener('click', this.close, true);
        document.addEventListener('keydown', this.closeOnEsc, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.close, true);
        document.removeEventListener('keydown', this.closeOnEsc, true);
    }

    closeOnEsc(e = false) {
        if (e.code === 'Escape') {
            this.props.close();
            e.stopPropagation();
        }
    }

    close(e = false) {
        if (!e || !ReactDOM.findDOMNode(this.dropDown).contains(e.target)) {
            this.props.close();
        }
    }

    handleClickOption(value) {
        if (this.state.companiesIds.includes(value)) {
            this.setState({
                companiesIds: this.state.companiesIds.filter(id => id !== value)
            });
        } else {
            this.setState({
                companiesIds: [
                    ...this.state.companiesIds,
                    value
                ]
            });
        }
    }

    renderOption(option, index) {
        const { companiesIds } = this.state;
        const isChecked = companiesIds.includes(option.value);
        const classList = new Set(['custom-drop-down__option', 'custom-drop-down__multi-option', 'multi-option']);
        const iconClassList = new Set(['multi-option__marker']);

        if (isChecked) {
            iconClassList.add('multi-option__marker--checked');
        }

        return (
            <div
                className={Array.from(classList).join(' ')}
                key={index}
                onClick={() => this.handleClickOption(option.value)}
                data-testid={`buttonSelectCompanyPopupOption-${index}`}
            >
                <div className={Array.from(iconClassList).join(' ')}>
                    { isChecked ? '✓' : '' }
                </div>
                {capitalize(option.title)}
            </div>
        );
    }

    renderOptions() {
        const { options } = this.props;
        const { searchValue } = this.state;
        let filteredOptions = options.concat([]).sort((a, b) => (a.title < b.title ? -1 : 1));

        if (searchValue !== '') {
            filteredOptions = options.filter(
                option => contains(option.title, searchValue)
            );
        }

        return (
            <div className="custom-drop-down__options custom-drop-down__options--comp">
                <div className="custom-drop-down__scrollbar-outer--comp">
                    <Scrollbar>
                        { filteredOptions.length > 0 ? filteredOptions.map(this.renderOption) : (
                            <span data-testid="textSelectCompanyPopupNotFound">
                                Companies are not found.
                            </span>
                        ) }
                    </Scrollbar>
                </div>
            </div>
        );
    }

    changeSearchValue(event) {
        this.setState({ searchValue: event.target.value });
    }

    setRef(dropDown) {
        this.dropDown = dropDown;
    }

    save() {
        this.props.setValue(this.state.companiesIds);
    }

    selectAll() {
        this.setState({
            companiesIds: this.props.options.map(company => company.value)
        });
    }

    deselectAll() {
        this.setState({
            companiesIds: [this.props.currentCompanyId]
        });
    }

    render() {
        const { searchValue } = this.state;
        const classList = new Set(['custom-drop-down', 'custom-drop-down--wide']);

        return (
            <Popup isOpened={true}>
                <div
                    ref={this.setRef}
                    className={Array.from(classList).join(' ')}
                    data-testid="blockSelectCompanyPopup"
                >
                    <div className="custom-drop-down__main custom-drop-down__main--no-pad">
                        <input
                            type="search"
                            autoFocus="autofocus"
                            value={searchValue}
                            onChange={this.changeSearchValue}
                            className="custom-drop-down__input custom-drop-down__input--comp"
                            placeholder="Search company..."
                            data-testid="inputSearchCompanyPopupSearch"
                        />
                        { this.renderOptions() }
                    </div>
                    <div className="custom-drop-down__pre-footer">
                        <button
                            className="popup__link popup__link--small popup__link--gray"
                            onClick={this.selectAll}
                            data-testid="buttonSelectCompanyPopupSelectAll"
                        >
                            Select All
                        </button>
                        <button
                            className="popup__link popup__link--small popup__link--gray popup__link--indent-left"
                            onClick={this.deselectAll}
                            data-testid="buttonSelectCompanyPopupDeselectAll"
                        >
                            Deselect All
                        </button>
                    </div>
                    <div className="custom-drop-down__footer">
                        <div className="popup__right-side">
                            <button
                                onClick={this.props.close}
                                className="popup__link popup__link--comfortaa"
                                data-testid="buttonSelectCompanyPopupCancel"
                            >
                                cancel
                            </button>
                            <button
                                onClick={this.save}
                                className="popup__btn popup__btn--xmd"
                                data-testid="buttonSelectCompanyPopupSave"
                            >
                                SAVE
                            </button>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}

SelectCompany.propTypes = {
    options: PT.array,
    multiple: PT.bool,
    value: PT.array,
    setValue: PT.func,
    placeholder: PT.string,
    search: PT.bool,
    checkAll: PT.bool,
    clear: PT.bool,
    className: PT.string,
    type: PT.string,
    createNew: PT.func,
    close: PT.func,
    createNewWhenNotFound: PT.bool,
    currentCompanyId: PT.number
};

export default SelectCompany;
