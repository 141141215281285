import Http from '../fetch';
import { useData } from "@hooks/useData";
import { API__ChainMenuAndLtosViewModel, API__OfferingChain, API__PlaceWithMenuItemsViewModel } from "@apiSchema";
import { iTopChainsData, TopChainsData } from "@models/TopChainsData";
import newTopChains from "@pages/NewTopChains/NewTopChains";

export interface OfferingChainsData extends iTopChainsData {
    menuItemsData: API__ChainMenuAndLtosViewModel[];
}

async function getOfferingChains(q: string): Promise<OfferingChainsData> {
    const data: API__PlaceWithMenuItemsViewModel[]  = await Http.get('offering chains + items', { SearchWord: q });

    return {
        ...new TopChainsData({ apiModel: data }),
        menuItemsData: data.map((i) => ({
            chainId: i.id,
            chainName: i.chainName,
            menuItems: i.menuItems,
        })),
    };
}

export function useOfferingChains(q: string) {
    return useData({
        ...new TopChainsData(),
        menuItemsData: [],
    }, getOfferingChains, q);
}