import { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import Popup from '../../components/Popup/Popup';
import DropZone from 'react-dropzone';
import Icon from '../../components/SpriteIcon/SpriteIcon';
import SelectShare from '../../components/SelectShare/SelectShare';
import SelectCompany from '../../components/SelectCompany/SelectCompany';
import SelectProject from '../../components/SelectProject/SelectProject';
import SelectType from '../../components/SelectType/SelectType';
import { FILE_SIZE_MAX_LIMIT } from '../DocumentsLibraryPopups/documentsLibraryPopups.reducers';
import {
    typesSelector,
    projectsSelector,
    contactsSelector,
    companiesSelector,
    companyIdSelector,
} from '../DocumentsLibraryPopups/documentsLibraryPopups.selectors';
import {
    close,
    save,
    setData,
    showValidationErrors,
    createNewProject,
    checkNameUnique,
    hideValidationErrors,
    clearData,
    storeUploadingFile,
    abortPreviousUploads,
} from './documentEditPopup.actions';
import { makeEnding, cutText, } from '../../core/old_helpers';
import { getArcLength } from '../../core/circles';
import getFileTypeIcon from '../../core/getFileTypeIcon';
import { documentEditPopupSubtitle } from './documentEditPopup.selectors';
import DocumentUploadGuidelinesPopup from "@components/DocumentUploadGudelinesPopup/DocumentUploadGuidelinesPopup";

class DocumentEditPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openedSelectType: '',
        };

        this.createNewProject = this.createNewProject.bind(this);
        this.openSelect = this.openSelect.bind(this);
        this.closeSelect = this.closeSelect.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.close = this.close.bind(this);
        this.startUploading = this.startUploading.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.data && prevProps.data && this.props.data.projectId !== prevProps.data.projectId) {
            setTimeout(() => {
                const projectInnerSpan = document.querySelector('.file-upload-credential__project-inner');
                let projectInnerSpanWidth;
                const projectSelect = document.querySelector('.file-upload-credential__project');
                let projectSelectWidth;


                if(projectSelect) {
                    projectSelectWidth = projectSelect.getBoundingClientRect().width - 50;
                }
                if(projectInnerSpan) {
                    projectInnerSpanWidth = projectInnerSpan.getBoundingClientRect().width
                }
                if(projectInnerSpanWidth > projectSelectWidth) {
                    projectInnerSpan.innerHTML = cutText(projectInnerSpan.textContent, 15);
                }
            }, 0);
        }
    }

    save() {
        const { save, close, showValidationErrors, hideValidationErrors, data } = this.props;
        const { name, isNameUnique, isFileChanged, file } = data;
        const isNameSet = (name !== '' && isNameUnique) || data.name === data.initialName;
        const isProjectSet = data.project !== null;
        let isSizeValid = true;
        if (isFileChanged) {
            isSizeValid = file.size < FILE_SIZE_MAX_LIMIT;
        }

        if (isNameSet && isProjectSet && isSizeValid) {
            save();
            close();
            hideValidationErrors();
        } else {
            showValidationErrors();
        }
    }

    close() {
        this.setState({
            openedSelectType: '',
        });
        this.props.close();
        this.props.clearData();
    }

    openSelect(type) {
        this.setState({
            openedSelectType: type,
        });
    }

    closeSelect() {
        this.setState({ openedSelectType: '' });
    }

    changeValue(type, value) {
        this.props.setData({ [type]: value });
        this.closeSelect();
    }

    changeName(value) {
        this.props.setData({ name: value });
        this.props.checkNameUnique(value);
    }

    createNewProject(name, description) {
        this.props.addNewProject(name, description);
        this.closeSelect();
    }

    renderText() {
        const { data } = this.props;
        const shareText = this.getShareFieldText();

        const inputClassList = new Set([
            'file-upload__input',
        ]);

        let errorMessage = '';

        if (data.showErrors && !data.isNameUnique && data.name !== data.initialName) {
            errorMessage = 'this document name already exists';
        }

        return (
            <div className="file-upload__text">
                <label
                    htmlFor="doc_name"
                    className="popup__label popup__label--uppercase popup__label--transp"
                    data-testid="textDocumentEditPopupNameLabel"
                >
                    Name
                </label>
                <div className="popup__error-msg popup__error-msg--doc-edit">
                    {errorMessage}
                </div>
                <input
                    value={data.name}
                    onChange={event => this.changeName(event.target.value)}
                    className={Array.from(inputClassList).join(' ')}
                    placeholder="Document name"
                    data-testid="inputDocumentEditPopupName"
                />
                <div className="file-upload-credential file-upload-credential--small file-upload-credential__share">
                    <span
                        onClick={this.openSelect.bind(this, 'share')}
                        dangerouslySetInnerHTML={{ __html: shareText }}
                        className="popup__content-link--share"
                        data-testid="buttonDocumentEditPopupSelectPermissions"
                    />
                </div>
            </div>
        );
    }

    startUploading({0: file}) {
        const {
            startUploadingFile,
            abortPreviousUploads,
            fileId,
        } = this.props;
        if (file.size > 0) {
            abortPreviousUploads(fileId);
            startUploadingFile(file);
        }
    }

    renderCredentials() {
        const { typesList, projectsList, data, companiesList } = this.props;
        const { typeId, projectId, showErrors } = data;

        const projectClassList = new Set(['file-upload-credential__status']);
        const projectBtnClassList = new Set(['popup__select', 'popup__select--lg', 'file-upload-credential__project']);
        const typeClassList = new Set(['file-upload-credential__status']);
        const typeBtnClassList = new Set(['popup__select', 'popup__select--md']);

        if (projectId) {
            projectClassList.add('file-upload-credential__status--filled');
            projectBtnClassList.add('popup__select--fulfilled');
        } else if (showErrors) {
            projectClassList.add('file-upload-credential__status--invalid');
        } else {
            projectClassList.add('file-upload-credential__status--need-to-fill');
        }

        if (typeId) {
            typeClassList.add('file-upload-credential__status--filled');
            typeBtnClassList.add('popup__select--fulfilled');
        }

        let projectTitle;

        if (projectId && projectsList.find(project => project.value === projectId)) {
            projectTitle = projectsList.find(project => project.value === projectId).title.toLowerCase();
        }

        if (projectTitle && projectTitle.length > 20) {
            projectBtnClassList.add('popup__select--small-font');
        }

        projectTitle = projectTitle && projectTitle.length > 83 ? projectTitle.slice(0, 80) + '...' : projectTitle;

        return (
            <div className="file-upload__credentials">
                <div className="file-upload-credential file-upload-credential__type">
                    <span data-testid="textDocumentEditPopupSelectTypeLabel">this is a</span>
                    <div
                        className={Array.from(typeBtnClassList).join(' ')}
                        onClick={() => this.openSelect('type')}
                        data-testid="buttonDocumentEditPopupSelectType"
                    >
                        <div className={Array.from(typeClassList).join(' ')}>✓</div>
                        <span data-testid="textDocumentEditPopupTypeName">
                            {typeId
                                ? typesList.find(type => type.value === typeId).title.toLowerCase()
                                : 'select'
                            }
                        </span>
                    </div>
                </div>
                <div className="file-upload-credential file-upload-credential__project">
                    <span data-testid="textDocumentEditPopupSelectProjectLabel">
                        link to this project
                    </span>
                    <div
                        className={Array.from(projectBtnClassList).join(' ')}
                        onClick={() => this.openSelect('project')}
                        data-testid="buttonDocumentEditPopupSelectProject"
                    >
                        <div className={Array.from(projectClassList).join(' ')}>✓</div>
                        {projectTitle ? (
                            <span
                                className="file-upload-credential__project-inner"
                                data-testid="textDocumentEditPopupProjectName"
                            >
                                {projectTitle}
                            </span>
                        ) : (
                            <div
                                className='file-upload__msg'
                                data-testid="textDocumentEditPopupProjectName"
                            >
                                choose project
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    getShareFieldText() {
        const { data, isCustomerViewMode, companiesList, customerViewCompanyId } = this.props;
        const { shareContacts, shareType } = data;
        const customerViewModeCompany = companiesList.find(company => company.value === customerViewCompanyId);
        const contactsText = `${shareContacts.size} ${makeEnding('person', shareContacts.size)}`;

        const shareText = (() => {
            if (isCustomerViewMode) {
                switch (shareType) {
                    case 0: {
                        return `everyone at ${customerViewModeCompany.title} can view and edit`;
                    }
                    case 1: {
                        if (shareContacts.size === 0) {
                            return `everyone at ${customerViewModeCompany.title} can view, no one can edit`;
                        }
                        return `everyone at ${customerViewModeCompany.title} can view, ${contactsText} can edit`;
                    }
                    case 2: {
                        if (shareContacts.size === 0) {
                            return 'no one can view or edit';
                        }
                        return `${contactsText} can view, no one can edit`;
                    }
                    default: {
                        return '';
                    }
                }
            } else {
                switch (shareType) {
                    case 0: {
                        return 'everyone at my company can view and edit';
                    }
                    case 1: {
                        if (shareContacts.size === 0) {
                            return 'everyone at my company can view, only me can edit';
                        }
                        return `everyone at my company can view, me and ${contactsText} can edit`;
                    }
                    case 2: {
                        if (shareContacts.size === 0 || (shareContacts.size === 1 && shareContacts.keys().next().value === window.pageData.userId)) {
                            return 'I can view and edit';
                        }
                        return `${contactsText} can view, only me can edit`;
                    }
                    default: {
                        return '';
                    }
                }
            }
        })();
        return shareText;
    }

    renderManageViewTips() {
        return (
            <DocumentUploadGuidelinesPopup/>
        );
    }

    renderManageViewFooter() {
        return (
            <div ref="footer" className="popup__footer popup__footer--gray popup__footer--thin">
                <div className="popup__right-side popup__right-side--flex">
                    <button
                        onClick={this.close}
                        className="popup__link popup__link--comfortaa popup__link--white"
                        data-testid="buttonDocumentEditPopupCancel"
                    >
                        cancel
                    </button>
                    <button
                        onClick={() => this.save()}
                        className="popup__btn popup__btn--xmd"
                        data-testid="buttonDocumentEditPopupSave"
                    >
                        Save
                    </button>
                </div>
            </div>
        );
    }

    renderProgress() {
        const {
            data,
            subTitle,
        } = this.props;
        const {
            progress = 0,
            fileSize,
            file,
            backEndData = {},
            isFileChanged,
            docTypeString = '',
        } = data;
        const circleStyle = {
            stroke: '#bfbfbf',
        };
        let errorSizeMessage = '';

        const progressArc = getArcLength(progress, 30);
        const restProgressArc = getArcLength(100, 30);

        let showProgress = false;
        let extension;
        let fileIcon;
        let isSizeValid;
        let isThumbnailExist;
        let thumbnail;

        if (isFileChanged) {
            extension = file.name.substring(file.name.lastIndexOf('.') + 1);
            fileIcon = `${getFileTypeIcon(extension)}-bw`;
            isSizeValid = file.size < FILE_SIZE_MAX_LIMIT;
            if (progress < 100 || !backEndData) {
                showProgress = isSizeValid;
            }
            if (backEndData) {
                isThumbnailExist = backEndData.thumnailExist;
                if (isThumbnailExist) {
                    thumbnail = backEndData.thumnailUrl.replace('182', '160');
                }
            }
        } else {
            extension = docTypeString.substr(1);
            fileIcon = `${getFileTypeIcon(extension)}-bw`;
            isSizeValid = fileSize < FILE_SIZE_MAX_LIMIT;
            isThumbnailExist = data.thumnailExist;
            if (isThumbnailExist) {
                thumbnail = data.thumnailUrl.replace('size=182', 'size=160');
            }
        }

        if (!isSizeValid) {
            errorSizeMessage = 'You can upload file less than 1GB';
        }

        return (
            <div className="file-upload__progress file-upload-progress">
                <div className="file-upload__error-msg file-upload__error-msg--size">
                    {errorSizeMessage}
                </div>
                <div className="file-upload-progress__img-wrap">
                    { showProgress ? (
                        <div className="file-upload-progress__wrap">
                            <svg
                                width="100"
                                height="100"
                                className="file-upload-progress__icon"
                            >
                                <circle
                                    className="file-upload-progress__circle"
                                    cx="50"
                                    cy="50"
                                    r="30"
                                    strokeDasharray={`${progressArc}, ${restProgressArc}`}
                                    style={circleStyle}
                                />
                                <circle
                                    className="file-upload-progress__circle"
                                    cx="50"
                                    cy="50"
                                    r="30"
                                    stroke="rgba(200, 200, 200, 0.3)"
                                />
                            </svg>
                            <div className="file-upload-progress__text">
                                {`${parseInt(progress, 10)}%`}
                            </div>
                        </div>
                    ) : (
                        <div>
                            { isThumbnailExist ? (
                                <img
                                    className="file-upload-progress__img"
                                    src={thumbnail}
                                    data-testid="blockDocumentEditPopupImage"
                                />
                            ) : (
                                <Icon
                                    className="file-upload-progress__file-icon file-upload-progress__file-icon--next-to-button"
                                    iconId={fileIcon}
                                    data-testid="blockDocumentEditPopupIcon"
                                />
                            )}
                            <DropZone
                                onDrop={this.startUploading} multiple={false}
                                className="file-upload-progress__change-btn"
                                data-testid="buttonDocumentEditPopupChangeFile"
                            >
                                Change file
                            </DropZone>
                        </div>
                    )}
                </div>
                <div
                    className="file-upload__file-info"
                    dangerouslySetInnerHTML={{
                        __html: subTitle,
                    }}
                />
            </div>
        );
    }

    render() {
        const {
            data, companyId, companiesList, projectsList, typesList, contactsList, isCustomerViewMode,
            customerViewCompanyId,
        } = this.props;
        const { isOpened, shareWithIds, shareType, shareContacts } = data;
        const { openedSelectType } = this.state;

        const selectedCompanies = companiesList
            .filter(company => shareWithIds.includes(company.value))
            .sort((a, b) => (a.value < b.value ? -1 : 1));
        let companiesDropDownTitle = '';
        if (selectedCompanies.length) {
            if (!isCustomerViewMode) {
                if (selectedCompanies.length === 0) {
                    companiesDropDownTitle = 'Datassential';
                } else if (selectedCompanies.length === 1) {
                    companiesDropDownTitle = selectedCompanies[0].title;
                } else if (selectedCompanies.length === 2) {
                    companiesDropDownTitle = `${selectedCompanies[1].title}`;
                } else {
                    companiesDropDownTitle = `
                ${selectedCompanies[1].title} and ${selectedCompanies.length - 2} more
            `;
                }
            } else {
                companiesDropDownTitle = selectedCompanies.find(({ value }) => value === customerViewCompanyId).title;
            }
        }
        return isOpened && (
            <Popup
                isOpened={isOpened}
                onClose={this.close}
                onOpen={this.open}
                className="popup-edit popup--fade-in">
                <div className="popup__inner popup__inner--small">
                    <div className="popup__header">
                        <span
                            className="popup__title"
                            data-testid="textDocumentEditPopupTitle"
                        >
                            EDIT FILE...
                        </span>
                        <div className="popup__header-right">
                            {
                                companyId === 1 && (
                                    <div className="popup__header-company">
                                        <span data-testid="textDocumentEditPopupSelectCompanyLabel">
                                            company
                                        </span>
                                        <div
                                            className="popup__header-company-name"
                                            onClick={() => {
                                                if (!isCustomerViewMode) {
                                                    this.openSelect('company');
                                                }
                                            }}
                                            data-testid="buttonDocumentEditPopupSelectCompany"
                                        >
                                            {companiesDropDownTitle}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="file-upload">
                        <div className="popup__upload-left">
                            {this.renderText()}
                            {this.renderCredentials()}
                        </div>
                        <div className="popup__upload-right">
                            {this.renderProgress()}
                        </div>
                    </div>
                    {this.renderManageViewFooter()}
                    {openedSelectType === 'project' && (
                        <SelectProject
                            saveNew={this.createNewProject}
                            close={this.closeSelect}
                            options={projectsList}
                            value={data.projectId}
                            setValue={this.changeValue.bind(this, 'projectId')}
                        />
                    )}
                    {openedSelectType === 'type' && (
                        <SelectType
                            close={this.closeSelect}
                            options={typesList}
                            value={data.typeId}
                            setValue={this.changeValue.bind(this, 'typeId')}
                        />
                    )}
                    {openedSelectType === 'company' && (
                        <SelectCompany
                            close={this.closeSelect}
                            options={companiesList.filter(company => company.value !== companyId)}
                            value={data.shareWithIds}
                            currentCompanyId={companyId}
                            setValue={this.changeValue.bind(this, 'shareWithIds')}
                        />
                    )}
                    {openedSelectType === 'share' && (
                        <SelectShare
                            close={this.closeSelect}
                            options={[
                                { value: 0, title: 'anyone can view or edit' },
                                { value: 1, title: 'anyone can view, only certain people can edit' },
                                { value: 2, title: 'only certain people can view' },
                            ]}
                            contacts={[
                                ...contactsList.filter(contacts => data.shareContacts.has(contacts.value)),
                                ...contactsList.filter(contacts => !data.shareContacts.has(contacts.value)),
                            ]}
                            value={{ shareContacts, shareType }}
                            setValue={this.props.setData}
                            isViewFieldRequired={isCustomerViewMode}
                        />
                    )}
                    {companyId === 1 && this.renderManageViewTips()}
                </div>
            </Popup>
        );
    }
}

DocumentEditPopup.propTypes = {
    className: PT.string,
    data: PT.object,
    companyId: PT.number,
    companiesList: PT.array,
    projectsList: PT.array,
    typesList: PT.array,
    contactsList: PT.array,
    close: PT.func,
    changeSelectValue: PT.func,
    changeName: PT.func,
    changeDescription: PT.func,
    save: PT.func,
    showValidationErrors: PT.func,
    hideValidationErrors: PT.func,
    setData: PT.func,
    clearData: PT.func,
    addNewProject: PT.func,
    checkNameUnique: PT.func,
    startUploadingFile: PT.func,
    abortPreviousUploads: PT.func,
    isCustomerViewMode: PT.bool,
    customerViewCompanyId: PT.number,
};

const mapStateToProps = state => ({
    typesList: typesSelector(state),
    projectsList: projectsSelector(state),
    isCustomerViewMode: state.documentsAddPopupData.isCustomerViewMode,
    customerViewCompanyId: state.documentsAddPopupData.customerViewCompanyId,
    companiesList: companiesSelector(state),
    contactsList: contactsSelector(state),
    data: state.documentEditPopupData,
    companyId: companyIdSelector(state),
    subTitle: documentEditPopupSubtitle(state),
    fileId: state.documentEditPopupData.id,
});

const mapDispatchDoProps = dispatch => ({
    close: () => dispatch(close()),
    setData: data => dispatch(setData(data)),
    save: () => dispatch(save()),
    showValidationErrors: () => dispatch(showValidationErrors()),
    hideValidationErrors: () => dispatch(hideValidationErrors()),
    addNewProject: (name, description) => dispatch(createNewProject(name, description)),
    checkNameUnique: name => dispatch(checkNameUnique(name)),
    clearData: () => dispatch(clearData()),
    startUploadingFile: file => dispatch(storeUploadingFile(file)),
    abortPreviousUploads: (fileId) => dispatch(abortPreviousUploads(fileId)),
});

export default connect(mapStateToProps, mapDispatchDoProps)(DocumentEditPopup);
