import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import './ReportProNewReleases.styles.scss';
import ColumnSlider from "@components/ColumnSlider/ColumnSlider";
import cn from 'classnames';
import { IconArrowRight } from "@icons";
import { useReportProNewReleases } from "@apiHooks/useReportProNewReleases";
import { AppContext } from "../../../AppContext";
import { sanitizeUrl } from "@helpers";
import { getSlidesPerView } from "@pages/Homepage/ReportProNewReleases/ReportProNewReleases.helpers";
import useWindowSizeDebounced from "@hooks/useWindowSizeDebounced";
import ReportProTile from "@components/ReportProTile/ReportProTile";

const CENTER_GAP = 350;

const AUTO_CLOSE_TIMEOUT = 5000;

const ReportProNewReleases: FC = () => {
    const [width] = useWindowSizeDebounced(200);
    const closeTimer = useRef<number>(0);

    const {
        globalVariables: {
            reportProUrl,
        },
    } = useContext(AppContext);

    const [isOpened, setIsOpened] = useState(false);
    const { data, isLoading } = useReportProNewReleases();
    const classNames = cn(
        'ReportProNewReleases',
        isOpened && 'is-opened',
        isLoading && 'is-loading',
    );

    const viewportWidth = useMemo(() => (width - CENTER_GAP) / 2, [width]);

    const slidesPerView = useMemo(() => {
        return getSlidesPerView(viewportWidth, 130);
    }, [viewportWidth]);

    useEffect(() => {
        if (!isLoading) {
            setIsOpened(true);

            closeTimer.current = window.setTimeout(() => {
                setIsOpened(false);
            }, AUTO_CLOSE_TIMEOUT);
        }
    }, [isLoading]);

    const denyAutoClose = useCallback(() => {
        window.clearTimeout(closeTimer.current);
    }, [closeTimer.current]);

    return (
        <div
            className={classNames}
            style={{
                width: viewportWidth,
            }}
        >
            <div className="ReportProNewReleases__button-container">
                <div
                    className="ReportProNewReleases__button"
                    onClick={() => setIsOpened(!isOpened)}
                >
                    <div className="ReportProNewReleases__button-icon"/>
                </div>
                <div className="ReportProNewReleases__view">
                    <div className="ReportProNewReleases__header">
                        <div className="ReportProNewReleases__title">Fake</div>
                    </div>
                    <div className="ReportProNewReleases__inner">
                        <ColumnSlider slidesPerView={slidesPerView}>
                            <ReportProTile/>
                            <></>
                        </ColumnSlider>
                    </div>
                </div>
            </div>
            <div className="ReportProNewReleases__viewport">
                <div
                    className="ReportProNewReleases__view"
                    onMouseEnter={denyAutoClose}
                >
                    <div className="ReportProNewReleases__header">
                        <div className="ReportProNewReleases__title">
                            New Content | Report Pro
                        </div>
                        <a
                            href={sanitizeUrl(`${reportProUrl}/browse?sortOrder=CreateDateToOrder&orderBy=desc`)}
                            target="_blank"
                            className="ReportProNewReleases__link-button"
                        >
                            Show all<IconArrowRight/>
                        </a>
                    </div>
                    <div className="ReportProNewReleases__inner">
                        <ColumnSlider slidesPerView={slidesPerView}>
                            {data.length === 0 && (
                                <ReportProTile/>
                            )}
                            {data.map((item) => (
                                <ReportProTile key={item.id} data={item}/>
                            ))}
                        </ColumnSlider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReportProNewReleases;