import { cutDomainName, getFormattedDate, sanitizeUrl } from "../helpers";
import { API__ChainNews } from "./api/ChainNews";

export interface iChainNews {
    title: string;
    date: string;
    url: string;
    shortUrl: string;
    description: string;
}

export class ChainNews implements iChainNews {
    static defaultData: iChainNews = {
        title: "",
        date: "",
        url: "",
        description: "",
        shortUrl: ""
    }

    title = ChainNews.defaultData.title;
    date = ChainNews.defaultData.date;
    url = ChainNews.defaultData.url;
    description = ChainNews.defaultData.description;
    shortUrl = ChainNews.defaultData.shortUrl;

    constructor(data: API__ChainNews) {
        if ( data ) {
            this.mapFromApi(data);
        }
    }

    private mapFromApi(data: API__ChainNews) {
        const url = data.source || ChainNews.defaultData.url;
        this.setDate({
            title: data.title,
            date: getFormattedDate(data.createdOnDate, "MMMM DD, YYYY"),
            url: sanitizeUrl(url),
            description: data.description || ChainNews.defaultData.description,
            shortUrl: cutDomainName(url)
        })
    }

    private setDate(model: iChainNews) {
        ({
            title: this.title,
            date: this.date,
            url: this.url,
            description: this.description,
            shortUrl: this.shortUrl,
        } = model);
    }
}