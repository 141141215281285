import { FC, memo } from "react";
import cn from "classnames";
import { iTableConfigItem, TABLE_ROW_SIZE } from "./Table.helpers";
import TableBodyWrapper from "./TableBodyWrapper";
import TableHead from "./TableHead";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import CustomScrollbarsVirtualList from "../../CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";
import TableRow from "./TableRow";
import { TableContext } from "./TableContext";
import CopyButton from "./CopyButton";
import "./TableNew.styles.scss";
import CopyNotification from "./CopyNotification";
import useNotification from "../../../core/hooks/useNotification";

interface iProps {
    config: iTableConfigItem[];
    isLoading?: boolean;
    rowsCount: number;
    rowHeight?: number | string;
    useIsScrolling?: boolean;
    hasError?: boolean;
    hasHeader?: boolean;
    hasBody?: boolean;
    hasInnerScroll?: boolean;
    isCopyButtonHidden?: boolean;
    theme?: 'dark' | 'light';
    testId?: string;
}

const Table: FC<iProps> = ({
    config,
    isLoading = false,
    rowsCount,
    rowHeight = TABLE_ROW_SIZE,
    useIsScrolling,
    hasError = false,
    hasHeader = true,
    hasBody = true,
    hasInnerScroll = true,
    isCopyButtonHidden = false,
    theme = 'dark',
    testId,
}) => {
    const notification = useNotification();

    const classNames = cn(
        "TableNew",
        `TableNew--theme-${theme}`,
        hasInnerScroll ? "TableNew--has-scrollbar" : "TableNew--auto-height",
    );

    const copyButtonClassNames = cn(
        "TableNew__copy-button",
        {
            "is-no-head" : !hasHeader,
            "is-no-scroll": !hasInnerScroll
        }
    )

    const styles = {
        row: {
            paddingRight: isCopyButtonHidden ? 0 : 35,
        }
    }



    return (
        <TableContext.Provider value={{ config }}>
            <div
                className={classNames}
                data-testid={testId}
            >
                <div className="TableNew__container">
                    {hasHeader && <TableHead styles={styles.row} />}

                    <CopyNotification isOpen={notification.isOpen} />

                    {!isCopyButtonHidden && !!rowsCount && (
                        <div
                            className={copyButtonClassNames}
                            onClick={notification.handleShowNotification}
                        >
                            <CopyButton testId="buttonCopyTable"/>
                        </div>
                    )}

                    {hasBody && (
                        <TableBodyWrapper
                            hasError={hasError}
                            isLoading={isLoading}
                            rowsCount={rowsCount}
                        >
                            {hasInnerScroll && (
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <List
                                            height={height}
                                            width={width}
                                            itemCount={rowsCount}
                                            itemSize={+rowHeight || TABLE_ROW_SIZE}
                                            useIsScrolling={useIsScrolling}
                                            outerElementType={CustomScrollbarsVirtualList}
                                            itemData={{
                                                styles: styles.row
                                            }}
                                        >
                                            {TableRow}
                                        </List>
                                    )}
                                </AutoSizer>
                            )}

                            {!hasInnerScroll && (
                                new Array(rowsCount)
                                    .fill(0)
                                    .map((_, index) => (
                                        <TableRow
                                            key={index}
                                            index={index}
                                            style={{
                                                ...styles.row,
                                                height: rowHeight,
                                            }}/>
                                    ))
                            )}
                        </TableBodyWrapper>
                    )}
                </div>
            </div>
        </TableContext.Provider>
    );
};

export default memo(Table);
