import { FC } from "react";
import "./TabsItem.styles.scss";

interface iProps {
    isShown: boolean;
    className?: string;
}
const TabsItem: FC<iProps> = ({
    isShown,
    className = "",
    children,
}) => {
    if (!isShown) return null;

    return <div className={`TabsItem ${className}`}>{children}</div>;
};

export default TabsItem;
