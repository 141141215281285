import Http from '../../core/fetch';
import {
    PROJECT_DATA_STORE,
    PROJECT_DATA_CLEAR
} from '../../actions/actionsNamesList';

export function storeData(data) {
    return {
        type: PROJECT_DATA_STORE,
        data
    };
}

export function getData() {
    return (dispatch, getState) => {
        const { id, customerViewCompanyId, isShareMode } = getState().projectData;
        const params = {
            projectId: id,
            isShareMode,
            time: Date.now(),
        };

        if (customerViewCompanyId) {
            params.companyId = customerViewCompanyId;
        }
        Http.get('get project', params)
            .then((response) => {
                dispatch(storeData({
                    ...response.project,
                    documents: response.projectDocuments
                }));
                dispatch(storeData({ dataLoaded: true }));
            });
    };
}

export function clearData() {
    return {
        type: PROJECT_DATA_CLEAR
    };
}
