import { FC } from "react";
import "./NotesFooter.styles.scss";

const NotesFooter: FC = ({
    children
}) => {
    return (
        <div className="NotesFooter">
            {children}
        </div>
    );
};

export default NotesFooter;
