import React, { useEffect, useRef, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import { filterDataBySearch, removeFromArray } from "../../../core/helpers";
import useComponentVisible from "../../../core/hooks/useComponentVisible";
import CustomScrollbarsVirtualList from "../../CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";
import Dropdown from "../Dropdown/Dropdown";
import Loader from "../Loader/Loader";
import { iOption } from "./Autocomplete.helpers";
import OptionTag from "./OptionTag/OptionTag";
import Option from "./Option/Option";
import "./Autocomplete.styles.scss";
import Scrollbars from "react-custom-scrollbars";

interface iProps {
    options: iOption[];
    selectedOptions: iOption[];
    onSelect: (options: iOption[]) => void;
    autoHighlight?: boolean;
    onSearch?: (value: string) => void;
    isDisabled?: boolean;
    isLoading?: boolean;
    autoFocus?: boolean;
    placeholder?: string;
}

const Autocomplete: React.FC<iProps> = ({
    isDisabled,
    isLoading,
    onSelect,
    options,
    autoHighlight = false,
    selectedOptions,
    autoFocus = false,
    onSearch = () => {},
    placeholder = "",
}) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(autoFocus);
    const inputRef = useRef<HTMLInputElement>(null);
    const [filteredData, setFilteredData] = useState<iOption[]>(options);
    const [searchQuery, setSearchQuery] = useState("");
    const listRef = useRef<any>(null);
    const DEFAULT_ACTIVE_OPTION_INDEX = -1;
    const [activeOptionIndex, setActiveOptionIndex] = useState(DEFAULT_ACTIVE_OPTION_INDEX);
    const [isDropdownToggling, setIsDropdownToggling] = useState(false);
    const ITEM_SIZE = 56;

    const handleDropdownToggle = (val: boolean) => {
        if (!isDisabled && !isLoading) {
            setIsDropdownToggling(true);
            setIsComponentVisible(val);
            setSearchQuery("");
            onSearch("");
            setActiveOptionIndex(DEFAULT_ACTIVE_OPTION_INDEX);
        }
    };

    const handleSelect = (option: iOption) => {
        onSelect([...selectedOptions, option]);
        setIsComponentVisible(false);
    };

    useEffect(() => {
        const trimSearchQuery = searchQuery.trim();

        const filteredSelected = options.filter(({ id }) => !selectedOptions.map(({ id }) => id).includes(id));
        const filteredBySearch = filterDataBySearch(filteredSelected, searchQuery);

        if (trimSearchQuery !== "") {
            filteredBySearch.sort((a, b) => {
                const isATitleBegins = a.title.toLowerCase().indexOf(trimSearchQuery) === 0;
                const isBTitleBegins = b.title.toLowerCase().indexOf(trimSearchQuery) === 0;
                const aScore = +isATitleBegins * 10;
                const bScore = +isBTitleBegins * 10;

                if (aScore === bScore)
                    return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
                else if (aScore < bScore) return 1;
                return -1;
            });
        }
        setFilteredData(filteredBySearch);
    }, [options, searchQuery, selectedOptions]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
        onSearch(e.target.value);
        setActiveOptionIndex(DEFAULT_ACTIVE_OPTION_INDEX);
    };

    useEffect(() => {
        if (!isComponentVisible && inputRef.current) {
            setSearchQuery("");
            onSearch("");
            setIsDropdownToggling(true);
            inputRef.current.blur();
        }
    }, [isComponentVisible, onSearch, isLoading]);

    const handleArrows = (e: React.KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
            case "ArrowDown":
                e.preventDefault();
                const downIdx =
                    activeOptionIndex === filteredData.length - 1
                        ? filteredData.length - 1
                        : activeOptionIndex + 1;
                setActiveOptionIndex(downIdx);
                if (listRef.current) {
                    listRef.current.scrollToItem(downIdx);
                }
                break;
            case "ArrowUp":
                e.preventDefault();
                const upIdx = activeOptionIndex === 0 ? 0 : activeOptionIndex - 1;
                setActiveOptionIndex(upIdx);
                if (listRef.current) {
                    listRef.current.scrollToItem(upIdx);
                }
                break;
            case "Enter":
                e.preventDefault();
                const currentUser = filteredData[activeOptionIndex];
                handleSelect(currentUser);
                break;
        }
    };

    return (
        <div className="Autocomplete" ref={ref}>
            <div className="Autocomplete__form">
                <input
                    className="Autocomplete__input"
                    placeholder={placeholder}
                    type="text"
                    onFocus={() => handleDropdownToggle(true)}
                    value={searchQuery}
                    onChange={handleSearch}
                    ref={inputRef}
                    autoFocus={autoFocus}
                    onKeyDown={e => handleArrows(e)}
                    disabled={isDisabled}
                />

                <Dropdown
                    shown={isComponentVisible && searchQuery.length > 0}
                    onExited={() => setIsDropdownToggling(false)}
                    onEntered={() => setIsDropdownToggling(false)}
                >
                    <div className="Autocomplete__dropdown" style={{ height: ITEM_SIZE * 4 }}>
                        {isLoading && !isDropdownToggling && (
                            <div className="Autocomplete__options-loader">
                                <Loader />
                            </div>
                        )}

                        {!isLoading && searchQuery.length > 0 && filteredData.length === 0 && (
                            <div className="Autocomplete__no-match-items">No match items...</div>
                        )}

                        {!!filteredData.length && (
                            <div
                                className="Autocomplete__options"
                                style={{ height: ITEM_SIZE * 4 }}
                            >
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <List
                                            className="List"
                                            outerElementType={CustomScrollbarsVirtualList}
                                            height={height}
                                            itemCount={filteredData.length}
                                            itemSize={ITEM_SIZE}
                                            width={width}
                                            ref={listRef}
                                            itemData={{
                                                options: filteredData,
                                                handleSelect,
                                                activeOptionIndex,
                                                searchQuery,
                                                autoHighlight,
                                            }}
                                        >
                                            {Option}
                                        </List>
                                    )}
                                </AutoSizer>
                            </div>
                        )}
                    </div>
                </Dropdown>
            </div>

            <div className="Autocomplete__tags">
                <Scrollbars>
                    <div className="Autocomplete__tags-list">
                        {selectedOptions.map(item => {
                            return (
                                <div className="Autocomplete__tags-item" key={item.id}>
                                    <OptionTag
                                        title={item.title}
                                        onRemove={() => onSelect(removeFromArray(selectedOptions, [item]))}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </Scrollbars>
            </div>
        </div>
    );
};

export default Autocomplete;
