import useDocument from "@apiHooks/useDocument";
import { routeNames } from "@core/routeNames";
import { useSelector } from "react-redux";
import { iDocumentQueryParams } from "./DocumentView.helpers";

const useDocumentViewData = (params: iDocumentQueryParams) => {
    const timestamp = useSelector((state: any) => state.documentEditPopupData.timestamp);
    const documentParams = {
        documentId: params.id,
        documentType: params.doctype,
        signature: params.signature,
        timestamp
    };
    const { isLoading, data } = useDocument(documentParams);

    const getBackwardRoute = (params: iDocumentQueryParams) => {
        return {
            pathname: params.fromDocuments ? routeNames.documents : routeNames.myDocuments,
            search: params.fromDocuments ? `${params.fromDocuments ? `?q=${encodeURIComponent(params.fromDocuments)}`: ''}` : ``
        }
    }

    return {
        getBackwardRoute,
        isLoading,
        data,
    }
}

export default useDocumentViewData;