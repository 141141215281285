export interface API__Advertisement {
    id: number;
    title: string;
    displayRestrict: number;
    startDate: string;
    endDate: string;
    interval: number;
}

export interface API__ArticleSearchPriority {
    id: number;
    publicationFamilyId: number;
    publicationFamily: API__PublicationFamily;
    categoryId: number;
    category: API__PublicationCategory;
    titlePriority: API__ArticleSearchPriorityTitlePriorityEnum;
    descriptionPriority: API__ArticleSearchPriorityDescriptionPriorityEnum;
    contentPriority: API__ArticleSearchPriorityContentPriorityEnum;
    priority: number;
}

export enum API__ArticleSearchPriorityTitlePriorityEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export enum API__ArticleSearchPriorityDescriptionPriorityEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export enum API__ArticleSearchPriorityContentPriorityEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export interface API__BaseChainNoteViewModel {
    id: number;
    isPublic: boolean;
    text: string;
}

export interface API__ChainBaseViewModel {
    id: number;
    chainName: string;
    parentCompanyName: string;
    metroArea: string;
    numberOfUnits: number;
    segment: string;
    menuType: string;
    stateFullName: string;
    unitsGrowth: number;
    alcohol: number;
}

export interface API__ChainDetailsModel {
    chainId: number;
    companyName: string;
    chainName: string;
    numberOfUnits: number;
    segment: string;
    cuisine: string;
    logoUrl: string;
}

export interface API__ChainFilterData {
    segments: string[];
    states: string[];
    metros: string[];
    menuTypes: string[];
    alcohols: string[];
    unitsTo: number;
    unitsFrom: number;
    searchQuery: string;
}

export interface API__ChainMenuAndLtosViewModel {
    chainId: number;
    chainName: string;
    menuItems: API__MenuItem[];
}

export interface API__ChainMenuItem {
    master_heading: string;
    heading: string;
    menu_part: string;
    item: string;
    item_description: string;
    item_price: number;
    photo: string;
}

export interface API__ChainMenuItemsData {
    chainName: string;
    chainId: number;
    totalCount: number;
    threeMonthcount: number;
    sixMonthcount: number;
    twelveMonthcount: number;
    twoYearscount: number;
}

export interface API__ChainModel {
    chainDetails: API__ChainDetailsModel;
    menuData: API__MenuDataModel[];
    news: API__NewsModel[];
    ltos: API__LtoModel[];
}

export interface API__ChainNoteViewModel {
    modifiedDateTime: string;
    isOwn: boolean;
    id: number;
    isPublic: boolean;
    text: string;
}

export interface API__ChainPermission {
    id: number;
    chainId: number;
    company: API__Company;
    companyId: number;
    ffPermissionState: API__ChainPermissionFfPermissionStateEnum;
    fpPermissionState: API__ChainPermissionFpPermissionStateEnum;
    scoresPermissionState: API__ChainPermissionScoresPermissionStateEnum;
    ffAccessEndDateTime: string;
    fpAccessEndDateTime: string;
    scoresAccessEndDateTime: string;
    isDeleted: boolean;
}

export enum API__ChainPermissionFfPermissionStateEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export enum API__ChainPermissionFpPermissionStateEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export enum API__ChainPermissionScoresPermissionStateEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}


export interface API__ChainPermissionWrapper<T> {
    permissionLevel: API__PermissionLevelEnum;
    hasData: boolean;
    data?: T;
}

interface UNUSED_INTERFACE_3354<T> {
    permissionLevel: API__PermissionLevelEnum;
    hasData: boolean;
    data: API__ChainMenuItem;
}

export enum API__PermissionLevelEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

interface UNUSED_INTERFACE_3593<T> {
    permissionLevel: API__PermissionLevelEnum;
    hasData: boolean;
    data: API__ChainReportViewModel;
}

enum UNUSED_ENUM_3752 {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

interface UNUSED_INTERFACE_3836<T> {
    permissionLevel: API__PermissionLevelEnum;
    hasData: boolean;
    data: API__DocumentsBlockViewModel;
}

enum UNUSED_ENUM_4009 {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

interface UNUSED_INTERFACE_4085<T> {
    permissionLevel: API__PermissionLevelEnum;
    hasData: boolean;
    data: API__FPChainData;
}

enum UNUSED_ENUM_4242 {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

interface UNUSED_INTERFACE_4310<T> {
    permissionLevel: API__PermissionLevelEnum;
    hasData: boolean;
    data: API__FlavorInterestViewModel;
}

enum UNUSED_ENUM_4499 {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

interface UNUSED_INTERFACE_4559<T> {
    permissionLevel: API__PermissionLevelEnum;
    hasData: boolean;
    data: API__FlavorWordInfo;
}

enum UNUSED_ENUM_4738 {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

interface UNUSED_INTERFACE_4790<T> {
    permissionLevel: API__PermissionLevelEnum;
    hasData: boolean;
    data: API__ChainNoteViewModel[];
}

enum UNUSED_ENUM_4997 {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

interface UNUSED_INTERFACE_5041<T> {
    permissionLevel: API__PermissionLevelEnum;
    hasData: boolean;
    data: API__InstantChartWordData[];
}

enum UNUSED_ENUM_5260 {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

interface UNUSED_INTERFACE_5296<T> {
    permissionLevel: API__PermissionLevelEnum;
    hasData: boolean;
    data: API__SRLtoWithScoresDataViewModel[];
}

enum UNUSED_ENUM_5539 {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

interface UNUSED_INTERFACE_5567<T> {
    permissionLevel: API__PermissionLevelEnum;
    hasData: boolean;
    data: API__UserContactViewModel[];
}

enum UNUSED_ENUM_5802 {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

interface UNUSED_INTERFACE_5822<T> {
    permissionLevel: API__PermissionLevelEnum;
    hasData: boolean;
    data: API__InsightsWrapper;
}

enum UNUSED_ENUM_6043 {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

interface UNUSED_INTERFACE_6055<T> {
    permissionLevel: API__PermissionLevelEnum;
    hasData: boolean;
    data: API__KeyAttributesDataViewModel;
}

enum UNUSED_ENUM_6306 {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

interface UNUSED_INTERFACE_6310<T> {
    permissionLevel: API__PermissionLevelEnum;
    hasData: boolean;
    data: API__LocalProductIndexArrayViewModel;
}

enum UNUSED_ENUM_6579 {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

interface UNUSED_INTERFACE_6575<T> {
    permissionLevel: API__PermissionLevelEnum;
    hasData: boolean;
    data: API__LtoLaunchesViewModel;
}

enum UNUSED_ENUM_6830 {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

interface UNUSED_INTERFACE_6818<T> {
    permissionLevel: API__PermissionLevelEnum;
    hasData: boolean;
    data: API__MacAndInstantChartViewModel;
}

enum UNUSED_ENUM_7095 {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

interface UNUSED_INTERFACE_7075<T> {
    permissionLevel: API__PermissionLevelEnum;
    hasData: boolean;
    data: API__NewPenetrationRootObjectViewModel;
}

enum UNUSED_ENUM_7372 {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

interface UNUSED_INTERFACE_7344<T> {
    permissionLevel: API__PermissionLevelEnum;
    hasData: boolean;
    data: API__PredictedHaikuDataViewModel;
}

enum UNUSED_ENUM_7637 {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

interface UNUSED_INTERFACE_7601<T> {
    permissionLevel: API__PermissionLevelEnum;
    hasData: boolean;
    data: API__ProfileViewModel;
}

enum UNUSED_ENUM_7880 {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

interface UNUSED_INTERFACE_7836<T> {
    permissionLevel: API__PermissionLevelEnum;
    hasData: boolean;
    data: API__SRLtoSubscriptionViewModel;
}

enum UNUSED_ENUM_8143 {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

export interface API__ChainReportViewModel {
    name: string;
    price: number;
}

export interface API__ChainsFilter {
    ids: number[];
    filter: API__ChainFilterData;
}

export interface API__Company {
    id: number;
    name: string;
    address: string;
    country: API__CompanyCountryEnum;
    state: API__CompanyStateEnum;
    city: string;
    zip: string;
    phone: string;
    dataAccess: boolean;
    isActive: boolean;
    exportLimit: number;
    userLimit: number;
    actCode: string;
    createdDt: string;
    createdById: string;
    modifiedDt: string;
    modifiedById: string;
    dateDeleted: string;
    deletedById: string;
    isDeleted: boolean;
    createdBy: API__SnapUser;
    modifiedBy: API__SnapUser;
    deletedBy: API__SnapUser;
    teamLeadId: string;
    teamLead: API__SnapUser;
    teamLeadEmail: string;
    partnerId: string;
    partner: API__SnapUser;
    partnerEmail: string;
    isAutoRegisterAllowed: boolean;
    isRequiresApproval: boolean;
    approvalResponsibleEmail: string;
    companyDomains: API__CompanyDomain[];
    permissions: API__CompanyPermission[];
    projects: API__Project[];
    foodProfilePermissions: API__FoodProfilePermissions[];
    scoresItemsPermissions: API__ScoresItemPermission[];
    chainPermissions: API__ChainPermission[];
    instantChartsPermissions: API__InstantChartPermission[];
    companyExternalPermissions: API__CompanyExternalPermission[];
    expirationList: API__ExpirationListUser[];
    userConfirmationPeriod: API__CompanyUserConfirmationPeriodEnum;
    isChecked: boolean;
    foodWatchId: number;
    foodWatch: API__FoodWatch;
    type: API__CompanyTypeEnum;
    enablePasswordExpiration: boolean;
    passwordExpirationPeriod: number;
    sessionDuration: number;
    hasAgency: boolean;
    clientCompanies: API__Company[];
    hasDisabledFrontDoor: boolean;
}

export enum API__CompanyCountryEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

export enum API__CompanyStateEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8,
    NUMBER_9 = 9,
    NUMBER_10 = 10,
    NUMBER_11 = 11,
    NUMBER_12 = 12,
    NUMBER_13 = 13,
    NUMBER_14 = 14,
    NUMBER_15 = 15,
    NUMBER_16 = 16,
    NUMBER_17 = 17,
    NUMBER_18 = 18,
    NUMBER_19 = 19,
    NUMBER_20 = 20,
    NUMBER_21 = 21,
    NUMBER_22 = 22,
    NUMBER_23 = 23,
    NUMBER_24 = 24,
    NUMBER_25 = 25,
    NUMBER_26 = 26,
    NUMBER_27 = 27,
    NUMBER_28 = 28,
    NUMBER_29 = 29,
    NUMBER_30 = 30,
    NUMBER_31 = 31,
    NUMBER_32 = 32,
    NUMBER_33 = 33,
    NUMBER_34 = 34,
    NUMBER_35 = 35,
    NUMBER_36 = 36,
    NUMBER_37 = 37,
    NUMBER_38 = 38,
    NUMBER_39 = 39,
    NUMBER_40 = 40,
    NUMBER_41 = 41,
    NUMBER_42 = 42,
    NUMBER_43 = 43,
    NUMBER_44 = 44,
    NUMBER_45 = 45,
    NUMBER_46 = 46,
    NUMBER_47 = 47,
    NUMBER_48 = 48,
    NUMBER_49 = 49,
    NUMBER_50 = 50,
    NUMBER_51 = 51,
    NUMBER_52 = 52,
    NUMBER_53 = 53,
    NUMBER_54 = 54,
    NUMBER_55 = 55,
    NUMBER_56 = 56,
    NUMBER_57 = 57,
    NUMBER_58 = 58,
    NUMBER_59 = 59,
    NUMBER_60 = 60,
    NUMBER_61 = 61,
    NUMBER_62 = 62
}

export enum API__CompanyUserConfirmationPeriodEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5
}

export enum API__CompanyTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4
}

export interface API__CompanyDataViewModel {
    id: number;
    logoUrl: string;
    segmentName: string;
    segmentId: number;
    cuisinePrimary: string;
    unitsCount: number;
    name: string;
    blurb: string;
    company: string;
    images: string[];
    city: string;
    address: string;
    state: string;
    zip: string;
    phone: string;
    fax: string;
    email: string;
    website: string;
    socialLinks: API__FFSocialLinks;
    metroArea: string;
    stateFullName: string;
    financialContent: API__FFFinancialData;
    salesByYear: API__FFYearValueData[];
    salesGrowth: number;
    unitsByYear: API__FFYearValueData[];
    unitsGrowth: number;
    auvByYear: API__FFYearValueData[];
    auvGrowth: number;
}

export interface API__CompanyDomain {
    id: number;
    domain: string;
    companyId: number;
    company: API__Company;
}

export interface API__CompanyExternalPermission {
    companyId: number;
    company: API__Company;
    id: number;
    externalDocumentId: number;
}

export interface API__CompanyModel {
    id: number;
    name: string;
}

export interface API__CompanyPermission {
    companyId: number;
    isChecked: boolean;
    addOrUpdate: boolean;
    isTrial: boolean;
    id: number;
    startDate: string;
    endDate: string;
    featureId: number;
    feature: API__Feature;
    isPurchase: boolean;
}

export interface API__CompetitorsTableRootObject {
    chainName: string;
    status: string;
    items: API__CompetitorsTableRow[];
    totalCount: number;
    totalPages: number;
    currentPage: string;
    rowsPerPage: string;
}

export interface API__CompetitorsTableRow {
    chainIdFrom: string;
    chainIdTo: string;
    chainName: string;
    summaryActionName: string;
    averageDistance: number;
    percent_05_miles: number;
    percent_1_miles: number;
    percent_3_miles: number;
    percent_5_miles: number;
    percent_10_miles: number;
    percent_20_miles: number;
    percent_50_miles: number;
    common_metros: string;
}

export interface API__ContactViewModel {
    fireflyContacts: API__ChainPermissionWrapper<API__UserContactViewModel[]>;
    myContacts: API__ChainPermissionWrapper<API__UserContactViewModel[]>;
}

export interface API__DecoderFallback {
    maxCharCount: number;
}

export interface API__Document {
    id: number;
    title: string;
    description: string;
    publicationDateTime: string;
    createdOn: string;
    isDeleted: boolean;
    fileId: number;
    file: any;
    sphinxId: number;
    pagesCount: number;
    parentDocumentId: number;
    overridedByUserGuid: string;
    overridedByUser: API__SnapUser;
    createdByUserGuid: string;
    createdByUser: API__SnapUser;
    whoCanEdit: API__DocumentWhoCanEditEnum;
    publicationFamilyId: number;
    publicationFamily: API__PublicationFamily;
    documentTypeId: number;
    documentType: API__DocumentType;
    featureId: number;
    feature: API__Feature;
    overridedForCompanyId: number;
    overridedForCompany: API__Company;
    editFeatureId: number;
    editFeature: API__Feature;
    featureToAllowViewByUserGroup: API__Feature;
    featureToAllowEditByUserGroup: API__Feature;
    featureToAllowViewByUserGroupId: number;
    featureToAllowEditByUserGroupId: number;
    documentProjectId: string;
    documentProject: API__Project;
    userGroups: API__UserGroup[];
    editUserGroups: API__UserGroup[];
    searchPriorityId: number;
    month: number;
    year: number;
    accessForEverybody: boolean;
    usersToGiveAccess: string[];
    projectsGuids: string[];
    usersAndGroups: string[];
    canEditDocument: boolean;
    docTypeClass: string;
    desiredFileName: string;
    companiesAssignedTo: API__Company[];
    alias: string;
    parentDocumentTitle: string;
    parentDocumentDescription: string;
    parentDocumentProject: API__Project;
    parentDocumentDocType: API__DocumentType;
    canEdit: boolean;
    newProjectName: string;
    newProjectDescription: string;
    newDocumentName: string;
    newDocumentDescription: string;
    canOverride: boolean;
    isShared: boolean;
    sharedWithCompanyIds: number[];
    isInternal: boolean;
    overrideForCompanyIdWithUsersIds: { [key: string]: string[]; };
}

export enum API__DocumentWhoCanEditEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export interface API__DocumentFilter {
    word: string;
    skip: number;
    take: number;
    doctype: string[];
    reportType: string;
}

export interface API__DocumentType {
    id: number;
    name: string;
    companyId: number;
    company: API__Company;
    isDeleted: boolean;
    isDefault: boolean;
}

export interface API__DocumentsBlockViewModel {
    totalCount: number;
    items: API__ReportProPublicationClient[];
    showAllUrl: string;
}

export interface API__DownloadFileLinkModel {
    downloadFileLink: string;
    itemName: string;
    userEmail: string;
    firstName: string;
}

export interface API__EncoderFallback {
    maxCharCount: number;
}

export interface API__Encoding {
    bodyName: string;
    encodingName: string;
    headerName: string;
    webName: string;
    windowsCodePage: number;
    isBrowserDisplay: boolean;
    isBrowserSave: boolean;
    isMailNewsDisplay: boolean;
    isMailNewsSave: boolean;
    isSingleByte: boolean;
    encoderFallback: API__EncoderFallback;
    decoderFallback: API__DecoderFallback;
    isReadOnly: boolean;
    codePage: number;
}

export interface API__ExpirationListUser {
    id: number;
    name: string;
    email: string;
    type: API__ExpirationListUserTypeEnum;
    companies: API__Company[];
}

export enum API__ExpirationListUserTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export interface API__FFFinancialData {
    last: number;
    change: number;
    changePercent: number;
    min52: number;
    max52: number;
    ticker: string;
}

export interface API__FFSocialLinks {
    wikipedia: string;
    facebook: string;
    twitter: string;
    instagram: string;
    youtube: string;
    pinterest: string;
    zomato: string;
    foursquare: string;
    snapchat: string;
    tumblr: string;
    linkedin: string;
}

export interface API__FFYearValueData {
    year: number;
    value: number;
}

export interface API__FPChainData {
    headerData: API__FPHeaderData;
    situationAssessmentData: API__FPSituationAssessmentData;
    brandFunnelData: API__FPNameValue[];
    foodAndBeverageQuality: API__FPNameValue[];
    valueProposition: API__FPNameValue[];
    customerExperience: API__FPNameValue[];
}

export interface API__FPCircleData {
    title: string;
    chainValue: number;
    averageValue: number;
}

export interface API__FPHeaderData {
    circles: API__FPCircleData[];
    nps: number;
    detractor: number;
    passive: number;
    promoter: number;
}

export interface API__FPNameValue {
    name: string;
    value: number;
}

export interface API__FPSituationAssessmentData {
    strengths: API__FPNameValue[];
    weakness: API__FPNameValue[];
}

export interface API__FacebookPost {
    postId: string;
    postStory: string;
    postMessage: string;
    postPicture: string;
    userId: string;
    userName: string;
    createdOn: string;
}

export interface API__FaqModel {
    json: string;
}

export interface API__Feature {
    id: number;
    name: string;
    description: string;
    price: number;
    isCategory: boolean;
    isBasic: boolean;
    productId: number;
    order: number;
    isStandard: boolean;
    isTrial: boolean;
    shareForNewCompanies: boolean;
    accessRequests: API__UserAccessRequest[];
    isChecked: boolean;
    startDate: string;
    endDate: string;
    productPermissionId: number;
    featureGroup: API__FeatureGroup;
}

export interface API__FeatureFlagViewModel {
    id: number;
    name: string;
    isOn: boolean;
}

export interface API__FeatureGroup {
    id: number;
    name: string;
    order: number;
    isDeleted: boolean;
    features: API__Feature[];
}

export interface API__FilterData {
    metros: string[];
    segments: string[];
    regions: string[];
    menuTypes: string[];
    skip: number;
    take: number;
    searchWord: string;
    elemsCount: number;
    segment: string;
    state: string;
    menuType: string;
    metroAreaId: number;
    metroAreaName: string;
    minUnits: number;
    maxUnits: number;
    order: string;
    filterMode: API__FilterDataFilterModeEnum;
    accessLevel: API__FilterDataAccessLevelEnum;
}

export enum API__FilterDataFilterModeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

export enum API__FilterDataAccessLevelEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export interface API__FlavorByoWordModel {
    knowIt: number;
    haveTriedIt: number;
    loveOrLikeIt: number;
}

export interface API__FlavorConfigModel {
    respondentGroupTypeInfo: API__FlavorRespondentGroupType[];
    heatLevelInfo: API__FlavorHeatLevel[];
    heatScoresTooltip: string;
    keywordsTotal: number;
}

export interface API__FlavorDataByRespondentType {
    respondentTypeId: number;
    data: API__FlavorMetricData;
}

export interface API__FlavorDetailedMetric {
    dataByRespondentType: API__FlavorDataByRespondentType[];
    generalData: API__FlavorMetricData;
    rank: number;
}

export interface API__FlavorHeatLevel {
    id: number;
    name: string;
    description: string;
}

export interface API__FlavorHeatScoresData {
    heatLevel: number;
    knowIt: number;
    triedIt: number;
    loveIt: number;
}

export interface API__FlavorInterestViewModel {
    knowIt: number;
    haveTriedIt: number;
    hadManyTimes: number;
    loveIt: number;
    mtdCategoryId: number;
    macStageName: string;
    generations: string;
    ethnicities: string;
    norms: API__NormsViewModel;
    genders10: string;
    ethnicities10: string;
    generations10: string;
    regions10: string;
    isEmpty: boolean;
    keywordUrl: string;
}

export interface API__FlavorMetric {
    generalData: API__FlavorMetricData;
    rank: number;
}

export interface API__FlavorMetricData {
    raw: number;
    norm: number;
}

export interface API__FlavorMostPopular {
    genders: number[];
    ethnicities: number[];
    generations: number[];
    regions: number[];
}

export interface API__FlavorRespondentGroupType {
    groupName: string;
    types: API__FlavorRespondentType[];
}

export interface API__FlavorRespondentType {
    id: number;
    name: string;
}

export interface API__FlavorWordInfo {
    config: API__FlavorConfigModel;
    data: API__FlavorWordModel;
}

export interface API__FlavorWordModel {
    id: string;
    mtdKeywordId: number;
    keywordUrl: string;
    knowIt: API__FlavorDetailedMetric;
    haveTriedIt: API__FlavorMetric;
    hadManyTimes: API__FlavorMetric;
    loveLikeIt: API__FlavorDetailedMetric;
    loveIt: API__FlavorMetric;
    likeIt: API__FlavorMetric;
    neutral: API__FlavorMetric;
    dislike: API__FlavorMetric;
    hateIt: API__FlavorMetric;
    noOpinion: API__FlavorMetric;
    heatScore: API__FlavorHeatScoresData;
    mostPopular: API__FlavorMostPopular;
}

export interface API__FoodProfilePageDataLiteViewModel {
    menuTrendsPenetration: API__ChainPermissionWrapper<API__NewPenetrationRootObjectViewModel>;
    flavorWordInfo: API__ChainPermissionWrapper<API__FlavorWordInfo>;
    flavorInterest: API__ChainPermissionWrapper<API__FlavorInterestViewModel>;
    foodProfileWord: string;
    foodProfileId: number;
}

export interface API__FoodProfilePageDataViewModel {
    menuTrendsPenetration: API__ChainPermissionWrapper<API__NewPenetrationRootObjectViewModel>;
    flavorInterest: API__ChainPermissionWrapper<API__FlavorInterestViewModel>;
    foodProfileWord: string;
    foodProfileId: number;
    document: API__ChainPermissionWrapper<API__DocumentsBlockViewModel>;
    productIndexByMetroState: API__ChainPermissionWrapper<API__LocalProductIndexArrayViewModel>;
}

export interface API__FoodProfilePermissions {
    id: number;
    foodProfileId: number;
    company: API__Company;
    companyId: number;
    endDateTime: string;
    isDeleted: boolean;
}

export interface API__FoodProfileWidgetsDataViewModel {
    document: API__ChainPermissionWrapper<API__DocumentsBlockViewModel>;
    productIndexByMetroState: API__ChainPermissionWrapper<API__LocalProductIndexArrayViewModel>;
    macAndInstantChart: API__ChainPermissionWrapper<API__MacAndInstantChartViewModel>;
}

export interface API__FoodWatch {
    id: number;
    createUserId: string;
    createUser: API__SnapUser;
    updateUserId: string;
    updateUser: API__SnapUser;
    chains: API__FoodWatchChain[];
    foodItems: API__FoodWatchFoodItem[];
    keywords: API__FoodWatchKeyword[];
}

export interface API__FoodWatchChain {
    id: number;
    chainId: number;
    foodWatchId: number;
    foodWacth: API__FoodWatch;
    name: string;
    segment: string;
    countryId: number;
}

export interface API__FoodWatchFoodItem {
    id: number;
    foodItemId: number;
    foodWatchId: number;
    foodWacth: API__FoodWatch;
}

export interface API__FoodWatchKeyword {
    id: number;
    value: string;
    foodWatchId: number;
    foodWacth: API__FoodWatch;
}

export interface API__HeaderDocumentsMenuItemsViewModel {
    addDocumentButton: boolean;
    myDocumentsButton: boolean;
    myDocumentsPageUrl: string;
    hasDocuments: boolean;
}

export interface API__HeaderItemViewModel {
    title: string;
    url: string;
}

export interface API__HeaderUserActionViewModel {
    title: string;
    url: string;
    iconId: string;
}

export interface API__HoverData {
    titleSVG: string;
    titleSVGWidth: string;
    titleSVGMarginLeft: string;
    lineColor: string;
    bg: string;
    title: string;
    subTitle: string;
    description: string;
}

export interface API__InsightsWrapper {
    competitors: API__CompetitorsTableRootObject;
    marketAreas: API__MarketAreasRootObject;
    residentPopulation: API__ResidentPopulationRootObject;
    locations: API__LocationsRootObject;
}

export interface API__InstantChartAnalyticsValues {
    currentYear: number;
    oneYearTrend: number;
    fourYearTrend: number;
}

export interface API__InstantChartBaseSizes {
    penetration: number;
    incidence: number;
}

export interface API__InstantChartDetailsModel {
    id: number;
    title: string;
    description: string;
    flavorPermissionLevel: API__PermissionLevelEnum;
    baseSizes: API__InstantChartBaseSizes;
    wordData: API__ChainPermissionWrapper<API__InstantChartWordData[]>;
}

enum UNUSED_ENUM_25496 {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

export interface API__InstantChartListItem {
    id: number;
    fullName: string;
}

export interface API__InstantChartPermission {
    id: number;
    childReportId: number;
    company: API__Company;
    companyId: number;
    endDateTime: string;
    isDeleted: boolean;
}

export interface API__InstantChartWordData {
    name: string;
    penetrations: API__InstantChartAnalyticsValues;
    flavorData: API__FlavorByoWordModel;
}

export interface API__InstantChartsListModel {
    data: API__InstantChartListItem[];
    mtPermissionLevel: API__PermissionLevelEnum;
}

enum UNUSED_ENUM_26121 {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

export interface API__JsonpResult {
    contentEncoding: API__Encoding;
    contentType: string;
    data: object;
    jsonCallback: string;
}

export interface API__KeyAttributesDataViewModel {
    airport: boolean;
    mall: boolean;
    lodging: boolean;
    collegeHospital: boolean;
    hasBreakfast: boolean;
    hasLunch: boolean;
    hasDinner: boolean;
    maxSquareFootage: number;
    minSquareFootage: number;
    franchisedPercent: number;
    primaryDistributor: string;
    pos: string;
    priceLevel: number;
    deliverySystem: string;
    averageRating: number;
    alcohol: API__KeyAttributesDataViewModelAlcoholEnum;
    foundedYear: number;
    holdingCompany: string;
    holdingCompanyWebsite: string;
    priceLevelString: string;
}

export enum API__KeyAttributesDataViewModelAlcoholEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_MINUS_1 = -1
}

export interface API__KeyValue {
    key: string;
    value: string;
}

export interface API__KeyValuePairStringDouble {
    key: string;
    value: number;
}

export interface API__LocalProductIndexArrayViewModel {
    termId: number;
    link: string;
    state: API__LocalProductItemViewModel[];
    metro: API__LocalProductItemViewModel[];
}

export interface API__LocalProductItemViewModel {
    key: string;
    value: number;
}

export interface API__LocationsRootObject {
    urbanCount: number;
    urbanPercent: number;
    suburbanCount: number;
    suburbanPercent: number;
    ruralCount: number;
    ruralPercent: number;
}

export interface API__LtoLaunchesViewModel {
    chart: API__SRChart[];
    list: API__SRListItem[];
}

export interface API__LtoModel {
    indexes: API__KeyValue[];
    id: number;
    name: string;
    description: string;
    price: string;
    isScoresItem: boolean;
    date: string;
    categoryFeatureId: number;
    isPublished: boolean;
    hasImage: boolean;
    imageUrl: string;
}

export interface API__LtoViewModel {
    launches: API__ChainPermissionWrapper<API__LtoLaunchesViewModel>;
    ltos: API__ChainPermissionWrapper<API__SRLtoWithScoresDataViewModel[]>;
}

export interface API__MacAndInstantChartViewModel {
    mac: Top2ViewModel;
    instantChart: Top2ViewModel;
}

export interface API__MacOrInstantChartViewModel {
    id: number;
    name: string;
    link: string;
}

export interface API__MacsUserTemplate {
    macs: number[];
    userId: string;
}

export interface API__MailModel {
    senderName: string;
    subject: string;
    body: string;
    viewName: string;
    to: string[];
    cc: string[];
    bcc: string[];
    addTeamLeadEmail: boolean;
    addPartnerEmail: boolean;
    includeShareLinksBccEmails: boolean;
}

export interface API__MarketAreasRootObject {
    stateMarketAreas: API__StateMarketArea[];
    metroMarketAreas: API__MetroMarketArea[];
}

export interface API__MenuDataModel {
    masterHeading: string;
    heading: string;
    menuPart: string;
    item: string;
    itemDescription: string;
    itemPrice: number;
    photoUrl: string;
}

export interface API__MenuItem {
    id: number;
    title: string;
    description: string;
    menuItemTypeId: number;
    score: number;
}

export interface API__MenuItemsVIewModel {
    menuDetails: API__ChainPermissionWrapper<API__ChainMenuItem>;
    menuExamples: API__ChainMenuItem[];
}

export interface API__MetroMarketArea {
    region: string;
    number_of_stores: object;
    percent_of_all_stores: number;
    density: number;
}

export interface API__ModelFile {
    id: number;
    name: string;
    url: string;
    extension: string;
    date: string;
    size: number;
    fileName: string;
    documentType: number;
    amazonLink: string;
    thumnailExist: boolean;
}

export interface API__NavigationItemsViewModel {
    url: string;
    title: string;
    hasAccess: boolean;
    appId: string;
}

export interface API__NewAllMenuTrendsPenetrationViewModel {
    totalUsPenetration: number;
    qsr: number;
    midscale: number;
    casual: number;
    fineDining: number;
    totalTrend: number;
    fastCasual: number;
    isDbWord: boolean;
    foodProfileActive: boolean;
    wordId: number;
    encryptedReportId: string;
    wordName: string;
    encryptedReportName: string;
    filterDescription: string;
    menuVersatility: number;
    foodVersatility: number;
    commonRestaurantType: string;
    oneYearTrend: number;
    fourYearTrend: number;
    yearsPenetrations: API__KeyValuePairStringDouble[];
    quartersPenetrations: API__KeyValuePairStringDouble[];
    predictedData: API__ChainPermissionWrapper<API__PredictedHaikuDataViewModel>;
}

export interface API__NewMenuTrendsPenetrationViewModel {
    oneYearTrend: number;
    fourYearTrend: number;
    yearsPenetrations: API__KeyValuePairStringDouble[];
    quartersPenetrations: API__KeyValuePairStringDouble[];
    predictedData: API__ChainPermissionWrapper<API__PredictedHaikuDataViewModel>;
}

export interface API__NewPenetrationItemsViewModel {
    casualDining: API__NewMenuTrendsPenetrationViewModel;
    qsr: API__NewMenuTrendsPenetrationViewModel;
    all: API__NewAllMenuTrendsPenetrationViewModel;
    midscale: API__NewMenuTrendsPenetrationViewModel;
    fineDining: API__NewMenuTrendsPenetrationViewModel;
    fastCasual: API__NewMenuTrendsPenetrationViewModel;
}

export interface API__NewPenetrationRootObjectViewModel {
    penetrationItems: API__NewPenetrationItemsViewModel;
    pairedFlavorsMostCommon: API__PenetrationPairedFlavorsMostCommonViewModel[];
    pairedFlavorsFastesGrowing: API__PenetrationPairedFlavorsFastesGrowingViewModel[];
    suggestedMacWords: API__PenetrationSuggestedMacWordViewModel[];
    macAndInstantChart: API__MacAndInstantChartViewModel;
    categoryId: number;
    categoryName: string;
    baseSize: number;
    foodETerms: string;
    snapshotTextBullets: string[];
    macStage: string;
}

export interface API__NewsModel {
    title: string;
    description: string;
    source: string;
    imageUrl: string;
    publishDate: string;
}

export interface API__NormsViewModel {
    wholeUs: number;
    male: number;
    female: number;
    genZ: number;
    millennials: number;
    genX: number;
    boomers: number;
    white: number;
    black: number;
    hispanic: number;
    asian: number;
    west: number;
    midwest: number;
    northeast: number;
    south: number;
    withKids: number;
    withoutKids: number;
    foodies: number;
}

export interface API__Notification {
    id: number;
    alias: string;
}

export interface API__OfferingChain {
    data: API__ChainMenuAndLtosViewModel[];
}

export interface API__PackageItemDocumentViewModel {
    itemId: string;
    type: string;
    displayType: string;
}

export interface API__PackageItemViewModel {
    id: number;
    key: string;
    type: string;
    name: string;
    format: string;
    size: number;
    downloadLink: string;
    shareLink: string;
}

export interface API__PackageItemsViewModel {
    packageId: number;
    itemDocuments: API__PackageItemDocumentViewModel[];
    name: string;
}

export interface API__PenetrationPairedFlavorsFastesGrowingViewModel {
    id: number;
    name: string;
    value: string;
}

export interface API__PenetrationPairedFlavorsMostCommonViewModel {
    id: number;
    name: string;
    value: string;
}

export interface API__PenetrationSuggestedMacWordViewModel {
    macStageId: number;
    macStageName: string;
    words: API__PenetrationWordViewModel[];
}

export interface API__PenetrationWordViewModel {
    id: number;
    name: string;
    macStage: number;
}

export interface API__Place {
    id: number;
    title: string;
    segment: string;
    cuisine: string;
    metroArea: string;
    numberOfUnits: number;
    logo: string;
    address: string;
    zip: string;
    city: string;
    state: string;
}

export interface API__PlaceWithMenuItemsViewModel {
    menuItems: API__MenuItem[];
    id: number;
    chainName: string;
    parentCompanyName: string;
    metroArea: string;
    numberOfUnits: number;
    segment: string;
    menuType: string;
    stateFullName: string;
    unitsGrowth: number;
    alcohol: number;
}

export interface API__PredictedHaikuData {
    yearsPredictedPenetrations: API__KeyValuePairStringDouble[];
    quartersPredictedPenetrations: API__KeyValuePairStringDouble[];
}

export interface API__PredictedHaikuDataViewModel {
    yearsPredictedPenetrations: API__KeyValuePairStringDouble[];
    quartersPredictedPenetrations: API__KeyValuePairStringDouble[];
}

export interface API__Product {
    id: number;
    name: string;
    description: string;
    url: string;
    ssoName: string;
    appId: string;
    price: number;
    category: API__ProductCategoryEnum;
    isActive: boolean;
    isDeleted: boolean;
    showInSidebarMenu: boolean;
    productFeatures: API__Feature[];
    orderedFeatures: number[];
    hasAccess: boolean;
    order: number;
    productGroup: API__ProductGroup;
}

export enum API__ProductCategoryEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

export interface API__ProductAdmin {
    id: number;
    isDeleted: boolean;
    snapUserId: string;
    productId: number;
    product: API__Product;
}

export interface API__ProductGroup {
    id: number;
    name: string;
    order: number;
    isDeleted: boolean;
    products: API__Product[];
}

export interface API__ProfileLtoViewModel {
    ltos: API__ChainPermissionWrapper<API__SRLtoWithScoresDataViewModel[]>;
    summary: API__ChainMenuItemsData[];
    itemsCount: number;
}

export interface API__ProfileMainViewModel {
    profileData: API__ChainPermissionWrapper<API__ProfileViewModel>;
    inTop1000: boolean;
}

export interface API__ProfileViewModel {
    companyData: API__CompanyDataViewModel;
    keyAttributesData: API__ChainPermissionWrapper<API__KeyAttributesDataViewModel>;
    sectionData: API__SectionDataViewModel;
    reportsData: Array<API__ChainPermissionWrapper<API__ChainReportViewModel>>;
    notes: API__ChainPermissionWrapper<API__ChainNoteViewModel[]>;
}

export interface API__Project {
    id: string;
    name: string;
    description: string;
    sphinxId: number;
    company: API__Company;
    companyId: number;
    displayForCompanyId: number;
    isDeleted: boolean;
    parentProjectId: string;
    overridedByUserGuid: string;
    documents: API__Document[];
    createdByUserGuid: string;
    createdByUser: API__SnapUser;
    createdDate: string;
    isChecked: boolean;
    canEdit: boolean;
    totalDocumentSize: number;
    isInternal: boolean;
    isShared: boolean;
    isModified: boolean;
    initialName: string;
    sharedWithCount: number;
    internalDocumentsCount: number;
    sharedDocumentsCount: number;
    internalDocumentFilesSize: number;
    sharedDocumentFilesSize: number;
    canOverride: boolean;
    sharedCompaniesIds: number[];
}

export interface API__PublicationCategory {
    id: number;
    name: string;
    publicationFamilies: API__PublicationFamily[];
}

export interface API__PublicationFamily {
    id: number;
    name: string;
    publicationCategories: API__PublicationCategory[];
    boost: number;
}

export interface API__PurchaseModel {
    id: number;
    user: API__SnapUser;
    userId: string;
    company: API__Company;
    companyId: number;
    userIp: string;
    userAgent: string;
    requestDateTime: string;
    itemName: string;
    itemId: number;
    purchaseType: API__PurchaseModelPurchaseTypeEnum;
    itemPrice: string;
    chainName: string;
    chainId: number;
    publicationFamilyId: string;
    isFromPenetration: boolean;
    deviceType: API__PurchaseModelDeviceTypeEnum;
}

export enum API__PurchaseModelPurchaseTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8,
    NUMBER_9 = 9,
    NUMBER_10 = 10,
    NUMBER_11 = 11,
    NUMBER_12 = 12,
    NUMBER_13 = 13,
    NUMBER_14 = 14,
    NUMBER_15 = 15,
    NUMBER_16 = 16,
    NUMBER_17 = 17,
    NUMBER_18 = 18,
    NUMBER_19 = 19,
    NUMBER_20 = 20,
    NUMBER_21 = 21
}

export enum API__PurchaseModelDeviceTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

export interface API__RegistrationModel {
    email: string;
    firstName: string;
    lastName: string;
    department: number;
    phoneNumber: string;
    password: string;
    confirmPassword: string;
}

export interface API__ReportProFile {
    filename: string;
    type: string;
}

export interface API__ReportProPublicationClient {
    bigImageUrl: string;
    featuredContentImageUrl: string;
    price: number;
    createDate: string;
    buttonCategoryId: number;
    titleCategoryId: number;
    chapters: API__ReportProPublicationClient[];
    publicationId: number;
    pageFrom: number;
    pageTo: number;
    hasChapters: boolean;
    publicationTitle: string;
    score: number;
    isPublished: boolean;
    id: number;
    title: string;
    description: string;
    imageUrl: string;
    tags: API__ReportProTag[];
    numberOfPages: number;
    minutesToRead: number;
    isLiked: boolean;
    isShared: boolean;
    isAddedToReadingList: boolean;
    hasAccess: boolean;
    publicationType: API__ReportProPublicationClientPublicationTypeEnum;
    files: API__ReportProFile[];
    addedToReadingListDate: string;
    shareOnDate: string;
    createDateToOrder: string;
}

export enum API__ReportProPublicationClientPublicationTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export interface API__ReportProPublicationClientWrapper {
    data: API__ReportProPublicationClient[];
}

export interface API__ReportProTag {
    id: number;
    value: string;
}

export interface API__ReportsViewModel {
    publicationFamilyIds: number[];
}

export interface API__ResidentPopulationBirthPlace {
    name: string;
    percent: string;
    index: number;
}

export interface API__ResidentPopulationEthnicity {
    name: string;
    percent: string;
    index: number;
}

export interface API__ResidentPopulationGender {
    name: string;
    percent: string;
    index: number;
}

export interface API__ResidentPopulationGeneration {
    name: string;
    percent: number;
    index: number;
}

export interface API__ResidentPopulationLanguage {
    name: string;
    percent: string;
    index: number;
}

export interface API__ResidentPopulationRootObject {
    population: string;
    statistic: API__ResidentPopulationStatistic;
}

export interface API__ResidentPopulationStatistic {
    gender: API__ResidentPopulationGender[];
    ethnicity: API__ResidentPopulationEthnicity[];
    languages: API__ResidentPopulationLanguage[];
    generations: API__ResidentPopulationGeneration[];
    birthPlace: API__ResidentPopulationBirthPlace[];
}

export interface API__RootUserInfoViewModel {
    anyMacExist: boolean;
    navigationItems: API__NavigationItemsViewModel[];
    sidebarMenuItems: API__SidebarProductViewModel[];
    headerNavigationItems: API__HeaderItemViewModel[];
    headerUserActionsMenuItems: API__HeaderUserActionViewModel[];
    featureFlags: API__FeatureFlagViewModel[];
    headerDocumentsMenuItems: API__HeaderDocumentsMenuItemsViewModel;
    userInitials: string;
    homeUrl: string;
    userName: string;
    userId: string;
    dashboardImageSrc: string;
    dashboardImagesSrc: string[];
    daysToExpire: number;
    confirmationPeriod: number;
    ads: string[];
    searchType: number;
    currentUsercompanyId: number;
    currentUserEmail: string;
    currentUserSessionToken: string;
    currentUsercompanyName: string;
    hasVideoFeature: boolean;
    hasMtdFeature: boolean;
    mtdEndDate: string;
    hasHaikuPreviewFeature: boolean;
    haikuPreviewEndDate: string;
    hasHaikuFeature: boolean;
    companyTeamLeadEmail: string;
    companyTeamLeadFullName: string;
    companyPartnerEmail: string;
    companyPartnerFullName: string;
    companyName: string;
    agencyModeCompanies: object[];
    originalData: object;
    hasImpersonateAccess: object;
    hasAccessFromMobileDevice: boolean;
    mtdApiUrl: string;
    reportsProUrl: string;
    helpUrl: string;
    hasFireflyFeature: boolean;
    hasSnapFeaturesProduct: boolean;
    hasSnapFeaturesFfChains: boolean;
    hasSnapFeaturesFfAnalytics: boolean;
    hasSnapFeaturesFfGrandfather: boolean;
    hasSnapLightChainProfile: boolean;
}

export interface API__SRChart {
    year: number;
    quarters: API__SRQuarterYear[];
}

export interface API__SRItemScore {
    indexType: API__SRItemScoreIndexTypeEnum;
    key: string;
    value: number;
    percentile: number;
    starsCount: number;
}

export enum API__SRItemScoreIndexTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6
}

export interface API__SRListItem {
    name: string;
    value: number;
}

export interface API__SRLtoSubscriptionViewModel {
    id: number;
    downloadPptLink: string;
    score: number;
    viability: string;
    indexes: API__SRItemScore[];
}

export interface API__SRLtoWithScoresDataViewModel {
    scoresData: API__ChainPermissionWrapper<API__SRLtoSubscriptionViewModel>;
    id: number;
    name: string;
    description: string;
    chainName: string;
    date: string;
    imageUrl: string;
    isScoresItem: boolean;
    price: string;
    link: string;
    hasAccess: boolean;
}

export interface API__SRQuarterYear {
    season: string;
    value: number;
}

export interface API__SaveChainNoteViewModel {
    chainId: number;
    data: API__BaseChainNoteViewModel;
}

export interface API__ScoresItemPermission {
    id: number;
    scoresItemId: number;
    company: API__Company;
    companyId: number;
    endDateTime: string;
    isDeleted: boolean;
}

export interface API__Section {
    id: number;
    isDeleted: boolean;
    name: string;
    url: string;
    editRight: API__SectionEditRightEnum;
}

export enum API__SectionEditRightEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export interface API__SectionDataViewModel {
    hasMenu: boolean;
    hasLto: boolean;
    hasLocation: boolean;
    hasContacts: boolean;
    hasBrand: boolean;
    hasSocials: boolean;
    isMtd: boolean;
}

export interface API__SidebarProductViewModel {
    title: string;
    iconName: string;
    url: string;
    disabled: boolean;
    hoverData: API__HoverData;
    order: number;
    type: string;
    action: string;
}

export interface API__SnapUser {
    id: string;
    password: string;
    oldPassword: string;
    oldUserId: number;
    isActiveUser: boolean;
    isDeleted: boolean;
    dateDeleted: string;
    deletedById: string;
    isApproved: boolean;
    hasEnabledFrontDoor: boolean;
    isPermissionsEqualWithCompanyPerimssions: boolean;
    notifications: API__UserNotificationState[];
    userGroups: API__UserGroup[];
    documents: API__Document[];
    answersByVideos: API__UserAnswerByVideo[];
    userActivities: API__UserActivity[];
    userContacts: API__UserContact[];
    advertisement: API__UserAdvertisement[];
    accessRequests: API__UserAccessRequest[];
    confirmDate: string;
    reconfirmationUniqueCode: string;
    createdByUserId: string;
    createdByUser: API__SnapUser;
    passwordExpireDate: string;
    loginTime: string;
    impersonatedData: string;
    userRole: number;
    expiredDate: string;
    hasImpersonated: boolean;
    availableSections: API__UserSectionAccess[];
    productAdminAccessList: API__ProductAdmin[];
    isAdminRegistered: boolean;
    foodWatchId: number;
    foodWatch: API__FoodWatch;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    phone: string;
    sessionToken: string;
    departmentId: number;
    company: API__Company;
    companyId: number;
    selfRegistrationDate: string;
    adminRegistrationDate: string;
    permissions: API__UserPermission[];
    userSessions: API__UserSession[];
    userExternalPermissions: API__UserExternalPermission[];
    userName: string;
    emailConfirmed: boolean;
    passwordHash: string;
    securityStamp: string;
    role: API__UserRole;
}

export interface API__SocialBlockViewModel {
    youTubeVideos: API__YouTubeVideoViewModel[];
    tweets: string[];
}

export interface API__SocialLinksViewModel {
    links: { [key: string]: string; };
}

export interface API__StateMarketArea {
    region: string;
    number_of_stores: string;
    percent_of_all_stores: number;
    density: number;
}

export interface API__SubscriptionRequestModel {
    featureId: number;
    userId: string;
    toolLocation: string;
    customProductName: string;
    mail: API__MailModel;
}

export interface API__SubscriptionResponseModel {
    success: boolean;
    downloadLink: string;
}

export interface Top2ViewModel {
    itemsCount: number;
    items: API__MacOrInstantChartViewModel[];
    link: string;
}

export interface API__TopChainNames {
    chainId: number;
    chainName: string;
}

export interface API__TopChainWrapperViewModel {
    places: API__Place[];
    filter: API__TopChainsShortViewModel;
}

export interface API__TopChainsShortViewModel {
    metros: string[];
    segments: string[];
    regions: string[];
    menuTypes: string[];
    skip: number;
    take: number;
    minUnits: number;
    maxUnits: number;
}

export interface API__TrendSpotting {
    id: number;
    title: string;
    description: string;
    issueDate: string;
    pagesCount: number;
    publicationFamilyId: number;
    featureId: number;
    publicationFamily: API__PublicationFamily;
    feature: API__Feature;
    isDeleted: boolean;
    fileId: number;
    trendspottingPptFileId: number;
    sphinxId: number;
    file: any;
    trendspottingPptFile: any;
    articles: API__TrendSpottingArticle[];
    alias: string;
}

export interface API__TrendSpottingArticle {
    id: number;
    title: string;
    description: string;
    fileExtention: string;
    pageFrom: number;
    pageTo: number;
    issueDate: string;
    searchPriorityId: number;
    trendSpottingId: number;
    searchPriority: API__ArticleSearchPriority;
    trendSpotting: API__TrendSpotting;
    isDeleted: boolean;
    fileId: number;
    sphinxId: number;
    file: any;
    pagesCount: number;
}

export interface API__UpdateUserDataViewModel {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phone: string;
    userSections: API__UserSectionViewModel[];
    isActive: boolean;
    isConfirmed: boolean;
    isApproved: boolean;
    departmentId: number;
    userRoleId: number;
}

export interface API__UpdateUsersDataViewModel {
    userIds: string[];
    isActive: boolean;
    isConfirmed: boolean;
    isApproved: boolean;
    departmentId: number;
    userRoleId: number;
}

export interface API__UserAccessRequest {
    id: number;
    userId: string;
    user: API__SnapUser;
    featureId: number;
    feature: API__Feature;
    requestDate: string;
    toolLocation: string;
}

export interface API__UserActivity {
    id: number;
    userId: string;
    user: API__SnapUser;
    companyId: number;
    company: API__Company;
    ip: string;
    browser: string;
    dateTime: string;
    type: API__UserActivityTypeEnum;
    state: string;
    city: string;
    country: string;
}

export enum API__UserActivityTypeEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3
}

export interface API__UserAdvertisement {
    id: number;
    displays: number;
    lastShowDate: string;
    userId: string;
    user: API__SnapUser;
    advertisementId: number;
    advertisement: API__Advertisement;
    isActive: boolean;
}

export interface API__UserAnswerByVideo {
    id: number;
    snapUser: API__SnapUser;
    snapUserId: string;
    video: API__VideoFile;
    videoId: number;
    helpfull: boolean;
    comment: string;
    postDate: string;
    isDeleted: boolean;
}

export interface API__UserAuthenticationModel {
    username: string;
    password: string;
}

export interface API__UserContact {
    id: number;
    userId: string;
    user: API__SnapUser;
    companyId: number;
    company: API__Company;
    chainId: number;
    name: string;
    title: string;
    email: string;
    phone: string;
    linkedInProfile: string;
    linkedInProfilePhoto: string;
    department: string;
    isPublic: boolean;
    createdOnDate: string;
}

export interface API__UserContactViewModel {
    id: number;
    chainId: string;
    name: string;
    title: string;
    email: string;
    phone: string;
    linkedInProfile: string;
    linkedInProfilePhoto: string;
    department: string;
    date: string;
    isCustom: boolean;
    isPublic: boolean;
    canEdit: boolean;
}

export interface API__UserExternalPermission {
    snapUserId: string;
    snapUser: API__SnapUser;
    id: number;
    externalDocumentId: number;
}

export interface API__UserGroup {
    id: number;
    name: string;
    companyId: number;
    company: API__Company;
    users: API__SnapUser[];
    documents: API__Document[];
    editDocuments: API__Document[];
    emails: API__UserGroupEmail[];
    isDeleted: boolean;
}

export interface API__UserGroupEmail {
    id: number;
    userGroupId: number;
    userGroup: API__UserGroup;
    email: string;
    isDeleted: boolean;
}

export interface API__UserModel {
    id: string;
    oldUserId: number;
    userName: string;
    firstName: string;
    lastName: string;
    company: API__CompanyModel;
    email: string;
    companyId: number;
    departmentId: number;
    permissions: number[];
    includedPermissions: number[];
    excludedPermissions: number[];
    role: number;
    sessionToken: string;
    isExpire: boolean;
    isActive: boolean;
    isApproved: boolean;
    isEmailConfirmed: boolean;
    isActiveCompany: boolean;
    fingerchainsPermissions: number[];
    clientCompanyId: number;
}

export interface API__UserNoteViewModel {
    id: number;
    chainId: number;
    chainName: string;
    state: string;
    note: string;
    isPublic: boolean;
    date: string;
    author: string;
}

export interface API__UserNotificationState {
    id: number;
    userId: string;
    user: API__SnapUser;
    notificationId: number;
    notification: API__Notification;
    isActive: boolean;
}

export interface API__UserPermission {
    snapUserId: string;
    id: number;
    startDate: string;
    endDate: string;
    featureId: number;
    feature: API__Feature;
    isPurchase: boolean;
}

export interface API__UserRole {
    id: number;
    name: string;
    users: API__SnapUser[];
}

export interface API__UserSectionAccess {
    id: number;
    isDeleted: boolean;
    editRight: API__UserSectionAccessEditRightEnum;
    snapUser: API__SnapUser;
    snapUserId: string;
    section: API__Section;
    sectionId: number;
}

export enum API__UserSectionAccessEditRightEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export interface API__UserSectionViewModel {
    editRight: API__UserSectionViewModelEditRightEnum;
    id: number;
}

export enum API__UserSectionViewModelEditRightEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export interface API__UserSession {
    id: number;
    ipAddress: string;
    macAddress: string;
    lastLoginTime: string;
    logouTime: string;
    sessionToken: string;
    snapUserId: string;
    isActive: boolean;
    isLoggedOut: boolean;
    lastActivtyTime: string;
    ativityCount: number;
}

export interface API__VideoFile {
    id: number;
    title: string;
    description: string;
    fileMpf: any;
    fileMpfId: number;
    isDeleted: boolean;
    feature: API__Feature;
    usersAnswersByVideo: API__UserAnswerByVideo[];
    isAlreadyWatched: boolean;
}

export interface API__YouTubeVideo {
    videoId: string;
    title: string;
    description: string;
    thumbnailUrl: string;
}

export interface API__YouTubeVideoViewModel {
    videoId: string;
    title: string;
    description: string;
    thumbnailUrl: string;
}
