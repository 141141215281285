import { FC } from "react";
import { IconFileOther } from "@icons";
import DocumentActions from "@components/new/DocumentActions/DocumentActions";
import { FileExtention } from "@components/new/FileExtention/FileExtention";
import getFileSize from "@core/getFileSize";
import { iDocument } from "@models/Documents/Documents";
import { getEnding, getFormattedDate } from "@helpers";
import { getFileType } from "../Documents.helpers";
import "./DocumentItem.styles.scss";

interface iProps {
    data: iDocument;
}

const DocumentItem: FC<iProps> = ({ data }) => {
    const {
        id,
        imageUrl,
        fileExtention = '',
        title,
        project,
        date,
        size,
        pages,
        copyUrl,
        downloadUrl,
        docType,
    } = data;

    const fileType = getFileType(fileExtention);
    const fileSize = getFileSize(parseInt(size));


    return (
        <div className="DocumentItem">
            <div className="DocumentItem__preview">
                {imageUrl && (
                    <img
                        className="DocumentItem__image"
                        src={imageUrl}
                        alt={title}
                    />
                )}
                {!imageUrl && (
                    <IconFileOther className="DocumentItem__image DocumentItem__image--blank" />
                )}
            </div>
            <div className="DocumentItem__details">
                <div className="DocumentItem__group">
                    <div className="DocumentItem__ext">
                        <FileExtention type={fileType} />
                    </div>
                    <div className="DocumentItem__name">{title}</div>
                </div>

                {project && (
                    <div className="DocumentItem__project">{project}</div>
                )}

                <div className="DocumentItem__group DocumentItem__group--bottom">
                    {date && <div className="DocumentItem__date">{getFormattedDate(`${date}`, "MMMM YYYY")}</div>}
                    {size && <div className="DocumentItem__size">{Math.round(fileSize.value)} {fileSize.units}</div>}
                    {pages > 0 && <div className="DocumentItem__pages">{pages} {getEnding('page', pages)}</div>  }
                </div>
            </div>

            <div className="DocumentItem__actions">
                <DocumentActions
                    copyUrl={copyUrl}
                    downloadUrl={downloadUrl}
                    packageData={{ id, docType }}
                    shareData={{ id, title, shareLinkString: copyUrl }}
                />
            </div>
        </div>
    );
};

export default DocumentItem;
