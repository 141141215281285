import Loader from "../Loader/Loader"

interface iProps {
    isLoading?: boolean;
    rowsCount: number;
    hasError?: boolean;
}

const TableBodyWrapper: React.FC<iProps> = ({ children, isLoading = false, rowsCount, hasError }) => {
    return (
        <div className="TableNew__body-wrapper">
            {!isLoading && rowsCount > 0 && children}

            {isLoading && (
                <div className="TableNew__loader">
                    <Loader className="is-no-bg" />
                </div>
            )}

            {rowsCount === 0 && !isLoading && <div className="TableNew__no-data">No data...</div>}
        </div>
    );
};

export default TableBodyWrapper;