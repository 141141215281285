import { equalInLC, getUnique, prepareForSorting } from "../helpers";
import { Alcohol, segmentsConfig } from "./ChainBase";
import { Place } from "./Place";

export const DEFAULT_FILTER_TITLE = "-";
export const MENU_TYPE_DEFAULT_FILTER_TITLE = "Without Menu";

export interface iChainsFilters {
    maxUnits: number;
    minUnits: number;
    menuTypes: string[];
    metros: string[];
    states: string[];
    segments: string[];
    alcohol: Alcohol[];
}

interface iChainFiltersData {
    chainsList?: Place[];
    subModel?: Partial<iChainsFilters>;
}

export class ChainsFilters implements iChainsFilters {
    static defaultData: iChainsFilters = {
        minUnits: 2,
        maxUnits: 2,
        menuTypes: [],
        metros: [],
        states: [],
        segments: [],
        alcohol: [],
    };

    minUnits = ChainsFilters.defaultData.minUnits;
    maxUnits = ChainsFilters.defaultData.maxUnits;
    menuTypes = ChainsFilters.defaultData.menuTypes;
    metros = ChainsFilters.defaultData.metros;
    states = ChainsFilters.defaultData.states;
    segments = ChainsFilters.defaultData.segments;
    alcohol = ChainsFilters.defaultData.alcohol;

    constructor(data?: iChainFiltersData) {
        if (data) {
            if (data.chainsList) {
                this.getFromList(data.chainsList);
            }
            if (data.subModel) {
                this.setPartial(data.subModel);
            }
        }
    }

    private setData(model: iChainsFilters) {
        ({
            minUnits: this.minUnits,
            maxUnits: this.maxUnits,
            menuTypes: this.menuTypes,
            metros: this.metros,
            states: this.states,
            segments: this.segments,
            alcohol: this.alcohol,
        } = model);
    }

    private setPartial(model: Partial<iChainsFilters>) {
        this.setData({
            minUnits: model.minUnits ?? this.minUnits,
            maxUnits: model.maxUnits ?? this.maxUnits,
            menuTypes: model.menuTypes ?? this.menuTypes,
            metros: model.metros ?? this.metros,
            states: model.states ?? this.states,
            segments: model.segments ?? this.segments,
            alcohol: model.alcohol ?? this.alcohol,
        })
    }

    static sortOptions (a: string, b: string) {
        const aValue = prepareForSorting(a);
        const bValue = prepareForSorting(b);
        if (aValue === DEFAULT_FILTER_TITLE) return 1;
        if (bValue === DEFAULT_FILTER_TITLE) return -1;
        return aValue < bValue ? -1 : 1;
    }

    static sortSegmentsOptions (a: string, b: string) {
        if (a === DEFAULT_FILTER_TITLE) return 1;
        if (b === DEFAULT_FILTER_TITLE) return -1;
        let aScore = 0;
        let bScore = 0;
        segmentsConfig.forEach((segmentGroup, sgIndex) => {
            const aItemIndex = segmentGroup.items.findIndex(i => equalInLC(i, a));
            const bItemIndex = segmentGroup.items.findIndex(i => equalInLC(i, b));
            if (aItemIndex >= 0) aScore = sgIndex * 100000 + aItemIndex;
            if (bItemIndex >= 0) bScore = sgIndex * 100000 + bItemIndex;
        });

        return aScore <= bScore ? -1 : 1;
    }

    static sortMenuOptions (a: string, b: string) {
        const aValue = prepareForSorting(a);
        const bValue = prepareForSorting(b);
        if (aValue === MENU_TYPE_DEFAULT_FILTER_TITLE) return 1;
        if (bValue === MENU_TYPE_DEFAULT_FILTER_TITLE) return -1;
        return aValue < bValue ? -1 : 1;
    }

    static sortAlcoholOptions (a: Alcohol, b: Alcohol) {
        const aValue = prepareForSorting(a);
        const bValue = prepareForSorting(b);
        // if (aValue === Alcohol.NoData) return 1;
        // if (bValue === Alcohol.NoData) return -1;
        return aValue < bValue ? -1 : 1;
    }

    getFromList(places: Place[]) {
        const menuTypes = getUnique(places.map(i => i.menuType)).map(i => i || MENU_TYPE_DEFAULT_FILTER_TITLE);
        const metros = getUnique(places.map(i => i.metroArea)).map(i => i || DEFAULT_FILTER_TITLE);
        const states = getUnique(places.map(i => i.stateFullName)).map(i => i || DEFAULT_FILTER_TITLE);
        const segments = getUnique(places.map(i => i.segment)).map(i => i || DEFAULT_FILTER_TITLE);
        /*
         we decided to exclude NoData value from alcohol filter
         chains with this value should appear only if all other alcohol options are selected
         this is implemented in filter function of NewTopChains component
         */
        const alcohol = getUnique(places.map(i => i.alcohol).filter(a => a !== Alcohol.NoData));

        menuTypes.sort(ChainsFilters.sortMenuOptions);
        metros.sort(ChainsFilters.sortOptions);
        states.sort(ChainsFilters.sortOptions);
        segments.sort(ChainsFilters.sortSegmentsOptions);
        alcohol.sort(ChainsFilters.sortAlcoholOptions);

        const maxUnits = places
            .map(i => i.numberOfUnits)
            .reduce((max, curr) => max > curr ? max : curr, 0);


        this.setData({
            states,
            segments,
            metros,
            menuTypes,
            minUnits: ChainsFilters.defaultData.minUnits,
            maxUnits,
            alcohol,
        });
    }
}