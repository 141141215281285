import { ChainsListItem__API } from "./api/ChainsListItem";

export interface iChainsListItem {
    id: number;
    cuisine: string;
    name: string;
    segment: string;
    units: number;
}

interface iData {
    apiModel: ChainsListItem__API;
}

export class ChainsListItem implements iChainsListItem {
    static defaultData: iChainsListItem = {
        id: 0,
        cuisine: "",
        name: "",
        segment: "",
        units: 0
    }

    id = ChainsListItem.defaultData.id;
    cuisine = ChainsListItem.defaultData.cuisine;
    name = ChainsListItem.defaultData.name;
    segment = ChainsListItem.defaultData.segment;
    units = ChainsListItem.defaultData.units;

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private setData (model: iChainsListItem) {
        ({
            id: this.id,
            cuisine: this.cuisine,
            name: this.name,
            segment: this.segment,
            units: this.units
        } = model);
    }

    private mapFromApi(data: ChainsListItem__API) {
        const {
            Id = ChainsListItem.defaultData.id,
            Cuisine = ChainsListItem.defaultData.cuisine,
            Name = ChainsListItem.defaultData.name,
            Segment = ChainsListItem.defaultData.segment,
            Units = ChainsListItem.defaultData.units,
            ...rest
        } = data;

        this.setData({
            ...rest,
            id: Id,
            segment: Segment,
            cuisine: Cuisine,
            name: Name,
            units: Units
        });
    }
}