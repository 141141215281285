import React, { FC, useContext, useMemo, useState } from "react";
import Button, { ButtonTypeEnum } from "../../../../components/new/Button/Button";
import SectionHeader from "../../../../components/new/SectionHeader/SectionHeader";
import { IconTwitterNew, IconYoutubeNew } from "../../../../icons";
import useSocialLinks from "../../../../core/apiHooks/useSocialLinks";
import ItemYoutube from "./ItemYoutube/ItemYoutube";
import "./Socials.styles.scss";
import Modal from "../../../../components/Modal/Modal";
import { ChainDetailsDataContext } from "../../ChainPage.helpers";
import SectionContent from "../../../../components/new/SectionContent/SetionContent";
import { splitColumns } from "../../../../core/helpers";
import useWindowSizeDebounced from "../../../../core/hooks/useWindowSizeDebounced";
import { getSlidesPerView } from "../Contacts/Contacts.helpers";

const Socials: FC = () => {
    const {
        data: {
            mainData: {
                socialLinks,
            }
        },
    } = useContext(ChainDetailsDataContext);
    const { data, isLoading } = useSocialLinks(socialLinks);
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedYoutubeUrl, setSelectedYoutubeUrl] = useState<string | null>(null);
    const [wWidth] = useWindowSizeDebounced(1000);

    const TWITTER_COLUMNS = useMemo(() => getSlidesPerView(wWidth, 320), [wWidth]);

    const twitterColumnsData = useMemo(
        () => splitColumns(data.twitter, TWITTER_COLUMNS),
        [data.twitter, TWITTER_COLUMNS],
    );

    const hasData = data.twitter.length > 0 || data.youtube.length > 0;

    return (
        <div className="Socials">
            <SectionHeader title="Social"/>
            <SectionContent isLoading={isLoading} className="Socials__content">
                {data && (
                    <>
                        {data.youtube.length > 0 && (
                            <div className="Socials__section">
                                <div className="Socials__title">
                                    <IconYoutubeNew className="Socials__title-icon"/> Youtube
                                </div>
                                <div className="Socials__grid">
                                    {data.youtube.slice(0, 4).map(item => {
                                        return (
                                            <div key={item.url} className="Socials__grid-item">
                                                <ItemYoutube
                                                    data={item}
                                                    onOpen={setSelectedYoutubeUrl}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                                {socialLinks.youtube && (
                                    <div className="Socials__footer Socials__footer--no-gap">
                                        <Button
                                            type={ButtonTypeEnum.Anchor}
                                            className="Socials__footer-action"
                                            link={socialLinks.youtube}
                                        >
                                            View more
                                        </Button>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}

                <Modal
                    isOpened={!!selectedYoutubeUrl}
                    close={() => setSelectedYoutubeUrl(null)}
                    hasInner={false}
                    closeOnOutsideClick
                >
                    <div className="Socials__youtube-frame-wrapper">
                        <iframe
                            id="ytplayer"
                            className="Socials__youtube-frame"
                            title="h"
                            chain-data__tweets
                            width="100%"
                            height="100%"
                            allow="autoplay"
                            allowFullScreen
                            src={`${selectedYoutubeUrl}?autoplay=1&rel=0`}
                            frameBorder={0}
                        />
                    </div>
                </Modal>
                {!isLoading && !hasData && <div className="Socials__no-data">No current Socials updates</div>}
            </SectionContent>
        </div>
    );
};

export default React.memo(Socials);
