import { FC } from "react";
import cn from "classnames";
import "./Tabs.styles.scss";
import { ID } from "../../../core/models";
import TabThumb from "./TabThumb";

interface iProps {
    selectedId: ID;
    onChange: (id: ID) => void;
    items: {
        id: ID;
        title: string;
        testId?: string;
    }[];
    testId?: string;
}

const Tabs: FC<iProps> = (
    {
        items,
        selectedId,
        onChange,
        children,
        testId,
    }
) => {
    return (
        <div
            className="Tabs"
            data-testid={testId}
        >
            <div className="Tabs__header">
                {items.map((tab, index) => {
                    return (
                        <TabThumb
                            key={tab.id}
                            isActive={tab.id ===selectedId}
                            className="Tabs__tab"
                            onClick={() => onChange(tab.id)}
                            testId={tab.testId ?? `buttonTabsItem-${index}`}
                        >
                            {tab.title}
                        </TabThumb>
                    );
                })}
            </div>
            <div className="Tabs__content">{children}</div>
        </div>
    );
};

export default Tabs;
