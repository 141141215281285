import { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import NotificationPopup from '../../components/NotificationPopup/NotificationPopup';
import { makeEnding } from '../../core/old_helpers';
import { close, getData, setData, sendReconfirmationEmail } from './ReconfirmationPopup.actions';

const TIME_TO_UPDATE_DATA = 2 * 60 * 60 * 1000;

class ReconfirmationPopup extends Component {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.sendReconfirmation = this.sendReconfirmation.bind(this);
    }

    componentDidMount() {
        setInterval(this.props.getData, TIME_TO_UPDATE_DATA);
    }

    close() {
        this.props.close();
        this.forceUpdate();
    }

    sendReconfirmation() {
        this.props.sendReconfirmationEmail();
        this.close();
    }

    renderSendLetterButton() {
        return (
            <button
                className="notification-popup__link"
                onClick={this.sendReconfirmation}
            >
                click here
            </button>
        );
    }

    render() {
        const { period, daysLeft, currentNotificationNumber } = this.props;
        const isCurrentNotificationShouldBeShown = window.localStorage.getItem(`msg${currentNotificationNumber}`) === 'show';
        const isOpened = currentNotificationNumber !== 0 && isCurrentNotificationShouldBeShown;
        return (
            <NotificationPopup close={this.close} isOpened={isOpened} className="notification-popup--wide">
                <div>
                    <div>
                        Every {period === 1 ? '' : period} {makeEnding('month', period)}, access to SNAP! is reconfirmed for security purposes.
                    </div>
                    <div>
                        Please {this.renderSendLetterButton()} for your reconfirmation email and to stay logged-in.
                    </div>
                    <div>
                        You can reconfirm now or anytime {daysLeft === 0 ? 'today' : `in ${daysLeft === 1 ? '' : 'the next'} ${daysLeft} ${makeEnding('day', daysLeft)}`}.
                    </div>
                </div>
            </NotificationPopup>
        );
    }
}

ReconfirmationPopup.propTypes = {
    close: PT.func,
    getData: PT.func,
    setData: PT.func,
    sendReconfirmationEmail: PT.func,
    period: PT.number,
    daysLeft: PT.number,
    currentNotificationNumber: PT.number,
};

const MapStateToProps = state => ({
    period: state.reconfirmationPopupData.period,
    daysLeft: state.reconfirmationPopupData.daysLeft,
    currentNotificationNumber: state.reconfirmationPopupData.currentNotificationNumber,
});

const MapDispatchToProps = dispatch => ({
    close: () => dispatch(close()),
    getData: () => dispatch(getData()),
    setData: data => dispatch(setData(data)),
    sendReconfirmationEmail: data => dispatch(sendReconfirmationEmail(data)),
});

export default connect(MapStateToProps, MapDispatchToProps)(ReconfirmationPopup);
