import { Component } from 'react';
import PropTypes from 'prop-types';
import { scrollTo } from '../../../core/old_helpers';

class MacMenusTableCell extends Component {
    componentDidMount() {
        let collapsibleElem;
        this.tableDataElement.childNodes.forEach((elem) => {
            if (elem.className.indexOf('') !== -1){
                collapsibleElem = elem;
            }
        });
        const maxNormalHeight = parseInt(window.getComputedStyle(collapsibleElem).fontSize) * 2.65;
        if (collapsibleElem.clientHeight > maxNormalHeight) {
            collapsibleElem.classList.add('collapsibleDiv--collapsible-mode');
        }
    }

    handleClickDescription(e) {
        const collapsibleElem =  e.target;
        const classList = collapsibleElem.classList;

        if (classList.contains('collapsibleDiv')) {
            classList.toggle('collapsibleDiv__expanded');
        }
    }

    render() {
        return (
                <td
                    className="table__data table__data--left mac-table__description-col"
                    ref={ (HTMLTableDataCellElement) => this.tableDataElement = HTMLTableDataCellElement}
                    onClick={this.handleClickDescription.bind(this)}
                    data-testid={this.props.testId}
                    dangerouslySetInnerHTML={{
                            __html: this.props.content
                        }}
                >

                </td>
            )
    }
}

export default class MacMenusTable extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.scrollToTable();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.menusLoading && !nextProps.menusLoading) {
            this.scrollToTable();
        }
    }

    scrollToTable() {
        let to = this.refs.DataTable.getBoundingClientRect();
        to = (to.top - 160) + document.getElementById('view-document__scrollbar-view').scrollTop;
        scrollTo(document.getElementById('view-document__scrollbar-view'), to, 800).then(() => console.log('Scroll finished'));
    }

    modifyData() {
        const modified = [].concat(this.props.tableData);
        const regex = new RegExp(`(${this.props.word})`, 'ig');
        this.props.tableData.map((elem, index) => {
            const indexOfWord = elem.Name.indexOf(this.props.word);
            if (indexOfWord !== -1) {
            }
            modified[index].Name = elem.Name.replace(regex, '<span class="mac-table__hightlighted">$1</span>');
            modified[index].Description = elem.Description.replace(regex, '<span class="mac-table__hightlighted">$1</span>');
        });

        return modified;
    }

    renderTableRows() {
        return this.modifyData().map((elem, index) => {
            return (
                <tr
                    key={index}
                    className="table__data-row mac-table__row"
                >
                    <td
                        className="table__data table__data--left"
                        data-testid={`textMacDetailsTableData-${index}-0`}
                    >
                        {elem.RestaurantName}
                    </td>
                    <td
                        className="table__data"
                        data-testid={`textMacDetailsTableData-${index}-1`}
                        dangerouslySetInnerHTML={{
                            __html: elem.Name
                        }}>
                    </td>
                    <MacMenusTableCell
                        content={elem.Description}
                        testId={`textMacDetailsTableData-${index}-2`}
                    />
                    <td
                        className="table__data mac-table__price-col"
                        data-testid={`textMacDetailsTableData-${index}-3`}
                    >
                        {elem.Price}
                    </td>
                </tr>
            );
        });
    }

    render() {
        return (
            <table
                className="mac-table"
                ref="DataTable"
                data-testid="tableMacDetailsMenuExamples"
            >
                <thead>
                <tr className="table__head-row">
                    <th className="table__head" data-testid="textMacDetailsTableHead-0">Restaurant</th>
                    <th className="table__head" data-testid="textMacDetailsTableHead-1">Item</th>
                    <th className="table__head" data-testid="textMacDetailsTableHead-2">Description</th>
                    <th className="table__head" data-testid="textMacDetailsTableHead-3">Price</th>
                </tr>
                </thead>
                <tbody>
                {
                    this.renderTableRows()
                }
                </tbody>
            </table>
        );
    }
}

MacMenusTable.propTypes = {
    tableData: PropTypes.array,
    word: PropTypes.string,
    menusLoading: PropTypes.bool
};
