import {
    MY_DOCUMENTS__STORE_DATA,
    MY_DOCUMENTS__CLEAR_DATA
} from '../../actions/actionsNamesList';

const getDefaultState = () => ({
    dataLoaded: false,
    projects: [],
    documentTypes: [],
    searchResultsProjects: [],
    searchResultsDocuments: [],
    customerViewCompanyId: null,
    isCustomerViewMode: false,
    searchQuery: ''
});

export default function myDocumentsData(state = getDefaultState(), action) {
    switch (action.type) {
        case MY_DOCUMENTS__STORE_DATA: {
            return {
                ...state,
                ...action.data
            };
        }
        case MY_DOCUMENTS__CLEAR_DATA: {
            return {
                ...getDefaultState()
            };
        }
        default: {
            return { ...state };
        }
    }
}
