import http from '../../core/fetch';
import { PROJECT_EDIT_POPUP } from '../../actions/actionsNamesList';
import { storeData as storeProjectPageData } from '../../pages/Project/project.actions';
import {
    getData as updateDocumentsLibraryData,
    updatePageToReload
} from '../DocumentsLibraryPopups/documentsLibraryPopups.actions';

const {
    OPEN, CLOSE, SET_DATA, SHOW_VALIDATION_ERRORS, HIDE_VALIDATION_ERRORS
} = PROJECT_EDIT_POPUP;
let timer;
let request = {
    abort() {
    },
    then() {
    },
    catch() {
    }
};

export function showValidationErrors() {
    return {
        type: SHOW_VALIDATION_ERRORS
    };
}
export function hideValidationErrors() {
    return {
        type: HIDE_VALIDATION_ERRORS
    };
}
export function open() {
    return {
        type: OPEN
    };
}
export function close() {
    return {
        type: CLOSE
    };
}
export function save() {
    return (dispatch, getState) => {
        const { name, description, id } = getState().projectEditPopupData;
        const { customerViewCompanyId } = getState().documentsLibraryPopupsData;
        const params = {
            name: name.trim(),
            description,
            id
        };

        if (customerViewCompanyId) {
            params.companyId = customerViewCompanyId;
        }

        http.post('edit project', params)
            .then((response) => {
                if (response.id !== id) {
                    dispatch(storeProjectPageData({ redirectId: response.id }));
                }
                dispatch(updateDocumentsLibraryData());
                dispatch(updatePageToReload());
                dispatch(close());
            });
    };
}
export function checkNameUnique(name) {
    return (dispatch) => {
        dispatch(setData({ isNameUniquenessChecking: true }));
        clearTimeout(timer);
        timer = setTimeout(() => {
            request.abort();

            request = http.getXHR(
                'check project unique name',
                { projectName: name.trim() }
            );
            request.then((response) => {
                dispatch(setData({
                    isNameUnique: response,
                    isNameUniquenessChecking: false
                }));
            });
        }, 500);
    };
}
export function setData(data) {
    return {
        type: SET_DATA,
        data
    };
}
