import { getFormattedDate } from "../helpers";

export interface iNoteExtend {
    id: number;
    chainId: number;
    chainName: string;
    state: string;
    note: string;
    isPublic: boolean;
    date?: string;
    dateTimestamp: number;
    author: string;
}

interface iData {
    apiModel: iNoteExtend;
}

export class NoteExtend implements iNoteExtend {
    static defaultData: iNoteExtend = {
        id: 0,
        chainId: 0,
        chainName: "",
        state: "",
        note: "",
        isPublic: false,
        date: "-",
        dateTimestamp: 0,
        author: "",
    }

    id = NoteExtend.defaultData.id;
    chainId = NoteExtend.defaultData.chainId;
    chainName = NoteExtend.defaultData.chainName;
    state = NoteExtend.defaultData.state;
    note = NoteExtend.defaultData.note;
    isPublic = NoteExtend.defaultData.isPublic;
    date = NoteExtend.defaultData.date;
    dateTimestamp = NoteExtend.defaultData.dateTimestamp;
    author = NoteExtend.defaultData.author;

    constructor(data?: iData) {
        if ( data && data.apiModel ) {
            this.mapFromApi(data.apiModel);
        }
    }

    private setData(model: iNoteExtend) {
        ({
            id: this.id,
            chainId: this.chainId,
            chainName: this.chainName,
            state: this.state,
            note: this.note,
            isPublic: this.isPublic,
            date: this.date,
            author: this.author
        } = model);
    }

    private formatDate(date?: string) {
        if (!date) return NoteExtend.defaultData.date;
        return getFormattedDate(date, "MMM DD, YYYY");
    }

    private mapFromApi(data: iNoteExtend) {
        this.setData({
            id: data.id,
            chainId: data.chainId,
            chainName: data.chainName,
            state: data.state,
            note: data.note,
            isPublic: data.isPublic,
            date: this.formatDate(data.date),
            dateTimestamp: +new Date(data.date || 0),
            author: data.author,
        });
    }
}