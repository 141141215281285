import { iMenuDetailsItem } from "./MenuDetailsItem";
import { iMenuExample, MenuExamples } from "./MenuExamples";
import { API__MenuItemsVIewModel } from "../apiSchema";
import { iPermissionFields, PermissionLevelEnum, PermissionSection } from "./PermissionSection";
import { getMedianValue, getUnique } from "../helpers";

export enum MenuPartsEnum {
    NotSet = "",
    Appetizers = "Appetizers",
    Entree = "Entrees",
    Side = "Sides",
    Dessert = "Desserts",
    NonAlcoholicBeverages = "Non alcoholic Beverages",
    AlcoholicBeverages = "Alcoholic Beverages",
    Food = "Foods",
    Beverage = "Beverages",
}

interface iMenuPart extends iMenuDetailsItem {
    itemTypes: iMenuDetailsItem[];
}

interface iCompetitorData {
    title: string; /* name of competitor */
    valuesByMenuPart: iMenuPart[];
}

interface iChainMenuItemsData extends iPermissionFields {
    data: iMenuPart[];
}

class ChainMenuItemsData implements iChainMenuItemsData {
    static defaultData: iChainMenuItemsData = {
        permissionLevel: PermissionLevelEnum.Full,
        hasData: true,
        data: [],
    };

    permissionLevel = ChainMenuItemsData.defaultData.permissionLevel;
    hasData = ChainMenuItemsData.defaultData.hasData;
    data = ChainMenuItemsData.defaultData.data;

    constructor(data?: iChainMenuItemsData) {
        if (data) {
            this.mapFromApi(data);
        }
    }

    private setData(data: iChainMenuItemsData) {
        ({
            permissionLevel: this.permissionLevel,
            hasData: this.hasData,
            data: this.data,
        } = data);
    }

    static generateFromData(data: iMenuExample[]): iMenuPart[] {
        const requiredMenuParts = [MenuPartsEnum.Appetizers, MenuPartsEnum.Entree, MenuPartsEnum.Side, MenuPartsEnum.Dessert, MenuPartsEnum.Beverage];

        const menuParts = getUnique([
            ...data.map(i => i.menuPart),
            ...requiredMenuParts
        ]);

        return menuParts.map(menuPart => {
            const menuItems = data.filter(i => i.menuPart === menuPart);
            const menuItemsPrices = menuItems.map(i => i.price).filter(i => !!i);

            // const returnData = {
            return {
                title: menuPart,
                itemCount: menuItems.length,
                maxPrice: menuItemsPrices.length ? Math.max(...menuItemsPrices) : 0,
                minPrice: menuItemsPrices.length ? Math.min(...menuItemsPrices) : 0,
                medianPrice: menuItemsPrices.length ? getMedianValue(menuItemsPrices) : 0,
                itemTypes: [],
            };
            // return returnData;
        });
    }

    mapFromApi(data: iChainMenuItemsData) {
        this.setData(data);
    }
}

export interface iChainMenuData {
    itemsData: iChainMenuItemsData;
    segmentData: {
        title: string;
        valuesByMenuPart: iMenuPart[];
    };
    competitorsData: iCompetitorData[];
    menuExamplesByMenuPart: iMenuExample[];
}

export function getMenuPartType(type: string) {
    switch (type) {
        case 'Appetizer':
            return MenuPartsEnum.Appetizers;
        case 'Entree':
            return MenuPartsEnum.Entree;
        case 'Side':
            return MenuPartsEnum.Side;
        case 'Dessert':
            return MenuPartsEnum.Dessert;
        case 'Beverage':
            return MenuPartsEnum.Beverage;
        case 'Non alcoholic Beverages':
            return MenuPartsEnum.NonAlcoholicBeverages;
        case 'Alcoholic Beverages':
            return MenuPartsEnum.AlcoholicBeverages;
        default:
            return MenuPartsEnum.NotSet;
    }
}

interface iData {
    apiModel: API__MenuItemsVIewModel;
}

export class ChainMenuData implements iChainMenuData {
    static defaultData: iChainMenuData = {
        itemsData: new ChainMenuItemsData(),
        segmentData: {
            title: "",
            valuesByMenuPart: [],
        },
        competitorsData: [],
        menuExamplesByMenuPart: [],
    };

    itemsData = ChainMenuData.defaultData.itemsData;
    segmentData = ChainMenuData.defaultData.segmentData;
    competitorsData = ChainMenuData.defaultData.competitorsData;
    menuExamplesByMenuPart = ChainMenuData.defaultData.menuExamplesByMenuPart;

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private setData(model: iChainMenuData) {
        ({
            itemsData: this.itemsData,
            segmentData: this.segmentData,
            competitorsData: this.competitorsData,
            menuExamplesByMenuPart: this.menuExamplesByMenuPart,
        } = model);
    }

    private mapFromApi(data: API__MenuItemsVIewModel) {
        const { menuDetails, menuExamples } = data;

        const mappedMenuExamples = menuExamples.map(item => new MenuExamples(item));

        const permissionLevel = PermissionSection.getPermissionLevel(menuDetails.permissionLevel);
        this.setData({
            itemsData: new ChainMenuItemsData({
                permissionLevel,
                hasData: menuDetails.hasData,
                data: permissionLevel === PermissionLevelEnum.None
                    ? ChainMenuItemsData.generateFromData([])
                    : ChainMenuItemsData.generateFromData(mappedMenuExamples),
            }),
            segmentData: {
                title: '',
                valuesByMenuPart: [],
                // valuesByMenuPart: segmentData.valuesByMenuPart.map(({ itemTypes, ...data }) => ({
                //     ...new MenuDetailsItem(data),
                //     itemTypes: itemTypes.map(item => new MenuDetailsItem(item)),
                // })),
            },
            competitorsData: [],
            // competitorsData: competitorsData.map(({ title, valuesByMenuPart }) => ({
            //     title: title || "",
            //     valuesByMenuPart: valuesByMenuPart.map(({ itemTypes, ...data }) => ({
            //         ...new MenuDetailsItem(data),
            //         itemTypes: itemTypes.map(item => new MenuDetailsItem(item)),
            //     })),
            // })),
            menuExamplesByMenuPart: mappedMenuExamples,
        });
    }
}
