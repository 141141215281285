import { FC, useEffect, useRef } from "react";
import Scrollbars from "react-custom-scrollbars";
import { iNote } from "../../../../core/models/Note";
import { IconClose } from "../../../../icons";
import Button from "../../Button/Button";
import Loader from "../../Loader/Loader";
import NotesFooter from "../NotesFooter/NotesFooter";
import NotesHeader from "../NotesHeader/NotesHeader";
import NotesList from "../NotesList/NotesList";
import "./NotesItems.styles.scss";

interface iProps {
    data: iNote[];
    isLoading: boolean;
    onClose: () => void;
    onEdit: (note: iNote) => void;
    onRemove: (id: number) => void;
    onToggleForm: () => void;
}

const NotesItems: FC<iProps> = ({
    onClose,
    isLoading,
    data,
    onEdit,
    onRemove,
    onToggleForm,
}) => {
    const scrollbarRef = useRef<Scrollbars>(null);

    useEffect(() => {
        if ( scrollbarRef.current ) {
            scrollbarRef.current.scrollTop(0);
        }
    }, [data]);

    return (
        <div className="NotesItems">
            <div className="NotesItems__header">
                <NotesHeader
                    title={"My notes"}
                >
                    <div className="NotesHeader__close" onClick={onClose}>
                        <IconClose width={15} height={15} />
                    </div>
                </NotesHeader>
            </div>
            <div className="NotesItems__body">
                {isLoading && (
                    <div className="NotesItems__loader">
                        <Loader />
                    </div>
                )}

                <NotesList
                    ref={scrollbarRef}
                    data={data}
                    isLoading={isLoading}
                    onEdit={onEdit}
                    onRemove={onRemove}
                />
            </div>
            <div className="NotesItems__footer">
                <NotesFooter>
                    <div className="NotesFooter__action">
                        <Button
                            modifiers={["primary"]}
                            onClick={onToggleForm}
                            isDisabled={isLoading}
                        >
                            Add Note
                        </Button>
                    </div>
                </NotesFooter>
            </div>
        </div>
    );
};

export default NotesItems;
