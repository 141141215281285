import { FC, useEffect, useMemo, useRef, useState } from "react";
import { iTableConfigItem } from "@components/new/Table/Table.helpers";
import useTableSort from "@components/new/Table/useTableSort";
import { useTableConfig } from "./WhoOffers.helpers";
import { API__ChainMenuAndLtosViewModel } from "@apiSchema";
import CopyButton from "@components/new/Table/CopyButton";
import { sanitizeHtml } from "@helpers";
import TableHead from "../../components/new/Table/TableHead";
import CopyNotification from "@components/new/Table/CopyNotification";
import useNotification from "@hooks/useNotification";
import { TableContext } from "@components/new/Table/TableContext";
import cn from "classnames";
import TableBodyWrapper from "@components/new/Table/TableBodyWrapper";
import { VariableSizeList as List } from "react-window";
import CustomScrollbarsVirtualList from "@components/CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";
import AutoSizer from "react-virtualized-auto-sizer";

import './WhoOffers.styles.scss';
import WhoOffersTableRow from "@pages/NewTopChains/WhoOffersTableRow";


interface iProps {
    setCount: (count: number) => void;
    data: API__ChainMenuAndLtosViewModel[];
}

const WhoOffers: FC<iProps> = (
    {
        setCount,
        data
    }
) => {
    const tableListRef = useRef();
    const {
        handleSort,
        sortingField,
        sortingDirection,
        getColumnIcon,
        sortedData,
    } = useTableSort(data, "chainName");

    const notification = useNotification();

    const [expandedRowIndexes, setExpandedRowIndexes] = useState<number[]>([]);

    const tableConfig: iTableConfigItem[] = useTableConfig(
        sortedData,
        sortingField,
        sortingDirection,
        handleSort,
        getColumnIcon,
        expandedRowIndexes,
        setExpandedRowIndexes,
    );

    useEffect(() => {
        setCount(data.length);
    }, [data.length, setCount]);

    const copyTableData = useMemo(
        (): string[][] => [
            tableConfig.map(i => i.title || ''),
            ...sortedData.map(chain => {
                const items = chain.menuItems[0];
                return [
                    chain.chainName,
                    `${sanitizeHtml(items.title)} - ${sanitizeHtml(items.description)}`,
                    chain.menuItems.length > 1 ? `plus ${chain.menuItems.length - 1} more` : ``
                ]
            })
        ],
        [tableConfig, sortedData]
    );

    const rowsSizes = useRef<number[]>([]);

    useEffect(() => {
        rowsSizes.current = data.map(() => 40);
    }, [data]);

    const getRowSize = (index: number) => rowsSizes.current[index];

    const setSize = (index: number, size: number) => {
        rowsSizes.current[index] = size;
        // @ts-ignore
        tableListRef.current?.resetAfterIndex(index);
    };

    return (
        <div className="NewTopChains__table">
            <TableContext.Provider value={{ config: tableConfig }}>
                <div
                    className={cn(
                        "TableNew",
                        `TableNew--theme-dark`,
                        "TableNew--has-scrollbar",
                    )}
                    data-testid="tablePlacesWhoOffersIt"
                >
                    <div className="TableNew__container">
                        <TableHead/>
                        <CopyNotification isOpen={notification.isOpen}/>
                        <div
                            className="TableNew__copy-button"
                            onClick={notification.handleShowNotification}
                        >
                            <CopyButton
                                data={copyTableData}
                                testId={"buttonFoodsCopyTable"}
                            />
                        </div>

                        <TableBodyWrapper rowsCount={sortedData.length}>
                            <AutoSizer>
                                {({ height, width }) => (
                                    <List
                                        // @ts-ignore
                                        ref={tableListRef}
                                        height={height}
                                        width={width}
                                        itemCount={sortedData.length}
                                        itemSize={getRowSize}
                                        outerElementType={CustomScrollbarsVirtualList}
                                        itemData={{
                                            styles: { paddingRight: 30 },
                                            setSize,
                                        }}
                                    >
                                        {WhoOffersTableRow}
                                    </List>
                                )}
                            </AutoSizer>
                        </TableBodyWrapper>
                    </div>
                </div>
            </TableContext.Provider>
        </div>
    );
};

export default WhoOffers;