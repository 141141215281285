import { Context, createContext, ReactNode } from "react";
import { API__FFSocialLinks, API__SectionDataViewModel } from "../../core/apiSchema";
import {
    ChainDetails,
    iSocialLink,
    ReportsNameEnum,
    SocialLinkEnum
} from "../../core/models/ChainDetails";
import Profile from "./Sections/Profile/Profile";
import Contacts from "./Sections/Contacts/Contacts";
import Menu from "./Sections/Menu/Menu";
import LTO from "./Sections/LTO/LTO";
import Locations from "./Sections/Locations/Locations";
import Brands from "./Sections/Brands/Brands";
import Socials from "./Sections/Socials/Socials";
import { ID } from "../../core/models";
import { sanitizeUrl } from "../../core/helpers";

export const ChainPageScrollId = "ChainPageScroll";

class ChainDetailsDataContextData {
    data = new ChainDetails();
    sections: iSectionConfigItem[] = [];
    openGapReportPopup: () => void = () => null;
    downloadReports: (items: ReportsNameEnum[]) => void = () => null;
}


export const ChainDetailsDataContext: Context<ChainDetailsDataContextData> = createContext(
    new ChainDetailsDataContextData()
);

export enum ChainSectionsEnum {
    Unknown = '',
    Profile = 'Profile',
    Menu = 'Menu',
    Lto = 'LTOs',
    Locations = 'Locations',
    Contacts = 'Contacts',
    Brand = 'Brand',
    Social = 'Social',
}

interface iSectionConfigItem {
    id: ChainSectionsEnum;
    title: string;
    checkHasDataField: keyof API__SectionDataViewModel | null;
    noAccessMessage: string;
    content: ReactNode;
}

export const sectionsConfig: iSectionConfigItem[] = [
    {
        id: ChainSectionsEnum.Profile,
        title: 'Profile',
        checkHasDataField: null,
        content: <Profile/>,
        noAccessMessage: 'Unlock with a subscription to Firefly Chains.',
    },
    {
        id: ChainSectionsEnum.Menu,
        title: 'Menu',
        checkHasDataField: "hasMenu",
        content: <Menu/>,
        noAccessMessage: 'Unlock with a subscription to MenuTrends.',
    },
    {
        id: ChainSectionsEnum.Lto,
        title: 'LTOs',
        checkHasDataField: "hasLto",
        content: <LTO/>,
        noAccessMessage: 'Unlock with a subscription to Firefly Chains.',
    },
    {
        id: ChainSectionsEnum.Locations,
        title: 'Locations',
        checkHasDataField: "hasLocation",
        content: <Locations/>,
        noAccessMessage: 'Unlock with a subscription to Firefly Chains.',
    },
    {
        id: ChainSectionsEnum.Contacts,
        title: 'Contacts',
        checkHasDataField: "hasContacts",
        content: <Contacts/>,
        noAccessMessage: 'Unlock with a subscription to Firefly Chains.',
    },
    {
        id: ChainSectionsEnum.Brand,
        title: 'Brand',
        checkHasDataField: "hasBrand",
        content: <Brands/>,
        noAccessMessage: 'Unlock with a subscription to BrandFingerPrints.',
    },
    {
        id: ChainSectionsEnum.Social,
        title: 'Social',
        checkHasDataField: "hasSocials",
        content: <Socials/>,
        noAccessMessage: 'Unlock with a subscription to Firefly Chains.',
    },
];

export function getAvailableSections(data: API__SectionDataViewModel) {
    return sectionsConfig.filter(i => !i.checkHasDataField || data[i.checkHasDataField]);
}

export function getSectionData(id: iSectionConfigItem['id']): iSectionConfigItem {
    return sectionsConfig.find(i => i.id === id) || {
        id: ChainSectionsEnum.Unknown,
        noAccessMessage: '',
        title: 'undefined section',
        content: <>no content</>,
        checkHasDataField: null,
    };
}

export const getSectionId = (value: ID) => `section-${value}`;
export const getInitialsName = (name: string) => name.match(/\b(\w)/g)?.slice(0, 3).join("").toUpperCase();
export const HEADER_LARGE_HEIGHT = 213;
export const HEADER_SMALL_HEIGHT = 80;
export const APP_HEADER_HEIGHT = 60;

const getSocialName = (name: string): SocialLinkEnum => {
    switch (name) {
        case (SocialLinkEnum.facebook):
            return SocialLinkEnum.facebook;
        case (SocialLinkEnum.foursquare):
            return SocialLinkEnum.foursquare;
        case (SocialLinkEnum.instagram):
            return SocialLinkEnum.instagram;
        case (SocialLinkEnum.linkedin):
            return SocialLinkEnum.linkedin;
        case (SocialLinkEnum.pinterest):
            return SocialLinkEnum.pinterest;
        case (SocialLinkEnum.snapchat):
            return SocialLinkEnum.snapchat;
        case (SocialLinkEnum.tumblr):
            return SocialLinkEnum.tumblr;
        case (SocialLinkEnum.twitter):
            return SocialLinkEnum.twitter;
        case (SocialLinkEnum.wikipedia):
            return SocialLinkEnum.wikipedia;
        case (SocialLinkEnum.youtube):
            return SocialLinkEnum.youtube;
        case (SocialLinkEnum.zomato):
            return SocialLinkEnum.zomato;
        default:
            return SocialLinkEnum.none;
    }
}

export const mapSocialLinksToArray = (data: API__FFSocialLinks): iSocialLink[] => {
    let keys = Object.keys(data) as Array<keyof typeof data>;

    return keys.map((key) => {
        return {
            title: getSocialName(key),
            url: sanitizeUrl(data[key])
        }
    }).filter(i => !!i.url);
}
