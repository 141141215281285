import { SEARCH_POPUP, SEARCH_POPUP_CLEAR_DATA } from '../actions/actionsNamesList';
import { searchPopupResultsSelector } from '../selectors/searchPopup.selectors';
import http from '../core/fetch';
import searchPopup from '../reducers/searchPopup.reducers';

const {
    CHANGE_QUERY, UPDATE_DATA, OPEN_CATEGORY, CLOSE_CATEGORY, SET_LOADING, UNSET_LOADING,
    SHOW_DOCUMENTS_ANYWAY, DONT_SHOW_DOCUMENTS_ANYWAY, SEARCH_POPUP_SHOW_SPINNER, SEARCH_POPUP_HIDE_SPINNER,
    UPDATE_CHAINS_WHO_OFFERS_COUNT,
} = SEARCH_POPUP;

let request;
let requestTimer;

export function updateSearchPopupQuery(searchQuery) {
    return {
        type: CHANGE_QUERY,
        searchQuery
    };
}

export function updateSearchPopupData(data) {
    return {
        type: UPDATE_DATA,
        data
    };
}

export function setSearchPopupCategoryOpened(categoryName) {
    return {
        type: OPEN_CATEGORY,
        categoryName
    };
}

export function closeOpenedSearchPopupCategory() {
    return {
        type: CLOSE_CATEGORY
    };
}

export function setLoading() {
    return {
        type: SET_LOADING
    };
}

export function unsetLoading() {
    return {
        type: UNSET_LOADING
    };
}

export function openSearchPopupCategory(categoryName) {
    return (dispatch, getState) => {
        dispatch(setSearchPopupCategoryOpened(categoryName));
        // const currentCategory = searchPopupResultsSelector(getState()).find(
        //     category => category.name === categoryName
        // );
        // const searchQuery = getState().searchPopup.query;

        // http.get('search', {
        //     q: searchQuery,
        //     documentType: categoryName
        // })
        //     .then((data) => {
        //         const { result } = data;
        //         const storedData = getState().searchPopup.data;
        //         const storeData = Array.from({
        //             ...storedData,
        //             [currentCategory.index]: result[0],
        //             length: storedData.length
        //         });
        //         dispatch(updateSearchPopupData(storeData));
        //     })
        //     .catch(() => {
        //         dispatch(unsetLoading());
        //     });
    };
}

export function updateChainsWhoOffersCount(count) {
    return {
        type: UPDATE_CHAINS_WHO_OFFERS_COUNT,
        count,
    };
}

export function changeSearchPopupQuery(searchQuery) {
    return (dispatch, getState) => {
        dispatch(updateSearchPopupQuery(searchQuery));
        dispatch(closeOpenedSearchPopupCategory());
        dispatch(updateSearchPopupData([]));
        dispatch(updateChainsWhoOffersCount(0));
        dispatch(setLoading());
        if (searchQuery !== '') {
            clearTimeout(requestTimer);
            requestTimer = setTimeout(() => {
                if (request) request.abort();
                request = http.getXHR('search', { q: searchQuery });
                request.then((data) => {
                    dispatch(unsetLoading());
                    if (getState().searchPopup.query !== '') {
                        dispatch(updateChainsWhoOffersCount(data.chainMenuItemsCount));
                    }
                    dispatch(updateSearchPopupData(data.result));
                });
            }, 300);
        }
    };
}

export function changeSearchFilter(searchQuery, fromDate) {
    return (dispatch) => {
        clearTimeout(requestTimer);
        requestTimer = setTimeout(() => {
            if (request) request.abort();
            const params = {
                q: searchQuery,
            };
            dispatch(showLoadingSpinner());
            if(fromDate) {
                params.fromDate = fromDate;
            }
            request = http.getXHR('search', params);
            request.then((data) => {
                dispatch(hideLoadingSpinner());
                dispatch(showDocumentsAnyway());
                dispatch(updateSearchPopupData(data.result));
            });
        }, 0);
    };
}

export function showLoadingSpinner() {
    return {
        type: SEARCH_POPUP_SHOW_SPINNER,
    }
}

export function hideLoadingSpinner() {
    return {
        type: SEARCH_POPUP_HIDE_SPINNER,
    }
}

export function initSearchPopupData() {
    return (dispatch) => {
        dispatch(changeSearchPopupQuery(''));
    };
}

export function clearSearchPopupResults() {
    return {
        type: SEARCH_POPUP_CLEAR_DATA
    };
}

export function showDocumentsAnyway() {
    return {
        type: SHOW_DOCUMENTS_ANYWAY,
    }
}

export function dontShowDocumentsAnyway() {
    return {
        type: DONT_SHOW_DOCUMENTS_ANYWAY,
    }
}
