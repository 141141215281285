import { FC } from "react";
import cn from "classnames";
import { Link, NavLink } from "react-router-dom";
import { AnalyticsData, getAnalyticsComputedData } from "@models/AnalyticsAttributes";

export type Modifiers = 'filled' | 'hover' | 'inactive' | 'mt';

export enum ButtonTypeEnum {
    Button,
    Anchor,
    Link,
    NavLink,
}

const ContentWidgetButton: FC<{
    className?: string;
    onClick?: () => void;
    link?: string;
    modifiers?: Modifiers[];
    type?: ButtonTypeEnum;
    analyticsData?: AnalyticsData;
    testId?: string;
}> = (
    {
        children,
        className,
        modifiers = [],
        onClick,
        link,
        type= ButtonTypeEnum.Button,
        analyticsData,
        testId,
    }
) => {
    const classNames = cn(
        'font-text',
        'ContentWidget__button',
        ...modifiers.map(m => `ContentWidget__button--${m}`),
        className
    );

    const props = {
        onClick,
        className: classNames,
        ['data-testid']: testId,
        ...(analyticsData ? getAnalyticsComputedData(analyticsData) : {}),
    }

    switch (type) {
        case ButtonTypeEnum.Anchor: {
            return (
                <a
                    target="_blank"
                    href={link}
                    {...props}
                >
                    {children}
                </a>
            );
        }
        case ButtonTypeEnum.Link: {
            return (
                <Link
                    to={link || ''}
                    {...props}
                >
                    <>
                        {children}
                    </>
                </Link>
            );
        }
        case ButtonTypeEnum.NavLink: {
            return (
                <NavLink
                    to={link || ''}
                    {...props}
                >
                    <>
                        {children}
                    </>
                </NavLink>
            );
        }
        default: {
            return (
                <div
                    {...props}
                >
                    {children}
                </div>
            );
        }
    }
}

export default ContentWidgetButton;