import { createSelector } from 'reselect';
import getFileSize from '../../core/getFileSize';
import { FILE_SIZE_MAX_LIMIT } from '../DocumentsLibraryPopups/documentsLibraryPopups.reducers';

const documentEditData = state => state.documentEditPopupData;

export const isEditDocumentPopupOpenedSelector = createSelector(
    documentEditData,
    (popupData) => popupData.isOpened,
);

export const documentEditPopupSubtitle = createSelector(
    documentEditData,
    document => {
        const { name, docTypeString, isFileChanged, file } = document;
        const fileSize = isFileChanged ? file.size : document.size;
        const size = getFileSize(fileSize);
        const isSizeValid = fileSize < FILE_SIZE_MAX_LIMIT;
        const title = isFileChanged ? file.name : `${name}${docTypeString}`;
        const fileName = title.length > 14
            ? `${title.substr(0, 7)}...${title.substr(title.length - 7)}`
            : title;

        let subTitle = `<div class="file-upload__file-name" data-testid="textDocumentEditPopupFileName">${fileName}</div>`;
        subTitle += ` ${!isSizeValid ? '<span class="file-upload__error-mark">' : ''}`;
        subTitle += `<div class="file-upload__file-size" data-testid="textDocumentEditPopupFileSize">${size.value.toFixed(1)} ${size.units}</div>`;
        subTitle += ` ${!isSizeValid ? '' : '</span>'}`;

        return subTitle;
    }
)
