import { FC, memo, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import ProgressBar from "@components/ProgressBar/ProgressBar";
import http from "@core/fetch";
import { IconDownload, IconSuccess } from '@icons';
import apiConfig from "@apiConfig";

enum ButtonText {
    Default = 'export instant chart',
    Loading = 'Downloading...',
    Success = 'Download Successful',
}

const ButtonIcon = {
    Default: (<IconDownload className="icon" width={20} height={20}/>),
    Loading: null,
    Success: (<IconSuccess className="icon" width={20} height={20}/>),
}

const ProfileDownloadButton: FC<{
    reportId: string;
}> = (
    {
        reportId,
    }
) => {
    const [isProfileDownloading, setIsProfileDownloading] = useState(false);
    const [hasBeenDownloaded, setHasBeenDownloaded] = useState(false);
    const [text, setText] = useState<ButtonText>(ButtonText.Default);
    const [icon, setIcon] = useState<ReactNode>(ButtonIcon.Default);

    useEffect(() => {
        if (isProfileDownloading) {
            setText(ButtonText.Loading);
            setIcon(ButtonIcon.Loading);
        }
        else if (!isProfileDownloading && hasBeenDownloaded) {
            setText(ButtonText.Success);
            setIcon(ButtonIcon.Success)
            setTimeout(() => {
                setText(ButtonText.Default);
                setIcon(ButtonIcon.Default);
            }, 5000);
        }
    }, [isProfileDownloading, hasBeenDownloaded]);

    const downloadFile = useCallback((url) => {
        setIsProfileDownloading(true);
        http.downloadFileXHRFromUrl('GET', url)
            .then(() => {
                setIsProfileDownloading(false);
                setHasBeenDownloaded(true);
            });
    }, []);

    let downloadXlsLink = useMemo(
        () => `${apiConfig['export instant chart xls']}?reportId=${reportId}`,
        [reportId]
    );
    let downloadPptLink = useMemo(
        () => `${apiConfig['export instant chart ppt']}?reportId=${reportId}`,
        [reportId]
    );

    return (
            <div
                className={cn(
                    'InstantChartReport__download-button',
                    'cube',
                    'font-text',
                    isProfileDownloading && 'InstantChartReport__download-button--downloading',
                )}
                data-testid={`buttonInstantChartsDownload`}
            >
                    <div
                        className={cn(
                        'InstantChartReport__download-button--download',
                            'cube__part',
                            'clickable'
                        )}
                        data-testid={`textInstantChartsDownloadLabel`}
                    >
                        {icon}
                        {text}
                        {isProfileDownloading && (
                            <div className="InstantChartReport__download-button--progress-bar">
                                <ProgressBar/>
                            </div>
                        )}
                    </div>
                    <div className={cn(
                        'InstantChartReport__download-button--download',
                        'cube__part',
                        'cube__part-hover',
                    )}>
                        <button
                            className="InstantCharts__button clickable"
                            onClick={() => downloadFile(downloadXlsLink)}
                            data-testid={`buttonInstantChartsDownloadXls`}
                        >
                            XLSX
                        </button>
                        <button
                            className="InstantCharts__button clickable"
                            onClick={() => downloadFile(downloadPptLink)}
                            data-testid={`buttonInstantChartsDownloadPpt`}
                        >
                            PPT
                        </button>
                    </div>
            </div>
    );
};

export default memo(ProfileDownloadButton);