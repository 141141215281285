import { PROJECT_EDIT_POPUP } from '../../actions/actionsNamesList';

const {
    OPEN, CLOSE, SET_DATA, SHOW_VALIDATION_ERRORS, HIDE_VALIDATION_ERRORS, SET_ACTIVE_COMPANY_ID
} = PROJECT_EDIT_POPUP;

const defaultState = {
    isOpened: false,
    showErrors: false,
    id: null,
    name: '',
    initialName: '',
    isNameUnique: false,
    isNameUniquenessChecking: false,
    customerViewCompanyId: null,
    description: ''
};

export default function projectEditPopupData(state = defaultState, action) {
    switch (action.type) {
        case OPEN: {
            return {
                ...state,
                isOpened: true
            };
        }
        case CLOSE: {
            return {
                ...state,
                isOpened: false
            };
        }
        case SHOW_VALIDATION_ERRORS: {
            return {
                ...state,
                showErrors: true
            };
        }
        case HIDE_VALIDATION_ERRORS: {
            return {
                ...state,
                showErrors: true
            };
        }
        case SET_ACTIVE_COMPANY_ID: {
            return {
                ...state,
                customerViewCompanyId: action.id
            };
        }
        case SET_DATA: {
            return {
                ...state,
                ...action.data
            };
        }
        default: {
            return { ...state };
        }
    }
}