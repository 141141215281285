import { FC } from "react";
import cn from "classnames";
import "./InputRadio.styles.scss";

interface iProps {
    isActive: boolean;
    isDisabled?: boolean;
    label?: string;
    className?: string;
    modifiers?: string[];
    onChange: () => void;
}

const InputRadio: FC<iProps> = ({ isActive, isDisabled, label, onChange, className, modifiers = [] }) => {
    const classNames = cn(
        "InputRadio",
        modifiers.map((m) => `InputRadio--${m}`),
        className,
        isDisabled ? "InputRadio--disabled" : "",
        isActive ? "InputRadio--active" : ""
    );

    return (
        <div className={classNames}>
            <label className="InputRadio__field">
                <input type="radio" className="InputRadio__input" onChange={onChange} checked={isActive} />
                <div className="InputRadio__icon"></div>
                <div className="InputRadio__label">{label}</div>
            </label>
        </div>
    );
};

export default InputRadio;
