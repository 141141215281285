import { Component } from 'react';
// import './SelectUser.styles.scss';
import { contains } from '../../core/old_helpers';
import Scrollbar from 'react-custom-scrollbars';
import { capitalize } from '../../core/old_helpers';

export default class SelectUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchQuery: '',
        };
    }

    renderContact(contact, index) {
        const { title, id, status } = contact;
        const { value } = this.props;
        const classList = new Set([
            'drop-down-contact',
        ]);

        if (id === value) {
            classList.add('drop-down-contact--checked');
        }

        const isDisabled = status !== 'active';

        if (isDisabled) {
            classList.add('drop-down-contact--disabled');
        }

        return (
            <div
                onClick={() => { if (!isDisabled) this.props.setValue(id)}}
                key={index}
                className={Array.from(classList).join(' ')}
                title={isDisabled ? capitalize(status.replace('_', ' ')) : ''}
            >
                <div
                    className="drop-down-contact__title"
                >
                    {title}
                </div>
            </div>
        );
    }

    renderContacts() {
        const { contacts } = this.props;
        const { searchQuery } = this.state;
        const filteredContacts = contacts.filter((contact) => {
            if (searchQuery === '') {
                return true;
            }
            return contains(contact.title, searchQuery);
        });

        return (
            <div className="custom-drop-down__scrollbar-outer">
                {filteredContacts.length > 0
                    ? (
                        <Scrollbar>
                            <div className="custom-drop-down__scrollbar-inner">
                                {filteredContacts.map(this.renderContact.bind(this))}
                            </div>
                        </Scrollbar>
                    )
                    : (
                        <div className="custom-drop-down__msg">
                            no matches
                        </div>
                    )
                }
            </div>

        );
    }


    render() {
        return (
            <div className="SelectUser">
                <input
                    ref='input'
                    value={this.state.searchQuery}
                    onChange={({ target }) => this.setState({ searchQuery: target.value })}
                    className="custom-drop-down__input custom-drop-down__input--margin"
                    placeholder="Start typing name..."
                />
                {this.renderContacts()}
            </div>
        );
    }
}
