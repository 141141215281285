import {
    DOCUMENTS_ADD_POPUP
} from '../../actions/actionsNamesList';

const {
    SET_VIEW,
    OPEN,
    CLOSE,
    SHOW_VALIDATION_ERRORS,
    HIDE_VALIDATION_ERRORS,
    SET_ACTIVE_PROJECT_ID,
    SET_ACTIVE_TYPE_ID,
    STORE_UPLOADING_FILES,
    UPDATE_DOCUMENT_DATA,
    CANCEL_FILE_UPLOAD,
    SET_UPLOADING_STARTED,
    SET_UPLOADING_FINISHED,
    SET_SAVING_REQUESTED,
    UNSET_SAVING_REQUESTED,
    REMOVE_LAST_SET,
    CLEAR_FILES_UPLOAD,
    SET_ACTIVE_COMPANY_ID,
    INC_CURRENT_DOCUMENT_INDEX,
    SET_CURRENT_DOCUMENT_INDEX,
} = DOCUMENTS_ADD_POPUP;

const companyId = window.pageData.currentUsercompanyId;

const defaultState = {
    isOpened: false,
    showErrors: false,
    isSavingRequested: false,
    view: 'upload',
    activeProjectId: null,
    activeTypeId: null,
    customerViewCompanyId: null,
    isCustomerViewMode: false,
    documents: [],
    lastUploadingSetId: null,
    isUploading: false,
    currentDocumentIndex: 0,
};

export default function documentsAddPopupData(state = defaultState, action) {
    switch (action.type) {
        case SET_VIEW: {
            return {
                ...state,
                view: action.viewName
            };
        }
        case OPEN: {
            return {
                ...state,
                isOpened: true
            };
        }
        case CLOSE: {
            return {
                ...state,
                isOpened: false
            };
        }
        case SHOW_VALIDATION_ERRORS: {
            return {
                ...state,
                showErrors: true
            };
        }
        case HIDE_VALIDATION_ERRORS: {
            return {
                ...state,
                showErrors: false
            };
        }
        case SET_ACTIVE_COMPANY_ID: {
            return {
                ...state,
                customerViewCompanyId: action.id,
                isCustomerViewMode: action.id !== null
            };
        }
        case SET_ACTIVE_PROJECT_ID: {
            return {
                ...state,
                activeProjectId: action.id
            };
        }
        case SET_ACTIVE_TYPE_ID: {
            return {
                ...state,
                activeTypeId: action.id
            };
        }
        case SET_UPLOADING_STARTED: {
            return {
                ...state,
                isUploading: true
            };
        }
        case SET_UPLOADING_FINISHED: {
            return {
                ...state,
                isUploading: false
            };
        }
        case SET_SAVING_REQUESTED: {
            return {
                ...state,
                isSavingRequested: true
            };
        }
        case UNSET_SAVING_REQUESTED: {
            return {
                ...state,
                isSavingRequested: false
            };
        }
        case STORE_UPLOADING_FILES: {
            if (action.isFromLastSet) {
                const setId = state.lastUploadingSetId;
                const allreadyAddedCount = +state.documents[state.documents.length - 1].id.split('--')[1] + 1;
                const shareWith = state.documents[0].shareWith;
                console.log(allreadyAddedCount);
                return {
                    ...state,
                    documents: [
                        ...state.documents,
                        ...action.files.map((file, index) => ({
                            file,
                            id: `${setId}--${allreadyAddedCount + index}`,
                            name: '',
                            description: '',
                            isNameUniqueOnServer: false,
                            isNameUniquenessChecking: false,
                            projectId: state.activeProjectId,
                            typeId: state.activeTypeId,
                            shareWith,
                            shareType: 0,
                            shareContacts: new Map(),
                            backEndData: {}
                        }))
                    ]
                };
            }

            const newSetId = new Date().getTime();
            const shareWith = [companyId];
            if (state.isCustomerViewMode) {
                shareWith.push(state.customerViewCompanyId);
            }
            return {
                ...state,
                lastUploadingSetId: newSetId,
                documents: [
                    ...state.documents,
                    ...action.files.map((file, index) => ({
                        file,
                        id: `${newSetId}--${index}`,
                        name: '',
                        description: '',
                        isNameUniqueOnServer: false,
                        isNameUniquenessChecking: false,
                        projectId: state.activeProjectId,
                        typeId: state.activeTypeId,
                        shareWith,
                        shareType: 0,
                        shareContacts: new Map(),
                        backEndData: {}
                    }))
                ]
            };
        }
        case UPDATE_DOCUMENT_DATA: {
            const index = state.documents.findIndex(document => document.id === action.id);
            const documents = state.documents.concat([]);
            documents.splice(index, 1, { ...documents[index], ...action.data });
            return {
                ...state,
                documents,
            };
        }
        case CANCEL_FILE_UPLOAD: {
            const index = state.documents.findIndex(document => document.id === action.id);
            const documents = state.documents.concat([]);
            documents.splice(index, 1);

            return {
                ...state,
                documents
            };
        }
        case CLEAR_FILES_UPLOAD: {
            return {
                ...state,
                lastUploadingSetId: null,
                documents: []
            };
        }
        case REMOVE_LAST_SET: {
            return {
                ...state,
                documents: state.documents.filter(doc => doc.id.indexOf(state.lastUploadingSetId) !== 0)
            };
        }
        case INC_CURRENT_DOCUMENT_INDEX: {
            return {
                ...state,
                currentDocumentIndex: state.currentDocumentIndex + 1
            };
        }
        case SET_CURRENT_DOCUMENT_INDEX: {
            return {
                ...state,
                currentDocumentIndex: action.index,
            };
        }
        default: {
            return state;
        }
    }
}
