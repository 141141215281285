import { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import Popup from '../../components/Popup/Popup';
import { Redirect, withRouter } from 'react-router-dom';
import serializeParams from '../../core/serializeParams';
import { close, resetData, deleting } from './projectDeletePopup.actions';

class ProjectDeletePopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            confirmationInputValue: '',
            forceRedirect: false
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.deleteProject = this.deleteProject.bind(this);
        this.changeConfirmationInputValue = this.changeConfirmationInputValue.bind(this);
        this.forceRedirect = this.forceRedirect.bind(this);
    }

    changeConfirmationInputValue(event) {
        this.setState({ confirmationInputValue: event.target.value });
    }

    forceRedirect() {
        this.setState({
            forceRedirect: true
        });
        setTimeout(() => {
            this.setState({
                forceRedirect: false
            });
        }, 1000);
    }

    deleteProject(event) {
        const { deleting } = this.props;
        const url = this.props.location.pathname;
        const needToRedirect = url.indexOf('/project') !== -1;
        event.preventDefault();
        if (this.state.confirmationInputValue.toLowerCase() === 'delete') {
            deleting(needToRedirect && this.forceRedirect);
        }
    }

    handleOpen() {
        this.setState({ confirmationInputValue: '' });
    }

    handleClose() {
        const { close } = this.props;
        close();
        resetData();
    }

    render() {
        const { filesCount, isCustomerViewMode, customerViewCompanyId } = this.props;
        const redirectLinkParams = [];
        const url = this.props.location.pathname;
        if (isCustomerViewMode) {
            redirectLinkParams.companyId = customerViewCompanyId;
        }
        if (url.indexOf('isShareMode=true') > -1) {
            redirectLinkParams.isShareMode = true;
        }
        const redirectLink = `/myDocuments?${serializeParams(redirectLinkParams)}`;
        return (
            <Popup
                isOpened={this.props.isOpened}
                onOpen={this.handleOpen}
                onClose={this.handleClose}
                className="popup--fade-in"
            >
                <div className="popup__inner popup-red popup__inner--small">
                    <div className="popup__header">
                        <div
                            className="popup__title"
                            data-testid="textProjectDeletePopupTitle"
                        >
                            Are you sure?
                        </div>
                    </div>
                    <div
                        className="popup__text popup__text--indented popup__text--white popup__text--light"
                        data-testid="textProjectDeletePopupContent"
                    >
                        This process can not be reversed and
                        will PERMANENTLY delete all <span data-testid="textProjectDeletePopupFilesCount">{filesCount}</span> files related to this project.
                    </div>
                    <div className="popup__form-wrapper popup__form-wrapper--bordered">
                        <form onSubmit={this.deleteProject}>
                            <div
                                className="popup__input-label popup__input-label--no-transform popup__input-label--light"
                                data-testid="textProjectDeletePopupConfirmInputLabel"
                            >
                                Type <strong>"DELETE"</strong> to confirm
                            </div>
                            <input
                                type="text"
                                value={this.state.confirmationInputValue.toUpperCase()}
                                onChange={this.changeConfirmationInputValue}
                                autoFocus={true}
                                className={`
                                    popup__input popup__input--big popup__input--underlined
                                    popup__input--backgrounded popup__margin--big
                                `}
                                data-testid="inputProjectDeletePopupConfirm"
                            />
                            <input type="submit" className="hidden"/>
                        </form>
                    </div>
                    <div className="popup__button-wrapper">
                        <div className="popup__right-side">
                            <button
                                onClick={this.handleClose}
                                className="popup__link popup__link--light popup__link--white"
                                data-testid="buttonProjectDeletePopupCancel"
                            >
                                cancel
                            </button>
                            <button
                                disabled={this.state.confirmationInputValue.toLowerCase() !== 'delete'}
                                onClick={this.deleteProject}
                                className="popup__btn popup__btn--white popup__btn--md"
                                data-testid="buttonProjectDeletePopupDelete"
                            >
                                DELETE
                            </button>
                            {
                                this.state.forceRedirect &&
                                <Redirect to={redirectLink} className="hidden" ref="my_docs_link"/>
                            }
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}

ProjectDeletePopup.propTypes = {
    isOpened: PT.bool,
    id: PT.oneOfType([
        PT.number,
        PT.string
    ]),
    filesCount: PT.number,
    close: PT.func,
    clearData: PT.func,
    deleting: PT.func,
    location: PT.object,
    customerViewCompanyId: PT.number,
    isCustomerViewMode: PT.bool
};

const MapStateToProps = state => ({
    isOpened: state.projectDeletePopupData.isOpened,
    id: state.projectDeletePopupData.id,
    filesCount: state.projectDeletePopupData.filesCount,
    customerViewCompanyId: state.documentsLibraryPopupsData.customerViewCompanyId,
    isCustomerViewMode: state.documentsLibraryPopupsData.customerViewCompanyId !== null
});

const MapDispatchToProps = dispatch => ({
    close: () => dispatch(close()),
    resetData: () => dispatch(resetData()),
    deleting: callback => dispatch(deleting(callback))
});

export default withRouter(connect(MapStateToProps, MapDispatchToProps)(ProjectDeletePopup));
