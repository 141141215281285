import { FC, ChangeEvent, CSSProperties } from "react";
import cn from "classnames";
import "./InputField.styles.scss";

interface iProps {
    label?: string;
    className?: string;
    onChange: (value: string) => void;
    hasError?: boolean;
    value: string;
    placeholder?: string;
    isRequired?: boolean;
    style?: CSSProperties & {
        input?: CSSProperties;
        label?: CSSProperties;
    };
}

const InputField: FC<iProps> = ({
    label = '',
    className,
    onChange,
    hasError,
    value,
    placeholder = "",
    isRequired,
    children,
    style,
}) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    const classNames = cn(
        "InputField",
        className,
        hasError && "InputField--has-error",
    );

    return (
        <div
            className={classNames}
            style={style}
        >
            <input
                type="text"
                placeholder={placeholder}
                onChange={handleChange}
                value={value}
                spellCheck={false}
                className={cn("InputField__input", className)}
                style={style?.input}
            />
            <label
                htmlFor={label}
                className={cn(["InputField__label", value && "is-upper", isRequired && "is-required"])}
                style={style?.label}
            >
                {label}
            </label>

            {children}
        </div>
    );
};

export default InputField;
