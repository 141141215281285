import {FC} from "react";
import './FoodProfilePipelineMenu.scss';
import {Link} from "react-router-dom";
import { ID } from "@models";

enum PipelineColorsEnum {
    Inception = `rgb(26, 175, 84)`,
    Adoption = `rgb(248, 61, 38)`,
    Proliferation = `rgb(175, 81, 214)`,
    Ubiquity = `rgb(147, 98, 84)`,
}

export interface iPipelineMenu {
    flavorCategoryName: string;
    categories: {
        id: ID;
        title: string;
        isActive?: boolean;
        words: {
            id: ID;
            name: string;
        }[];
    }[];
}

const FoodProfilePipelineMenu: FC<{
    data: iPipelineMenu
}> = (
    {
        data,
    }
) => {
    return (
        <ul className="FoodProfilePipelineMenu">
            {data.categories.map((item, index) => {
                const newWordsData = item.words.slice(0, 6);
                const itemColor = PipelineColorsEnum[item.title as keyof typeof PipelineColorsEnum];
                return (
                    <li
                        className={"FoodProfilePipelineMenu__item" + (item.isActive ? ' is-active' : '')}
                        key={item.id + '_' + index}
                        style={{'color': `${itemColor}`}}
                        data-testid={`buttonFoodProfileMacStage${index}`}
                    >
                        <div
                            className="FoodProfilePipelineMenu__text"
                            data-testid="textFoodProfileMacStageTitle"
                        >
                            {item.title}
                        </div>
                        <div className="FoodProfilePipelineMenu__hover-popup">
                            <div data-testid="textFoodProfileMacStagePopupTitle">
                                Other {data.flavorCategoryName} in the {item.title.toUpperCase()} stage:
                            </div>
                            <div className="FoodProfilePipelineMenu__other-list">
                                {newWordsData.map((word, worldIndex) => (
                                    <Link
                                        key={word.id + '_' + worldIndex}
                                        to={{
                                            pathname: '/food',
                                            search: `?q=${word.name.toLowerCase()}`
                                        }}
                                        className="FoodProfilePipelineMenu__other-item"
                                        data-testid={`buttonFoodProfileMacStagePopupItem${worldIndex}`}
                                    >
                                        {word.name.toLowerCase()}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}

export default FoodProfilePipelineMenu;