import { FC, useCallback, useEffect, useRef, useState } from "react";
import './FoodProfileConsumerAffinity.scss';
import SpriteIcon from "@components/SpriteIcon/SpriteIcon";
import ContentWidgetLink from "@components/ContentWidget/ContentWidgetLink";
import { AnalyticsAttribute, getAnalyticsComputedData } from "@models/AnalyticsAttributes";
import { PermissionLevelEnum } from "@models/PermissionSection";
import {
    iAffinityNormsGroup,
    iFoodProfileAffinityData,
    iNorm
} from "@models/FoodProfileAffinity";
import cn from "classnames";
import useOutsideClick from "@hooks/useOutsideClick";
import { IconProductFlavorMinimal } from '@icons';

enum AffinitySlices {
    Affinity,
    Experience,
}

enum ValueTypes {
    Raw,
    Norms,
}

const AffinityCategory: FC<{
    category: iAffinityNormsGroup;
    categoryIndex: number;
    activeSlice: AffinitySlices;
    activeValueType: ValueTypes;
}> = (
    {
        category,
        categoryIndex,
        activeSlice,
        activeValueType,
    }
) => {
    const getValueString = useCallback((item: iNorm) => {
        const value = activeSlice === AffinitySlices.Affinity ? item.affinityValue : item.experienceValue;

        return [
            activeValueType === ValueTypes.Raw ? value.raw : value.norm,
            activeValueType === ValueTypes.Raw ? `${Math.round(value.raw)}%` : value.norm,
        ];
    }, [category, activeSlice, activeValueType]);

    return (
        <div
            className="FoodProfileConsumerChart__group"
            data-testid={`textFoodProfileConsumerSection${categoryIndex}`}
        >
            {category.title !== '' &&
                <div
                    className="FoodProfileConsumerChart__group-title font-search"
                    data-testid="textFoodProfileConsumerSectionTitle"
                >
                    {category.title}
                </div>
            }
            {category.norms.map((item, index) => {
                const [valueNum, valueStr] = getValueString(item);
                return (
                    <div
                        key={item.title}
                        className="FoodProfileConsumerChart__item"
                        data-testid={`textFoodProfileConsumerSectionItem${index}`}
                    >
                        <div
                            className="FoodProfileConsumerChart__name font-search"
                            data-testid="textFoodProfileConsumerSectionItemTitle"
                        >
                            {item.title}
                        </div>
                        <div className="FoodProfileConsumerChart__progress-bar">
                            <div
                                key={`${activeSlice}-${activeValueType}`}
                                className="FoodProfileConsumerChart__progress-bar-line"
                                style={{ width: `${valueNum}%` }}
                            />
                        </div>
                        <div
                            className="FoodProfileConsumerChart__value"
                            data-testid="textFoodProfileConsumerSectionItemValue"
                        >
                            {valueStr}
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

const FoodProfileConsumerAffinity: FC<{
    data: iFoodProfileAffinityData;
    consumerAffinityCategories: iAffinityNormsGroup[];
    permissionLevel: PermissionLevelEnum;
}> = (
    {
        data,
        consumerAffinityCategories,
        permissionLevel,
    }
) => {
    const container = useRef<HTMLDivElement>(null);
    const [activeSlice, setActiveSlice] = useState<AffinitySlices>(AffinitySlices.Affinity);
    const [activeValueType, setActiveValueType] = useState<ValueTypes>(ValueTypes.Raw);
    const [isOpened, setIsOpened] = useState(false);
    const close = useCallback(() => setIsOpened(false), []);

    useOutsideClick({
        ref: container,
        isDisabled: !isOpened,
        fn: close,
    });

    const showDetailInfo = function () {
        setIsOpened(!isOpened);
    }

    return (
        <div
            ref={container}
            className={"FoodProfileSummary__consumer-affinity-content FoodProfileConsumerAffinity" + (isOpened ? " is-expand" : "")}
        >
            <div className="FoodProfileConsumerAffinity__wrapper">
                <div
                    className={cn(
                        "FoodProfileConsumerAffinity__block",
                        isOpened && activeSlice === AffinitySlices.Affinity && "is-active",
                    )}
                >
                    <div
                        className="FoodProfileConsumerAffinity__title"
                        data-testid="textFoodProfileConsumerWidgetAffinityTitle"
                    >
                        Consumer affinity
                    </div>
                    <div className="FoodProfileConsumerAffinity__circle">
                        <div className="FoodProfileConsumerAffinity__circle-icon">
                            <SpriteIcon iconId="heart"/>
                        </div>
                        <div
                            className="FoodProfileConsumerAffinity__circle-title"
                            data-testid="textFoodProfileConsumerWidgetAffinityValue"
                        >
                            {Math.round(data.loveOrLikeIt)}
                            <div className="FoodProfileConsumerAffinity__circle-title-value">%</div>
                        </div>
                        <div
                            className="FoodProfileConsumerAffinity__circle-subtitle"
                            data-testid="textFoodProfileConsumerWidgetAffinityDescription"
                        >
                            love or<br/> like it
                        </div>
                    </div>
                </div>
                <div
                    className={cn(
                        "FoodProfileConsumerAffinity__block",
                        isOpened && activeSlice === AffinitySlices.Experience && "is-active",
                    )}
                >
                    <div
                        className="FoodProfileConsumerAffinity__title"
                        data-testid="textFoodProfileConsumerWidgetExperienceTitle"
                    >
                        Consumer Experience
                    </div>
                    <div className="FoodProfileConsumerAffinity__item">
                        <div
                            className="FoodProfileConsumerAffinity__item-title"
                            data-testid="textFoodProfileConsumerWidgetKnowItValue"
                        >
                            {Math.round(data.knowIt)}
                            <div className="FoodProfileConsumerAffinity__item-title-value">%</div>
                        </div>
                        <div
                            className="FoodProfileConsumerAffinity__item-subtitle"
                            data-testid="textFoodProfileConsumerWidgetKnowItDescription"
                        >
                            know it
                        </div>
                    </div>
                    <div className="FoodProfileConsumerAffinity__item">
                        <div
                            className="FoodProfileConsumerAffinity__item-title"
                            data-testid="textFoodProfileConsumerWidgetHaveTriedItValue"
                        >
                            {Math.round(data.haveTriedIt)}
                            <div className="FoodProfileConsumerAffinity__item-title-value">%</div>
                        </div>
                        <div
                            className="FoodProfileConsumerAffinity__item-subtitle"
                            data-testid="textFoodProfileConsumerWidgetHaveTriedItDescription"
                        >
                            have <br/>tried it
                        </div>
                    </div>
                </div>
                <a
                    href={data.url}
                    target="_blank"
                    {...getAnalyticsComputedData({
                        [AnalyticsAttribute.ClickTitle]: 'Flavor Redirect',
                    })}
                    className={cn(
                        "FoodProfileConsumerAffinity__logo",
                        "clickable",
                        permissionLevel === PermissionLevelEnum.Limited && "is-hidden",
                    )}
                    data-testid="buttonFoodProfileConsumerExternalLink"
                >
                    <IconProductFlavorMinimal height={30} width={130}/>
                </a>
                <div className="FoodProfileWidget__link-wrapper">
                    <ContentWidgetLink
                        className="FoodProfileConsumerAffinity__link clickable"
                        analyticsData={{
                            [AnalyticsAttribute.ClickTitle]: 'Flavor Sidebar',
                        }}
                        onClick={showDetailInfo}
                        testId={"buttonFoodProfileConsumerExpandCollapse"}
                    >
                        {isOpened ? 'hide details' : 'view details'}
                    </ContentWidgetLink>
                </div>
            </div>
            <div
                className="FoodProfileConsumerAffinity__hover-data-wrapper"
                onClick={() => setIsOpened(false)}
            >
                <div
                    className={cn(
                        "FoodProfileConsumerAffinity__hover-data",
                        isOpened && "is-show",
                    )}
                    onClick={(event) => event.stopPropagation()}
                >
                    <div className="FoodProfileConsumerAffinity__hover-data-header">
                        <button
                            onClick={() => setActiveSlice(AffinitySlices.Affinity)}
                            data-testid="buttonFoodProfileConsumerNavAffinity"
                            className={cn(
                                "FoodProfileConsumerAffinity__tab",
                                activeSlice === AffinitySlices.Affinity && 'is-active',
                            )}
                        >
                            Affinity
                        </button>
                        <button
                            onClick={() => setActiveSlice(AffinitySlices.Experience)}
                            data-testid="buttonFoodProfileConsumerNavExperience"
                            className={cn(
                                "FoodProfileConsumerAffinity__tab",
                                activeSlice === AffinitySlices.Experience && 'is-active',
                            )}
                        >
                            Experience
                        </button>
                    </div>
                    <div className="FoodProfileConsumerAffinity__chart-title">
                        <div data-testid="textFoodProfileConsumerPopupTitle">
                            {activeSlice === AffinitySlices.Affinity ? 'LOVE OR LIKE IT' : 'KNOW IT'}
                        </div>
                        <div className="FoodProfileConsumerAffinity__chart-title-small">
                            <div data-testid="textFoodProfileConsumerPopupShow">
                                show&nbsp;&nbsp;
                            </div>
                            <button
                                onClick={() => setActiveValueType(ValueTypes.Raw)}
                                data-testid="buttonFoodProfileConsumerPopupRaw"
                                className={cn(
                                    "FoodProfileConsumerAffinity__switch-button",
                                    activeValueType === ValueTypes.Raw && 'is-active',
                                )}
                            >
                                RAW
                            </button>
                            <button
                                onClick={() => setActiveValueType(ValueTypes.Norms)}
                                data-testid="buttonFoodProfileConsumerPopupNorms"
                                className={cn(
                                    "FoodProfileConsumerAffinity__switch-button",
                                    activeValueType === ValueTypes.Norms && 'is-active',
                                )}
                            >
                                NORMS
                                <div className="FoodProfileConsumerAffinity__tooltip">
                                    Norms versus all<br/>food and beverage
                                </div>
                                <div className="FoodProfileConsumerAffinity__tooltip-triangle"/>
                            </button>
                        </div>
                    </div>
                    <div className="FoodProfileConsumerChart">
                        {consumerAffinityCategories.map((category, categoryIndex) => (
                            <AffinityCategory
                                key={category.title}
                                activeValueType={activeValueType}
                                activeSlice={activeSlice}
                                category={category}
                                categoryIndex={categoryIndex}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FoodProfileConsumerAffinity;