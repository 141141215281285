import { FC } from "react";
import cn from "classnames";
import "./BrandsHeader.styles.scss";
import CopyButton from "../../../../../components/new/Table/CopyButton";

interface iProps {
    title?: string;
    subTitle?: string;
    icon?: React.ReactElement;
    classNames?: string;
    onCopy?: () => void;
    clipboardData?: string[][];
}

const BrandsHeader: FC<iProps> = ({
    title,
    subTitle,
    icon,
    classNames = "",
    onCopy = () => {},
    children,
    clipboardData = [],
}) => {
    const classes = cn("BrandsHeader", classNames);

    return (
        <div className={classes}>
            {title && (
                <div className="BrandsHeader__title">
                    {icon && <div className="BrandsHeader__title-icon">{icon}</div>}
                    <div className="BrandsHeader__tooltip">
                        {children}
                    </div>
                    {title}
                    {clipboardData.length > 0 && (
                        <div className="BrandsHeader__copy" onClick={onCopy}>
                            <CopyButton data={clipboardData}/>
                        </div>
                    )}
                </div>
            )}
            {subTitle && <div className="BrandsHeader__sub-title">{subTitle}</div>}
        </div>
    );
};

export default BrandsHeader;
