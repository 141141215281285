import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";

import cn from "classnames";
import "./Modal.styles.scss";
import { IconClose } from "../../icons";

interface iProps {
    isOpened: boolean;
    close: () => void;
    children: ReactNode;
    hasInner?: boolean;
    className?: string;
    animationName?: string;
    closeOnEsc?: boolean;
    closeOnOutsideClick?: boolean;
    isHiddenCloseButton?: boolean;
}

const Modal: React.FC<iProps> = ({
    isOpened,
    children,
    close,
    className,
    hasInner = true,
    animationName,
    closeOnEsc,
    closeOnOutsideClick,
    isHiddenCloseButton,
}) => {
    const modalRef = useRef(null);

    const handleClose = useCallback(
        (e) => {
            if (e.key === "Escape") {
                close();
            }
        },
        [close]
    );

    useEffect(() => {
        if (closeOnEsc && isOpened) {
            document.addEventListener("keydown", handleClose, { passive: true });

        }
        return () => {
            document.removeEventListener("keydown", handleClose);
        };
    }, [isOpened, handleClose, closeOnEsc]);

    return createPortal(
        <CSSTransition
            in={isOpened}
            timeout={600}
            classNames={`Modal${animationName ? `__${animationName}` : ""}`}
            unmountOnExit={true}
            nodeRef={modalRef}
        >
            <div className={cn("Modal", [className])} onClick={(e: React.MouseEvent) => e.stopPropagation()}
                 ref={modalRef}>
                <div
                    className="Modal__overlay"
                    onClick={() => {
                        if (closeOnOutsideClick) {
                            close();
                        }
                    }}
                />
                <div className="Modal__content">
                    {hasInner ? (
                        <div className="Modal__inner">
                            {children}
                            {!isHiddenCloseButton && (
                                <div className="Modal__close" onClick={close}>
                                    <IconClose className="Icon32"/>
                                </div>
                            )}
                        </div>
                    ) : children}
                </div>
            </div>
        </CSSTransition>,
        document.getElementById("portal-root")!
    );
};

export default Modal;
