import { FC } from "react";
import cn from "classnames";
import "./ButtonView.styles.scss";
import { IconViewList, IconViewTiles } from "../../../../../icons";
import { ViewTypeEnum } from "../Contacts.helpers";

interface iProps {
    selectedView: ViewTypeEnum;
    type: ViewTypeEnum;
    onClick: (type: ViewTypeEnum) => void;
}

const ButtonView: FC<iProps> = ({ selectedView, type, onClick }) => {
    const classnames = cn(
        "ButtonView",
        selectedView === type && "ButtonView--active",
    );

    return (
        <div className={classnames} onClick={() => onClick(type)}>
            <div className="ButtonView__icon">
                {type === ViewTypeEnum.List && <IconViewList />}
                {type === ViewTypeEnum.Tiles && <IconViewTiles />}
            </div>
            <div className="ButtonView__label">{type}</div>
        </div>
    );
};

export default ButtonView;
