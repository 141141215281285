import Http from '../core/fetch';
import {
    MAC_DETAILS_FETCH_DATA_LOADED,
    MAC_DETAILS_FETCH_DATA_STARTED,
    MAC_DETAILS_FETCH_DATA_STORE,
    MAC_DETAILS_POST_BACKGROUND_STARTED,
    MAC_DETAILS_POST_BACKGROUND_UPLOADED,
    MAC_DETAILS_BACKGROUND_STORE,
    MAC_DETAILS_BACKGROUND_SET_FILE_SIZE_STATUS,
    MAC_DETAILS_POST_DEFAULT_BACKGROUND_STARTED,
    MAC_DETAILS_POST_DEFAULT_BACKGROUND_LOADED,
    MAC_DETAILS_DEFAULT_BACKGROUND_STORE,
    MAC_MENUS_FETCH_DATA_STARTED,
    MAC_MENUS_FETCH_DATA_LOADED,
    MAC_MENUS_FETCH_DATA_STORE,
    MAC_MENUS_RESET_DATA,
    MAC_DOWNLOAD_LINK_STARTED,
    MAC_DOWNLOAD_LINK_LOADED,
    MAC_DOWNLOAD_LINK_STORE
} from './actionsNamesList';
import { test, test2, toQueryString } from '../core/old_helpers';

export function setMacDetailsDataLoaded() {
    return {
        type: MAC_DETAILS_FETCH_DATA_LOADED
    };
}

export function setMacDetailsFetchingData() {
    return {
        type: MAC_DETAILS_FETCH_DATA_STARTED
    };
}

export function setReceivedMacDetailsData(data) {
    return {
        type: MAC_DETAILS_FETCH_DATA_STORE,
        data
    };
}

export function setMacDetailsPostingBackgroundImage() {
    return {
        type: MAC_DETAILS_POST_BACKGROUND_STARTED
    };
}

export function setMacDetailsBackgroundImageUploaded() {
    return {
        type: MAC_DETAILS_POST_BACKGROUND_UPLOADED
    };
}

export function setReceivedMacDetailsBackgroundImageUrl(data) {
    return {
        type: MAC_DETAILS_BACKGROUND_STORE,
        data
    };
}

export function setMacDetailsBackgroundImageStatus(data) {
    return {
        type: MAC_DETAILS_BACKGROUND_SET_FILE_SIZE_STATUS,
        data
    };
}

export function setMacDetailsBackgroundDefault() {
    return {
        type: MAC_DETAILS_POST_DEFAULT_BACKGROUND_STARTED
    };
}

export function setMacDetailsBackgroundDefaultLoaded() {
    return {
        type: MAC_DETAILS_POST_DEFAULT_BACKGROUND_LOADED
    };
}

export function setReceivedMacDetailsBackgroundDefaultImageUrl(data) {
    return {
        type: MAC_DETAILS_DEFAULT_BACKGROUND_STORE,
        data
    };
}

export function setMacMenusFetchingData() {
    return {
        type: MAC_MENUS_FETCH_DATA_STARTED
    };
}

export function setMacMenusDataLoaded() {
    return {
        type: MAC_MENUS_FETCH_DATA_LOADED
    };
}

export function setReceivedMacMenusData(data) {
    return {
        type: MAC_MENUS_FETCH_DATA_STORE,
        data
    };
}

export function resetMacMenusData() {
    if(downloadMacRequest) {
        downloadMacRequest.abort();
    }
    return {
        type: MAC_MENUS_RESET_DATA
    };
}

export function setMacDownloadLinkFetching() {
    return {
        type: MAC_DOWNLOAD_LINK_STARTED
    };
}

export function setMacDownloadLinkLoaded() {
    return {
        type: MAC_DOWNLOAD_LINK_LOADED
    };
}

export function setReceivedMacDownloadLink(data) {
    return {
        type: MAC_DOWNLOAD_LINK_STORE,
        data
    };
}

export function getMacDetailsData(params) {
    return (dispatch) => {
        const data = {
            macId: params.id
        };
        dispatch(setMacDetailsFetchingData());
        Http.get('mac-details', data)
            .then((res) => {
                const actualData = {
                    title: res.title,
                    options: res.options,
                    isNonUs: res.nonUS,
                    id: res.id,
                    dispArrays: res.dispArrays,
                    dispArraysSecond: res.dispArraysSecond
                };
                dispatch(setMacDetailsDataLoaded());
                dispatch(setReceivedMacDetailsData(actualData));
            })
            .catch(res => {
                console.error(res);
                return false;
            });
    };
}

export function setMacDetailsBackgroundImage(params) {
    return (dispatch) => {
        const data = {
            ...params
        };
        dispatch(setMacDetailsPostingBackgroundImage());
        Http.post('mac-details-save-bg', {}, {
            body: JSON.stringify(data),
            headers: {
                'content-type': 'application/json; charset=utf-8'
            }
        })
            .then((res) => {
                dispatch(setMacDetailsBackgroundImageUploaded());
                dispatch(setReceivedMacDetailsBackgroundImageUrl(`url(${res.defaultLink})`));
            })
            .catch(res => {
                console.error(res);
                dispatch(setMacDetailsBackgroundImageUploaded());
                return false;
            });
    };
}

export function setDefaultMacDetailsBackgroundImage(params) {
    return (dispatch) => {
        const data = {
            macId: params.macId
        };
        dispatch(setMacDetailsBackgroundDefault());
        Http
            .post(
                'mac-details-reset-bg',
                {},
                {
                    body: JSON.stringify(data),
                    headers: {
                        'content-type': 'application/json; charset=utf-8'
                    }
                })
            .then((res) => {
                dispatch(setMacDetailsBackgroundDefaultLoaded());
                dispatch(setReceivedMacDetailsBackgroundDefaultImageUrl(`url(${res.defaultLink})`));
            })
            .catch(res => {
                console.error(res);
                return false;
            });
    };
}

export function getMacMenuExamplesData(params, jsonData) {
    return (dispatch) => {
        const random = Math.random();
        const data = {
            Word: params.text,
            id: params.id,
            [random]: ''
        };
        const word = JSON.parse(jsonData);

        const order = {
            column: 0,
            dir: 'desc'
        };

        const search = {
            value: '',
            regex: false
        };

        const rawData = {
            menuParts: word.menuParts,
            restaurantFilter: word.restaurantFilter,
            mayContainWords: word.mayContainWords,
            viewInSnap: word.viewInSnap,
            kidsMenu: word.kidsMenu,
            exclude: word.exclude,
            order: [order],
            start: 0,
            length: 100,
            search: search
        };


        dispatch(setMacMenusFetchingData());

        const flattenedObj = test2(test(rawData));

        const dataEncodedString = toQueryString(flattenedObj);

        Http.post(
            'mac-menu-examples',
            data,
            {
                body: JSON.stringify({ data: dataEncodedString, ...data }),
                // body: JSON.parse(formData),
                headers: {
                    // 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
                    // 'Content-Type': 'multipart/form-data'
                    'content-type': 'application/json; charset=utf-8'
                }
            })
            .then((res) => {
                dispatch(setMacMenusDataLoaded());
                dispatch(setReceivedMacMenusData(res));
            })
            .catch(res => {
                console.error(res);
                return false;
            });
    };
}

let downloadMacRequest;

export function getMacDownloadLink(data, type, anchorLink) {
    return (dispatch) => {
        const requestType = type === 'image' ? 'mac-download-image' : 'mac-download-ppt';
        dispatch(setMacDownloadLinkFetching());
        if (downloadMacRequest) {
            downloadMacRequest.abort();
        }
        downloadMacRequest = Http.postXHR(
            requestType,
            {},
            JSON.stringify(data)
            );

        downloadMacRequest.then((res) => {
                console.dir(res);
                dispatch(setMacDownloadLinkLoaded());
                //dispatch(setReceivedMacDownloadLink(res.DownloadLink));

                const link = document.createElement('a');
                link.href = `${process.env.REACT_APP_API}${res.DownloadLink}`;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link)
            })
            .catch(res => {
                console.error(res);
                return false;
            });
    };
}
