import cn from "classnames";
import { TABLE_HEAD_SIZE, TABLE_ROW_SIZE } from "./Table.helpers";

export interface iProps {
    head?: boolean;
    style?: React.CSSProperties;
    width?: number | string;
    fillRow?: boolean;
    textCentered?: boolean;
    isHoverable?: boolean;
    isVisibileOnHover?: boolean;
    isActive?: boolean;
    onClick?: () => void;
    testId?: string;
}

const TableCellWrapper: React.FC<iProps> = ({
    children,
    head = false,
    style = {},
    width = "auto",
    fillRow = false,
    textCentered,
    isHoverable,
    isActive,
    onClick,
    isVisibileOnHover,
    testId,
}) => {
    const align = textCentered ? ("center" as const) : ("start" as const);
    const styles = {
        flexGrow: fillRow ? 1 : 0,
        flexShrink: fillRow ? 1 : 0,
        flexBasis: fillRow ? "0%" : width,
        height: head ? TABLE_HEAD_SIZE : TABLE_ROW_SIZE,
        minHeight: "100%",
        lineHeight: 1,
        justifyContent: align,
        textAlign: align,
        paddingLeft: align === "center" ? 0 : 24,
        width: fillRow ? "auto" : width,
        ...style,
    };
    const classNames = cn(
         "TableNew__cell",
         {
            "is-head": head,
            "is-hoverable": isHoverable,
            "is-active": isActive,
            "is-toggle-visible": isVisibileOnHover
        });

    const props: any = {
        className: classNames,
        style: styles,
        ["data-testid"]: testId,
    };

    if (onClick) {
        props.onClick = onClick;
    }
    return (
        <div {...props}>
            {children}
        </div>
    );
};

export default TableCellWrapper;
