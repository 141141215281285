import {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PT from 'prop-types';
import DocumentEditPopup from '../DocumentEditPopup/DocumentEditPopup';
import DocumentDeletePopup from '../DocumentDeletePopup/DocumentDeletePopup';
import DocumentsAddPopup from '../DocumentsAddPopup/DocumentsAddPopup';
import ProjectCreatePopup from '../ProjectCreatePopup/ProjectCreatePopup';
import ProjectEditPopup from '../ProjectEditPopup/ProjectEditPopup';
import ProjectDeletePopup from '../ProjectDeletePopup/ProjectDeletePopup';

import { getData } from './documentsLibraryPopups.actions';

import './FileUpload.scss';
import './custom-drop-down.scss';

class DocumentsLibraryPopups extends Component {
    componentDidMount() {
        this.props.getData();
    }
    render() {
        return (
            <div>
                <DocumentEditPopup/>
                <DocumentDeletePopup/>
                <DocumentsAddPopup/>
                <ProjectCreatePopup/>
                <ProjectEditPopup/>
                <ProjectDeletePopup/>
            </div>
        );
    }
}

DocumentsLibraryPopups.propTypes = {
    getData: PT.func
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    getData: () => dispatch(getData())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentsLibraryPopups));
