import { FC } from "react";
import { toggleInArray } from "../../../core/helpers";
import cn from "classnames";
import './SelectGrid.styles.scss';

interface iItem {
    title: string;
    value: string;
}

interface iProps {
    data: iItem[];
    value: string[];
    setValue: (value: string[]) => void;
}

const SelectGrid: FC<iProps> = (
    {
        data,
        setValue,
        value,
    }
) => {
    const handleItemToggle = (itemValue: string) => () => {
        setValue(toggleInArray(value, itemValue));
    };

    return (
        <div className="SelectGrid">
            {data.map(item => {
                const className = cn(
                    'SelectGrid__item',
                    'SelectGridItem',
                    value.includes(item.value) && 'SelectGridItem--active',
                );

                return (
                    <div
                        key={item.value}
                        className={className}
                        onClick={handleItemToggle(item.value)}
                        dangerouslySetInnerHTML={{
                            __html: item.title,
                        }}
                    />
                );
            })}
        </div>
    );
};

export default SelectGrid;