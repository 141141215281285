import { FC } from 'react';
import classNames from 'classnames';
import "./InfoBlock.styles.scss";

interface iProps {
    title?: JSX.Element | string;
    subTitle?: JSX.Element | string;
    className?: string;
    isLocked?: boolean;
    testId?: string;
}

const InfoBlock: FC<iProps> = (
    {
        title = '',
        subTitle = '',
        className = '',
        children,
        testId,
    }
) => {
    return (
        <div
            className={classNames('InfoBlock', className)}
            data-testid={testId}
        >
            {title && (
                <div className="InfoBlock__title">
                    {title}
                    {subTitle && (
                        <div className="InfoBlock__sub-title">
                            {subTitle}
                        </div>
                    )}
                </div>
            )}

            <div className="InfoBlock__content">
                {children}
            </div>
        </div>
    );
};

export default InfoBlock;
